import constants from '../../../reducers/_constants';

const initialState = {
    selectedCodes: [],
    onlySelectedCodes: [],
};

const CodesReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SupplierRegistrationConstants.SET_CODES: {
            return {
                ...state,
                selectedCodes: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.SET_ONLY_SELECTED_CODES: {
            return {
                ...state,
                onlySelectedCodes: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.CLEAR_CODES: {
            return {
                ...state,
                onlySelectedCodes: [],
                selectedCodes: [],
            };
        }
        default:
            return state;
    }
};

export default CodesReducer;
