import PropTypes from 'prop-types';
import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { v4 as uuidv4 } from 'uuid';
import { base64ToHtml } from '../../services/html.service';
import Constants from './constants';
import formatHTMLToPlainText from '../../utils/formatHTMLToPlainText';

const StructureViewer = ({
    data,
    viewerMode,
    showDiffOnly = false,
    splitView = true,
    hideLineNumbers = true,
}) => {
    const getDescriptionText = (description) => {
        if (!description) {
            return '';
        }
        const str = typeof description === 'string' ? description : description.toString();
        return formatHTMLToPlainText(base64ToHtml(str));
    };

    const getDetails = (str) => {
        const detailsSection = [];
        str?.details?.forEach((detail, index) => {
            const old = detail?.title === Constants.DESCRIPTION_FIELD ? getDescriptionText(detail?.old) : detail?.old ?? '';
            const current = detail?.title === Constants.DESCRIPTION_FIELD ? getDescriptionText(detail?.current) : detail?.current ?? '';
            detailsSection.push(
                <div className='diff-section'>
                    <p>{detail.title}</p>
                    <ReactDiffViewer
                        key={`react-diff-viewer-${uuidv4()}`}
                        oldValue={typeof old !== 'string' ? old.toString() : old}
                        newValue={typeof current !== 'string' ? current.toString() : current}
                        leftTitle= {index === 0 && str.leftTitle ? str.leftTitle : undefined}
                        rightTitle = {index === 0 && str.rightTitle ? str.rightTitle : undefined}
                        compareMethod={viewerMode}
                        showDiffOnly = {showDiffOnly}
                        splitView={splitView}
                        hideLineNumbers = {hideLineNumbers} />
                </div>,
            );
        });
        return detailsSection;
    };

    return (
        <>

            {data.additionalTextInfo && (
                <div className='diff-group'>
                    <h2>{data.additionalTextInfoTitle}</h2>
                    <h4>{data.additionalTextInfo}</h4>
                </div>
            )}

            {
                (data?.title || data?.details)
                && <div className='diff-group' >
                    <>
                        {data.title && <h2>{data.title}</h2>}
                        {data.details && getDetails(data)}
                    </>
                </div>
            }

            {data.children
                 && <div className='diff-group' >
                     {data.childrenTitle && <h2>{data.childrenTitle}</h2>}
                     {data.children.map((child) => (
                         <StructureViewer
                             key={`structure-viewer-${uuidv4()}`}
                             data={child}
                             viewerMode={viewerMode} />
                     )) }
                 </div>
            }
        </>
    );
};

StructureViewer.propTypes = {
    data: PropTypes.object.isRequired,
    viewerMode: PropTypes.string,
    showDiffOnly: PropTypes.bool,
    splitView: PropTypes.bool,
    hideLineNumbers: PropTypes.bool,
};

export default StructureViewer;
