import React from 'react';
import Button from '../../components/Button';
import { evaluationStatuses } from '../../config/constants';

const downloadAllLabel = 'Download All';
const E_SUMMARY = 'e-summary';

const getDisqualifySupplierButton = (handleDisqualify) => <Button
    id={'disqualifySupplier'}
    size={'small'}
    label='Disqualify Supplier'
    variant={'primary'}
    additionalVariant={'red'}
    handleClick={() => handleDisqualify(true)}
/>;

const getReasonButton = (handleReason) => <Button
    id={'getReason'}
    size={'small'}
    label='View Reason'
    variant={'primary'}
    handleClick={() => handleReason}
/>;

const getExportButton = (handleExport) => <Button
    id={'exportAll'}
    size={'small'}
    label='Export All'
    handleClick={handleExport}
/>;

const getBackButton = (handleGoBack) => <Button
    id={'back'}
    size={'small'}
    label='Back'
    variant={'secondary'}
    handleClick={handleGoBack}
/>;

const getOpenAndReleaseButton = (handleStatusUpdate, accountId) => <Button
    id={'openAllSealsButton'}
    size={'small'}
    label='Open & Release'
    handleClick={() => handleStatusUpdate(
        evaluationStatuses.released,
        null,
        accountId,
    )}
/>;

const getSubmitForApprovalButton = (handleStatusUpdate, accountId) => <Button
    id={'submitForApproval'}
    size={'small'}
    label='Submit for Approval'
    handleClick={() => handleStatusUpdate(
        evaluationStatuses.evaluationComplete,
        null,
        accountId,
    )}
/>;

const getStage1ApproveButton = (handleStatusUpdate, accountId, buttonLabel = 'Approve All') => <Button
    id={'approveAll'}
    size={'small'}
    label={buttonLabel}
    handleClick={() => handleStatusUpdate(
        evaluationStatuses.stage1Approved, 's1approved', accountId,
    )}
/>;

const getStage2ApproveButton = (handleStatusUpdate, accountId, buttonLabel = 'Approve All') => <Button
    id={'approveAll'}
    size={'small'}
    label={buttonLabel}
    handleClick={() => handleStatusUpdate(
        evaluationStatuses.stage2Approved, 's2approved', accountId,
    )}
/>;

const getStage1RejectButton = (handleStatusUpdate, accountId, buttonLabel = 'Reject All') => <Button
    id={'rejectAll'}
    size={'small'}
    label={buttonLabel}
    variant={'primary'}
    additionalVariant={'red'}
    handleClick={() => handleStatusUpdate(
        evaluationStatuses.stage1Approved, 'rejected', accountId,
    )}
/>;

const getStage2RejectButton = (handleStatusUpdate, accountId, buttonLabel = 'Reject All') => <Button
    id={'rejectAll'}
    size={'small'}
    label={buttonLabel}
    variant={'primary'}
    additionalVariant={'red'}
    handleClick={() => handleStatusUpdate(
        evaluationStatuses.stage2Approved, 'rejected', accountId,
    )}
/>;

const getDownloadAllButton = (handleMultipleDownload, buttonLabel = 'Download All', documentIds) => (<Button
    id='downloadAll'
    size='small'
    label={buttonLabel}
    handleClick={(e) => {
        e.preventDefault();
        handleMultipleDownload(documentIds);
    }}
/>);

const evaluationButtons = {
    getDisqualifySupplierButton,
    getReasonButton,
    getExportButton,
    getBackButton,
    getOpenAndReleaseButton,
    getSubmitForApprovalButton,
    getStage1ApproveButton,
    getStage2ApproveButton,
    getStage1RejectButton,
    getStage2RejectButton,
    getDownloadAllButton,
    downloadAllLabel,
};

export { evaluationButtons, E_SUMMARY };
