import React, { useState, useEffect, useContext } from 'react';
import SelectSearch from 'react-select-search/dist/cjs';
import { useHistory, useParams } from 'react-router-dom';
import { contractSteeringRoles, userStatus } from '../../config/constants';
import Table from '../../components/Table';
import Button from '../../components/Button';
// import Modal from '../../components/Modal';
import userManagementAPIs from '../../services/user-management.service';
import projectManagementAPIs from '../../services/project-management.service';
import rbac from '../../rbac';
import AuthContext from '../../context/AuthContext';
import Toast from '../../components/Alerts/Toast/Toast';
import helperFunctions from '../../utils/helperFunctions';

const SteeringGroupContract = () => {
    const history = useHistory();
    const { contractId } = useParams();
    let isValid = true;
    const userRoleProperties = {
        roleTitle: 'Role', users: [], userRole: '',
    };
    const authContext = useContext(AuthContext);
    const [steeringState, setSteeringState] = useState({
        procurementOfficer: userRoleProperties,
        procurementManager: userRoleProperties,
        contractManager: userRoleProperties,
        categorySpecialist: userRoleProperties,
    });
    const [userList, setUserList] = useState([]);
    const [awardedSuppliersList, setAwardedSuppliersList] = useState([]);
    // const { accountType, role } = authContext.user;
    // const [showUserModal, setShowUserModal] = useState(false);
    let steeringData = {
        // assignees: [],
        steeringInfo: [],
    };

    const getAllAccountUsersRequest = async () => {
        try {
            const response = await userManagementAPIs
                .getAllUsersByAccount(userStatus.approved);
            setUserList(response.data.map((userItem) => ({
                name: `${userItem.contactDetails?.firstname} ${userItem.contactDetails?.surname}`,
                value: userItem.userID,
                departmentName: userItem.departmentName,
                departmentID: userItem.departmentID,
                accountID: userItem.accountID,
            })));
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const getAllUsersWithRole = (userRole, steeringInfo) => steeringInfo
        .filter((el) => el.userRole === userRole).map((el) => ({
            name: el.username,
            value: el.userID,
            departmentID: el.departmentID,
            accountID: el.accountID,
        }));

    const prepareSteeringState = (steeringInfo) => {
        const preparedState = steeringState;
        if (steeringInfo) {
            Object.keys(preparedState).map((key) => {
                const roleEl = { ...preparedState[key] };
                roleEl.roleTitle = 'Role';
                roleEl.users = getAllUsersWithRole(contractSteeringRoles
                    .filter((steeringRole) => (steeringRole.id === key))[0]?.role, steeringInfo);
                preparedState[key] = roleEl;
                return null;
            });
        }
        setSteeringState({ ...preparedState, hasLoaded: true });
    };

    const getContractRequest = async () => {
        try {
            const response = await projectManagementAPIs.getContract(contractId);
            // Prevent users who cannot edit the steering group from accessing the page.
            if (response?.data?.steeringInfo && !rbac.can(
                rbac.action.editSteeringGroup, rbac.util.getSteeringGroupRole(
                    authContext.user.id,
                    response?.data?.steeringInfo,
                ),
            )) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'You do not have access to this content.',
                });
                return history.goBack();
            }
            prepareSteeringState(response.data?.steeringInfo);
            setAwardedSuppliersList(response.data?.awardedSuppliers?.map((userItem) => ({
                name: userItem.accountName,
                value: userItem.userID,
                departmentName: userItem.departmentName,
            })));
            return [];
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
        return [];
    };

    useEffect(() => {
        getAllAccountUsersRequest();
        getContractRequest();
    }, [contractId]);

    const fieldValidation = (fieldId, fieldName) => {
        if (fieldId === 'procurementOfficer'
            && steeringState[fieldId]?.users.length === 0) {
            isValid = false;
            alert('Please select at least 1 user for the Procurement Officer role.');
        } else if (steeringState[fieldId]?.users.length > 0
            && (!steeringState[fieldId]?.roleTitle)) {
            isValid = false;
            alert(`Please enter the role title for the user(s) assigned to the role ${fieldName}.`);
        }
    };

    const createSteeringGroup = (key, userole) => {
        if (steeringState[key]?.roleTitle?.length > 0
            && steeringState[key]?.users?.length > 0
        ) {
            // const userIds = steeringState[key]?.users.map((user) => user.value);
            // let assignees;
            // if (steeringData.assignees.length > 0) {
            //     assignees = userIds.filter(
            //         (dt) => steeringData.assignees.indexOf(dt) === -1,
            //     );
            // } else {
            //     assignees = userIds;
            // }
            const steeringInfo = steeringState[key]?.users.map((user) => ({
                userRole: userole,
                userID: user.value,
                departmentID: user.departmentID,
                accountID: user.accountID,
            }));

            // In put request we pass assignees as steeringGroup
            // and in get requet we get it as steeringGroup.
            steeringData = {
                ...steeringData,
                // assignees: [
                //     ...steeringData.assignees,
                //     ...assignees,
                // ],
                steeringInfo: [
                    ...steeringData.steeringInfo,
                    ...steeringInfo,
                ],
            };
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        contractSteeringRoles.map((data) => (fieldValidation(data.id, data.name)));
        contractSteeringRoles.map((data) => (createSteeringGroup(data.id, data.role)));
        if (isValid) {
            // API integration pending.
            const responseData = await projectManagementAPIs.updateContractSteeringGroup(
                contractId,
                steeringData,
            );
            if (responseData.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Steering group updated successfully.',
                });
                history.goBack();
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to update steering group.',
                });
            }
        }
    };

    const handleChange = (userSelect, roleId) => {
        let addRoleObj;
        let roleProperty;
        const roleKeys = steeringState[roleId];
        if (userSelect.target) {
            roleProperty = userSelect.target.id.split('-');
            if (roleProperty[1] === 'roleTitle') {
                addRoleObj = { roleTitle: userSelect.target.value };
            } else {
                addRoleObj = { [roleProperty[1]]: userSelect.target.checked };
            }
        } else {
            addRoleObj = { users: userSelect };
        }

        setSteeringState({
            ...steeringState,
            [roleId]: {
                ...roleKeys,
                ...addRoleObj,
            },
        });
    };

    const prepareBody = () => (contractSteeringRoles.filter((grp) => grp.id !== 'collaborativeUser').map((useRole, idx) => ({
        role: <div key={idx} className='role-label ssgField'>
            <label>{useRole.name}</label>
            {useRole.optional && <label className='caption selectSearchTitle'> &#40;optional&#41;</label>}
        </div>,
        users: <><div className='ssgField'>
            <SelectSearch
                id={`${useRole.id}-users`}
                options={useRole.id !== 'supplierUser' ? helperFunctions.constructGroupedDropdownData(userList, 'departmentName') || [] : helperFunctions.constructGroupedDropdownData(awardedSuppliersList, 'departmentName') || []}
                filterOptions={helperFunctions.handleFilter}
                search={true}
                multiple={true}
                value={steeringState[useRole.id]?.users?.map((a) => a.value)}
                printOptions='on-focus'
                closeOnSelect={false}
                placeholder='Search users on Open'
                onChange={(event, data) => handleChange(data,
                    useRole.id)}
                z-index='4'
            />
        </div></>
        ,
    })));

    return <section id='selectSteeringGroup'>
        <div id='selectSteeringGroupContainer'>
            <Table
                id='selectSteeringGroupTable'
                headerData={['Role', 'Add User(s)']}
                bodyData={prepareBody()}
            />
            <div className='sgSubmitContainer'>
                <Button key='ssg-Cancelbtn' className='sgCancelBtn'
                    id='selectSteeringGroup-cancel'
                    variant='secondary'
                    label='Cancel'
                    handleClick={() => history.goBack()}
                />
                <Button key='ssg-saveBtn' className='sSGBtnSave'
                    id='selectSteeringGroup-save'
                    variant='primary'
                    label='Save'
                    handleClick={(e) => handleSave(e)}
                />
            </div>
        </div>
    </section>;
};

export default SteeringGroupContract;
