import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/Button';

const LotCard = ({
    id,
    header,
    caption,
    headerTitle,
    content,
    actionBtnText,
    handlerActionBtn,
}) => (<div className='lotCard' id={id}>
    <section className='lot-card-container'>
        <section className='itemHeaderContainer'>
            <div className='header'>
                {caption && <p className='caption-small'>{caption}</p>}
                {header && <p className='title-small projectTitle'>{headerTitle}</p>}
            </div>
        </section>
        <div className='content'>
            {content}
        </div>
        {actionBtnText && <div className='btnFooter'>
            <Button
                id='actionBtn'
                variant='tertiary'
                label={actionBtnText}
                handleClick={handlerActionBtn} />
        </div>}
    </section>
</div >);

LotCard.propTypes = {
    id: PropTypes.string.isRequired,
    header: PropTypes.bool,
    headerTitle: PropTypes.string,
    caption: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    footer: PropTypes.bool,
    actionBtn: PropTypes.bool,
    actionBtnText: PropTypes.string,
    handlerActionBtn: PropTypes.func,
};

export default LotCard;
