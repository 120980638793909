import constants from '../../../reducers/_constants';

const setLocalities = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_LOCALITIES,
    payload,
});

const setOnlySelectedLocalities = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_ONLY_SELECTED_LOCALITIES,
    payload,
});

const clearLocalities = () => ({
    type: constants.SupplierRegistrationConstants.CLEAR_LOCALITIES,
});

export default {
    setLocalities,
    setOnlySelectedLocalities,
    clearLocalities,
};
