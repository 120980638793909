const PROFILE_LABELS = {
    organisationInformation: 'Organisation Information',
    businessStructure: 'Company Type:',
    natureOfBusiness: 'Nature of Business (SIC):',
    vatNumber: 'VAT Number:',
    registrationNumber: 'Registration number:',
    address: 'Address:',
    wardName: 'Ward:',
    localityName: 'Locality:',
    regionName: 'Region:',
    noOfEmployees: 'Number of employees:',
    companyPhoneNo: 'Phone Number:',
    organizationAddress: 'Organisation Address:',
    organizationName: 'Organisation Name:',
};

export default PROFILE_LABELS;
