import constants from './constants';

const setBuilderState = (payload) => ({
    type: constants.SET_BUILDERSTATE,
    payload,
});

export default {
    setBuilderState,
};
