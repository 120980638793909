import React from 'react';
import PropTypes from 'prop-types';

import LabelledTextarea from '../../../components/LabelledTextArea';

const TextAreaComponent = ({
    id,
    label,
    value,
    placeholder,
    required = false,
}) => (
    <div className='vpnItem'>
        <LabelledTextarea
            id={id}
            type='textarea'
            label={label}
            placeholder={placeholder}
            value={value}
            breakColumn={false}
            currentCharCount={0}
            required={required}
            readonly={false}
        />
    </div>
);

TextAreaComponent.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
};

export default TextAreaComponent;
