import React from 'react';
import PropTypes from 'prop-types';

import TimePicker from 'react-time-picker';

import clearIcon from '../../../styles/images/clearIcon.svg';

const TimePickerComponent = ({
    id,
    label,
    value,
    noticeType,
    permittedNoticeTypes = [],
    onChange,
    minTimeFlag = true,
    ...props
}) => (
    (!noticeType || (noticeType && permittedNoticeTypes.includes(noticeType)))
    && <div className='vpnItem'>
        <div className='noticeDatePicker'>
            <label className='form-label title datePickerLabel'>
                {label}
            </label>

            <TimePicker
                clockIcon={null}
                className='customDateInput nmiDate-imput'
                clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                hourPlaceholder='HH'
                minutePlaceholder='mm'
                format={'HH:mm'}
                locale='en-gb'
                minTime={minTimeFlag ? '00:00' : undefined}
                disableClock={true}
                name={id}
                value={value}
                onChange={(e) => onChange(e, id)}
                { ... props }
            />
        </div>
    </div>
);

TimePickerComponent.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    noticeType: PropTypes.string,
    permittedNoticeTypes: PropTypes.array,
    minTimeFlag: PropTypes.bool,
};

export default TimePickerComponent;
