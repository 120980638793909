import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Constants from './constants';
import './index.scss';

const ShowMoreShowLess = ({ inputText, inputTextCharacterLimit }) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    if (inputText?.length >= inputTextCharacterLimit) {
        return <div id='seeMoreSeeLess'>
            <p className='caption sectionValue'>
                {isReadMore
                    ? inputText.slice(0, inputTextCharacterLimit) : inputText}
                <span onClick={toggleReadMore} className='seeMoreLess'>
                    {isReadMore ? Constants.seeMore : Constants.seeLess}
                </span>
            </p>
        </div>;
    }
    return <div>
        <p className='caption sectionValue'>{inputText}</p>
    </div>;
};


ShowMoreShowLess.propTypes = {
    inputText: PropTypes.string.isRequired,
    inputTextCharacterLimit: PropTypes.number.isRequired,
};

export default ShowMoreShowLess;
