import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    EditorState, ContentState, convertToRaw, Modifier,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import debounce from 'lodash/debounce';

import RichTextEditorConfig from './rich-text-editor-config';
import { base64ToHtml, htmlToBase64 } from '../../../services/html.service';
import Counter from './Counter';
import {
    HANDLED, INSERT_CHARACTERS, DEBOUNCE_TIME_IN_MS, CHAR_LIMIT_TO_DEBOUNCE,
} from './constants';
import {
    contentExcededLimits, getNumOfCharacters, getRemainingText,
} from './helpers';

const RichEditor = ({
    id,
    value,
    showCharCount,
    maxCharCount = 512,
    onChange,
}) => {
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(base64ToHtml(value || '')))),
    );
    const [charCount, setCharCount] = useState(0);


    const onEditorStateChange = (currentEditorState) => {
        if (!contentExcededLimits(currentEditorState, editorState, maxCharCount, showCharCount)) {
            const htmlRaw = draftToHtml(convertToRaw(currentEditorState.getCurrentContent()));
            setEditorState(currentEditorState);
            onChange({ target: { name: id, value: htmlToBase64(htmlRaw) } });
        }
    };

    const debouncedOnChange = useCallback(
        debounce(onEditorStateChange, DEBOUNCE_TIME_IN_MS), [editorState],
    );

    const handleBeforeInput = (input) => {
        const inputLength = getNumOfCharacters(editorState);
        if (input && inputLength >= maxCharCount) {
            return HANDLED;
        }
        return '';
    };

    const handlePastedText = (input) => {
        const inputLength = getNumOfCharacters(editorState);
        const remainingLength = getRemainingText(inputLength, editorState, maxCharCount);

        if (input.length + inputLength >= maxCharCount) {
            const newContent = Modifier.replaceText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                input.slice(0, remainingLength),
            );
            onEditorStateChange(
                EditorState.push(
                    editorState,
                    newContent,
                    INSERT_CHARACTERS,
                ),
            );
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (maxCharCount) {
            setCharCount(getNumOfCharacters(editorState));
        }
    },
    [editorState]);

    return (
        <>
            <Editor
                defaultEditorState={editorState}
                wrapperClassName='rich-text-editor-wrapper'
                editorClassName='rich-text-editor'
                onEditorStateChange={charCount > CHAR_LIMIT_TO_DEBOUNCE
                    ? debouncedOnChange : onEditorStateChange}
                toolbar={RichTextEditorConfig.toolbar}
                stripPastedStyles={true}
                handleBeforeInput={showCharCount ? handleBeforeInput : undefined}
                handlePastedText={showCharCount ? handlePastedText : undefined}
                preserveSelectionOnBlur={true}
            />
            {showCharCount
            && <Counter limit={maxCharCount} numberOfChars={charCount} ></Counter>
            }
        </>
    );
};

export default RichEditor;

RichEditor.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    showCharCount: PropTypes.bool,
    maxCharCount: PropTypes.number,
};
