import React, { useState } from 'react';
import durhamIcon from '../../styles/images/about-us/Durham.png';
import darlingtonIcon from '../../styles/images/about-us/Darlington.png';
import gatesheadIcon from '../../styles/images/about-us/gateshead-counsil.png';
import hartlepoolIcon from '../../styles/images/about-us/hartlepool.png';
import middlesboroghIcon from '../../styles/images/about-us/middlesborogh.png';
import newcastleCityIcon from '../../styles/images/about-us/newcastle-city.png';
import northTynesideIcon from '../../styles/images/about-us/north-tyneside.png';
import northemberlandIcon from '../../styles/images/about-us/northemberland.png';
import redcarIcon from '../../styles/images/about-us/redcar.png';
import southTynesideIcon from '../../styles/images/about-us/south-tyneside.png';
import stocktonIcon from '../../styles/images/about-us/stockton-on-tees.png';
import sunderlandIcon from '../../styles/images/about-us/sunderland.png';
import chevronLeftIcon from '../../styles/images/chevronLeftIcon.svg';
import chevronRight from '../../styles/images/chevronRight.svg';

const AboutOpen = () => {
    const icons = [
        [southTynesideIcon, stocktonIcon, sunderlandIcon],
        [darlingtonIcon, durhamIcon, gatesheadIcon],
        [hartlepoolIcon, middlesboroghIcon, newcastleCityIcon],
        [northTynesideIcon, northemberlandIcon, redcarIcon],
    ];

    const [activeGroupIndex, setActiveGroupIndex] = useState(0);

    const printGroup = (i = 0) => <div className='group'>
        <div className='column'>
            <img src={icons[i][0]} />
        </div>
        <div className='column'>
            <img src={icons[i][1]} />
        </div>
        <div className='column'>
            <img src={icons[i][2]} />
        </div>
    </div>;

    return <div className='bdContentSection'>
        <p className='body bold xx-large-title'>Who are NEPO?</p>
        <p className='about-us-content body'>Since its inception in 1976, NEPO undertakes high-value procurement in major strategic areas of spend (such as construction, energy, fleet and professional services) on behalf of North East local authorities and the wider UK public sector.</p>
        <p className='about-us-content body'>At every step of our procurement journey, we collaborate with a wide range of stakeholders to ensure their needs are understood and met. So, whether you are a public sector colleague, a supplier interested in doing business with us, or an end-user using the goods and services we procure, you can rest assured that we are procuring for you.</p>

        <p className='about-us-title body bold xx-large-title'>What does NEPO do?</p>
        <p className='about-us-content body'>NEPO&apos;s procurement solutions are the result of extensive consultation with the public sector, suppliers and end users so that we deliver positive outcomes for the communities we serve. Our wide range of solutions are available for use by UK public sector organisations via a free-of-charge associate membership scheme.</p>
        <p className='about-us-content body'>NEPO also drives best practice by working with regional and national partners to help suppliers improve their tendering skills via the NEPO Business Club training programme.</p>
        <p className='about-us-content body'>Visit <a href='https://www.nepo.org/'>nepo.org</a> to find out more.</p>

        <p className='about-us-title body bold xx-large-title'>What is Open?</p>
        <p className='about-us-content body'>We believe that our procurement systems
        should help us focus resources,
        support strategic goals and deliver the best outcomes for our communities and
        internal stakeholders.</p>
        <p className='about-us-content body'>That&rsquo;s why we built Open.</p>
        <p className='about-us-content body'>Our journey started in 2018 when NEPO and the twelve North East local authorities identified recurring challenges:</p>
        <p className='about-us-content body'>Opportunity to consider design aspects here e..g grid or more dynamic approach</p>
        <ul className='about-us-list'>
            <li className='about-us-content body'> How could we bring together the wealth of data available to us, to <span className='bold'>support our decision- making and procurement approach?</span></li>
            <li className='about-us-content body'> How could we <span className='bold'>maintain a strategic understanding</span> of the procurement lifecycle from commissioning to contract management?</li>
            <li className='about-us-content body'> How could we <span className='bold'>address supplier frustrations</span> over duplication and clunky interfaces?</li>
            <li className='about-us-content body'> How could we <span className='bold'>move forward at our own pace,</span> rather than waiting for the technology to catch up?</li>
        </ul>
        <p className='about-us-content body'>Open is the result of extensive collaboration and a shared ambition by NEPO and the twelve North East local authorities to drive excellence in public sector procurement.</p>
        <div className='container'>
            <button className='prevButton' onClick={() => { setActiveGroupIndex((activeGroupIndex - 1) % 4); }}>
                <img className='prevButtomImage' src={chevronLeftIcon}/>
            </button>
            { printGroup(activeGroupIndex) }
            <button className='nextButton' onClick={() => { setActiveGroupIndex((activeGroupIndex + 1) % 4); }}>
                <img className='nextButtomImage' src={chevronRight} />
            </button>
        </div>
        <p className='about-us-content body'>Open is built around public sector requirements, identified through consultation with a wide range of local authorities and industry insight. From the outset, public sector colleagues have shaped and tested Open, to ensure it meets their needs.</p>
        <p className='about-us-title body bold xx-large-title'>Why use Open?</p>
        <p className='about-us-content body'>From concept through to contract management, Open provides users with the ability to carry out public sector procurement in a simplified and compliant way,
        unlocking a wide range of benefits across commissioning and procurement.</p>

    </div>;
};

export default AboutOpen;
