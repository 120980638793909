const Constants = {
    ACCESS_REQUESTED_SUCCESSFULLY: 'Access Request has been sent',
    ACCESS_REQUEST_ERROR_MESSAGE: 'The access request couldn\'t be sent at this moment. Please try again in a few minutes',
    SOLUTION_ACCESS_REQUEST_STATUSES: {
        NOT_REQUESTED: 'not_requested',
        ACCESS_REQUESTED: 'access_requested',
        APPROVED: 'approved',
        REJECTED: 'rejected',
        UNKNOWN: 'unknown',
    },
};


export default Constants;
