import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';

const CompanySearchResults = ({
    currentStep, onSubmit, closeModal, skipStep, results,
}) => {
    const modalBody = <>
        <p className='body'>We&apos;ve found the following record on Companies House for the
        company number <span className='title'>{results.companyNumber}</span>.
        Is this record correct?</p>
        <div id='search-results'>
            <p className='title'>{results.name || ''}</p>
            <p className='caption'>{results.companyNumber || ''}</p>
            <p className='body'>{`${results?.address?.addressLine1 || ''} ${results?.address?.addressLine2 || ''} 
                ${results?.address?.postTown || ''} ${results?.address?.county || ''} 
                ${results?.address?.country || ''} ${results?.address?.postCode || ''}`}</p>

        </div>
    </>;
    if (currentStep !== 5) {
        return null;
    }
    return <>
        <Modal
            open={true}
            closeModal={closeModal}
            size='medium'
            headerTitle='Search Result'
            handleMainActionBtnClick={(e) => onSubmit(e, results)}
            mainActionButtonTxt='Continue'
            closeButton={false}
            body={modalBody}
            helpOption={false}
            secondActionButton={true}
            secondActionButtonTxt='No'
            handleSecondaryActionBtnClick={(e) => { e.preventDefault(); skipStep(); }}
        />
    </>;
};

CompanySearchResults.propTypes = {
    currentStep: PropTypes.number,
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    skipStep: PropTypes.func,
    results: PropTypes.object,
};

export default CompanySearchResults;
