import React from 'react';
import PropTypes from 'prop-types';

import Tick from '../../../../styles/images/tick.png';

const CreatePasswordSuccessBody = ({ bodyMessage }) => <>
    <div className='success-container'>
        <img src={Tick} alt='Success' className='success-img'></img>
        <p className='body'>{bodyMessage}</p>
    </div>
</>;

CreatePasswordSuccessBody.propTypes = {
    bodyMessage: PropTypes.string.isRequired,
};

export default CreatePasswordSuccessBody;
