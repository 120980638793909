import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Proptypes from 'prop-types';

const Breadcrumbs = ({ routes }) => {
    const match = useRouteMatch();
    const crumbs = routes
        .filter(({ path }) => match.path.includes(path))
        .map(({ path, ...rest }) => ({
            path: Object.keys(match.params).length
                ? Object.keys(match.params).reduce(
                    (pathAcc, param) => pathAcc.replace(`:${param}`, match.params[param]),
                    path,
                )
                : path,
            ...rest,
        }));

    return (crumbs.length > 1) && <div className='breadcrumbsContainer'>
        {crumbs.map(({ name, path }, idx) => ((idx + 1 === crumbs.length)
            ? <p key={`breadcrumbCurrentPage-${name}`} className='breadcrumbCurrentPage body'>{name}</p>
            : <React.Fragment key={`breadcrumbLink-${name}`}>
                <Link key={`breadcrumbLink-${name}`} className='breadcrumbLink body' to={path}>{name}</Link>
                <p key={`breadcrumbDivider-${idx}`} className='breadcrumbDivider body'>{'>'}</p>
            </React.Fragment>))}
    </div>;
};

Breadcrumbs.propTypes = {
    routes: Proptypes.array,
};

export default Breadcrumbs;
