import React, { useState, useEffect, useContext } from 'react';
import { format, parse } from 'date-fns';
import CalendarFeature from '../../features/Calendar';
import ViewEventPanel from '../../features/ViewEventPanel';
import NewMeetingInviteModal from '../../features/NewMeetingInviteModal';
import WorknotesModal from '../../features/WorknotesModal';
import AuthContext from '../../context/AuthContext';
import userManagementAPIs from '../../services/user-management.service';
// import projectManagementAPIs from '../../services/project-management.service';
// import { projectTypes } from '../../config/constants';
import Toast from '../../components/Alerts/Toast/Toast';
import helperFunctions from '../../utils/helperFunctions';
import { accountTypes } from '../../config/constants';

const Calendar = () => {
    const [viewEventPanelOpen, setViewEventPanelOpen] = useState(false);
    // const [projects, setProjects] = useState([]);
    // const [additionalAttendees, setAdditionalAttendees] = useState([]);
    const [newMeetingEventPanelOpen, setNewMeetingEventPanelOpen] = useState(false);
    const [userEvents, setUserEvents] = useState([]);
    const [viewEventData, setViewEventData] = useState({});
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [date, setDate] = useState('');
    const [showWorknotes, setShowWorkNote] = useState(false);
    const closeWorknoteModal = () => setShowWorkNote(false);
    const [workNoteAddMode, changeAddMode] = useState('');
    const authContext = useContext(AuthContext);


    const getPrettyType = (_type) => {
        if (_type) return helperFunctions.camel2title(_type);
        return '';
    };

    const formatEvents = (events) => {
        const formattedEvents = [];
        if (events) {
            events.forEach((item) => {
                formattedEvents.push({
                    ...item,
                    startDate: item.start,
                    endDate: item.end,
                    start: parse(item.eventType === 'start' ? item.start : item.end, 'dd-MM-yyyy', new Date()),
                    end: parse(item.eventType === 'start' ? item.start : item.end, 'dd-MM-yyyy', new Date()),
                    title: item.eventType === 'start' ? `${getPrettyType(item.type.toLowerCase())} Created - ${item.title}` : `${getPrettyType(item.type.toLowerCase())} Closing - ${item.title}`,
                    name: item.title,
                });
            });
        }
        return formattedEvents;
    };

    useEffect(() => {
        const getEvents = async () => {
            const response = await userManagementAPIs.getUserProjectEvents();
            if (response.status === 200) {
                let responseData = Object.values(response.data);
                if (authContext.user?.accountType?.toLowerCase() === accountTypes.supplier) {
                    responseData = responseData.filter((evt) => evt.type !== 'PROJECT');
                }
                setUserEvents(formatEvents(responseData));
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        };

        // const getProjects = async () => {
        //     const response = await projectManagementAPIs.getAllProjectsByIDandType(
        //         authContext.user.accountId, projectTypes.prf,
        //     );
        //     if (response.status === 200) {
        //         setProjects(response.data);
        //     } else {
        //         Toast.fire({
        //             icon: 'error',
        //             titleText: 'Unable to retrieve information.',
        //         });
        //     }
        // };

        // const getAdditionalAttendees = async () => {
        //     const additionalList = [];
        //     const response = await userManagementAPIs.getAllAccountUsers(
        //         authContext.user.accountId,
        //     );
        //     response.data.forEach((user) => {
        //         additionalList.push({
        //             name: `${user.contactDetails.firstname} ${user.contactDetails.surname}`,
        //             value: `${user.userID}`,
        //         });
        //     });
        //     setAdditionalAttendees(additionalList);
        // };
        if (authContext.authenticated) {
            getEvents();
            // getAdditionalAttendees();
            // getProjects();
        }
    }, [authContext]);

    const handleViewEvent = (evt) => {
        setViewEventData(evt);
        setViewEventPanelOpen(!viewEventPanelOpen);
    };

    const handleMeetingEvent = ({ start, end }) => {
        if (start >= new Date()) {
            setDate(start);
            setStartTime(format(start, 'HH:mm'));
            setEndTime(format(end, 'HH:mm'));
            // Temporarily disabled New Meeting Func
            // setNewMeetingEventPanelOpen(!newMeetingEventPanelOpen);
        }
    };

    const linkWorknote = async () => {
        const newListWorknotes = viewEventData.worknotes;
        const updateRequest = {
            ...viewEventData,
            worknotes: newListWorknotes,
        };
        const response = await userManagementAPIs.updateEvent(updateRequest);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Worknote added successfully.',
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to add worknote.',
            });
        }
    };

    return <>
        <CalendarFeature
            eventsList={userEvents}
            onSelectEvent={handleViewEvent}
            onSelectSlot={handleMeetingEvent}
        />
        <ViewEventPanel
            id='vePanel'
            userID={authContext.user.id}
            open={viewEventPanelOpen}
            // viewButton={placeholderOpenView}
            viewEventData={viewEventData}
            closePanel={() => setViewEventPanelOpen(!viewEventPanelOpen)}
            footerBtnsDisabled={viewEventData.completeEvent
                || viewEventData.rejectEvent || viewEventData.approveEvent || false}
            handleWorknotes={() => {
                changeAddMode('add');
                setShowWorkNote(true);
            }}
            handleViewWorknotes={() => {
                setShowWorkNote(true);
                changeAddMode('');
            }}
            handleCompleteEvent={() => {
                setViewEventData({
                    ...viewEventData,
                    completeEvent: true,
                });
            }}
        />
        <NewMeetingInviteModal
            userID={authContext.user.id}
            open={newMeetingEventPanelOpen}
            closeModal={() => setNewMeetingEventPanelOpen(!newMeetingEventPanelOpen)}
            // projects={projects}
            start={startTime}
            end={endTime}
            meetingDate={date}
            // additionalAttendees={additionalAttendees}
        />
        {showWorknotes
        && <WorknotesModal
            closeModal={closeWorknoteModal}
            mode={workNoteAddMode}
            worknotesInput={viewEventData.worknotes}
            handleCallback={linkWorknote}
        />}
    </>;
};

export default Calendar;
