import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DocumentBuilder from '../../features/DocumentBuilder';
import documentManagementAPIs from '../../services/document-management.service';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';

const EditSection = () => {
    const { sectionID } = useParams();
    const history = useHistory();
    const [type, setType] = useState('');
    const [initialState, setInitialState] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const responseData = await documentManagementAPIs.getSection(sectionID);
            if (responseData.status === 200) {
                const preparedData = helperFunctions
                    .prepareDocumentBuilderState('section', responseData.data, sectionID);
                setInitialState(preparedData);
                setType(preparedData.details.type);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        };
        // TODO: if (templateId && authContext.user.isAuthenticated) {
        if (sectionID) {
            fetchData();
        }
    }, [sectionID]);

    const constructAndPostSectionData = async (documentState) => {
        const sectionState = documentState.sections[0];
        const questionsInSection = [];
        sectionState.sectionItems?.forEach((question) => {
            const questionToAdd = {
                ...question,
                attachments: question.attachments ? question.attachments : [],
            };
            questionsInSection.push(questionToAdd);
        });

        const postBody = {
            title: sectionState.title,
            description: sectionState.description,
            templateType: documentState.details.templateType,
            questions: questionsInSection,
        };

        const responseData = await documentManagementAPIs.updateSection(sectionID, postBody);
        if (responseData.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Questionnaire section updated successfully.',
            });
            history.push('/sections');
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update questionnaire section.',
            });
        }
    };

    return Object.keys(initialState).length > 0 ? <DocumentBuilder
        documentMode={'Edit'}
        context='Section'
        type={type}
        initialDocumentState={initialState}
        sectionLabel='Section'
        sectionItemLabel='Question'
        allowSectionReordering={false}
        allowSectionItemsReordering={false}
        sectionDetailsUserList={[]}
        handlePrimaryToolbarSubmitButton={constructAndPostSectionData}
    /> : <></>;
};

export default EditSection;
