import { v4 as uuidv4 } from 'uuid';
import { parseISO, isPast } from 'date-fns';

import {
    responseStatus, responseStatuses, responseStatusColour, dpsLabels, publishAtLevel,
    view,
    edit,
    viewQuestionnaireUrl,
    TOTAL_SCORE_TITLE,
} from '../config/constants';

const responseStatusValues = {
    colors: [
        responseStatuses[0].colour,
        responseStatuses[1].colour,
        responseStatuses[2].colour,
        responseStatuses[2].colour],
    labels: [
        responseStatuses[0].label,
        responseStatuses[1].label,
        responseStatuses[2].label,
        responseStatuses[2].label],
};

const getResponseStatusValues = (status, values) => ({
    [responseStatus.notStarted]: values[0],
    [responseStatus.inProgress]: values[1],
    [responseStatus.submitted]: values[2],
    [responseStatus.bidSubmitted]: values[3],
}[status || '']);


const getResponseStatusColor = (status, requiresUpdate, awaitingSupplierUpdate) => {
    if (requiresUpdate || awaitingSupplierUpdate) return responseStatusColour.amber;
    return getResponseStatusValues(status, responseStatusValues.colors);
};

const getResponseStatusLabel = (status, requiresUpdate, awaitingSupplierUpdate) => {
    if (requiresUpdate || awaitingSupplierUpdate) return responseStatus.updated;
    return getResponseStatusValues(status, responseStatusValues.labels);
};

const getLotOrProject = (publishLevel) => (publishLevel === publishAtLevel.lot
    ? dpsLabels.lotLabel : dpsLabels.projectLabel);

const isButtonDisabled = (response, data, questionnaireAwaitingRefresh) => (
    questionnaireAwaitingRefresh.length > 0
            && !(response.responseStatus === responseStatus.notStarted
            || isPast(parseISO(data?.submissionEndDate))
            || data?.bidStatus === responseStatus.bidSubmitted
            || data?.projectStatus === responseStatus.cancelled));

const getButtonLabel = (response, data) => (
    response.responseStatus === responseStatus.notStarted
                        || isPast(parseISO(data.submissionEndDate))
                        || data.bidStatus === responseStatus.bidSubmitted
                        || data.projectStatus === responseStatus.cancelled
                        || data.awardApproval === responseStatus.approved
        ? view : edit
);

const getMenuWithWeightage = (response) => {
    if (!response?.weightage) return {};
    return {
        id: uuidv4(),
        type: 'textLabel',
        labelTxt: `${TOTAL_SCORE_TITLE}: ${response.score}/${response.weightage}`,
    };
};

const getResponsesMenu = (initialData, response, questionnaireAwaitingRefresh) => [
    getMenuWithWeightage(response, initialData),
    {
        id: uuidv4(),
        type: 'button',
        size: 'regular',
        variant: 'primary',
        additionalVariant: 'btnSize',
        disabled: isButtonDisabled(response, initialData, questionnaireAwaitingRefresh),
        label: getButtonLabel(response, initialData),
        onClick: `${viewQuestionnaireUrl}${response.responseID}`,
        onClickOptions: {
            deadlinePassed: true,
        },
    },
];

const hasQuestionsAssigned = (response) => response?.questionsAssigned ?? true;

export {
    getResponseStatusColor,
    getResponseStatusLabel,
    getLotOrProject,
    getResponsesMenu,
    hasQuestionsAssigned,
};
