import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import TreeViewer from '../../../components/TreeViewer/TreeViewer';
import {
    getCurrentSubmission,
} from '../helpers';

const SupplierCurrentSubmission = ({
    awardInfo,
    supplier,
    readOnly,
    index,
    userPermissions,
    handleActionClick,
}) => {
    const [supplierCurrentSubmission, setSupplierCurrentSubmission] = useState();

    useEffect(() => {
        if (!supplier || !supplier.currentSubmission) {
            return;
        }
        setSupplierCurrentSubmission(getCurrentSubmission(
            readOnly, supplier, index, userPermissions, awardInfo,
        ));
    }, [supplier]);

    return (
        <>
            {supplierCurrentSubmission
            && <TreeViewer
                key = {uuidv4()}
                id = {'tree-current-submission'}
                classAdded='award-summary-tree-submissions'
                data={supplierCurrentSubmission}
                hasSearch={false}
                handleActionClick={handleActionClick}/>
            }
        </>
    );
};

SupplierCurrentSubmission.propTypes = {
    awardInfo: PropTypes.object,
    supplier: PropTypes.object,
    readOnly: PropTypes.bool,
    userSteeringRole: PropTypes.array,
    index: PropTypes.number,
    userPermissions: PropTypes.object,
    handleActionClick: PropTypes.func,
};

export default SupplierCurrentSubmission;
