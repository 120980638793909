import React from 'react';
import PropTypes from 'prop-types';
import addIcon from '../../../styles/images/addEllipseIcon.svg';
import Button from '../../../components/Button';

const RegistrationHeader = ({ addAction, headingLabel }) => <>
    <section className='registration-section-header'>
        <h4>{headingLabel}</h4>
        {/* <div className='add-item' onClick={addAction}>
            <img src={addIcon} />
            <span className='title large'>Add</span>
        </div> */}
        <div className='add-item'>
            <Button id='dashbaordActionBtn'
                type='button'
                variant={'secondary'}
                additionalVariant={'skinless align-right'}
                icon={true}
                iconSrc={addIcon}
                label={'Add'}
                handleClick={addAction} /></div>
    </section>
</>;

RegistrationHeader.propTypes = {
    headingLabel: PropTypes.string.isRequired,
    addAction: PropTypes.func.isRequired,
};

export default RegistrationHeader;
