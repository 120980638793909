const Constants = {
    MENU_OPTIONS: {
        BUTTON: 'button',
        STATUS_LABEL: 'statusLabel',
        TEXT_LABEL: 'textLabel',
        LINK: 'link',
    },
    TYPE_AND_SEARCH: 'Type and search',
    EXPAND: 'expand',
    CLICK_TYPE: {
        EVENT: 'event',
        URL: 'url',
    },
};
export default Constants;
