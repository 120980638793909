import React from 'react';
import PropTypes from 'prop-types';
import Upload from '../../../../features/Upload';
import DashboardCard from '../../../../components/DashboardCard';

const ContractDocuments = ({
    contractDetails,
    title,
    isSupplier,
    handleUpdate,
    handleDelete,
    readOnly,
}) => {
    const updateContractDocs = async (data, contract) => {
        const documents = data.map((doc) => ({ documentID: doc.documentID }));

        const payload = { ...contract };

        if (payload.suppliers) {
            Object.keys(payload.suppliers).forEach((key) => {
                // eslint-disable-next-line no-param-reassign
                payload.suppliers[key] = documents;
            });
        }

        if (payload.lots) {
            Object.keys(payload.lots).forEach((key) => {
                // eslint-disable-next-line no-param-reassign
                payload.lots[key] = documents;
            });
        }

        delete payload.type;

        handleUpdate(payload);
    };

    const deleteContractDoc = async (data) => {
        handleDelete(data.documentID);
    };

    return <>
        {isSupplier === false
            ? <DashboardCard
                id='contractDocuments'
                size='small'
                header={true}
                headerTitle={title}
                content={contractDetails?.awardedSuppliers
                && contractDetails.awardedSuppliers.length > 0
                    ? <div className='documentContainer'>
                        <Upload
                            title='Documents'
                            allowMultiple={true}
                            uploadIdentifier='document'
                            uploadCallback={updateContractDocs}
                            deleteCallback={deleteContractDoc}
                            uploadedDocuments={contractDetails.contractDocs}
                            audienceSelectionType='contract'
                            data={contractDetails}
                            readOnly={readOnly}
                        />
                    </div>
                    : <p className='body'>No awarded suppliers. There must be at least one awarded supplier before contract documents can be uploaded.</p>
                }
                footer={false}
            />
            : <DashboardCard
                id='contractDocuments'
                size='small'
                header={true}
                headerTitle={title}
                content={
                    <div className='documentContainer'>
                        <Upload
                            title='Documents'
                            allowMultiple={true}
                            uploadIdentifier='document'
                            uploadedDocuments={contractDetails.contractDocs}
                            audienceSelectionType='contract'
                            data={contractDetails}
                            readOnly={true}
                        />
                    </div>}
                footer={false}
            />
        }
    </>;
};

ContractDocuments.propTypes = {
    contractDetails: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    isSupplier: PropTypes.bool.isRequired,
    handleUpdate: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

export default ContractDocuments;
