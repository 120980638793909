const Constants = {
    SEARCH_FOR_A_CALL_OFF: 'Search for a Call Off',
    sortOptions: [
        null,
        { label: 'Contract Start Date (newest)', value: 'contractStart-descending-date' },
        { label: 'Name (ascending)', value: 'title-ascending-string' },
        { label: 'Name (descending)', value: 'title-descending-string' },
    ],
    tabOptions: [
        { label: 'My Call Offs', value: 'createdBy', url: 'createdBy' },
        { label: 'My Department', value: 'department', url: 'department' },
        { label: 'My Organisation', value: 'all', url: 'organisation' },
    ],
};

export default Constants;
