/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import SelectSearch, { fuzzySearch } from 'react-select-search';
import Form from '../../../../components/Form';
import Modal from '../../../../components/Modal';
import LabelledInput, { LabelledInputTypeNumber, LabelledInputTypeText } from '../../../../components/LabelledInput';
import LabelledTextarea from '../../../../components/LabelledTextArea';
import LabelledRadioButton from '../../../../components/LabelledRadioButton';
import { MAX_CHAR_COUNT_30000 } from '../../../../config/constants';

const SectionDetailsModal = ({
    mode,
    context,
    sectionLabel,
    sectionNumber,
    initialData,
    evaluators,
    handleResult,
    closeModal,
}) => {
    const [sectionDetails, setSectionDetails] = useState(initialData);

    const generateBody = () => {
        switch (context.toLowerCase()) {
            case 'questionnaire':
            case 'section':
                return <>
                    <LabelledInput
                        id='title'
                        type={LabelledInputTypeText}
                        label='Title'
                        placeholder='Enter title'
                        value={sectionDetails.title || ''}
                        onChange={(event) => setSectionDetails({
                            ...sectionDetails,
                            title: event.target.value,
                        })}
                        breakColumn={false}
                        required={true} />
                    <div className='formElementsDivider'></div>
                    <LabelledTextarea
                        id='description'
                        label='Enter description'
                        onChange={(event) => setSectionDetails({
                            ...sectionDetails,
                            description: event.target.value,
                        })}
                        value={sectionDetails.description || ''}
                        placeholder='Enter description'
                        maxCharCount={MAX_CHAR_COUNT_30000}
                        currentCharCount={sectionDetails.description
                            ? sectionDetails.description.length : 0}
                        breakColumn={false}
                        required={true}
                        richText={true}
                    />
                    <div className='formElementsDivider'></div>
                    {/* <div id='sectionWeightageInputContainer'>
                        <LabelledInput
                            id='sectionWeightage'
                            type={LabelledInputTypeNumber}
                            label='Enter section weightage'
                            placeholder='Enter weightage number'
                            value={sectionDetails.sectionWeightage || ''}
                            onChange={(event) => setSectionDetails({
                                ...sectionDetails,
                                sectionWeightage: event.target.value,
                            })}
                            breakColumn={false}
                            maxNumber={100}
                            required={true} />
                        <div id='sectionWeightageOutOfLabel'>
                            <p className='title-large'>{'\u00A0/\u00A0100'}</p></div>
                    </div> */}
                    <div className='formElementsDivider'></div>
                    {/** TODO: replace 'name' with user first name and last name after BE update
                    <label className='form-label title' id='selectedEvaluators-input-label'
                    htmlFor='selectedEvaluators-input'>
                    Select evaluator(s)<span className='caption'> (Optional)</span></label>
                    <SelectSearch
                        id='selectedEvaluators-input'
                        closeOnSelect={false}
                        printOptions='on-focus'
                        options={evaluators.map((evalutor) => ({
                            value: evalutor,
                            name: evalutor,
                        }))}
                        multiple
                        search
                        filterOptions={fuzzySearch}
                        placeholder='Select evaluator(s) (optional)'
                        value={sectionDetails.selectedEvaluators || []}
                        onChange={(event) => setSectionDetails({
                            ...sectionDetails,
                            selectedEvaluators: event,
                        })}
                    />
                    <div className='formElementsDivider'></div> */}
                </>;
            case 'workflow':
            case 'stage':
                return <>
                    <LabelledInput
                        id='name'
                        type={LabelledInputTypeText}
                        label='Title'
                        placeholder='Enter title'
                        value={sectionDetails.name || ''}
                        onChange={(event) => setSectionDetails({
                            ...sectionDetails,
                            name: event.target.value,
                        })}
                        breakColumn={false}
                        required={true}/>
                    <div className='formElementsDivider'></div>
                    <LabelledRadioButton
                        id='category'
                        label='Select category'
                        breakColumn={false}
                        onChange={(event) => setSectionDetails({
                            ...sectionDetails,
                            category: event.target.value,
                        })}
                        options={[{
                            label: 'Pre-Procurement',
                            value: 'pre-procurement',
                            id: 'stageCategoryPreProcurement',
                            name: 'stageCategory',
                            checked: sectionDetails.category
                                ? sectionDetails.category === 'pre-procurement'
                                : false,
                            required: true,
                        }, {
                            label: 'e-Tendering',
                            value: 'e-tendering',
                            id: 'stageCategoryETendering',
                            name: 'stageCategory',
                            checked: sectionDetails.category
                                ? sectionDetails.category === 'e-tendering'
                                : false,
                            required: true,
                        }, {
                            label: 'Contract Management',
                            value: 'contract-management',
                            id: 'stageCategoryContractManagement',
                            name: 'stageCategory',
                            checked: sectionDetails.category
                                ? sectionDetails.category === 'contract-management'
                                : false,
                            required: true,
                        }]}
                        renderAsRow={false} />
                </>;

            default:
                return <></>;
        }
    };

    return <Form id={`${sectionLabel.toLowerCase()}DetailsForm`}
        onSubmit={(e) => {
            e.preventDefault();
            setSectionDetails({});
            handleResult(sectionDetails, mode, sectionNumber);
        }}>
        <Modal
            open={true}
            size='medium'
            closeModal={closeModal}
            headerTitle={`${mode} ${sectionLabel}`}
            secondActionButton={true}
            secondActionButtonTxt='Cancel'
            handleSecondaryActionBtnClick={closeModal}
            handleMainActionBtnClick={() => null}
            mainActionButtonTxt={(mode === 'Add' ? 'Add' : 'Update')}
            closeButton={true}
            body={generateBody()}
            helpOption={false}
        />
    </Form>;
};

SectionDetailsModal.propTypes = {
    mode: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    sectionLabel: PropTypes.string.isRequired,
    sectionNumber: PropTypes.number,
    initialData: PropTypes.object,
    evaluators: PropTypes.array,
    handleResult: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default SectionDetailsModal;
