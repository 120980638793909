import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Constants from './constants';
import BrowseData from '../../features/BrowseData';
import projectManagementAPIs from '../../services/project-management.service';
import AuthContext from '../../context/AuthContext';
import Toast from '../../components/Alerts/Toast/Toast';

const BrowseProcurementRequests = () => {
    const history = useHistory();
    const [requests, setRequests] = useState([]);
    const authContext = useContext(AuthContext);
    const { accountId } = authContext.user;

    const fetchRequests = async () => {
        try {
            const responseData = await projectManagementAPIs.listPRF();
            if (responseData.status === 200) {
                setRequests(responseData.data.items);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (err) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchRequests();
    }, [accountId]);


    return <BrowseData
        id='browseProcurementRequests'
        context='Requests'
        contextSingular='Request'
        dataSet={requests}
        searchPlaceholder='Search for a request'
        searchFilterAttribute='title'
        sortOptions={Constants.sortOptions}
        allowAddNew={true}
        labelAddNew='Add'
        handleAddNew={() => history.push('/pre-procurement-request')}
    />;
};

export default BrowseProcurementRequests;
