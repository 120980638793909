const Constants = {
    DISQUALIFY_TITLE: 'Disqualify Supplier(s)',
    DISQUALIFY_BODY: 'You are disqualifying the following supplier from this opportunity. If they have multiple submissions, they will be marked as unsuccessful in all of them.',
    DISQUALIFY_REASON: 'Reason for disqualification',
    ENTER_REASON: 'Enter reason',
    CONFIRM: 'Confirm',
    CANCEL: 'Cancel',
    SUPPLIER: 'Supplier',
};

export default Constants;
