import React from 'react';
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import withReactContent from 'sweetalert2-react-content';
import closeBtn from '../../../styles/images/closeButton.svg';

const confirmSwal = withReactContent(Swal);

const Confirm = (successCb, confirmAttrs, callbackAttrs = undefined) => confirmSwal.fire({
    title: confirmAttrs.title || 'Are you sure?',
    text: confirmAttrs.subTitle || '',
    showCancelButton: true,
    confirmButtonColor: '#2c4874',
    cancelButtonColor: '#ce0000',
    confirmButtonText: confirmAttrs.confirmBtnText || 'Yes',
    input: confirmAttrs.input || null,
    inputOptions: confirmAttrs.inputOptions || null,
    inputValidator: confirmAttrs.inputValidator || null,
    inputLabel: confirmAttrs.inputLabel || null,
    inputPlaceholder: confirmAttrs.inputPlaceholder || null,
    inputAttributes: {
        'aria-label': 'Type your reason here',
    },
    reverseButtons: true,
    focusConfirm: false,
    showCloseButton: true,
    closeButtonHtml: <img src={closeBtn} className='modal-header-close-btn-img' alt='modal-close-button'></img>,
    showClass: {
        backdrop: 'swal2-noanimation', // disable backdrop animation
        popup: '', // disable popup animation
        icon: '', // disable icon animation
    },
    hideClass: {
        popup: '', // disable popup fade-out animation
    },
}).then((result) => {
    if (result.isConfirmed) {
        if (callbackAttrs !== undefined) {
            successCb(callbackAttrs);
        } else if (result.value !== undefined) {
            successCb(result.value);
        } else {
            successCb();
        }
    }
});

export default Confirm;
