import React, {
    useContext, useEffect, useState, useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import BrowseData from '../../features/BrowseData';
import Constants from './constants';
import projectManagementAPIs from '../../services/project-management.service';
import AuthContext from '../../context/AuthContext';
import rbac from '../../rbac';
import Toast from '../../components/Alerts/Toast/Toast';
import ToastConstants from '../../components/Alerts/Toast/constants';

const BrowseProjects = () => {
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [selectedTab, setSelectedTab] = useState();
    const tabsRefs = useRef(Constants.tabOptions.map(() => undefined));


    const handleProjectsByTab = (tabLabel) => {
        setSelectedTab(Constants.tabOptions.find((tab) => tab.label === tabLabel).value);
    };

    const getTabs = () => {
        const tabs = Constants.tabOptions;
        return tabs.map((item) => item.label);
    };
    const getTabIndex = (tabValue) => Constants.tabOptions
        .map((item) => item.value).indexOf(tabValue);

    const getTabUrl = (tabValue) => Constants.tabOptions
        .find((item) => item.value === tabValue).url;

    const fetchData = async (tab) => {
        try {
            const response = await projectManagementAPIs
                .listProjects(getTabUrl(tab));
            if (response.status === 200) {
                tabsRefs.current[getTabIndex(tab)] = response.data.items;
                setFilteredProjects(tabsRefs.current[getTabIndex(tab)]);
            } else {
                Toast.fire({
                    icon: ToastConstants.error,
                    titleText: Constants.UNABLE_TO_RETRIEVE_PROJECTS,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: ToastConstants.error,
                titleText: Constants.UNABLE_TO_RETRIEVE_PROJECTS,
            });
        }
    };

    useEffect(() => {
        fetchData(Constants.tabOptions[0].value);
    }, []);

    useEffect(() => {
        if (!selectedTab) {
            return;
        }
        if (selectedTab && !tabsRefs.current[getTabIndex(selectedTab)]) {
            fetchData(selectedTab);
            return;
        }
        setFilteredProjects(tabsRefs.current[getTabIndex(selectedTab)]);
    }, [selectedTab]);

    return <BrowseData
        id='browseProjects'
        context='projects'
        contextSingular='Project'
        dataSet={filteredProjects}
        searchPlaceholder= {Constants.SEARCH_FOR_A_PROJECT}
        searchFilterAttribute='title'
        sortOptions={Constants.sortOptions}
        tabOptions={getTabs()}
        handleTabSelected={handleProjectsByTab}
        allowAddNew={rbac.can(rbac.action.createProject,
            [user.accountType?.toLowerCase() + user?.role])}
        labelAddNew={Constants.ADD}
        handleAddNew={() => history.push('/project/create')}
        selectedTab={selectedTab}
    />;
};

export default BrowseProjects;
