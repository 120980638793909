import ToastConstants from '../components/Alerts/Toast/constants';
import Toast from '../components/Alerts/Toast/Toast';
import {
    RETURN_TO_URL, ToastErrorMessages, LOGIN_PATH, QUERY_URL_PARAM,
} from '../config/constants';

const redirectToLoginURL = (returnUrl, history, showExpiredSessionToast = true, queryUrl) => {
    const redirectUrl = new URL(LOGIN_PATH, document.location.origin);

    if (returnUrl) {
        redirectUrl.searchParams.append(RETURN_TO_URL, returnUrl);
    }

    if (queryUrl) {
        redirectUrl.searchParams.append(QUERY_URL_PARAM, queryUrl);
    }

    const redirectUrlStr = [redirectUrl.pathname, redirectUrl.search].join('');

    if (showExpiredSessionToast) {
        Toast.fire({
            icon: ToastConstants.error,
            title: ToastErrorMessages.SESSION_EXPIRED,
        });
    }

    // History doesn't exist on first load?
    if (history) {
        history.push(redirectUrlStr);
    } else {
        document.location = redirectUrlStr;
    }
};

export default redirectToLoginURL;
