import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DashboardCard from '../../../../components/DashboardCard';
import editIcon from '../../../../styles/images/edit.png';
import Events from './components/Events';

const WorkFlowEvents = ({
    eventDetails,
    handleEditWorkflow,
    handleUpdateEvent,
    handleUploadDocument,
    handleDeleteDocument,
    handleAddWorkNotes,
    handleExtentContract,
    handleCreateVariation,
    handleEscalateEvent,
    updateCompleteEventContract,
    canEditWorkflow = false,
    contractId,
    awardedSupplierData = [],
    readOnly,
    userSteeringRoles,
}) => {
    const [events, setEvents] = useState([]);
    useEffect(() => {
        const allEvents = [];
        eventDetails?.stages?.map((stage) => {
            allEvents.push(...stage.events);
            return null;
        });
        setEvents(allEvents);
    }, [eventDetails]);

    const generateEventsSection = () => (eventDetails
        ? <Events
            events={events}
            projectId={eventDetails?.projectID}
            contractId={contractId}
            handleUpdateEvent={handleUpdateEvent}
            handleUploadDocument={handleUploadDocument}
            handleDeleteDocument={handleDeleteDocument}
            handleAddWorkNotes={handleAddWorkNotes}
            handleExtentContract={handleExtentContract}
            handleCreateVariation={handleCreateVariation}
            handleEscalateEvent={handleEscalateEvent}
            updateCompleteEventContract={updateCompleteEventContract}
            awardedSupplierData={awardedSupplierData}
            readOnly={readOnly}
            userSteeringRoles={userSteeringRoles}
        />
        : <div className='noWorkflowSelected'>
            <p className='title'>No workflow selected for this project.</p>
        </div>);

    return (
        <DashboardCard
            id={eventDetails ? 'workflowEvent' : 'noWorkflowEvent'}
            size='medium'
            header={true}
            headerTitle={'Workflow'}
            caption={eventDetails?.workflowName}
            actionBtn={canEditWorkflow}
            actionBtnText={canEditWorkflow ? 'Edit Workflow' : ''}
            handlerActionBtn={canEditWorkflow ? handleEditWorkflow : () => null }
            variant={canEditWorkflow ? 'secondary' : ''}
            additionalVariant={canEditWorkflow ? 'skinless' : ''}
            content={generateEventsSection()}
            footer={false}
            icon={canEditWorkflow}
            iconSrc={canEditWorkflow ? editIcon : ''}
        />
    );
};

WorkFlowEvents.propTypes = {
    eventDetails: PropTypes.object,
    steeringInfo: PropTypes.object,
    handleEditWorkflow: PropTypes.func,
    handleUpdateEvent: PropTypes.func,
    handleUploadDocument: PropTypes.func,
    handleDeleteDocument: PropTypes.func,
    handleAddWorkNotes: PropTypes.func,
    handleExtentContract: PropTypes.func,
    handleCreateVariation: PropTypes.func,
    handleEscalateEvent: PropTypes.func,
    updateCompleteEventContract: PropTypes.func,
    canEditWorkflow: PropTypes.bool,
    contractId: PropTypes.string,
    awardedSupplierData: PropTypes.array,
    readOnly: PropTypes.bool,
    userSteeringRoles: PropTypes.array,
};

export default WorkFlowEvents;
