import React from 'react';
import TreeMenu from 'react-simple-tree-menu';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import ListItem from './ListItem';
import './styles.scss';
import Constants from './constants';

const TreeViewer = ({
    data, hasSearch = false, initialOpenNodes = [], classAdded,
    handleActionClick, handleStatusNodeOnClick,
}) => {
    const handleSearch = (e, search) => {
        search(e.target.value);
    };

    const classItem = `tree-viewer-container ${classAdded || ''}`;
    return (
        <section className={classItem}>
            <TreeMenu hasSearch={false} data={data} initialOpenNodes={initialOpenNodes}>
                {({ search, items }) => (
                    <>
                        {hasSearch && <input onChange={(e) => handleSearch(e, search)}
                            placeholder= {Constants.TYPE_AND_SEARCH} />}
                        {items.map((props) => (
                            <ListItem key={uuidv4()}
                                {...props}
                                handleActionClick={handleActionClick}
                                handleStatusNodeOnClick = {handleStatusNodeOnClick}
                            />
                        ))}
                    </>
                )}
            </TreeMenu>
        </section>
    );
};

TreeViewer.propTypes = {
    data: PropTypes.array.isRequired,
    hasSearch: PropTypes.bool,
    initialOpenNodes: PropTypes.object,
    classAdded: PropTypes.string,
    handleActionClick: PropTypes.func,
    handleStatusNodeOnClick: PropTypes.func,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};


export default TreeViewer;
