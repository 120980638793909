import React from 'react';
import PropTypes from 'prop-types';

import LabelledSelect from '../../../components/LabelledSelect';

const SelectComponent = ({
    id,
    label,
    value,
    placeholder,
    options = {},
    ...props
}) => {
    const buildOptions = (opts) => (
        <>
            <option
                key='option-type-default'
                selected={true}
                disabled={true}
                value={''}>
                    Please select
            </option>

            {Object.keys(opts).map((key, i) => (
                <option
                    key={`${key}-type-${i}`}
                    value={key}>
                    {opts[key]}
                </option>
            ))}
        </>
    );

    return (
        <div className='vpnItem'>
            <LabelledSelect
                id={id}
                label={label}
                renderAsRow={true}
                placeholder={placeholder}
                value={value}
                // onChange={(e) => handleChange(e, el)}
                breakColumn={false}
                options={buildOptions(options)}
                { ...props }
            />
        </div>
    );
};

SelectComponent.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.object,
    pattern: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.string.isRequired,
};

export default SelectComponent;
