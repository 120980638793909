import React from 'react';
import propTypes from 'prop-types';
import { capitalize } from 'inflected';

import Button from '../../../components/Button';

function UploadItem({
    document, removeDocument, downloadFile, readOnly = false, showExpiryDate = true,
}) {
    return <section className='worknote-item'>
        <p className='title-small bold'>{document.filename}</p>
        <div className='worknote-item-row'>
            <div className='worknote-section'>
                {document.document?.uploadedByUser && <div className='sectionRow'>
                    <span className='title-small sectionLabel'>Uploaded By: </span>
                    <span className='title-small'>
                        {document.document?.uploadedByUser?.contactDetails?.username || 'Unknown'}
                    </span>
                </div>}
                {document.document?.lastModified && <div className='sectionRow'>
                    <span className='title-small sectionLabel'>Uploaded Date: </span>
                    <span className='title-small'>{document.document?.lastModified}</span>
                </div>}
                {showExpiryDate && document.document?.expiryDate && <div className='sectionRow'>
                    <span className='title-small sectionLabel'>Expiry Date: </span>
                    <span className='title-small'>{document.document?.expiryDate}</span>
                </div>}
                {document.resourceType && <span className='title-small'>{capitalize(document.resourceType)}</span>}
            </div>
            <div className='buttonContainer'>
                {<Button
                    id='downloadDocument'
                    label='Download'
                    size='small'
                    variant='primary'
                    handleClick={(e) => {
                        e.preventDefault();
                        downloadFile(document);
                    }}
                />}
                <div className='buttonDivider'></div>
                {!readOnly && <Button
                    id='deleteDocument'
                    label='Delete'
                    size='small'
                    variant='secondary'
                    handleClick={(e) => {
                        e.preventDefault();
                        removeDocument(document);
                    }}
                />}
            </div>
        </div>

        <div className='worknote-item-divider'></div>
    </section>;
}
UploadItem.propTypes = {
    document: propTypes.oneOfType([
        propTypes.array,
        propTypes.object,
    ]).isRequired,
    removeDocument: propTypes.func,
    downloadFile: propTypes.func.isRequired,
    readOnly: propTypes.bool,
    showExpiryDate: propTypes.bool,
};
export default UploadItem;
