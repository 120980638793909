import React from 'react';
import PropTypes from 'prop-types';

import { humanize } from 'inflected';

import './ErrorMessagesFor.scss';

const formatErrorMessage = (errorMessage, origin) => {
    // Handle JSON schema validation errors
    if (errorMessage.stack) {
        return humanize(errorMessage.stack);
    }

    // Handle notice property validation errors
    if (errorMessage.property) {
        return humanize(errorMessage.message || errorMessage.messages);
    }

    // Handle generic notice errors from FTS
    if (origin === 'FTS') {
        return humanize(errorMessage);
    }

    // Handle generic notice errors
    return humanize(errorMessage.message);
};

const ErrorMessagesFor = React.forwardRef(({ errorMessages = [], origin = '' }, ref) => (
    <>
        {errorMessages?.length > 0
            && <div className='errorMessages' id='errorMessages' ref={ref}>
                <h3 className='title-large'>
                    There were errors processing this submission.
                </h3>
                <ul>
                    {Array.isArray(errorMessages) && errorMessages.map((errorMessage, i) => (
                        <li key={`errorMessage-${i}`} className='title'>
                            {formatErrorMessage(errorMessage, origin)}
                        </li>
                    ))}

                    {!Array.isArray(errorMessages)
                    && <li key='errorMessage-single' className='title'>
                        {errorMessages}
                    </li>
                    }
                </ul>
            </div>
        }
    </>
));

ErrorMessagesFor.displayName = 'ErrorMessagesFor';

ErrorMessagesFor.propTypes = {
    errorMessages: PropTypes.array,
    origin: PropTypes.string,
};

export default ErrorMessagesFor;
