import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';

const SupplierCard = ({
    id,
    header = true,
    caption,
    headerTitle,
    alertMessage,
    content,
    actionBtnText,
    handlerActionBtn,
    rtm,
}) => (<div className='supplierCard' id={id}>
    <section className='supplier-card-container'>
        <section className='itemHeaderContainer'>
            <div className='header'>
                {caption && <p className='caption-small'>{caption}</p>}
                {alertMessage && <p className='title-small projectTitle alertRed' title={alertMessage}>{alertMessage}</p>}
                {header && <p className='title-small projectTitle' title={headerTitle}>{headerTitle}</p>}
                {rtm && <p className='caption-small rtm'>{rtm}</p>}
            </div>
        </section>
        <div className='content'>
            {content}
        </div>
        {actionBtnText && <div className='btnFooter'>
            <Button
                id='actionBtn'
                variant='tertiary'
                label={actionBtnText}
                handleClick={handlerActionBtn} />
        </div>}
    </section>
</div >);

SupplierCard.propTypes = {
    id: PropTypes.string.isRequired,
    header: PropTypes.bool,
    headerTitle: PropTypes.string,
    alertMessage: PropTypes.string,
    caption: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    footer: PropTypes.bool,
    actionBtn: PropTypes.bool,
    actionBtnText: PropTypes.string,
    handlerActionBtn: PropTypes.func,
    rtm: PropTypes.string,
};

export default SupplierCard;
