import { format, parseISO } from 'date-fns';

// APIs
import projectManagementAPIs from '../../services/project-management.service';

// UI
import Toast from '../../components/Alerts/Toast/Toast';

import contractsFinderSchemas from './contractsFinderSchemas';
import ftsSchemas from './ftsSchemas';

const getProjectEvent = (projectStages, eventId) => {
    const stages = Object.values(projectStages);

    if (stages.length > 0) {
        for (let stage = 0; stage < stages.length; stage += 1) {
            for (let i = 0; i < stages[stage].events.length; i++) {
                const event = { ...stages[stage].events[i] };
                if (event.eventID === eventId) {
                    return event;
                }
            }
        }
    }

    return {};
};

const convertDate = (value) => {
    let date = value;

    if (typeof value === 'string') {
        date = parseISO(value);
    }

    return (value ? `${format(date, 'yyyy-MM-dd')}T00:00:00Z` : undefined);
};

const setRadioButtonValue = (data, truthy = 'yes', falsy = 'no', nullable = true) => {
    if (nullable) {
        switch (data) {
            case truthy:
                return true;
            case falsy:
                return false;
            default:
                return undefined;
        }
    }

    return data === truthy;
};

const hasRequiredReferenceType = (supplier) => (
    !([
        'COMPANIES_HOUSE',
        'CHARITY_COMMISSION',
        'SCOTTISH_CHARITY',
        'NI_CHARITY_COMMISSION',
    ].includes(supplier.referenceType))
);

const validateContractsFinderNotice = async (data) => {
    let valid = false;
    let errors = [];
    let schema = contractsFinderSchemas.contractNoticeSchema;

    const validateOptions = {
        abortEarly: false,
    };

    switch (data.type) {
        case 'Contract':
            schema = contractsFinderSchemas.awardNoticeSchema;
            break;
        case 'PreProcurement':
            schema = contractsFinderSchemas.preprocurementNoticeSchema;
            break;
        case 'Pipeline':
            schema = contractsFinderSchemas.futureOpportunityNoticeSchema;
            break;
        default:
            schema = contractsFinderSchemas.contractNoticeSchema;
            break;
    }

    try {
        await schema.validate(data, validateOptions);
        valid = true;
    } catch (e) {
        errors = e.errors.map((error) => ({ message: error }));
    }

    return {
        valid,
        errors,
    };
};

const validateFTSNotice = async (data) => {
    let valid = false;
    let errors = [];
    let schema = ftsSchemas.contractNoticeSchema;

    const validateOptions = {
        abortEarly: false,
    };

    switch (data.type) {
        case 'Contract':
            schema = ftsSchemas.awardNoticeSchema;
            break;
        case 'PreProcurement':
            schema = ftsSchemas.preprocurementNoticeSchema;
            break;
        case 'Pipeline':
            schema = ftsSchemas.futureOpportunityNoticeSchema;
            break;
        default:
            schema = ftsSchemas.contractNoticeSchema;
            break;
    }

    try {
        await schema.validate(data, validateOptions);
        valid = true;
    } catch (e) {
        errors = e.errors.map((error) => ({ message: error }));
    }

    return {
        valid,
        errors,
    };
};

const validateNotice = (data) => {
    switch (data.noticePublisher) {
        case 'CF':
            return validateContractsFinderNotice(data);
        case 'FTS':
            return validateFTSNotice(data);
        default:
            return validateContractsFinderNotice(data);
    }
};

const createContractsFinderNoticePayload = (data) => {
    const payload = {
        noticePublisher: data.noticePublisher,

        // identifier: data.project.projectReference,
        title: data.project.title,
        description: data.project.description,
        deadlineDate: convertDate(data.contract?.submissionDeadlineEndDate),
        start: convertDate(data.contract?.contractStart),
        end: convertDate(data.contract?.contractEnd),

        valueLow: (parseFloat(data.extra.valueLow, 10) || 0),
        valueHigh: (parseFloat(data.extra.valueHigh, 10) || 0),

        isSuitableForSme: setRadioButtonValue(data.extra.isSuitableForSme),
        isSuitableForVco: setRadioButtonValue(data.extra.isSuitableForVco),

        procedureType: data.extra.procedureType,
        ojeuContractType: data.extra.contractType,

        contactDetails: {
            name: data.extra.contactName,
            email: data.extra.contactEmail,
            address1: data.extra.contactAddress1,
            address2: data.extra.contactAddress2,
            town: data.extra.contactCity,
            country: data.extra.contactCountry,
            postcode: data.extra.contactPostcode,
            phone: data.extra.contactTelephone,
            webAddress: data.extra.contactWebsite,
        },

        region: data.extra.region?.join(','),
        cpvCodes: data.extra.cpvCodes,
    };

    switch (data.noticeEvent.noticeType) {
        case 'award':
            payload.type = 'Contract';
            payload.awards = data.suppliers.awardedSuppliers.map((supplier) => ({
                accountId: supplier.accountID,
                userId: supplier.userID,
                contact: supplier.contact,
                referenceType: supplier.referenceType,
                reference: supplier.referenceNo,
                supplierAwardedValue: (parseFloat(supplier.awardedValue) || undefined),
                awardedDate: convertDate(supplier.awardedDate),
                startDate: convertDate(supplier.startDate),
                endDate: convertDate(supplier.endDate),
                awardedToSME: setRadioButtonValue(supplier.awardedToSME),
                awardedToVCSO: setRadioButtonValue(supplier.awardedToVCSO),
                // departmentID: supplier.departmentID,
                // supplierName: supplier.account?.companyName
                // supplierAddress: supplier.account?.address
            }));

            break;
        case 'futureOpportunity':
            payload.type = 'Pipeline';

            payload.approachMarketDate = convertDate(data.contract.approachToMarketDate);
            payload.spendProfile = data.extra.spendProfile;

            break;
        case 'preprocurement':
            payload.type = 'PreProcurement';

            break;
        case 'liveOpportunity':
            payload.type = 'Contract';

            break;
        default:
            payload.type = 'Contract';
            break;
    }

    return payload;
};

const createFTSNoticePayload = (data) => {
    const payload = {
        noticePublisher: data.noticePublisher,
        title: data.project.title,
        // TODO
    };

    return payload;
};


const createNoticePayload = (data) => {
    switch (data.noticePublisher) {
        case 'CF':
            return createContractsFinderNoticePayload(data);
        case 'FTS':
            return createFTSNoticePayload(data);
        default:
            return createContractsFinderNoticePayload(data);
    }
};

const createNotice = async (projectId, eventId, payload) => {
    try {
        const response = await projectManagementAPIs
            .createNotice(projectId, eventId, payload);

        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Notice created successfully.',
            });
        }

        return response;
    } catch (error) {
        Toast.fire({
            icon: 'error',
            titleText: 'Unable to create notice.',
        });

        return false;
    }
};

export {
    getProjectEvent,
    createNotice,
    validateNotice,
    createNoticePayload,
    convertDate,
    hasRequiredReferenceType,
};
