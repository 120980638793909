import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import Constants from './constants';

const ReasonDisqualifyModal = ({
    reason,
    handleCloseModal,
}) => {
    const generateReasonModalBody = () => <>
        <div className='disqualifySuppliersModal'>
            <p className='body'>{reason}</p>
        </div>
    </>;

    return (
        <Modal
            open={true}
            closeModal={handleCloseModal}
            footer={false}
            size='small'
            headerTitle={Constants.DISQUALIFICATION_REASON}
            closeButton={true}
            body={generateReasonModalBody()}
            helpOption={false}
            secondActionButton={false}
        />
    );
};

ReasonDisqualifyModal.propTypes = {
    reason: PropTypes.string,
    handleCloseModal: PropTypes.func,

};

export default ReasonDisqualifyModal;
