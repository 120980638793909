const Constants = {
    newSubmissionBtn: 'New Submission',
    answerSummaryDPSUrl: '/dps/answers/summary',
    unsuccessful: 'unsuccessful',
    errorIcon: 'error',
    retrieveInfoError: 'Unable to retrieve information.',
    totalScore: 'Total Score :',
    publishLotLevel: 'lot',
    CURRENT_SUBMISSION_TITLE: 'Current Submission',
    SUBMITTED_AT: 'Submitted at',
    DIRECT_AWARD_SUMMARY_LABEL: 'Original Award Details',
};

export default Constants;
