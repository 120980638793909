import constants from '../../../reducers/_constants';

const setCurrentStep = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_CURRENTSTEP,
    payload,
});

const setUserType = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_USERTYPE,
    payload,
});

const setCompanyType = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_COMPANYTYPE,
    payload,
});
const setConsentStatus = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_CONSENTSTATUS,
    payload,
});

const setCompanyDetails = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_COMPANYDETAILS,
    payload,
});
const setHiddenDetails = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_HIDDENDETAILS,
    payload,
});
const setSelectedHQROData = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_SELECTEDHQRODATA,
    payload,
});

const setAccountDetails = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_ACCOUNTDETAILS,
    payload,
});
const setNewRODetails = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_NEWRODETAILS,
    payload,
});
const setShowRO = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_SHOWRO,
    payload,
});
const setShowROForm = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_SHOWROFORM,
    payload,
});

const initiateLogin = (payload) => ({
    type: constants.SupplierRegistrationConstants.INITIATE_LOGIN,
    payload,
});

const resetRegForm = () => ({
    type: constants.SupplierRegistrationConstants.RESET_REG_FORM,
});

const accountExists = (payload) => ({
    type: constants.SupplierRegistrationConstants.ACCOUNT_EXISTS,
    payload,
});

const joinAccountId = (payload) => ({
    type: constants.SupplierRegistrationConstants.JOIN_ACCOUNT_ID,
    payload,
});

export default {
    setUserType,
    setCompanyType,
    setConsentStatus,
    setCompanyDetails,
    setHiddenDetails,
    setSelectedHQROData,
    setAccountDetails,
    setNewRODetails,
    setShowRO,
    setShowROForm,
    initiateLogin,
    setCurrentStep,
    resetRegForm,
    accountExists,
    joinAccountId,
};
