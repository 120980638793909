import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';


import DocumentBuilder from '../../../features/DocumentBuilder';
import projectManagementAPIs from '../../../services/project-management.service';
import helperFunctions from '../../../utils/helperFunctions';
import Toast from '../../../components/Alerts/Toast/Toast';
import AuthContext from '../../../context/AuthContext';
import { ERROR_RETRIEVING_DIFF_INFO, accountTypes, RESPONSE_STATUS_TYPE_AUTOSAVE } from '../../../config/constants';
import responseVersionsManagementAPIs from '../../../services/response-versions.service';
import Constants from '../../../features/DocumentBuilder/constants';
import ToastConstants from '../../../components/Alerts/Toast/constants';
import { YOUR_CHANGES_HAVE_BEEN_SAVED_SUCCESSFULLY, ANSWERS_UPDATED_SUCCESSFULLY, UNABLE_TO_UPDATE_ANSWERS } from './constants';

const autosaveCustomClass = {
    container: 'autosave-container',
};

const showToast = (icon, titleText, customClass = undefined) => {
    Toast.fire({
        icon,
        titleText,
        customClass,
    });
};

const AnswerQuestionnaire = () => {
    const { responseId } = useParams();
    const [type, setType] = useState('');
    const [initialState, setInitialState] = useState({});
    const [currentVersionData, setCurrentVersionData] = useState({});
    const [structureToCompare, setStructureToCompare] = useState();
    const history = useHistory();
    const authContext = useContext(AuthContext);

    const handleSubmit = async (data, actionType) => {
        const payload = {};

        const sections = [];
        data.sections.forEach((section) => {
            const sectionData = {
                ...section,
            };
            const questions = [];
            section.sectionItems.forEach((question) => {
                const questionItem = {
                    ...question,
                    questionAnswer: question.questionAnswer,
                    supportingAnswerDocuments: question.supportingAnswerDocuments,
                };
                questions.push(questionItem);
            });
            sectionData.questions = questions;
            sections.push(sectionData);
        });
        payload.sections = sections;

        const response = await projectManagementAPIs.answerResponse(
            responseId, actionType, payload,
        );
        if (response.status === 200) {
            const titleText = RESPONSE_STATUS_TYPE_AUTOSAVE
                ? YOUR_CHANGES_HAVE_BEEN_SAVED_SUCCESSFULLY
                : ANSWERS_UPDATED_SUCCESSFULLY;
            const customClass = RESPONSE_STATUS_TYPE_AUTOSAVE ? autosaveCustomClass : undefined;

            showToast(ToastConstants.success, titleText, customClass);

            if (actionType !== RESPONSE_STATUS_TYPE_AUTOSAVE) {
                history.goBack();
            }
        } else {
            showToast(ToastConstants.error, UNABLE_TO_UPDATE_ANSWERS);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (authContext.user.accountType === accountTypes.supplier) {
                const responseData = await projectManagementAPIs.getResponse(responseId);
                if (responseData.status === 200) {
                    const preparedData = helperFunctions.prepareDocumentBuilderState(
                        Constants.QUESTIONNAIRE, responseData.data, responseId,
                    );
                    setInitialState(preparedData);
                    setType(preparedData.details.responseType);
                    setCurrentVersionData(responseData.data);
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to retrieve information.',
                    });
                }
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'You do not have permission to view this content.',
                });
                history.push('/dashboard');
            }
        };
        fetchData();
    }, [responseId]);

    const getResponseVersion = async (idTemplate) => {
        const responseData = await responseVersionsManagementAPIs.getResponseVersion(idTemplate);
        return responseData;
    };

    const handleShowDiffsInViewer = async (versionId) => {
        const responseData = await getResponseVersion(versionId);
        if (responseData.status === 200) {
            const oldPreparedVersion = helperFunctions
                .prepareDocumentBuilderState(Constants.RESPONSE, responseData.data, versionId);
            const currentPreparedVersion = helperFunctions
                .prepareDocumentBuilderState(Constants.RESPONSE, currentVersionData, responseId);
            setStructureToCompare(helperFunctions.prepareVersionsToCompare(
                Constants.RESPONSE, oldPreparedVersion, currentPreparedVersion,
            ));
        } else {
            Toast.fire({
                icon: ToastConstants.error,
                titleText: ERROR_RETRIEVING_DIFF_INFO,
            });
        }
    };

    return Object.keys(initialState).length > 0
        ? <DocumentBuilder
            documentMode={'answer'}
            context='Questionnaire'
            type={type}
            initialDocumentState={initialState}
            sectionLabel='Section'
            sectionItemLabel='Question'
            allowSectionReordering={false}
            allowSectionItemsReordering={false}
            sectionDetailsUserList={[]}
            handlePrimaryToolbarSubmitButton={handleSubmit}
            showVersionDropdown = {true}
            handleShowDiffsInViewer={handleShowDiffsInViewer}
            structureToCompare={structureToCompare}
            withAutosave={true}
            handleAutoSaveData={(data) => handleSubmit(data, RESPONSE_STATUS_TYPE_AUTOSAVE)}
        />
        : <></>;
};

export default AnswerQuestionnaire;
