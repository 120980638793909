import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    format, addMonths, parse,
} from 'date-fns';
import AuthContext from '../../context/AuthContext';
import Button from '../../components/Button';
import DashboardCard from '../../components/DashboardCard';
import Modal from '../../components/Modal';
import LabelledTextArea from '../../components/LabelledTextArea';
import Form from '../../components/Form';
import Upload from '../../features/Upload';
import ViewContract from './components/ViewContract';
import SteeringGroup from './components/SteeringGroup';
import Suppliers from './components/Suppliers';
import ContractDocuments from './components/ContractDocuments';
import WorknotesModal from '../../features/WorknotesModal';
import WorkFlowEvents from './components/WorkFlowEvents';
import UserRoles from '../../features/Header/constants';
import editIcon from '../../styles/images/edit.png';
import projectManagementAPIs from '../../services/project-management.service';
import Constants from './constants';
import rbac from '../../rbac';
import Confirm from '../../components/Alerts/Confirm/Confirm';
import Toast from '../../components/Alerts/Toast/Toast';
import {
    mergeUploadedDocuments,
    persistUploadedDocuments,
} from '../../utils/uploadHelper';
import helperFunctions from '../../utils/helperFunctions';
import { view, activityLogRoute, resourceTypes } from '../../config/constants';
import showAwardSummaryButton from './helpers';
import correspondenceHelper from '../../utils/correspondenceHelper';
import projectService from '../../services/project.service';
import isSupplierRole from '../../utils/isSupplierRole';

const ContractOverview = () => {
    const authContext = useContext(AuthContext);
    const accountType = authContext.user?.accountType?.toLowerCase();

    const { contractId, supplierId, lotId } = useParams();
    const history = useHistory();

    const [contractDetails, setContractDetails] = useState({});
    const [unreadCorrespondence, setUnreadCorrespondence] = useState(0);
    const [values, setValues] = useState({});
    const [escalation, setEscalation] = useState({});

    const [cancelModal, setCancelModal] = useState(false);
    const [extentContract, setExtentContract] = useState(false);
    const [showWorknotes, setShowWorkNotes] = useState(false);
    const [showEscalation, setShowEscalation] = useState(false);
    const [hasEvents, setHasEvents] = useState(false);
    const [escalationResolvedModal, setEscalationResolvedModal] = useState(false);

    const [workNoteMode, setWorkNoteMode] = useState('');

    const userSteeringGroupRole = rbac.util.getSteeringGroupRole(
        authContext.user.id,
        contractDetails?.steeringInfo,
    );

    const pluralizeText = (count, singular) => (
        `${count || 0} ${helperFunctions.pluralizeText(count || 0, singular)} added`
    );

    const availableExtensions = contractDetails.extensionsAvailable
        ? contractDetails.extensionsAvailable?.filter(
            (ext) => ext.extensionStatus === null
        || ext.extensionStatus === undefined
        || ext.extensionStatus.toUpperCase() !== 'INVALID',
        ) : [];

    const calculateValidDuration = () => (
        availableExtensions?.length > 0
            ? parseInt(availableExtensions.filter((extension) => extension.extensionStatus === 'active')[0]?.monthsAvailable || 0, 10)
            : 0
    );

    const calculateEndDate = (endDate, duration) => {
        const endDateInstance = parse(endDate, 'dd-MM-yyyy', new Date());
        return format(
            addMonths(endDateInstance, Number(duration)),
            'dd-MM-yyyy',
        );
    };

    const handleChange = (e) => {
        const { name } = e.target;
        const { value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const closeCancelModal = () => {
        setCancelModal(false);
        setValues({
            ...values,
            cancel: '',
            cancelExternal: '',
        });
    };

    const getEvent = (contractData, eventId) => (
        contractData.workflow?.stages?.map((stage) => (
            stage?.events?.find((event) => event.eventID === eventId)
        )).flat()[0]
    );

    const groupByRole = (steeringInfo, getUserID) => {
        const rekeyedArray = [];

        steeringInfo.forEach((a) => {
            rekeyedArray[a.userRole] = rekeyedArray[a.userRole] || [];
            let name = [];
            if (a.userRole !== 'supplier' && !getUserID) {
                name = a.user?.contactDetails?.username;
            } else if (a.userRole !== 'supplier' && getUserID) {
                name = a.userID;
            } else {
                name = a?.account?.companyName;
            }
            rekeyedArray[a.userRole].push(name);
        });
        return Object.keys(rekeyedArray).map((k) => ({
            userRole: k,
            users: rekeyedArray[k],
        }));
    };

    const updateUnreadAmount = async (resourceType, resourceId) => {
        const unreadCount = await correspondenceHelper.fetchUnreadData(resourceType, resourceId);
        setUnreadCorrespondence(unreadCount ?? 0);
    };

    const fetchContract = async () => {
        try {
            const response = await projectManagementAPIs.getContract(contractId);
            if (response.status === 200) {
                setContractDetails(response.data);
                await updateUnreadAmount(resourceTypes.contract, contractId);
                setHasEvents(() => (response?.data?.workflow
                    ? Object.values(response?.data?.workflow).length > 0 : false));
            } else if (response.data.ErrorCode === '6000') {
                Toast.fire({
                    icon: 'error',
                    titleText: 'You do not have access to this action.',
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (responseError) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const handleEscalateReason = async (reason, eventId, closeModal) => {
        const payLoad = {
            reason,
            escalationAction: 'escalate',
        };
        try {
            const response = await projectManagementAPIs
                .escalateEvent(contractDetails.projectID,
                    eventId, contractDetails.workflowID, payLoad);
            if (response.status === 200) {
                closeModal();
                fetchContract();
                Toast.fire({
                    icon: 'success',
                    titleText: 'Event escalated successfully.',
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to escalate event.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to escalate event.',
            });
        }
    };

    const handleEscalateResolve = async (e) => {
        e.preventDefault();
        const payLoad = {
            escalationAction: 'resolve',
            reason: values.resolvedReason,
        };
        try {
            const response = await projectManagementAPIs.escalateEvent(
                contractDetails.projectID,
                escalation.eventID, contractDetails.workflowId, payLoad,
            );
            if (response.status === 200) {
                setValues({
                    ...values,
                    resolvedReason: '',
                });
                fetchContract();
                setEscalationResolvedModal(false);
                Toast.fire({
                    icon: 'success',
                    titleText: 'Escalation resolved successfully.',
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to resolve escalation.',
                });
            }
        } catch {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to resolve escalation.',
            });
        }
    };

    const handleCreateVariation = async (variation, closeModal) => {
        const payload = {
            variationComment: variation,
        };
        const response = await projectManagementAPIs.createContractVariation(
            contractId, payload,
        );
        if (response.status === 200) {
            closeModal();
            fetchContract();
            Toast.fire({
                icon: 'success',
                titleText: 'Contract variation created successfully.',
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to create variation contract.',
            });
        }
    };

    const handleExtentSubmit = async (event) => {
        event.preventDefault();
        const response = await projectManagementAPIs.extendContract(contractId);
        if (response.status === 200) {
            setExtentContract(false);
            fetchContract();
            Toast.fire({
                icon: 'success',
                titleText: 'Contract extended successfully.',
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to extent contract.',
            });
        }
    };

    const handleEventUploadDocument = async (docs, eventId) => {
        const contractEvent = getEvent(contractDetails, eventId);

        if (contractEvent) {
            const payload = {
                additionalDocuments: docs,
            };

            await persistUploadedDocuments(
                'upload',
                () => projectManagementAPIs.uploadEventDocument(contractDetails.projectID,
                    eventId, payload),
                () => fetchContract(),
            );
        }
    };

    const handleEventDeleteDocument = async (eventId, documentId) => {
        await persistUploadedDocuments(
            'delete',
            () => projectManagementAPIs.deleteEventDocument(contractDetails.projectID,
                eventId, documentId),
            () => fetchContract(),
        );
    };

    const uploadDocuments = async (docs) => {
        const mergedDocs = mergeUploadedDocuments(contractDetails.additionalDocuments, docs,
            {}, authContext.user);

        const payLoad = {
            additionalDocuments: mergedDocs,
        };

        await persistUploadedDocuments(
            'upload',
            () => projectManagementAPIs.uploadDocumentForContract(contractId, payLoad),
            () => fetchContract(),
        );
    };

    const deleteDocument = async (doc) => {
        await persistUploadedDocuments(
            'delete',
            () => projectManagementAPIs.deleteDocumentForContract(contractId, doc.documentID),
            () => fetchContract(),
        );
    };

    const handleUpdateContractDocs = async (data) => {
        const response = await projectManagementAPIs.updateContractDocs(contractId, data);
        if (response.status === 201) {
            Toast.fire({
                icon: 'success',
                titleText: 'Document uploaded successfully.',
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to upload document.',
            });
        }
        await fetchContract();
    };

    const handleDeleteContractDoc = async (documentId) => {
        await projectManagementAPIs.deleteContractDoc(contractId, documentId);
        await fetchContract();
    };

    const handleAddWorkNotes = async (eventId, worknote, closeModal) => {
        const payLoad = {
            worknote,
        };
        try {
            const response = await projectManagementAPIs
                .addEventWorknote(contractDetails.projectID, eventId, payLoad);
            if (response.status === 200) {
                closeModal();
                fetchContract();
                Toast.fire({
                    icon: 'success',
                    titleText: 'Worknote added successfully.',
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to add worknote.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to add worknote.',
            });
        }
    };

    const handleUpdateEvent = async ({ eventId, action }) => {
        const payLoad = {
            action,
        };
        try {
            const response = await projectManagementAPIs
                .updateEvent(contractDetails.projectID, eventId, payLoad);
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Event updated successfully.',
                });
                fetchContract();
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to update event.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update event.',
            });
        }
    };

    const updateCompleteEventContract = async ({ eventId, action }) => {
        try {
            const response = await projectManagementAPIs.updateCompleteEventContract(
                contractDetails.projectID, eventId, {
                    action,
                },
            );
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Event updated successfully.',
                });
                fetchContract();
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to update event.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update event.',
            });
        }
    };

    const handleCancellation = async (event) => {
        event.preventDefault();
        try {
            await projectManagementAPIs.cancelContract(contractId, {
                internalReason: values.cancel,
                externalReason: values.cancelExternal,
            });
            Toast.fire({
                icon: 'success',
                titleText: 'Contract cancelled successfully.',
            });
            setValues({
                ...values,
                cancel: '',
                cancelExternal: '',
            });
            fetchContract();
            closeCancelModal();
        } catch (e) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to cancel contract.',
            });
        }
    };

    const handleAddActionWorkNotes = async (worknotes, value) => {
        const payload = {
            worknote: value,
        };
        try {
            const response = await projectManagementAPIs.updateWorknoteForContract(
                contractId, payload,
            );
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Worknote added successfully.',
                });
                fetchContract();
            }
        } catch {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to add worknote.',
            });
        }
    };

    const generateActivityLogContent = () => (
        contractDetails?.activityLog?.length > 0
            ? <>
                {contractDetails.activityLog.map((log, index) => <div key={`log-${index}`} className={'logItem'}>
                    <p className={'headerTitle title'}>{log.message}</p>
                    <p className={'caption'}>{log.username}, {log.departmentName}</p>
                    <p className={'caption small'}>{helperFunctions.formatPrettyDateTime(log.systemTimestamp)}</p>
                </div>)}
            </>
            : <div id='noActivityLog'>
                <p className='caption'>No logs.</p>
            </div>);

    const generateVariationContent = () => (
        contractDetails?.activityLog?.length > 0
            ? <>
                {contractDetails.activityLog.map((log, index) => <div key={`log-${index}`} className={'logItem'}>
                    <p className={'headerTitle caption'}>{log.username}</p>
                    <p className={'body small'}>{helperFunctions.formatPrettyDateTime(log.systemTimestamp)}</p>
                    <p className={'body small'}>{log.message.split('\n')[0]}</p>
                    <ul>
                        {log.message.split('\n').slice(1).map((m, i) => <li className={'headerTitle title'} key={`message-${i}`}>{'\u2022'} {m}</li>)}
                    </ul>

                </div>)}
            </>
            : <div id='noActivityLog'>
                <p className='caption'>No logs.</p>
            </div>);

    const generateCancelModal = () => <>
        <LabelledTextArea id='cancel'
            label='Enter your reason for cancelling this project.'
            subLabel='This is internal and only visible within your organisation.'
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={values.cancel || ''}
            currentCharCount={values.cancel ? values.cancel.length : 0}
            placeholder='Enter reason'
            required={true} />

        <LabelledTextArea id='cancelExternal'
            label='Public statement for cancelling this project.'
            subLabel='This is a public statement and is visible to suppliers.'
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={values.cancelExternal || ''}
            currentCharCount={values.cancelExternal ? values.cancelExternal.length : 0}
            placeholder='Enter reason'
            required={false}
        />
    </>;

    const getExtentContent = () => <><div className='sectionRow'>
        <p className='title sectionLabel'>Extension Duration: </p>
        <p className='caption sectionValue'>{calculateValidDuration()} months</p>
    </div>
    <div className='sectionRow'>
        <p className='title sectionLabel'>New Contract End Date: </p>
        <p className='caption sectionValue'>
            {calculateEndDate(contractDetails.endDate, calculateValidDuration())}
        </p>
    </div>
    <div className='sectionRow'>
        <p className='title sectionLabel'>Extension(s) Remaining: </p>
        <p className='caption sectionValue'>{availableExtensions?.length ? (availableExtensions?.length - 1) : 0}</p>
    </div>
    </>;

    const generateEscalations = () => ((contractDetails?.eventsEscalations?.length > 0)
        ? <>
            {contractDetails.eventsEscalations.map((escalate, index) => <div key={`esc-${index}`} className={'escalationItem'}>
                <p className={'title'}>{escalate.eventName}</p>
                <div id={'escalationBtnContainer'}>
                    <Button
                        id={`tBtn-${index}`}
                        label={escalate.escalationStatus === 'closed' ? 'Closed' : 'Resolve'}
                        size='small'
                        variant='secondary'
                        disabled={escalate.escalationStatus === 'closed'}
                        handleClick={() => {
                            setEscalationResolvedModal(true);
                            setEscalation({
                                ...escalate,
                            });
                        }}
                    />
                    <Button
                        id={`tBtn-${index}`}
                        label='View'
                        size='small'
                        handleClick={() => {
                            setShowEscalation(true);
                            setEscalation({
                                ...escalate,
                            });
                        }}
                    />
                </div>
            </div>)}
        </>
        : <div id='noEscalations'>
            <p className='caption'>No escalations.</p>
        </div>);

    const handleAddAdmin = async () => {
        if (rbac.can('editSteeringGroup', userSteeringGroupRole)) {
            Toast.fire({
                icon: 'error',
                titleText: 'User is already a procurement officer in the steering group.',
            });
            return;
        }

        const steeringInfo = contractDetails.steeringInfo.filter((user) => user.userRole.toLowerCase() !== 'supplier').map((user) => ({
            userRole: user.userRole,
            accountID: user.accountID,
            departmentID: user.departmentID,
            userID: user.userID,
        }));
        steeringInfo.push({
            userRole: 'po',
            accountID: authContext.user.accountId,
            departmentID: authContext.user.details.departmentID,
            userID: authContext.user.id,
        });
        const payload = {
            steeringInfo,
        };

        const response = await projectManagementAPIs.updateContractSteeringGroup(
            contractId, payload,
        );
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Successfully added to steering group.',
            });
            fetchContract();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to add user to steering group.',
            });
        }
    };

    const addAdminToContract = () => {
        Confirm(handleAddAdmin, {
            subTitle: 'Add yourself as a procurement officer to this contract? You can remove this at any time by visiting the edit steering group page.',
        });
    };

    const generateActionContent = () => (<>
        {rbac.can(rbac.action.cancelProject, userSteeringGroupRole)
            && <div className={'dashboardCard large'} id={'projectActions'}>
                <div id={'projectActionContainer'}>
                    <div className={'cancelButtonContainer'}>
                        <Button
                            id='cancelButton'
                            type='button'
                            variant='secondary'
                            additionalVariant='cancel'
                            label={'Cancel Contract'}
                            disabled={contractDetails?.contractStatus === Constants.cancelled}
                            icon={false}
                            handleClick={() => setCancelModal(true)}
                        />
                    </div>
                </div>

            </div>}</>);

    useEffect(() => {
        fetchContract();
    }, [contractId]);

    return <>
        {contractDetails?.contractStatus !== Constants.cancelled
        && contractDetails?.awardedSuppliers?.length > 0
        && <div className='contractOverviewContainer'>
            {generateActionContent()}
        </div>}
        {contractDetails.contractStatus === Constants.cancelled
        && <div className='contractOverviewContainer'>
            <div className={'dashboardCard large'} id={'prfActions'}>
                <main className='dashboardCardMain'>
                    {contractDetails.statusReasons?.map((status, index) => (
                        <div key={`procumentDetails-approvalHistory${index}`}>
                            <p className='title-xLarge' id='error'>Cancelled</p>
                            <p className='caption'><span className='title' id='labelText'>Reason :</span> {accountType === UserRoles.SUPPLIER_ROLE
                                ? status.externalReason : status.internalReason}</p>
                        </div>
                    ))}
                </main>
            </div>
        </div>}

        {showAwardSummaryButton(contractDetails, accountType, supplierId, lotId)
        && <div className='contractOverviewContainer'>
            <div className='dashboardCard large' id={'projectActions'}>
                <div id={'projectActionContainer'}>
                    <div className='moreActionContainer'>
                        <Button
                            id={'awardSummaryButton'}
                            size={'size-fit-text'}
                            label={Constants.awardSummary}
                            variant={'secondary'}
                            additionalVariant={'oneLineText'}
                            handleClick={() => {
                                if (isSupplierRole(accountType)
                                    && projectService.isRTMDirectAward(contractDetails?.route)
                                ) {
                                    history.push(`/contract/award/summary/${contractDetails.projectID}/${contractDetails.awardEventID}`);
                                } else {
                                    history.push(`/award/summary/${contractDetails.projectID}/${contractDetails.awardEventID}`);
                                }
                            }
                            }
                        />
                    </div>
                </div>
            </div>
        </div>}

        <div className='contractOverviewContainer'>
            <ViewContract
                data={contractDetails}
                optionToExtend={calculateValidDuration()}
            />
        </div>
        <div className='contractOverviewContainer'>
            <div className='leftcontractOverviewContainer'>
                {accountType === UserRoles.SUPPLIER_ROLE
                    ? <ContractDocuments
                        contractDetails={contractDetails}
                        title='Contract Documents'
                        isSupplier={true}
                        handleUpdate={handleUpdateContractDocs}
                        handleDelete={handleDeleteContractDoc}
                        readOnly={contractDetails?.contractStatus === Constants.cancelled}
                    />
                    : <SteeringGroup
                        id='steeringGroup'
                        headerTitle='Steering Group'
                        actionBtn={((contractDetails?.contractStatus !== Constants.cancelled)
                            && (rbac.can(rbac.action.editSteeringGroup, userSteeringGroupRole))) || ((contractDetails?.contractStatus !== Constants.cancelled) && authContext?.user?.role === 'admin')}
                        actionBtnText={rbac.can(rbac.action.editSteeringGroup, userSteeringGroupRole) ? 'Edit' : 'Join Contract'}
                        handlerActionBtn={
                            rbac.can(rbac.action.editSteeringGroup, userSteeringGroupRole)
                                ? () => history.push(`/steering-group/contract/${contractId}`)
                                : () => addAdminToContract()
                        }
                        iconSrc={editIcon}
                        icon={rbac.can(
                            rbac.action.editSteeringGroup, userSteeringGroupRole,
                        ) && true}
                        variant={rbac.can(rbac.action.editSteeringGroup, userSteeringGroupRole) ? 'secondary' : 'primary'}
                        additionalVariant={rbac.can(rbac.action.editSteeringGroup, userSteeringGroupRole) && 'skinless editSteeringGroup'}
                        data={contractDetails?.steeringInfo
                            ? groupByRole(contractDetails.steeringInfo, false)
                            : []}
                    />
                }
                {accountType !== UserRoles.SUPPLIER_ROLE
                && <Suppliers
                    id='suppliersGroup'
                    contractDetails={contractDetails}
                    headerTitle='Supplier(s)'
                    actionBtn={false}
                    icon={false}
                    footer={false}
                    data={(contractDetails && contractDetails.awardedSuppliers)
                        ? contractDetails.awardedSuppliers
                        : []}
                />}
                {accountType !== UserRoles.SUPPLIER_ROLE
                && <ContractDocuments
                    contractDetails={contractDetails}
                    title='Contract Documents'
                    isSupplier={false}
                    handleUpdate={handleUpdateContractDocs}
                    handleDelete={handleDeleteContractDoc}
                    readOnly={!rbac.can(
                        rbac.action.uploadDocuments,
                        userSteeringGroupRole,
                    )
                   || contractDetails?.contractStatus === Constants.cancelled}
                />}
            </div>
            <WorkFlowEvents
                type='current'
                title='Workflow'
                eventDetails={contractDetails?.workflow}
                handleUpdateEvent={handleUpdateEvent}
                handleEscalateEvent={handleEscalateReason}
                handleUploadDocument={handleEventUploadDocument}
                handleDeleteDocument={handleEventDeleteDocument}
                handleAddWorkNotes={handleAddWorkNotes}
                handleExtentContract={() => {
                    setExtentContract(true);
                }}
                handleCreateVariation={handleCreateVariation}
                showSelectWorkflow={!hasEvents}
                updateCompleteEventContract={updateCompleteEventContract}
                canEditWorkflow={(contractDetails?.contractStatus !== Constants.cancelled)
                    && (rbac.can(rbac.action.editWorkflow,
                        [authContext.user.accountType?.toUpperCase() + authContext?.user?.role])
                        || rbac.can(rbac.action.editWorkflow, userSteeringGroupRole))}
                handleEditWorkflow={() => {
                    history.push(`/workflows/contract/edit/${contractDetails.projectID}/${contractDetails.workflowID}/${contractDetails.contractID}`);
                }}
                steeringInfo={contractDetails.steeringInfo}
                contractId={contractId}
                awardedSupplierData={(contractDetails && contractDetails.awardedSuppliers)
                    ? contractDetails.awardedSuppliers
                    : []}
                readOnly={contractDetails?.contractStatus === Constants.cancelled}
                userSteeringRoles={userSteeringGroupRole}
            />
        </div>
        {hasEvents
         && <>
             <div className='contractOverviewContainer'>
                 <DashboardCard
                     id='actions'
                     size='small'
                     header={true}
                     headerTitle='Actions'
                     content={<div id='contractActionContent'>
                         <div id='correspondenceContent'>
                             <p className='title sectionLabel'>Correspondence</p>
                             <p className='caption'>
                                 {`${unreadCorrespondence} unread correspondence available`}
                             </p>
                             <div id='buttonContainer'>
                                 <Button id='actionViewCorrespondence'
                                     size='small'
                                     variant='secondary'
                                     label='View'
                                     handleClick={() => history.push(`/contract/correspondence/${contractDetails.contractID}`)} />
                             </div>
                         </div>
                         <div id='worknoteContent'>
                             <p className='title sectionLabel'id='worknotes'>Worknotes</p>
                             <p className='caption'>
                                 {pluralizeText(contractDetails?.worknotes?.length, 'worknote')}
                             </p>
                             <div className='buttonContainer'>
                                 <Button id='actionBtnS'
                                     size='small'
                                     variant='secondary'
                                     label='View'
                                     handleClick={() => {
                                         setWorkNoteMode('view');
                                         setShowWorkNotes(true);
                                     }}
                                 />
                                 {rbac.can(rbac.action.addNotes, userSteeringGroupRole)
                                && contractDetails?.contractStatus !== Constants.cancelled
                                && <Button id='actionBtn'
                                    size='small'
                                    variant='primary'
                                    label='Add'
                                    handleClick={() => {
                                        setWorkNoteMode('add');
                                        setShowWorkNotes(true);
                                    }}
                                />}
                             </div>
                         </div>
                         <Upload
                             title = {'Documents'}
                             allowMultiple = {true}
                             uploadIdentifier = {'document'}
                             uploadCallback={uploadDocuments}
                             deleteCallback={deleteDocument}
                             uploadedDocuments={contractDetails.additionalDocuments}
                             readOnly={!(rbac.can(
                                 rbac.action.uploadDocuments,
                                 userSteeringGroupRole,
                             )
                            && contractDetails?.contractStatus !== Constants.cancelled)}
                         />
                     </div>}
                 />
                 {authContext?.user?.accountType?.toLowerCase() !== 'supplier'
                 && <DashboardCard
                     id='contractActions'
                     size='medium'
                     header={true}
                     headerTitle='Variation'
                     actionBtn={false}
                     content={generateVariationContent()}
                     footer={false}
                 />
                 }
             </div>
             <div className='contractOverviewContainer'>
                 {accountType !== UserRoles.SUPPLIER_ROLE
                 && <DashboardCard
                     id={Constants.activityLogId}
                     size='small'
                     header={true}
                     headerTitle={Constants.activityLog}
                     caption={''}
                     actionBtn={true}
                     actionBtnText={view}
                     handlerActionBtn={() => {
                         history.push(`/${resourceTypes.contract}/${activityLogRoute}/${contractId}`);
                     }}
                     content={generateActivityLogContent()}
                     footer={false}
                 />}
                 {accountType === UserRoles.SUPPLIER_ROLE
                 && <DashboardCard
                     id={Constants.activityLogId}
                     size='small'
                     header={true}
                     headerTitle={Constants.activityLog}
                     caption={''}
                     actionBtn={false}
                     content={generateActivityLogContent()}
                     footer={false}
                 />}
                 {accountType !== UserRoles.SUPPLIER_ROLE
                 && <DashboardCard
                     id='contractEscalations'
                     size='small'
                     header={true}
                     headerTitle={'Contract Escalations'}
                     caption={'Escalations raised against workflow events for approvers to review and resolve.'}
                     content={generateEscalations()}
                     footer={false}
                 />}
             </div>
         </>
        }
        {extentContract && <Form onSubmit={handleExtentSubmit} id='extentForm'><Modal
            id='extentContract'
            open={true}
            size='medium'
            headerTitle='Confirm Extension'
            body={getExtentContent()}
            footer={true}
            mainActionButtonTxt='Extend'
            secondActionButtonTxt='Cancel'
            secondActionButton={true}
            handleMainActionBtnClick={() => null}
            handleSecondaryActionBtnClick={() => {
                setExtentContract(false);
            }}
            helpOption={false}
            closeModal={() => { setExtentContract(false); }} /></Form>}
        {cancelModal && <Form onSubmit={handleCancellation}><Modal
            id='cancelModal'
            open={true}
            size='medium'
            headerTitle='Cancellation Reason'
            body={generateCancelModal()}
            footer={true}
            mainActionButtonTxt='Submit'
            secondActionButtonTxt='Cancel'
            secondActionButton={true}
            handleMainActionBtnClick={() => null}
            handleSecondaryActionBtnClick={closeCancelModal}
            helpOption={false}
            closeModal={closeCancelModal} /></Form>}
        {showWorknotes
        && <WorknotesModal
            closeModal={() => setShowWorkNotes(false)}
            mode={workNoteMode}
            worknotesInput={contractDetails?.worknotes?.length > 0
                ? contractDetails.worknotes
                : []}
            handleCallback={handleAddActionWorkNotes}
        />}
        {showEscalation
        && <Modal
            id='escalationModal'
            headerTitle={'Escalation Reason'}
            open={showEscalation}
            size='small'
            body={<section>
                <div className='escalationReasonItem'>
                    <p className='title-small escalationTitle'>{`Event escalated by ${escalation.username} on ${helperFunctions.formatPrettyDate(escalation.escalatedOn)}`}</p>
                    <p className='caption-small escalationReason'>{escalation.escalationReason}</p>
                </div>

                {escalation.escalationStatus === 'closed'
                    ? <div className='escalationReasonItem'>
                        <p className='title-small escalationTitle'>
                            {`Event escalation resolved by ${escalation.resolvedByUsername} on ${helperFunctions.formatPrettyDate(escalation.resolvedOn)}`}</p>
                        <p className='caption-small escalationReason'>{escalation.resolvedReason}</p>
                    </div>
                    : <></>}
            </section>}
            mainActionButton={false}
            footer={false}
            helpOption={false}
            closeModal={() => {
                setShowEscalation(false);
            }}
        />}
        {escalationResolvedModal
        && <Form onSubmit={(e) => handleEscalateResolve(e)}>
            <Modal
                id='escalationResolvedModal'
                headerTitle='Resolve Escalation'
                open={true}
                size='medium'
                body={<>
                    <LabelledTextArea
                        id='resolvedReason'
                        label='Resolved Reason'
                        breakColumn={true}
                        onChange={(e) => handleChange(e)}
                        value={values.resolvedReason || ''}
                        currentCharCount={values.resolvedReason ? values.resolvedReason.length : 0}
                        placeholder='Enter escalation resolved reason'
                        required={true}
                    />
                </>}
                footer={true}
                handleMainActionBtnClick = {() => null}
                mainActionButtonTxt={'Confirm'}
                secondActionButton={true}
                handleSecondaryActionBtnClick={() => {
                    setValues({
                        ...values,
                        resolvedReason: '',
                    });
                    setEscalationResolvedModal(false);
                }}
                secondActionButtonTxt={'Cancel'}
                helpOption={false}
                closeModal={() => {
                    setValues({
                        ...values,
                        resolvedReason: '',
                    });
                    setEscalationResolvedModal(false);
                }}
            />
        </Form>}
    </>;
};

export default ContractOverview;
