import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SearchBar = ({
    id,
    placeholder,
    handleSearchCallback,
}) => {
    const [searchValue, setSearchValue] = useState('');

    const handleChange = (event) => {
        const { value } = event.target;
        setSearchValue(value);
        if (handleSearchCallback) {
            handleSearchCallback({
                search: value,
            });
        }
    };
    return (<input type='search'
        className='searchBar body'
        id={id}
        placeholder={placeholder}
        value={searchValue}
        onChange={(event) => handleChange(event)}>
    </input>);
};

SearchBar.propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    handleSearchCallback: PropTypes.func,
};

export default SearchBar;
