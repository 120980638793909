import React, { useState } from 'react';
import PropTypes from 'prop-types';
import infoIcon from '../../styles/images/infoIcon.svg';
import guidanceInfo from '../../styles/images/Guidance Info.png';
import StatusLabel from '../StatusLabel';
import Button from '../Button';
import CountDownTimer from '../../features/CountdownTimer';
import Modal from '../Modal';
import GeneralConstants from '../../utils/generalConstants';

const GuidanceBanner = ({
    id,
    type = '',
    headerText,
    bannerText1,
    bannerText2,
    buttonArr,
    timerEndDate,
    timerTextType,
    showStatus,
    actionableItem,
}) => {
    const [showModal, setShowModal] = useState(false);

    const handleModal = (show) => {
        setShowModal(show);
    };

    const handleActionableItem = (action) => {
        switch (action) {
            case 'ANSWERS': return <div><p className='body'>Click <a href='' onClick ={(e) => { e.preventDefault(); handleModal(true); }}> here </a> to complete the selection questionnaire.</p></div>;

            case 'PROFILE': return <div><p className='body'>Click <a href='' onClick ={(e) => { e.preventDefault(); handleModal(true); }}> here </a>  to complete the selection questionnaire.</p></div>;

            case 'PRE-INTEREST': return <div><p className='body'><a href='' onClick ={(e) => { e.preventDefault(); handleModal(true); }}> Find out more </a>  about what you need to know before registering interest.</p></div>;

            case 'POST-INTEREST': return <div><p className='body'><a href='' onClick ={(e) => { e.preventDefault(); handleModal(true); }}> Find out more </a>  about when you can register interest in opportunities.</p></div>;

            case 'APPROVAL': return <div><p className='body'>Don&apos;t panic! <a href='' onClick ={(e) => { e.preventDefault(); handleModal(true); }}> Find out more </a>  about approvals when registering interest.</p></div>;

            case 'FINDOUTMORE': return <div><p className='body'><a href='' onClick ={(e) => { e.preventDefault(); handleModal(true); }}> Find out more </a> about what you need to know before submitting a bid.</p></div>;

            case 'FINDOUTMOREANSWER': return <div><p className='body'><a href='' onClick ={(e) => { e.preventDefault(); handleModal(true); }}> Find out more </a> about what you need to know before submitting a answer.</p></div>;

            default: return <div></div>;
        }
    };

    const response = actionableItem ? handleActionableItem(actionableItem) : '';
    return (
        <div className='guidanceBannerContainer' id={`guidanceBanner-${id}`}>
            <div className={`guidanceBannerCard ${type}`}>
                <div className={`guidanceBannerIcon ${type}`}>
                    <img src={infoIcon} alt='Guidance Banner' className='infoIcon'/>
                </div>
                <div className='guidanceBannerContent'>
                    <div className='showText'>
                        <div>
                            {type === 'small'
                                ? <h4 className='title'>{headerText}</h4>
                                : <h4>{headerText}</h4>}
                        </div>
                        {timerEndDate ? (
                            <div>
                                <CountDownTimer timerEndDate={timerEndDate}
                                    type={timerTextType} enablerag={true} />
                            </div>
                        )
                            : (<div>
                                <p className='body'>{bannerText1}</p>
                            </div>)}
                        {response !== '' ? <>{response}</>
                            : <div>
                                {type === 'small'
                                    ? <p className='body-small'>{bannerText2}</p>
                                    : <p className='body'>{bannerText2}</p>}
                            </div>
                        }
                    </div>
                    {showStatus
                && <div className='showLabel'>
                    <StatusLabel id={`showLabel${id}`} color='green' labelTxt={showStatus}/>
                </div>}
                    {buttonArr?.length > 0 ? (
                        <div className='showButton'>
                            {buttonArr.map((btn) => (
                                <div key={btn.id}>
                                    {btn.allowAction
                                    && <Button id='primary-action-btn'
                                        type='button'
                                        size={btn.size}
                                        variant={btn.variant}
                                        additionalVariant={btn.additionalVariant ? btn.additionalVariant : ''}
                                        label={btn.label}
                                        handleClick={btn.action}
                                        disabled={btn.disabled}/>}
                                </div>
                            ))}
                        </div>
                    ) : ('')}
                </div>
            </div>

            {showModal && <Modal id={'showProfile'}
                open={showModal}
                size='temp-size'
                // headerTitle='Profile'
                // mainActionButtonTxt='Ok'
                footer={false}
                body={<img src={guidanceInfo} alt={GeneralConstants.guidanceBanner}></img>}
                // handleMainActionBtnClick={() => handleModal(false)}
                helpOption={false}
                closeModal={() => handleModal(false)}/>
            }
        </div>
    );
};

GuidanceBanner.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string, // set warning for red banner
    headerText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    bannerText1: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    bannerText2: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    buttonArr: PropTypes.array,
    showStatus: PropTypes.string,
    timerEndDate: PropTypes.string, // for timer interval pass as yyyy-mm-dd hh:mm:ss
    timerTextType: PropTypes.string,
    actionableItem: PropTypes.string, // for link actions
};

// {
//     headerText='Market Consultation',
//     bannerText1='Congrats! You have successfully registered interest in this opportunity.',
//     bannerText2='The buyer has a few questions, click on ‘Answer’ to begin.',
//     buttonArr=[{id:1 ,name:'Remove interest', type:'primary',size:'regular' ,variant:'primary' ,
//     additionalVariant:'red', action:'removeInterest'},
//     {id:2 ,name:'Answer', type:'primary', size:'regular' ,variant:'primary' ,
//     additionalVariant:'red',action:'Answer'}],
//     }
export default GuidanceBanner;
