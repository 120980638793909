const Constants = {
    eventLabelFields: {
        calendar: {
            label: 'Calendar',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'location', 'startDateReminder', 'startDateReminderDays', 'steeringGroupAttendees',
            ],
        },
        document: {
            label: 'Document',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible', 'stage1Approval', 'stage2Approval',
            ],
        },
        publish: {
            label: 'Publish',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible', 'stage1Approval', 'stage2Approval', 'approveSupplier', 'publishType', 'immediateResponse', 'questionnaireType', 'publishDestinations', 'dpsRulesApplicable',
            ],
        },
        notice: {
            label: 'Notice',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible', 'stage1Approval', 'stage2Approval', 'noticeType',
            ],
        },
        evaluation: {
            label: 'Evaluation',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible', 'stage1Approval', 'stage2Approval',
            ],
        },
        issueAwardUnsuccessfulNotices: {
            label: 'Issue Award/Unsuccessful Notices',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible', 'stage1Approval', 'stage2Approval', 'intendProcedure',
            ],
        },
        supplierDocumentation: {
            label: 'Supplier Documentation',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'rolesResponsible',
            ],
        },
        marketUnderstanding: {
            label: 'Market Understanding',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible',
            ],
        },
        demandManagement: {
            label: 'Demand Management',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible',
            ],
        },
        costManagement: {
            label: 'Cost Management',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible',
            ],
        },
        performanceMonitoringSupplierRelations: {
            label: 'Performance Monitoring & Supplier Relations',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible',
            ],
        },
        riskManagement: {
            label: 'Risk Management',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible',
            ],
        },
        updateContractManagementPlan: {
            label: 'Update Contract Management Plan',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible',
            ],
        },
        exitStrategy: {
            label: 'Exit Strategy',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible',
            ],
        },
        extension: {
            label: 'Extension',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible',
            ],
        },
        variation: {
            label: 'Variation',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible',
            ],
        },
        renewContract: {
            label: 'Renew Contract',
            fields: [
                'name', 'description', 'startDate', 'duration', 'endDate', 'startDateReminder', 'startDateReminderDays', 'endDateReminder', 'endDateReminderDays', 'rolesResponsible',
            ],
        },
    },
    publishTypes: [
        null,
        { label: 'Request For Anything', value: 'invitationToTender' },
        { label: 'Market Consultation', value: 'marketConsultation' },
        { label: 'Selection', value: 'selection' },
    ],
    questionnaireTypes: [
        { label: 'Selection Questionnaire', value: 'selection' },
        { label: 'Tender Questionnaire', value: 'tender' },
        { label: 'Award Questionnaire', value: 'award' },
        { label: 'Market Consultation Questionnaire', value: 'marketConsultation' },
        { label: 'Additional Questionnaire', value: 'additional' },
    ],
    noticeTypes: [
        null,
        { label: 'PIN', value: 'pin' },
        { label: 'Contracts Notice', value: 'contractsNotice' },
        { label: 'Contract Award Notice', value: 'contractAwardNotice' },
        { label: 'Contracts Register', value: 'contractsRegister' },
    ],
    contractManagementEventTypes: [
        null,
        { label: 'Supplier Documentation', value: 'supplierDocumentation' },
        { label: 'Market Understanding', value: 'marketUnderstanding' },
        { label: 'Demand Management', value: 'demandManagement' },
        { label: 'Cost Management', value: 'costManagement' },
        { label: 'Performance Monitoring & Supplier Relations', value: 'performanceMonitoringSupplierRelations' },
        { label: 'Risk Management', value: 'riskManagement' },
        { label: 'Update Contract Management Plan', value: 'updateContractManagementPlan' },
        { label: 'Exit Strategy', value: 'exitStrategy' },
        { label: 'Extension Event', value: 'extension' },
        { label: 'Variation Event', value: 'variation' },
        { label: 'Renew Contract Event', value: 'renewContract' },
    ],
    eventTypes: [
        null,
        { label: 'Calendar', value: 'calendar' },
        { label: 'Document', value: 'document' },
        { label: 'Publish Event', value: 'publish' },
        { label: 'Notice Event', value: 'notice' },
        { label: 'Evaluation', value: 'evaluation' },
        { label: 'Issue Award/Unsuccessful Notices', value: 'issueAwardUnsuccessfulNotices' },
    ],
};

export default Constants;
