import React from 'react';
import PropTypes from 'prop-types';

import {
    LabelComponent,
    InputComponent,
} from './components';

import FTSF01NoticeForm from './FTSF01NoticeForm';
import FTSF02NoticeForm from './FTSF02NoticeForm';
import FTSF03NoticeForm from './FTSF03NoticeForm';

const FTSNoticeForm = ({
    project,
    contract,
    suppliers,
    noticeEvent,
    extra,
    setExtra,
    handleChange,
    setShowCodesModal,
    generateCodeTag,
}) => {
    const renderFTSNoticeSubForm = (noticeType) => {
        switch (noticeType) {
            case 'preprocurement':
                return (
                    <FTSF01NoticeForm
                        project={project}
                        noticeEvent={noticeEvent}
                    />
                );
            case 'liveOpportunity':
                return (
                    <FTSF02NoticeForm
                        project={project}
                        noticeEvent={noticeEvent}
                    />
                );
            case 'award':
                return (
                    <FTSF03NoticeForm
                        project={project}
                        contract={contract}
                        suppliers={suppliers}
                        noticeEvent={noticeEvent}
                        extra={extra}
                        setExtra={setExtra}
                        handleChange={handleChange}
                        setShowCodesModal={setShowCodesModal}
                        generateCodeTag={generateCodeTag}
                    />
                );
            default:
                return (
                    <h3 className='title-large subHeading'>Invalid notice type selected</h3>
                );
        }
    };

    return (<>
        <section id='viewPublishNoticesForm'>
            <div>
                <h3 className='title-large subHeading'>Summary Information</h3>
                <LabelComponent
                    label='Procurement Reference'
                    value={project?.projectReference}
                />
                <InputComponent
                    id='project.title'
                    label='Notice Title'
                    type='text'
                    value={project?.title}
                    required={'Enter Title'}
                    onChange={handleChange}
                />
                {renderFTSNoticeSubForm(noticeEvent?.noticeType)}
            </div>
        </section>
    </>);
};

FTSNoticeForm.propTypes = {
    project: PropTypes.object.required,
    contract: PropTypes.object.required,
    suppliers: PropTypes.array.required,
    noticeEvent: PropTypes.object.required,
    extra: PropTypes.object.required,
    setExtra: PropTypes.func.required,
    handleChange: PropTypes.func.required,
    setShowCodesModal: PropTypes.func.required,
    generateCodeTag: PropTypes.func.required,
};
export default FTSNoticeForm;
