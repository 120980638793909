import React from 'react';
import PropTypes from 'prop-types';

import LabelledRadioButton from '../../../components/LabelledRadioButton';

const RadioButtonComponent = ({
    id,
    label,
    value,
    options = {
        yes: 'Yes',
        no: 'No',
    },
    required = false,
    disabled = false,
    ...props
}) => {
    const buildOptions = (opts, val) => (
        Object.keys(opts).map((key) => ({
            id: `${key}-${id}`,
            value: key,
            label: opts[key],
            name: id,
            checked: (val === key),
            disabled,
        }))
    );

    return (
        <div className='vpnItem'>
            <div className=''>
                <LabelledRadioButton
                    breakColumn={false}
                    id={id}
                    label={label}
                    placeholder={label}
                    renderAsRow={true}
                    type='radio'
                    value={value}
                    options={buildOptions(options, value)}
                    required={required}
                    { ...props }
                />
            </div>
        </div>
    );
};

RadioButtonComponent.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.array,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
};


export default RadioButtonComponent;
