import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import RegistrationHeader from '../../features/SupplierRegistration/components/RegistrationHeader';
import SelectionPills from '../../features/SupplierRegistration/components/SelectionPills';
import constants from '../../features/SupplierRegistration/constants';
import Form from '../../components/Form';
import ProgressBar from '../../features/ProgressBar';
import Button from '../../components/Button';
// import SelectCodesModal from '../../features/CodesModal/SelectCodesModal';
import actions from '../../features/CodesModal/slice/codes.action';
import GuidanceBanner from '../../components/GuidanceBanner';

const SelectCodesModal = React.lazy(() => import('../../features/CodesModal/SelectCodesModal'));

const CodesAndCategories = () => {
    const history = useHistory();
    const [showCodesModal, setShowCodesModal] = useState(false);
    const dispatch = useDispatch();

    const { companyDetailForm, initCodesData, onlySelectedCodes } = useSelector((state) => ({
        companyDetailForm: state.SupplierRegReducer.companyDetails,
        initCodesData: state.CodesReducer.selectedCodes,
        onlySelectedCodes: state.CodesReducer.onlySelectedCodes,
    }));

    const [data, setData] = useState(onlySelectedCodes);

    useEffect(() => {
        if (companyDetailForm.accountName === '') {
            // No Company Details Error.
            history.push('/');
        }
    }, []);

    const toggleModal = () => {
        setShowCodesModal(!showCodesModal);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        history.push('/registration/regions-of-supply');
    };

    const removeCodesSelection = (parent, level) => {
        parent.map((el) => {
            const elem = el;
            if (el.level === level) {
                elem.selected = false;
            }
            if (el.children) removeCodesSelection(el.children, level);
            return null;
        });
        return initCodesData;
    };

    const updateCodesSelectionView = (pillName) => {
        dispatch(actions.setOnlySelectedCodes(onlySelectedCodes
            .filter((el) => el.level !== pillName)));
    };

    const handlePillDelete = (pillName) => {
        removeCodesSelection(initCodesData, pillName);
        updateCodesSelectionView(pillName);
    };

    const removePill = (pillName) => {
        handlePillDelete(pillName);
    };

    const prev = (event) => {
        event.preventDefault();
        history.goBack();
    };


    useEffect(() => {
        setData(onlySelectedCodes);
    }, [onlySelectedCodes]);

    return <>
        <ProgressBar options={constants.progressBar}
            activeStep={2}
            id='company-reg-progress'
            context='LeftToRight' />
        <GuidanceBanner
            id={'codesGuidanceBanner'}
            type={''}
            bannerText1={'To ensure you are notified about the right opportunities, you need to assign Codes and Categories to your company account. Codes and Categories show buyers what goods or services you offer - you can select multiple and drill down into sub-categories.'}
            bannerText2={'Not sure about which codes to choose? Don’t worry! You can choose some now, and edit your codes later on in the ‘My Profile’ area.'}
        />
        <section className='nepo-container registration-form'>
            <div className='wrapper' >
                <Form id='cnc-form' onSubmit={(e) => handleSubmit(e)}>
                    <RegistrationHeader addAction={toggleModal} headingLabel='Codes and Categories Selected' />
                    <SelectionPills whenEmptyShow='No industry codes selected. Please select at least one code.' data={data} removePillItemAction={removePill} />
                    <div className='form-btn-wrapper'>
                        <Button id='second-action-btn'
                            additionalVariant='ros-back'
                            variant='secondary'
                            label='Back'
                            handleClick={(event) => prev(event)} />
                        <Button id='main-action-btn'
                            label='Continue'
                            disabled={data.length === 0}
                            handleClick={() => null} />
                    </div>
                </Form>
            </div>
        </section>
        {showCodesModal && <SelectCodesModal
            closeModal={toggleModal}
            initialData = {data} /> }
    </>;
};
export default CodesAndCategories;
