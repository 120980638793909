import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

/**
 * A simple wrapper of the pills that are used everywhere in the app.
 * For now it does NOT support the ones that have the REMOVE button
 * @param {String} content text that'll be within the pill
 * @param {String} destination (optional) link for the text to take you to
 * @returns {HTMLElement} the green pill with standard styles
 */
const PillLarge = ({ content, destination = undefined }) => <div className={'pill-large'}>
    <div className={'text-container'}>
        <a className={'text-holder'} href={destination}>
            {content}
        </a>
    </div>
</div>;

PillLarge.propTypes = {
    content: PropTypes.string.isRequired,
    destination: PropTypes.string.isOptional,
};
export default PillLarge;
