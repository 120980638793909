import React from 'react';
import PropTypes from 'prop-types';
import DashboardCard from '../../../../components/DashboardCard';

const SolutionUsersCard = (props) => {
    function getDepartmentName(departmentID, departments) {
        const department = departments.find((dept) => dept.departmentID === departmentID);

        return department?.departmentName ?? 'No Department Specified';
    }

    const CardContent = () => <>
        <div className='departments'>
            {props.solutionAccessRequests?.length > 0
                && props.solutionAccessRequests.map((req, idx) => req.account && <p className='approved-department caption' key={idx}>
                    {`${getDepartmentName(req.departmentID, req.account.departments)} (${req.account.companyName})`}
                </p>)}
            {props.solutionAccessRequests?.length === 0
                && <p className='caption'>No departments have approved access for this solution.</p>}
        </div>
    </>;

    return <DashboardCard
        id='solutionUsersCard'
        size='large'
        header={true}
        headerTitle={'Solution Users'}
        content={CardContent()} />;
};

SolutionUsersCard.propTypes = {
    solutionAccessRequests: PropTypes.array,
};

export default SolutionUsersCard;
