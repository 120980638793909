import { DiffMethod } from 'react-diff-viewer';
import Constants from './constants';

const getModeOptions = () => [
    { name: Constants.DIFF_MODE.CHARS, value: DiffMethod.CHARS },
    { name: Constants.DIFF_MODE.WORDS, value: DiffMethod.WORDS },
    { name: Constants.DIFF_MODE.WORDS_WITH_SPACE, value: DiffMethod.WORDS_WITH_SPACE },
    { name: Constants.DIFF_MODE.LINES, value: DiffMethod.LINES },
    { name: Constants.DIFF_MODE.TRIMMED_LINES, value: DiffMethod.TRIMMED_LINES },
    { name: Constants.DIFF_MODE.SENTENCES, value: DiffMethod.SENTENCES },
];

export default getModeOptions;
