import constants from './constants';

const setSupplierState = (payload) => ({
    type: constants.SET_SUPPLIERSTATE,
    payload,
});

export default {
    setSupplierState,
};
