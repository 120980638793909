import React, { useEffect, useContext } from 'react';
import { addMinutes, isAfter } from 'date-fns';

import AuthContext from '../../context/AuthContext';
import useLocalStorage from '../../utils/useLocalStorage';

const UserAccessRefresh = () => {
    const refreshInterval = 10; // 10 mins
    const checkInterval = 10000; // 10 seconds

    const authContext = useContext(AuthContext);

    const updateRefreshTime = () => addMinutes(new Date(), refreshInterval);

    const [refreshTimeout, setRefreshTime] = useLocalStorage('refreshTimeout', updateRefreshTime());

    const checkTimeout = () => {
        const currentTime = new Date();
        const refreshTime = new Date(Date.parse(refreshTimeout));

        if (isAfter(currentTime, refreshTime)) {
            setRefreshTime(updateRefreshTime());
            authContext.refresh();
        }
    };

    useEffect(() => {
        const intervalID = setInterval(checkTimeout, checkInterval);

        return () => {
            clearInterval(intervalID);
        };
    }, [refreshTimeout]);

    return <></>;
};

export default UserAccessRefresh;
