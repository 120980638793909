import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Constants from './constants';
import BrowseData from '../../features/BrowseData';
import userManagementAPIs from '../../services/user-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import actionsLocality from '../../features/LocalityModal/slice/supplyLocalities.action';

const BrowseSuppliers = () => {
    const [suppliers, setSuppliers] = useState([]);

    const dispatch = useDispatch();

    const fetchData = async () => {
        try {
            const response = await userManagementAPIs.getAllSuppliers();
            if (response.status === 200) {
                setSuppliers(response.data.items);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const cleanupFilter = () => {
        dispatch(actionsLocality.clearLocalities());
    };

    useEffect(() => {
        cleanupFilter();
        fetchData();
    }, []);

    return <BrowseData
        id='browseSuppliers'
        context='Suppliers'
        contextSingular='Supplier'
        dataSet={suppliers}
        searchPlaceholder='Search for a supplier'
        numOfRowsPerPage={15}
        searchFilterAttribute='accountName'
        sortOptions={Constants.sortOptions}
    />;
};

export default BrowseSuppliers;
