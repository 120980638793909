import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DocumentBuilder from '../../features/DocumentBuilder';
import projectManagementAPIs from '../../services/project-management.service';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';

const ViewStage = () => {
    const { stageId } = useParams();
    const [type, setType] = useState('');
    const [initialState, setInitialState] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const responseData = await projectManagementAPIs.getStage(stageId);
            if (responseData.status === 200) {
                const preparedData = helperFunctions
                    .prepareDocumentBuilderState('workflow', { stages: [responseData.data] }, stageId);
                setInitialState(preparedData);
                setType(preparedData.details.type);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        };
        if (stageId) {
            fetchData();
        }
    }, [stageId]);

    return Object.keys(initialState).length > 0 ? <DocumentBuilder
        documentMode={'View'}
        context='Stage'
        type={type}
        initialDocumentState={initialState}
        sectionLabel='Stage'
        sectionItemLabel='Event'
        allowSectionReordering={false}
        allowSectionItemsReordering={false}
    /> : <></>;
};

export default ViewStage;
