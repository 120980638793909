import React from 'react';
import PropTypes from 'prop-types';

const ScreenOverlay = ({ handleClick, isStackTop = false }) => <div className={ `screen-overlay${isStackTop ? ' stack-top' : ''}` } onClick={handleClick}></div>;

ScreenOverlay.propTypes = {
    handleClick: PropTypes.func,
    isStackTop: PropTypes.bool,
};

export default ScreenOverlay;
