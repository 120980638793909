import constants from '../../../reducers/_constants';

const initialState = {
    companyDetails: {
        accountName: '',
        businessStructure: '',
        natureOfBusiness: '',
        vatNumber: '',
        registrationNumber: '',
        noOfEmployees: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        county: '',
        postcode: '',
        country: '',
        companyCountryCode: '',
        phoneNumber: '',
    },
    accountDetails: {
        email: '',
        firstname: '',
        surname: '',
        jobTitle: '',
        department: '',
        mobile: '',
        countryCode: '',
    },
    newRODetails: {
        roAccountName: '',
        roAddressLine1: '',
        roAddressLine2: '',
        roCity: '',
        roCounty: '',
        roPostcode: '',
        roCountry: '',
        roBusinessStructure: '',
        roNatureOfBusiness: '',
        roVatNumber: '',
        roRegistrationNumber: '',
        roNoOfEmployees: '',
        roCompanyCountryCode: '',
        roPhoneNumber: '',
    },
    hiddenDetails: {
        accountID: '',
        departments: [],
        wishToJoin: true,

    },
    selectedHQROData: {
        companyDetails: {},
        selectedROAccountID: '',
        selectedHQAccountID: '',
    },
    userType: '',
    companyType: '',
    showRO: false,
    showROForm: false,
    consentStatus: {
        tcpp: false,
        consentEmail: false,

    },
    currentStep: 1,
    initiateLogin: false,
    accountExists: false,
    joinAccountId: '',
};

const SupplierRegReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SupplierRegistrationConstants.SET_CURRENTSTEP: {
            return {
                ...state,
                currentStep: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.SET_USERTYPE: {
            return {
                ...state,
                userType: action.payload.userType,
            };
        }
        case constants.SupplierRegistrationConstants.SET_COMPANYTYPE: {
            return {
                ...state,
                companyType: action.payload.companyType,
            };
        }
        case constants.SupplierRegistrationConstants.SET_CONSENTSTATUS: {
            return {
                ...state,
                consentStatus: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.SET_COMPANYDETAILS: {
            return {
                ...state,
                companyDetails: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.SET_HIDDENDETAILS: {
            return {
                ...state,
                hiddenDetails: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.SET_SELECTEDHQRODATA: {
            return {
                ...state,
                selectedHQROData: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.SET_ACCOUNTDETAILS: {
            return {
                ...state,
                accountDetails: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.SET_NEWRODETAILS: {
            return {
                ...state,
                newRODetails: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.SET_SHOWRO: {
            return {
                ...state,
                showRO: action.payload.showRO,
            };
        }
        case constants.SupplierRegistrationConstants.SET_SHOWROFORM: {
            return {
                ...state,
                showROForm: action.payload.showROForm,
            };
        }
        case constants.SupplierRegistrationConstants.INITIATE_LOGIN: {
            return {
                ...state,
                initiateLogin: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.RESET_REG_FORM: {
            return {
                ...initialState,
            };
        }
        case constants.SupplierRegistrationConstants.ACCOUNT_EXISTS: {
            return {
                ...state,
                accountExists: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.JOIN_ACCOUNT_ID: {
            return {
                ...state,
                joinAccountId: action.payload,
            };
        }
        default:
            return state;
    }
};

export default SupplierRegReducer;
