import React from 'react';
import propTypes from 'prop-types';
import Modal from '../../components/Modal';

const ConfirmModal = ({
    body, closeModal, submitForm, size = 'medium',
}) => (
    <Modal
        open={true}
        id='confirmModal'
        size={size}
        headerTitle='Are you sure?'
        mainActionButtonTxt='Yes'
        handleMainActionBtnClick={submitForm}
        secondActionButton
        secondActionButtonTxt='No'
        handleSecondaryActionBtnClick={closeModal}
        body={body}
        helpOption={false}
        closeModal={closeModal}
    />
);


ConfirmModal.propTypes = {
    body: propTypes.element.isRequired,
    submitForm: propTypes.func.isRequired,
    closeModal: propTypes.func.isRequired,
    size: propTypes.string,
};
export default ConfirmModal;
