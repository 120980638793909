import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { set } from 'lodash';

// UI
import Toast from '../../components/Alerts/Toast/Toast';

import ContractsFinderNoticeForm from './ContractsFinderNoticeForm';
import FTSNoticeForm from './FTSNoticeForm';

// APIs
import projectManagementAPIs from '../../services/project-management.service';

// Components
import Form from '../../components/Form';
import Button from '../../components/Button';
import LabelledInput, { LabelledInputTypeSubmit } from '../../components/LabelledInput';
import { ErrorMessagesFor } from './components';

// Pickers
import Tag from '../../components/Tag';

// Config
import { noticeTypes } from '../../config/constants';

// Helpers
import {
    getProjectEvent,
    createNoticePayload,
    createNotice,
    validateNotice,
} from './index.helpers';

// Lazy loaded components
const SelectCPVCodesModal = React.lazy(() => import('../CodesModalCPV/SelectCPVCodesModal'));

const NoticeForm = () => {
    const history = useHistory();
    const { projectId, eventId } = useParams();

    const [project, setProject] = useState(null);
    const [noticeEvent, setNoticeEvent] = useState(null);
    const [contract, setContract] = useState(null);
    const [noticePublisher, setNoticePublisher] = useState('CF');
    const [extra, setExtra] = useState({});
    const [suppliers, setSuppliers] = useState([]);
    const [showCodesModal, setShowCodesModal] = useState(false);

    const [errorMessages, setErrorMessages] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = (e, fieldName) => {
        let name;
        let value;

        if (e?.target) {
            name = e.target.name;
            value = e.target.value;
        } else {
            // Handle SelectSearchComponent
            name = fieldName;
            value = e;
        }

        const nodeTree = name.split('.');
        const obj = nodeTree.shift();
        let stateSetterFn;

        switch (obj) {
            case 'project':
                stateSetterFn = setProject;
                break;
            case 'contract':
                stateSetterFn = setContract;
                break;
            case 'extra':
                stateSetterFn = setExtra;
                break;
            case 'suppliers':
                stateSetterFn = setSuppliers;
                break;
            case 'noticeEvent':
                stateSetterFn = setNoticeEvent;
                break;
            default:
                // console.error(obj, 'does not have a setState function');
                break;
        }

        stateSetterFn((prevState) => (
            { ...set((prevState || {}), nodeTree.join('.'), value) }
        ));
    };

    const handleCancel = (event) => {
        event.preventDefault();
        history.goBack();
    };

    const handleErrors = (errors) => {
        setErrorMessages(errors);

        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessages([]);
        setFormSubmitted(false);

        const params = {
            project,
            contract,
            noticeEvent,
            extra,
            suppliers,
            noticePublisher,
        };

        setFormSubmitted(true);

        const payload = createNoticePayload(params);
        const validation = await validateNotice(payload);

        if (validation.valid) {
            const response = await createNotice(projectId, eventId, payload);

            if (response) {
                if (response.data.Errors) {
                    return handleErrors(response.data.Errors.body || response.data.Errors);
                }

                if (response.status !== 200) {
                    return handleErrors(response.data.Message.split(' - ')[1]);
                }

                if (response.status === 200) {
                    return history.push(`/projects/overview/${projectId}`);
                }
            }

            handleErrors('There was a problem submitting the form');
            return false;
        }

        handleErrors(validation.errors);

        return false;
    };


    const handleUpdateCPVCodes = (data) => {
        setExtra((prevState) => ({
            ...prevState,
            cpvCodes: data.map((code) => code.code),
            cpvInfo: data.map((code) => ({
                code: code.code,
                description: code.description,
                currentLevel: code.currentLevel,
            })),
        }));
    };

    const handleRemoveCPVCode = (data) => {
        const codes = extra.cpvCodes.filter((code) => code !== data.code);
        const info = extra.cpvInfo.filter((code) => code.code !== data.code);

        setExtra((prevState) => ({
            ...prevState,
            cpvCodes: codes,
            cpvInfo: info,
        }));
    };

    const generateCodeTag = (data, isDeletable) => {
        const TagList = [];

        data.forEach((item) => {
            const TagElement = <Tag key={item.code}
                id={item.code}
                tagTxt={`${item.code} - ${item.description}`}
                isDeletable={isDeletable}
                size='large'
                handleDeleteBtnClick={() => handleRemoveCPVCode(item)}
            />;
            TagList.push(TagElement);
        });

        return (
            <div className='vpnItemTags'>
                {TagList}
            </div>
        );
    };

    const getLotSuppliers = (supplierData) => {
        const supplierArray = [];

        if (contract) {
            supplierData.forEach((lot) => {
                lot.suppliers.forEach((item) => {
                    if (!supplierArray.some((supplier) => (
                        supplier.accountID === item.accountID
                    ))) {
                        supplierArray.push(item);
                    }
                });
            });
        }

        return supplierArray;
    };

    // 1. Side effect of setting projectId and eventId from the URL params
    useEffect(() => {
        const getProject = async () => {
            try {
                const projectResponse = await projectManagementAPIs.getProject(projectId);
                if (projectResponse.status === 200) {
                    setProject(projectResponse.data);
                } else {
                    throw new Error('Unable to retrieve project information');
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: error.message || 'Unable to retrieve information.',
                });
            }
        };

        getProject();
    }, [projectId, eventId]);

    // 2. Side effect of loading project details
    useEffect(() => {
        const stages = project?.workflow?.stages;

        if (stages) {
            const event = getProjectEvent(stages, eventId);

            if (event) {
                setNoticeEvent(event);
            }

            setNoticePublisher(event.noticePublisher ? event.noticePublisher : 'FTS');
        }
    }, [project]);

    // 3. Side effect of loading project with a contractID
    useEffect(() => {
        const getContract = async () => {
            if (project?.contractID) {
                try {
                    const contractResponse = await projectManagementAPIs
                        .getContract(project.contractID);

                    if (contractResponse.status === 200) {
                        setContract(contractResponse.data);
                    } else {
                        throw new Error('Unable to retrieve contract information');
                    }
                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        titleText: error.message || 'Unable to retrieve information.',
                    });
                }
            }
        };

        getContract();
    }, [project?.contractID]);

    // 4. Side effect of loading a contract - get the awarded suppliers
    useEffect(() => {
        let awardedSuppliers = [];

        if (contract?.publishLevel === 'lot') {
            awardedSuppliers = getLotSuppliers(contract?.awardedSuppliers);
        }

        if (contract?.publishLevel === 'project') {
            awardedSuppliers = contract?.awardedSuppliers;
        }

        // Set the default contact detail to the supplier user if present
        awardedSuppliers.forEach((supplier) => {
            // eslint-disable-next-line no-param-reassign
            supplier.contact = supplier.user?.contactDetails?.username;
        });

        setSuppliers({ awardedSuppliers });
    }, [contract]);

    return (
        <section id='viewPublishNoticesSection'>
            {project && noticeEvent && extra
             && noticePublisher === 'CF'
                ? <Form id='viewPublishNoticesForm' autocomplete='off' className={formSubmitted ? 'submitted' : ''} onSubmit={handleSubmit} noValidate={true}>
                    <h2 className='title-xLarge heading'>
                        Enter details for {
                            noticeTypes.FTS[noticeEvent?.noticeType]
                        } notice
                    </h2>

                    <div className='vpnDivider'></div>

                    <ErrorMessagesFor
                        errorMessages={errorMessages}
                    />

                    <ContractsFinderNoticeForm
                        project={project}
                        contract={contract}
                        suppliers={suppliers}
                        noticeEvent={noticeEvent}
                        extra={extra}
                        handleChange={handleChange}
                        setShowCodesModal={setShowCodesModal}
                        generateCodeTag={generateCodeTag}
                    />

                    <div className='vpnItemButtonGroup'>
                        <Button
                            id='cancel'
                            label='Cancel'
                            variant='secondary'
                            handleClick={handleCancel}
                            disabled={false}
                        />

                        <div className='buttonDivider'></div>

                        <LabelledInput
                            id='submit'
                            type={LabelledInputTypeSubmit}
                            value='Create'
                            label=''
                            breakColumn={false}
                        />
                    </div>
                </Form>
                //   {noticePublisher === 'FTS' &&
                : <FTSNoticeForm
                    project={project}
                    contract={contract}
                    suppliers={suppliers}
                    noticeEvent={noticeEvent}
                    extra={extra}
                    setExtra={setExtra}
                    handleChange={handleChange}
                    setShowCodesModal={setShowCodesModal}
                    generateCodeTag={generateCodeTag}
                />
            }

            {showCodesModal
            && <SelectCPVCodesModal
                closeModal={() => setShowCodesModal(false)}
                onSubmit={handleUpdateCPVCodes}
                selectedCodes={extra?.cpvCodes}
            />}
        </section>
    );
};

export default NoticeForm;
