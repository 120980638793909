import constants from './constants';

const getFilterPropertiesByFieldName = (fieldName) => {
    switch (fieldName.toLowerCase()) {
        case constants.filterFields.STATUS:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.STATUS,
                stateKey: 'status',
                dynamicOptions: false,
            };
        case constants.filterFields.CONTRACT_STATUS:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.STATUS,
                stateKey: 'contractStatus',
                dynamicOptions: false,
            };
        case constants.filterFields.RIGOUR_LEVEL:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.RIGOUR_LEVEL,
                stateKey: 'rigour',
                dynamicOptions: false,
            };
        case constants.filterFields.PROJECT_STATUS:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.STATUS,
                stateKey: 'projectStatus',
                dynamicOptions: false,
            };
        case constants.filterFields.WORKFLOWTYPE:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.WORKFLOW_TYPE,
                stateKey: 'workflowType',
                dynamicOptions: false,
            };
        case constants.filterFields.EVENTTYPE:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.EVENT_TYPE,
                stateKey: 'eventType',
                dynamicOptions: false,
            };
        case constants.filterFields.STAGETYPE:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.STAGE_TYPE,
                stateKey: 'stageCategory',
                dynamicOptions: false,
            };
        case constants.filterFields.RTM:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.ROUTE_MARKET,
                stateKey: 'route',
                dynamicOptions: false,
            };
        case constants.filterFields.DEPARTMENTS:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.DEPARTMENT,
                stateKey: 'departmentID',
                dynamicOptions: true,
            };
        case constants.filterFields.AUTHORITY:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.AUTHORITY,
                stateKey: 'accountID',
                dynamicOptions: true,
            };
        case constants.filterFields.CREATED_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: constants.filterLabels.CREATED_BETWEEN,
                stateKey: 'createdOn',
            };
        case constants.filterFields.PRFS_STARTING_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: constants.filterLabels.PRFS_STARTING_BETWEEN,
                stateKey: 'requestStartRange',
            };
        case constants.filterFields.CONTRACTS_STARTING_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: constants.filterLabels.CONTRACTS_STARTING_BETWEEN,
                stateKey: 'contractStart',
            };
        case constants.filterFields.CONTRACTS_ENDING_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: constants.filterLabels.CONTRACTS_ENDING_BETWEEN,
                stateKey: 'contractEnd',
            };
        case constants.filterFields.CODESANDCATEGORIES:
            return {
                filterType: constants.filterTypes.CODESANDCATEGORIES,
                label: constants.filterLabels.CODES_AND_CATEGORIES,
            };
        case constants.filterFields.DELIVERYAREA:
            return {
                filterType: constants.filterTypes.DELIVERYAREA,
                label: constants.filterLabels.DELIVERY_AREA,
            };
        case constants.filterFields.LOCALITY:
            return {
                filterType: constants.filterTypes.LOCALITY,
                label: constants.filterLabels.LOCALITY,
            };
        case constants.filterFields.QUESTIONNAIRETYPE:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.QUESTIONNAIRE_TYPE,
                stateKey: 'templateType',
                dynamicOptions: false,
            };
        case constants.filterFields.QUESTIONNAIRE_STATUS:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.STATUS,
                stateKey: 'templateStatus',
                dynamicOptions: false,
            };
        case constants.filterFields.COMPANY_TYPE:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.COMPANY_TYPE,
                stateKey: 'companyType',
                dynamicOptions: false,
            };
        case constants.filterFields.EXPRESSION_WINDOW_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: constants.filterLabels.EXPRESSION_WINDOW_BETWEEN,
                stateKey: 'expressionDate',
            };
        case constants.filterFields.SUBMISSION_WINDOW_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: constants.filterLabels.SUBMISSION_WINDOW_BETWEEN,
                stateKey: 'submissionBetween',
            };
        case constants.filterFields.COMPANY_SIZE_BETWEEN:
            return {
                filterType: constants.filterTypes.INPUT_RANGE,
                label: constants.filterLabels.COMPANY_SIZE_BETWEEN,
                stateKeyFrom: 'noOfEmployeesFrom',
                stateKeyTo: 'noOfEmployeesTo',
            };
        case constants.filterFields.POSTCODE:
            return {
                filterType: constants.filterTypes.INPUT,
                label: constants.filterLabels.POSTCODE,
                stateKey: 'postcode',
            };
        case constants.filterFields.ESTIMATED_VALUE_BETWEEN:
            return {
                filterType: constants.filterTypes.INPUT_RANGE,
                label: constants.filterLabels.ESTIMATED_VALUE_BETWEEN,
                stateKeyFrom: 'estimatedValueFrom',
                stateKeyTo: 'estimatedValueTo',
            };
        case constants.filterFields.CALL_OFF_MECHANISM:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.CALL_OFF_MECHANISM,
                stateKey: 'callOffMechanism',
                dynamicOptions: false,
            };
        case constants.filterFields.STATUS_CALL_OFF:
            return {
                filterType: constants.filterTypes.SELECT,
                label: constants.filterLabels.STATUS,
                stateKey: 'callOffStatus',
                dynamicOptions: false,
            };
        case constants.filterFields.SUBMISSION_ENDING_BETWEEN:
            return {
                filterType: constants.filterTypes.RANGE,
                label: constants.filterLabels.SUBMISSION_ENDING_BETWEEN,
                stateKey: 'submissionEndDate',
            };
        default:
            return {};
    }
};

const getSelectOptionsByField = (fieldName) => {
    switch (fieldName.toLowerCase()) {
        case constants.filterFields.STATUS:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Disabled',
                value: 'false',
            },
            {
                label: 'Active',
                value: 'true',
            }];
        case constants.filterFields.CONTRACT_STATUS:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Cancelled',
                value: 'cancelled',
            },
            {
                label: 'Active',
                value: 'active',
            }];
        case constants.filterFields.QUESTIONNAIRE_STATUS:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Draft',
                value: 'draft',
            },
            {
                label: 'Created',
                value: 'created',
            }];
        case constants.filterFields.RIGOUR_LEVEL:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Platinum',
                value: 'platinum',
            },
            {
                label: 'Gold',
                value: 'gold',
            },
            {
                label: 'Silver',
                value: 'silver',
            }];
        case constants.filterFields.PROJECT_STATUS:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Created',
                value: 'created',
            },
            {
                label: 'Open',
                value: 'open',
            }, {
                label: 'Cancelled',
                value: 'cancelled',
            }];
        case constants.filterFields.WORKFLOWTYPE:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Project Management',
                value: 'Project Management',
            },
            {
                label: 'Contract Management',
                value: 'Contract Management',
            }];
        case constants.filterFields.EVENTTYPE:
            return [{
                label: '',
                value: '',
            }, {
                label: 'Calendar Event',
                value: 'calendar',
            }, {
                label: 'Document Event',
                value: 'document',
            }, {
                label: 'Publish Event',
                value: 'publish',
            }, {
                label: 'Notice Event',
                value: 'notice',
            }, {
                label: 'Evaluation Event',
                value: 'evaluation',
            }, {
                label: 'Issue Award/Unsuccessful Notices Event',
                value: 'issueAwardUnsuccessfulNotices',
            },
            {
                label: 'Supplier Documentation',
                value: 'supplierDocumentation',
            },
            {
                label: 'Market Understanding',
                value: 'marketUnderstanding',
            },
            {
                label: 'Demand Management',
                value: 'demandManagement',
            },
            {
                label: 'Cost Management',
                value: 'costManagement',
            },
            {
                label: 'Performance Monitoring & Supplier Relations',
                value: 'performanceMonitoringSupplierRelations',
            },
            {
                label: 'Risk Management',
                value: 'riskManagement',
            },
            {
                label: 'Update Contract Management Plan',
                value: 'updateContractManagementPlan',
            },
            {
                label: 'Exit Strategy',
                value: 'exitStrategy',
            },
            {
                label: 'Extension Event',
                value: 'extension',
            },
            {
                label: 'Variation Event',
                value: 'variation',
            },
            {
                label: 'Renew Contract Event',
                value: 'renewContract',
            },
            ];
        case constants.filterFields.STAGETYPE:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'pre-procurement',
                value: 'pre-procurement',
            },
            {
                label: 'e-tendering',
                value: 'e-tendering',
            },
            {
                label: 'contract-management',
                value: 'contract-management',
            }];
        case constants.filterFields.RTM:
            return [{
                label: '',
                value: '',
            }, {
                label: 'Not Selected',
                value: '_',
            }, {
                label: 'Open Procedure',
                value: 'openProcedure',
            }, {
                label: 'Restricted Procedure',
                value: 'restrictedProcedure',
            }, {
                label: 'Competitive Procedure with Negotiation',
                value: 'CPN',
            }, {
                label: 'Competitive Procedure with Dialogue',
                value: 'CPD',
            }, {
                label: 'Innovation Partnership',
                value: 'innovationPartnership',
            }, {
                label: 'Request for Quotation',
                value: 'requestQuotation',
            }, {
                label: 'Request for Quotation - Clever Quotes',
                value: 'requestQuotationCleverQuotes',
            }, {
                label: 'Under Threshold',
                value: 'underThreshold',
            }, {
                label: 'Direct Award',
                value: 'directAward',
            }, {
                label: 'Established Solution',
                value: 'establishedSolution',
            }];
        case constants.filterFields.AUTHORITY:
            // TODO: Waiting API integration
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Disabled',
                value: 'true',
            },
            {
                label: 'Active',
                value: 'false',
            }];
        case constants.filterFields.QUESTIONNAIRETYPE:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Selection Questionnaire',
                value: 'selection',
            },
            {
                label: 'Tender Questionnaire',
                value: 'tender',
            },
            {
                label: 'Award Questionnaire',
                value: 'award',
            },
            {
                label: 'Market Consultation Questionnaire',
                value: 'marketConsultation',
            },
            {
                label: 'Additional Questionnaire',
                value: 'additional',
            }];
        case constants.filterFields.COMPANY_TYPE:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Public Limited Company',
                value: 'Public Limited Company',
            },
            {
                label: 'Private Limited Company',
                value: 'Private Limited Company',
            },
            {
                label: 'Sole Trader',
                value: 'Sole Trader',
            },
            {
                label: 'Charity',
                value: 'Charity',
            },
            {
                label: 'Other',
                value: 'Other',
            }];
        case constants.filterFields.CALL_OFF_MECHANISM:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Direct Award',
                value: 'directAward',
            },
            {
                label: 'Further Competition',
                value: 'furtherComp',
            }];
        case constants.filterFields.STATUS_CALL_OFF:
            return [{
                label: '',
                value: '',
            },
            {
                label: 'Active',
                value: 'active',
            },
            {
                label: 'Cancelled',
                value: 'cancelled',
            },
            {
                label: 'Awarded',
                value: 'awarded',
            }];
        default:
            return {};
    }
};

export default {
    getFilterPropertiesByFieldName,
    getSelectOptionsByField,
};
