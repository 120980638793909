import { BUYER_STATUS, BUYER_STATUS_ACTIONS } from './constants';

const getThreadBuyerStatus = (action, currentBuyerStatus) => {
    const threadStatus = {
        [BUYER_STATUS_ACTIONS.NEW_MESSAGE]: (currentBuyerStatus === BUYER_STATUS.ACTION_NEEDED.value
            && BUYER_STATUS.RESPONDED.value) || undefined,
        [BUYER_STATUS_ACTIONS.VIEW_REPLIES]: (currentBuyerStatus === BUYER_STATUS.UNREAD.value
            && BUYER_STATUS.ACTION_NEEDED.value) || undefined,
    };
    return threadStatus[action] || currentBuyerStatus;
};

export default getThreadBuyerStatus;
