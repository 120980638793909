import React, { useEffect, useState, useRef } from 'react';
import BrowseData from '../../features/BrowseData';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import Constants from './constants';
import ToastConstants from '../../components/Alerts/Toast/constants';
import { ToastErrorMessages, filterAttributes } from '../../config/constants';

const BrowseCallOffs = () => {
    const [filteredCallOffs, setFilteredCallOffs] = useState([]);
    const [selectedCallOffTab, setSelectedCallOffTab] = useState();
    const callOffTabsRefs = useRef(Constants.tabOptions.map(() => undefined));

    const handleCallOffsByTab = (tabLabel) => {
        setSelectedCallOffTab(Constants.tabOptions.find((tab) => tab.label === tabLabel).value);
    };

    const getTabs = () => {
        const tabs = Constants.tabOptions;
        return tabs.map((item) => item.label);
    };
    const getTabIndex = (tabValue) => Constants.tabOptions
        .map((item) => item.value).indexOf(tabValue);

    const getTabUrl = (tabValue) => Constants.tabOptions
        .find((item) => item.value === tabValue).url;

    const fetchData = async (tab) => {
        try {
            const responseData = await projectManagementAPIs.browseCallOff(getTabUrl(tab));
            if (responseData.status === 200) {
                callOffTabsRefs.current[getTabIndex(tab)] = responseData.data.items;
                setFilteredCallOffs(callOffTabsRefs.current[getTabIndex(tab)]);
            } else {
                Toast.fire({
                    icon: ToastConstants.error,
                    titleText: ToastErrorMessages.UNABLE_TO_RETRIEVE_INFO,
                });
            }
        } catch (error) {
            Toast.fire({
                icon: ToastConstants.error,
                titleText: ToastErrorMessages.UNABLE_TO_RETRIEVE_INFO,
            });
        }
    };

    useEffect(() => {
        fetchData(Constants.tabOptions[0].value);
    }, []);

    useEffect(() => {
        if (!selectedCallOffTab) {
            return;
        }
        if (selectedCallOffTab && !callOffTabsRefs.current[getTabIndex(selectedCallOffTab)]) {
            fetchData(selectedCallOffTab);
            return;
        }
        setFilteredCallOffs(callOffTabsRefs.current[getTabIndex(selectedCallOffTab)]);
    }, [selectedCallOffTab]);

    return <BrowseData
        id='browseCallOff'
        context='calloff'
        contextSingular='calloff'
        dataSet={filteredCallOffs}
        searchPlaceholder={Constants.SEARCH_FOR_A_CALL_OFF}
        searchFilterAttribute={filterAttributes.title}
        sortOptions={Constants.sortOptions}
        tabOptions={getTabs()}
        handleTabSelected={handleCallOffsByTab}
        selectedTab={selectedCallOffTab}
        allowAddNew={false}
    />;
};

export default BrowseCallOffs;
