import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import GuidanceBanner from '../../components/GuidanceBanner';
import Button from '../../components/Button';
import StatusLabel from '../../components/StatusLabel';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import DashboardCard from '../../components/DashboardCard';
import {
    awardStatus, publishAtLevel, documentTypes, awardSummaryLabels, attatchedToTypes,
    intentStatuses,
    intentStatusColor,
    ToastErrorMessages,
} from '../../config/constants';
import { handleDownload, handleMultipleDownload } from '../../utils/uploadHelper';
import Constants from './constants';

import './styles.scss';
import showContractAwardDocsOutcome from './helpers';
import { handleIntentAwardDocumentDownload } from '../../services/award.service';
import ToastConstants from '../../components/Alerts/Toast/constants';

const ContractAwardSummaryRTMDirectAward = () => {
    const [data, setData] = useState({});
    const { projectId, eventId } = useParams();

    const getAwardSummaryResponse = async () => {
        try {
            const response = await projectManagementAPIs.getAwardSummary(projectId, eventId);
            if (response.status === 200) {
                setData(response.data);
            } else {
                Toast.fire({
                    icon: ToastConstants.errorIcon,
                    titleText: ToastErrorMessages.UNABLE_TO_RETRIEVE_INFO,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: ToastConstants.errorIcon,
                titleText: ToastErrorMessages.UNABLE_TO_RETRIEVE_INFO,
            });
        }
    };

    useEffect(() => {
        getAwardSummaryResponse();
    }, []);


    const getPrettyResponseStatus = (status) => awardStatus.filter(
        (rStatus) => rStatus.value === status,
    )[0]?.label;

    const generateEvaluation = () => (data
        ? <>
            <div id={'evaluationItem'}>
                <div className='evalationContent'>
                    <p className={'title'}>{Constants.OUTCOME_CONTENT}</p>
                </div>
                <div id={'awardBtnContainer'}>
                    <Button
                        id={'tBtn-downloadAwardLetter'}
                        label={awardSummaryLabels.AWARD_LETTER_DOWNLOAD}
                        size='small'
                        additionalVariant='btnFullWidth'
                        handleClick={() => handleIntentAwardDocumentDownload(
                            documentTypes.AWARD, projectId, attatchedToTypes.PROJECT,
                            data?.docs, handleDownload, handleMultipleDownload,
                        ) }
                    />
                </div>
            </div>
        </> : '');

    return <div id='awardSummaryContainer'>
        <div id='awardSummaryBanner'>
            <GuidanceBanner
                id={'viewOutcomeGuidance'}
                headerText={Constants.VIEW_OUTCOME_GUIDANCE_TITLE}
                bannerText2={data && !data.recordFound
                    ? <></>
                    : <>{Constants.VIEW_OUTCOME_GUIDANCE_TEXT}</>
                }
            />;
        </div>

        {!data.isProjectDPS && data.publishLevel === publishAtLevel.lot
        && data?.responses?.length > 0
        && <div className='awardSummaryContentContainer' >
            {data.responses?.map((lot, index) => (
                <div key={`lot-${index}`} className='awardSummaryLotInfoCard'>
                    <div className='awardSummaryQuestionTitle'>
                        <p className='title'>{`${Constants.LOT} ${index + 1}: ${lot.lotTitle}`}</p>
                        <div className='awardSummaryQuestionScore'>
                            <StatusLabel
                                id='questionStatus'
                                color= {lot.intentStatus === intentStatuses.successful
                                    ? intentStatusColor.green : intentStatusColor.red}
                                labelTxt={getPrettyResponseStatus(lot.intentStatus)}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>}

        <div className='awardSummaryOutcome'>
            {showContractAwardDocsOutcome(data)
            && <DashboardCard
                id='evaluationOutcome'
                size='small'
                header={true}
                headerTitle={Constants.OUTCOME_TITLE}
                content={generateEvaluation()}
                footer={false}
            />}
        </div>
    </div>;
};

export default ContractAwardSummaryRTMDirectAward;
