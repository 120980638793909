import React, { useEffect, useReducer } from 'react';
import axios from 'axios';

import instance from './axios.instance';
import ScreenOverlay from '../components/ScreenOverlay';

/**
 * Paths that the spinner should not be shown on
 */
const spinnerDenyList = [
    'gateway/auth/refresh',
    'gateway/auth/verify',
    'notifications/all',
    'notifications/get/all/project',
    '/autosave/',
    '/logout',
];

/**
 * The initial state of the request counter object
 */
const initialState = { count: 0 };

/**
 * This reducer method tracks the number of requests which
 * have been made
 * @param {object} state The current state of the request counter
 * @param {action} action Whether to increment or decrement the counter
 * @returns {object} The new counter state
 */
const reducer = (state, action) => {
    let newState;

    switch (action.type) {
        case 'increment':
            newState = { count: state.count + 1 };
            break;
        case 'decrement':
            newState = { count: state.count - 1 };
            break;
        default:
            throw new Error();
    }

    return newState;
};

/**
 *
 * @param {object} error The Axios error object
 * @returns {Promise} Rejected promise, throwing the Axios error
 */
const errorHandler = (error) => Promise.reject(error);

/**
 *
 * @param {url} url - The url to test whether the spinner should be shown
 * @returns {boolean} - Whether the spinner should be shown for the supplied url
 */
const shouldShowSpinner = (url) => (
    !spinnerDenyList.some((path) => url.includes(path))
);

/**
 * Axios interceptor functional component. Provides the ability to display loading spinner
 * for certain urls, and also to track how many requests are in flight vs completed, so the
 * spinner remains visible until all tracked requests are completed
 */
const LoaderInterceptor = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    /**
     * Intercept the Axios request, and determine whether it should be added
     * to the tracked URLs for showing/hiding the spinner
     * @param {object} request - The Axios request object
     * @returns {object} - The Axios request object
     */
    const requestInterceptor = (request) => {
        if (shouldShowSpinner(request.url)) {
            dispatch({ type: 'increment' });
        }

        return request;
    };

    /**
     * Intercept the Axios response, and determine whether it should be removed
     * from the tracked URLs for showing/hiding the spinner
     * @param {object} request - The Axios response object
     * @returns {object} - The Axios response object
     */
    const responseInterceptor = (response) => {
        if (shouldShowSpinner(response.config.url)) {
            dispatch({ type: 'decrement' });
        }

        return response;
    };

    /**
     * Intercept the Axios response error, and determine whether the originating URL
     * should be removed from the tracked URLs for showing/hiding the spinner,
     * then re-throw the error
     * @param {object} request - The Axios response object
     * @returns {object} - The Axios response object
     */
    const responseErrorHandler = (error) => {
        if (shouldShowSpinner(error.config.url)) {
            dispatch({ type: 'decrement' });
        }

        return errorHandler(error);
    };

    /**
     * Mount and unmount the interceptors when the component mounts/unmounts
     */
    useEffect(() => {
        const instanceRequestInterceptor = instance.interceptors.request.use(
            requestInterceptor, errorHandler,
        );
        const instanceResponseInterceptor = instance.interceptors.response.use(
            responseInterceptor, responseErrorHandler,
        );

        const mainRequestInterceptor = axios.interceptors.request.use(
            requestInterceptor, errorHandler,
        );
        const mainResponseInterceptor = axios.interceptors.response.use(
            responseInterceptor, responseErrorHandler,
        );

        // Unload the interceptors when the component unmounts
        return () => {
            instance.interceptors.request.eject(instanceRequestInterceptor);
            instance.interceptors.response.eject(instanceResponseInterceptor);
            axios.interceptors.request.eject(mainRequestInterceptor);
            axios.interceptors.response.eject(mainResponseInterceptor);
        };
    });

    // If there are 1 or more request in flight, we should show the spinner, otherwise hide it
    return state.count > 0 ? <><ScreenOverlay isStackTop={true} /><div className='loader'></div></> : null;
};

export default LoaderInterceptor;
