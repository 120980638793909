import instance from '../config/axios.instance';
import endpoints from '../config/endpoints';

const { notifications } = endpoints;

const notificationsManagementAPIs = {

    getAll: async () => {
        try {
            const endpoint = notifications.getMessages;
            const response = await instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    readAllByRecipient: async () => {
        try {
            const endpoint = notifications.readAllByRecipient;
            const response = await instance.put(`${endpoint}/Notification`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    readAllByNotificationID: async (notificationID) => {
        try {
            const endpoint = notifications.readAllByNotificationID;
            const response = await instance.put(`${endpoint}/${notificationID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getMessages: async (projectID, recipientID) => {
        try {
            const endpoint = notifications.getMessages;
            const response = await instance.get(`${endpoint}/${recipientID}/${projectID}/Message`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getProjectMessages: async (projectID) => {
        try {
            const endpoint = notifications.getProjectMessages;
            const response = await instance.get(`${endpoint}/${projectID}/Message`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getSupplierMessages: async (projectID, supplierID) => {
        try {
            const endpoint = notifications.getSupplierMessages;
            const response = await instance.get(`${endpoint}/${supplierID}/${projectID}/Message`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    createMessage: async (body) => {
        try {
            const endpoint = notifications.createMessage;
            const response = await instance.post(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    contactUs: async (body) => {
        try {
            const endpoint = notifications.contactUs;
            const response = await instance.post(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
};

export default notificationsManagementAPIs;
