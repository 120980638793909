import documentManagement from './documentManagement';
import externalServices from './externalServices';
import financialManagement from './financialManagement';
import gatewayOrchestrator from './gatewayOrchestrator';
import monitoring from './monitoring';
import notifications from './notifications';
import projectManagement from './projectManagement';
import userManagement from './userManagement';

export default {
    documentManagement,
    financialManagement,
    gatewayOrchestrator,
    monitoring,
    notifications,
    projectManagement,
    userManagement,
    externalServices,
};
