import React from 'react';
import PropTypes from 'prop-types';

const LabelledRadioButton = ({
    id,
    label,
    renderAsRow,
    breakColumn,
    onChange,
    options,
    required = true,
    caption,
}) => (<div className='labelled-radio-button'>
    {label
    && <label
        className='form-label title radio-title'
        id={`${id}-radio-button-label`}>
        {label}{required ? null : <span className='caption'> (Optional)</span>}
    </label>}
    {caption && <p id={`${id}-radio-button-caption`} className='caption form-caption'>{caption}</p>}
    <div className={`labelled-radio radio-button-group ${renderAsRow ? 'row' : ''}`}>
        {options.map((option, idx) => (<div key={idx} className='form-radio-button-container'>
            <input
                type='radio'
                className={`form-radio-button green ${option.disabled ? 'disabled-radio' : ''}`}
                checked={option.checked}
                id={`${option.id}-radio-btn`}
                name={option.name}
                value={option.value}
                onChange={(event) => onChange(event)}
                required={option.required || false}
                disabled={option.disabled || false}
                defaultChecked={option.defaultChecked || false}
            />

            <label
                className='form-label radio-label title'
                id={`${option.id}-label`}
                htmlFor={`${option.id}-radio-btn`}>
                {option.label}
            </label>
        </div>))}
    </div>
    {breakColumn && <div className='flex-break' />}
</div>);

LabelledRadioButton.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    breakColumn: PropTypes.bool.isRequired,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    renderAsRow: PropTypes.bool.isRequired,
    caption: PropTypes.string,
};

export default LabelledRadioButton;
