import React from 'react';
import { useHistory } from 'react-router-dom';
import Proptypes from 'prop-types';
import chevronRight from '../../../../styles/images/chevronRight.svg';
import StatusLabel from '../../../../components/StatusLabel';
import { BUYER_STATUS } from '../../../Correspondence/ViewCorrespondence/constants';

const AccordionSection = ({
    id,
    sectionNumber,
    header,
    link,
    content,
    toggleAccordion,
    contentRef,
    rotateChevron = '',
    contentHeight = '0px',
    sectionClass = '',
    headerClass = '',
    buyerStatus,
}) => {
    const history = useHistory();
    return <section className={`accordion-section ${sectionClass}`} id={id} key={id}>
        <button
            className={`accordion-section-header ${contentHeight !== '0px' && 'contentOpen'} ${headerClass}`}
            id={`accordion-section-${sectionNumber}-header`}
            onClick={(content) ? ((event) => toggleAccordion(event)) : (() => (link.startsWith('/') ? history.push(link) : window.open(link)))}>
            <div className='accordion-section-header-data'
                id={`accordion-section-${sectionNumber}-header-data`}>{header}
            </div>
            {buyerStatus && <StatusLabel
                id={`status-label-${id}`}
                color={BUYER_STATUS[buyerStatus].color}
                labelTxt={BUYER_STATUS[buyerStatus].label}/>}
            {content && <img src={chevronRight}
                alt='expand'
                className={`accordion-section-header-icon ${rotateChevron}`}></img>}
        </button>
        {(contentHeight !== '0px' && content)
        && <main ref={contentRef} className={`accordion-section-content ${contentHeight !== '0px' && 'open'}`}
            id={`accordion-section-${sectionNumber}-content`}
            style={{ maxHeight: `${contentHeight}` }}>
            <div className='accordion-section-content-data'>{content}
            </div>
        </main>}
    </section >;
};

AccordionSection.propTypes = {
    id: Proptypes.string,
    sectionNumber: Proptypes.number,
    header: Proptypes.oneOfType([
        Proptypes.string,
        Proptypes.object,
    ]),
    link: Proptypes.string,
    content: Proptypes.oneOfType([
        Proptypes.string,
        Proptypes.object,
    ]),
    toggleAccordion: Proptypes.func,
    contentRef: Proptypes.any,
    rotateChevron: Proptypes.string,
    contentHeight: Proptypes.string,
    sectionClass: Proptypes.string,
    headerClass: Proptypes.string,
    buyerStatus: Proptypes.string,
};

export default AccordionSection;
