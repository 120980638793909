import React from 'react';

import ErrorIcon from '../../../../styles/images/errorIcon.svg';

const UserDisabled = () => <>
    <div className='success-container'>
        <img src={ErrorIcon} alt='Error' className='success-img'></img>
        <p className='body error'>Couldn{'\''}t log you into Open.</p>
    </div>
    <p className='body inval-login'>Your account is disabled. Please contact your
            line manager/department lead for an account on Open.</p>
</>;


export default UserDisabled;
