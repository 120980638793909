import React from 'react';
import PropTypes from 'prop-types';

import LabelledInput from '../../../components/LabelledInput';

const InputComponent = ({
    id,
    label,
    maxLength,
    pattern,
    placeholder,
    required,
    title,
    type,
    value,
    minNumber,
    maxNumber,
    disabled = false,
    ...props
}) => (
    <div className='vpnItem'>
        <div className='vpnLabel'>
            <LabelledInput
                // onChange={(e) => handleChange(e, el)}
                breakColumn={false}
                id={id}
                label={label}
                maxLength={maxLength}
                minNumber={minNumber}
                maxNumber={maxNumber}
                pattern={pattern}
                placeholder={placeholder}
                readonly={disabled}
                required={required}
                title={title}
                type={type}
                value={value}
                { ...props }
            />
        </div>
    </div>
);

InputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    options: PropTypes.array,
    pattern: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    minNumber: PropTypes.number,
    maxNumber: PropTypes.number,
    disabled: PropTypes.string,
};

export default InputComponent;
