import isProdEnvironment from '../../utils/isProdEnvironment';

const LoginPageModes = {
    LOGIN_FORM: 'LOGIN_FORM',
    CREATE_PASSWORD_FORM: 'CREATE_PASSWORD_FORM',
    CREATE_PASSWORD_SUCCESS_MESSAGE: 'CREATE_PASSWORD_SUCCESS_MESSAGE',
    INVALID_LOGIN_MESSAGE_MESSAGE: 'INVALID_LOGIN_MESSAGE_MESSAGE',
    FP_SUBMIT_EMAIL_FORM: 'FP_SUBMIT_EMAIL_FORM',
    FP_SUBMIT_EMAIL_SUCCESS_MESSAGE: 'FP_SUBMIT_EMAIL_SUCCESS_MESSAGE',
    FP_VERIFY_CODE_FORM: 'FP_VERIFY_CODE_FORM',
    FP_RESET_PASSWORD_FORM: 'FP_RESET_PASSWORD_FORM',
    FP_RESET_PASSWORD_SUCCESS_MESSAGE: 'FP_RESET_PASSWORD_SUCCESS_MESSAGE',
    VERIFY_YOUR_ACCOUNT: 'VERIFY_YOUR_ACCOUNT',
    VERIFICATION_SUCCESS_MESSAGE: 'VERIFICATION_SUCCESS_MESSAGE',
    USER_DISABLED: 'USER_DISABLED',
    SELECTION_QUESTIONNAIRE: 'SELECTION_QUESTIONNAIRE',
};

const buttonTxt = {
    COMPLETE_NOW: 'Complete Now',
    BACK_TO_LOGIN: 'Back to Login',
};

const Constants = {
    nepoLoginFormModes: [
        {
            headerTitle: 'Login',
            mainBtnTxt: 'Login',
            showHelpOption: true,
            modalBodyMode: LoginPageModes.LOGIN_FORM,
            modalSize: 'medium',
        },
        {
            headerTitle: 'Enter New Password',
            mainBtnTxt: 'Submit',
            showHelpOption: false,
            modalBodyMode: LoginPageModes.CREATE_PASSWORD_FORM,
            modalSize: 'medium',
        },
        {
            headerTitle: 'Password Updated',
            modalBodyMode: LoginPageModes.CREATE_PASSWORD_SUCCESS_MESSAGE,
            mainBtnTxt: 'Go to Dashboard',
            showHelpOption: false,
            modalSize: 'small',
        },
        {
            headerTitle: 'Verification Complete',
            modalBodyMode: LoginPageModes.VERIFICATION_SUCCESS_MESSAGE,
            mainBtnTxt: 'Continue',
            showHelpOption: false,
            modalSize: 'medium',
        },
        {
            headerTitle: 'Login Failed',
            mainBtnTxt: 'Back to Login',
            showHelpOption: false,
            modalBodyMode: LoginPageModes.INVALID_LOGIN_MESSAGE_MESSAGE,
            modalSize: 'medium',
        },
        {
            headerTitle: 'Forgotten your password?',
            modalBodyMode: LoginPageModes.FP_SUBMIT_EMAIL_FORM,
            mainBtnTxt: 'Send me a recovery Email',
            showHelpOption: false,
            modalSize: 'small',
        },
        {
            headerTitle: 'Forgotten your password?',
            modalBodyMode: LoginPageModes.FP_SUBMIT_EMAIL_SUCCESS_MESSAGE,
            mainBtnTxt: 'OK',
            showHelpOption: false,
            modalSize: 'small',
        },
        {
            headerTitle: 'Verification',
            modalBodyMode: LoginPageModes.FP_VERIFY_CODE_FORM,
            mainBtnTxt: 'Verify',
            showHelpOption: false,
            modalSize: 'small',
            secondActionButton: true,
            secondActionButtonTxt: 'Resend Code',
        },
        {
            headerTitle: 'Enter New Password',
            mainBtnTxt: 'Change my password',
            showHelpOption: false,
            modalBodyMode: LoginPageModes.FP_RESET_PASSWORD_FORM,
            modalSize: 'medium',
        },
        {
            headerTitle: 'Password Reset Successful',
            modalBodyMode: LoginPageModes.FP_RESET_PASSWORD_SUCCESS_MESSAGE,
            mainBtnTxt: 'Back to Login',
            showHelpOption: false,
            modalSize: 'small',
        },
        {
            headerTitle: 'Congrats!',
            mainBtnTxt: 'Close',
            modalBodyMode: LoginPageModes.VERIFY_YOUR_ACCOUNT,
            modalSize: 'medium',
            allowCloseButton: true,
        },
        {
            headerTitle: 'Login Failed',
            mainBtnTxt: 'Back to Login',
            showHelpOption: false,
            modalBodyMode: LoginPageModes.USER_DISABLED,
            modalSize: 'medium',
        }, {
            headerTitle: 'Password Updated',
            mainBtnTxt: !isProdEnvironment() ? buttonTxt.COMPLETE_NOW : buttonTxt.BACK_TO_LOGIN,
            showHelpOption: false,
            modalBodyMode: LoginPageModes.SELECTION_QUESTIONNAIRE,
            secondActionButton: !isProdEnvironment(),
            secondActionButtonTxt: 'Complete Later',
            modalSize: 'medium',
        },
    ],
    nepoLoginChallenges: [{
        challengeName: 'NEW_PASSWORD_REQUIRED',
        challengeBodyState: 'createPassword',
    }],
    // Titles
    networkErrorTitle: 'Network Error',
    invalPasswordOrAttemptTitle: 'Invalid Password',
    invalPasswordTitle: 'Invalid Password',
    passwordResetAPIErrorTitle: 'Error',
    passwordResetWithoutCreateTitle: 'Error',
    passwordResetErrorTitle: 'Error',
    invalEmailTitle: 'Error',
    passwordCreateSuccessTitle: 'Success',
    passwordResetSuccessTitle: 'Success',
    disabledUser: 'User Disabled',
    // Messages
    networkError: 'Unable to reach Open. Please try again later',
    invalPasswordOrAttempt: 'Password does not meet the required constraints or you\'ve exhausted your attempts. Please try again later.',
    invalPassword: 'Password does not meet the required constraints',
    passwordResetAPIError: 'User password cannot be reset in the current state.',
    passwordResetWithoutCreate: 'Unable to reset password. Please login with the password emailed to you.',
    passwordResetDisabledUser: 'Unable to reset password. The user is disabled.',
    passwordResetError: 'Unable to reset password',
    invalEmail: 'Invalid Email',
    invalCode: 'Invalid code provided, please request a code again.',
    passwordCreateSuccess: 'Your password has been successfully updated.',
    passwordResetSuccess: 'Your password has been successfully reset.',
    disabledUserMessage: 'Your account is disabled. Please contact administrator.',
};

const ServiceMocks = {
    authSuccessMock: {
        data: {
            userID: '9804b218-6fa2-4c78-8743-a8f0f341f76d',
            email: 'test.test@accenture.com',
            username: 'test.test@accenture.com',
            accessToken: 'eyJraETWnEMSKSZQ',
            accountName: 'NEPO',
            role: 'Supplier',
            accountType: 'Permanent',
        },
    },
    authFailedMock: {
        response: {
            data: {
                error: 'Incorrect username or password.',
            },
        },
    },
    createPasswordMock: {
        data: {
            ChallengeName: 'NEW_PASSWORD_REQUIRED',
            Session: 'session-token',
            ChallengeParameters: {
                USER_ID_FOR_SRP: '97205268-3753-40d6-8261-5f87ea2746f1',
                requiredAttributes: '[]',
                userAttributes: '{\'email\':\'test.test@accenture.com\'}',
            },
        },
    },
    createPasswordFailedMock: {
        response: {
            data: {
                error: 'Invalid session for the user',
            },
        },
    },
    getVerificationCodeMock: {
        status: 200,
    },
    getResetPasswordMock: {
        status: 200,
    },
    getUserDetailsMock: {
        data: {
            accountId: '123',
        },
    },
    verificationCodeSubmitErrorMock: {
        status: 500,
        data: {
            'An error occurred: ': '',
        },
    },
};

const QueryParams = {
    returnToUrl: 'returnTo',
    queryUrl: 'queryUrl',
    questionMark: '?',
};

const LoginConstants = {
    ...LoginPageModes,
    ...Constants,
    ...ServiceMocks,
    ...QueryParams,
};

export default LoginConstants;
