import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import LabelledRadioButton from '../../../components/LabelledRadioButton';

const WhoAreYou = ({ currentStep, onSubmit, closeModal }) => {
    const options = [
        {
            id: 'supplier',
            value: 'Supplier',
            label: 'Supplier',
            checked: true,
        },
        {
            id: 'nepo-mem-auth',
            value: 'Buyer',
            label: 'Buyer',
            checked: false,
        },
        // Temporarily replaced by buyer.
        // {
        //     id: 'nepo-mem-auth',
        //     value: 'NEPO Member Authority',
        //     label: 'NEPO Member Authority',
        //     checked: false,
        // },
        // {
        //     id: 'nepo-assoc-mem',
        //     value: 'NEPO Associate Member',
        //     label: 'NEPO Associate Member',
        //     checked: false,
        // },
    ];

    const [formState, setFormState] = useState(options);

    const onRadioChange = (e) => {
        setFormState(formState.map((el) => {
            let preparedEl = {};

            if (el.value === e.target.value) {
                preparedEl = {
                    id: e.target.id,
                    label: e.target.value,
                    value: e.target.value,
                    checked: true,
                };
            } else {
                preparedEl = {
                    id: el.id, label: el.label, value: el.value, checked: false,
                };
            }
            return preparedEl;
        }));
    };

    const modalBody = <>
        { <LabelledRadioButton id={'who-are-you'}
            label={'Please select one of the following options:'}
            breakColumn={true}
            options= {formState}
            onChange={onRadioChange}
            renderAsRow={false}/>}
    </>;
    if (currentStep !== 1) {
        return null;
    }
    return <>
        <Modal
            open={true}
            closeModal={closeModal}
            size='small'
            headerTitle='Who are you?'
            handleMainActionBtnClick={(e) => onSubmit(e, formState)}
            mainActionButtonTxt='Continue'
            closeButton={false}
            body={modalBody}
            helpOption={false}
        />
    </>;
};

WhoAreYou.propTypes = {
    currentStep: PropTypes.number,
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
};

export default WhoAreYou;
