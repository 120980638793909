import React from 'react';

const SelectionQuestionnairePrompt = () => <>
    <p className='body'>Your password has been updated!</p>
    <br/>
    <br/>
    <p className='body'>If you are intending to bid for opportunities over £5000, we recommend you to complete
        the Section Questionnaire which we will automatically attach when you
        are bidding for opportunities, saving you time late on!</p>
</>;

export default SelectionQuestionnairePrompt;
