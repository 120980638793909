import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { v4 as uuidv4 } from 'uuid';
import {
    generateDPSSolutionHeaderButtons,
} from './helpers';
import {
    getAwardUserPermissions,
    getSuppliersAndLotsOrderedByIncompleteSubmissions,
} from '../../services/award.service';
import { HANDLE_UPDATE_INTENT_STATUS } from '../../config/constants';
import SupplierSubmissions from './components/SupplierSubmissions';

const SummaryBuyerAwardDPS = ({
    projectDetails,
    awardSummary,
    userSteeringRole,
    readOnly,
    handleStatusUpdate,
    uploadIntentLetter,
    deleteIntentLetter,
    localStorageProperty,
    handleAwardModal,
    handleUpdateIntentStatus,
    handleSaveOpenNodesInLocalStorage,
}) => {
    const history = useHistory();
    const [awardInfo, setAwardInfo] = useState();
    const [userPermissions, setUserPermissions] = useState();
    const [suppliers, setSuppliers] = useState();

    useEffect(() => {
        if (!awardSummary) {
            return;
        }
        const awardUserPermissions = getAwardUserPermissions(userSteeringRole);
        setUserPermissions(awardUserPermissions);
        setAwardInfo(awardSummary);
        setSuppliers(getSuppliersAndLotsOrderedByIncompleteSubmissions(awardSummary.responses));
    }, [awardSummary]);

    const actions = {
        [HANDLE_UPDATE_INTENT_STATUS]: (params) => handleUpdateIntentStatus(...params),
    };

    const handleClickAction = (clickAction, ...params) => {
        if (actions[clickAction]) {
            actions[clickAction](params);
        }
    };

    return (
        <> {awardInfo
            && generateDPSSolutionHeaderButtons(awardInfo.awardInfo, history)
        }
        {suppliers && userPermissions
            && suppliers?.map((supplier, index) => <SupplierSubmissions
                awardSupplier = {supplier}
                index={index}
                key={uuidv4()}
                awardInfo={awardInfo}
                readOnly={readOnly}
                userPermissions={userPermissions}
                projectDetails={projectDetails}
                uploadIntentLetter = {uploadIntentLetter}
                handleStatusUpdate = {handleStatusUpdate}
                handleAwardModal = {handleAwardModal}
                deleteIntentLetter = {deleteIntentLetter}
                handleClickAction={handleClickAction}
                localStorageProperty = {localStorageProperty}
                handleSaveOpenNodesInLocalStorage = {handleSaveOpenNodesInLocalStorage}
            />)
        }
        </>
    );
};

SummaryBuyerAwardDPS.propTypes = {
    projectDetails: PropTypes.object.isRequired,
    awardSummary: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    userSteeringRole: PropTypes.array,
    readOnly: PropTypes.bool,
    handleStatusUpdate: PropTypes.func.isRequired,
    uploadIntentLetter: PropTypes.func,
    deleteIntentLetter: PropTypes.func,
    handleAwardModal: PropTypes.func,
    handleUpdateIntentStatus: PropTypes.func.isRequired,
    localStorageProperty: PropTypes.string,
    handleSaveOpenNodesInLocalStorage: PropTypes.func,

};
export default SummaryBuyerAwardDPS;
