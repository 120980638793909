import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../components/Modal';

const CannotOpenAccount = ({ currentStep, onSubmit, closeModal }) => {
    const modalBody = <>
        <div className='success-container'>
            <p className='body'>To create an account, please contact us at open.support@nepo.org and we will create an account for you on Open.</p>
        </div>
    </>;

    if (currentStep !== 2) {
        return null;
    }
    return <>
        <Modal
            open={true}
            closeModal={closeModal}
            size='small'
            headerTitle='Information'
            handleMainActionBtnClick={(e) => onSubmit(e, 'close')}
            mainActionButtonTxt='Back to home'
            closeButton={false}
            body={modalBody}
            helpOption={false}
        />
    </>;
};

CannotOpenAccount.propTypes = {
    currentStep: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default CannotOpenAccount;
