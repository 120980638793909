export const projectDetailsTitles = {
    DESCRIPTION: 'Description:',
    ESTIMATED_VALUE: 'Estimated Value (£):',
    CONTRACT_START_DATE: 'Contract Start Date:',
    CONTRACT_END_DATE: 'Contract End Date:',
    CONTRACT_DURATION: 'Contract Duration:',
    ROUTE_TO_MARKET: 'Route to Market:',
    PROJECT_OWNER: 'Project Owner:',
    ORGANISATION_NAME: 'Organisation Name:',
    PROJECT_TYPE: 'Project Type:',
    CALL_OF_MECHANISM: 'Call Off Mechanism:',
    EXTENSIONS_AVAILABLE: 'Extension(s) Available:',
    EXTENSION_DURATION: 'Extension Duration',
    VAT_THAT_CANNOT_BE_RECOVERED: 'VAT that cannot be recovered (£)',
    RESPONSIBLE_OFFICER: 'Responsible Officer:',
    PUBLISH_QUESTION: 'Would you like to publish to contracts register?',
    PREVIOUS_CONTRACT: 'Previous contract:',
    CODES_AND_CATEGORIES: 'Codes and Categories:',
    DELIVERY_AREA: 'Delivery Area:',
};

export const projectOwnerType = {
    MY_ORGANISATION: 'My Organisation',
    EXTERNAL: 'External',
};

export const publishQuestionData = {
    YES: 'Yes',
    NO: 'No',
};

export const previousContractLink = 'View Contract';

export const manualCreationId = 'manualCreation';
