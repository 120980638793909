import React from 'react';
import propTypes from 'prop-types';
import Modal from '../../components/Modal';
import StatusLabel from '../../components/StatusLabel';
import helperFunctions from '../../utils/helperFunctions';

const ShowRecipientsModal = ({
    closeModal,
    recipients = [],
    publicCorrespondence = true,
}) => {
    const generateViewRecipients = () => {
        let heading = null;
        if (publicCorrespondence === true) {
            heading = <section className='recipient-heading'>
                <p className='body-small'>This correspondence has been sent to all suppliers. The following suppliers have read this correspondence:</p>
                <div className='recipient-item-divider'></div>
            </section>;
            if (recipients?.length === 0) {
                return <div className='recipient-body'>
                    {heading}
                    <p className='body-small'>No suppliers have read this correspondence.</p>
                </div>;
            }
        } else if (!publicCorrespondence && recipients.length === 0) {
            return <div className='recipient-body'>
                <p className='body-small'>Unable to retrieve recipients.</p>
            </div>;
        } else {
            heading = <section className='recipient-heading'>
                <p className='body-small'>This correspondence has been sent to the following suppliers:</p>
            </section>;
        }

        const elements = recipients.map((element, idx) => <>
            <section className='recipient-item'>
                <p className='body-small'>{element.companyName}</p>
                {element.readStatus === true && <StatusLabel
                    id={`${idx}-readStatus`}
                    className='readStatus'
                    color='green'
                    labelTxt={`Read: ${helperFunctions.formatPrettyDateTime(element.timestamp)}`}
                />}
                <div className='recipient-item-divider'></div>
            </section>
        </>);
        return <div className='recipient-body'>{heading}{elements}</div>;
    };

    return <Modal
        open={true}
        id='recipient-view'
        size='x-large'
        headerTitle='Recipients'
        secondActionButton={false}
        handleSecondaryActionBtnClick={closeModal}
        mainActionButtonTxt='false'
        closeModal={closeModal}
        body={generateViewRecipients()}
        helpOption={false}
        footer={false}
    />;
};

ShowRecipientsModal.propTypes = {
    mode: propTypes.string.isRequired,
    closeModal: propTypes.func.isRequired,
    recipients: propTypes.array.isRequired,
    publicCorrespondence: propTypes.string.isRequired,
};

export default ShowRecipientsModal;
