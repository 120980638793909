import React from 'react';
import PropTypes from 'prop-types';

const LabelledCheckBox = ({
    id,
    label,
    renderAsRow,
    breakColumn,
    onChange,
    options,
    required,
    disabled,
}) => (<>
    {label
    && <label
        className={`form-label title checkbox-title${disabled ? ' disabled' : ''}`}
        id={`${id}-checkBox-label`}>
        {label}{required ? null : <span className='caption'> (Optional)</span>}
    </label>}
    <div className={`labelled-checkBox checkBox-group ${renderAsRow ? 'row' : ''} ${disabled ? ' disabled' : ''}`}>
        {options.map((option, idx) => (
            <div key={idx} className='form-checkBox-container'>
                <input
                    type='checkbox'
                    className='form-checkBox green'
                    checked={option.checked}
                    id={`${option.id}-checkBox-btn`}
                    name={option.name}
                    value={option.value}
                    onChange={(event) => onChange(event)}
                    required={option.required || false}
                    disabled={option.disabled || false}
                />
                <label
                    className='form-label checkBox-label title'
                    id={`${option.id}-label`}
                    htmlFor={`${option.id}-checkBox-btn`}>
                    {option.label}</label>
            </div>
        ))}
    </div>
    {breakColumn && <div className='flex-break' />}
</>);

LabelledCheckBox.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    breakColumn: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    renderAsRow: PropTypes.bool.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default LabelledCheckBox;
