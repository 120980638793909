import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import LabelledInput, { LabelledInputTypeText } from '../../components/LabelledInput';
import Button from '../../components/Button';
import addIcon from '../../styles/images/addIcon.svg';
import errorIcon from '../../styles/images/errorIcon.svg';
import helperFunctions from '../../utils/helperFunctions';

const AddSupplierModal = ({
    closeModal,
    addSuppliers,
}) => {
    const [suppliers, setSuppliers] = useState([{ supplierName: '', supplierId: helperFunctions.createUniqueId() }]);

    const submitData = () => {
        const existingData = [...suppliers];
        const updatedSuppliers = existingData.filter((supp) => supp.supplierName !== '');
        addSuppliers(updatedSuppliers);
        closeModal();
    };

    const setSupplierValue = (event, supp) => {
        const updatedSuppliers = [...suppliers];
        const index = updatedSuppliers.findIndex((x) => x.supplierId === supp.supplierId);
        if (index !== -1) updatedSuppliers[index].supplierName = event.target.value;
        setSuppliers(updatedSuppliers);
    };

    const handleDelete = (event, supp) => {
        const updatedSuppliers = [...suppliers];
        const index = updatedSuppliers.findIndex((x) => x.supplierId === supp.supplierId);
        if (index !== -1) updatedSuppliers.splice(index, 1);
        setSuppliers(updatedSuppliers);
    };

    const generateResponseOptionHTML = (option, idx) => <>
        <div className='crossSupplier'><LabelledInput
            key={`Input-${idx}`}
            id={`Input-${idx}`}
            type={LabelledInputTypeText}
            placeholder='Enter supplier name'
            value={option?.supplierName || ''}
            onChange={(event) => setSupplierValue(event, option)}
            breakColumn={false}
            required={true}
        />
        <Button
            id={`supplierDeleteBtn-${idx}`}
            type={'button'}
            variant={'primary remove'}
            additionalVariant={'skinless'}
            size={'small'}
            label={''}
            disabled={false}
            icon={true}
            iconSrc={errorIcon}
            alt='remove'
            handleClick={(event) => handleDelete(event, option)}
        />
        </div>
        <div className='formElementsDivider'></div>
    </>;

    const addNewSupplier = () => {
        const updatedSuppliers = [...suppliers];
        updatedSuppliers.push({ supplierName: '', supplierId: helperFunctions.createUniqueId() });
        setSuppliers(updatedSuppliers);
    };

    const modalBody = <>

        <div className='suppliersWrapper'>
            <div id={'addSupplierModalCR'}>
                <div className='sup-items'>
                    {suppliers.map((option, idx) => generateResponseOptionHTML(option, idx))}
                    <Button id='addAnotherSupplierBtn'
                        type='button'
                        label={'Add another option'}
                        variant='secondary'
                        additionalVariant='skinless'
                        icon={true}
                        iconSrc={addIcon}
                        handleClick={addNewSupplier}
                    />
                </div>
            </div>
        </div>
    </>;
    return <>
        <Modal
            open={true}
            closeModal={closeModal}
            size='medium'
            headerTitle='Add Suppliers'
            handleMainActionBtnClick={(e) => submitData(e)}
            mainActionButtonTxt='Add'
            closeButton={false}
            body={modalBody}
            helpOption={false}
            secondActionButton={true}
            secondActionButtonTxt='Cancel'
            handleSecondaryActionBtnClick={(e) => { e.preventDefault(); closeModal(); }}
        />
    </>;
};


AddSupplierModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    addSuppliers: PropTypes.func.isRequired,
};

export default AddSupplierModal;
