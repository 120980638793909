import React from 'react';
import PropTypes from 'prop-types';

const ToggleButton = ({ handleToggleBtnClick }) => (
    <button className='mobile-menu-toggle-btn'
        id='mobile-menu-button'
        aria-label='open-mobile-menu'
        onClick={handleToggleBtnClick}>
        <p className='toggle-btn-label title-xLarge'>Menu</p>
        <div className='toggle-btn-lines'>
            <div className='toggle-btn-line' />
            <div className='toggle-btn-line' />
            <div className='toggle-btn-line' />
        </div>
    </button>
);
ToggleButton.propTypes = {
    handleToggleBtnClick: PropTypes.func.isRequired,
};

export default ToggleButton;
