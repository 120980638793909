import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../components/Modal';
import Tick from '../../../styles/images/tick.png';

const RegistrationComplete = ({
    closeModal,
    primaryBtnAction,
}) => {
    const modalBody = <>
        <div className='success-container top-icon'>
            <img src={Tick} alt='Success' className='success-img '></img>
            <p className='body'>You have completed the registration process. Go to your personalised dasboard
            to view opportunities we think will be relevant for you.</p>
        </div>
    </>;

    return <>
        <Modal
            open={true}
            closeModal={closeModal}
            size='small'
            headerTitle='Registration Complete'
            handleMainActionBtnClick={primaryBtnAction}
            mainActionButtonTxt='Go to Dashboard'
            closeButton={false}
            body={modalBody}
            helpOption={false}
            secondActionButton={false}
        />
    </>;
};

RegistrationComplete.propTypes = {
    closeModal: PropTypes.func.isRequired,
    primaryBtnAction: PropTypes.func.isRequired,
};

export default RegistrationComplete;
