import rbacRules from './rules';

const Can = (action, roles = []) => {
    if (!action) return false;
    const caseInsensitiveRoles = roles.filter(Boolean).map((x) => x?.toUpperCase());
    if (typeof (action) === 'object') {
        return action.some((role) => caseInsensitiveRoles.includes(role?.toUpperCase()));
    }
    return rbacRules[action].some((role) => caseInsensitiveRoles.includes(role?.toUpperCase()));
};

export default Can;
