import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import constants from '../../features/SupplierRegistration/constants';
import Form from '../../components/Form';
import ProgressBar from '../../features/ProgressBar';
import Button from '../../components/Button';
import LabelledRadioButton from '../../components/LabelledRadioButton';
import SelectionQuestionnairePrompt from '../../features/SupplierRegistration/components/SelectionQuestionnairePrompt';
import RegistrationComplete from '../../features/SupplierRegistration/components/RegistrationComplete';
import authenticationAPIs from '../../services/user-management.service';
import regionsActions from '../../features/RegionsOfSupply/slice/supplyRegions.action';
import codesActions from '../../features/CodesModal/slice/codes.action';
import actionsLocality from '../../features/LocalityModal/slice/supplyLocalities.action';


// This is currently unused.
const AdditionalQuestions = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const options = [
        {
            id: 'res-yes',
            value: 'Yes',
            label: 'Yes',
            checked: true,
        },
        {
            id: 'res-no',
            value: 'No',
            label: 'No',
            checked: false,
        }];

    const { onlySelectedCodes, onlySelectedRegions } = useSelector((state) => ({
        onlySelectedCodes: state.CodesReducer.onlySelectedCodes,
        onlySelectedRegions: state.SupplyRegionsReducer.onlySelectedRegions,
    }));
    const [formState, setFormState] = useState(options);

    const [showSelectionQuestionnairePrompt,
        setShowSelectionQuestionnairePrompt] = useState(false);
    const [showRegistrationComplete,
        setShowRegistrationComplete] = useState(false);

    const onRadioChange = (e) => {
        setFormState(formState.map((el) => {
            let preparedEl = {};

            if (el.value === e.target.value) {
                preparedEl = {
                    id: e.target.id,
                    label: e.target.value,
                    value: e.target.value,
                    checked: true,
                };
            } else {
                preparedEl = {
                    id: el.id, label: el.label, value: el.value, checked: false,
                };
            }
            return preparedEl;
        }));
    };

    const toggleSelectionQuestionnairePrompt = () => {
        setShowSelectionQuestionnairePrompt(!showSelectionQuestionnairePrompt);
    };

    const toggleRegistrationComplete = () => {
        setShowRegistrationComplete(!showRegistrationComplete);
    };

    const routeToDashboard = () => {
        history.push('/dashboard');
    };

    const routeToSelectionQuestionnaire = () => {
        history.push('/supplier/edit/sq');
    };

    // const getClassificationIDs = () => onlySelectedCodes.map((el) => el.classificationID);

    // const getSupplyRegions = () => onlySelectedRegions.map((el) => el.code);

    const getSupplyRegionsInfo = () => onlySelectedRegions.map((el) => ({
        code: el.code,
        description: el.description,
    }));

    const getCodesInfo = () => onlySelectedCodes.map((el) => ({
        level: el.level,
        category: el.category,
        classificationID: el.classificationID,
    }));


    const getUpdateReq = (isLargeBid) => ({
        organisationDetails: {
            deliveryAreaInfo: getSupplyRegionsInfo(),
            industryInfo: getCodesInfo(),
            isBidAmountLarge: isLargeBid === 'Yes',
        },
    });

    const prev = (event) => {
        event.preventDefault();
        history.goBack();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const userRes = formState.filter((el) => el.checked)[0];
        const res = await authenticationAPIs.updateProfile(
            getUpdateReq(userRes.value),
        );
        dispatch(regionsActions.clearRegions());
        dispatch(codesActions.clearCodes());
        dispatch(actionsLocality.clearLocalities());
        if (res.status === 200 && userRes.value === 'Yes') toggleSelectionQuestionnairePrompt();
        if (res.status === 200 && userRes.value === 'No') toggleRegistrationComplete();
    };

    return <>
        <ProgressBar
            options={constants.createCompanyAccountProgressBar}
            activeStep={3}
            id='company-reg-progress'
            context='LeftToRight'/>
        <section className='nepo-container registration-form'>
            <div className='wrapper' >
                <Form id='cnc-form' onSubmit={(e) => handleSubmit(e)}>
                    <div>
                        <LabelledRadioButton id={'who-are-you'}
                            label={'Are you intending on submitting bids for opportunities over £5000?'}
                            breakColumn={false}
                            options={formState}
                            onChange={onRadioChange}
                            renderAsRow={true} />
                    </div>
                    <div className='form-btn-wrapper'>
                        <Button id='second-action-btn'
                            additionalVariant='cnc-back'
                            variant='secondary'
                            label='Back'
                            handleClick={(event) => prev(event)} />
                        <Button id='main-action-btn'
                            label='Submit'
                            handleClick={() => null} />
                    </div>
                </Form>
            </div>
        </section>
        {showSelectionQuestionnairePrompt
        && <SelectionQuestionnairePrompt
            closeModal={toggleSelectionQuestionnairePrompt}
            primaryBtnAction={routeToSelectionQuestionnaire}
            secondaryBtnAction={routeToDashboard}
        />}
        {showRegistrationComplete && <RegistrationComplete
            closeModal={toggleRegistrationComplete}
            primaryBtnAction={routeToDashboard}/>}
    </>;
};
export default AdditionalQuestions;
