const Constants = {
    sortOptions: [
        null,
        { label: 'Created On (newest)', value: 'createdOn-descending-dateTime' },
        { label: 'Created On (oldest)', value: 'createdOn-ascending-dateTime' },
    ],
    tabOptions: [
        'My Contracts',
        'My Department',
        'My Organisation',
    ],
};

export default Constants;
