import { createContext } from 'react';

const AuthContext = createContext({
    authenticated: false, // to check if user is authenticated or not
    user: {}, // object to store user data returned by Cognito
    tokens: {}, // object containing issued tokens for the logged in user
    login: () => { }, // login the user
    logout: () => { }, // logout the user
    refresh: () => { }, // refresh user access after every 30 mins
    createPassword: () => { }, // force user to change password
    silentLogin: () => { },
    setRole: () => {},
    updateAuthContext: () => { }, // update the auth context
});

export default AuthContext;
