import React from 'react';
import { parseISO, isPast } from 'date-fns';
import PropTypes from 'prop-types';
import {
    getBannerColour, getBannerText, handleSubmitAll, handleWithdrawAll, isSubmitAllButtonDisabled,
    isWidthdrawAllButtonDisabled, getSubmissionStatusFromProjectOrLots,
} from '../../helpers';
import { responseStatus } from '../../../../config/constants';
import GuidanceBanner from '../../../../components/GuidanceBanner';
import dpsConstants from '../../dpsConstants';

const GenerateBanner = ({
    submissionsData,
    questionnaireAwaitingRefresh,
    projectId,
    eventId,
    getAnswerSummaryDPSResponse,
    selectedLots,
}) => {
    const isSubmissionDeadline = isPast(parseISO(submissionsData.submissionEndDate));
    const submissionStatus = getSubmissionStatusFromProjectOrLots(
        submissionsData, questionnaireAwaitingRefresh,
    );

    if (submissionsData.length === 0) { return <></>; }

    if (submissionsData.publishLevel === dpsConstants.projectPublishLevel) {
        return <GuidanceBanner
            id={'submitAnswerGuidance'}
            type={getBannerColour(submissionStatus, isSubmissionDeadline)}
            headerText={getBannerText(submissionStatus, isSubmissionDeadline)}
            timerTextType={!isSubmissionDeadline ? 'flat' : ''}
            timerEndDate={!isSubmissionDeadline ? submissionsData.submissionEndDate : null}
            bannerText1={isSubmissionDeadline
                && dpsConstants.deadlineMessage
            }
            buttonArr={[{
                id: 'btn-WithdrawAll',
                label: dpsConstants.withdrawAllButtonLabel,
                size: 'medium',
                variant: 'primary',
                additionalVariant: dpsConstants.red,
                action: handleWithdrawAll,
                allowAction: !isSubmissionDeadline,
                disabled: () => isWidthdrawAllButtonDisabled(isSubmissionDeadline, submissionsData),
            }, {
                id: 'btn-SubmitAll',
                label: dpsConstants.submitAllButtonLabel,
                size: 'medium',
                variant: 'primary',
                action: handleSubmitAll,
                allowAction: !isSubmissionDeadline,
                disabled: () => isSubmitAllButtonDisabled(isSubmissionDeadline, submissionsData),
            }]}
        />;
    }

    if (submissionsData.publishLevel === dpsConstants.lotPublishLevel) {
        if (!selectedLots.length) {
            return <GuidanceBanner
                id={'defaultGuidance'}
                headerText={getBannerText(
                    submissionStatus, isSubmissionDeadline, submissionsData.withAward,
                )}
                bannerText1={isSubmissionDeadline
                    && dpsConstants.deadlineMessage
                } />;
        }

        return <GuidanceBanner
            id={'submitAnswerGuidance'}
            type={getBannerColour(submissionStatus, isSubmissionDeadline)}
            headerText={getBannerText(submissionStatus, isSubmissionDeadline)}
            timerTextType={!isSubmissionDeadline ? 'flat' : ''}
            timerEndDate={!isSubmissionDeadline ? submissionsData.submissionEndDate : null}
            bannerText1={isSubmissionDeadline
                    && dpsConstants.deadlineMessage
            }
            buttonArr={[{
                id: 'btn-WithdrawAll',
                label: 'Withdraw All',
                size: 'medium',
                variant: 'primary',
                additionalVariant: dpsConstants.red,
                action: () => handleWithdrawAll(projectId, eventId, getAnswerSummaryDPSResponse),
                allowAction: !isSubmissionDeadline,
                disabled: (isSubmissionDeadline
                    || submissionsData.bidStatus !== responseStatus.bidSubmitted
                    || submissionsData.projectStatus === responseStatus.cancelled),
            }, {
                id: 'btn-SubmitAll',
                label: 'Submit All',
                size: 'medium',
                variant: 'primary',
                action: () => handleSubmitAll(projectId, eventId, getAnswerSummaryDPSResponse),
                allowAction: !isSubmissionDeadline,
                disabled: (isSubmissionDeadline
                    || submissionsData.bidStatus === responseStatus.bidSubmitted
                    || submissionsData.projectStatus === responseStatus.cancelled),
            }]}
        />;
    }

    return <GuidanceBanner
        id={'defaultGuidance'}
        type={getBannerColour(submissionStatus, isSubmissionDeadline)}
        headerText={getBannerText(submissionStatus, isSubmissionDeadline)}
        bannerText1={isSubmissionDeadline
                    && dpsConstants.deadlineMessage
        }
        bannerText2={!isSubmissionDeadline
                    && <>{dpsConstants.findOutMoreMessage}</>
        }
    />;
};

GenerateBanner.propTypes = {
    submissionsData: PropTypes.object,
    questionnaireAwaitingRefresh: PropTypes.bool,
    projectId: PropTypes.string,
    eventId: PropTypes.string,
    selectedLots: PropTypes.array,
    getAnswerSummaryDPSResponse: PropTypes.func.isRequired,
};

export default GenerateBanner;
