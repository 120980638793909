/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Tabs = ({
    data,
    handleClick,
    defaultTab = null,
}) => {
    const [active, setActive] = useState('');

    useEffect(() => {
        if (defaultTab) {
            setActive(data[defaultTab]);
        } else {
            setActive(data[0]);
        }
    }, []);


    const changeTab = (name) => {
        setActive(name);
        handleClick(name);
    };

    const changeTabOnKey = (event, name) => {
        if (event.key === 'Enter') {
            setActive(name);
            handleClick(name);
        }
    };

    const getTabsByData = () => {
        const elements = [];
        data.forEach((item, idx) => {
            elements.push(<li
                onClick={() => changeTab(item)}
                onKeyPress={(e) => changeTabOnKey(e, item)}
                key={idx}
                id={`tab-${idx}`}
                tabIndex='0'
                className={item === active ? 'body active' : 'body'}>
                {item}
            </li>);
        });

        return elements;
    };

    return (
        <div id='tabs' className='tabs'>
            <ul id='tab-header' className='tab-header'>
                {getTabsByData()}
            </ul>
        </div>
    );
};

Tabs.propTypes = {
    data: PropTypes.array,
    handleClick: PropTypes.func,
    defaultTab: PropTypes.number,
};

export default Tabs;
