const Constants = {
    sortOptions: [
        null,
        { label: 'Submission date (oldest)', value: 'createdOn-ascending-dateTime' },
        { label: 'Request start date (oldest)', value: 'contractStart-ascending-date' },
        { label: 'Name (ascending)', value: 'title-ascending-string' },
        { label: 'Name (descending)', value: 'title-descending-string' },
        { label: 'Estimated value (highest first)', value: 'estimatedValue-descending-number' },
        { label: 'Estimated value (lowest first)', value: 'estimatedValue-ascending-number' },
    ],
};

export default Constants;
