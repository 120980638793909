import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import NotificationEventRow from './components/NotificationEventRow';
import AuthContext from '../../context/AuthContext';
import notificationsManagementAPIs from '../../services/notifications.service';
import { fetchData } from '../NotificationsChecker/actions';
import Toast from '../../components/Alerts/Toast/Toast';

const Notifications = ({ notificationsData }) => {
    const notifications = notificationsData || [];
    const dispatch = useDispatch();
    const globalContext = useContext(AuthContext);
    const { id } = globalContext.user;
    const generateNotificationEventRows = () => {
        const elements = [];
        notifications.forEach((data, idx) => {
            elements.push(
                <NotificationEventRow
                    id={`notificationEventRow-${idx}`}
                    notificationID={data.notificationID}
                    message={data.message}
                    timeSent={data.timeSent}
                    readStatus={data.readStatus}
                    notificationType={data?.actionType ? 'ACTION' : data?.notificationType}
                    actionType={data?.actionType ? data?.actionType : ''}
                    context={data?.actionType ? data?.context : []}
                    email={id}/>,
            );
        });
        return elements;
    };

    const handleReadAll = async () => {
        try {
            const responseData = await notificationsManagementAPIs.readAllByRecipient();
            if (responseData) {
                dispatch(fetchData(id));
            }
        } catch {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to mark all notification as read.',
            });
        }
    };

    return (
        <div id='notificationsContainer'>
            {notifications && notifications.length > 0 ? <><div id='notificationEventRows'>
                {generateNotificationEventRows()}
            </div>
            <div id='notificationsContainerFooter'>
                <Button id='markAllAsReadBtn'
                    label='Mark all as read'
                    variant='secondary'
                    additionalVariant='skinless'
                    handleClick={handleReadAll} />
            </div>
            </> : <>
                <h1 className='caption' id='notificationError'>No notifications </h1>
                <div id='notificationsContainerFooter'>
                    <Button id='closeBtn'
                        label='Close Notifications'
                        variant='secondary'
                        additionalVariant='skinless'
                        handleClick={() => {}} />
                </div>
            </>
            }
        </div>
    );
};


Notifications.propTypes = {
    notificationsData: PropTypes.array.isRequired,
};

export default Notifications;
