import React from 'react';
import PropTypes from 'prop-types';

const generateHeader = (headers) => {
    const headerHTML = [];
    headerHTML.push(<tr key='table-header-tr' className='table-header-row title-large'>
        {headers.map((data, idx) => <th key={`table-header-th-${idx}`} className='table-header-row-cell' align='center'>{data}</th>)}
    </tr>);
    return headerHTML;
};

const generateBody = (rows) => {
    const bodyHTML = [];
    rows.forEach((rowData, index) => {
        bodyHTML.push(<tr key={`table-body-tr-${index}`} className='table-body-row title'>
            {Object.keys(rowData).map((key, idx) => (
                idx === 0
                    ? <th key={`table-body-th-${index}-${idx}`} className='table-body-row-cell' align='center'>{rowData[key]}</th>
                    : <td key={`table-body-td-${index}-${idx}`} className='table-body-row-cell' align='center'>{rowData[key]}</td>
            ))}
        </tr>);
    });
    return bodyHTML;
};

const generateFooter = (footer) => {
    const footerHTML = [];
    footerHTML.push(<tr key='table-footer-tr' className='table-footer-row'>
        {footer.map((data, idx) => (
            idx === 0
                ? <th key={`table-footer-th-${idx}`} className='table-footer-row-cell' align='center'>{data}</th>
                : <td key={`table-footer-td-${idx}`} className='table-footer-row-cell' align='center'>{data}</td>
        ))}
    </tr>);
    return footerHTML;
};

const Table = ({
    id = '',
    headerData = [],
    bodyData = [],
    footerData = [],
}) => (<div className='table-container'>
    <table className='table' id={id}>
        <thead className='table-header'>{generateHeader(headerData)}</thead>
        <tbody className='table-body'>{generateBody(bodyData)}</tbody>
        {footerData.length > 0 && <tfoot className='table-footer'>{generateFooter(footerData)}</tfoot>}
    </table>
</div>);

Table.propTypes = {
    id: PropTypes.string,
    headerData: PropTypes.array,
    bodyData: PropTypes.array,
    footerData: PropTypes.array,
};

export default Table;
