import constants from '../../../reducers/_constants';

const initialState = {
    selectedRegions: [],
    onlySelectedRegions: [],
};

const SupplyRegionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SupplierRegistrationConstants.SET_REGIONS: {
            return {
                ...state,
                selectedRegions: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.SET_ONLY_SELECTED_REGIONS: {
            return {
                ...state,
                onlySelectedRegions: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.CLEAR_REGIONS: {
            return {
                ...state,
                onlySelectedRegions: [],
                selectedRegions: [],
            };
        }
        default:
            return state;
    }
};

export default SupplyRegionsReducer;
