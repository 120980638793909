import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FilterPanel from '../../components/FilterPanel';
import LabelledSelect from '../../components/LabelledSelect';
import helperFunctions from '../../utils/helperFunctions';

const applyFilters = (dataToFilter, filterData) => {
    let dataTo = [...dataToFilter];

    if (dataTo.length > 0 && Object.values(filterData).some((value) => value !== null)) {
        if (filterData.department) {
            dataTo = dataTo.filter((data) => data.department === filterData.department);
        }
        if (filterData.userRole) {
            dataTo = dataTo.filter((data) => data.userRole === filterData.userRole);
        }
        return dataTo.length > 0 ? dataTo : null;
    }

    return [];
};

const UserManagementFilterPanel = ({
    open,
    performFilters = false,
    dataSet,
    closePanel,
    handleFilterResult,
}) => {
    const [firstFilter, setFirstFilter] = useState(true);
    const [filterData, setFilterData] = useState({});
    useEffect(() => {
        if (Object.keys(filterData).length > 0 && performFilters && dataSet.length > 1) {
            handleFilterResult(applyFilters(dataSet, filterData));
            if (firstFilter) {
                setFirstFilter(false);
            }
        }

        if (Object.keys(filterData).length === 0 && performFilters) {
            handleFilterResult(['NoFiltersToPerform']);
        }
    }, [dataSet, dataSet.length, filterData, firstFilter, handleFilterResult, performFilters]);

    const generateDropdownOptions = (key, option) => ((key > 0)
        ? <option key={`usersFilterStatusOption-${key}`} value={option.value}>{option.label}</option>
        : <option key={`usersFilterStatusOption-${key}`} value='default' disabled>{'All'}</option>);

    const clearAll = () => {
        setFilterData({});
        handleFilterResult([]);
    };

    const showResults = () => {
        if (Object.keys(filterData).length > 0) {
            handleFilterResult(applyFilters(dataSet, filterData));
            if (firstFilter) {
                setFirstFilter(false);
            }
        }
        closePanel();
    };

    return <FilterPanel id='userFilterPanel'
        open={open}
        closePanel={closePanel}
        footerBtnsDisabled={Object.keys(filterData).length === 0}
        handleClearAll={clearAll}
        handleShowResults={showResults}>
        <LabelledSelect id='usersFilterDepartment'
            options={helperFunctions.constructDropdownData(dataSet, 'department').map((option, idx) => generateDropdownOptions(idx, option))}
            label='Department'
            breakColumn={false}
            value={filterData.department || 'default'}
            onChange={(event) => setFilterData({
                ...filterData,
                department: event.target.value,
            })} />
        <div className='filterOptionsDivider'></div>
        <LabelledSelect id='usersFilterAccessLevel'
            options={helperFunctions.constructDropdownData(dataSet, 'userRole').map((option, idx) => generateDropdownOptions(idx, option))}
            label='Access Level'
            breakColumn={false}
            value={filterData.userRole || 'default'}
            onChange={(event) => setFilterData({
                ...filterData,
                userRole: event.target.value,
            })} />
        <div className='filterOptionsDivider'></div>
    </FilterPanel >;
};

UserManagementFilterPanel.propTypes = {
    open: PropTypes.bool.isRequired,
    performFilters: PropTypes.bool.isRequired,
    dataSet: PropTypes.array.isRequired,
    closePanel: PropTypes.func.isRequired,
    handleFilterResult: PropTypes.func.isRequired,
};

export default UserManagementFilterPanel;
