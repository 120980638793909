import React, {
    useEffect, useState, useCallback,
    useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import projectManagementAPIs from '../../services/project-management.service';
import SummaryBuyerAward from '../../features/SummaryBuyerAward';
import Toast from '../../components/Alerts/Toast/Toast';
import { mergeUploadedDocuments } from '../../utils/uploadHelper';
import { publishAtLevel, userStatus } from '../../config/constants';
import AuthContext from '../../context/AuthContext';

const AwardSummary = () => {
    const authContext = useContext(AuthContext);
    const [awardDetails, setAwardDetails] = useState([]);
    const [projectDetails, setProjectDetails] = useState();
    const [intentDocumentsRequired, setIntentDocumentsRequired] = useState(false);
    const [awardDocumentsRequired, setAwardDocumentsRequired] = useState(false);
    const { projectId, eventId } = useParams();

    const checkDocumentsRequired = (data) => {
        const intentLetters = data?.responses?.filter((response) => response?.docs?.some((doc) => doc.documentType === 'intent'));
        const awardLetters = data?.responses?.filter((response) => response?.docs?.some((doc) => doc.documentType === 'award'));

        if (data && Array.isArray(data.responses)) {
            if (typeof intentLetters !== 'undefined' && intentLetters.length >= data.responses.length) {
                setIntentDocumentsRequired(false);
            } else {
                setIntentDocumentsRequired(true);
            }

            if (typeof awardLetters !== 'undefined' && awardLetters.length >= data.responses.length) {
                setAwardDocumentsRequired(false);
            } else {
                setAwardDocumentsRequired(true);
            }
        } else {
            setIntentDocumentsRequired(true);
            setAwardDocumentsRequired(true);
        }
    };

    const arrangeSuppliers = (data) => {
        const incompleteSuppliers = [];
        const completeSuppliers = [];

        data?.responses?.forEach((response) => {
            if (data.dpsRulesApplicable === false && data.addIntentProcedure === true
                && (!data?.awardInfo?.intentLetterSent
                    || data?.awardInfo?.intentLetterSent === false)) {
                if (response?.intentStatus === 'successful' && response?.docs?.length === 1) {
                    completeSuppliers.push(response);
                } else {
                    incompleteSuppliers.push(response);
                }
            } else if (data.dpsRulesApplicable === false && data.addIntentProcedure === false) {
                if (response?.docs?.length === 2) {
                    completeSuppliers.push(response);
                } else {
                    incompleteSuppliers.push(response);
                }
            } else if (data.dpsRulesApplicable === false) {
                if (response?.docs?.length === 2) {
                    completeSuppliers.push(response);
                } else {
                    incompleteSuppliers.push(response);
                }
            } else if (response?.awardLetterSent === true) {
                completeSuppliers.push(response);
            } else {
                incompleteSuppliers.push(response);
            }
        });
        const arrangedSuppliers = incompleteSuppliers.concat(completeSuppliers);
        const awardData = Object.create(data);
        awardData.responses = arrangedSuppliers;
        setAwardDetails(awardData);
    };

    const getAllAwardsRequest = useCallback(async () => {
        try {
            const response = await projectManagementAPIs.getAwardSummary(projectId, eventId);
            const projectResponse = await projectManagementAPIs.getProject(projectId);

            if (response.data.isProjectDPS && response.data.publishLevel === publishAtLevel.lot) {
                setAwardDetails(response.data);
            } else if (response.data.publishLevel === publishAtLevel.lot) {
                arrangeSuppliers(response.data);
                checkDocumentsRequired(response.data);
            } else {
                arrangeSuppliers(response.data);
                checkDocumentsRequired(response.data);
            }
            setProjectDetails(projectResponse.data);
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    }, [projectId, eventId]);

    const submitForApproval = async (accountId) => {
        const response = await projectManagementAPIs.submitForApprovalAward(
            projectId, eventId, accountId,
        );
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Successfully submitted for approval.',
            });
            getAllAwardsRequest();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to submit for approval.',
            });
        }
    };

    const approveAward = async (action, documentType, accountId) => {
        const payload = {
            action,
            documentType,
        };
        const response = await projectManagementAPIs.approveAward(
            projectId, eventId, payload, accountId,
        );
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Successfully submitted for approval.',
            });
            getAllAwardsRequest();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to submit for approval.',
            });
        }
    };

    const handleStatusUpdate = (action, documentType, accountId) => {
        if (action === 'submit') {
            submitForApproval(accountId);
        } else {
            approveAward(action, documentType, accountId);
        }
    };

    const sendIntentLetter = async (payload) => {
        const response = await projectManagementAPIs.sendIntentLetters(
            projectId, eventId, payload,
        );
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Intent letters sent successfully.',
            });
            getAllAwardsRequest();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to send Intent letters.',
            });
        }
    };

    const handleStandstill = async (payload) => {
        const response = await projectManagementAPIs.updateStandstillTimer(
            projectId, eventId, payload,
        );
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: payload.standstillPaused === true ? 'Standstill timer paused.' : 'Standstill timer resumed',
            });
            getAllAwardsRequest();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: payload.standstillPaused === true ? 'Unable to pause standstill timer.' : 'Unable to resume standstill timer',
            });
        }
    };

    const handleSendAwards = async (accountId, callbackFunction) => {
        try {
            const sendResponse = await projectManagementAPIs.sendAwardLetters(
                projectId, eventId, accountId,
            );
            if (sendResponse.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Successfully sent award letters.',
                });
                getAllAwardsRequest();
                callbackFunction();
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to send award letters.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to send award.',
            });
        }
    };

    const handleUpdateIntentStatus = async (action, accountId, lotId) => {
        const payload = {
            status: action,
        };
        try {
            let response;
            if (awardDetails.publishLevel === 'project') {
                response = await projectManagementAPIs.updateIntentByProject(
                    projectId, eventId, accountId, payload,
                );
            } else {
                response = await projectManagementAPIs.updateIntentByLot(
                    projectId, eventId, accountId, lotId, payload,
                );
            }
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Successfully updated supplier.',
                });
                getAllAwardsRequest();
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to update supplier.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update supplier.',
            });
        }
    };

    const deleteIntentLetter = async (docs, type, accountId) => {
        try {
            const response = await projectManagementAPIs.deleteIntentAwardLetter(
                type, projectId, eventId, accountId, docs.documentID,
            );

            if (response.status === 200) {
                getAllAwardsRequest();
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to delete document.',
            });
        }
    };

    const uploadIntentLetter = async (docs, type, accountId, lotId, lotTitle) => {
        const extraAttributes = {
            documentType: type === 'intentletter' ? 'intent' : 'award',
            lotID: lotId || undefined,
            lotTitle: lotTitle || undefined,
        };
        const mergedDocs = mergeUploadedDocuments([], docs, extraAttributes,
            authContext.user);
        const payLoad = { docs: [] };

        mergedDocs.forEach(async (mergedDoc) => {
            payLoad.docs.push({
                documentID: mergedDoc.documentID,
                documentType: mergedDoc.documentType,
                lotID: mergedDoc.lotID || undefined,
                lotTitle: mergedDoc.lotTitle || undefined,
                documentName: mergedDoc.filename,
            });
        });

        const response = await projectManagementAPIs.uploadIntentAwardLetter(
            type, projectId, eventId, accountId, payLoad,
        );

        if (response.status === 200) {
            getAllAwardsRequest();
        }
    };

    useEffect(() => {
        getAllAwardsRequest();
    }, [getAllAwardsRequest]);

    const isDPS = (projectDetails?.projectDescribes?.includes('dps') && awardDetails.dpsRulesApplicable) || false;

    const getContent = () => <SummaryBuyerAward
        projectDetails={projectDetails}
        dataSet={awardDetails}
        handleStatusUpdate={handleStatusUpdate}
        handleIntentLetters={sendIntentLetter}
        handleSendAwards={handleSendAwards}
        handleStandstill={handleStandstill}
        handleUpdateIntentStatus={handleUpdateIntentStatus}
        uploadIntentLetter={uploadIntentLetter}
        deleteIntentLetter={deleteIntentLetter}
        isDPS={isDPS}
        intentDocumentsRequired={intentDocumentsRequired}
        awardDocumentsRequired={awardDocumentsRequired}
        publishLevel={awardDetails.publishLevel}
        readOnly={projectDetails?.projectStatus === userStatus.cancelled}
        isProjectDPS = {awardDetails.isProjectDPS}
        eventId={eventId}
    />;

    // We need to check awardSummary for non-pending project level
    return awardDetails?.recordFound === true && projectDetails
        ? getContent()
        : <div id={'evaluationContainer'}>
            <p className={'caption'}>No submissions available for evaluation.</p>
        </div>;
};

export default AwardSummary;
