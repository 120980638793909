import React from 'react';
import PropTypes from 'prop-types';
import LabelledInput, { LabelledInputTypeNumber } from '../LabelledInput';

const SelectExtensionsAvailable = ({
    numberOfInputs,
    handleExtensionsChange,
    handleExtension,
    values,
    readonly = false,
}) => {
    const numberOfInputsInt = numberOfInputs ? parseInt(numberOfInputs, 10) : 0;
    return (
        <>
            <LabelledInput
                id='extensionsAvailable'
                type={LabelledInputTypeNumber}
                label='Extension(s) available'
                breakColumn={true}
                onChange={(e) => handleExtensionsChange(e)}
                value={numberOfInputs || ''}
                placeholder='Enter number of extensions'
                required={false}
                minNumber={0}
                readonly={readonly}
                maxNumber={10}
                title='Please enter number less than or equal to 10'
            />
            {[...Array(numberOfInputsInt)].map(
                (value, i) => (
                    <div className='formInputContainer' key={i}>
                        <LabelledInput
                            id={`${i}`}
                            type={LabelledInputTypeNumber}
                            label={`Number of month(s) for extension ${i + 1}`}
                            breakColumn={true}
                            onChange={(e) => handleExtension(e)}
                            value={values.extensionsAvailable ? values.extensionsAvailable[i].monthsAvailable : ''}
                            placeholder='Enter number of months'
                            key={i}
                            readonly={readonly}
                        />
                    </div>
                ),
            )}
        </>
    );
};

SelectExtensionsAvailable.propTypes = {
    numberOfInputs: PropTypes.string,
    handleExtensionsChange: PropTypes.func,
    handleExtension: PropTypes.func,
    values: PropTypes.object,
    readonly: PropTypes.bool,
};

export default SelectExtensionsAvailable;
