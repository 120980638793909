import React, {
    createRef, useRef, useState, useEffect,
} from 'react';
import Proptypes from 'prop-types';
import AccordionSection from './components/AccordionSection';

const Accordion = ({
    id,
    data,
    open = false,
    handleToogleAccordion,
}) => {
    const [sectionState, setSectionState] = useState({});
    const contentRefs = useRef(data.map(() => createRef()));
    if (contentRefs.current.length !== data.length) {
        contentRefs.current = Array(data.length).fill()
            .map((_, i) => contentRefs.current[i] || createRef());
    }

    const toggleAccordion = (sectionId, event) => {
        const sectionClicked = event.target.id.split('-')[2];
        const sectionToToggle = sectionState[sectionClicked];
        const active = (sectionToToggle === undefined || sectionToToggle.active === '')
            ? 'active' : '';
        const rotateChevron = (active === 'active') ? 'rotate' : '';
        const contentHeight = (active === 'active') ? `${contentRefs.current[sectionClicked]
            ?.current?.scrollHeight}px` : '0px';
        setSectionState({
            ...sectionState,
            [sectionClicked]: { active, rotateChevron, contentHeight },
        });
        if (handleToogleAccordion) {
            handleToogleAccordion(sectionId, !!active);
        }
    };

    useEffect(() => {
        let sectionsState = {};
        if (open) {
            data.forEach((_sectionData, idx) => {
                sectionsState = {
                    ...sectionsState,
                    [idx]: { rotateChevron: 'rotate', contentHeight: 'undefinedpx' },
                };
            });
            setSectionState(sectionsState);
        }
    }, [data, open]);

    const generateSections = () => {
        const sections = [];
        data.forEach((sectionData, idx) => {
            sections.push(<AccordionSection key={sectionData.sectionId}
                id={sectionData.sectionId}
                sectionNumber={idx}
                header={sectionData.header}
                link={sectionData.link}
                content={sectionData.content}
                contentRef={contentRefs.current[idx]}
                toggleAccordion={sectionData.content && ((event) => toggleAccordion(
                    sectionData?.sectionId, event,
                ))}
                rotateChevron={sectionState[idx] && sectionState[idx].rotateChevron}
                contentHeight={sectionState[idx] && sectionState[idx].contentHeight}
                sectionClass={sectionData.sectionClass}
                headerClass={sectionData.headerClass}
                buyerStatus={sectionData.buyerStatus}
            />);
        });
        return sections;
    };

    return (
        <div className='accordion-container' id={id}>
            {generateSections()}
        </div>);
};

Accordion.propTypes = {
    id: Proptypes.string.isRequired,
    data: Proptypes.array.isRequired,
    open: Proptypes.bool,
    handleToogleAccordion: Proptypes.func,
};

export default Accordion;
