const Constants = {
    DIFF_MODE: {
        CHARS: 'Chars',
        WORDS: 'Words',
        WORDS_WITH_SPACE: 'Words with space',
        LINES: 'Lines',
        TRIMMED_LINES: 'Trimmed Lines',
        SENTENCES: 'Sentences',
    },
    METHOD_FOR_DIFF: 'Method for diff',
    HIDE_VIEWER: 'Hide viewer',
    PREVIOUS_VERSIONS_QUESTIONNAIRE_TYPE: 'questionnaire',
    DESCRIPTION_FIELD: 'Description',
};
export default Constants;
