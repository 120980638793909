import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import TreeViewer from '../../../components/TreeViewer/TreeViewer';
import {
    getPreviousSubmissions,
} from '../helpers';

const SupplierPreviousSubmissions = ({
    supplier,
    readOnly,
    index,
    awardInfo,
    steeringInfo,
}) => {
    const [supplierPreviousSubmissions, setSupplierPreviousSubmissions] = useState();

    useEffect(() => {
        if (!supplier || !supplier.previousSubmissions) {
            return;
        }
        setSupplierPreviousSubmissions(getPreviousSubmissions(readOnly, supplier,
            `supplier-${ index}`, awardInfo, steeringInfo));
    }, [supplier]);

    return (
        <>
            {supplierPreviousSubmissions
            && <TreeViewer
                key = {uuidv4()}
                id = {'tree-previous-submissions'}
                classAdded='award-summary-tree-submissions'
                data={supplierPreviousSubmissions}
                hasSearch={false} />
            }
        </>
    );
};

SupplierPreviousSubmissions.propTypes = {
    supplier: PropTypes.object,
    readOnly: PropTypes.bool,
    userSteeringRole: PropTypes.array,
    index: PropTypes.number,
    awardInfo: PropTypes.object,
    steeringInfo: PropTypes.object,
};

export default SupplierPreviousSubmissions;
