import React from 'react';

const AccessibilityStatement = () => <div className='bdContentSection justify-content'>
    <div className='pp-content body'>
    This accessibility statement applies to the Open website (
        <a href='http://www.open-uk.org/' target='_blank' rel='noreferrer'>www.open-uk.org</a>
        ).
    </div>

    <div className='pp-content body'>NEPO is committed to making its website accessible, in accordance with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.</div>
    <div className='pp-content body'>This website is run by NEPO. We want as many people as possible to be able to use this website. For example, that means you should be able to:</div>

    <div className='pp-content body'>
        <ul className='pp-list'>
            {/* <li>change colours, contrast levels and fonts</li> */}
            <li>zoom in up to 200% without the text spilling off the screen</li>
            <li>navigate most of the website using just a keyboard
                (except date selection, selecting codes and categories,
                 radio button selections, close buttons on popup modals)</li>
            {/* <li>navigate most of the website using speech recognition software</li> */}
            <li>listen to most of the website using a screen reader
                (including the most recent versions of NVDA, Voice Over and Talk Back)
                on Chrome-NVDA(Desktop), IOS & Android (Mobile)</li>
        </ul>
    </div>

    <div className='pp-content body'>We’ve also made the website text as simple as possible to understand.</div>

    <div className='pp-content body'>
        <a href='https://mcmw.abilitynet.org.uk/' target='_blank' rel='noreferrer'>AbilityNet</a> has advice on making your device easier to use if you have a disability.
    </div>

    <div className='pp-title body bold xx-large-title'>How accessible this website is:</div>
    <div className='pp-content body'>We know some parts of this website are not fully accessible:</div>
    <div className='pp-content body'>
        <ul className='pp-list'>
            <li>you cannot modify the line height or spacing of text, font sizes and colours</li>
            <li>some older PDF documents may not be accessible to screen reader software </li>
            <li>Reflow criteria may not work on some pages.</li>
            <li>Some elements may not have the appropriate alternate text and focus.</li>
            <li>Some text that is not yet enabled to the user may be read by the screen reader</li>
        </ul>
    </div>
    <div className='pp-title body bold'>Feedback and contact information</div>
    <div className='pp-content body'>If you need information on this website in a different format like accessible PDF, large print, easy read, audio recording or braille, please contact us at <a href='mailto:comms@nepo.org?subject=Website%20enquiry'>comms@nepo.org</a></div>
    <div className='pp-content body'>
        We’ll consider your request and get back to you within five working days.
    </div>

    <div className='pp-title body bold xx-large-title'>
        Reporting accessibility problems with this website:
    </div>
    <div className='pp-content body'>
        We’re always looking to improve the accessibility of this website. If you find any problems not listed on this page or think we’re not meeting accessibility requirements, please contact us at <a href='mailto:comms@nepo.org?subject=Website%20enquiry'>comms@nepo.org</a>.
    </div>

    <div className='pp-title body bold xx-large-title'>Enforcement procedure:</div>
    <div className='pp-content body'>
        The Equality and Human Rights Commission (EHRC) is responsible for enforcing the
        Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility
        Regulations 2018 (the ’accessibility regulations’).  If you’re not happy with
        how we respond to your complaint, please contact the <a href='https://www.equalityadvisoryservice.com/' target='_blank' rel='noreferrer'>Equality Advisory and Support Service (EASS).</a>.
    </div>

    {/* <div className='pp-title body bold'>
        Technical information about this websites accessibility:
    </div>
    <div className='pp-content body'>
        NEPO is committed to making its website accessible, in accordance with the Public Sector
        Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.
    </div> */}

    <div className='pp-title body bold xx-large-title'>
        Compliance status:
    </div>
    <div className='pp-content body'>
        This website is fully compliant with the <a href='https://www.w3.org/TR/WCAG21/' target='_blank' rel='noreferrer'>Web Content Accessibility Guidelines version 2.1</a> A and AA standard.
    </div>

    {/* <div className='pp-title body bold'>
        Content thats not within the scope of the accessibility regulations:
    </div> */}
    <div className='pp-title body bold xx-large-title'>
        Non-accessible content:
    </div>
    <div className='pp-content body'>
        The content listed below is non-accessible for the following reason:
        the content is not within the scope of the applicable legislation.
    </div>
    <div className='pp-title body bold'>
        PDFs and other documents
    </div>
    <div className='pp-content body'>
        Some of our PDFs and Word documents are essential to providing our services.
        For example, we have PDFs with information on how users can access our services,
        and forms published as Word documents.
    </div>
    <div className='pp-content body'>
        The accessibility regulations  <a href='https://www.legislation.gov.uk/uksi/2018/952/regulation/4/made' target='_blank' rel='noreferrer'> do not require us to fix PDFs or other documents published before 23 September 2018</a> if they’re not essential to providing our services.
    </div>
    <div className='pp-content body'>Any new PDFs or Word documents we publish will meet accessibility standards.</div>

    <div className='pp-title body bold'>Live video</div>
    <div className='pp-content body'>
        We do not plan to add captions to live video streams because live video is <a href='http://www.legislation.gov.uk/uksi/2018/952/regulation/4/made' target='_blank' rel='noreferrer'>exempt from meeting the accessibility regulations</a>.
    </div>
</div>;

export default AccessibilityStatement;
