import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Confirm from '../../components/Alerts/Confirm/Confirm';
import DashboardCard from '../../components/DashboardCard';
import GuidanceBanner from '../../components/GuidanceBanner';
import projectManagementAPIs from '../../services/project-management.service';
import gatewayAPIs from '../../services/gateway.service';
import addEllipseIcon from '../../styles/images/addEllipseIcon.svg';
import Toast from '../../components/Alerts/Toast/Toast';
import Button from '../../components/Button';
import rbac from '../../rbac';
import AuthContext from '../../context/AuthContext';
import StatusLabel from '../../components/StatusLabel';
import SearchBar from '../../features/SearchBar';

const ManageSuppliersContract = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [filteredSuppliers, setFilteredSuppliers] = useState([]);
    const [contractDetails, setContractDetails] = useState({});
    const { contractId } = useParams();
    const history = useHistory();
    const authContext = useContext(AuthContext);

    // Once manageContract is working, this may not be needed.
    const fetchContract = async () => {
        const response = await projectManagementAPIs.getContract(contractId);
        if (response.status === 200) {
            setContractDetails(response.data);
        } else {
            Toast.fire({
                icon: 'error',
                title: 'Failed to get awarded suppliers',
            });
        }
    };

    const fetchSuppliers = async () => {
        const response = await projectManagementAPIs.manageContract(contractId);
        if (response.status === 200) {
            setSuppliers(response.data.awardedSuppliers || []);
            setFilteredSuppliers(response.data.awardedSuppliers || []);
        } else {
            Toast.fire({
                icon: 'error',
                title: 'Failed to get awarded suppliers',
            });
        }
    };

    const fetchData = () => {
        fetchSuppliers();
        fetchContract();
    };


    useEffect(() => {
        fetchData();
    }, [contractId]);

    const handleSupplierStatus = async (accountID, status, lotID = null) => {
        let response;
        let successMsg;
        let errorMsg;
        if (status === 'reactivate') {
            if (lotID) {
                response = await gatewayAPIs.reactivateSupplierByLot(
                    contractId, accountID, lotID,
                );
            } else {
                response = await gatewayAPIs.reactivateSupplier(contractId, accountID);
            }
            successMsg = 'Successfully reactivated supplier';
            errorMsg = 'Failed to reactivate supplier';
        }
        if (status === 'deactivate') {
            if (lotID) {
                response = await gatewayAPIs.deactivateSupplierByLot(
                    contractId, accountID, lotID,
                );
            } else {
                response = await gatewayAPIs.deactivateSupplier(contractId, accountID);
            }
            successMsg = 'Successfully deactivated supplier';
            errorMsg = 'Failed to deactivate supplier';
        }
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                title: successMsg,
            });
        } else {
            Toast.fire({
                icon: 'error',
                title: errorMsg,
            });
        }
        fetchData();
    };

    const generateContent = (data) => (data.length > 0
        ? <>
            {data.map(
                (supplier, idx) => <>
                    <div className={`mscSupplierRow ${supplier?.supplierStatus === 'active' ? 'green' : 'red'}`}>
                        <div className='mscSupplierDetails'>
                            <div className='companyDetails'>
                                <p className={`title comapanyTitle ${supplier?.supplierStatus === 'active' ? 'green' : 'red'}`}>{supplier?.account?.companyName}</p>
                                <p className='caption-small'>{supplier.departmentName}</p>
                            </div>
                        </div>
                        <div className='mscButtonContainer'>
                            <StatusLabel id={idx} color={supplier?.supplierStatus === 'active' ? 'green' : 'red'}
                                labelTxt={supplier.supplierStatus === 'active' ? 'Active' : 'Deactivated' } />
                            <Button
                                id={'viewSupplier'}
                                variant={'primary'}
                                additionalVariant={`skinless ${supplier?.supplierStatus === 'active' ? 'green' : 'red'}`}
                                size='small'
                                handleClick={() => history.push(`/supplier/overview/${supplier.accountID}`)}
                                label='View Supplier' />
                            {supplier?.supplierStatus === 'active'
                            && contractDetails.contractStatus !== 'cancelled'
                            && <Button
                                id={idx}
                                variant={'secondary'}
                                size='small'
                                additionalVariant={'cancel'}
                                disabled={!rbac.can(
                                    rbac.action.manageContractSuppliers,
                                    rbac.util.getSteeringGroupRole(authContext.user.id,
                                        contractDetails?.steeringInfo),
                                )}
                                handleClick={() => Confirm(() => {
                                    handleSupplierStatus(supplier.accountID, 'deactivate', supplier.lotID);
                                }, {
                                    subTitle: 'Are you sure want to deactivate this supplier?',
                                })}
                                label='Deactivate'/>
                            }
                            {supplier?.supplierStatus === 'inactive'
                            && contractDetails.contractStatus !== 'cancelled'
                            && <Button
                                id={idx}
                                variant={'primary'}
                                size='small'
                                disabled={!rbac.can(
                                    rbac.action.manageContractSuppliers,
                                    rbac.util.getSteeringGroupRole(authContext.user.id,
                                        contractDetails?.steeringInfo),
                                )}
                                handleClick={() => Confirm(() => {
                                    handleSupplierStatus(supplier.accountID, 'reactivate', supplier.lotID);
                                }, {
                                    subTitle: 'Are you sure want to reactivate this supplier?',
                                })}
                                label='Reactivate'/>
                            }
                        </div>
                    </div>
                </>,
            )}
        </>
        : <div><p className='caption'>No record found.</p></div>);

    const generateLotContent = (data) => (data.length > 0
        ? <>
            {data.sort((a, b) => a.lotOrderNo - b.lotOrderNo).map((lot, idx) => <div key={idx} className='mscLotContainer'>
                <div className='lotTitleContainer'>
                    <p className='title-large lotName'>{`Lot ${lot.lotOrderNo}: ${lot.lotTitle}`}</p>
                </div>
                {generateContent(lot.suppliers)}
            </div>)}
        </>
        : <div><p className='caption'>No record found.</p></div>);

    const handleSearch = (searchData) => {
        const { search: searchString } = searchData;
        const originalData = [
            ...suppliers,
        ];
        const filteredSupplier = [];
        let filteredData = [];

        if (contractDetails.publishLevel === 'lot') {
            filteredData = originalData?.filter((lot) => {
                if (lot?.lotTitle?.toLowerCase()?.includes(searchString.toLowerCase())) {
                    return true;
                }
                const filteredSupplierData = lot?.suppliers?.filter(
                    (supplier) => supplier?.account?.companyName?.toLowerCase()?.includes(
                        searchString.toLowerCase(),
                    ),
                );
                if (filteredSupplierData?.length > 0) {
                    filteredSupplier.push({
                        lotId: lot.lotID,
                        suppliers: filteredSupplierData,
                    });
                    return true;
                }
                return false;
            }).map((lot) => {
                const filteredLotData = filteredSupplier?.filter(
                    (lot2) => lot2.lotId === lot.lotID,
                );
                if (filteredLotData?.length > 0) {
                    return {
                        ...lot,
                        suppliers: filteredLotData[0]?.suppliers,
                    };
                }
                return {
                    ...lot,
                };
            });
        } else {
            filteredData = originalData?.filter(
                (supplier) => supplier?.account?.companyName?.toLowerCase()?.includes(
                    searchString.toLowerCase(),
                ),
            );
        }

        setFilteredSuppliers(filteredData);
    };

    return (
        <>
            <GuidanceBanner
                id='mscGuidanceBanner'
                headerText='Manage Suppliers'
                bannerText2="You're managing suppliers for this contract!"
                buttonArr={[{
                    size: 'small',
                    allowAction: true,
                    variant: 'secondary',
                    label: 'Back',
                    action: () => history.goBack(),
                }]}
            />
            <div className='mscDetailsContainer'>
                <DashboardCard
                    id='supplierCard'
                    size={'large'}
                    header={true}
                    headerTitle='Suppliers'
                    actionBtn={rbac.can(
                        rbac.action.manageContractSuppliers,
                        rbac.util.getSteeringGroupRole(authContext.user.id,
                            contractDetails?.steeringInfo),
                    ) && contractDetails.contractStatus !== 'cancelled'}
                    actionBtnText='Add'
                    headerStatus={true}
                    headerStatusContent={<SearchBar
                        id='manageSuppliersSearch'
                        placeholder={''}
                        handleSearchCallback={handleSearch}
                    />}
                    handlerActionBtn={() => history.push('/suppliers')}
                    variant='secondary'
                    additionalVariant='skinless'
                    icon={true}
                    iconSrc={addEllipseIcon}
                    content={contractDetails.publishLevel === 'lot' ? generateLotContent(filteredSuppliers) : generateContent(filteredSuppliers)}
                />
            </div>

        </>);
};

export default ManageSuppliersContract;
