import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Constants from './constants';

const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
};

const HomepageBanner = () => (
    <div className='homepageBannerContainer'>
        <h4 className='hpBannerText1'><span className='hpBannerTextBold'>{Constants.bannerTextBold}</span>{Constants.bannerText}</h4>
        <Button id='learnMoreBtn' label={Constants.buttonText} role={Constants.buttonRole} handleClick={() => openInNewTab(Constants.link)}/>
    </div>
);

HomepageBanner.propTypes = {
    bannerTextBold: PropTypes.string,
    bannerText: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
};

export default HomepageBanner;
