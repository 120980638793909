const Constants = {
    notification: 'notification',
    action_notification: 'action',
    actionType: {
        OPEN_ANSWER_SUMMARY: 'OPEN_ANSWER_SUMMARY',
        OPEN_SUPPLIER_AWARD_SUMMARY: 'OPEN_SUPPLIER_AWARD_SUMMARY',
        OPEN_CONTRACT_OVERVIEW: 'OPEN_CONTRACT_OVERVIEW',
        OPEN_EVALUATION_SUMMARY: 'OPEN_EVALUATION_SUMMARY',
        OPEN_PROJECT_OVERVIEW: 'OPEN_PROJECT_OVERVIEW',
        OPEN_OPPORTUNITIES_OVERVIEW: 'OPEN_OPPORTUNITIES_OVERVIEW',
        OPEN_CORRESPONDENCE_OVERVIEW: 'OPEN_CORRESPONDENCE_OVERVIEW',
        OPEN_CORRESPONDENCE_VIEWMESSAGE: 'OPEN_CORRESPONDENCE_VIEWMESSAGE',
        OPEN_SOLUTION_OVERVIEW: 'OPEN_SOLUTION_OVERVIEW',
    },
    contextTypes: {
        projectID: 'projectID',
        eventID: 'eventID',
        contractID: 'contractID',
        opportunityID: 'opportunityID',
        resourceType: 'resourceType',
        solutionID: 'solutionID',
    },
};

export default Constants;
