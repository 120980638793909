const Constants = {
    error: 'error',
    loadError: 'Unable to load project.',
    retrieveInfoError: 'Unable to retrieve information.',
    permissionError: 'You do not have permission to view this content.',

    questionnaire: 'questionnaire',

    dashboard: '/dashboard',

    pseudoPass: 'Pseudo Pass',

    overwrite: 'overwrite',
    create: 'create',

    success: 'success',
    weightingSuccess: 'Assigned weightings successfully.',
    weightingFail: 'Unable to assign weighting.',
};

export default Constants;
