const Constants = {
    sortOptions: [
        null,
        { label: 'Published Date (newest)', value: 'createOn-descending-dateTime' },
        {
            label: 'Expression Window Date (oldest)',
            value: 'expressionWindowStartDate-ascending-dateTime',
        },
        { label: 'Estimated Value (highest first)', value: 'estimatedValue-descending-number' },
        { label: 'Estimated Value (lowest first)', value: 'estimatedValue-ascending-number' },
        { label: 'Submission End Date (newest)', value: 'submissionEndDate-descending-dateTime' },
    ],
    tabOptions: [
        'Available Opportunities',
        'My Opportunities',
        'My Department',
        'My Organisation',
    ],
};

export default Constants;
