import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    approvalStatuses,
    intentLetterStatuses,
    evaluationStatusesLabels,
    responseStatusColour,
} from '../../../config/constants';
import Upload from '../../Upload';
import StatusLabel from '../../../components/StatusLabel';
import rbac from '../../../rbac';
import AuthContext from '../../../context/AuthContext';
import Button from '../../../components/Button';
import awardConstants from '../constants';
import './styles.scss';


const SupplierDataUpload = ({
    dataSet,
    data,
    userSteeringInfo,
    isDPS,
    readOnly,
    uploadIntentLetter,
    handleStatusUpdate,
    handleAwardModal,
    deleteIntentLetter,
    isPreviousSubmission = false,
}) => {
    const authContext = useContext(AuthContext);
    const [awardInfo, setAwardInfo] = useState();
    const [supplierSubmission, setSupplierSubmission] = useState();

    useEffect(() => {
        if (!dataSet || !data) {
            return;
        }
        setAwardInfo(dataSet);
        setSupplierSubmission(data);
    }, [dataSet, data]);

    const generateStatusPills = (status) => {
        const statusPills = {
            [intentLetterStatuses.notStarted]: <></>,
            [intentLetterStatuses.stage1ApprovalPending]:
                <>
                    <StatusLabel
                        id={'intentDocsApproval'}
                        color={responseStatusColour.amber}
                        labelTxt={evaluationStatusesLabels.awaitingApproval}
                    />
                </>,
            [intentLetterStatuses.stage2ApprovalPending]:
                <>
                    <StatusLabel
                        id={'intentDocsApproval'}
                        color={responseStatusColour.green}
                        labelTxt={awardConstants.STAGE_1_APPROVED}
                    />
                    <StatusLabel
                        id={'intentDocsApproval'}
                        color={responseStatusColour.amber}
                        labelTxt={evaluationStatusesLabels.stage1Approved}
                    />
                </>,
            [intentLetterStatuses.approved]:
                <>
                    <StatusLabel
                        id={'intentDocsApproval'}
                        color={responseStatusColour.green}
                        labelTxt={awardConstants.APPROVED}
                    />
                </>,
        };
        return statusPills[status] || <></>;
    };

    const generateDPSButtons = (approvalStatus, accountId, awardLetterSent) => {
        const dpsButtons = {
            [approvalStatuses.notStarted]: (
                <div className='sbAccordionSectionContentDataUpload'>
                    <div className='sbAccordionSectionContentDataAwardRow'>
                        {rbac.can(rbac.action.submitAwardLetterForApproval,
                            rbac.util.getSteeringGroupRole(authContext.user.id,
                                userSteeringInfo)) && <>
                            <Button
                                id={'submitForApproval'}
                                label={awardConstants.SUBMIT_FOR_APPROVAL}
                                size={'small'}
                                handleClick={() => handleStatusUpdate(awardConstants.SUBMIT,
                                    null, accountId)}
                            />
                        </>}
                    </div>
                </div>
            ),
            [approvalStatuses.stage1ApprovalPending]: (
                <div className='sbAccordionSectionContentDataUpload'>
                    <div className='sbAccordionSectionContentDataAwardRow'>
                        <div className='sbAccordionSectionContentDataAwardRowDPS'>
                            {rbac.can(rbac.action.approveStage1,
                                rbac.util.getSteeringGroupRole(authContext.user.id,
                                    userSteeringInfo)) && <>
                                <Button
                                    id={'rejectAll'}
                                    label={awardConstants.REJECT}
                                    size={'small'}
                                    variant={'primary'}
                                    additionalVariant={responseStatusColour.red}
                                    handleClick={
                                        () => handleStatusUpdate(awardConstants.ACTION_REJECTED,
                                            awardConstants.AWARD_LETTER,
                                            accountId)}
                                />
                                <Button
                                    id={'approveAll'}
                                    label={awardConstants.APPROVE}
                                    size={'small'}
                                    handleClick={
                                        () => handleStatusUpdate(awardConstants
                                            .ACTION_S1_APPROVED, awardConstants.AWARD_LETTER,
                                        accountId)}
                                />
                            </>}
                        </div>
                    </div>
                </div>
            ),
            [approvalStatuses.stage2ApprovalPending]: (
                <div className='sbAccordionSectionContentDataUpload'>
                    <div className='sbAccordionSectionContentDataAwardRow'>
                        <div className='sbAccordionSectionContentDataAwardRowDPS'>
                            {rbac.can(rbac.action.approveStage2,
                                rbac.util.getSteeringGroupRole(authContext.user.id,
                                    userSteeringInfo)) && <>
                                <Button
                                    id={'rejectAll'}
                                    label={awardConstants.REJECT}
                                    size={'small'}
                                    variant={'primary'}
                                    additionalVariant={responseStatusColour.red}
                                    handleClick={
                                        () => handleStatusUpdate(awardConstants.ACTION_REJECTED,
                                            awardConstants.AWARD_LETTER,
                                            accountId)}
                                />
                                <Button
                                    id={'approveAll'}
                                    label={awardConstants.APPROVE}
                                    size={'small'}
                                    handleClick={
                                        () => handleStatusUpdate(awardConstants
                                            .ACTION_S2_APPROVED, awardConstants.AWARD_LETTER,
                                        accountId)}
                                />
                            </>}
                        </div>
                    </div>
                </div>
            ),
            [approvalStatuses.approved]: (
                !awardLetterSent
                    ? <div className='sbAccordionSectionContentDataUpload'>
                        <div className='sbAccordionSectionContentDataAwardRow'>
                            {rbac.can(
                                rbac.action.submitAwardForApproval,
                                rbac.util.getSteeringGroupRole(
                                    authContext.user.id,
                                    userSteeringInfo,
                                ),
                            ) && <Button
                                id={'sendAwardLetters'}
                                label={awardConstants.SEND_AWARD_LETTERS}
                                size={'small'}
                                handleClick={() => handleAwardModal(accountId)}
                            />}
                        </div>
                    </div>
                    : <div className='sbAccordionSectionContentDataUpload'>
                        <div className='sbAccordionSectionContentDataAwardRow'>
                            <StatusLabel
                                id='awardsent'
                                color={responseStatusColour.green}
                                labelTxt={awardConstants.AWARD_LETTERS_SENT}
                            />
                        </div>
                    </div>
            ),
        };

        return dpsButtons[approvalStatus] || <></>;
    };

    const getUploadTitle = () => {
        const { awardApproval, awardLetterSent } = supplierSubmission || {};
        if (awardApproval && awardApproval !== awardConstants.NOT_STARTED) {
            return awardLetterSent
                ? awardConstants.AWARD_LETTERS_SENT
                : awardConstants.AWARD_LETTER_TITLE;
        }
        return awardConstants.UPLOAD_AWARD_LETTER;
    };

    return (
        <div className='award-info-data-upload'>
            {awardInfo && supplierSubmission
            && <>
                {awardInfo.addIntentProcedure === true && !isDPS
                        && <>
                            {generateStatusPills(supplierSubmission?.intentApproval)}
                            {supplierSubmission.intentStatus
                            && <Upload
                                title={supplierSubmission?.intentApproval
                                    && supplierSubmission?.intentApproval
                                    !== awardConstants.NOT_STARTED
                                    ? awardConstants.INTENT_LETTER_TITLE
                                    : awardConstants.UPLOAD_INTENT_LETTER}
                                allowMultiple={false}
                                uploadIdentifier={awardConstants.UPLOAD_IDENTIFIER}
                                uploadCallback={(docs) => uploadIntentLetter(docs,
                                    awardConstants.INTENT_LETTER, supplierSubmission.accountID)}
                                deleteCallback={(docs) => deleteIntentLetter(docs,
                                    awardConstants.INTENT_LETTER, supplierSubmission.accountID)}
                                uploadedDocuments={supplierSubmission?.docs?.filter(
                                    (doc) => doc.documentType === awardConstants.INTENT,
                                ) || []}
                                readOnly={(supplierSubmission?.intentApproval
                                    && supplierSubmission?.intentApproval
                                    !== awardConstants.NOT_STARTED) || readOnly}
                                showExpiryDate={false}
                            />}
                        </>}
                {((awardInfo?.awardInfo?.standstillEndDate
                || awardInfo?.awardInfo?.standstillApplicable === false)
                    || awardInfo.addIntentProcedure === false || isDPS)
                        && <>
                            {generateStatusPills(supplierSubmission?.awardApproval)}
                            {(((awardInfo?.awardInfo?.standstillEndDate
                            || awardInfo?.awardInfo?.standstillApplicable === false)
                                || (awardInfo.addIntentProcedure === false
                                    && supplierSubmission.intentApproval)
                                || isDPS))
                            && <Upload
                                title={getUploadTitle()}
                                allowMultiple={true}
                                uploadIdentifier={awardConstants.AWARD_LETTER_IDENTIFIER}
                                uploadCallback={(docs) => uploadIntentLetter(docs,
                                    awardConstants.AWARD_LETTER, supplierSubmission.accountID)}
                                deleteCallback={(docs) => deleteIntentLetter(docs,
                                    awardConstants.AWARD_LETTER, supplierSubmission.accountID)}
                                uploadedDocuments={supplierSubmission?.docs?.filter(
                                    (doc) => doc.documentType === awardConstants.AWARD,
                                ) || []}
                                readOnly={(supplierSubmission.intentStatus
                                    && supplierSubmission?.awardApproval
                                    && supplierSubmission?.awardApproval
                                    !== awardConstants.NOT_STARTED) || readOnly}
                                showExpiryDate={false}
                            />}
                        </>}
            </>
            }
            {(awardInfo && supplierSubmission && isDPS && (!readOnly || isPreviousSubmission))
            && generateDPSButtons(supplierSubmission.awardApproval, supplierSubmission.accountID,
                supplierSubmission.awardLetterSent)}
        </div>
    );
};

SupplierDataUpload.propTypes = {
    dataSet: PropTypes.object.isRequired,
    data: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    isDPS: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    userSteeringInfo: PropTypes.array,
    uploadIntentLetter: PropTypes.func,
    handleStatusUpdate: PropTypes.func,
    handleAwardModal: PropTypes.func,
    deleteIntentLetter: PropTypes.func,
    isPreviousSubmission: PropTypes.bool,

};


export default SupplierDataUpload;
