import constants from './constants';

const initialState = {
    supplierState: [],
};

const SupplierReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_SUPPLIERSTATE: {
            return {
                ...state,
                supplierState: action.payload,
            };
        }
        default:
            return state;
    }
};

export default SupplierReducer;
