import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from './Menu';
import chevronRight from '../../styles/images/chevronRight.svg';
import Constants from './constants';
import './styles.scss';

const ToggleIcon = ({ on }) => <img src={chevronRight}
    alt= {Constants.EXPAND}
    className={`accordion-section-header-icon ${on ? 'rotate' : '' }`}></img>;

ToggleIcon.propTypes = {
    on: PropTypes.bool,
};

const ListItem = ({
    level = 0,
    hasNodes,
    isOpen,
    label,
    searchTerm,
    openNodes,
    toggleNode,
    matchSearch,
    focused,
    openState,
    handleActionClick,
    handleStatusNodeOnClick,
    id,
    elementId,
    ...props
}) => {
    const handleClick = (e, nodeId) => {
        if (level === 0 && handleStatusNodeOnClick) { // only taking into account parents elements
            handleStatusNodeOnClick(nodeId, !isOpen);
        }
        if (hasNodes && toggleNode) toggleNode();
        e.stopPropagation();
        e.preventDefault();
    };

    const handleLinkClick = (e, href) => {
        window.open(href, '_self');
        e.stopPropagation();
        e.preventDefault();
    };

    useEffect(() => {
        setTimeout(() => {
            if (openState && toggleNode) {
                toggleNode();
            }
        }, 10);
    }, []);

    const getMenuOptions = () => {
        if (props.menu) {
            return <Menu options = {props.menu}
                handleActionClick={handleActionClick} handleLinkClick={handleLinkClick}/>;
        }
        return <></>;
    };
    return (
        <div aria-expanded={isOpen} className='tree-viewer-item' id={id} aria-level={level}>
            {hasNodes ? (
                <a href={`#${id}`} onClick={(e) => handleClick(e, elementId)} >
                    {label}
                    {getMenuOptions()}
                    <ToggleIcon on={isOpen} /> </a>
            ) : (
                <div className='tree-view-child'>
                    { label }
                    {getMenuOptions()}
                </div>
            )}
        </div>
    );
};

ListItem.propTypes = {
    level: PropTypes.number.isRequired,
    id: PropTypes.string,
    elementId: PropTypes.string,
    hasNodes: PropTypes.bool,
    isOpen: PropTypes.bool,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    searchTerm: PropTypes.string,
    openNodes: PropTypes.array,
    toggleNode: PropTypes.func,
    matchSearch: PropTypes.string,
    focused: PropTypes.bool,
    menu: PropTypes.array,
    openState: PropTypes.bool,
    className: PropTypes.string,
    handleActionClick: PropTypes.func,
    handleStatusNodeOnClick: PropTypes.func,
};


export default ListItem;
