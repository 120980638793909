import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import SelectSearch from 'react-select-search/dist/cjs';
import Modal from '../../components/Modal';
import ListedCheckBox from '../../components/ListedCheckBox';
import Tag from '../../components/Tag';
import Toast from '../../components/Alerts/Toast/Toast';
import userManagementAPIs from '../../services/user-management.service';
import BrowsePageHelper from '../../utils/browsePageHelper';
import Button from '../../components/Button';
import helperFunctions from '../../utils/helperFunctions';

const CollaborativeUserModal = ({
    closeModal,
    handleSubmit,
    initialUsers,
}) => {
    const [selectedUsers, setSelectedUsers] = useState(initialUsers || []);
    const [searchSortFilter, setSearchSortFilter] = useState({});
    const [startKey, setStartKey] = useState('');
    const [userList, setUserList] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [authorities, setAuthorities] = useState([]);

    const formatUserList = (userData) => {
        const preparedArray = [];
        userData.forEach((el) => {
            let preparedEl = {};
            if (selectedUsers.filter(
                (user) => user.userID === el.userID,
            ).length > 0) {
                preparedEl = {
                    ...el,
                    selected: !el.selected,
                };
            } else {
                preparedEl = {
                    ...el,
                };
            }
            preparedArray.push(preparedEl);
        });
        return preparedArray;
    };

    const onChange = (e, item) => {
        const currentUsers = selectedUsers || [];
        const newUser = {
            username: `${item.firstname} ${item.surname}`,
            userID: item.userID,
            departmentID: item.departmentID,
            accountID: item.accountID,
        };
        let updatedData;
        // eslint-disable-next-line max-len
        if (currentUsers.length > 0 && currentUsers.filter((user) => user.userID === newUser.userID).length > 0) {
            updatedData = currentUsers.filter((user) => user.userID !== newUser.userID);
            setSelectedUsers(updatedData);
        } else {
            currentUsers.push(newUser);
            setSelectedUsers(currentUsers);
        }

        setUserList(userList.map((el) => {
            let preparedEl = {};
            if (`checkbox-${el.userID}-checkBox-btn` === e.target.id) {
                preparedEl = {
                    ...el,
                    selected: !el.selected,
                };
            } else {
                preparedEl = {
                    ...el,
                };
            }
            return preparedEl;
        }));
    };

    const removePill = (item) => {
        const currentUsers = selectedUsers;
        const updatedData = currentUsers.filter((user) => user.userID !== item.userID);
        setSelectedUsers(updatedData);

        setUserList(userList.map((el) => {
            let preparedEl = {};
            if (el.userID === item.userID) {
                preparedEl = {
                    ...el,
                    selected: !el.selected,
                };
            } else {
                preparedEl = {
                    ...el,
                };
            }
            return preparedEl;
        }));
    };

    const generateUserTags = (data) => {
        const TagList = [];
        data.forEach((item) => {
            const TagElement = <Tag key={item.userID}
                id={item.userID}
                tagTxt={item.username}
                isDeletable={true}
                size='small'
                handleDeleteBtnClick={() => removePill(item)}
            />;
            TagList.push(TagElement);
        });
        return <div className='cuSectionTags'>
            {TagList}
        </div>;
    };

    const getDepartmentList = (authority, authoritiesData = []) => {
        let alldepts = [];
        const authoritiesList = authority === '' ? responseData : authoritiesData;
        if (authority === '' || authority === 'initialcall') {
            const deptList = authoritiesList?.map(
                (item) => item.departments?.map((dept) => ({
                    name: dept.departmentName,
                    value: dept.departmentID,
                    accountName: item.companyName,
                    accountID: item.accountID,
                })),
            )?.flat(1);
            alldepts = [
                {
                    name: 'All',
                    value: '',
                },
                ...helperFunctions.constructGroupedDropdownData(deptList, 'accountID', 'accountName'),
            ];
        } else {
            const item = responseData.filter((auth) => auth.accountID
        === authority)[0];
            alldepts = [
                {
                    name: 'All',
                    value: '',
                },
            ];
            item.departments?.map((dept) => {
                alldepts.push({
                    name: dept.departmentName,
                    value: dept.departmentID,
                });
                return null;
            });
        }
        setDepartments(alldepts);
    };

    const fetchAuthoritiesList = async () => {
        const response = await userManagementAPIs.getAllAccountsByType('buyer');
        const authorityData = response.data.map((auth) => ({
            name: `${auth.companyName}`,
            value: auth.accountID,
        }));
        setAuthorities([
            {
                name: 'All',
                value: '',
            },
            ...authorityData,
        ]);
        setResponseData(response.data);
        getDepartmentList('initialcall', response.data);
    };

    const fetchData = async (filterOptions, isPaginationQuery) => {
        try {
            const response = await userManagementAPIs.listUsers(filterOptions, true);
            if (response.status === 200) {
                if (isPaginationQuery) {
                    const newRecords = formatUserList(response.data.items);
                    setUserList([...userList, ...newRecords]);
                } else {
                    setUserList(formatUserList(response.data.items));
                }
                setStartKey(response.data?.startKey?.userID || 'N/A');
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchData();
        fetchAuthoritiesList();
    }, []);


    const handleSearchSortFilter = (data) => {
        setStartKey('');

        const updatedState = {
            ...searchSortFilter,
            ...data,
            filter: {
                ...searchSortFilter.filter,
                ...data.filter,
            },
        };

        if (data.filter && Object.keys(data.filter).length === 0) {
            updatedState.filter = {};
        }
        fetchData(BrowsePageHelper.prepareBrowsePageQueryParams(updatedState, ''));
        setSearchSortFilter({
            ...updatedState,
        });
    };

    const handleSearch = (e) => {
        const filterObject = {
            search: e.target.value,
        };
        handleSearchSortFilter(filterObject);
    };

    const handleFilter = (e, data) => {
        if (data === 'authority') {
            const filterObject = {
                filter: {
                    [data]: e,
                    department: '',
                },
            };
            handleSearchSortFilter(filterObject);
            if (data === 'authority') getDepartmentList(e);
        } else {
            const filterObject = {
                filter: {
                    [data]: e,
                },
            };
            handleSearchSortFilter(filterObject);
        }
    };

    const handleResetFilter = () => {
        handleSearchSortFilter({
            filter: {},
            search: '',
        });
    };

    const getContentBody = <>
        <div className='codesWrapper'>
            <div className='cuHeader'>
                <div className='cuFilterContainer'>
                    <div className='cuFilter'>
                        <label className='caption-small selectSearchTitle'>Authority</label>
                        <SelectSearch
                            id='authority'
                            options={authorities || []}
                            filterOptions={helperFunctions.handleFilter}
                            value={searchSortFilter?.filter?.authority || ''}
                            search={true}
                            printOptions='on-focus'
                            closeOnSelect={true}
                            multiple={false}
                            placeholder='Search authorities'
                            onChange={(e) => handleFilter(e, 'authority')}
                            z-index='4'
                        />
                    </div>
                    <div className='cuFilter'>
                        <label className='caption-small selectSearchTitle'>Department</label>
                        <SelectSearch
                            id='department'
                            options={departments}
                            filterOptions={helperFunctions.handleFilter}
                            value={searchSortFilter?.filter?.department || ''}
                            search={true}
                            printOptions='on-focus'
                            closeOnSelect={true}
                            multiple={false}
                            placeholder='Search departments'
                            onChange={(e) => handleFilter(e, 'department')}
                            z-index='4'
                        />
                    </div>
                    <div className='cuFilterButton'>
                        <label className='caption-small'></label>
                        <Button
                            id='collaborativeUserReset'
                            variant='primary'
                            additionalVariant='medium'
                            label='Reset'
                            handleClick= {() => handleResetFilter()}
                        />
                    </div>
                </div>
                {selectedUsers && generateUserTags(selectedUsers)}
            </div>
            {userList && <InfiniteScroll
                dataLength={userList.length}
                next={() => fetchData(BrowsePageHelper
                    .prepareBrowsePageQueryParams(searchSortFilter, startKey), true)}
                hasMore={startKey !== 'N/A'}
                endMessage={
                    <p className='title' id='endMessage'>
                        <b>You have seen it all.</b>
                    </p>
                }
                scrollableTarget={'collabrorativeUsers_main'}
            >
                <div className='cuUserSection'>
                    <input type='search'
                        className='searchBar body regSearch'
                        id={'search'}
                        placeholder={'Search for a User'}
                        // value={search}
                        onChange={(event) => handleSearch(event)}
                    >
                    </input>
                    <div >
                        <div className='cuItems'>
                            {userList.map((item, idx) => (
                                <div key={idx} className='cuRowItems'>
                                    <div
                                    // className='toggler'
                                    // id={`toggle-menu-item-${idx}`}
                                    >
                                        <ListedCheckBox id={`checkbox-${idx}`}
                                            label=''
                                            subLabel=''
                                            tertiaryLabel=''
                                            breakColumn={false}
                                            renderAsRow={true}
                                            onChange={(e) => onChange(e, item)}
                                            options={[{
                                                name: `checkbox-${item.userID}`,
                                                label: `${item.firstname} ${item.surname}`,
                                                subLabel: item.email,
                                                tertiaryLabel: `${item.departmentName}, ${item.accountName}`,
                                                id: `checkbox-${item.userID}`,
                                                value: item.userID,
                                                checked: item.selected || false,
                                            }]}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </InfiniteScroll> }
        </div>
    </>;

    return <Modal
        id={'collabrorativeUsers'}
        open={false}
        closeModal={closeModal}
        size='x-large'
        headerTitle='Collaborate User(s)'
        handleMainActionBtnClick={() => handleSubmit(selectedUsers)}
        mainActionButtonTxt='Select User(s)'
        closeButton={true}
        body={getContentBody}
        helpOption={false}
        secondActionButton={true}
        secondActionButtonTxt='Cancel'
        handleSecondaryActionBtnClick={closeModal}
    />;
};

CollaborativeUserModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialUsers: PropTypes.array,
};

export default CollaborativeUserModal;
