import React from 'react';
import CreateProcurementForm from '../../features/CreateProcurementForm';

const Renewal = () => (
    <section id='cPPSection'>
        <p className='title-xLarge screenTitleHeading' id='sectionTitle'>Enter Project Details</p>
        <CreateProcurementForm type={'renewal'}/>
    </section>
);

export default Renewal;
