import React from 'react';
import ProjectOverviewContent from './components';

const ProjectOverview = () => (
    <>
        <ProjectOverviewContent />
    </>
);

export default ProjectOverview;
