/* eslint-disable max-len */
import React from 'react';
import rbac from '../../../../../../rbac';
import Button from '../../../../../../components/Button';
import { eventActions, eventStatuses, workflowEvents as eventTypes } from '../../../../../../config/constants';

const genericRbacRoles = ['po', 'pm', 'cs', 'cm'];

const getStartEventButton = (handleUpdateEventStatus, eventID, userSteeringRoles, responsibleRole) => responsibleRole
&& userSteeringRoles
&& (responsibleRole !== 'supplier' ? rbac.can([responsibleRole], userSteeringRoles) : rbac.can([...genericRbacRoles], userSteeringRoles))
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless white'
    label='Start Event'
    handleClick={() => handleUpdateEventStatus(eventID, eventActions.started, 'start')}
/>;

const getEscalateButton = (setEscalateModal, userSteeringRoles) => userSteeringRoles
&& rbac.can(rbac.action.escalateEvent, userSteeringRoles)
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless red'
    label='Escalate'
    handleClick={() => setEscalateModal(true)}
/>;

const getSendForApprovalButton = (handleUpdateEventStatus, eventID, userSteeringRoles, responsibleRole) => responsibleRole && userSteeringRoles
&& (responsibleRole !== 'supplier' ? rbac.can([responsibleRole], userSteeringRoles) : rbac.can([...genericRbacRoles], userSteeringRoles))
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless white'
    label='Send for approval'
    handleClick={() => handleUpdateEventStatus(
        eventID, eventActions.awaitingApproval, 'send it for approval',
    )}
/>;

const getStage1ApproveButton = (handleUpdateEventStatus, eventID, userSteeringRoles) => userSteeringRoles
&& rbac.can([...genericRbacRoles], userSteeringRoles)
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless white'
    label='Approve'
    handleClick={() => handleUpdateEventStatus(
        eventID,
        eventActions.approveStage1,
        'approve',
    )}
/>;

const getStage2ApproveButton = (handleUpdateEventStatus, eventID, userSteeringRoles) => userSteeringRoles
&& rbac.can([...genericRbacRoles], userSteeringRoles)
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless white'
    label='Approve'
    handleClick={() => handleUpdateEventStatus(
        eventID,
        eventActions.approveStage2,
        'approve',
    )}
/>;

const getStage1RejectButton = (handleUpdateEventStatus, eventID, userSteeringRoles) => userSteeringRoles
&& rbac.can([...genericRbacRoles], userSteeringRoles)
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless red'
    label='Reject'
    handleClick={() => handleUpdateEventStatus(
        eventID,
        eventActions.rejectStage1,
        'reject',
    )}
/>;

const getStage2RejectButton = (handleUpdateEventStatus, eventID, userSteeringRoles) => userSteeringRoles
&& rbac.can([...genericRbacRoles], userSteeringRoles)
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless red'
    label='Reject'
    handleClick={() => handleUpdateEventStatus(
        eventID,
        eventActions.rejectStage2,
        'reject',
    )}
/>;

const getReopenButton = (handleUpdateEventStatus, eventID, userSteeringRoles, responsibleRole) => responsibleRole && userSteeringRoles
&& (responsibleRole !== 'supplier' ? rbac.can([responsibleRole], userSteeringRoles) : rbac.can([...genericRbacRoles], userSteeringRoles))
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless white'
    label='Reopen'
    handleClick={() => handleUpdateEventStatus(
        eventID,
        eventActions.reopen,
    )}
/>;

const getRenewButton = (handleUpdateEventStatus, eventID, userSteeringRoles, responsibleRole) => responsibleRole && userSteeringRoles
&& rbac.can([responsibleRole], userSteeringRoles)
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless white'
    label='Renew'
    handleClick={() => handleUpdateEventStatus(eventID, eventActions.renew, 'renew')}
/>;

const getExtendButton = (handleUpdateEventStatus, eventID, userSteeringRoles, responsibleRole) => responsibleRole && userSteeringRoles
&& rbac.can([responsibleRole], userSteeringRoles)
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless white'
    label='Extend'
    handleClick={() => handleUpdateEventStatus(eventID, eventActions.extend, 'extend')}
/>;

const createContractVariationButton = (setShowVariation, eventID, userSteeringRoles, responsibleRole) => responsibleRole && userSteeringRoles
&& rbac.can([responsibleRole], userSteeringRoles)
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless white'
    label='Create Variation'
    handleClick={() => setShowVariation(true)}
/>;

const getCompleteButton = (handleCompleteEventStatus, eventID, userSteeringRoles, responsibleRole) => responsibleRole && userSteeringRoles
&& (responsibleRole !== 'supplier' ? rbac.can([responsibleRole], userSteeringRoles) : rbac.can([...genericRbacRoles], userSteeringRoles))
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless white'
    label='Complete'
    handleClick={() => handleCompleteEventStatus(
        eventID, eventActions.complete,
    )}
/>;

const getDefaultEventButtons = (
    event,
    handleUpdateEventStatus,
    handleCompleteEventStatus,
    setEscalateModal,
    userSteeringRoles,
) => {
    switch (event.eventStatus) {
        case eventStatuses.pending:
            return <div className='button-container'>
                {getStartEventButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.started:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {event.stageFirstApproval
                    ? getSendForApprovalButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                    : getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                }
            </div>;
        case eventStatuses.awaitingApproval:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getStage1ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                {getStage1RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
            </div>;
        case eventStatuses.stageFirstApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {event.stageSecondApproval
                    ? <>
                        {getStage2ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                        {getStage2RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                    </>
                    : getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                }
            </div>;
        case eventStatuses.stageSecondApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.completed:
            return <div className='button-container'>
                {getReopenButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        default: return <></>;
    }
};

const getRenewalButtons = (
    event,
    handleUpdateEventStatus,
    handleRenewEventHandler,
    handleCompleteEventStatus,
    setEscalateModal,
    userSteeringRoles,
) => {
    switch (event?.eventStatus) {
        case eventStatuses.pending:
            return <div className='button-container'>
                {getStartEventButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.started:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {event.stageFirstApproval
                    && getSendForApprovalButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                }
                {getRenewButton(handleRenewEventHandler, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.awaitingApproval:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getStage1ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                {getStage1RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
            </div>;
        case eventStatuses.stageFirstApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {event.stageSecondApproval
                    ? <>
                        {getStage2ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                        {getStage2RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                    </>
                    : <>
                        {getRenewButton(handleRenewEventHandler, event.eventID, userSteeringRoles, event.rolesResponsible)}
                        {getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
                    </>
                }
            </div>;
        case eventStatuses.stageSecondApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getRenewButton(handleRenewEventHandler, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.completed:
            return <div className='button-container'>
                {getReopenButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        default: return <></>;
    }
};

const getCreateVariationButtons = (
    event,
    handleUpdateEventStatus,
    setShowVariation,
    handleCompleteEventStatus,
    setEscalateModal,
    userSteeringRoles,
) => {
    switch (event?.eventStatus) {
        case eventStatuses.pending:
            return <div className='button-container'>
                {getStartEventButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.started:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {event.stageFirstApproval
                    && getSendForApprovalButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                }
                {createContractVariationButton(setShowVariation, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.awaitingApproval:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getStage1ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                {getStage1RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
            </div>;
        case eventStatuses.stageFirstApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {event.stageSecondApproval
                    ? <>
                        {getStage2ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                        {getStage2RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                    </>
                    : <>
                        {createContractVariationButton(setShowVariation, event.eventID, userSteeringRoles, event.rolesResponsible)}
                        {getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
                    </>
                }
            </div>;
        case eventStatuses.stageSecondApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {createContractVariationButton(setShowVariation, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.completed:
            return <div className='button-container'>
                {getReopenButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        default: return <></>;
    }
};

const getExtendEventButtons = (
    event,
    handleUpdateEventStatus,
    handleExtentContract,
    handleCompleteEventStatus,
    setEscalateModal,
    userSteeringRoles,
) => {
    switch (event?.eventStatus) {
        case eventStatuses.pending:
            return <div className='button-container'>
                {getStartEventButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.started:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getExtendButton(handleExtentContract, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {event.stageFirstApproval
                    ? getSendForApprovalButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                    : getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                }
            </div>;
        case eventStatuses.awaitingApproval:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getStage1ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                {getStage1RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
            </div>;
        case eventStatuses.stageFirstApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {event.stageSecondApproval
                    ? <>
                        {getStage2ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                        {getStage2RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                    </>
                    : <>
                        {getExtendButton(handleExtentContract, event.eventID, userSteeringRoles, event.rolesResponsible)}
                        {getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
                    </>
                }
            </div>;
        case eventStatuses.stageSecondApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getExtendButton(handleExtentContract, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.completed:
            return <div className='button-container'>
                {getReopenButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        default: return <></>;
    }
};

const getSupplierDocumentEventButtons = (
    event,
    handleUpdateEventStatus,
    handleCompleteEventStatus,
    setEscalateModal,
    userSteeringRoles,
) => {
    switch (event.eventStatus) {
        case eventStatuses.pending:
            return event.suppliersResponsibleForEvent.length > 0
            && <div className='button-container'>
                {getStartEventButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, 'supplier')}
            </div>;
        case eventStatuses.started:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {event.stageFirstApproval
                    ? getSendForApprovalButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, 'supplier')
                    : getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, 'supplier')
                }
            </div>;
        case eventStatuses.awaitingApproval:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getStage1ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                {getStage1RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
            </div>;
        case eventStatuses.stageFirstApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {event.stageSecondApproval
                    ? <>
                        {getStage2ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                        {getStage2RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                    </>
                    : getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, 'supplier')
                }
            </div>;
        case eventStatuses.stageSecondApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getCompleteButton(handleCompleteEventStatus, event.eventID, userSteeringRoles, 'supplier')}
            </div>;
        case eventStatuses.completed:
            return <div className='button-container'>
                {getReopenButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, 'supplier')}
            </div>;
        default: return <></>;
    }
};

const eventButtons = (
    event,
    setEscalateModal,
    setShowVariation,
    handleUpdateEventStatus,
    handleExtentContract,
    handleRenewContract,
    handleCompleteEventStatus,
    userSteeringRoles,
) => {
    switch (event?.eventType) {
        case eventTypes.variation:
            return <div className='button-container'>
                {getCreateVariationButtons(
                    event,
                    handleUpdateEventStatus,
                    setShowVariation,
                    handleCompleteEventStatus,
                    setEscalateModal,
                    userSteeringRoles,
                )}
            </div>;
        case eventTypes.renewContract:
            return <div className='button-container'>
                {getRenewalButtons(
                    event,
                    handleUpdateEventStatus,
                    handleRenewContract,
                    handleCompleteEventStatus,
                    setEscalateModal,
                    userSteeringRoles,
                )}
            </div>;
        case eventTypes.extension:
            return <div className='button-container'>
                {getExtendEventButtons(
                    event,
                    handleUpdateEventStatus,
                    handleExtentContract,
                    handleCompleteEventStatus,
                    setEscalateModal,
                    userSteeringRoles,
                )}
            </div>;
        case eventTypes.supplierDocumentation:
            return <div className='button-container'>
                {getSupplierDocumentEventButtons(
                    event,
                    handleUpdateEventStatus,
                    handleCompleteEventStatus,
                    setEscalateModal,
                    userSteeringRoles,
                )}
            </div>;
        default:
            return <div className='button-container'>
                {getDefaultEventButtons(
                    event,
                    handleUpdateEventStatus,
                    handleCompleteEventStatus,
                    setEscalateModal,
                    userSteeringRoles,
                )}
            </div>;
    }
};

export default eventButtons;
