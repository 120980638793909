const constants = {
    specialAccountPrivileges: [
        {
            name: 'Organisation Directors',
            id: 'organisation_directors',
        },
        {
            name: 'Organisation Rebate Officers',
            id: 'organisation_rebate_officers',
        },
        {
            name: 'Organisation Management Approvers',
            id: 'organisation_management_approvers',
        },
        {
            name: 'Organisation Communications Team',
            id: 'organisation_communications_team',
        },
        {
            name: 'Organisation Pre-procurement Reviewers',
            id: 'organisation_preprocurement_reviewers',
        },
    ],
    successMessage: 'Profile updated successfully.',
};

export default constants;
