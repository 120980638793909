module.exports = [
    {
        AA01: 'Metal',
    },
    {
        AA02: 'Aluminium',
    },
    {
        AA03: 'Bronze',
    },
    {
        AA04: 'Copper',
    },
    {
        AA05: 'Iron',
    },
    {
        AA06: 'Lead',
    },
    {
        AA07: 'Nickel',
    },
    {
        AA08: 'Tin',
    },
    {
        AA09: 'Zinc',
    },
    {
        AA10: 'Alloy',
    },
    {
        AA11: 'Steel',
    },
    {
        AA12: 'Carbon-steel',
    },
    {
        AA13: 'Vanadium',
    },
    {
        AA14: 'Chromium',
    },
    {
        AA15: 'Manganese',
    },
    {
        AA16: 'Cobalt',
    },
    {
        AA17: 'Yttrium',
    },
    {
        AA18: 'Zirconium',
    },
    {
        AA19: 'Molybdenum',
    },
    {
        AA20: 'Technetium',
    },
    {
        AA21: 'Ruthenium',
    },
    {
        AA22: 'Rhodium',
    },
    {
        AA23: 'Cadmium',
    },
    {
        AA24: 'Lutetium',
    },
    {
        AA25: 'Hafnium',
    },
    {
        AA26: 'Tantalum',
    },
    {
        AA27: 'Tungsten',
    },
    {
        AA28: 'Iridium',
    },
    {
        AA29: 'Gallium',
    },
    {
        AA30: 'Indium',
    },
    {
        AA31: 'Thallium',
    },
    {
        AA32: 'Barium',
    },
    {
        AA33: 'Caesium',
    },
    {
        AA34: 'Strontium',
    },
    {
        AA35: 'Rubidium',
    },
    {
        AA36: 'Calcium',
    },
    {
        AA37: 'Potassium',
    },
    {
        AA38: 'Sodium',
    },
    {
        AA39: 'Lithium',
    },
    {
        AA40: 'Niobium',
    },
    {
        AA41: 'Osmium',
    },
    {
        AA42: 'Rhenium',
    },
    {
        AA43: 'Palladium',
    },
    {
        AA44: 'Brass',
    },
    {
        AA45: 'Gold',
    },
    {
        AA46: 'Silver',
    },
    {
        AA47: 'Platinum',
    },
    {
        AA48: 'Magnesium',
    },
    {
        AB02: 'Ceramic',
    },
    {
        AB03: 'Porcelain or china ceramic',
    },
    {
        AB04: 'Clay',
    },
    {
        AB05: 'Concrete',
    },
    {
        AB06: 'Plastic',
    },
    {
        AB07: 'Polyester',
    },
    {
        AB08: 'Polyethene (Polyethylene)',
    },
    {
        AB09: 'Polystyrene',
    },
    {
        AB11: 'PVC',
    },
    {
        AB12: 'Rubber',
    },
    {
        AB13: 'Wooden',
    },
    {
        AB14: 'Coniferous wood',
    },
    {
        AB15: 'Tropical wood',
    },
    {
        AB16: 'Bamboo',
    },
    {
        AB17: 'Cork',
    },
    {
        AB18: 'Paper',
    },
    {
        AB19: 'Leather',
    },
    {
        AB20: 'Fibreglass',
    },
    {
        AB21: 'Glass',
    },
    {
        AB22: 'Glass inners',
    },
    {
        AB23: 'Glass parts',
    },
    {
        AB24: 'Textile',
    },
    {
        AB25: 'Synthetic',
    },
    {
        AB26: 'Timber',
    },
    {
        AB27: 'Stone',
    },
    {
        AB28: 'Marble',
    },
    {
        AB29: 'Precious stones',
    },
    {
        AB30: 'Wool',
    },
    {
        AB31: 'Silk',
    },
    {
        AB32: 'Linen',
    },
    {
        AB33: 'Cotton',
    },
    {
        AB34: 'Terrazzo',
    },
    {
        AB35: 'Granite',
    },
    {
        AB36: 'Slate',
    },
    {
        AB37: 'Carbon',
    },
    {
        BA01: 'Live',
    },
    {
        BA02: 'Natural',
    },
    {
        BA03: 'Artificial',
    },
    {
        BA04: 'Fresh',
    },
    {
        BA05: 'Cold',
    },
    {
        BA06: 'Hot',
    },
    {
        BA07: 'Liquid',
    },
    {
        BA08: 'Solid',
    },
    {
        BA09: 'Gas',
    },
    {
        BA10: 'Crude',
    },
    {
        BA11: 'Dry',
    },
    {
        BA12: 'Hard',
    },
    {
        BA13: 'Soft',
    },
    {
        BA14: 'Dust/powder',
    },
    {
        BA15: 'Residues',
    },
    {
        BA16: 'Flakes',
    },
    {
        BA17: 'Bars',
    },
    {
        BA18: 'Ash',
    },
    {
        BA19: 'Granules',
    },
    {
        BA20: 'Ingots',
    },
    {
        BA22: 'Rods',
    },
    {
        BA23: 'Unwrought',
    },
    {
        BA24: 'Frozen',
    },
    {
        BA25: 'Deep-frozen',
    },
    {
        BA26: 'Folded',
    },
    {
        BA27: 'Forged',
    },
    {
        BA28: 'Freeze-dried',
    },
    {
        BA29: 'Flat-rolled',
    },
    {
        BA30: 'Dried',
    },
    {
        BA31: 'Grated',
    },
    {
        BA32: 'Concentrated',
    },
    {
        BA33: 'Chilled',
    },
    {
        BA34: 'Coated',
    },
    {
        BA35: 'Galvanised',
    },
    {
        BA36: 'Granulated',
    },
    {
        BA37: 'Hot-rolled',
    },
    {
        BA38: 'Vitrified',
    },
    {
        BA39: 'Laminated',
    },
    {
        BA40: 'Liquefied',
    },
    {
        BA41: 'Vulcanised',
    },
    {
        BA42: 'Welded',
    },
    {
        BA43: 'Prepared',
    },
    {
        BA44: 'Pulverised',
    },
    {
        BA45: 'Refined',
    },
    {
        BA46: 'Remelted',
    },
    {
        BA47: 'Untreated',
    },
    {
        BA48: 'Treated',
    },
    {
        BA49: 'Unliquefied',
    },
    {
        BA50: 'Unrefined',
    },
    {
        BA51: 'Semi-finished',
    },
    {
        BA52: 'Processed',
    },
    {
        BA53: 'Semi-manufactured',
    },
    {
        BA54: 'Solution',
    },
    {
        BB01: 'Non-tubular',
    },
    {
        BB02: 'Tubular',
    },
    {
        BB03: 'Round',
    },
    {
        BB04: 'Rectangular',
    },
    {
        BB05: 'Square',
    },
    {
        BB06: 'Triangle',
    },
    {
        BB07: 'Trapezoid',
    },
    {
        BC01: 'With metal frames',
    },
    {
        BC02: 'With plastic frames',
    },
    {
        BC03: 'With wooden frames',
    },
    {
        BC04: 'Clad with gold',
    },
    {
        BC05: 'Clad with platinum',
    },
    {
        BC06: 'Clad with silver',
    },
    {
        BC07: 'Steel-framed',
    },
    {
        BC08: 'Veneer',
    },
    {
        BC09: 'Non-cast',
    },
    {
        BC10: 'Upholstered',
    },
    {
        BC11: 'Printed',
    },
    {
        BC12: 'Coloured',
    },
    {
        BC13: 'Impregnated',
    },
    {
        BC14: 'Prefabricated',
    },
    {
        BC15: 'Plastic coated',
    },
    {
        BC16: 'In bags',
    },
    {
        BC17: 'In blocks',
    },
    {
        BC18: 'In boards',
    },
    {
        BC19: 'In brine',
    },
    {
        BC20: 'In bulk',
    },
    {
        BC21: 'In cartons',
    },
    {
        BC22: 'In cartridges',
    },
    {
        BC23: 'In cuts',
    },
    {
        BC24: 'In joints',
    },
    {
        BC25: 'In lots',
    },
    {
        BC26: 'In oil',
    },
    {
        BC27: 'In packets',
    },
    {
        BC28: 'In pouches',
    },
    {
        BC29: 'In reams',
    },
    {
        BC30: 'In rods',
    },
    {
        BC31: 'In rolls',
    },
    {
        BC32: 'In sachets',
    },
    {
        BC33: 'In sacks',
    },
    {
        BC34: 'In sheets',
    },
    {
        BC35: 'In slices',
    },
    {
        BC36: 'In solution',
    },
    {
        BC37: 'In strips',
    },
    {
        BC38: 'In water',
    },
    {
        BC39: 'Flavoured',
    },
    {
        BC40: 'Moulded glass',
    },
    {
        BC41: 'Canned/tinned',
    },
    {
        BC42: 'Bottled',
    },
    {
        BC43: 'Vacuum-packed',
    },
    {
        BC44: 'Parboiled',
    },
    {
        BC45: 'Recycled',
    },
    {
        BC46: 'Sterilised',
    },
    {
        BC48: 'Toughened',
    },
    {
        CA01: 'Waterproof',
    },
    {
        CA02: 'Windproof',
    },
    {
        CA03: 'Bullet-proof',
    },
    {
        CA04: 'Fireproof/flameproof',
    },
    {
        CA05: 'Stainless',
    },
    {
        CA06: 'Expandable',
    },
    {
        CA07: 'Insulated',
    },
    {
        CA08: 'Non-insulated',
    },
    {
        CA09: 'Refractory',
    },
    {
        CA10: 'Non-refractory',
    },
    {
        CA11: 'Reinforced',
    },
    {
        CA12: 'Wired',
    },
    {
        CA13: 'Oil-absorbent',
    },
    {
        CA14: 'Self-adhesive',
    },
    {
        CA15: 'Ductile',
    },
    {
        CA16: 'Magnetic',
    },
    {
        CA17: 'Environmentally friendly',
    },
    {
        CA18: 'GMO free',
    },
    {
        CA19: 'Hormone free',
    },
    {
        CA20: 'Pesticide free',
    },
    {
        CA21: 'Asbestos free',
    },
    {
        CA22: 'CFC free',
    },
    {
        CA23: 'Ozone free',
    },
    {
        CA24: 'PVC free',
    },
    {
        CA25: 'Hybrid',
    },
    {
        CA26: 'Hydrocarbon free',
    },
    {
        CA27: 'NOx free',
    },
    {
        CA28: 'CO free',
    },
    {
        CA29: 'CO2 free',
    },
    {
        CA30: 'Sulphur free',
    },
    {
        CA31: 'Euro 0 (fuel)',
    },
    {
        CA32: 'Euro 1 (fuel)',
    },
    {
        CA33: 'Euro 2 (fuel)',
    },
    {
        CA34: 'Euro 3 (fuel)',
    },
    {
        CA35: 'Euro 4 (fuel)',
    },
    {
        CA36: 'Euro 5 (fuel)',
    },
    {
        CA37: 'Articulated',
    },
    {
        CA38: 'Long-life',
    },
    {
        CA39: 'Lightweight',
    },
    {
        CA40: 'Seagoing',
    },
    {
        CA41: 'Semi-submersible',
    },
    {
        CA42: 'Submersible',
    },
    {
        CA43: 'Digital',
    },
    {
        CA44: 'Portable',
    },
    {
        CA45: 'High resolution',
    },
    {
        CA46: 'High-performance',
    },
    {
        CA47: 'Multitasking',
    },
    {
        CA48: 'Multi-user',
    },
    {
        CA49: 'Upgradeable',
    },
    {
        CA50: 'Disposable',
    },
    {
        CA51: 'Rechargeable',
    },
    {
        CA52: 'Fire resistant/flame resistant',
    },
    {
        CA53: 'Fire retardant',
    },
    {
        CA54: 'Heat-resistant',
    },
    {
        CA55: 'Sterile',
    },
    {
        CA56: 'Non-sterile',
    },
    {
        CA57: 'Ecolabel',
    },
    {
        CA58: 'Colorants free',
    },
    {
        CA59: 'Preservatives free',
    },
    {
        CA60: 'Tridimensional',
    },
    {
        CA61: 'Removable',
    },
    {
        CA62: 'Organic',
    },
    {
        CA63: 'Cultivated',
    },
    {
        CA64: 'Wild',
    },
    {
        CA65: 'Farmed',
    },
    {
        CA66: 'Single-use',
    },
    {
        CA67: 'Reusable',
    },
    {
        CA68: 'Armoured',
    },
    {
        CA69: 'Ergonomic',
    },
    {
        CB01: 'Electrically heated',
    },
    {
        CB02: 'Remote-controlled',
    },
    {
        CB03: 'High pressure',
    },
    {
        CB04: 'High-voltage',
    },
    {
        CB05: 'Low pressure',
    },
    {
        CB06: 'Low-voltage',
    },
    {
        CB07: 'Medium pressure',
    },
    {
        CB08: '4-wheel-drive',
    },
    {
        CB09: 'Diesel-powered',
    },
    {
        CB10: 'Electrically powered',
    },
    {
        CB11: 'Hydro-electric power',
    },
    {
        CB12: 'Nuclear powered',
    },
    {
        CB13: 'Solar powered',
    },
    {
        CB14: 'Petrol-engined',
    },
    {
        CB15: 'Mechanical',
    },
    {
        CB16: 'Mechanical and electrical',
    },
    {
        CB17: 'Self-drive',
    },
    {
        CB18: 'Automatic',
    },
    {
        CB19: 'Computerised',
    },
    {
        CB20: 'Computer-aided',
    },
    {
        CB21: 'Computer-based',
    },
    {
        CB22: 'Computer-controlled',
    },
    {
        CB23: 'Multiprocessing',
    },
    {
        CB24: 'Synchronous',
    },
    {
        CB25: 'Asynchronous',
    },
    {
        CB26: 'Handheld',
    },
    {
        CB27: 'Integrated',
    },
    {
        CB28: 'Fossil-fuel-fired',
    },
    {
        CB29: 'Coal-fired',
    },
    {
        CB30: 'Gas-fired',
    },
    {
        CB31: 'Oil-fired',
    },
    {
        CB32: 'Wood-fired',
    },
    {
        CB33: '1-phased',
    },
    {
        CB34: '2-phased',
    },
    {
        CB35: '3-phased',
    },
    {
        CB36: 'Instant',
    },
    {
        CB37: 'Single-phase',
    },
    {
        CB38: 'Stand-alone',
    },
    {
        CB39: 'Uninterruptible',
    },
    {
        CB40: 'Interruptible',
    },
    {
        CB41: 'Hybrid powered',
    },
    {
        CB42: 'Battery powered',
    },
    {
        CB43: 'Bio-diesel powered',
    },
    {
        CB44: 'Hydrogen powered',
    },
    {
        CB45: 'Ethanol powered',
    },
    {
        CB46: 'Kerosene powered',
    },
    {
        CB47: 'Gas-powered',
    },
    {
        DA01: 'International',
    },
    {
        DA02: 'National',
    },
    {
        DA03: 'Public',
    },
    {
        DA04: 'Private',
    },
    {
        DA05: 'Second-hand',
    },
    {
        DA06: 'Primary',
    },
    {
        DA07: 'Secondary',
    },
    {
        DA08: 'Tertiary',
    },
    {
        DA09: 'Real-time',
    },
    {
        DA10: 'Stage',
    },
    {
        DA11: 'Secretarial',
    },
    {
        DA12: 'Indoor',
    },
    {
        DA13: 'Outdoor',
    },
    {
        DA14: 'Mobile',
    },
    {
        DA15: 'Domestic',
    },
    {
        DA16: 'Non-domestic',
    },
    {
        DA17: 'Turnkey project',
    },
    {
        DA18: 'Underground',
    },
    {
        DA19: 'Underwater',
    },
    {
        DA20: 'On-site',
    },
    {
        DA21: 'Above-ground',
    },
    {
        DA22: 'All-purpose',
    },
    {
        DA23: 'Special-purpose',
    },
    {
        DA24: 'And related articles',
    },
    {
        DA25: 'Standard',
    },
    {
        DA26: 'Management',
    },
    {
        DA27: 'Administrative',
    },
    {
        DA28: 'Social service',
    },
    {
        DA29: 'Social work',
    },
    {
        DA30: 'Counselling',
    },
    {
        DA31: 'Daycare',
    },
    {
        DA32: 'Family-planning',
    },
    {
        DA33: 'Rehabilitation',
    },
    {
        DA34: 'Guidance',
    },
    {
        DA35: 'From workroom',
    },
    {
        DA36: 'Natural resource',
    },
    {
        DA37: 'Recycling',
    },
    {
        DA38: 'Decontamination',
    },
    {
        DA39: 'Pollution tracking',
    },
    {
        DA40: 'Quality control',
    },
    {
        DA41: 'Treatment',
    },
    {
        DA42: 'Including installation',
    },
    {
        DA43: 'Multipurpose',
    },
    {
        DA44: 'Spare parts',
    },
    {
        EA01: 'For babies',
    },
    {
        EA02: 'For children',
    },
    {
        EA03: 'For teenager',
    },
    {
        EA04: 'For men',
    },
    {
        EA05: 'For elderly person',
    },
    {
        EA06: 'For women',
    },
    {
        EA07: 'For pregnant women',
    },
    {
        EA08: 'For unemployed person',
    },
    {
        EA09: 'For homeless',
    },
    {
        EA10: 'For drug user',
    },
    {
        EA11: 'For pensioner',
    },
    {
        EA12: 'For/Adapted for disabled person',
    },
    {
        EA13: 'For/Adapted for physical disabled',
    },
    {
        EA14: 'For/Adapted for paralysed person',
    },
    {
        EA15: 'For/Adapted for paralysed on one side person',
    },
    {
        EA16: 'For/Adapted for tetraplegic person',
    },
    {
        EA17: 'For/Adapted for paraplegic person',
    },
    {
        EA18: 'For/Adapted for mute person',
    },
    {
        EA19: 'For/Adapted for hard of hearing person',
    },
    {
        EA20: 'For/Adapted for deaf-mute person',
    },
    {
        EA21: 'For/Adapted for visually impaired person',
    },
    {
        EA22: 'For/Adapted for mentally disabled',
    },
    {
        EA23: 'For passenger',
    },
    {
        EA24: 'For restricted-clientele',
    },
    {
        EA25: 'For prisoners',
    },
    {
        EA26: 'For patient',
    },
    {
        EA27: 'For non-emergency patient',
    },
    {
        EA28: 'For regular passenger',
    },
    {
        EA29: 'For human use',
    },
    {
        EA30: 'For animal use',
    },
    {
        FA01: 'For educational use',
    },
    {
        FA02: 'For kindergarten use',
    },
    {
        FA03: 'For school use',
    },
    {
        FA04: 'For training purposes',
    },
    {
        FB01: 'For military use',
    },
    {
        FB02: 'For weapons and ammunition',
    },
    {
        FB03: 'For the police force',
    },
    {
        FB04: 'For firefighting',
    },
    {
        FB05: 'For prison use',
    },
    {
        FB06: 'For rescue',
    },
    {
        FB07: 'For emergency',
    },
    {
        FB08: 'For fire protection',
    },
    {
        FB09: 'For security system',
    },
    {
        FB10: 'For nuclear protection',
    },
    {
        FB11: 'In case of radiation',
    },
    {
        FB12: 'For terrorist prevention',
    },
    {
        FB13: 'With military specifications',
    },
    {
        FC01: 'For waste disposal',
    },
    {
        FC02: 'For waste material',
    },
    {
        FC03: 'For domestic waste',
    },
    {
        FD01: 'For spring',
    },
    {
        FD02: 'For summer',
    },
    {
        FD03: 'For fall',
    },
    {
        FD04: 'For winter',
    },
    {
        FD05: 'For all seasons',
    },
    {
        FE01: 'For packing goods',
    },
    {
        FE02: 'For parcels',
    },
    {
        FE03: 'For post-office use',
    },
    {
        FF01: 'For cleaning',
    },
    {
        FF02: 'For day homes',
    },
    {
        FG01: 'For access',
    },
    {
        FG02: 'For exhibition use',
    },
    {
        FG03: 'For graphic purposes',
    },
    {
        FG04: 'For heating',
    },
    {
        FG05: 'For industrial use',
    },
    {
        FG06: 'For printing',
    },
    {
        FG07: 'For naval use',
    },
    {
        FG08: 'For office use',
    },
    {
        FG09: 'For indoor use',
    },
    {
        FG10: 'For outdoor use',
    },
    {
        FG11: 'For scientific purposes',
    },
    {
        FG12: 'For storage purposes',
    },
    {
        FG13: 'For survival',
    },
    {
        FG14: 'For technical uses',
    },
    {
        FG15: 'For test purposes',
    },
    {
        FG16: 'For urban use',
    },
    {
        FG17: 'For use in agriculture',
    },
    {
        FG18: 'For use in horticulture',
    },
    {
        FG19: 'For camping',
    },
    {
        FG20: 'For kitchen use',
    },
    {
        FG21: 'For subsea use',
    },
    {
        FG22: 'For cosmetics',
    },
    {
        FG23: 'For tourism',
    },
    {
        FG24: 'For use in artistic performances',
    },
    {
        FG25: 'For buildings or goods of particular historical or architectural interest',
    },
    {
        GA01: 'Specific weight required',
    },
    {
        GA02: 'Gross vehicle weight limited',
    },
    {
        GA03: 'Specific tonnes per annum required',
    },
    {
        GA04: 'Specific kg per annum required',
    },
    {
        GA05: 'Specific height required',
    },
    {
        GA06: 'Specific width required',
    },
    {
        GA07: 'Specific thickness required',
    },
    {
        GA08: 'Specific length required',
    },
    {
        GA09: 'Specific diameter required',
    },
    {
        GA10: 'Specific volume required',
    },
    {
        GA11: 'Specific litres per annum required',
    },
    {
        GA12: 'Specific size required',
    },
    {
        GA13: 'Specific unit size required',
    },
    {
        GA14: 'Specific capacity required',
    },
    {
        GA15: 'Specific circumference required',
    },
    {
        GA16: 'Specific depth required',
    },
    {
        GA17: 'Specific kWh per annum required',
    },
    {
        GA18: 'Specific MWh per annum required',
    },
    {
        GA19: 'Per annum (pa)',
    },
    {
        GA20: 'Specific power',
    },
    {
        GA21: 'Specific pressure',
    },
    {
        GA22: 'Specific voltage required',
    },
    {
        GA32: 'Specific degrees Celsius required',
    },
    {
        GA33: 'Specific degrees Fahrenheit required',
    },
    {
        GB01: 'Daily',
    },
    {
        GB02: 'Weekly',
    },
    {
        GB03: 'Monthly',
    },
    {
        GB04: 'Annually',
    },
    {
        GC01: 'Not exceeding',
    },
    {
        GC02: 'In excess of',
    },
    {
        GC03: 'A maximum of',
    },
    {
        GC04: 'A minimum of',
    },
    {
        GC05: 'Phase:',
    },
    {
        GC06: 'Rating:',
    },
    {
        GC07: 'Reference:',
    },
    {
        GC08: 'Response time',
    },
    {
        GC09: 'Rooms:',
    },
    {
        GC10: 'Scale of work:',
    },
    {
        GC11: 'Specifications:',
    },
    {
        GC12: 'Subsidiary lot:',
    },
    {
        GC13: 'Type:',
    },
    {
        GC14: 'Type of architecture:',
    },
    {
        GC15: 'Type of protocol:',
    },
    {
        GC16: 'Type of standard:',
    },
    {
        GC17: 'Dwellings:',
    },
    {
        GC18: 'Lots:',
    },
    {
        GC19: 'Lot No',
    },
    {
        GC20: 'Main lot:',
    },
    {
        GC21: 'Area:',
    },
    {
        GC22: 'Commissioning in',
    },
    {
        GC23: 'Lengthening',
    },
    {
        GC24: 'Whole',
    },
    {
        GC25: 'Number:',
    },
    {
        GC26: 'Number of channels:',
    },
    {
        GC27: 'Number of items:',
    },
    {
        GC28: 'Number of phases:',
    },
    {
        GC29: 'Number of seats:',
    },
    {
        GC30: 'Number of sets:',
    },
    {
        GC31: 'Number of units:',
    },
    {
        GC32: 'Number of users:',
    },
    {
        GC33: 'Number of sheets:',
    },
    {
        GC34: 'Number of copies:',
    },
    {
        HA01: 'Cook and chill',
    },
    {
        HA02: 'Cook and frozen',
    },
    {
        HA03: 'Kosher',
    },
    {
        HA04: 'Pork free',
    },
    {
        HA05: 'Alcohol free',
    },
    {
        HA06: 'Diabetic meal',
    },
    {
        HA07: 'Gluten free meal',
    },
    {
        HA08: 'High fibre meal',
    },
    {
        HA09: 'Low calorie meal',
    },
    {
        HA10: 'Low cholesterol/fat meal',
    },
    {
        HA11: 'Low protein meal',
    },
    {
        HA12: 'Low purine meal',
    },
    {
        HA13: 'Low sodium meal',
    },
    {
        HA14: 'Non-lactose meal',
    },
    {
        HA15: 'Peanut allergy meal',
    },
    {
        HA16: 'Vegetarian meal',
    },
    {
        HA17: 'Vegetarian Lacto-Ovo meal',
    },
    {
        HA18: 'Religious meal',
    },
    {
        HA19: 'Hallal',
    },
    {
        HA20: 'Carcass',
    },
    {
        HA21: 'Par-fried',
    },
    {
        HA22: 'In syrup',
    },
    {
        HA23: 'In tomato sauce',
    },
    {
        HA24: 'In natural juice',
    },
    {
        HA25: 'Unsalted',
    },
    {
        HA26: 'Salted',
    },
    {
        HA27: 'Cooked',
    },
    {
        HA28: 'Baked',
    },
    {
        HA29: 'Fried',
    },
    {
        HA30: 'Steamed',
    },
    {
        HA31: 'Wet mix',
    },
    {
        IA01: 'Design and construction',
    },
    {
        IA02: 'Design and lay',
    },
    {
        IA03: 'Extension',
    },
    {
        IA04: 'Exterior',
    },
    {
        IA05: 'Finishing work',
    },
    {
        IA06: 'Fitted',
    },
    {
        IA07: 'Fitting for',
    },
    {
        IA08: 'Floors',
    },
    {
        IA09: 'Interior',
    },
    {
        IA10: 'Modernisation',
    },
    {
        IA11: 'Modification',
    },
    {
        IA13: 'Reconstruction',
    },
    {
        IA14: 'Renovation and extension',
    },
    {
        IA15: 'Replacement',
    },
    {
        IA16: 'Multi-storey',
    },
    {
        IA17: 'Onshore',
    },
    {
        IA18: 'Resurfacing',
    },
    {
        IA19: 'Conversion',
    },
    {
        IA20: 'Offset',
    },
    {
        IA21: 'Offshore',
    },
    {
        IA22: 'Overhaul',
    },
    {
        IA23: 'Overhaul and repair',
    },
    {
        IA24: 'Reconditioning',
    },
    {
        IA25: 'Refurbishment',
    },
    {
        IA26: 'Removal',
    },
    {
        IA27: 'Renewal',
    },
    {
        IA28: 'Strengthening',
    },
    {
        IA29: 'Hollow',
    },
    {
        IA30: 'Illuminated',
    },
    {
        IA31: 'Improvements',
    },
    {
        IA32: 'Rough terrain',
    },
    {
        IA33: 'Scrap',
    },
    {
        IA34: 'Widening',
    },
    {
        IA35: 'Partition',
    },
    {
        IA36: 'For buildings',
    },
    {
        IA37: 'For insulation',
    },
    {
        IA38: 'For mechanical installations',
    },
    {
        IA39: 'For motorways',
    },
    {
        IA40: 'Renovation',
    },
    {
        IA41: 'Restoration',
    },
    {
        IA42: 'Wall',
    },
    {
        JA01: 'For computer hardware',
    },
    {
        JA02: 'For computer software',
    },
    {
        JA03: 'For network use',
    },
    {
        JA04: 'For design',
    },
    {
        JA05: 'Desktop',
    },
    {
        JA06: 'For development',
    },
    {
        JA07: 'Online',
    },
    {
        JA08: 'Upgrade',
    },
    {
        JA09: 'Multimedia',
    },
    {
        JA10: 'For Internet',
    },
    {
        JA11: 'For intranet',
    },
    {
        JA12: 'For network',
    },
    {
        JA13: 'For local area network',
    },
    {
        JA14: 'For wide area network',
    },
    {
        JA15: 'With licence',
    },
    {
        JA16: 'Free licence',
    },
    {
        JA17: 'For data',
    },
    {
        JA18: 'For auditing',
    },
    {
        JA19: 'For server',
    },
    {
        JA20: 'For back-up',
    },
    {
        JA21: 'For developing',
    },
    {
        JA22: 'For programming',
    },
    {
        JA23: 'For upgrading',
    },
    {
        JA24: 'Broadband',
    },
    {
        KA01: 'For district heating',
    },
    {
        KA02: 'For drainage',
    },
    {
        KA03: 'For electrical equipment',
    },
    {
        KA04: 'For electrical installations',
    },
    {
        KA05: 'For sewage treatment',
    },
    {
        KA06: 'For the gas industry',
    },
    {
        KA07: 'For the oil industry',
    },
    {
        KA08: 'For the storage of natural gas',
    },
    {
        KA09: 'For the treatment of water',
    },
    {
        KA10: 'For use in the water industry',
    },
    {
        KA11: 'For water utilities',
    },
    {
        KA12: 'For electricity production',
    },
    {
        KA13: 'Industrial supply',
    },
    {
        KA14: 'Foul water',
    },
    {
        KA15: 'Steam',
    },
    {
        KA16: 'For the energy industry',
    },
    {
        LA01: 'For anaesthetics',
    },
    {
        LA02: 'For artificial-kidney treatment',
    },
    {
        LA03: 'For autopsy',
    },
    {
        LA04: 'For bronchial endoscopy',
    },
    {
        LA05: 'For burns',
    },
    {
        LA06: 'For cytostatic treatment',
    },
    {
        LA07: 'For diagnostic radiology',
    },
    {
        LA08: 'For dietary use',
    },
    {
        LA09: 'For disinfection',
    },
    {
        LA10: 'For dispensary use',
    },
    {
        LA11: 'For endovascular use',
    },
    {
        LA12: 'For gynaecology',
    },
    {
        LA13: 'For haemodialysis',
    },
    {
        LA14: 'For haemodynamics',
    },
    {
        LA15: 'For heart surgery',
    },
    {
        LA16: 'For hospital use',
    },
    {
        LA17: 'For immunology',
    },
    {
        LA18: 'For infusion',
    },
    {
        LA19: 'For injection',
    },
    {
        LA20: 'For intensive-care use',
    },
    {
        LA21: 'For laboratory use',
    },
    {
        LA22: 'For medical personnel',
    },
    {
        LA23: 'For medical use',
    },
    {
        LA24: 'For neuro-angiographical use',
    },
    {
        LA25: 'For neuro-surgical use',
    },
    {
        LA26: 'For nursing staff',
    },
    {
        LA27: 'For operating theatre',
    },
    {
        LA28: 'For operating-theatre use',
    },
    {
        LA29: 'For orthopaedic use',
    },
    {
        LA30: 'For osteosynthesis',
    },
    {
        LA31: 'For paediatric medicine',
    },
    {
        LA32: 'For paramedical use',
    },
    {
        LA33: 'For pathology',
    },
    {
        LA34: 'For peritoneal dialysis',
    },
    {
        LA35: 'For psychiatric purposes',
    },
    {
        LA36: 'For radiodiagnosis',
    },
    {
        LA37: 'For radio-immunoassay',
    },
    {
        LA38: 'For radio-immunology',
    },
    {
        LA39: 'For radiology',
    },
    {
        LA40: 'For radiotherapy reagents',
    },
    {
        LA41: 'For serology',
    },
    {
        LA42: 'For surgical use',
    },
    {
        LA43: 'For the artificial-kidney unit',
    },
    {
        LA44: 'For therapeutic medicine',
    },
    {
        LA45: 'For therapeutic purposes',
    },
    {
        LA46: 'For transperitoneal dialysis',
    },
    {
        LA47: 'For treatment of hypothermia',
    },
    {
        LA48: 'For urology treatment',
    },
    {
        LA49: 'For X-ray unit',
    },
    {
        LA50: 'For cardiology',
    },
    {
        LA51: 'For cardiovascular use',
    },
    {
        LA52: 'Medical',
    },
    {
        LA53: 'For post-mortem use',
    },
    {
        LA54: 'For vascular use',
    },
    {
        LA55: 'For veterinary use',
    },
    {
        MA01: 'For aircraft',
    },
    {
        MA02: 'For aircraft crews',
    },
    {
        MA03: 'For buses',
    },
    {
        MA04: 'For cars',
    },
    {
        MA05: 'For civil aircraft',
    },
    {
        MA06: 'For industrial vehicles',
    },
    {
        MA07: 'For motor vehicles',
    },
    {
        MA08: 'For railway use',
    },
    {
        MA09: 'For transport',
    },
    {
        MA10: 'For use in airports',
    },
    {
        MA11: 'For vehicles',
    },
    {
        MA12: 'For urban transport',
    },
    {
        MA13: 'For waterways',
    },
    {
        MA14: 'For boats',
    },
    {
        MB01: 'Left-hand-drive',
    },
    {
        MB02: 'Right-hand-drive',
    },
    {
        MB03: 'Subsea',
    },
    {
        MB04: 'High speed',
    },
    {
        MD01: 'Using refrigerated vehicles',
    },
    {
        MD02: 'Using tanker vehicles',
    },
    {
        MD03: 'Using vehicles adapted for bulk goods',
    },
    {
        MD04: 'Using container vehicles',
    },
    {
        MD05: 'Using furniture-removal vehicles',
    },
    {
        MD06: 'Using loading trailers',
    },
    {
        MD07: 'Using armoured car',
    },
    {
        ME01: 'Of dry bulk goods',
    },
    {
        ME02: 'Of natural gas',
    },
    {
        ME03: 'Of corpse',
    },
    {
        ME04: 'Of artistic objects',
    },
    {
        ME05: 'Of toxic substances',
    },
    {
        MF01: 'Using aircraft',
    },
    {
        MF02: 'Using tram',
    },
    {
        MF03: 'Using specialised vehicles',
    },
    {
        MF04: 'Using car',
    },
    {
        MF05: 'Using bus',
    },
    {
        MF06: 'Using coach',
    },
    {
        MF07: 'Using balloon',
    },
    {
        MF08: 'Using zeppelin',
    },
    {
        MF09: 'Using hovercraft',
    },
    {
        MF10: 'Using zodiac',
    },
    {
        PA01: 'Hire',
    },
    {
        PA02: 'Lease',
    },
    {
        PA03: 'Hire-purchase',
    },
    {
        PB01: 'With crew',
    },
    {
        PB02: 'Without crew',
    },
    {
        PB03: 'With driver',
    },
    {
        PB04: 'Without driver',
    },
    {
        PB05: 'With operator',
    },
    {
        PB06: 'Without operator',
    },
    {
        QA01: 'For broadcast avertising',
    },
    {
        QA02: 'For advertising on radio',
    },
    {
        QA03: 'For advertising on television',
    },
    {
        QA04: 'For advertising on the Internet',
    },
    {
        QA05: 'For advertising in cinemas',
    },
    {
        QA06: 'For advertising in newspapers',
    },
    {
        QA07: 'For advertising in magazines',
    },
    {
        QA08: 'For advertising on posters',
    },
    {
        QA09: 'For advertising by means of coupons',
    },
    {
        QA10: 'For fairs',
    },
    {
        QA11: 'For events',
    },
    {
        QA12: 'For conferences',
    },
    {
        QA13: 'For exhibitions',
    },
    {
        QB01: 'For contract law',
    },
    {
        QB02: 'For trade law',
    },
    {
        QB03: 'For company law',
    },
    {
        QB04: 'For competition law',
    },
    {
        QB05: 'For administrative law',
    },
    {
        QB06: 'For private law',
    },
    {
        QB07: 'For public law',
    },
    {
        QB08: 'For international law',
    },
    {
        QB09: 'For national law',
    },
    {
        QB10: 'For regional law',
    },
    {
        QB11: 'For employment law',
    },
    {
        QB12: 'For immigration law',
    },
    {
        QB13: 'For taxation law',
    },
    {
        QB14: 'For social law',
    },
    {
        QB15: 'For criminal law',
    },
    {
        QB16: 'For discrimination or harassment law',
    },
    {
        QB17: 'For juvenile justice law',
    },
    {
        QB18: 'For appellate procedure',
    },
    {
        QB19: 'For defence law',
    },
    {
        QB20: 'For business law',
    },
    {
        QB21: 'For government antitrust law',
    },
    {
        QB23: 'For bankruptcy law',
    },
    {
        QB24: 'For partnership law',
    },
    {
        QB25: 'For patent law',
    },
    {
        QB26: 'For trademark law',
    },
    {
        QB27: 'For copyright law',
    },
    {
        QB28: 'For liquidation law',
    },
    {
        QB29: 'For real estate law',
    },
    {
        QB30: 'For mergers law',
    },
    {
        QB31: 'For acquisitions law',
    },
    {
        QB32: 'For debt collection law',
    },
    {
        QB33: 'For healthcare claim law',
    },
    {
        QB34: 'For personal injury law',
    },
    {
        QB35: 'For property law',
    },
    {
        QB36: 'For employee benefits law',
    },
    {
        QB37: 'For labour disputes law',
    },
    {
        QB38: 'For family law',
    },
    {
        QB39: 'For adoption law',
    },
    {
        QB40: 'For naturalization law',
    },
    {
        QB41: 'For guardianship law',
    },
    {
        QB42: 'For custody law',
    },
    {
        QB43: 'On land',
    },
    {
        QB44: 'On water',
    },
    {
        QB45: 'On fisheries',
    },
    {
        QB46: 'On plants',
    },
    {
        QB47: 'On animals',
    },
    {
        QB48: 'On food',
    },
    {
        QB49: 'On forestry',
    },
    {
        QB50: 'On wildlife',
    },
    {
        QB51: 'On national park',
    },
    {
        QB52: 'On environment',
    },
    {
        QB53: 'On biodiversity',
    },
    {
        QB54: 'On automotive sector',
    },
    {
        QB55: 'On aeronautics sector',
    },
    {
        QB56: 'On space industry sector',
    },
    {
        QB57: 'On political issues',
    },
    {
        RA01: 'In pharmaceutics',
    },
    {
        RA02: 'In bacteriology',
    },
    {
        RA03: 'In biomedical',
    },
    {
        RA04: 'In cardiology',
    },
    {
        RA05: 'In anatomy',
    },
    {
        RA06: 'In pathology',
    },
    {
        RA07: 'In embryology',
    },
    {
        RA08: 'In epidemiology',
    },
    {
        RA09: 'In genetics',
    },
    {
        RA10: 'In immunology',
    },
    {
        RA11: 'In ophthalmology',
    },
    {
        RA12: 'In physiology',
    },
    {
        RA13: 'In toxicology',
    },
    {
        RA14: 'In neurology',
    },
    {
        RA15: 'In urology',
    },
    {
        RA16: 'In dermatology',
    },
    {
        RB01: 'For micro-economics',
    },
    {
        RB02: 'For macro-economics',
    },
    {
        RB03: 'For industrial economics',
    },
    {
        RB04: 'For competition economics',
    },
    {
        RB05: 'For regulatory economics',
    },
    {
        RB06: 'For trade economics',
    },
    {
        RB07: 'For international economics',
    },
    {
        RB08: 'For national economics',
    },
    {
        RB09: 'For regional economics',
    },
    {
        RB10: 'For geographical economics',
    },
    {
        RB11: 'For banking economics',
    },
    {
        RB12: 'For insurance economics',
    },
    {
        RB13: 'For econometrics',
    },
    {
        RB14: 'For monetary economics',
    },
    {
        RB15: 'For foreign exchange economics',
    },
    {
        RB16: 'For innovation economics',
    },
    {
        RB17: 'For economic forecasting',
    },
    {
        RB18: 'For public economics',
    },
    {
        RB19: 'For private economics',
    },
    {
        RB20: 'For market economics',
    },
    {
        RB21: 'For planned economics',
    },
    {
        RC01: 'In aerospace technology',
    },
    {
        RC02: 'In space technology',
    },
    {
        RC03: 'In computer technology',
    },
    {
        RC04: 'In communication technology',
    },
    {
        RC05: 'In information technology',
    },
    {
        RC06: 'In automation technology',
    },
    {
        RC07: 'In fuel technology',
    },
    {
        RC08: 'In nuclear technology',
    },
    {
        RD01: 'In mathematics',
    },
    {
        RD02: 'In natural sciences',
    },
    {
        RD03: 'In biology',
    },
    {
        RD04: 'In chemistry',
    },
    {
        RD05: 'In physics',
    },
    {
        RD06: 'In earth sciences',
    },
    {
        RD07: 'In social sciences',
    },
    {
        RD08: 'In behavioural sciences',
    },
    {
        RD09: 'In applied sciences',
    },
    {
        RD10: 'In health sciences',
    },
    {
        RD11: 'For interdisciplinary fields',
    },
    {
        SA01: 'For banking',
    },
    {
        SA02: 'For foreign exchange',
    },
    {
        SA03: 'For loan',
    },
    {
        SA04: 'For credit',
    },
    {
        SB01: 'For insurance',
    },
    {
        SB02: 'For reinsurance',
    },
    {
        SC01: 'For pension',
    },
    {
        TA01: 'For books',
    },
    {
        TA02: 'For address books',
    },
    {
        TA03: 'For exercise books',
    },
    {
        TA04: 'For diaries',
    },
    {
        TA05: 'For personal organisers',
    },
    {
        TA06: 'For notebooks',
    },
    {
        TA07: 'For booklets',
    },
    {
        TA08: 'For memorandum pads',
    },
    {
        TA09: 'For letter pads',
    },
    {
        TA10: 'For blotting pads',
    },
    {
        TA11: 'For trade-advertising material',
    },
    {
        TA12: 'For commercial catalogues',
    },
    {
        TA13: 'For registers',
    },
    {
        TA14: 'For account books',
    },
    {
        TA15: 'For order books',
    },
    {
        TA16: 'For receipt books',
    },
    {
        TA17: 'For stationery',
    },
    {
        TA18: 'For envelopes',
    },
    {
        TA19: 'For notepaper',
    },
    {
        TA20: 'For interleaved carbon sets',
    },
    {
        TA21: 'For albums for samples or collections',
    },
    {
        TA22: 'For binders, folders or file covers',
    },
    {
        TA23: 'For business cards',
    },
    {
        TA24: 'For security printed matter',
    },
    {
        TA25: 'For permits',
    },
    {
        TA26: 'For stock certificates',
    },
    {
        TA27: 'For cheque forms',
    },
    {
        TA28: 'For new stamps',
    },
    {
        TA29: 'For stamp-impressed paper',
    },
    {
        TA30: 'For luncheon vouchers',
    },
    {
        TA31: 'For forms',
    },
    {
        TA32: 'For manifold business forms',
    },
    {
        TA33: 'For continuous forms',
    },
    {
        TA34: 'For tickets',
    },
    {
        TA35: 'For entrance tickets',
    },
    {
        TA36: 'For transport tickets',
    },
    {
        TA37: 'For tickets with magnetic strips',
    },
    {
        TA38: 'For calendars',
    },
    {
        TA39: 'For timetables',
    },
    {
        TA40: 'For newspaper',
    },
    {
        TA41: 'For posters',
    },
    {
        TA42: 'For industrial flexography',
    },
    {
        TA43: 'For industrial rotogravure',
    },
    {
        TA44: 'For industrial screen',
    },
    {
        TA45: 'For industrial offset',
    },
    {
        TA46: 'For industrial thermal transfer',
    },
    {
        TA47: 'Of Braille books',
    },
    {
        TA48: 'Of paperboard',
    },
    {
        UA01: 'Food products',
    },
    {
        UA02: 'Beverages',
    },
    {
        UB01: 'Furniture',
    },
    {
        UB02: 'Clothing',
    },
    {
        UB03: 'Construction materials',
    },
    {
        UB04: 'Printed matter',
    },
    {
        UB05: 'Office items',
    },
    {
        UB06: 'Horticultural products',
    },
    {
        UB07: 'Medical products',
    },
    {
        UB08: 'Surplus inventory',
    },
];
