import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
    useEffect(() => {
        /**
         * update to get data from backend
         * and generate jsx
         */
    }, []);

    return <div className='ppContentSection'>
        <div className='pp-content body'>The Association of North East Councils Limited trading as the North East Procurement Organisation (NEPO)(&quot;We&quot;) are commited to protecting and respecting your privacy.</div>
        <div className='pp-content body'>This policy (together with our terms of use and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us.  Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By visiting <a href='www.open-uk.org'> www.open-uk.org</a> you are accepting and consenting to the practices described in this policy.</div>
        <div className='pp-content body'>For the purpose of the Data Protection Act 1998 (the <span className='bold'>Act</span>), the data controller is The Association of North East Councils Limited trading as the North East Procurement Organisation (NEPO) of Northern Design Centre, Abbots Hill, Baltic Business Quarter, Gateshead, NE8 3DF.</div>
        <div className='pp-title body bold xx-large-title'>Information We May Collect From You</div>
        <div className='pp-content body'>We may collect and process the following data about you:</div>
        <div className='pp-content body'>
            <ul className='pp-list body'>
                <li className='pp-list-content body'><span className='bold'>Information you give us.</span> You may give us information about you by filling in forms on our site www.nepo.org (<span className='bold'> our site</span>) or by corresponding with us by phone, e-mail or otherwise. This includes information you provide when you register to use our site, subscribe to our service, and when you report a problem with our site. The information you give us may include your name, address, e-mail address and phone number, type of business e.g. SME and areas of interest.</li>
                <li className='pp-list-content body'><span className='bold'>Information we collect about you.</span> With regard to each of your visits to our site we may automatically collect the following information:</li>
                <li className='pp-list-content body'>technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;</li>
                <li className='pp-list-content body'>information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our site (including date and time); products or services you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number.</li>
                <li className='pp-list-content body'><span className='bold'>Information we receive from other sources.</span> We may receive information about you if you use any of the other websites we operate or the other services we provide. In this case we will have informed you when we collected that data that it may be shared internally and combined with data collected on this site. We are also working closely with third parties (including, for example, business partners, sub-contractors in technical services, advertising networks, analytics providers, search information providers,) and may receive information about you from them.</li>
            </ul>
        </div>
        <div className='pp-title body bold xx-large-title'>Cookies</div>
        <div className='pp-content body'>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. For detailed information on the cookies we use and the purposes for which we use them see our Cookie policy.</div>
        <div className='pp-title body bold xx-large-title'>Uses made of the information</div>
        <div className='pp-content body'>We use information held about you in the following ways:</div>
        <div className='pp-content body'>
            <ul className='pp-list body'>
                <li className='pp-list-content body'><span className='bold'>Information you give to us.</span> We will use this information:</li>
                <li className='pp-list-content body'>to carry out our obligations arising from any contracts entered into between you and us and to provide you with the information and services that you request from us;</li>
                <li className='pp-list-content body'>to provide you with information about other services we offer that may be of interest to you;</li>
                <li className='pp-list-content body'>to provide you, or permit selected third parties to provide you, with information about goods or services we feel may interest you. If you do not want us to use your data in this way, or to pass your details on to third parties for marketing purposes, please tick the relevant box situated on the form on which we collect your data (the registration form);</li>
                <li className='pp-list-content body'>to notify you about changes to our service;</li>
                <li className='pp-list-content body'>to ensure that content from our site is presented in the most effective manner for you and for your computer.</li>
                <li className='pp-list-content body'><span className='bold'>Information we collect about you.</span> We will use this information:</li>
                <li className='pp-list-content body'>to administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</li>
                <li className='pp-list-content body'>to improve our site to ensure that content is presented in the most effective manner for you and for your computer;</li>
                <li className='pp-list-content body'>to allow you to participate in interactive features of our service, when you choose to do so;</li>
                <li className='pp-list-content body'>as part of our efforts to keep our site safe and secure;</li>
                <li className='pp-list-content body'>to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you;</li>
                <li className='pp-list-content body'>to make suggestions and recommendations to you and other users of our site about goods or services that may interest you or them.</li>
                <li className='pp-list-content body'><span className='bold'>Information we receive from other sources.</span> We may combine this information with information you give to us and information we collect about you. We may use this information and the combined information for the purposes set out above (depending on the types of information we receive).</li>
            </ul>
        </div>
        <div className='pp-title body bold xx-large-title'>Disclosure of your information</div>
        <div className='pp-content body'>We may share your personal information with any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006.</div>
        <div className='pp-content body'>We may share your information with selected third parties including:</div>
        <div className='pp-content body'>
            <ul className='pp-list body'>
                <li className='pp-list-content-sublist body'>Business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you.</li>
                <li className='pp-list-content-sublist body'>Analytics and search engine providers that assist us in the improvement and optimisation of our site. </li>
            </ul>
        </div>
        <div className='pp-content body'>We may disclose your personal information to third parties:</div>
        <div className='pp-content body'>
            <ul className='pp-list body'>
                <li className='pp-list-content-sublist body'>If The Association of North East Councils Limited trading as the North East Procurement Organisation (NEPO) or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.</li>
                <li className='pp-list-content-sublist body'>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; or to protect the rights, property, or safety of The Association of North East Councils Limited trading as the North East Procurement Organisation (NEPO), our customers, or others.</li>
            </ul>
        </div>
        <div className='pp-title body bold xx-large-title'>Where we store your personal data</div>
        <div className='pp-content body'>The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (&quot;EEA&quot;). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.</div>
        <div className='pp-content body'>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</div>
        <div className='pp-title body bold xx-large-title'>Your rights</div>
        <div className='pp-content body'>You have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to any third party for such purposes. You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data.  You can also exercise the right at any time by contacting us.</div>
        <div className='pp-content body'>Our site may, from time to time, contain links to and from the websites of our partner networks and affiliates.  If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies.  Please check these policies before you submit any personal data to these websites.</div>
        <div className='pp-title body bold xx-large-title'>Access to information</div>
        <div className='pp-content body'>The Act gives you the right to access information held about you. Your right of access can be exercised in accordance with the Act. Any access request may be subject to a fee of £10 to meet our costs in providing you with details of the information we hold about you.</div>
        <div className='pp-title body bold xx-large-title'>Changes to our privacy policy</div>
        <div className='pp-content body'>Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy.</div>
        <div className='pp-title body bold xx-large-title'>Contact</div>
        <div className='pp-content body'>Questions, comments and requests regarding this privacy policy are welcomed.</div>

        {/* <div className='pp-warning body bold'>Remaining content from here - https://www.nepo.org/terms-and-conditions/privacy</div> */}
    </div>;
};

export default PrivacyPolicy;
