import React from 'react';
import PropTypes from 'prop-types';

import LabelledInput from '../../../../components/LabelledInput';

const VerifyCode = ({ formData, handleInput }) => <>
    <p className='body py-10'>If you have an account on Open, please enter the one-time
            verification code we have sent to your email <span className='title'>{formData.email}</span></p>
    <LabelledInput
        id='verifyCode'
        type='number'
        label=''
        placeholder='e.g. 0123456'
        value={formData.verifyCode || ''}
        onChange={(event) => handleInput(event)}
        breakColumn={false}
        required={true} />
</>;

VerifyCode.propTypes = {
    formData: PropTypes.object,
    handleInput: PropTypes.func,
};

export default VerifyCode;
