import roles from './roles';

const getSteeringGroupRole = (userId, steeringInfo) => {
    const role = [];
    steeringInfo?.map((group) => {
        if (group.userID === userId) {
            role.push(group.userRole);
        }
        return null;
    });
    return role;
};


const isEvaluatorManager = (
    authContext, steeringInfo,
) => steeringInfo?.some((steering) => steering.userID === authContext.user?.id
    && steering.userRole === roles.evaluator);

const isIndividualEvaluator = (
    authContext, steeringInfo,
) => steeringInfo?.some((steering) => steering.userID === authContext.user?.id
        && steering.userRole === roles.individual_eval);

const helperFunctions = {
    getSteeringGroupRole,
    isEvaluatorManager,
    isIndividualEvaluator,
};

export default helperFunctions;
