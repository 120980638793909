import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import logo from '../../styles/images/NewFooterLogo.png';
import Constants from './constants';
import AuthContext from '../../context/AuthContext';

const generateFooterLinks = () => {
    const { footerData } = Constants;
    const elements = [];
    footerData.forEach((data, idx) => elements.push(
        <Link key={idx} className='footerLink links' to={data.link}>
            {data.title}
        </Link>,
    ));
    return elements;
};


const currentYear = () => {
    const year = format(new Date(), 'yyyy');
    return year;
};

const Footer = () => {
    const authContext = useContext(AuthContext);
    const handleRedirect = () => {
        if (authContext.authenticated) {
            window.open('https://www.nepo.org/', '_blank');
        }
    };

    return <footer className='footer remove-on-print'>
        <div id='footerLogo' onClick={handleRedirect}>
            <img src={logo} alt='footer-logo' />
        </div>
        <div id='footerLinks'>
            {generateFooterLinks()}
        </div>
        <div id='copyrightTextContainer'>
            <p className='caption'>© {currentYear()} NEPO. All Rights Reserved.</p>
        </div>
    </footer>;
};

export default Footer;
