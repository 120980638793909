const Constants = {
    LOCAL_AUTHORITY_ROLE: 'la',
    NEPO_ROLE: 'nepo',
    SUPPLIER_ROLE: 'supplier',
    ADMIN: 'admin',
    menuData: {
        offline: [{
            title: 'Home',
            hasOptions: false,
            options: null,
            link: '/',
        }, {
            title: 'Browse Opportunities',
            hasOptions: false,
            options: null,
            link: '/opportunities',
        }, {
            title: 'Contracts Register',
            hasOptions: false,
            options: null,
            link: '/contracts-register',
        }],
        unverified: [{
            title: 'Home',
            hasOptions: false,
            options: null,
            link: '/',
        }, {
            title: 'Dashboard',
            hasOptions: false,
            options: null,
            link: '/dashboard',
        }],
        nepo: [{
            title: 'Dashboard',
            hasOptions: false,
            options: null,
            link: '/dashboard',
        },
        {
            title: 'Browse',
            hasOptions: true,
            options: [
                // { name: 'Procurement Requests', link: '/procurement-requests' },
                { name: 'Projects', link: '/projects' },
                { name: 'Contracts', link: '/contracts' },
                { name: 'Suppliers', link: '/suppliers' },
                { name: 'Solutions', link: '/solutions' },
                { name: 'Workflows', link: '/workflows/browse' },
                { name: 'Questionnaires', link: '/questionnaires' },
                { name: 'Events', link: '/events' },
                { name: 'Stages', link: '/stages' },
                { name: 'Sections', link: '/sections' },
                { name: 'Call Off', link: '/callOffs' },
            ],
        }, {
            title: 'Calendar',
            hasOptions: false,
            options: null,
            link: '/calendar',
        }, {
            title: 'Contracts Register',
            hasOptions: false,
            options: null,
            link: '/contracts-register',
        }],
        nepoUserProfile: [
            { name: 'My Profile', link: '/my-profile' },
            { name: 'Spend', link: '/spends' },
            { name: 'Rebate', link: '/rebates' },
            { name: 'Analytics', link: process.env.NEPO_KIBANA_HOME, rbacAction: 'analytics' },
            { name: 'Manage User Accounts', link: '/user-management', rbacAction: 'userAccountManagement' },
            { name: 'Logout', link: '/logout' },
        ],
        la: [{
            title: 'Dashboard',
            hasOptions: false,
            options: null,
            link: '/dashboard',
        },
        {
            title: 'Browse',
            hasOptions: true,
            options: [
                { name: 'Procurement Requests', link: '/procurement-requests' },
                { name: 'Projects', link: '/projects' },
                { name: 'Contracts', link: '/contracts' },
                { name: 'Suppliers', link: '/suppliers' },
                { name: 'Solutions', link: '/solutions' },
                { name: 'Workflows', link: '/workflows/browse' },
                { name: 'Questionnaires', link: '/questionnaires' },
                { name: 'Events', link: '/events' },
                { name: 'Stages', link: '/stages' },
                { name: 'Sections', link: '/sections' },
                { name: 'Call Off', link: '/callOffs' },
            ],
        }, {
            title: 'Calendar',
            hasOptions: false,
            options: null,
            link: '/calendar',
        }, {
            title: 'Contracts Register',
            hasOptions: false,
            options: null,
            link: '/contracts-register',
        }],
        laUserProfile: [
            { name: 'My Profile', link: '/my-profile' },
            { name: 'Spend', link: '/spends' },
            { name: 'Analytics', link: process.env.NEPO_KIBANA_HOME, rbacAction: 'analytics' },
            { name: 'Manage User Accounts', link: '/user-management', rbacAction: 'userAccountManagement' },
            { name: 'Logout', link: '/logout' },
        ],
        supplier: [{
            title: 'Dashboard',
            hasOptions: false,
            options: null,
            link: '/dashboard',
        },
        {
            title: 'Browse',
            hasOptions: true,
            options: [
                { name: 'Opportunities', link: '/opportunities' },
                { name: 'Contracts', link: '/contracts' }],
        }, {
            title: 'Calendar',
            hasOptions: false,
            options: null,
            link: '/calendar',
        }, {
            title: 'Contracts Register',
            hasOptions: false,
            options: null,
            link: '/contracts-register',
        }],
        supplierUserProfile: [
            { name: 'My Profile', link: '/my-profile' },
            { name: 'Manage User Accounts', link: '/user-management', rbacAction: 'userAccountManagement' },
            { name: 'Logout', link: '/logout' },
        ],
    },
};

export default Constants;
