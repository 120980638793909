import React, {
    useState, useContext, useEffect, useRef,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AuthContext from '../../context/AuthContext';

import Button from '../../components/Button';
import LabelledInput, {
    LabelledInputTypeEmail,
} from '../../components/LabelledInput';
import Form from '../../components/Form';

import Login from '../../features/Login';
import Constants from '../../features/Login/constants';
import SupplierRegistration from '../../features/SupplierRegistration';

import useQuery from '../../utils/useQuery';

import logo from '../../styles/images/openLogo.png';
import supplierCardImage from '../../styles/images/nepoOpenIllustration1.png';


const LoginPage = () => {
    const authContext = useContext(AuthContext);
    const query = useQuery();

    const history = useHistory();

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showRegModal, setShowRegModal] = useState(false);
    const [initiateVerification, setInitiateVerification] = useState(false);
    const [loginFormMode, setLoginFormMode] = useState('');
    const [loginFormData, setLoginFormData] = useState({
        email: '', password: '',
    });

    const {
        confirmationCode,
    } = useParams();

    const isValidEmail = (email) => email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    const inputReference = useRef(null);

    const toggleLoginModal = () => {
        setShowLoginModal(!showLoginModal);
    };

    useEffect(() => {
        inputReference?.current?.focus();
        document.title = 'Login - Open | Developed by Nepo';
        if (confirmationCode) {
            setLoginFormMode(Constants.FP_RESET_PASSWORD_FORM);
            toggleLoginModal();
        }
    }, []);

    const toggleRegModal = () => {
        setShowRegModal(!showRegModal);
    };

    const handleInput = (event) => {
        const attribute = event.target.name;
        const { value } = event.target;
        setLoginFormData({
            ...loginFormData,
            [attribute]: value,
        });
    };

    const handleLogin = async (event) => {
        event.preventDefault();
        if (loginFormData.email && loginFormData.password
            && isValidEmail(loginFormData.email)) {
            const authRes = await authContext.login(loginFormData.email, loginFormData.password);
            if (authRes.actionSuccess) {
                if (authRes.data.ChallengeName) {
                    setInitiateVerification(true);
                    setLoginFormMode(Constants.CREATE_PASSWORD_FORM);
                    toggleLoginModal();
                } else {
                    let returnToUrl = query.get(Constants.returnToUrl);
                    const queryUrl = query.get(Constants.queryUrl);
                    if (queryUrl) {
                        returnToUrl = returnToUrl.concat(Constants.questionMark, queryUrl);
                    }
                    history.push(returnToUrl || '/dashboard');
                }
            } else if (!authRes.actionSuccess
                && authRes?.data?.ErrorCode === '4000'
                && authRes?.data?.Message?.includes('User is disabled.')) {
                setLoginFormMode(Constants.USER_DISABLED);
                toggleLoginModal();
            } else {
                setLoginFormMode(Constants.INVALID_LOGIN_MESSAGE_MESSAGE);
                toggleLoginModal();
            }
        }
    };

    const toggleForgotPasswordModal = () => {
        setLoginFormMode(Constants.FP_SUBMIT_EMAIL_FORM);
        toggleLoginModal();
    };

    return <>
        <main id='lpMainContent'>
            <section id='lpLeftContainer'>
                <div>
                    <header>
                        <div id='logo'
                            onClick={() => history.push('/')}>
                            <img src={logo} alt='logo' />
                        </div>
                        <p className='title-xLarge'>The complete technology solution for <span className='bold'>your</span> e-Procurement value chain.</p>

                        <div id='supplierCardImage'>
                            <img src={supplierCardImage} alt='supplierCardImage' />
                        </div>
                    </header>
                </div>
            </section>
            <section id='lpRightContainer'>
                <div
                    id='auth-logo'
                    onClick={() => history.push('/')}>
                    <img src={logo} alt='logo' />
                </div>
                <nav id='auth-nav'>
                    <p className='title'>New to Open?</p>
                    <button
                        type='button'
                        id='create-an-account-btn'
                        className='button links'
                        onClick={toggleRegModal}> Create an Account
                    </button>
                </nav>
                <div id='login-form-contanier'>
                    <div id='auth-content'>
                        <h2>Sign in to Open</h2>
                    </div>
                    <Form id='form-login' className='form-login' onSubmit={(event) => handleLogin(event)}>
                        <div className='form-login-fields'>
                            <LabelledInput
                                id='email'
                                inputReference={inputReference}
                                type={LabelledInputTypeEmail}
                                label='Email'
                                placeholder='Enter your email'
                                value={loginFormData.email || ''}
                                onChange={(event) => handleInput(event)}
                                breakColumn={false}
                                required={true} />
                            <div className='labelled-input'>
                                <div id='passwordLabel'>
                                    <label className='form-label title' id='password-input-label' htmlFor='password-input'>Password</label>
                                    <button
                                        type='button'
                                        id='forgot-password-btn'
                                        className='button title links-small'
                                        onClick={toggleForgotPasswordModal}>Forgot password?
                                    </button>
                                </div>
                                <input
                                    type='password'
                                    className='form-input body-small'
                                    id='password-input'
                                    name='password'
                                    placeholder='Enter password'
                                    required={true}
                                    value={loginFormData.password || ''}
                                    onChange={(event) => handleInput(event)}/>
                            </div>
                        </div>
                        <Button
                            id='login-action-btn'
                            variant='primary'
                            label='Login'
                            handleClick={() => null}
                        />
                    </Form>
                </div>
                <div id='login-form-maintenence-box'>
                    <p className='caption-xSmall'>Please Note: Any scheduled maintenance to Open will take place on a Monday 17:00 – 18:30 GMT. Open may be unavailable during this time but please do check back again outside of these hours for access.</p>
                </div>
            </section>
            {showLoginModal && <Login
                showLoginModal={showLoginModal}
                closeModal={toggleLoginModal}
                toggleRegModal={toggleRegModal}
                isVerificationJourney={initiateVerification}
                loginFormMode={loginFormMode}
            />}
            {showRegModal && <SupplierRegistration
                showRegModal={showRegModal}
                closeModal={toggleRegModal}
            />}
        </main>
    </>;
};
export default LoginPage;
