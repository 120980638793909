import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../Alerts/Toast/Toast';
import LabelledCheckBox from '../LabelledCheckBox';
import ListedCheckBox from '../ListedCheckBox';
import BrowsePageHelper from '../../utils/browsePageHelper';
import Tag from '../Tag';
import constants from './constants';
import Modal from '../Modal';
import {
    intentStatuses as intentStatusText,
} from '../../config/constants';

const AnswerSummaryModal = ({
    openModal,
    closeModal,
    handleSubmit,
    selectedLotsData,
    intentStatuses = {},
    getLotStatus = () => null,
}) => {
    const [searchSortFilter, setSearchSortFilter] = useState({});
    const [startKey, setStartKey] = useState('');
    const [lotList, setLotList] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const { projectId } = useParams();
    const [successfulLotsIds, setSuccessfulLotsIds] = useState([]);


    const setSelectedLots = (lots) => lots.map((lot) => {
        if (selectedLotsData?.length > 0 && selectedLotsData.includes(lot.lotID)) {
            return {
                ...lot,
                selected: true,
            };
        }
        return {
            ...lot,
        };
    });

    const fetchData = async (filterOptions, isPaginationQuery) => {
        try {
            const response = await projectManagementAPIs.browseLots(projectId, filterOptions);
            if (response.status === 200) {
                if (isPaginationQuery) {
                    setLotList(setSelectedLots([...lotList, ...response.data.items]));
                } else {
                    setLotList(setSelectedLots(response.data.items));
                }
                setStartKey(response?.data?.startKey?.lotID || 'N/A');
            } else {
                Toast.fire({
                    icon: constants.errorIcon,
                    titleText: constants.unableToRetrieveInfo1,
                });
            }
        } catch (error) {
            Toast.fire({
                icon: constants.errorIcon,
                titleText: constants.unableToRetrieveInfo2,
            });
        }
    };

    const handleSearchSortFilter = (data) => {
        setStartKey('');
        const updatedState = {
            ...searchSortFilter,
            ...data,
            filter: {},
        };

        fetchData(BrowsePageHelper.prepareBrowsePageQueryParams(updatedState, ''), false);
        setSearchSortFilter({
            ...updatedState,
        });
    };

    const handleConfirmSelection = () => {
        const selectedLots = lotList.filter((lot) => lot.selected).map((lot) => lot.lotID);
        handleSubmit(selectedLots);
    };

    const getDepartmentName = (departmentID, departments) => departments?.filter(
        (dept) => dept.departmentID === departmentID,
    )[0]?.departmentName;

    useEffect(() => {
        setSearchSortFilter({});
        fetchData({}, false);

        setSuccessfulLotsIds(Object.entries(intentStatuses)
            ?.reduce((acc, [key, value]) => {
                if (value === intentStatusText.successful) {
                    return [
                        ...acc,
                        key,
                    ];
                }
                return acc;
            }, []));
    }, []);

    const handleSelectLot = (e, selectedLot) => {
        setLotList(lotList.map((lot) => {
            if (lot.lotID === selectedLot) {
                return {
                    ...lot,
                    selected: e.target.checked,
                };
            }
            return {
                ...lot,
            };
        }));
    };

    const handleDeselectLot = (selectedLot) => {
        setLotList(lotList.map((lot) => {
            if (lot.lotID === selectedLot) {
                return {
                    ...lot,
                    selected: false,
                };
            }
            return {
                ...lot,
            };
        }));
    };

    const generateLotTags = () => {
        const TagList = lotList.filter((lot) => lot.selected).map((lot) => <Tag key={lot.lotID}
            id={lot.lotID}
            tagTxt={`${constants.lot} ${lot.lotOrderNo} : ${lot.title}`}
            isDeletable={true}
            size='medium'
            handleDeleteBtnClick={() => handleDeselectLot(lot.lotID)}
        />);
        switch (true) {
            case TagList?.length === 0:
                return <p className='caption'>{constants.noLotsSelected}</p>;
            case TagList?.length <= 2:
                return <div className='answerSummarySectionTags'>
                    {TagList}
                </div>;
            case TagList?.length > 2:
                return <div className='answerSummarySectionTags'>
                    <div><p className='caption'>{constants.numberOfLotsSelected} {TagList.length}</p></div>
                </div>;
            default:
                return <p className='caption'>{constants.noLotsSelected}</p>;
        }
    };

    const handleCheckAll = (checked) => {
        setLotList(lotList.map((lot) => {
            if (!successfulLotsIds.includes(lot.lotID)) {
                return {
                    ...lot,
                    selected: checked,
                };
            }
            return lot;
        }));
    };

    const getAdditionalLabels = (item) => <>
        <div key='codescategories'><label className='form-label checkBox-label-primary title'>{constants.codesAndCategories}</label></div>
        <div><label className='form-label checkBox-label body-small'>{item.industryInfo?.map((industry) => `${industry.category }-${ industry.level}`).join(', ')}</label></div>
        <div key='deliveryArea'><label className='form-label checkBox-label-primary title'>{constants.deliveryArea}</label></div>
        <div><label className='form-label checkBox-label body-small'>{item.deliveryAreaInfo?.map((area) => `${area.code}-${ area.description}`).join(', ')}</label></div>
        <div key='estimatedValue'><label className='form-label checkBox-label body-small'>{constants.estimatedValue} {item.estimatedValue}</label></div>
    </>;

    const getContentBody = () => <>
        <div className='codesWrapper'>
            <div className='answerLotsHeader'>
                <label className='title' id='selectLabel'>{constants.selectedLots}</label>
                {generateLotTags()}
            </div>
            <div className='answerSummaryLotSection'>
                <div className='answerSummarySearchFilterPanel'>
                    <input type='search'
                        className='searchBar body regSearch'
                        id={'search'}
                        placeholder={constants.searchForLot}
                        onChange={(event) => {
                            handleSearchSortFilter({
                                search: event.target.value,
                            });
                        }}
                    />
                    <div id='checkAllCheckbox'>
                        <LabelledCheckBox
                            id='checkAll'
                            label=''
                            renderAsRow={false}
                            breakColumn={false}
                            onChange={(e) => {
                                setSelectAll(e.target.checked);
                                handleCheckAll(e.target.checked);
                            }}
                            options={[{
                                name: 'checkbox-checkAll',
                                id: 'checkbox-checkAll',
                                label: constants.selectAll,
                                checked: selectAll || false,
                            }]}
                            required={true}
                        />
                    </div>
                </div>
                <div>
                    <div className='answerSummaryItems'>
                        {lotList?.sort((a, b) => a.lotOrderNo - b.lotOrderNo)?.map((lot, idx) => (
                            <div key={idx} className='answerSummaryRowItems'>
                                <div>
                                    <ListedCheckBox id={`checkbox-${idx}`}
                                        label=''
                                        subLabel=''
                                        tertiaryLabel=''
                                        breakColumn={false}
                                        renderAsRow={true}
                                        required = {true}
                                        onChange={(e) => handleSelectLot(e, lot.lotID)}
                                        options={[{
                                            name: `checkbox-${lot.lotID}`,
                                            label: `${constants.lot} ${lot.lotOrderNo} : ${lot.title}`,
                                            informationPill: getLotStatus(lot.lotID),
                                            subLabel: lot.description,
                                            tertiaryLabel: `${getDepartmentName(lot?.user?.departmentID, lot?.account?.departments)},
                                                ${lot?.account?.companyName}`,
                                            additionalLabelTxt: getAdditionalLabels(lot),
                                            id: `checkbox-${lot.lotID}`,
                                            value: lot.lotID,
                                            checked: lot.selected || false,
                                            disabled: !!successfulLotsIds.includes(lot.lotID),
                                        }]}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </>;

    const getContentSection = () => <InfiniteScroll
        dataLength={lotList.length}
        next={() => fetchData(BrowsePageHelper
            .prepareBrowsePageQueryParams(searchSortFilter, startKey), true)
        }
        hasMore={startKey !== 'N/A'}
        endMessage={
            <p className='title' id='endMessage'>
                <b>{constants.allSeen}</b>
            </p>
        }
    >
        {getContentBody()}
    </InfiniteScroll>;

    return <Modal
        id={'selectLots'}
        open={openModal}
        closeModal={closeModal}
        size='x-large'
        headerTitle='Select Lot(s)'
        handleMainActionBtnClick={() => handleConfirmSelection()}
        mainActionButtonTxt={constants.confirmSelectionButton}
        closeButton={true}
        body={getContentSection()}
        helpOption={false}
        secondActionButton={true}
        secondActionButtonTxt={constants.cancelButton}
        handleSecondaryActionBtnClick={closeModal}
        mainActionButtonDisable={lotList?.filter((lot) => lot.selected)?.length === 0}
    />;
};

AnswerSummaryModal.propTypes = {
    openModal: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    selectedLotsData: PropTypes.array,
    intentStatuses: PropTypes.object,
    getLotStatus: PropTypes.func,
};

export default AnswerSummaryModal;
