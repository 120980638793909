import instance from '../config/axios.instance';
import endpoints from '../config/endpoints';

const { gatewayOrchestrator } = endpoints;

const gatewayAPIs = {
    refresh: async (token) => {
        try {
            const endpoint = gatewayOrchestrator.refresh;
            const body = { token };
            const response = await instance.post(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    upload: async (body) => {
        try {
            const endpoint = gatewayOrchestrator.upload;
            const response = await instance.post(endpoint, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    assignWorkflowToProject: async (projectId, workflowId) => {
        try {
            const endpoint = `${gatewayOrchestrator.assignWorkflowToProject}`;
            const response = await instance.post(endpoint, [{ PARAMS: [projectId, workflowId] }]);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    assignWorkflowToAccount: async (payload) => {
        try {
            const endpoint = `${gatewayOrchestrator.assign}`;
            const response = await instance.put(`${endpoint}/default/workflow`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    startSubmission: async (body) => {
        try {
            const endpoint = gatewayOrchestrator.startSubmission;
            const response = await instance.post(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    verify: async () => {
        try {
            const endpoint = gatewayOrchestrator.verify;
            const response = await instance.post(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    dashboard: async () => {
        try {
            const endpoint = gatewayOrchestrator.dashboard;
            const response = await instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getWorkflow: async (workflowId) => {
        try {
            const endpoint = gatewayOrchestrator.getWorkflow;
            const response = await instance.get(endpoint, {
                params: {
                    ids: workflowId,
                },
            });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getQuestionnaire: async (questionnaireId) => {
        try {
            const endpoint = gatewayOrchestrator.getQuestionnaire;
            const response = await instance.get(endpoint, {
                params: {
                    ids: questionnaireId,
                },
            });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    submitSelectionQuestionnaire: async (type, payload) => {
        try {
            const endpoint = gatewayOrchestrator.template;
            const response = await instance.put(`${endpoint}/${type}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateSelectionQuestionnaire: async (type, payload, templateID) => {
        try {
            const endpoint = gatewayOrchestrator.template;
            const response = await instance.put(`${endpoint}/${type}/${templateID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    reactivateSupplier: async (contractId, accountId) => {
        try {
            const endpoint = gatewayOrchestrator.contract;
            const response = await instance.put(`${endpoint}/reactivate/${contractId}/${accountId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    reactivateSupplierByLot: async (contractId, accountId, lotId) => {
        try {
            const endpoint = gatewayOrchestrator.contract;
            const response = await instance.put(`${endpoint}/reactivate/${contractId}/${accountId}/${lotId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    deactivateSupplier: async (contractId, accountId) => {
        try {
            const endpoint = gatewayOrchestrator.contract;
            const response = await instance.put(`${endpoint}/deactivate/${contractId}/${accountId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    deactivateSupplierByLot: async (contractId, accountId, lotId) => {
        try {
            const endpoint = gatewayOrchestrator.contract;
            const response = await instance.put(`${endpoint}/deactivate/${contractId}/${accountId}/${lotId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    exportPublicContracts: async () => {
        try {
            const endpoint = gatewayOrchestrator.exportPublicContracts;
            const response = await instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    exportEvaluationSummary: async (projectId) => {
        try {
            const endpoint = gatewayOrchestrator.exportEvaluationSummary;
            const response = await instance.get(`${endpoint}/${projectId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    exportAnswerSummary: async (projectId) => {
        try {
            const endpoint = gatewayOrchestrator.exportAnswersSummary;
            const response = await instance.get(`${endpoint}/${projectId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
};
export default gatewayAPIs;
