/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
    useHistory, useParams, generatePath,
} from 'react-router-dom';
import BrowseData from '../../features/BrowseData';
import Constants from './constants';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import AuthContext from '../../context/AuthContext';
import gatewayAPIs from '../../services/gateway.service';
import rbac from '../../rbac';

const BrowseWorkflows = () => {
    const [searchSortFilter, setSearchSortFilter] = useState({});
    const [workflows, setWorkflows] = useState([]);
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const {
        resourceName,
        projectID,
        accountID,
        callOffMechanism,
    } = useParams();

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const workflowTypes = params.get('type');

    const fetchData = async (tabSelected) => {
        const responseData = await projectManagementAPIs.getAllWorkflowsByAccount(tabSelected);

        if (responseData.status === 200) {
            setWorkflows(responseData.data.items);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchData('');
        setSearchSortFilter({
            ...searchSortFilter,
            filter: {
                route: workflowTypes || undefined,
            },
        });
    }, []);

    const assignWorkflowToAccount = async (workflowId) => {
        const payload = {
            workflowID: workflowId,
            callOffMechanism,
        };

        const response = await gatewayAPIs.assignWorkflowToAccount(payload);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                title: 'Workflow selected successfully.',
            });
            const trySilentAuth = await gatewayAPIs.verify();
            if (trySilentAuth.status === 200) {
                authContext.silentLogin(trySilentAuth.data);
            }
            history.goBack();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to select workflow.',
            });
        }
    };

    const assignWorkflowToProject = async (workflowId) => {
        const responseData = await projectManagementAPIs.assignWorkflowToProject(
            projectID, workflowId,
        );
        if (responseData.status === 200) {
            Toast.fire({
                icon: 'success',
                title: 'Workflow selected successfully.',
            });
            history.push(`/projects/overview/${projectID}`);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to select workflow.',
            });
        }
    };

    const getWorkflowsByTab = (selectedTab) => {
        setWorkflows([]);
        switch (selectedTab) {
            case 'My Workflows':
                fetchData('createdBy');
                break;
            case 'My Organisation':
                fetchData('');
                break;
            case 'My Department':
                fetchData('department');
                break;
            case 'All Organisations':
                fetchData('nepo');
                break;
            default:
                break;
        }
    };

    return <>
        <BrowseData
            id='browseWorkflows'
            context='Workflows'
            contextSingular='Workflow'
            action={{
                type: (resourceName && projectID) ? 'select' : 'view',
                cb: accountID ? assignWorkflowToAccount : assignWorkflowToProject,
            }}
            dataSet={workflows}
            tabOptions={Constants.tabOptions}
            handleTabSelected={getWorkflowsByTab}
            searchPlaceholder='Search for a workflow'
            searchFilterAttribute='workflowName'
            sortOptions={Constants.sortOptions}
            allowAddNew={rbac.can(rbac.action.addWorkflow,
                [authContext?.user?.accountType?.toLowerCase() + authContext?.user?.role])}
            labelAddNew='Add'
            handleAddNew={() => history.push(generatePath('/workflows/:resourceName?/:projectId?/:routeToMarket?/create/:isImport?', {
                resourceName: resourceName || '_',
                projectId: projectID || '_',
                routeToMarket: '_',
            }))}
            defaultTab={2}
            searchSortFilter={searchSortFilter}
        />
    </>;
};

export default BrowseWorkflows;
