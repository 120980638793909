const Constants = {
    title: 'Get in touch',
    completeForm: 'Complete the form and the team will get back to you. Alternatively you can contact us using the details below:',
    supportEmail: 'open.support@nepo.org',
    supportPhone: '01916380040',
    supportAddress: 'Northern Design Centre, Abbots Hill, Baltic Business Quarter, Gateshead, NE8 3DF',
    businessHours: 'Our Business Hours are:',
    businessHoursFirstLine: ' 08.30 to 17.00 Monday to Thursday',
    businessHoursSecondLine: '08.30 to 16.30 Friday',
    checkWebTraining: 'Check out our Supplier Hub for Training Guides, FAQ’s, Events and more:',
    webTraining: 'https://www.open-hub.org',
    webTrainingLabel: 'www.open-hub.org',
    webTrainingTitle: 'Open Hub',

    christmasClosure: 'Christmas Closure:',
    christmasClosureFirstLine: '16:30 Friday 22nd December 2023',
    christmasClosureSecondLine: 'until 08:30 Tuesday 2nd January 2024',
};
export default Constants;
