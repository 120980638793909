import Action from './constants';
import roles from './roles';

const rules = {
    [Action.registerInterestRequests]: [roles.po, roles.cs, roles.pm, roles.cm],
    [Action.registerInterestInvites]: [roles.po, roles.cs],
    [Action.editWorkflow]: [roles.po, roles.cs,
        roles.NEPOAdmin],
    [Action.selectWorkflow]: [roles.po, roles.cs],
    [Action.setDefaultWorkflow]: [roles.NEPOAdmin, roles.NEPOAdvanced,
        roles.LAAdmin, roles.LAAdvanced],
    [Action.selectQuestionnaire]: [roles.po, roles.cs],
    [Action.editAssignedQuestionnaire]: [roles.po, roles.cs],
    [Action.editSteeringGroup]: [roles.po, roles.cs],
    [Action.advertProject]: [roles.po, roles.cs],
    [Action.cancelProject]: [roles.po, roles.cs],
    [Action.editProject]: [roles.po, roles.cs, roles.pm, roles.cm],
    [Action.createProject]: [roles.NEPOIntermediate, roles.NEPOAdvanced,
        roles.NEPOAdmin, roles.LAAdmin, roles.LAAdvanced, roles.LAIntermediate],
    [Action.userAccountManagement]: [roles.NEPOAdmin, roles.LAAdmin, roles.supplierAdmin],
    [Action.userProfileManagement]: [roles.NEPOAdmin, roles.supplierAdmin, roles.LAAdmin],
    [Action.openAccountRequests]: [roles.supplierAdmin],
    [Action.openAllSeal]: [roles.legal],
    [Action.releaseAll]: [roles.legal],
    [Action.viewOpenAnswerQuestionnaire]: [roles.legal],
    [Action.evaluateQuestionnaire]: [roles.evaluator, roles.individual_eval],
    [Action.viewAnswerQuestionnaire]: [roles.po, roles.cs, roles.legal],
    [Action.viewEvaluateQuestionnaire]: [
        roles.po, roles.cs, roles.evaluator, roles.individual_eval,
        roles.stage1approver, roles.stage2approver,
    ],
    [Action.evaluateApproveRejectAll]: [roles.cs],
    [Action.submitEvaluationsForApproval]: [roles.po],
    [Action.submitIntentLetterForApproval]: [roles.po],
    [Action.submitAwardForApproval]: [roles.po],
    [Action.submitAwardLetterForApproval]: [roles.po],
    [Action.updateStandstillTimer]: [roles.po],
    [Action.cancelStandstillTimer]: [roles.po],
    [Action.disqualifySuppliers]: [roles.po],
    [Action.exportData]: [
        roles.po, roles.cs, roles.stage1approver, roles.stage2approver,
        roles.evaluator, roles.individual_eval,
    ],
    [Action.downloadAll]: [
        roles.po, roles.cs, roles.stage1approver, roles.stage2approver,
        roles.evaluator, roles.individual_eval,
    ],
    [Action.sendIntentLetters]: [roles.po],
    [Action.completeWorkflowEvent]: [roles.po],
    [Action.approveRejectWorkflowEvent]: [roles.cs, roles.approver],
    [Action.setProjectRisk]: [roles.po, roles.cs],
    [Action.addEditSelectionQuestionnaire]: [roles.supplierAdmin, roles.supplierAdvanced],
    [Action.addLots]: [roles.po, roles.pm],
    [Action.addFAQ]: [roles.po, roles.pm],
    [Action.addNotes]: [roles.po, roles.pm],
    [Action.uploadDocuments]: [roles.po, roles.pm],
    [Action.addContract]: [roles.po, roles.pm],
    [Action.manageContractSuppliers]: [roles.po, roles.cs, roles.pm, roles.cm],
    [Action.viewMessenger]: [roles.po, roles.pm, roles.cm, roles.cs],
    [Action.escalateEvent]: [roles.po, roles.pm, roles.cm, roles.cs, roles.legal, roles.evaluator,
        roles.approver],
    [Action.editFAQ]: [roles.po, roles.pm],
    [Action.openUserRequests]: [roles.supplierAdmin],
    [Action.editProcurement]: [roles.LABasic],
    [Action.approveProcurement]: [roles.LAAdmin],
    [Action.resolveEscalation]: [roles.stage1approver, roles.stage2approver],
    [Action.addWorkflow]: [roles.NEPOAdmin, roles.NEPOAdvanced, roles.LAAdmin, roles.LAAdvanced],
    [Action.addQuestionnaire]: [roles.NEPOAdmin, roles.NEPOAdvanced,
        roles.LAAdmin, roles.LAAdvanced],
    [Action.addEvent]: [roles.NEPOAdmin, roles.NEPOAdvanced, roles.LAAdmin, roles.LAAdvanced],
    [Action.addSection]: [roles.NEPOAdmin, roles.NEPOAdvanced, roles.LAAdmin, roles.LAAdvanced],
    [Action.addStage]: [roles.NEPOAdmin, roles.NEPOAdvanced, roles.LAAdmin, roles.LAAdvanced],
    [Action.editQuestionnaire]: [roles.NEPOAdmin, roles.NEPOAdvanced,
        roles.LAAdmin, roles.LAAdvanced],
    [Action.editEvent]: [roles.NEPOAdmin, roles.NEPOAdvanced, roles.LAAdmin, roles.LAAdvanced],
    [Action.editSection]: [roles.NEPOAdmin, roles.NEPOAdvanced, roles.LAAdmin, roles.LAAdvanced],
    [Action.editStage]: [roles.NEPOAdmin, roles.NEPOAdvanced, roles.LAAdmin, roles.LAAdvanced],
    [Action.editContract]: [roles.po, roles.pm, roles.cs, roles.cm],
    [Action.approveStage1]: [roles.stage1approver],
    [Action.approveStage2]: [roles.stage2approver],
    [Action.rejectStage1]: [roles.stage1approver],
    [Action.rejectStage2]: [roles.stage2approver],
    [Action.answerSQ]: [roles.supplierAdmin, roles.supplierAdvanced],
    [Action.manageDepartments]: [roles.LAAdmin, roles.LAAdvanced, roles.LAIntermediate,
        roles.NEPOAdmin, roles.NEPOAdvanced, roles.NEPOIntermediate,
        roles.supplierAdmin, roles.supplierAdvanced, roles.supplierAdvanced],
    [Action.addContractRegister]: [roles.NEPOAdmin, roles.NEPOAdvanced,
        roles.LAAdmin, roles.LAAdvanced],
    [Action.analytics]: [roles.LAAdmin, roles.LAAdvanced, roles.LAIntermediate,
        roles.NEPOAdmin, roles.NEPOAdvanced, roles.NEPOIntermediate],
};

export default rules;
