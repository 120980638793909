/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import ScreenOverlay from '../../components/ScreenOverlay';
import NavBar from '../NavBar';
import BurgerMenu from '../BurgerMenu';
import Constants from './constants';
import Login from '../Login';
import AuthContext from '../../context/AuthContext';
import NotificationsChecker from '../NotificationsChecker';
import SupplierRegistration from '../SupplierRegistration';
import actions from '../SupplierRegistration/slice/supplier-reg.action';
import Timeout from '../Timeout';
import UserAccessRefresh from '../UserAccessRefresh';
// import NotificationToggleButton from '../Notifications/components/NotificationToggleButton';

const Header = () => {
    const authContext = useContext(AuthContext);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showRegModal, setShowRegModal] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { initiateVerification } = useSelector((state) => ({
        initiateVerification: state.SupplierRegReducer.initiateLogin,
    }));

    useEffect(() => {
        if (showLoginModal) document.documentElement.style.overflow = 'hidden';
        else document.documentElement.style.overflow = 'unset';
    }, [showLoginModal]);

    useEffect(() => {
        if (initiateVerification) {
            setShowLoginModal(true);
        }
    }, [initiateVerification]);

    useEffect(() => {
        if (location && location.state) {
            if (location.state.loginAction) {
                setShowLoginModal(!showLoginModal);
                history.replace('/');
            }
        }
    }, [location.state]);

    const handleDrawerToggleClick = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const handleScreenOverlayClick = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const toggleLoginModal = () => {
        setShowLoginModal(!showLoginModal);
        if (showLoginModal) {
            dispatch(actions.initiateLogin(false));
            dispatch(actions.setCurrentStep(2));
        }
    };

    const toggleRegModal = () => {
        setShowRegModal(!showRegModal);
    };

    const determineMenuData = () => {
        if (authContext.authenticated
            && authContext.user.accountType
            && authContext.user.details?.verified === 'approved'
            && authContext.user.accountVerified === 'approved') {
            return Constants.menuData[authContext.user.accountType.toLowerCase()];
        }

        if (authContext.authenticated
            && (authContext.user?.details?.verified !== 'approved'
            || authContext.user.accountVerified !== 'approved')) {
            return Constants.menuData.unverified;
        }

        return Constants.menuData.offline;
    };

    const determineMobileUserMenuData = () => {
        if (authContext.authenticated && authContext.user.accountType) {
            return Constants.menuData[`${authContext.user.accountType.toLowerCase()}UserProfile`];
        }
        return [];
    };

    const determineUserMenuData = () => {
        if (authContext.authenticated && authContext.user.accountType) {
            return Constants.menuData[`${authContext.user.accountType.toLowerCase()}UserProfile`];
        }

        return Constants.menuData.offline;
    };

    const screenOverlay = (mobileMenuOpen)
        ? <ScreenOverlay handleClick={handleScreenOverlayClick} />
        : undefined;

    return (<>
        <NavBar isLoggedIn={authContext.authenticated}
            firstName={(authContext.authenticated && authContext.user.firstName)
                ? authContext.user.firstName : ''}
            menuData={determineMenuData()}
            userMenuData={determineUserMenuData()}
            handleBurgerMenuBtnClick={handleDrawerToggleClick}
            showLoginModal={toggleLoginModal}
            toggleRegModal={toggleRegModal}/>

        <BurgerMenu open={mobileMenuOpen}
            handleCloseBtnClick={handleDrawerToggleClick}
            menuData={determineMenuData()}
            userData={determineMobileUserMenuData()}
            showLoginModal={toggleLoginModal}
            toggleRegModal={toggleRegModal}/>
        {showLoginModal && <Login showLoginModal={showLoginModal}
            closeModal={toggleLoginModal} toggleRegModal={toggleRegModal}
            isVerificationJourney={initiateVerification} />}
        {showRegModal && <SupplierRegistration showRegModal={showRegModal}
            closeModal={toggleRegModal} />}
        {screenOverlay}
        {authContext.authenticated ? <>
            <NotificationsChecker/><Timeout /><UserAccessRefresh /></> : null}
    </>);
};

export default Header;
