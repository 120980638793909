import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Constants from './constants';
import Form from '../../components/Form';
import Modal from '../../components/Modal';
import LabelledRadioButton from '../../components/LabelledRadioButton';
import BrowseData from '../../features/BrowseData';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import EventModal from '../../features/DocumentBuilder/components/EventModal';
import AuthContext from '../../context/AuthContext';
import rbac from '../../rbac';

const BrowseEvents = () => {
    const [events, setEvents] = useState([]);
    const [showStageCategoryModal, setShowStageCategoryModal] = useState(false);
    const [stageCategory, setStageCategory] = useState('');
    const [showEventModalDetails, setShowEventModalDetails] = useState({
        show: false,
        mode: 'View',
    });
    const [eventDetails, setEventDetails] = useState({});
    const history = useHistory();
    const { stage, stageName } = useParams();
    const authContext = useContext(AuthContext);

    const fetchData = async () => {
        try {
            const responseData = await projectManagementAPIs.listEvents();
            if (responseData.status === 200) {
                setEvents(responseData.data.items);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const createEvent = (event) => {
        event.preventDefault();
        history.push(`/events/library/${stageCategory}/create`);
    };

    const handleCancelEvent = () => {
        setShowStageCategoryModal(false);
        setStageCategory('');
    };

    const handleViewEvent = async (eventId) => {
        try {
            const response = await projectManagementAPIs.getEvent(eventId);
            if (response.status === 200) {
                setEventDetails(response.data);
                setShowEventModalDetails({
                    show: true,
                    mode: 'View',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const handleUpdateEvent = async (e, eventData) => {
        try {
            const payLoad = {
                ...eventData,
                eventDuration: parseInt(eventData.eventDuration, 10),
                startDateReminder: eventData.startDateReminderFlag
                    ? parseInt(eventData.startDateReminder, 10)
                    : undefined,
                endDateReminder: eventData.endDateReminderFlag
                    ? parseInt(eventData.endDateReminder, 10)
                    : undefined,
            };

            delete payLoad.eventID;

            const response = await projectManagementAPIs.updateLibraryEvent(
                eventData.eventID, payLoad,
            );
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Event updated successfully.',
                });
                setShowEventModalDetails({
                    show: false,
                    mode: 'View',
                });
                fetchData();
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const handleEditEvent = async (eventId) => {
        try {
            const response = await projectManagementAPIs.getEvent(eventId);
            if (response.status === 200) {
                setEventDetails(response.data);
                setShowEventModalDetails({
                    show: true,
                    mode: 'Edit',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    return <>
        <BrowseData
            id='browseEvents'
            context='Events'
            contextSingular='Event'
            dataSet={events}
            action={{
                type: (stage) ? 'select' : 'view',
                stage,
                stageName,
            }}
            searchPlaceholder='Search for an event'
            numOfRowsPerPage={15}
            searchFilterAttribute='title'
            sortOptions={Constants.sortOptions}
            allowAddNew={!stage && rbac.can(rbac.action.addEvent,
                [authContext.user.accountType.toLowerCase() + authContext.user.role])}
            labelAddNew='Add'
            handleAddNew={() => setShowStageCategoryModal(true)}
            handleView={(eventId) => handleViewEvent(eventId)}
            handleEdit={(eventId) => handleEditEvent(eventId)}
            allowEdit={rbac.can(rbac.action.editEvent,
                [authContext.user.accountType.toLowerCase() + authContext.user.role])}
        />
        {showStageCategoryModal
        && <Form id='createWorkflowTypeForm' onSubmit={(event) => createEvent(event)}>
            <Modal
                open={true}
                closeModal={handleCancelEvent}
                size={'medium'}
                headerTitle={'Select Stage Category'}
                secondActionButton={true}
                secondActionButtonTxt='Cancel'
                secondActionButtonType='button'
                handleSecondaryActionBtnClick={handleCancelEvent}
                handleMainActionBtnClick={() => null}
                mainActionButtonTxt='Continue'
                closeButton={true}
                body={
                    <LabelledRadioButton
                        id='stageCategorySelection'
                        label='Please select one of the following options'
                        breakColumn={false}
                        onChange={(event) => setStageCategory(event.target.value)}
                        options={[{
                            label: 'Pre-Procurement',
                            value: 'pre-procurement',
                            id: 'stageCategoryPreProcurement',
                            name: 'stageCategory',
                            checked: stageCategory
                                ? stageCategory === 'pre-procurement'
                                : false,
                            required: true,
                        }, {
                            label: 'e-Tendering',
                            value: 'e-tendering',
                            id: 'stageCategoryETendering',
                            name: 'stageCategory',
                            checked: stageCategory
                                ? stageCategory === 'e-tendering'
                                : false,
                            required: true,
                        }, {
                            label: 'Contract Management',
                            value: 'contract-management',
                            id: 'stageCategoryContractManagement',
                            name: 'stageCategory',
                            checked: stageCategory
                                ? stageCategory === 'contract-management'
                                : false,
                            required: true,
                        }]}
                        renderAsRow={false} />
                }
                helpOption={false}
            />
        </Form>
        }
        {showEventModalDetails.show
        && <EventModal
            mode={showEventModalDetails.mode}
            existingData={[]}
            initialData={eventDetails}
            closeModal={() => {
                setEventDetails({});
                setShowEventModalDetails({
                    show: false,
                    mode: 'View',
                });
            }}
            handleResult={(e, eventData) => handleUpdateEvent(e, eventData)}
        />}
    </>;
};

export default BrowseEvents;
