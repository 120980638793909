import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Button from '../Button';
import StatusLabel from '../StatusLabel';
import Constants from './constants';

const Menu = ({ options, handleActionClick, handleLinkClick }) => {
    const history = useHistory();

    const handleClickButton = ({ clickType, onClick, onClickOptions }) => {
        if (clickType === Constants.CLICK_TYPE.EVENT) {
            handleActionClick(...onClickOptions);
        }
        history.push(onClick, onClickOptions);
    };

    const handleClickLink = (e, href) => {
        handleLinkClick(e, href);
    };

    const getButton = (props) => <Button {...props}
        handleClick={() => handleClickButton(props)}
    />;

    const getLink = ({
        href, labelTxt, className,
    }) => <a href={href}
        className={className} onClick={(e) => handleClickLink(e, href)}
        title={labelTxt}>{labelTxt}</a>;

    const getStatusLabel = (props) => <StatusLabel {...props} />;

    const getTextLabel = ({ labelTxt, id }) => <span id={id} >{labelTxt}</span>;

    const getMenuOption = (option) => {
        const optionsMenu = {
            [Constants.MENU_OPTIONS.STATUS_LABEL]: getStatusLabel,
            [Constants.MENU_OPTIONS.BUTTON]: getButton,
            [Constants.MENU_OPTIONS.TEXT_LABEL]: getTextLabel,
            [Constants.MENU_OPTIONS.LINK]: getLink,
        };
        return option?.type ? optionsMenu[option.type](option) : '';
    };

    const getMenuOptions = () => options?.map((option) => (
        <li key={uuidv4()}>{getMenuOption(option)}</li>
    ));

    return (
        <ul className='tree-view-menu'>{getMenuOptions()}</ul>
    );
};

Menu.propTypes = {
    index: PropTypes.string,
    options: PropTypes.array,
    handleActionClick: PropTypes.func,
    handleLinkClick: PropTypes.func,
    uniqueKey: PropTypes.string,
};


export default Menu;
