import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

const Toast = Swal.mixin({
    icon: undefined,
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    // backdrop: false,
    timerProgressBar: true,
    showClass: {
        backdrop: 'swal2-noanimation', // disable backdrop animation
        popup: '', // disable popup animation
        icon: '', // disable icon animation
    },
    hideClass: {
        popup: '', // disable popup fade-out animation
    },
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
});

export default Toast;
