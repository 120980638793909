import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    differenceInMonths,
    addMonths,
    differenceInDays,
    addDays,
    differenceInHours,
    addHours,
    differenceInMinutes,
    addMinutes,
    differenceInSeconds,
} from 'date-fns';

const CountdownTimer = ({ type, timerEndDate, enablerag = false }) => {
    const [months, setMonths] = useState();
    const [days, setDays] = useState();
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();
    const [fontColor, setFontColor] = useState();

    const handleClick = () => {
        let x = new Date();
        const y = new Date(timerEndDate);// yyyy-mm-dd hh:mm:ss
        let temp = differenceInMonths(y, x);
        setMonths(temp);
        x = addMonths(x, temp);
        temp = differenceInDays(y, x);
        setDays(temp);
        x = addDays(x, temp);
        temp = differenceInHours(y, x);
        setHours(temp);
        x = addHours(x, temp);
        temp = differenceInMinutes(y, x);
        setMinutes(temp);
        x = addMinutes(x, temp);
        temp = differenceInSeconds(y, x);
        setSeconds(temp);
    };


    useEffect(() => {
        setInterval(handleClick, 1000);
        if (enablerag) {
            if (days < 1) {
                setFontColor('red');
            } else if (days <= 2) {
                setFontColor('amber');
            } else {
                setFontColor('');
            }
        }
    });

    return (<>
        {(months + days + hours + minutes + seconds) > 0
            ? <>{type === 'flat' && (<> <p className='body'>The deadline for submission is in <span id={fontColor}><span className='bold'>{months}</span> month(s),<span className='bold'> {days} </span> days,
                <span className='bold'> {hours} </span> hours,<span className='bold'> {minutes} </span>minutes,
                <span className='bold'> {seconds} </span>seconds.</span>
            </p></>)}
            {type === 'tiled' && (
                <div className='timerContainer'>
                    <div className='timerTile content'><p className='body bold'>Submission Deadline</p></div>
                    <div className={`timerTile ${fontColor}Tile`}><p className='body bold'>{months} month(s)</p></div>
                    <div className={`timerTile ${fontColor}Tile`}><p className='body bold'>{days} days</p></div>
                    <div className={`timerTile ${fontColor}Tile`}><p className='body bold'>{hours} hours</p></div>
                    <div className={`timerTile ${fontColor}Tile`}><p className='body bold'>{minutes} minutes</p></div>
                    <div className={`timerTile ${fontColor}Tile`}><p className='body bold'>{seconds} seconds</p></div>
                </div>
            )}
            </>
            : <></>}
    </>);
};

CountdownTimer.propTypes = {
    type: PropTypes.string,
    timerEndDate: PropTypes.string.isRequired,
    enablerag: PropTypes.bool,
};

export default CountdownTimer;
