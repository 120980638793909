/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import Modal from '../../../components/Modal';
import userManagementAPIs from '../../../services/user-management.service';
import actions from '../slice/supplier-reg.action';
import constants from '../constants';
import Toast from '../../../components/Alerts/Toast/Toast';

const ConfirmJoinRequest = ({
    closeModal,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        accountName, initFormData, companyDetailForm, companyType, consentStatus,
    } = useSelector((state) => ({
        accountName: state.SupplierRegReducer.companyDetails.accountName,
        initFormData: state.SupplierRegReducer.accountDetails,
        companyType: state.SupplierRegReducer.companyType,
        consentStatus: state.SupplierRegReducer.consentStatus,
        companyDetailForm: state.SupplierRegReducer.companyDetails,
    }));

    const modalBody = <>
        <p className='title'>An account for company <span className='title'>{accountName}</span> exists in Open. Would you like to join the account?</p>
        <br/>
        <br/>
        <p className='title'>If you are the administrator of the account, please log-in instead.</p>
    </>;

    const consentStatusSet = (consentStatusData) => ({
        hasAgreedToTnC: consentStatusData?.tcpp || false,
        hasAgreedToRecieveComms: consentStatusData?.consentEmail || false,
        agreedOn: `${format(new Date(), 'dd-MM-yyyy')}T${new Date().toLocaleTimeString('en-GB')}`,

    });

    const prepareRequest = (obj) => {
        const finalConsentStatus = consentStatusSet(consentStatus);
        const submittedForm = {
            ...initFormData, ...companyDetailForm, companyType, ...finalConsentStatus,
        };
        Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'object') {
                prepareRequest(obj[key]);
            } else {
                obj[key] = submittedForm[key];
            }
        });
        delete obj.companyType;
        return { ...obj, accountType: 'supplier' };
    };


    const createAccount = async () => {
        const createAccountRes = await userManagementAPIs.createAccount(
            prepareRequest(constants.request),
        );
        if (createAccountRes.status === 201) {
            closeModal();
            dispatch(actions.initiateLogin(true));
            dispatch(actions.setCurrentStep(3));
        } else if (createAccountRes.data && createAccountRes.data.ErrorCode === '2000') {
            Toast.fire({
                icon: 'error',
                titleText: createAccountRes?.data?.Message?.split('- ')[1] || 'Error while processing your request. Please try again later.',
                // 'An account with the given email already exists,
                // please login or register with a different email',
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to create an account.',
            });
        }
    };

    return <>
        <Modal
            open={true}
            closeModal={closeModal}
            size='small'
            headerTitle='Join Company Account'
            handleMainActionBtnClick={createAccount}
            mainActionButtonTxt='Yes'
            closeButton={false}
            body={modalBody}
            helpOption={false}
            secondActionButton={true}
            secondActionButtonTxt='Log-in'
            handleSecondaryActionBtnClick={(e) => { e.preventDefault(); history.push('/', { loginAction: true }); }}
        />
    </>;
};

ConfirmJoinRequest.propTypes = {
    closeModal: PropTypes.func,
    next: PropTypes.func,
};

export default ConfirmJoinRequest;
