import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import helperFunctions from '../../../../utils/helperFunctions';
import notificationsManagementAPIs from '../../../../services/notifications.service';
import AuthContext from '../../../../context/AuthContext';
import { fetchData } from '../../../NotificationsChecker/actions';
import Toast from '../../../../components/Alerts/Toast/Toast';
import shareIcon from '../../../../styles/images/share-icon.png';
import constants from '../../constants';

const NotificationEventRow = ({
    id, notificationID, message, timeSent, notificationType, readStatus, email, actionType = '', context = [],
}) => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const dispatch = useDispatch();
    const handleRead = async (idx) => {
        if (readStatus === false) {
            try {
                let markAsRead = true;
                if (notificationType.toLowerCase() === constants.action_notification) {
                    if (context.length > 0) {
                        switch (actionType) {
                            case constants.actionType.OPEN_ANSWER_SUMMARY:
                                history.push(`/answers/summary/${context.filter((projectID) => projectID.contextType === constants.contextTypes.projectID)[0].contextID}/${context.filter((eventID) => eventID.contextType === constants.contextTypes.eventID)[0].contextID}`);
                                break;
                            case constants.actionType.OPEN_SUPPLIER_AWARD_SUMMARY:
                                history.push(`/opportunity/award/summary/${context.filter((projectID) => projectID.contextType === constants.contextTypes.projectID)[0].contextID}/${context.filter((eventID) => eventID.contextType === constants.contextTypes.eventID)[0].contextID}`);
                                break;
                            case constants.actionType.OPEN_CONTRACT_OVERVIEW:
                                history.push(`/contracts/overview/${context.filter((contractID) => contractID.contextType === constants.contextTypes.contractID)[0].contextID}`);
                                break;
                            case constants.actionType.OPEN_EVALUATION_SUMMARY:
                                history.push(`/evaluation/summary/${context.filter((projectID) => projectID.contextType === constants.contextTypes.projectID)[0].contextID}/${context.filter((eventID) => eventID.contextType === constants.contextTypes.eventID)[0].contextID}`);
                                break;
                            case constants.actionType.OPEN_PROJECT_OVERVIEW:
                                history.push(`/projects/overview/${context.filter((projectID) => projectID.contextType === constants.contextTypes.projectID)[0].contextID}`);
                                break;
                            case constants.actionType.OPEN_OPPORTUNITIES_OVERVIEW:
                                history.push(`/opportunities/overview/${context.filter((opportunityID) => opportunityID.contextType === constants.contextTypes.opportunityID)[0].contextID}`);
                                break;
                            case constants.actionType.OPEN_SOLUTION_OVERVIEW:
                                history.push(`/solutions/overview/${context.filter((solutionID) => solutionID.contextType === constants.contextTypes.solutionID)[0].contextID}`);
                                break;
                            case constants.actionType.OPEN_CORRESPONDENCE_OVERVIEW:
                                history.push(`/${context.filter((resourceType) => resourceType.contextType === constants.contextTypes.resourceType)[0].contextID}/correspondence/overview/${context.filter((projectID) => projectID.contextType === constants.contextTypes.projectID || projectID.contextType === constants.contextTypes.contractID)[0]?.contextID}/${context.filter((topicID) => topicID.contextType === 'topicID')[0]?.contextID}${authContext.user.accountType !== 'supplier' && context.filter((accountID) => accountID.contextType === 'senderAccountID') === authContext.user.accountID ? '' : '/single'}`);
                                break;
                            case constants.actionType.OPEN_CORRESPONDENCE_VIEWMESSAGE:
                                history.push(`/${context.filter((resourceType) => resourceType.contextType === constants.contextTypes.resourceType)[0].contextID}/correspondence/overview/${context.filter((projectID) => projectID.contextType === constants.contextTypes.projectID || projectID.contextType === constants.contextTypes.contractID)[0]?.contextID}/${context.filter((topicID) => topicID.contextType === 'topicID')[0]?.contextID}${authContext.user.accountType !== 'supplier' ? '' : '/single'}`);
                                break;
                            default:
                                break;
                        }
                    } else {
                        markAsRead = false;
                        Toast.fire({
                            icon: 'error',
                            titleText: 'Unable to take you to required action',
                        });
                    }
                }
                if (markAsRead) {
                    const responsedata = await notificationsManagementAPIs
                        .readAllByNotificationID(idx);
                    if (responsedata) {
                        dispatch(fetchData(email));
                    }
                }
            } catch {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to mark the notification as read.',
                });
            }
        }
        return null;
    };

    return (
        <div key={id} className='notificationEventRow' id={id} tabIndex={0} onClick={() => handleRead(notificationID)} onKeyPress={() => handleRead(notificationID)}>
            <div className='notificationEventRowStatusCol'>
                {notificationType.toLowerCase() === constants.action_notification && <img src={shareIcon} alt={'shared'} className='share-icon'></img>}
            </div>
            <div className='notificationEventRowContentCol'>
                <div className='notificationEventHeaderRow'>
                    <p className={notificationType.toLowerCase() === constants.action_notification ? 'notificationTitle title-small notification' : 'notificationTitle title-small notification notification-space' }>{message}</p>
                </div>
                <p className='notificationTimestamp title-small notification-time'>{helperFunctions.getRelativeTime(timeSent)}</p>
                {/* <div className='notificationEventContentRow'>
                    <p className='notificationDesc caption-small'>{notificationType}</p>
                </div> */}
            </div>
            <div className='notificationEventRowStatusCol'>
                {!readStatus && <div className='unreadIcon'></div>}
            </div>
        </div >
    );
};

NotificationEventRow.propTypes = {
    id: PropTypes.string,
    notificationID: PropTypes.string,
    message: PropTypes.string.isRequired,
    timeSent: PropTypes.string,
    notificationType: PropTypes.string.isRequired,
    readStatus: PropTypes.bool,
    email: PropTypes.string,
    actionType: PropTypes.string,
    context: PropTypes.array,
};

export default NotificationEventRow;
