const getLocalStorageProperty = (name, userId, projectId, eventId) => `${name}-${userId}
-${projectId}-${eventId}`;

const updateOpenNodes = (elements, elementId, isOpen) => {
    const clonedElements = [...elements];
    const elementExists = clonedElements.some((element) => element === elementId);
    if (elementExists && !isOpen) {
        return clonedElements.filter((element) => element !== elementId);
    }
    if (!elementExists && isOpen) {
        return [...clonedElements, elementId];
    }
    return clonedElements;
};

const getOpenNodes = (property) => {
    if (localStorage.getItem(property) !== null) {
        return JSON.parse(localStorage.getItem(property));
    }
    return [];
};
const saveOpenNodes = (property,
    currentOpenNodes, elementId, status) => {
    localStorage.setItem(property,
        JSON.stringify(updateOpenNodes(currentOpenNodes, elementId, status)));
};

const updateAndSaveOpenNodesInLocalStorage = (property, elementId, status) => {
    const openNodes = getOpenNodes(property);
    saveOpenNodes(property, openNodes, elementId, status);
};

export {
    getLocalStorageProperty,
    updateAndSaveOpenNodesInLocalStorage,
    getOpenNodes,
};
