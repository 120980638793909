import React from 'react';
import helperFunctions from '../utils/helperFunctions';
import { getLotOrProject } from './response.service';
import {
    previousSubmissions, submission, SUBMITTED_AT,
} from '../config/constants';

const getPreviousResponses = (_initialData, responses, getPreviousResponsesMenu,
    prevIndex) => responses
    .reduce((acc, response, index) => {
        const id = `${prevIndex }_response_${ index }`;
        return acc.concat({
            key: response.responseID,
            label: response.templateName,
            id,
            menu: getPreviousResponsesMenu
                ? getPreviousResponsesMenu(response.responseStatus,
                    response.responseID) : undefined,
        });
    }, []);


const getPreviousLotsOrProjects = (
    initialData, data, getPreviousResponsesMenu, getPreviousLotsMenu, prevIndex,
) => Object.entries(data)
    .reduce((acc, [key, value], index) => {
        const id = `${prevIndex}_lot_${ index}`;
        return acc.concat({
            key,
            openState: true,
            id,
            label: `${getLotOrProject(initialData[0].publishLevel)} ${value.order} : ${value.title}`,
            menu: getPreviousLotsMenu ? getPreviousLotsMenu(value) : undefined,
            nodes: getPreviousResponses(initialData, value.responses, getPreviousResponsesMenu, id),
        });
    }, []);

const getPreviousSubmissionLabel = (value) => <p className='previous-submission-label'>{`${submission} #${value.submissionNumber} `}
    <span>{`${SUBMITTED_AT}: ${helperFunctions.formatPrettyDateTime(value.submissionDate)}`}</span></p>;

const getPreviousSubmissions = (data, getPreviousResponsesMenu,
    getPreviousLotsMenu) => Object.entries(data)
    .reverse().reduce((acc, [key, value], index) => {
        const id = `submission_${ index}`;
        return acc.concat({
            key,
            openState: true,
            id,
            label: getPreviousSubmissionLabel(value),
            nodes: getPreviousLotsOrProjects(data, value.lotsOrProjects,
                getPreviousResponsesMenu, getPreviousLotsMenu, id),
        });
    }, []);

const preparePreviousSubmissionsTree = (data, getPreviousResponsesMenu,
    getPreviousLotsMenu, projectId, eventId, getPreviousSubmissionsMenu) => [{
    key: 'previousSubmissions',
    label: previousSubmissions,
    openState: true,
    menu: getPreviousSubmissionsMenu ? getPreviousSubmissionsMenu(projectId, eventId) : undefined,
    nodes: getPreviousSubmissions(data, getPreviousResponsesMenu, getPreviousLotsMenu),
}];

export default preparePreviousSubmissionsTree;
