import React, { useState, useContext, useEffect } from 'react';
import { formatDistanceStrict, addSeconds } from 'date-fns';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';

import Modal from '../../components/Modal';
import AuthContext from '../../context/AuthContext';

const Timeout = () => {
    const timeout = 25 * 60 * 1000; // 25 minutes
    const promptTimeout = 5 * 60 * 1000; // 5 minutes

    const history = useHistory();
    const authContext = useContext(AuthContext);

    // Modal open state
    const [open, setOpen] = useState(false);

    // Time before idle
    const [remaining, setRemaining] = useState(0);

    const closeModal = () => {
        setOpen(false);
        setRemaining(0);
    };

    const logout = () => {
        authContext.logout(() => history.push('/'));
        closeModal();
    };

    const handleLogout = () => {
        closeModal();
        logout();
    };

    const onPrompt = () => {
        // onPrompt will be called after the timeout value is reached
        // In this case 30 minutes. Here you can open your prompt.
        // All events are disabled while the prompt is active.
        // If the user wishes to stay active, call the `reset()` method.
        // You can get the remaining prompt time with the `getRemainingTime()` method,
        setOpen(true);
        setRemaining(promptTimeout);
    };

    const onIdle = () => {
        // onIdle will be called after the promptTimeout is reached.
        // In this case 30 seconds. Here you can close your prompt and
        // perform what ever idle action you want such as log out your user.
        // Events will be rebound as long as `stopOnMount` is not set.
        handleLogout();
    };

    const onActive = () => {
        // onActive will only be called if `reset()` is called while `isPrompted()`
        // is true. Here you will also want to close your modal and perform
        // any active actions.
        closeModal();
    };

    const { getRemainingTime, isPrompted, activate } = useIdleTimer({
        name: 'open-session-timeout',
        timeout,
        promptTimeout,
        onPrompt,
        onIdle,
        onActive,
        crossTab: true,
    });

    const handleRefresh = () => {
        authContext.refresh();
        closeModal();
        activate();
    };

    const formatRemainingTime = () => (
        formatDistanceStrict(addSeconds(new Date(), remaining), new Date(), {
            roundingMethod: 'ceil',
        })
    );

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [getRemainingTime, isPrompted]);

    const modalBody = (
        <>
            <p className='body'>Due to inactivity you will be logged out soon. Do you wish to stay logged in?</p>
            <p className='body'>Auto logout in <b className='title'>{formatRemainingTime()}</b></p>
        </>
    );

    return (
        <>
            {open && <Modal
                open={open}
                closeModal={handleRefresh}
                size='small'
                headerTitle='Session timeout'
                handleMainActionBtnClick={handleRefresh}
                mainActionButtonTxt='Continue Session'
                closeButton={true}
                body={modalBody}
                helpOption={false}
                secondActionButton={true}
                secondActionButtonTxt='Logout'
                handleSecondaryActionBtnClick={logout}
            />}
        </>
    );
};

export default Timeout;
