import React from 'react';
import PropTypes from 'prop-types';

const StatusLabel = ({
    id,
    color,
    labelTxt,
}) => (<div id={`${id}-statuslabel`} className={`statuslabel-content ${color}`}>
    <div className='statuslabel-txt-container'>
        <div id={`${id}-statuslabel-text`} className='statuslabel-txt body'>{labelTxt}</div>
    </div>
</div >);


StatusLabel.propTypes = {
    id: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    labelTxt: PropTypes.string.isRequired,
};

export default StatusLabel;
