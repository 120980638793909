import {
    workflowEvents,
    eventStatuses,
    workflowRestrictionConstants,
    callOffMechanismValues,
    projectTypes,
    publishAtLevel,
} from '../config/constants';

function getCurrentPublishEvent(workflowStages) {
    const events = workflowStages.reduce((acc, stage) => acc.concat(stage.events), []);
    return events?.findLast((event) => event.eventType === workflowEvents.publish
    && event.eventStatus !== eventStatuses.pending
    && event.isPublished === true);
}

function getRegisteredSuppliersFromCurrentPublishEvent(project) {
    const publishEvent = getCurrentPublishEvent(project.workflow?.stages);
    return publishEvent?.registerInterest || [];
}

function findStageOrderNoByEventID(stages, eventID) {
    const stage = stages?.find((s) => s.events?.some((event) => event.eventID === eventID));
    return stage ? stage.orderNo : null;
}

function isEventDPSAndFirstStage(event, workflow) {
    if (event) {
        const isDPS = event.dpsRulesApplicable;
        const stageOrderNo = findStageOrderNoByEventID(
            workflow.stages, event.eventID,
        );
        return isDPS && stageOrderNo === 1;
    }
    return false;
}

function isStageDirectAward({
    route, projectType, callOffMechanism, workflow,
}, stagePublishEvent) {
    if (workflow) {
        if (isEventDPSAndFirstStage(stagePublishEvent, workflow)) {
            return false;
        }
    }

    return (projectType === projectTypes.project
        && this.isRTMDirectAward(route))
    || (projectType === projectTypes.callOff
        && callOffMechanism === callOffMechanismValues.directAward);
}

function isProjectOrCallOffDirectAward({
    route, callOffMechanism, projectType, workflow,
}) {
    if (workflow) {
        const currentPublishEvent = getCurrentPublishEvent(workflow?.stages);
        if (isEventDPSAndFirstStage(currentPublishEvent, workflow)) {
            return false;
        }
    }

    return (projectType === projectTypes.project
            && this.isRTMDirectAward(route))
        || (projectType === projectTypes.callOff
            && callOffMechanism === callOffMechanismValues.directAward);
}

function isCallOffDirectAward({ callOffMechanism, projectType }) {
    return projectType === projectTypes.callOff
            && callOffMechanism === callOffMechanismValues.directAward;
}

function isCallOffFurtherCompetition({ callOffMechanism, projectType }) {
    return projectType === projectTypes.callOff
            && callOffMechanism === callOffMechanismValues.furtherComp;
}

function isRTMDirectAward(route) {
    return [
        workflowRestrictionConstants.routeToMarket_directAward,
        workflowRestrictionConstants.establishedSolution,
    ].includes(route);
}

function getPublishLevel(workflowRecord) {
    return getCurrentPublishEvent(workflowRecord.stages)?.publishProject
        ? publishAtLevel.project
        : publishAtLevel.lot;
}

export default {
    getRegisteredSuppliersFromCurrentPublishEvent,
    isProjectOrCallOffDirectAward,
    isStageDirectAward,
    isCallOffDirectAward,
    isCallOffFurtherCompetition,
    isRTMDirectAward,
    getPublishLevel,
};
