const Constants = {

    sortOptions: [
        null,
        { label: 'Start date (oldest)', value: 'contractStartDate-ascending-date' },
        { label: 'Start date (newest)', value: 'contractStartDate-descending-date' },
    ],
    tabOptions: [
        { label: 'My Organisation', value: 'myorganisation' },
        { label: 'NEPO', value: 'nepo' },
        { label: 'External', value: 'external' },
    ],
    UNABLE_TO_RETRIEVE_INFORMATION: 'Unable to retrieve information.',
    SEARCH_FOR_A_SOLUTION: 'Search for a solution',
    NUM_OF_ROWS_PER_PAGE: 15,
};

export default Constants;
