import React, { useState, useContext } from 'react';
import propTypes from 'prop-types';
import { format } from 'date-fns';
import Modal from '../../components/Modal';
import Form from '../../components/Form';
import LabelledTextarea from '../../components/LabelledTextArea';
import WorknoteItem from './components/WorknoteItem';
import AuthContext from '../../context/AuthContext';
import Toast from '../../components/Alerts/Toast/Toast';
import helperFunctions from '../../utils/helperFunctions';

const WorknotesModal = ({
    mode,
    closeModal,
    worknotesInput = [],
    handleCallback,
}) => {
    const [value, setValue] = useState('');
    const authContext = useContext(AuthContext);
    const { user } = authContext;

    const generateViewWorkNoteBody = () => {
        if (worknotesInput?.length > 0) {
            const elements = worknotesInput.map((data, idx) => {
                const title = data.username
                    ? `Note added by ${data.username} on ${helperFunctions.formatPrettyDateTime(data.dateAdded)}`
                    : `Note added on ${helperFunctions.formatPrettyDateTime(data.dateAdded)}`;
                return (<WorknoteItem
                    key={`worknote-${idx}-${data.dateAdded}`}
                    title={title}
                    txt={data.worknote}
                />);
            });
            return <div className='worknote-body'>{elements}</div>;
        }
        return <div className='worknote-body'><p className='caption'>No worknotes added.</p></div>;
    };

    const handleEvent = (e) => {
        e.preventDefault();
        let newWorknotes = [];
        const todaysDate = format(new Date(), 'dd-MM-yyyy HH:mm');
        const addNote = {
            dateAdded: todaysDate,
            worknote: value,
            username: `${user.firstName } ${ user.lastName}`,
            userID: user.id,
        };
        newWorknotes = worknotesInput?.map((worknote) => ({
            ...worknote,
            user: undefined,
        }));
        newWorknotes.push(addNote);
        if (handleCallback) {
            handleCallback(newWorknotes, value);
        } else {
            Toast.fire({
                icon: 'success',
                titleText: 'Worknote added successfully.',
            });
        }
        closeModal();
    };

    return <>
        {(mode === 'add') ? (
            <Form id='worknote-form' onSubmit={(e) => handleEvent(e)}>
                <Modal
                    open={true}
                    size='medium'
                    headerTitle='Add Worknote'
                    secondActionButton
                    secondActionButtonTxt='Cancel'
                    handleMainActionBtnClick={() => null}
                    handleSecondaryActionBtnClick={closeModal}
                    mainActionButtonTxt='Add'
                    closeModal={closeModal}
                    body={<LabelledTextarea
                        id='worknote-input'
                        label='Enter worknote'
                        breakColumn
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        placeholder='Enter worknote'
                        currentCharCount={value.length || 0}
                        required={true}
                        maxCharCount={1000}
                    />}
                    helpOption={false}
                />
            </Form>
        ) : (<Modal
            open={true}
            id='worknote-view'
            size='medium'
            headerTitle='View Worknotes'
            secondActionButton={false}
            handleSecondaryActionBtnClick={closeModal}
            mainActionButtonTxt='false'
            closeModal={closeModal}
            body={generateViewWorkNoteBody()}
            helpOption={false}
            footer={false}
        />)}
    </>;
};

WorknotesModal.propTypes = {
    mode: propTypes.string.isRequired,
    closeModal: propTypes.func.isRequired,
    worknotesInput: propTypes.array,
    handleCallback: propTypes.func,
};

export default WorknotesModal;
