import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';
import BrowseData from '../../features/BrowseData';
import Constants from './constants';
import projectManagementAPIs from '../../services/project-management.service';
import gatewayAPIs from '../../services/gateway.service';
import Toast from '../../components/Alerts/Toast/Toast';
import AuthContext from '../../context/AuthContext';
import rbac from '../../rbac';

const ContractsRegister = () => {
    const [contracts, setContracts] = useState([]);
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const transformData = (data) => {
        const contract = data;
        for (let i = 0; i < contract.length; i++) {
            contract[i].awardValue = Number(contract[i].awardValue);
        }
        return contract;
    };

    const fetchData = async () => {
        try {
            const response = await projectManagementAPIs.getPublicContracts();
            if (response.status === 200) {
                setContracts(transformData(response.data.items));
            } else {
                setContracts([]);
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const exportFunction = async () => {
        const response = await gatewayAPIs.exportPublicContracts();
        if (response.status === 200) {
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const filename = response.headers['content-disposition'].split('"')[1] || 'export.csv';
            try {
                saveAs(blob, filename);
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to download document.',
                });
            }
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to download document.',
            });
        }
    };

    return <BrowseData
        id='browseContractsRegister'
        context='contractsRegister'
        contextSingular='contractRegister'
        exportFunction={exportFunction}
        dataSet={contracts}
        searchPlaceholder='Search for an awarded contract'
        numOfRowsPerPage={15}
        searchFilterAttribute='title'
        sortOptions={Constants.sortOptions}
        allowAddNew={authContext?.authenticated && rbac.can(rbac.action.addContractRegister,
            [authContext?.user?.accountType?.toLowerCase() + authContext?.user?.role])}
        labelAddNew='Add'
        handleAddNew={() => history.push('/contracts-register/create-public')}
    />;
};

export default ContractsRegister;
