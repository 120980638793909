/* eslint-disable no-param-reassign */
// TODO: Remove when BE is updated for evaluators
/* eslint-disable no-unused-vars */
import format from 'date-fns/format';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    parseISO,
} from 'date-fns';
import AuthContext from '../../context/AuthContext';
import DocumentBuilder from '../../features/DocumentBuilder';
import gatewayAPIs from '../../services/gateway.service';
import documentManagementAPIs from '../../services/document-management.service';
import projectManagementAPIs from '../../services/project-management.service';
import userManagementAPIs from '../../services/user-management.service';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';
import actions from '../EditProjectWorkflow/slice/workflows.actions';
import { accountTypes } from '../../config/constants';

const EditQuestionnaire = () => {
    const {
        templateId, projectId, eventId, isImport, responseId,
    } = useParams();
    // TODO: Check is user is authenticated and add evaluators
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [evaluators, setEvaluators] = useState([]);
    const [isOpportunityLive, setIsOpportunityLive] = useState(false);
    const [type, setType] = useState('');
    const [initialState, setInitialState] = useState({});
    const [memberAuthorities, setMemberAuthorities] = useState([]);
    const [steeringInfo, setSteeringInfo] = useState();
    const {
        documentBuilderState,
    } = useSelector((state) => ({
        documentBuilderState: state.WorkflowsReducer.documentBuilderState,
    }));
    const dispatch = useDispatch();

    const fetchMemberAuthorities = async () => {
        const authorities = await userManagementAPIs.getAllAccountsByType('buyer');
        const auths = authorities.data.map((el) => ({
            value: el.accountID,
            label: el.companyName,
        }));
        setMemberAuthorities(auths);
    };

    const fetchProject = async (id) => {
        const response = await projectManagementAPIs.getProject(id);
        if (response.status === 200) {
            let publishEvent = {};
            response?.data?.workflow?.stages?.forEach((stage) => {
                stage?.events?.forEach((evnt) => {
                    if (evnt.eventID === eventId) {
                        publishEvent = {
                            ...evnt,
                        };
                        if (publishEvent.submissionStartDate && publishEvent.submissionEndDate) {
                            if (helperFunctions.isBetween(new Date(),
                                parseISO(publishEvent.submissionStartDate),
                                parseISO(publishEvent.submissionEndDate))) {
                                setIsOpportunityLive(true);
                            }
                        }
                    }
                });
            });
            setSteeringInfo(response?.data?.steeringInfo);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to load project.',
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (responseId) {
                if (authContext.user.accountType !== accountTypes.supplier) {
                    const response = await projectManagementAPIs.getResponse(responseId);
                    if (response.status === 200) {
                        const preparedData = helperFunctions
                            .prepareDocumentBuilderState('questionnaire', response.data, responseId);
                        setInitialState(preparedData);
                        setType(preparedData.details.templateType);
                        fetchProject(response.data.projectID);
                    } else {
                        Toast.fire({
                            icon: 'error',
                            titleText: 'Unable to retrieve information.',
                        });
                    }
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'You do not have permission to view this content.',
                    });
                    history.push('/dashboard');
                }
            } else {
                const responseData = await gatewayAPIs.getQuestionnaire(templateId);
                // responseData = initialData;
                if (responseData.status === 200) {
                    const preparedData = helperFunctions
                        .prepareDocumentBuilderState('questionnaire', responseData.data, templateId);
                    setInitialState(preparedData);
                    setType(preparedData.details.templateType);
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to retrieve information.',
                    });
                }
            }
        };
        // TODO: if (templateId && authContext.user.isAuthenticated) {
        if (templateId) {
            fetchMemberAuthorities();
            if (documentBuilderState.details && isImport && isImport.length > 1) {
                setInitialState(documentBuilderState);
                setType(documentBuilderState.details.templateType);
            } else {
                dispatch(actions.setBuilderState({}));
                fetchData();
            }
        }
    }, [templateId]);

    const constructAndPostQuestionnaireData = async (documentState, confirmAction) => {
        const sectionsInDocument = [];
        documentState.sections?.forEach((section, idx) => {
            const questionsInSection = [];
            section.sectionItems?.forEach((item, i) => {
                const questionToAdd = {
                    orderNo: i,
                    responseOptional: item.responseOptional || false,
                    ...item,
                };

                if (item.attachments) {
                    questionToAdd.attachments = item.attachments;
                }

                if (item.freeTextLength) {
                    questionToAdd.freeTextLength = parseInt(item.freeTextLength, 10);
                }

                if (responseId) questionToAdd.weightage = item.weightage ? item.weightage : 0;

                if (questionToAdd.scoringMechanism !== 'scored') {
                    if (item.weightage !== undefined && documentState?.details?.weightage > 0) {
                        documentState.details.weightage -= parseInt(questionToAdd.weightage, 10);
                        delete questionToAdd.weightage;
                    }
                }

                questionsInSection.push(questionToAdd);
            });

            const sectionDataToAdd = {
                orderNo: idx,
                id: section.id,
                title: section.title,
                description: section.description,
                questions: questionsInSection,
                weightage: section.weightage,
            };

            sectionsInDocument.push(sectionDataToAdd);
        });
        const isPublic = documentState.details.shareQuestionnaire === 'true';

        const postBody = {
            ...documentState.details,
            maxScore: documentState.details.maxScore
                ? parseInt(documentState.details.maxScore, 10) : 0,
            minScore: documentState.details.minScore
                ? parseInt(documentState.details.minScore, 10) : 0,
            sections: sectionsInDocument,
            isPublic,
            sharedWith: isPublic && documentState.details.shareQuestionnaireWithEveryone === 'false'
                ? documentState.details.sharedWith : undefined,
            versionReason: (confirmAction !== 'overwrite' && confirmAction !== 'create' && typeof confirmAction === 'string') ? confirmAction : undefined,
        };
        delete postBody.shareQuestionnaireWithEveryone;
        delete postBody.sharedWithName;
        delete postBody.shareQuestionnaire;
        delete postBody.sharedBy;
        delete postBody.accountIDs;

        let responseData = {};

        if (confirmAction === 'overwrite') {
            responseData = await documentManagementAPIs.updateTemplate(
                postBody, documentState.templateId,
            );
        } else if (confirmAction && responseId) {
            postBody.responseType = documentState?.details?.templateType;
            postBody.templateDescription = documentState?.details?.description;
            responseData = await projectManagementAPIs.editAssignedQuestionnaire(
                responseId, postBody,
            );
        } else {
            responseData = await documentManagementAPIs.createTemplate(postBody);
        }

        if (responseData.status === 200 || responseData.status === 201) {
            if (responseId) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Questionnaire updated successfully.',
                });
                if (isImport && isImport === '_') {
                    history.goBack();
                } else {
                    history.go(-3);
                }
                return true;
            }

            if (!(projectId && eventId) || (projectId === '_' && eventId === '_')) {
                Toast.fire({
                    icon: 'success',
                    titleText: `Questionnaire ${confirmAction === 'create' ? 'created' : 'updated'} successfully.`,
                });
            }

            if (projectId && eventId && projectId !== '_' && eventId !== '_') {
                const payload = {
                    tenderName: postBody.templateName,
                    tenderType: postBody.templateType,
                    templateID: confirmAction === 'create' ? responseData.data : documentState.templateId,
                };

                const response = await projectManagementAPIs
                    .assignQuestionnaireToEvent(projectId, eventId, payload);
                if (response.status === 200) {
                    const responsePayload = {
                        sections: postBody.sections,
                        title: postBody.templateName,
                        templateType: postBody.templateType,
                        templateID: confirmAction === 'create' ? responseData.data : documentState.templateId,
                        responseType: 'Questionnaire',
                    };
                    const updateResponsesUpdate = await projectManagementAPIs
                        .updateResponsesOnQuestionnaireChange(projectId, eventId,
                            responsePayload);
                    if (updateResponsesUpdate.status === 200) {
                        Toast.fire({
                            icon: 'success',
                            titleText: 'Questionnaire updated successfully.',
                        });
                        history.push(`/projects/overview/${projectId}`);
                        return true;
                    }
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to update questionnaire.',
                    });
                }
            }
            /** TODO: Update route as per journey */
            history.push('/questionnaires');
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update questionnaire.',
            });
        }
        return null;
    };

    const handleEnableDisableBtnClick = async (enabled) => {
        const responseData = await documentManagementAPIs.disableTemplate(templateId, {
            isDisabled: enabled,
        });
        if (responseData.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Questionnaire updated successfully.',
            });
            history.goBack();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to updated questionnaire.',
            });
        }
    };

    return Object.keys(initialState).length > 0 ? <DocumentBuilder
        documentMode={'edit'}
        context='Questionnaire'
        type={type}
        initialDocumentState={initialState}
        sectionLabel='Section'
        sectionItemLabel='Question'
        allowSectionReordering={true}
        allowSectionItemsReordering={true}
        sectionDetailsUserList={evaluators}
        handleEnableDisableBtnClick={handleEnableDisableBtnClick}
        handlePrimaryToolbarSubmitButton={constructAndPostQuestionnaireData}
        memberAuthorities={memberAuthorities}
        isALiveQuestionnaire={isOpportunityLive}
        steeringInfo={steeringInfo}
    /> : <></>;
};

export default EditQuestionnaire;
