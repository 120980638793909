import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import React from 'react';
import Button from '../../components/Button';
import Constants from './supplierConstants';

const SupplierListComp = ({
    dataSet,
    context,
}) => {
    const history = useHistory();
    const generateContent = (data) => {
        switch (context.toLowerCase()) {
            case Constants.regionalOffice:
            case Constants.headquarter:
                return <div>
                    <p className='caption'>{data?.accountName}</p>
                </div>;
            case Constants.department:
                return <div className='bdAccordionSectionContentData'>
                    <p className='caption'>{`${data?.contactDetails?.firstname} ${data?.contactDetails?.surname}`}</p>
                    <p className='title-small'>{data?.email}</p>
                    <p className='title-xSmall'>{data?.jobTitle}</p>
                    <p className='title-xSmall'>{data?.contactDetails?.countryCode} {data?.contactDetails?.mobile}</p>
                </div>;
            default:
                return <></>;
        }
    };

    const generateComp = () => {
        const ListComp = dataSet?.length > 0
            ? <div className='cardContentContainer'>
                {dataSet?.length > 0 && dataSet?.map((supp, idx) => (
                    <div className={context !== Constants.department ? 'solutionAccessRequestItem' : 'departmentRequestItem'} key={idx}>
                        {generateContent(supp)}
                        <p className='title-xSmall'>
                            {context !== Constants.department && <Button
                                id='view'
                                label='View Supplier'
                                variant='primary'
                                additionalVariant='white'
                                handleClick={() => history.push(supp.accountID)}
                            />}
                        </p>
                    </div>
                ))}
            </div>
            : <div className='cardContentContainer'>
                <p className='title' id={context !== Constants.department ? 'noContent' : 'noDepartmentContent'} >
                    {context !== Constants.department ? 'There are no regional offices registered in Open for this supplier.'
                        : 'There are no users registered in Open for this department.' }</p>
            </div>;
        return ListComp;
    };
    return (generateComp());
};

SupplierListComp.propTypes = {
    dataSet: PropTypes.arrayOf(PropTypes.any),
    context: PropTypes.string.isRequired,
};

export default SupplierListComp;
