import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DocumentBuilder from '../../features/DocumentBuilder';
import projectManagementAPIs from '../../services/project-management.service';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';

const EditStage = () => {
    const { stageId } = useParams();
    const history = useHistory();
    const [type, setType] = useState('');
    const [initialState, setInitialState] = useState({});
    const [routeToMarket, setRouteToMarket] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const responseData = await projectManagementAPIs.getStage(stageId);
            if (responseData.status === 200) {
                const preparedData = helperFunctions
                    .prepareDocumentBuilderState('workflow', { stages: [responseData.data] }, stageId);
                setInitialState(preparedData);
                setType(preparedData.details?.type);
                setRouteToMarket(responseData?.data?.route);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        };
        if (stageId) {
            fetchData();
        }
    }, [stageId]);

    const constructAndPostStageData = async (documentState) => {
        const stagesInDocument = {};
        documentState.sections.forEach((stage, idx) => {
            const eventsInSection = [];
            stage?.sectionItems?.forEach((event, i) => {
                const eventToAdd = {
                    ...event,
                    orderNo: i,
                    eventDuration: parseInt(event.eventDuration, 10),
                    startDateReminder: event.startDateReminderFlag
                        ? parseInt(event.startDateReminder, 10)
                        : undefined,
                    endDateReminder: event.endDateReminderFlag
                        ? parseInt(event.endDateReminder, 10)
                        : undefined,
                    stageCategory: stage.category,
                };
                eventsInSection.push(eventToAdd);
            });

            const stageDataToAdd = {
                events: eventsInSection,
            };

            stagesInDocument[idx] = stageDataToAdd;
        });

        const putBody = {
            stageName: documentState.sections[0].name,
            category: documentState.sections[0].category,
            events: stagesInDocument[0].events,
            route: routeToMarket,
        };

        const responseData = await projectManagementAPIs.updateStage(stageId, putBody);

        if (responseData.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Stage updated successfully.',
            });
            /** TODO: Update route as per journey */
            history.push('/stages');
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update stage.',
            });
        }
    };

    return Object.keys(initialState).length > 0 ? <DocumentBuilder
        documentMode={'Edit'}
        context='Stage'
        type={type}
        initialDocumentState={initialState}
        sectionLabel='Stage'
        sectionItemLabel='Event'
        allowSectionReordering={false}
        allowSectionItemsReordering={false}
        handlePrimaryToolbarSubmitButton={constructAndPostStageData}
    /> : <></>;
};

export default EditStage;
