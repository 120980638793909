import * as yup from 'yup';

// import { set } from 'lodash';

// const formatNestedLabel = (params, label, message, customKey = undefined) => {
//     const strReg = /\$\{\s*(\w+)\s*\}/g;

//     const fieldName = set({}, params.path, { label, message });

//     const labelPrefix = Object.entries(fieldName).map(([key, value]) => (
//         `${customKey || key} - #${value.length}`
//     ));

//     const finalMessage = `${labelPrefix} - ${label} ${message}`;
//     return finalMessage.replace(strReg, (_, key) => params[key]);
// };

const baseSchema = {
    title: yup.string().label('notice title').required(),
};

const awardSchema = {
    awards: yup.array().ensure().label('awarded suppliers'),
};

const contractSchema = {
    start: yup.date().required(),
    end: yup.date().required(),
};

// FO2 Schema
const contractNoticeSchema = yup.object().noUnknown().shape({
    ...baseSchema,
    ...contractSchema,
});

// F03 Schema
const awardNoticeSchema = yup.object().noUnknown().shape({
    ...baseSchema,
    ...contractSchema,
    ...awardSchema,
});

// F01 Schema
const preprocurementNoticeSchema = yup.object().noUnknown().shape({
    ...baseSchema,
});

const ftsSchemas = {
    contractNoticeSchema,
    awardNoticeSchema,
    preprocurementNoticeSchema,
};

export default ftsSchemas;
