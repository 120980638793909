import React from 'react';
import PropTypes from 'prop-types';

import SelectSearch, { fuzzySearch } from 'react-select-search/dist/cjs';

const SelectSearchComponent = ({
    id,
    label,
    options,
    placeholder,
    value,
    optionInputType = '',
    multiple = true,
    ...props
}) => {
    const mapOptions = (opts) => {
        if (Array.isArray(opts)) {
            if (optionInputType === 'obj') {
                return opts.map((opt) => ({
                    name: opt[Object.keys(opt)[0]] ? `${Object.keys(opt)[0]} - ${opt[Object.keys(opt)[0]]}` : '',
                    value: Object.keys(opt)[0] || '',
                }));
            }
            return opts.map((opt) => ({
                name: opt,
                value: opt,
            }));
        }

        return Object.keys(opts).map((key) => ({
            name: key,
            value: opts[key],
        }));
    };

    return (
        <div className='noticeSearch'>
            <label className='form-label title selectSearchTitle' id='selectLabel'>
                {label}
            </label>

            <SelectSearch
                id={id}
                options={mapOptions(options)}
                filterOptions={fuzzySearch}
                value={value}
                search={true}
                printOptions='on-focus'
                closeOnSelect={false}
                multiple={multiple}
                placeholder={placeholder}
                { ...props }
                // onChange={(e) => handleChange(e, el)}
            />
        </div>
    );
};

SelectSearchComponent.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    value: PropTypes.array.isRequired,
    multiple: PropTypes.bool,
    optionInputType: PropTypes.string,
};

export default SelectSearchComponent;
