const Constants = {
    data: [{
        content: [{
            value: '931ce7ad-526d-4023-9afb-02e0133ebcc2',
            masterDataAttribute: 'contractID',
        }, {
            value: 'McAfee Endpoint',
            masterDataAttribute: 'title',
        }, {
            value: 13650,
            masterDataAttribute: 'awardValue',
        }, {
            value: '31-12-2030',
            masterDataAttribute: 'startDate',
        }, {
            value: '30-12-2031',
            masterDataAttribute: 'endDate',
        }, {
            value: 'Kent County Council',
            masterDataAttribute: 'accountName',
        }, {
            value: 'Caretower Ltd.',
            masterDataAttribute: [{
                suppliers: 'name',
            }],
        }, {
            value: '16-03-2021',
            masterDataAttribute: 'awardDate',
        }],
    }],
    sortOptions: [
        null,
        { label: 'Award Date (newest)', value: 'awardDate-descending-date' },
        { label: 'Award Date (oldest)', value: 'awardDate-ascending-date' },
        { label: 'Estimated Total Value (highest first)', value: 'awardValue-descending-number' },
        { label: 'Estimated Total Value (lowest first)', value: 'awardValue-ascending-number' },
    ],
};

export default Constants;
