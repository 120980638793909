import React from 'react';
import PropTypes from 'prop-types';

import {
    InputComponent,
} from './components';

const FTSCriteria = ({
    prefix,
    labelPrefix,
    formFields,
    handleChange,
}) => {
    const getPropObjValue = (object) => {
        const parts = prefix?.split('.');
        let returnProp = null;
        if (parts !== null && parts !== undefined) {
            for (let i = 0; i < parts.length; i++) {
                if (object[parts[i]]) {
                    returnProp = object[parts[i]];
                    // eslint-disable-next-line no-param-reassign
                    object = returnProp;
                } else { return null; }
            }
        }
        return returnProp;
    };

    return (<>
        <div className='criteria' >
            <InputComponent
                id={`${prefix}.criterion`}
                label={`${labelPrefix} criterion`}
                type='text'
                value={getPropObjValue(formFields)?.criterion}
                required={true}
                onChange={handleChange}
            />
            <InputComponent
                id={`${prefix}.weighting`}
                label='Weighting'
                type='number'
                value={getPropObjValue(formFields)?.weighting}
                required={true}
                onChange={handleChange}
            />
        </div>
    </>
    );
};

FTSCriteria.propTypes = {
    prefix: PropTypes.string.isRequired,
    labelPrefix: PropTypes.string.isRequired,
    formFields: PropTypes.object.required,
    handleChange: PropTypes.func.required,
};

export default FTSCriteria;
