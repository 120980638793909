import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => (
    <div id='wrapper'>
        <div id='info'>
            <p className='body'>This page could not be found</p>
            <p className='caption' style={{ textAlign: 'left' }} >
                <Link className='caption' to='/'>Go to Home </Link>
            </p>
        </div>
    </div >
);

export default PageNotFound;
