import React from 'react';

const TermsAndConditions = () => <div className='bdContentSection'>
    <div className='WordSection1'>
        <p className='tc-title-align'>
            <span className='tc-title body bold xx-large-title'>SUPPLIER TERMS AND CONDITIONS </span>
        </p>
        <p className='tc-title-align'>
            <span className='tc-title body bold xx-large-title'>FOR THE OPEN SYSTEM</span>
        </p>

        <p className='tc-list tc-content'>
            <span className='tc-title body bold xx-large-title'>1.</span>
            <span className='tc-title body bold xx-large-title'>DEFINITIONS AND INTERPRETATION</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>1.1</span>
            <span className='tc-content body'>
        The definitions and rules of interpretation which apply to these
        Supplier Terms are set out in Schedule 1.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>2.</span>
            <span className='tc-title body bold xx-large-title'>INTRODUCTION</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>2.1</span>
            <span className='tc-content body'>The Open System is provided by NEPO and operated by the Buyer and the
      Supplier. These Supplier Terms apply to the Supplier&#39;s and its Supplier
      Users&#39; access to and use of the Open System.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>2.2</span>
            <span className='tc-content body'>The Supplier acknowledges that in accessing
                the Open System, the Supplier
      agrees to be bound by these Supplier Terms.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>2.3</span>
            <span className='tc-content body'>The Supplier shall only use the Open System to respond to an invitation to participate in a procurement activity and in accordance with these Supplier Terms or any subsequent terms and conditions expressed and presented by NEPO in the Open System. In the event of conflict between these Supplier Terms and the terms and conditions expressed and presented by NEPO within the Open System, the latter will take precedence over these Supplier Terms.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>2.4</span>
            <span className='tc-content body'>
        These Supplier Terms between NEPO and the Supplier governs the access
        and use of the Open System by the Supplier to respond to an invitation
        from the active Buyer to participate in the
        procurement activities managed within the Open System. Procurement activities
        refers to the full spectrum of operations,
      processes and policies required to perform the procurement duties in its
      entirety and within the confines of any existing or future permeations of
      the governing Public Contract Regulations. Buyers will have full
      discretion to apply unique terminologies to its procurement activities
      performed within the Open System.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body' >2.5</span>
            <span className='tc-content body'>
      Any specification, descriptive matter or advertising issued by NEPO and
      any specifications, descriptions or illustrations of the Open System
      contained in the Open System or in NEPO&#39;s marketing materials are issued
      or published for the sole purpose of giving an approximate idea of the
      Open System. They shall not have any contractual force.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>3.</span>
            <span className='tc-title body bold xx-large-title'>ACCESS </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>3.1</span>
            <span className='tc-content body'>
      NEPO will use reasonable endeavours to grant the Supplier access to the
      Open System in all material respects in accordance with these Supplier
      Terms. This access is granted:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>3.1.1</span>
            <span className='tc-content body'>free of charge; </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>3.1.2</span>
            <span className='tc-content body'>
      solely for the purposes of responding to any procurement activities
      referred to in clause 2.4; and </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>3.1.3</span>
            <span className='tc-content body'>subject to these Supplier Terms.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>3.2</span>
            <span className='tc-content body'>
      NEPO reserves the right to immediately deny or revoke access to the Open
      System for any Supplier in the event of any of the following:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>3.2.1</span>
            <span className='tc-content body'>
      the Supplier commits a material breach of any of its obligations under
      these Supplier Terms; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>3.2.2</span>
            <span className='tc-content body'>
      the Supplier fails to remedy, or persists in, any breach of any of its
      obligations under these Supplier Terms after having been notified in
      writing to remedy or desist such breach within a period of ten (10)
      Business Days.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>3.3</span>
            <span className='tc-content body'>
      Without prejudice to any of NEPO&#39;s other rights, NEPO reserves the right
      to suspend access to the Open System without notice for technical or legal
      reasons.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>3.4</span>
            <span className='tc-content body'>
      NEPO may change the Open System if required to comply with Applicable Law
      or any regulatory requirement.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>4.</span>
            <span className='tc-title body bold xx-large-title'>REGISTRATION</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>4.1</span>
            <span className='tc-content body'>
      To be granted access to the Open System, the Supplier must provide NEPO
      with:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>4.1.1
            </span>
            <span className='tc-content body'>its registered company name;</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>4.1.2</span>
            <span className='tc-content body'>its registered company number; </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>4.1.3</span>
            <span className='tc-content body'>its registered office address; and</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>4.1.4</span>
            <span className='tc-content body'>any other information that NEPO indicates to be necessary,</span>
        </p>
        <p className='tc-content-c tc-content'>
            <span className='tc-content body'>following which NEPO shall create an account for the Supplier.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>4.2</span>
            <span className='tc-content body'>
      To be granted access to the Open System, the Supplier must register each
      User profile by providing the mandatory registration data and specifying a
      Username, password and role.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>4.3</span>
            <span className='tc-content body'>
      Username and password are strictly personal to the User profile and
      non-transferable. The Supplier shall ensure that its Users do not divulge
      or disclose their username or password to third parties. If the Supplier
      or a User becomes aware that a username and / or password may have been
      divulged, disclosed or discovered by any third party, they shall
      immediately notify the Helpdesk.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>4.4</span>
            <span className='tc-content body'>
      The first User to register a User profile shall be automatically assigned
      the role of superuser. Superusers will be granted additional permissions
      within the Open System to manage the Supplier data on behalf of the
      Supplier.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>4.5</span>
            <span className='tc-content body'>
      The Supplier must immediately inform the Helpdesk if a User leaves its
      employment or no longer requires access to the Open System. NEPO reserves
      the right to deactivate a User profile if there has been no access to the
      Open System for a period of ninety (90) days.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>5.</span>
            <span className='tc-title body bold xx-large-title'>PARTICIPATION</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>5.1</span>
            <span className='tc-content body'>
      Buyers may issue, via the Open System, an invitation to participate in a
      specific procurement activity to Suppliers that have registered and have
      been activated on the Open System as described in these Supplier Terms.
      All data and information made available by the Buyer within the Open
      System and pertinent to the procurement activity shall be made available
      to all invited Suppliers.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>5.2</span>
            <span className='tc-content body'>
      The Buyer shall configure the Open System as necessary for each
      procurement activity. The selected configuration settings for a
      procurement activity shall be displayed on the Open System for invited
      Suppliers to view. By participating in a procurement activity on the Open
      System the Supplier agrees to be bound by those configuration settings.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>5.3</span>
            <span className='tc-content body'>
      All materials issued by the Buyer through the Open System pertaining to a
      procurement activity shall remain the property of the Buyer and shall be
      used by the Supplier only for the purposes stipulated by the Buyer within
      the Open System or otherwise in writing.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>6.</span>
            <span className='tc-title body bold xx-large-title'>SUPPLIER OBLIGATIONS</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.1</span>
            <span className='tc-content body'>
      The Supplier is responsible for ensuring its Users have the hardware and
      software required to access and use the Open System.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.2</span>
            <span className='tc-content body'>
      The Supplier shall provide software to ensure the security of the Open
      System and use all reasonable endeavours to protect the Open System from
      Viruses during access.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.3</span>
            <span className='tc-content body'>
      The Supplier shall use the Open System for lawful and proper purposes
      only.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.4</span>
            <span className='tc-content body'>The Supplier agrees that it will not:</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.4.1</span>
            <span className='tc-content body'>
      manipulate any information supplied on the Open System in a manner that
      would lead to inaccurate, misleading or discriminating presentation of
      information being displayed;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.4.2</span>
            <span className='tc-content body'>
      post, transmit or disseminate any information on or via the Open System,
      which is or may be harmful, obscene, defamatory, or otherwise illegal;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.4.3</span>
            <span className='tc-content body'>
      use the Open System in a manner which causes or may cause an infringement
      of the rights of any other; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.4.4</span>
            <span className='tc-content body'>
      use any software, routine, or device to interfere or attempt to interfere
      electronically or manually with the operation or functionality of the Open
      System, including uploading or making available files containing corrupt
      data or viruses via whatever means.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.5</span>
            <span className='tc-content body'>
      The Supplier agrees that its Users shall act as authorised agents for and
      on behalf of the Supplier in submitting any response to any procurement
      activities via the Open System.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.6</span>
            <span className='tc-content body'>The Supplier represents and warrants that:</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.6.1</span>
            <span className='tc-content body'>
      all information it provides will be accurate and complete and shall
      accurately represent the Supplier’s capabilities;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.6.2</span>
            <span className='tc-content body'>
      it shall have the right, through actual ownership or otherwise, to provide
      any product, service or works that is the subject of any response made by
      it and in relation to any procurement activity; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.6.3</span>
            <span className='tc-content body'>
      it can sell the product, service or works at the prices offered in any
      response made by it and in relation to any procurement activity according
      to the relevant criteria specified by the Buyer at the point of request.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.7</span>
            <span className='tc-content body'>
      Suppliers are responsible for examination of all file attachments and/or
      links published in the Open System, including any requested from the Buyer
      by the Supplier within its submission or otherwise in writing.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.8</span>
            <span className='tc-content body'>
      Suppliers must provide all the items of information requested in the
      procurement activity. Incomplete submission of the information requested
      including the submission of information in file attachments and/or links
      containing Viruses shall entitle the Buyer to invalidate or reject the
      Supplier&#39;s submission to the procurement activity.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.9</span>
            <span className='tc-content body'>
      The Supplier shall be solely responsible for any and all activities
      resulting from its use of the Open System, including:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.9.1</span>
            <span className='tc-content body'>
      any and all goods and/or services sold, or offered for sale, to Buyers or
      potential Buyers from time to time by the Supplier, including any and all
      associated charges, descriptions, questions, offers, defects and other
      such information; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.9.2</span>
            <span className='tc-content body'>
      any and all contracts of sale made with Buyers from time to time by the
      Supplier, including all associated terms and conditions, policies,
      charges, refunds, returns, support questions, comments, complaints and
      other such information.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.10</span>
            <span className='tc-content body'>The Supplier shall:</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.10.1</span>
            <span className='tc-content body'>
      comply with Applicable Law in relation to its use of, access to, and
      activities in connection with, the Open System (including in relation to
      Buyers and potential Buyers);
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.10.2</span>
            <span className='tc-content body'>
      provide NEPO with such information and materials as NEPO may reasonably
      require to provide the Open System, including in response to NEPO&#39;s
      request any administrator credentials for the Supplier&#39;s Users, within the
      timeframe specified by NEPO and ensure that the information it provides to
      NEPO is complete and accurate and does not infringe any third party
      Intellectual Property Rights;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.10.3</span>
            <span className='tc-content body'>use and access the Open System in accordance with these Supplier Terms and
      any documentation or reasonable instructions of NEPO;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.10.4</span>
            <span className='tc-content body'>
      keep all NEPO Materials in safe custody at the Supplier&#39;s own risk and not
      use them other than in accordance with NEPO&#39;s written authorisation;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.10.5</span>
            <span className='tc-content body'>
      co-operate with NEPO in all matters relating to the Open System within the
      timeframe specified by NEPO;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.10.6</span>
            <span className='tc-content body'>
      where required, enter into and in all cases comply with, the Third Party
      Terms;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.10.7</span>
            <span className='tc-content body'>
      ensure that all Users comply with all relevant provisions of these
      Supplier Terms and be responsible for the acts and omissions of any User,
      as if they were the acts and omissions of the Supplier;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.10.8</span>
            <span className='tc-content body'>
      except to the extent caused by an act or omission of NEPO, be responsible
      for managing the use of all login details made available to the Supplier
      and its Users and be responsible for use of the Open System via any logins
      issued to it or its Users (including any User who accesses any protected
      parts of the Open System);
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.10.9</span>
            <span className='tc-content body'>
      comply, and procure that its Users comply, with the Acceptable Use Policy
      and promptly notify NEPO if it becomes aware of any event or circumstance
      that has caused its Users to fail to comply with the Acceptable Use
      Policy;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.10.10</span>
            <span className='tc-content body'>
      comply with any additional obligations stipulated by NEPO in writing or
      via the Open System; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.10.11</span>
            <span className='tc-content body'>
      be responsible for any breach of these Supplier Terms by any of the
      Supplier&#39;s customers, contractors, sub-contractors, agents or
      representatives as if it were the Supplier&#39;s own breach.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.11</span>
            <span className='tc-content body'>The Supplier shall not:</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.11.1</span>
            <span className='tc-content body'>
      unlawfully or illegally disclose or process personal data (as defined in
      clause 10);</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.11.2</span>
            <span className='tc-content body'>
      infringe the Intellectual Property Rights of NEPO or any third party;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.11.3</span>
            <span className='tc-content body'>
      decompile, disassemble or otherwise reverse engineer any aspect of the
      Open System in order to work around, bypass or otherwise circumvent any
      technical limitations of the Open System;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.11.4</span>
            <span className='tc-content body'>
      introduce any Virus or Vulnerability into the systems, servers or networks
      of NEPO or any other third party&#39;s systems, servers or networks; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.11.5</span>
            <span className='tc-content body'>
      in NEPO&#39;s sole discretion, overburden or interfere with NEPO&#39;s systems,
      servers or networks.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.12</span>
            <span className='tc-content body'>Except as expressly authorised in these Supplier Terms, the Supplier shall not:</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.12.1</span>
            <span className='tc-content body'>
      rent, lease, sublicense, distribute, transfer, copy, reproduce, display,
      modify or timeshare the Open System or any portion of it;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.12.2</span>
            <span className='tc-content body'>
      use the Open System or any portion of it as a component of or a base for
      products or services prepared for commercial sale, sublicense, lease,
      access or distribution; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>6.12.3</span>
            <span className='tc-content body'>
      authorise any person who is not a User to access or use the Open System
      under a User’s account.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.13</span>
            <span className='tc-content body'>
      The Supplier is solely responsible for any and all activity undertaken on
      the Open System on the Supplier&#39;s behalf. NEPO is not responsible
      for any loss or damage caused by the Supplier&#39;s failure to maintain the
      security of the Supplier&#39;s Open System access credentials.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.14</span>
            <span className='tc-content body'>
      The Supplier is solely responsible for the legality, compatibility,
      operation and maintenance of any applications and systems that it uses
      with the Open System.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.15</span>
            <span className='tc-content body'>
      The Supplier shall not, without NEPO&#39;s prior written consent, make any
      change to its configuration of the Open System which impacts, or could
      reasonably be expected to impact, on the way in which the Open System is
      provided or made available by NEPO (including in respect of any API used
      in connection with the Open System). The Supplier shall reimburse NEPO for
      any costs incurred by NEPO in respect of work required to be carried out
      in connection with any such change (whether agreed or not).
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.16</span>
            <span className='tc-content body'>
      The Supplier must not attempt to circumvent any security measures put in
      place by NEPO or interfere with any reporting systems installed by NEPO.
      The Supplier will reasonably co-operate with any requests from NEPO
      concerning such measures and/or systems, and with any reporting
      requirements.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.17</span>
            <span className='tc-content body'>
      No part of these Supplier Terms shall have the effect of a transfer or
      grant of license to any software or applications contained in the Open
      System unless expressly granted by NEPO.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.18</span>
            <span className='tc-content body'>
      The Supplier may copy and use any documentation supplied by NEPO only in
      connection with the proper use of the Open System.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>7.</span>
            <span className='tc-title body bold xx-large-title'>SUBMISSIONS AND AWARDS</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>7.1</span>
            <span className='tc-content body'>
      Any procurement activity issued via the Open System by a Buyer must be
      responded to via the Open System by the Supplier and as per the submission
      criteria stipulated for that specific procurement activity.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>7.2</span>
            <span className='tc-content body'>
      Responses may be submitted through the Open System at any time up to the
      respond by date and time as specified on the Open System.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>7.3</span>
            <span className='tc-content body'>
      Suppliers shall be entitled to submit one or more response prior to the
      submission deadline stipulated for that procurement activity. The Supplier
      accepts that the last response submitted prior to the submission deadline
      shall be the response that is considered by the Buyer in the subsequent
      procurement activity. Although the Buyer shall have visibility of the
      number of responses submitted by a Supplier prior to the submission
      deadline, they shall only have access to the last response submission and
      therefore will be unable to consider earlier submissions to the same
      procurement activity.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>7.4</span>
            <span className='tc-content body'>
      The Supplier agrees to keep any responses valid for a period of ninety
      (90) days commencing from the submission deadline, unless any terms and
      conditions for the specific procurement activity require the Supplier to
      keep responses valid for a longer period.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>7.5</span>
            <span className='tc-content body'>
      In the case of discrepancies, the last response submitted through the Open
      System shall prevail over the contents of a file, attachments, links and
      other documents made available by the Supplier during the course of the
      procurement activity.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>7.6</span>
            <span className='tc-content body'>
      Responses will be the opened by the Buyer in accordance with the opening
      procedures specified on the Open System for each procurement activity.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>7.7</span>
            <span className='tc-content body'>
      Notification of award decisions will be made by the Buyer to the Supplier
      after the submission deadline and following evaluation of the Supplier&#39;s
      response to the procurement activity. Such notification of award decision
      will be in accordance with clause 7.8. No other notification of award decision
      sent via the Open System shall
      in any way represent any obligation towards the Supplier on the part of
      the Buyer.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>7.8</span>
            <span className='tc-content body'>
        All notifications of awards shall be in writing and shall be sent to the
        Supplier:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>7.8.1</span>
            <span className='tc-content body'>
      via email, to the e-mail address provided by the Supplier upon
      registration of the User profile; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>7.8.2</span>
            <span className='tc-content body'>via the messaging facility on the Open System. </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>7.9</span>
            <span className='tc-content body'>
      All notifications shall be deemed to be received by the addressee on the
      same day as the addressor sending such notification, provided that, in
      respect of any notification sent by email, the addressor uses the
      addressee’s correct email address.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>7.10</span>
            <span className='tc-content body'>
      The Supplier accepts that the Buyer reserves the right to:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>7.10.1</span>
            <span className='tc-content body'>suspend, re-open or cancel a procurement activity;</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>7.10.2</span>
            <span className='tc-content body'>
      extend the submission deadline for a procurement activity; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>7.10.3</span>
            <span className='tc-content body'>
      vary or amend the details of a procurement activity at any time prior to
      the closing date and submission deadline by publication of a notification
      through the Open System and without incurring any liability whatsoever;
      and in the event of a suspension and subsequent re-opening of a
      procurement activity, the procurement activity will resume on the basis of
      the last response recorded by the Supplier through the Open System.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>8.</span>
            <span className='tc-title body bold xx-large-title'>MONITORING</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>8.1</span>
            <span className='tc-content body'>
        NEPO may install monitoring systems in connection with the Open System
        so as to collect and analyse Supplier Materials and/or aggregated
        information in order to measure and improve performance and to enable it
        to identify and track general trends and statistics in connection with
        use of the Open System. Subject to clause 12, for the duration of the Supplier&#39;s
        use of the Open System, and following
      the termination or expiry of it, NEPO will be free to use Supplier
      Materials and other aggregated information, trends and statistics as
      part of services and systems that it makes available.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>8.2</span>
            <span className='tc-content body'>NEPO shall not be responsible for creating back-ups of the Buyer Materials
      or the Supplier Materials.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>9.</span>
            <span className='tc-title body bold xx-large-title'>INTELLECTUAL PROPERTY RIGHTS</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.1</span>
            <span className='tc-content body'>
      Except as expressly provided, nothing in these Supplier Terms gives a
      party any rights in respect of any Intellectual Property Rights of the
      other party or Buyers.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.2</span>
            <span className='tc-content body'>
      All Intellectual Property Right relating to the Open System (other than
      the Supplier Materials), any derivative works thereof and any tools made
      available by NEPO in connection therewith arising out of or during the
      course of the Agreement will be owned exclusively by NEPO or its licensors
      and the Supplier shall have no rights in or to the Open System other than
      the right to receive them in accordance with these Supplier Terms.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.3</span>
            <span className='tc-content body'>
        NEPO grants the Supplier the non-exclusive, non-assignable right
        (without right to sub-license other than to Users) to use the
        Intellectual Property Rights in the Open System for the duration and
        purpose of the Agreement in the course of the Supplier&#39;s normal business
        operations. The Supplier acquires no other rights to NEPO&#39;s Intellectual
        Property Rights.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.4</span>
            <span className='tc-content body'>
      NEPO will be free to use any know-how utilised or acquired by it in
      connection with performance of its obligations under these Supplier Terms
      for its own benefit or the benefit of third parties provided that such
      know-how does not compromise the Supplier&#39;s Intellectual Property Rights
      or make use of the Supplier&#39;s Confidential Information.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.5</span>
            <span className='tc-content body'>
      The Supplier grants NEPO a non-exclusive, non-transferable and
      royalty-free licence (including the right to grant sub-licences to its
      subcontractors) to copy, use and modify the Supplier Materials:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.5.1</span>
            <span className='tc-content body'>
      for the term of the Agreement for the purposes of providing the Open
      System to the Supplier; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.5.2</span>
            <span className='tc-content body'>in accordance with clause 8.1.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.6</span>
            <span className='tc-content body'>Subject to clause 9.8, the Supplier warrants that the Supplier and its licensors own all Intellectual Property Rights in the Supplier Materials and that it has all the rights in relation to the Supplier Materials that are necessary to grant all the rights it purports to grant under, and in accordance with, these Supplier Terms.</span>
            <span className='tc-content body'>9.7</span>
            <span className='tc-content body'>In the event that NEPO amends, varies or otherwise changes the Open System as a result of feedback given by the Supplier, the Supplier hereby immediately and absolutely assigns to NEPO all right, title, interest in any such amendment, variation or change, present and future. The Supplier shall, at its own expense, promptly execute and deliver such documents and perform such acts as may be required for the purpose of giving full effect to this clause 9.7.</span>
            <span className='tc-content body'>9.8</span>
            <span className='tc-content body'>Any Intellectual Property Rights developed or created in the Supplier Materials by NEPO (whether as a result of the development, modification or otherwise of such Supplier Materials) shall be owned by NEPO and shall be licensed to the Supplier in accordance with clause 9.3.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.9</span>
            <span className='tc-content body'>
        NEPO may use the Supplier&#39;s name, logo and/or trademark on NEPO&#39;s
        website and in its marketing collateral.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.10</span>
            <span className='tc-content body'>
        The Open System may include the Buyer&#39;s logo and/or trademark. The
        Supplier acknowledges that it may not copy, use, or mask the Buyer&#39;s
        logo and/or trademark which appear on the Open System without the prior
        written consent of the Buyer.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.11</span>
            <span className='tc-content body'>
        NEPO will indemnify the Supplier against any settlement amount agreed by
        NEPO, damages awarded and reasonable legal costs in connection with any
        claim by a third party of alleged or actual infringement of any third
        party Intellectual Property Rights arising out of or in connection with
        the use of the Open System by the Supplier in accordance with these
        Supplier Terms provided that, if any third party makes a claim, or
        notifies an intention to make a claim, against the Supplier which may
        reasonably be considered likely to give rise to liability under this
        indemnity (a <span className='bold'>Claim</span>), the Supplier:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.11.1</span>
            <span className='tc-content body'>
      as soon as reasonably possible, gives written notice of the Claim to NEPO,
      specifying the nature of the Claim in reasonable detail;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.11.2</span>
            <span className='tc-content body'>
      does not make any admission of liability, agreement or compromise in
      relation to the Claim without the prior written consent of NEPO (such
      consent not to be unreasonably withheld or delayed); and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.11.3</span>
            <span className='tc-content body'>
      gives NEPO and its professional advisers access at reasonable times (on
      reasonable prior notice) to its premises and its officers, directors,
      employees, agents, representatives or advisers, and to any relevant
      assets, accounts, documents and records within the power or control of the
      Supplier, so as to enable NEPO and its professional advisers to examine
      them and to take copies (at NEPO&#39;s expense) for the purpose of assessing
      the Claim.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.12</span>
            <span className='tc-content body'>
      NEPO will not have any liability for any Claim if the infringement is the
      result of:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.12.1</span>
            <span className='tc-content body'>
      the Supplier&#39;s modification or misuse of the Open System;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.12.2</span>
            <span className='tc-content body'>
      the Supplier&#39;s failure to use enhancements or modifications offered by
      NEPO to avoid infringement;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.12.3</span>
            <span className='tc-content body'>
      NEPO having followed a design, specification and/or instruction given by
      the Supplier; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.12.4</span>
            <span className='tc-content body'>
      any part of the Open System provided by a Third Party Service Provider.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.13</span>
            <span className='tc-content body'>
      If the Open System is, or in NEPO&#39;s reasonable opinion is likely to be,
      held to be infringing the Intellectual Property Rights of a third party,
      NEPO may at its expense and option either:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.13.1</span>
            <span className='tc-content body'>
      procure the right for the Supplier to continue using it;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.13.2</span>
            <span className='tc-content body'>replace it with a non-infringing equivalent;</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.13.3</span>
            <span className='tc-content body'>modify it to make it non-infringing; or</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>9.13.4</span>
            <span className='tc-content body'>terminate the Agreement.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.14</span>
            <span className='tc-content body'>
      Notwithstanding any other provision in these Supplier Terms, clause 9.11
      will not apply to the extent that any Claim arises directly or indirectly
      through the possession, use, development, modification or maintenance of
      any open source or third party software which is made available through
      the Open System.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.15</span>
            <span className='tc-content body'>
      The Supplier will use all reasonable efforts to mitigate the losses it
      suffers as a result of a Claim. The indemnity in clause 9.11
      constitutes the Supplier&#39;s sole and exclusive remedy and NEPO&#39;s entire
      liability with respect to any part of the Open System infringing any third
      party Intellectual Property Rights.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.16</span>
            <span className='tc-content body'>This clause 9 states the Supplier&#39;s sole and exclusive rights and remedies, and NEPO&#39;s
      (including NEPO&#39;s employees&#39;, agents&#39; and sub-contractors&#39;) entire
      obligations and liability, for infringement of any Intellectual Property
      Rights.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>10.</span>
            <span className='tc-title body bold xx-large-title'>DATA PROTECTION</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.1</span>
            <span className='tc-content body'>
      The terms <span className='bold'>controller, processor, processing/process/processes, personal data </span>
      and <span className='bold'>data subject</span> shall be interpreted and construed by reference to
      Data Protection Law.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.2</span>
            <span className='tc-content body'>The Supplier shall:</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>10.2.1</span>
            <span className='tc-content body'>
        comply with its obligations under Data Protection Law;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>10.2.2</span>
            <span className='tc-content body'>
      promptly (and without undue delay) notify NEPO if: (i) it receives a
      complaint or claim from a data subject, or it receives a notice or
      communication from a data protection regulator, or it becomes subject to
      an investigation or enforcement proceedings by a data protection
      regulator, in each case which relates to either party&#39;s actual or alleged
      non-compliance with Data Protection Law with respect to the personal data;
      or (ii) it becomes aware of a personal data breach with respect to the
      personal data that will or is likely to have an adverse impact on NEPO;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>10.2.3</span>
            <span className='tc-content body'>without prejudice to clause 10.2.1,
                be responsible for: (i) obtaining all necessary consents from, and
      providing all necessary fair processing information to, the data subjects
      of the personal data, in each case to enable NEPO to lawfully process the
      personal data for the purposes contemplated by the Agreement in accordance
      with Data Protection Law (including, where applicable, the transfer by
      NEPO of personal data to a Third Party Service Provider in connection with
      the use by the Supplier of their services); and (ii) dealing with and
      responding to data subject requests, enquiries or complaints (including
      any request by a data subject to exercise their rights under Data
      Protection Law) NEPO or the Supplier receives in relation to the personal
      data; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>10.2.4</span>
            <span className='tc-content body'>without prejudice to clause 10.2.1,
                ensure that the Supplier does not upload on to the Open System or
      otherwise transfer to NEPO (i) any special categories of personal data;
      (ii) any medical, criminal, and financial information relating to
      individuals; or (ii) any personal data other than the personal data
      required to enable the Supplier to access and use the Open System.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.3</span>
            <span className='tc-content body'>
      The parties acknowledge and agree that, for the purposes of the Data
      Protection Law, NEPO acts as a processor in its processing of personal
      data on behalf of the Supplier (as controller) in the performance of
      NEPO&#39;s obligations under the Agreement.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.4</span>
            <span className='tc-content body'>
      Further information about the personal data processing activities
      contemplated by the Agreement is set out in the Data Processing Details.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.5</span>
            <span className='tc-content body'>
        NEPO shall only process the personal data for the purposes of exercising
        its rights and performing its obligations under the Agreement and for
        any purpose that is incidental to these purposes (these being the
        Supplier&#39;s documented instructions), unless NEPO is subject to an
        obligation under applicable law to do otherwise, in which case NEPO
        shall notify the Supplier in advance of that legal obligation (unless
        prohibited by that law). NEPO shall notify the Supplier immediately if,
        in NEPO&#39;S opinion, an instruction from the Supplier (as set out in this
        clause 10.5) is not in accordance with Data Protection Law, provided that this shall
      not be construed as an obligation on NEPO to obtain legal or professional
      advice or services to verify the lawfulness of each instruction or provide
      such advice or services to the Supplier.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.6</span>
            <span className='tc-content body'>
      In the event that NEPO transfers any personal data to a Third Party
      Service Provider, the Supplier acknowledges and agrees that the Third
      Party Service Provider will process personal data as a data processor
      directly on behalf of the Supplier and NEPO shall not be responsible for
      the Third Party Service Provider&#39;s compliance with (nor will NEPO be
      liable for any costs or losses incurred by the Supplier in connection with
      the Third Party Service Provider failing to comply with) its obligations
      under Data Protection Law.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.7</span>
            <span className='tc-content body'>When processing the personal data, NEPO shall implement appropriate
      technical and organisational measures to ensure a level of security for
      the personal data appropriate to the risk, taking into account the state
      of the art, the costs of implementation, the nature, scope, context and
      purposes of processing as well as the risk of varying likelihood and
      severity for the rights and freedoms of the data subjects.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.8</span>
            <span className='tc-content body'>
      NEPO shall ensure that all its personnel with access to personal data have
      committed themselves to confidentiality or are under an appropriate
      statutory obligation of confidentiality.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.9</span>
            <span className='tc-content body'>
      At the reasonable request of the Supplier (and at the Supplier&#39;s cost),
      and taking into account the nature of the processing and the information
      available to NEPO, NEPO shall assist the Supplier:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>10.9.1</span>
            <span className='tc-content body'>
      in responding to requests from data subjects exercising their rights under
      Data Protection Law with respect to the personal data; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>10.9.2</span>
            <span className='tc-content body'>
      in undertaking data protection impact assessments (pursuant to Article 35
      of the GDPR and equivalent Data Protection Law), and consulting with data
      protection regulators in relation to high risk processing (pursuant to
      Article 36 of the GDPR and equivalent Data Protection Law) in all cases to
      the extent related to the processing of personal data by NEPO under the
      Agreement, <p className='tc-content body'>provided that in each case the nature and scope of the assistance provided
      by NEPO shall be subject to prior agreement between the Supplier and
      NEPO.</p>
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.10</span>
            <span className='tc-content body'>
      NEPO shall notify the Supplier without undue delay after becoming aware of
      a personal data breach in relation to NEPO&#39;s processing of personal data
      and shall provide such assistance as is reasonably requested by the
      Supplier in responding to the personal data breach, provided that the
      nature and scope of the assistance provided by NEPO, and the apportionment
      of the costs of providing such assistance, shall be subject to prior
      agreement between the Supplier and NEPO.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.11</span>
            <span className='tc-content body'>
      The Supplier hereby authorises NEPO to transfer personal data to any
      country or territory and the parties shall provide each other with
      reasonable cooperation and assistance to enable the transfer to take place
      in accordance with Data Protection Law.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.12</span>
            <span className='tc-content body'>
        The Supplier hereby authorises NEPO to subcontract the processing of
        personal data, provided that NEPO will (at the reasonable request of the
        Supplier, and in a manner and frequency agreed between the parties)
        notify the Supplier of the names of those subprocessors and their role
        in processing personal data. The subprocessors as at the date of the Agreement
        are set out in Schedule 4.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.13</span>
            <span className='tc-content body'>If, following notification under clause 10.12, the Supplier (acting reasonably and in good faith) has concerns over a
      subprocessor&#39;s ability to process personal data in accordance with this
      clause 10, the Supplier may raise this with NEPO, and NEPO and the Supplier agree
      to work together to address those concerns (each party acting reasonably
      and in good faith).
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.14</span>
            <span className='tc-content body'>
      In relation to each subprocessor it appoints to process personal data,
      NEPO shall:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>10.14.1</span>
            <span className='tc-content body'>
      put in place a written agreement with the subprocessor which contains
      terms that provide at least equivalent protection for the personal data as
      the provisions applicable to NEPO under this clause 10; and</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>10.14.2</span>
            <span className='tc-content body'>
      remain responsible for a breach of its obligations under this clause 10
      caused by the subprocessor. </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.15</span>
            <span className='tc-content body'>
      NEPO shall (at the Supplier&#39;s option and cost) return to the Supplier or
      destroy the personal data without undue delay following the termination or
      expiry of this Agreement, provided that NEPO may retain the personal data
      in the following circumstances: (i) where continued retention is required
      to comply with the requirements of applicable law; (ii) where continued
      retention is required to enable NEPO to comply with its post-termination
      obligations under this Agreement; and (iii) where the personal data is
      retained as part of NEPO&#39;s internal record keeping, back-up or business
      continuity procedures, provided, in each case, that the personal data is
      promptly deleted when it is no longer required to be retained.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.16</span>
            <span className='tc-content body'>
      NEPO shall (at the Supplier&#39;s cost and taking into account the nature of
      the processing and the information available to NEPO) provide the Supplier
      with such information as is reasonably requested by the Supplier to enable
      the Supplier to verify NEPO&#39;s compliance with this clause 10.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.17</span>
            <span className='tc-content body'>
      In relation to any personal data that NEPO processes on behalf of the
      Supplier, NEPO shall at least once a year, conduct audits of its personal
      data processing practices and the information technology and information
      security controls for all facilities and systems used in complying with
      its obligations under the Agreement. On the Supplier&#39;s written request,
      NEPO will make all of the relevant audit reports available to the Supplier
      for review. The Supplier will treat such audit reports as NEPO&#39;s
      Confidential Information.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.18</span>
            <span className='tc-content body'>
      If there is any inconsistency or conflict between any of the provisions of
      this clause and the other provisions of the Agreement, the provisions of
      this clause shall prevail to the extent required to enable the parties to
      comply with Data Protection Law.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.19</span>
            <span className='tc-content body'>
      This clause shall survive termination or expiration of the Agreement.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>11.</span>
            <span className='tc-title body bold xx-large-title'>LIMITATION OF LIABILITY</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.1</span>
            <span className='tc-content body'>
      NEPO is not responsible for ensuring that the Open System complies with
      any laws or regulations beyond the Applicable Law, nor is it responsible
      for ensuring that the Open System complies with any codes of practice,
      guidance notes, rules, standards and/or procedures that may be applicable
      to the Supplier or the Buyer from time to time.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.2</span>
            <span className='tc-content body'>
      NEPO relies on information provided by Suppliers and Buyers and, unless
      agreed otherwise by NEPO in writing, NEPO will not review or independently
      verify such information.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.3</span>
            <span className='tc-content body'>
      The Supplier acknowledges that NEPO has no control over any content placed
      on the Open System by Buyers or Suppliers, and that NEPO does not monitor
      the content of the Open System.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.4</span>
            <span className='tc-content body'>
        The Open System may contain integration with third party websites,
        software, systems or services, which are not owned or controlled by NEPO
        (<span className='bold'>Third Party Services</span>). Unless otherwise agreed in writing:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.4.1</span>
            <span className='tc-content body'>
      the Supplier acknowledges that any Third Party Services are governed
      solely by the terms and conditions of the relevant Third Party Terms, at
      the sole risk and responsibility of the Supplier;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.4.2</span>
            <span className='tc-content body'>
      NEPO is not responsible for ensuring the successful integration of Third
      Party Services; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.4.3</span>
            <span className='tc-content body'>
      NEPO shall have no liability for any damage or loss arising from or in
      connection with any Third Party Services.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.5</span>
            <span className='tc-content body'>
      The parties acknowledge and agree that, unless otherwise agreed in writing
      with NEPO, NEPO is not responsible for:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.5.1</span>
            <span className='tc-content body'>
      any third parties (including the Third Party Service Providers);
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.5.2</span>
            <span className='tc-content body'>
      any factor or circumstance (including algorithmic changes) attributable to
      a third party (including the Third Party Service Providers);
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.5.3</span>
            <span className='tc-content body'>
      any external development requirements (technical or otherwise);
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.5.4</span>
            <span className='tc-content body'>any content on the Open System;</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.5.5</span>
            <span className='tc-content body'>
      obtaining any licences for materials on the Open System, for any Buyer
      Materials, or for any Supplier Materials; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.5.6</span>
            <span className='tc-content body'>
      advising on any aspect of the Supplier or Buyer&#39;s compliance with
      Applicable Law, including Data Protection Law.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.6</span>
            <span className='tc-content body'>
      Unless otherwise agreed in writing, the Open System is provided on an “as
      is” basis. NEPO does not warrant:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.6.1</span>
            <span className='tc-content body'>
      that access to the Open System will be uninterrupted, timely, or
      error-free; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.6.2</span>
            <span className='tc-content body'>
      that the Open System will not contain any Virus or Vulnerability.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.7</span>
            <span className='tc-content body'>NEPO has no liability to the Supplier for any</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.7.1</span>
            <span className='tc-content body'>indirect, special or consequential loss; or</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.7.2</span>
            <span className='tc-content body'>
      any loss of anticipated savings, profits, business, revenue, data or
      goodwill howsoever arising (whether direct or indirect).
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.8</span>
            <span className='tc-content body'>
      In no event shall NEPO be liable to the Supplier for any direct loss,
      indirect loss or damages incurred by the Supplier arising from the use of
      the Open System caused by:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.8.1</span>
            <span className='tc-content body'>
      a Force Majeure Event that affects the availability and/or performance of
      the Open System;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.8.2</span>
            <span className='tc-content body'>incorrect use by Supplier of the Open System;</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.8.3</span>
            <span className='tc-content body'>
      connectivity failures in respect of the equipment used by the Supplier;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.8.4</span>
            <span className='tc-content body'>
      breakdown of the Open System for a period not exceeding thirty (30)
      Business Days.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.9</span>
            <span className='tc-content body'>The Supplier acknowledges and accepts that:</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.9.1</span>
            <span className='tc-content body'>
      NEPO reserves the right to interrupt and/or suspend the availability of
      the Open System and/or revoke access at any time by notice to the Supplier
      without incurring any liability; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.9.2</span>
            <span className='tc-content body'>
      NEPO does not accept any liability for any information or links on the
      Open System that may refer the Supplier to external sources outside of
      NEPO&#39;s reasonable control.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.10</span>
            <span className='tc-content body'>
      The Supplier accepts that computer systems in general cannot be free of
      errors or interruptions and agrees that their existence does not
      constitute a breach of these Supplier Terms.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.11</span>
            <span className='tc-content body'>
      The Supplier shall indemnify NEPO in full against all liabilities, costs,
      expenses, damages and losses (including any direct, indirect or
      consequential losses, loss of profit, loss of reputation and all interest,
      penalties and legal costs (calculated on a full indemnity basis) and all
      other reasonable professional costs and expenses) suffered or incurred by
      NEPO arising out of or in connection with any breach of these Supplier
      Terms by the Supplier.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.12</span>
            <span className='tc-content body'>
        To the extent permitted by law, NEPO&#39;s obligations in the Agreement
        replace all other warranties with respect to the Open System, either
        express or implied, including but not limited to any implied warranties
        of satisfactory quality or fitness for any particular purpose.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.13</span>
            <span className='tc-content body'>
        Nothing in the Agreement will exclude or limit a party&#39;s liability for:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.13.1</span>
            <span className='tc-content body'>
      death or personal injury caused by its negligence, or the negligence of
      its employees, agents or subcontractors (as applicable);
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.13.2</span>
            <span className='tc-content body'>
      fraud or fraudulent misrepresentation or wilful default; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>11.13.3</span>
            <span className='tc-content body'>
      any matter in respect of which it would be unlawful to exclude or restrict
      liability.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.14</span>
            <span className='tc-content body'>Subject to clause 11.13, the maximum liability of NEPO for each dispute or claim, or series of
      connected disputes or claims, arising out of, or in connection with, this
      Agreement, its subject matter or formation shall be £5,000.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>12.</span>
            <span className='tc-title body bold xx-large-title'>CONFIDENTIALITY</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>12.1</span>
            <span className='tc-content body'>NEPO and the Supplier agrees to:</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>12.1.1</span>
            <span className='tc-content body'>
      treat all Confidential Information as confidential and safeguard it
      accordingly;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>12.1.2</span>
            <span className='tc-content body'>
      not disclose any Confidential Information to any third party without the
      prior written consent of the other party, except to such persons and to
      such extent as may be necessary for the performance of the Agreement; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>12.1.3</span>
            <span className='tc-content body'>
      not use any Confidential Information for any purposes beyond the
      performance of the Agreement.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>12.2</span>
            <span className='tc-content body'>
      The Supplier shall take all necessary precautions to ensure that all
      Confidential Information obtained via use of the Open System is:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>12.2.1</span>
            <span className='tc-content body'>
      given only to such employee, consultant, sub-contractor, professional
      advisor or agent engaged as is strictly necessary for the performance of
      the Agreement and then only to the extent necessary for each employee,
      consultant, sub-contractor, professional advisor or agent&#39;s activities in
      the performance of the Agreement; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>12.2.2</span>
            <span className='tc-content body'>
      is treated as confidential and not disclosed (without prior written
      approval) or used by any employee, consultant, sub-contractor,
      professional advisor or agent otherwise than for the purposes of the
      Agreement.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>12.3</span>
            <span className='tc-content body'>
      Where it is considered necessary in the opinion of the Buyer, the Supplier
      shall ensure that any employee, consultant, sub-contractor, professional
      advisor or agent of the Supplier sign a confidentiality undertaking before
      commencing activity relating to the performance of the Agreement.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>12.4</span>
            <span className='tc-content body'>The provisions of this clause 12 shall not apply to any information which:</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>12.4.1</span>
            <span className='tc-content body'>
      is or becomes public knowledge (otherwise than by breach of the
      obligations of confidentiality under the Agreement);
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>12.4.2</span>
            <span className='tc-content body'>
      is in the possession of the party concerned, without restriction as to its
      disclosure, before receiving it from the disclosing party;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>12.4.3</span>
            <span className='tc-content body'>
      is received from a third party who lawfully acquired it and who is under
      no obligation restricting its disclosure; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>12.4.4</span>
            <span className='tc-content body'>
      is independently developed without access to the Confidential Information.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>12.5</span>
            <span className='tc-content body'>
      Nothing in these Supplier Terms shall prevent NEPO or the Buyer from
      disclosing any Confidential Information which is required to be disclosed
      by an order of court or other tribunal or required to be disclosed in
      accordance with any law, statute, proclamation, by-law, directive,
      decision, regulation, rule, order, notice, rule of court, delegated or
      subordinate legislation, or parliamentary obligation.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>12.6</span>
            <span className='tc-content body'>
      Nothing in these Supplier Terms shall prevent NEPO or the Buyer from
      disclosing any information for the examination and certification of the
      Buyer&#39;s accounts; or any examination pursuant to Section 6(1) of the
      National Audit Act 1983 of the economy, efficiency and effectiveness with
      which the Buyer has used its resources.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>12.7</span>
            <span className='tc-content body'>
      Nothing in these Supplier Terms shall prevent NEPO or the Buyer from
      disclosing any information obtained from the Supplier:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>12.7.1</span>
            <span className='tc-content body'>
      to any department, office or agency of the Crown; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>12.7.2</span>
            <span className='tc-content body'>
        to any person engaged in providing any services to the Buyer for any
        purpose relating to or ancillary to the Agreement, provided that in
        disclosing information under this clause 12.7.2 the
        Buyer discloses only the information which is necessary for the
      purpose concerned and requires that the information is treated in
      confidence and that a confidentiality undertaking is given where
      appropriate.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>12.8</span>
            <span className='tc-content body'>
      Nothing in these Supplier Terms shall prevent either party from using any
      techniques, ideas or know-how gained during the performance of the
      Agreement during its normal business, to the extent that this does not
      result in a disclosure of Confidential Information or an infringement of
      Intellectual Property Rights.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>12.9</span>
            <span className='tc-content body'>If the Supplier fails to comply with this clause 12, NEPO reserves the right to terminate the Agreement by notice in writing
      and with immediate effect.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>12.10 </span>
            <span className='tc-content body'>
        The Supplier acknowledges that the Buyer is subject to the requirements
        of the Freedom of Information Act 2000 and the Environmental Information
        Regulations 2004 and shall assist and cooperate with NEPO and the Buyer
        in meeting the Buyer&#39;s obligations thereunder. The Supplier shall use
        all reasonable endeavours to ensure the observance of the provisions of
        this clause 12.10 by its sub-contractors.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>12.11</span>
            <span className='tc-content body'>
      For the avoidance of doubt, the fact that NEPO is providing, or has
      provided, access to the Open System to the Supplier does not constitute
      Confidential Information.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>12.12</span>
            <span className='tc-content body'>The obligations in this clause 12 shall continue beyond the termination or expiry of the Agreement for a
      period of five (5) years.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>13.</span>
            <span className='tc-title body bold xx-large-title'>termination</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>13.1</span>
            <span className='tc-content body'>
      The Supplier may terminate the Agreement immediately without liability to
      NEPO by giving notice via the Open System to NEPO if the Supplier no
      longer requires access and use of the Open System to participate in the
      procurement activities managed within the Open System.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>13.2</span>
            <span className='tc-content body'>
      Either party may terminate the Agreement immediately without liability to
      the other party by giving notice in writing to the other party at any time
      if:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>13.2.1</span>
            <span className='tc-content body'>
      the other party commits a material breach of any of the terms of the
      Agreement which, in the reasonable opinion of the terminating party,
      cannot be remedied;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>13.2.2</span>
            <span className='tc-content body'>
      the other party commits a material breach of any of the terms of the
      Agreement which can be remedied, but fails to do so within thirty (30)
      days of being notified in writing to do so; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>13.2.3</span>
            <span className='tc-content body'>
      an Insolvency Event occurs in relation to the other party.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>13.3</span>
            <span className='tc-content body'>
      NEPO may also terminate the availability of any element of the Open System
      provided in connection with a Third Party Service Provider on written
      notice to the Supplier in the event that NEPO or Supplier&#39;s agreement with
      the relevant Third Party Service Provider is terminated or expires.
      Subject to any applicable obligations of confidentiality, each party will
      provide the other with as much notice of the occurrence (or anticipated
      occurrence) of any such circumstances as is reasonably possible such that
      the parties can take reasonable steps to mitigate the impact thereof and,
      where agreed in writing, put alternative arrangements in place.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>13.4</span>
            <span className='tc-content body'>
      The ending of the Agreement will not affect any of the rights, remedies,
      obligations or liabilities of the parties that have accrued up to the date
      on which it ends, including the right to claim damages for any breach of
      the Agreement which existed at or before the date on which it ends.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>13.5</span>
            <span className='tc-content body'>At the end of the Agreement for any reason: </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>13.5.1</span>
            <span className='tc-content body'>
      Supplier Materials in the Open System will, as far as technically or
      practically possible, be deleted, save that NEPO may retain (subject to
      the confidentiality provisions of the Agreement) any copies of data that
      have been created in electronic form:

                <p className='tc-list'>
                    <span className='tc-content body'>(a)</span>
                    <span className='tc-content body'>for regulatory or legal reasons; or</span>
                </p>
                <p className='tc-list'>
                    <span className='tc-content body'>(b)</span>
                    <span className='tc-content body'>under standard backup and archival procedures; or </span>
                </p>
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>13.5.2</span>
            <span className='tc-content body'>if requested by the Supplier, NEPO will (at the Supplier&#39;s reasonable
        cost) make available to the Supplier the Supplier Materials in the Open
        System. For the avoidance of doubt, this clause 13.5.2
      shall not apply in respect of any Supplier Materials in the possession of
      a Third Party Service Provider.</span>
        </p>

        <p className='tc-list'>
            <span className='tc-content body'>13.6</span>
            <span className='tc-content body'>
      Any provision of the Agreement that expressly or by implication is
      intended to come into or continue in force on or after the end of the
      Agreement will remain in full force and effect.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'> 14.</span>
            <span className='tc-title body bold xx-large-title'>THIRD PARTY RIGHTS</span>
        </p>
        <p>
            <span className='tc-content body'>
      These Supplier Terms shall not create any rights that are enforceable by
      anyone other than NEPO and the Supplier, save for any rights that may be
      enforced by the Buyer.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>15.</span>
            <span className='tc-title body bold xx-large-title'>ASSIGNMENT</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>15.1</span>
            <span className='tc-content body'>
      NEPO may assign, transfer, mortgage, charge, declare a trust over or deal
      in any other manner with any of its rights and obligations under the
      Agreement.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>15.2</span>
            <span className='tc-content body'>
      NEPO may also subcontract its rights and obligations under the Agreement
      without the prior written consent of the Supplier, provided that NEPO will
      be responsible for the acts, default or neglect of any subcontractor as if
      they were the acts, defaults or neglect of NEPO.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>15.3</span>
            <span className='tc-content body'>
      The Supplier may not assign, transfer, mortgage, charge or subcontract any
      of its rights or obligations under the Agreement without NEPO&#39;s prior
      written consent (not to be unreasonably withheld or delayed).
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>16.</span>
            <span className='tc-title body bold xx-large-title'>waiver</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>16.1</span>
            <span className='tc-content body'>
      Failure or delay by either party to exercise any right or remedy provided
      under the Agreement or by law will not represent a waiver of such right or
      remedy, nor shall it prevent or restrict the further exercise of that or
      any other right or remedy and will not affect the validity of the
      Agreement.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>17.</span>
            <span className='tc-title body bold xx-large-title'>SEVERABILITY</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>17.1</span>
            <span className='tc-content body'>
      If any provision or part-provision of the Agreement is or becomes invalid,
      illegal or unenforceable, it will be deemed modified to the minimum extent
      necessary to make it valid, legal and enforceable. If such modification is
      not possible, the relevant provision or part-provision will be deemed
      deleted. Any modification to or deletion of a provision or part-provision
      under this clause will not affect the validity and enforceability of the
      rest of the Agreement.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>18.</span>
            <span className='tc-title body bold xx-large-title'>NOTICES </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>18.1</span>
            <span className='tc-content body'>Subject to clause 7.8, any notice or written agreement under the Agreement may be given by:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>18.1.1</span>
            <span className='tc-content body'>
      mail or courier to the other party&#39;s authorised representative at any
      address shown in the Agreement, or to any other address as one party has
      notified the other of, and will be valid on the date of recorded receipt;
      or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>18.1.2</span>
            <span className='tc-content body'>
      email to the email address of the other party&#39;s authorised representative
      and will be valid at the time of sending unless the email system has
      generated an unsuccessful transmission or unsuccessful delivery report.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>19.</span>
            <span className='tc-title body bold xx-large-title'>FORCE MAJEURE</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>19.1</span>
            <span className='tc-content body'>
      Except for the obligation to make payment properly due, neither party will
      be liable for delays or failures in performing its obligations under this
      Agreement caused by a Force Majeure Event. The affected party must
      promptly give the other notice of any such delay or failure and use
      reasonable endeavours to mitigate the delay or failure.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>20.</span>
            <span className='tc-title body bold xx-large-title'>ANTI-CORRUPTION AND ANTI-BRIBERY</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>20.1</span>
            <span className='tc-content body'>
      The Supplier represents and warrants that the Supplier and its personnel
      have not bribed in connection with the Agreement.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>20.2</span>
            <span className='tc-content body'>
      The Supplier warrants and undertakes that the Supplier shall not, and
      shall procure that its personnel shall not, bribe in connection with the
      Agreement or its performance.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>20.3</span>
            <span className='tc-content body'>
      The Supplier shall adopt, implement, maintain, enforce and update (as
      necessary) adequate policies designed to prevent bribery from
      occurring. The Supplier shall provide adequate and regular training
      to Supplier personnel in order to ensure an understanding of its policy
      and procedures and their obligations arising from it on a continuing
      basis.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      20.4
            </span>
            <span className='tc-content body'>
      The Supplier shall notify NEPO immediately in writing upon becoming aware
      of, or suspecting, any failure to comply with any provisions of this
      clause 20.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      20.5
            </span>
            <span className='tc-content body'>
      If Supplier personnel bribe in connection with the Agreement, without
      prejudice to NEPO&#39;s other rights or remedies under the Agreement or under
      Applicable Law, the Supplier shall promptly upon request by NEPO remove or
      procure the removal of the relevant person who has bribed from all
      involvement in connection with the performance of the Agreement and take
      such other action as NEPO reasonably requires for the purpose of remedying
      or preventing the future occurrence of such activity.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      20.6
            </span>
            <span className='tc-content body'>
      NEPO may treat the Supplier&#39;s failure to comply with this clause 20
      as a material breach of the Agreement.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>21.</span>
            <span className='tc-title body bold xx-large-title'>publicity</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>21.1</span>
            <span className='tc-content body'>
        During and after the term of the Agreement, NEPO shall be entitled to
        publicise, announce or otherwise communicate:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>21.1.1</span>
            <span className='tc-content body'>the existence of the Agreement; and/or</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>21.1.2</span>
            <span className='tc-content body'>
        that it is providing, or has provided, access to the Open System to the
        Supplier.
            </span>
            <span>.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      21.2
            </span>
            <span className='tc-content body'>
      The Supplier hereby grants NEPO a fully paid-up, perpetual, worldwide,
      non-exclusive, royalty-free licence to use the Supplier&#39;s name and/or logo
      (including any trade mark) for the purposes described in clause 9.9 and clause 21.1.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>22.</span>
            <span className='tc-title body bold xx-large-title'>UPDATES</span>
            <span> </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>22.1</span>
            <span className='tc-content body'>
        The Supplier acknowledges that NEPO reserves the right to vary or amend
        these Supplier Terms by publication of a notification or message on the
        Open System or through an email or letter to the Supplier, using the
        email address or postal address provided at registration.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>22.2</span>
            <span className='tc-content body'>Any update pursuant to clause  22.1 shall be deemed to be accepted by the Supplier unless the Helpdesk
      receives, within ten (10) days of the date of the notice, a written
      communication from the Supplier rejecting the update. For the avoidance of
      doubt, the Supplier’s continued use of the Open System after said ten (10)
      day period shall represent its unconditional acceptance of the update in
      its entirety.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>22.3</span>
            <span className='tc-content body'>
      The Supplier shall be entitled to terminate the Agreement with immediate
      effect if it gives notice pursuant to clause 22.2.</span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>23.</span>
            <span className='tc-title body bold xx-large-title'>LAW AND JURISDICTION</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      23.1
            </span>
            <span className='tc-content body'>
      The parties irrevocably agree that the Agreement and any dispute or claim
      arising out of or in connection with it or its subject matter or formation
      (including non-contractual disputes or claims) shall be governed by and
      construed in accordance with the law of England and Wales and that the
      courts of England and Wales will have exclusive jurisdiction to settle any
      such dispute or claim.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-title body bold xx-large-title'>24.</span>
            <span className='tc-title body bold xx-large-title'>GENERAL</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      24.1
            </span>
            <span className='tc-content body'>
      Nothing contained either inside or outside the Agreement shall be taken as
      constituting:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>24.1.1</span>
            <span className='tc-content body'>
      a contract, including any collateral contract, or any contractual term
      between the Buyer or NEPO and any other party as to the procurement of any
      goods, services or works, including any potential service provider; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>24.1.2</span>
            <span className='tc-content body'>
      any representation by or on behalf of the Buyer or NEPO.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      24.2
            </span>
            <span className='tc-content body'>
      The Buyer shall not be committed to any course of action because of:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>24.2.1</span>
            <span className='tc-content body'>
      issuing any invitation to participate in a procurement activity;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>24.2.2</span>
            <span className='tc-content body'>
      an invitation to submit any proposal in respect of a procurement activity;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>24.2.3</span>
            <span className='tc-content body'>
      communicating with a Supplier in respect of a procurement activity; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>24.2.4</span>
            <span className='tc-content body'>
      any other communication between the Buyer and any other party.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      24.3
            </span>
            <span className='tc-content body'>The Supplier accepts and acknowledges that:</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>24.3.1</span>
            <span className='tc-content body'>
      whilst every care has been taken to ensure that the data and information
      contained within the procurement activity is valid, the Buyer and NEPO
      shall not accept liability for the accuracy, adequacy or completeness of
      such information and data;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>24.3.2</span>
            <span className='tc-content body'>
      that by issuing an invitation to participate in any procurement activity
      the Buyer shall not be bound to accept any response and the Buyer reserves
      the right to award a contract for some but not all of the requirements
      specified or not to award any contract at all; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>24.3.3</span>
            <span className='tc-content body'>
      the Buyer and NEPO shall not accept any liability for any costs or
      expenses incurred by a Supplier responding to any invitation to
      participate in a procurement activity.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      24.4
            </span>
            <span className='tc-content body'>The Supplier acknowledges that:</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>24.4.1</span>
            <span className='tc-content body'>
      for the entire duration of the procurement activity, any applicable dates
      and times shall be those displayed on the Open System; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>24.4.2</span>
            <span className='tc-content body'>
      any Helpdesk recordings via telecommunication equipment shall represent a
      full and accurate account of the facts and circumstances reflected in
      those recordings.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      24.5
            </span>
            <span className='tc-content body'>
      The Supplier acknowledges that the Open System will not allow it to view
      the identity of the other Suppliers during a procurement activity.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      24.6
            </span>
            <span className='tc-content body'>
      Nothing in the Agreement is intended to, or will be deemed to, establish
      any partnership or joint venture between any of the parties, constitute
      any party the agent of another party, or authorise any party to make or
      enter into any commitments for or on behalf of any other party. Each party
      confirms it is acting on its own behalf and not for the benefit of any
      other person.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      24.7
            </span>
            <span className='tc-content body'>
      The Agreement does not give rise to any rights under the Contracts (Rights
      of Third Parties) Act 1999.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      24.8
            </span>
            <span className='tc-content body'>
      The parties can rescind, change or end the Agreement or any term of it
      without the consent of any third party.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>
      24.9
            </span>
            <span className='tc-content body'>
      The Agreement constitutes the entire agreement between the parties and
      extinguishes all previous agreements, arrangements, promises, assurances,
      warranties, representations and understandings between them, whether
      written or oral, relating to its subject matter. Neither party has
      relied on and will have no remedies in respect of any statement,
      representation, assurance or warranty (whether made innocently or
      negligently) that is not set out in the Agreement. The terms and
      conditions of any purchase orders, acknowledgements or similar
      documentation put forward by the Supplier have no effect whether or not
      NEPO supplies the Open System without expressly rejecting such terms and
      conditions.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>24.10</span>
            <span className='tc-content body'>Subject to clause 22
      , no variation of this Agreement shall be effective unless it is agreed in
      writing and signed by the parties (or their authorised representatives).
            </span>
        </p>
        <p className='tc-title-align tc-content'>
            <span className='tc-title body bold xx-large-title'>SCHEDULE 1</span>
        </p>
        <p className='tc-title-align tc-content'>
            <span className='tc-title body bold xx-large-title'>DEFINITIONS AND INTERPRETATION</span>
        </p>
        <p className='tc-content'>
            <span className='tc-content body'>1.</span>
            <span className='tc-content body'>
      In these Supplier Terms, the definitions and rules of interpretation in
      this Schedule 1 apply:
            </span>
        </p>
        <table
            border={0}
            cellSpacing={0}
            cellPadding={0}
            style={{ marginLeft: 'auto', marginRight: 'auto', borderCollapse: 'collapse' }}
        >
            <tbody>
                <tr >
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Acceptable Use Policy </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              the restrictions and requirements relating to access to and use of
              the Open System as set out in Schedule 2.</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Agreement </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means the agreement for the provision of the Open System
              comprising of these Supplier Terms.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Applicable Law </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means any and all laws, legislation, statutes, regulations,
              bye-laws, decisions, notices, orders, rules (including any rules
              or decisions of court), local government rules, statutory
              instruments or other delegated or subordinate legislation and any
              directions and codes of practice issued pursuant to any
              legislation that are applicable to the Open System from time to
              time, including Data Protection Law.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Business Day</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means any day excluding Saturdays, Sundays and other days on which
              banks are not generally open for a full range of business in
              London.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Buyer</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means an organisation that conducts procurement activities via the
              Open System.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Buyer Materials</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means any materials, information, specifications and data supplied
              by the Buyer in connection with its use of the Open System.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Claim </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>has the meaning given to it in clause 9.11.</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}>
                        <p className='tc-content body'>
                            <span className='tc-content body'>Confidential Information</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p

                            style={{ marginBottom: '8.0pt', lineHeight: '107%' }}
                        >
                            <span className='tc-content body'>
              means all information which is identified in the Agreement,
              or at the time of disclosure, by the disclosing party as being
              confidential information, or which should be reasonably regarded
              as the confidential information of the disclosing party, including
              information relating to the business, finances or trade secrets of
              a party or its Third Party Service Providers.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Data Processing Details </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p

                            style={{ marginBottom: '8.0pt', lineHeight: '107%' }}
                        >
                            <span className='tc-content body'>
              means the description of the personal data processing activities
              undertaken pursuant to the Agreement, as set out in Schedule 3.</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Data Protection Law </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p

                            style={{ marginBottom: '8.0pt', lineHeight: '107%' }}
                        >
                            <span className='tc-content body'>
              means all applicable laws and regulations, in each case pertaining
              to the security, confidentiality, protection or privacy of
              personal data, as amended or re-enacted from time to time,
              including (without limitation and to the extent applicable) the
              GDPR.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body' >
                            <span className='tc-content body'>Force Majeure Event</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p
                            style={{ marginBottom: '8.0pt', lineHeight: '107%' }}
                        >
                            <span className='tc-content body'>
              means any circumstance not within a party&#39;s reasonable control
              including, without limitation acts of God, flood, drought,
              earthquake or other natural disaster, pandemic, civil commotion or
              riots, imposition of sanctions, imposition of tariffs, embargo, or
              breaking off of diplomatic relations, failure of a utility service
              or transport or telecommunication network, including any such
              event which impacts NEPO&#39;s supply chain and non-performance by
              NEPO&#39;s suppliers, subcontractors or Third Party Service Providers.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body' >
                            <span className='tc-content body'>GDPR</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p

                            style={{ marginBottom: '8.0pt', lineHeight: '107%' }}
                        >
                            <span className='tc-content body'>
              means the European General Data Protection Regulation (EU
              2016/679) as it forms part of the law of England and Wales,
              Scotland and Northern Ireland by virtue of section 3 of the
              European Union (Withdrawal) Act 2018.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body' >
                            <span className='tc-content body'>Helpdesk</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p

                            style={{ marginBottom: '8.0pt', lineHeight: '107%' }}
                        >
                            <span className='tc-content body'>
              means the facility provided by NEPO to handle support queries and
              issues raised by Suppliers and Buyers.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body' >
                            <span className='tc-content body'>Insolvency Event </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p

                            style={{ marginBottom: '8.0pt', lineHeight: '107%' }}
                        >
                            <span className='tc-content body'>
              means each and any of the following in relation to a party:
                            </span>
                        </p>
                        <p className='tc-table-list'>
                            <span className='tc-content body'>(a)</span>
                            <span className='tc-content body'>
              any action (corporate or otherwise), legal proceedings or other
              procedure or step is taken by any person in any jurisdiction in
              relation to or with a view to: (i) the winding up, dissolution,
              administration or reorganisation (by way of voluntary arrangement,
              scheme of arrangement or otherwise) of a party (except that no
              right to terminate will arise in respect of any procedure
              commenced for the purpose of a solvent amalgamation or
              reconstruction); (ii) the appointment of a liquidator, trustee in
              bankruptcy, judicial custodian, compulsory manager, receiver,
              administrative receiver, administrator, nominee, supervisor or
              similar officer in respect of a party or any of its assets;
              (iii) the enforcement of any security over any assets of a party;
              or (iv) the expropriation, attachment, sequestration, distress or
              execution over or affecting any material asset of a party;
                            </span>
                        </p>
                        <p className='tc-table-list'>
                            <span className='tc-content body'>(b)</span>
                            <span className='tc-content body'>
              a party is unable to pay its debts as they fall due or is
              insolvent; or
                            </span>
                        </p>
                        <p className='tc-table-list'>
                            <span className='tc-content body'>(c)</span>
                            <span className='tc-content body'>
              a party enters into a composition or arrangement with its
              creditors or any class of them,
                            </span>
                        </p>
                        <p className='tc-content'>
                            <span className='tc-content body'>
              or an insolvency event occurs to a party in any other jurisdiction
              which is analogous to those set out above.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body' >
                            <span className='tc-content body'>Intellectual Property Rights</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>
              means patents, trade and service marks, trade names, design
              rights, topography rights, copyright (including rights in computer
              software and moral rights), database rights, rights in know-how
              and other intellectual property rights, in each case whether
              registered or unregistered including applications for the grant of
              any of the foregoing and all rights or forms of protection which
              have a similar effect anywhere in the world.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>NEPO</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means The Association of North East Councils Limited (trading as
              NEPO), whose registered office is at Sunderland City Council, City
              Hall, Plater Way, Sunderland, Tyne and Wear, SR1 3AA.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>NEPO Materials </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means any materials, information, specifications and data supplied
              by NEPO in connection with the Open System.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Open System </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means the electronic system provided by NEPO for Suppliers to
              participate and interface with Buyers during electronic
              procurement activity.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Supplier</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means an organisation that has accepted, or been deemed to accept,
              these Supplier Terms.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body' >
                            <span className='tc-content body'>Supplier Materials</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means any materials, information, specifications, data and
              metadata supplied or directly and/or indirectly generated by the
              Supplier in connection with the use of the Open System.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Supplier Terms </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>means these standard terms and conditions.</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Third Party Service Providers </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means NEPO&apos;s suppliers and partners from time to time.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Third Party Services</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>has the meaning given to it in clause 11.4.</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Third Party Terms</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means the terms and conditions applicable to use of any services
              and/or modules of the Open System which are made available in
              connection with Third Party Service Providers and which are
              notified to the Supplier by NEPO from time to time.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body' >
                            <span>User</span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means an employee, officer, contractor or agent of the Supplier
              that has registered and has been granted access to the Open
              System.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Virus </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means anything or device (including any software, code, file or
              programme) which may (i) prevent, impair or otherwise adversely
              affect the operation of any software, hardware, network or other
              service or device, (ii) prevent, impair or otherwise adversely
              affect access to, or the operation of, any programme or data,
              including the reliability of any programme or data, or (iii)
              adversely affect the user experience, including worms, trojan
              horses, viruses and other similar things or devices.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width={146}
                        valign='top'
                        style={{ width: '109.3pt', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p className='tc-content body'>
                            <span className='tc-content body'>Vulnerability </span>
                        </p>
                    </td>
                    <td
                        width={427}
                        valign='top'
                        style={{ width: '4.45in', padding: '0in 5.4pt 0in 5.4pt' }}
                    >
                        <p>
                            <span className='tc-content body'>
              means any weakness in the computational logic (including in the
              source code) found in software and/or hardware components of the
              Open System that, when exploited, results in a negative impact to
              confidentiality, integrity or availability.
                            </span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p className='tc-list'>
            <span className='tc-content body'>2.</span>
            <span className='tc-content body'>
      Unless the context requires otherwise, the singular includes the plural
      (and vice versa), and words denoting any gender include all genders.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>3.</span>
            <span className='tc-content body'>
      References to any statute or any section of any statute include any
      statutory amendment, modification or re-enactment and instruments and
      regulations under it in force from time to time, unless the contrary is
      stated. References to any rules, regulations, codes of practice or
      guidance include any amendments or revisions from time to
      time.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>4.</span>
            <span className='tc-content body'>A reference to:</span >
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>4.1</span>
            <span className='tc-content body'><span className='tc-bold'>writing</span> or <span className='tc-bold'>written</span> includes any method of reproducing words in
                a legible and non- transitory form, including email;</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>4.2</span>
            <span className='tc-content body'><span className='tc-bold'>include</span>
      , <span className='tc-bold'>includes</span>,<span className='tc-bold'> including</span> and <span className='tc-bold'>included</span> will be construed
      without limiting the general nature of the words that come before;
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>4.3</span>
            <span className='tc-content body'><span className='tc-bold'>indemnify </span>
 or <span className='tc-bold'>indemnifies </span>
      means on demand to indemnify and keep indemnified, and hold harmless, the
      party to be indemnified on an after tax basis; and
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>4.4</span>
            <span className='tc-content body'><span className='tc-bold'>end </span>
                <span>
      means to terminate or expire (as applicable) and <span className='tc-bold'>ended</span> and
                    <span className='tc-bold'> ending</span> will be construed accordingly.
                </span>
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>5.</span>
            <span className='tc-content body'>
      A <span className='bold'>person</span> includes a natural person, corporate or unincorporated
      body (whether or not having separate legal personality).
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>6.</span>
            <span className='tc-content body'>
      References to a law of the European Union include a reference to that law
      as incorporated into the laws of the United Kingdom at any time before or
      after the United Kingdom ceases to be a member state of the European
      Union.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>7.</span>
            <span className='tc-content body'>
      The headings to clauses are inserted for convenience only and shall not
      affect the interpretation or construction of these Supplier Terms.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>8.</span>
            <span className='tc-content body'>
      Any words following the terms
                <span className='bold'> including, include, in particular, for example</span> or any similar
      expression shall be interpreted as illustrative and shall not limit the
      sense of the words preceding those terms.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>9.</span>
            <span className='tc-content body'>
      The Schedules form part of these Supplier Terms and shall have effect as
      if set out in full in the body of these Supplier Terms. Any reference to
      these Supplier Terms includes the Schedules.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>10.</span>
            <span className='tc-content body'>
      References to clauses and Schedules are to the clauses and Schedules of
      these Supplier Terms and references to paragraphs are to paragraphs of the
      relevant Schedule.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>11.</span>
            <span className='tc-content body'>
      For the avoidance of doubt, in the event of any conflict or inconsistency
      between these Supplier Terms and any specifications, descriptions or
      illustrations of the Open System, these Supplier Terms shall take
      priority.
            </span>
        </p>
        <p className='tc-title-align tc-content'>
            <span className='tc-title body bold xx-large-title'>SCHEDULE 2</span>
        </p>
        <p className='tc-title-align tc-content'>
            <span className='tc-title body bold xx-large-title'>Acceptable Use Policy</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>1.</span>
            <span className='tc-content body'>
      The Supplier shall not, and shall ensure that no User shall, use the Open
      System to:
            </span>
        </p>
        <p className='tc-sub-list' >
            <span className='tc-content body'>(a)</span>
            <span className='tc-content body'>transmit or upload any
      material which is unlawful, hateful, obscene, libellous, threatening or
      defamatory;</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>(b)</span>
            <span className='tc-content body'> commit, encourage or enable
      a criminal offence to be committed;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>(c)</span>
            <span className='tc-content body'> conduct or encourage any
      activity that is not permitted under Applicable Law;
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>(d)</span>
            <span className='tc-content body'> transmit any spam; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>(e)</span>
            <span className='tc-content body'> prevent or restrict any
      other User from using or benefiting from the Open System.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>2.</span>
            <span className='tc-content body'>The Supplier shall ensure that each User:</span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>(a)</span>
            <span className='tc-content body'> keeps their login and
      password details confidential and does not share them with any other
      person or allow any other person to use them; and
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>(b)</span>
            <span className='tc-content body'> uses their log in details to
      conduct business on behalf of or with the Supplier only.
            </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>3.</span>
            <span className='tc-content body'>
      The Supplier shall not, and shall ensure that no User will:
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>(a)</span>
            <span className='tc-content body'> attempt to delete or modify
      any Intellectual Property Rights notice or other legal notice from the
      Open System; or
            </span>
        </p>
        <p className='tc-sub-list'>
            <span className='tc-content body'>(b)</span>
            <span className='tc-content body'> either through an API or
      otherwise, allow more than one user to access or use the Open System
      through a single login.
            </span>
        </p>

        <p className='tc-title-align tc-content'>
            <span className='tc-title body bold xx-large-title'>SCHEDULE 3</span>
        </p>
        <p className='tc-title-align tc-content'>
            <span className='tc-title body bold xx-large-title'>Data Processing Details</span>
        </p>
        <table
            className='tc-content'
            border={1}
            cellSpacing={0}
            cellPadding={0}
            width='100%'
            style={{ width: '100.0%', borderCollapse: 'collapse', border: 'none' }}
        >
            <tbody>
                <tr>
                    <td
                        width='6%'
                        valign='top'
                        style={{
                            width: '6.0%',
                            border: 'solid black 1.0pt',
                            background: '#D9D9D9',
                            padding: '0in 0in 0in 0in',
                        }}
                    >
                        <p>
                            <span>
                                <span className='tc-content body tc-bold tc-table-content'>(A)</span>
                            </span>
                        </p>
                    </td>
                    <td
                        width='32%'
                        valign='top'
                        style={{
                            width: '32.0%',
                            border: 'solid black 1.0pt',
                            borderLeft: 'none',
                            background: '#D9D9D9',
                            padding: '0in 0in 0in 0in',
                        }}
                    >
                        <p>
                            <span>
                                <span className='tc-content body tc-bold tc-table-content'>
                Subject matter, nature and purpose of the processing of Personal
                Data under the Agreement
                                </span>
                            </span>
                        </p>
                    </td>
                    <td
                        width='62%'
                        valign='top'
                        style={{
                            width: '62.0%',
                            border: 'solid black 1.0pt',
                            borderLeft: 'none',
                            padding: '0in 0in 0in 0in',
                        }}
                    >
                        <p>
                            <span>
                                <span className='tc-content body tc-bold tc-table-content'>Subject matter</span>
                            </span>
                        </p>
                        <p>
                            <span className='tc-content body tc-table-content'>
              The provision of the Open System, and related services, by NEPO to
              the Supplier.
                            </span>
                        </p>
                        <p>
                            <span>
                                <span className='tc-content body tc-bold tc-table-content'>Nature</span>
                            </span>
                        </p>
                        <p>
                            <span className='tc-content body tc-table-content'>
              Processing activities, such as storage, retrieval, analysing, data
              collection and data transfer will all be undertaken by NEPO.
                            </span>
                        </p>
                        <p>
                            <span>
                                <span className='tc-content body tc-bold tc-table-content'>Purpose</span>
                            </span>
                        </p>
                        <p>
                            <span className='tc-content body tc-table-content'>
              Personal data is processed in order to enable the Supplier to
              utilise the Open System, and related services, to respond to
              invitations from active Buyers to participate in the procurement
              activities managed within the Open System.
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width='6%'
                        valign='top'
                        style={{
                            width: '6.0%',
                            border: 'solid black 1.0pt',
                            borderTop: 'none',
                            background: '#D9D9D9',
                            padding: '0in 0in 0in 0in',
                        }}
                    >
                        <p>
                            <span>
                                <span className='tc-bold tc-content body'>
                (B)
                                </span>
                            </span>
                        </p>
                    </td>
                    <td
                        width='32%'
                        valign='top'
                        style={{
                            width: '32.0%',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid black 1.0pt',
                            borderRight: 'solid black 1.0pt',
                            background: '#D9D9D9',
                            padding: '0in 0in 0in 0in',
                        }}
                    >
                        <p>
                            <span>
                                <span className='tc-content body tc-bold tc-table-content'>
                Duration of the processing of Personal Data under the Agreement
                                </span>
                            </span>
                        </p>
                    </td>
                    <td
                        width='62%'
                        valign='top'
                        style={{
                            width: '62.0%',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid black 1.0pt',
                            borderRight: 'solid black 1.0pt',
                            padding: '0in 0in 0in 0in',
                        }}
                    >
                        <p>
                            <span className='tc-content body tc-table-content'>For the term of the Agreement.</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width='6%'
                        valign='top'
                        style={{
                            width: '6.0%',
                            border: 'solid black 1.0pt',
                            borderTop: 'none',
                            background: '#D9D9D9',
                            padding: '0in 0in 0in 0in',
                        }}
                    >
                        <p>
                            <span>
                                <span className='tc-content body tc-bold tc-table-content'>
                (C)
                                </span>
                            </span>
                        </p>
                    </td>
                    <td
                        width='32%'
                        valign='top'
                        style={{
                            width: '32.0%',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid black 1.0pt',
                            borderRight: 'solid black 1.0pt',
                            background: '#D9D9D9',
                            padding: '0in 0in 0in 0in',
                        }}
                    >
                        <p>
                            <span>
                                <span className='tc-content body tc-bold tc-table-content'>
                Type of Personal Data processed under the Agreement
                                </span>
                            </span>
                        </p>
                    </td>
                    <td
                        width='62%'
                        valign='top'
                        style={{
                            width: '62.0%',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid black 1.0pt',
                            borderRight: 'solid black 1.0pt',
                            padding: '0in 0in 0in 0in',
                        }}
                    >
                        <p>
                            <span>
                                <span className='tc-content body tc-bold tc-table-content'>Personal Data</span>
                            </span>
                        </p>
                        <p className='tc-content'>
                            <span className='tc-content body tc-table-content'>
              Contact data (e.g. name, address, email address, phone numbers).
                            </span>
                        </p>
                        <p className='tc-content'>
                            <span className='tc-content body tc-table-content'>Gender (e.g. male, female, non-binary). </span>
                        </p>
                        <p className='tc-content'>
                            <span className='tc-content body tc-table-content'>
              Professional information (e.g. job title, professional and regulatory
               registration details).
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td
                        width='6%'
                        valign='top'
                        style={{
                            width: '6.0%',
                            border: 'solid black 1.0pt',
                            borderTop: 'none',
                            background: '#D9D9D9',
                            padding: '0in 0in 0in 0in',
                        }}
                    >
                        <p>
                            <span>
                                <span className='tc-content body tc-bold tc-table-content'>
                (D)
                                </span>
                            </span>
                        </p>
                    </td>
                    <td
                        width='32%'
                        valign='top'
                        style={{
                            width: '32.0%',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid black 1.0pt',
                            borderRight: 'solid black 1.0pt',
                            background: '#D9D9D9',
                            padding: '0in 0in 0in 0in',
                        }}
                    >
                        <p>
                            <span>
                                <span className='tc-content body tc-bold tc-table-content'>
                Categories of data subjects of the Personal Data processed under
                the Agreement
                                </span>
                            </span>
                        </p>
                    </td>
                    <td
                        width='62%'
                        valign='top'
                        style={{
                            width: '62.0%',
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderBottom: 'solid black 1.0pt',
                            borderRight: 'solid black 1.0pt',
                            padding: '0in 0in 0in 0in',
                        }}
                    >
                        <p>
                            <span className='tc-content body tc-table-content'>
             Past and present employees and personnel of the Supplier.
                            </span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p className='tc-title-align tc-content'>
            <span className='tc-title body bold xx-large-title'>SCHEDULE 4</span>
        </p>
        <p className='tc-title-align tc-content'>
            <span className='tc-title body bold xx-large-title'>Subprocessors </span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>1.</span>
            <span className='tc-content body'>Amazon Web Services (hosting the Open System)</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>2.</span>
            <span className='tc-content body'>Accenture (maintaining the Open System)</span>
        </p>
        <p className='tc-list'>
            <span className='tc-content body'>3.</span>
            <span className='tc-content body'>
      UIPath (provider of the data migration tool that NEPO uses from time to
      time to pull data from third party systems to the Open System)
            </span>
        </p>
    </div>

</div>;

export default TermsAndConditions;
