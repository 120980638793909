import React, {
    useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import DocumentBuilder from '../../features/DocumentBuilder';
import documentManagementAPIs from '../../services/document-management.service';
import gatewayAPIs from '../../services/gateway.service';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';

const EditSelectionQuestionnaire = () => {
    const [initialState, setInitialState] = useState({});
    const history = useHistory();

    const fetchData = async () => {
        const responseData = await documentManagementAPIs.getDefaultTemplate();
        if (responseData.status === 200) {
            const questionnaire = responseData.data[0];
            const preparedData = helperFunctions.prepareDocumentBuilderState(
                'questionnaire', questionnaire, questionnaire.templateID,
            );
            setInitialState(preparedData);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const handleSubmit = async (data, type) => {
        // const payload = {
        //     title: data.details.name,
        // };

        const sections = [];
        data.sections.forEach((section) => {
            const sectionData = {
                name: section.name,
                description: section.description,
            };
            const questions = [];
            section.sectionItems.forEach((question) => {
                const questionItem = {
                    ...question,
                    questionAnswer: question.questionAnswer,
                    supportingAnswerDocuments: question.supportingAnswerDocuments,
                };
                questions.push(questionItem);
            });
            sectionData.questions = questions;
            sections.push(sectionData);
        });

        const payload = {
            ...data.details,
            sections,
        };
        delete payload.isPublic;
        delete payload.isDisabled;
        delete payload.templateStatus;
        delete payload.shareQuestionnaire;
        delete payload.shareQuestionnaireWithEveryone;
        delete payload.accountIDs;

        // payload.sections = sections;
        let response;
        if (initialState?.details?.templateType === 'defaultSelectionQuestionnaire') {
            response = await gatewayAPIs.submitSelectionQuestionnaire(type, payload);
        } else {
            response = await gatewayAPIs.updateSelectionQuestionnaire(
                type, payload, initialState.templateId,
            );
        }

        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Questionnaire updated successfully.',
            });
            history.push('/dashboard');
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update questionnaire.',
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    return Object.keys(initialState).length > 0
        ? <DocumentBuilder
            documentMode={'answersqmode'}
            context='Questionnaire'
            type={'selectionQuestionnaire'}
            initialDocumentState={initialState}
            sectionLabel='Section'
            sectionItemLabel='Question'
            allowSectionReordering={false}
            allowSectionItemsReordering={false}
            sectionDetailsUserList={[]}
            handlePrimaryToolbarSubmitButton={handleSubmit}
        />
        : <></>;
};

export default EditSelectionQuestionnaire;
