import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Modal from '../../../components/Modal';
import Success from '../../../styles/images/joinCompanyAccount.png';

const JoinRequestConfirmed = ({
    closeModal,
}) => {
    const history = useHistory();

    const modalBody = <>

        <div className='verification-success-container'>
            <p className='title title-padding'>Request for registration is now with your company admin for approval.</p>
            <p className='caption'>We will email you as soon as you have access to your company’s account on Open.</p>
            <img src={Success} alt='Success' width='180' className='verification-success-img'></img>
        </div>
    </>;

    return <>
        <Modal
            open={true}
            closeModal={closeModal}
            size='medium'
            headerTitle='Join Company Account'
            handleMainActionBtnClick={() => history.push('/dashboard') }
            mainActionButtonTxt='Go to Home'
            closeButton={false}
            body={modalBody}
            helpOption={false}
        />
    </>;
};

JoinRequestConfirmed.propTypes = {
    closeModal: PropTypes.func,
};

export default JoinRequestConfirmed;
