const dpsConstants = {
    refreshHeaderText: 'Some questionnaires have changed!',
    refreshBannerText1: 'You now have the chance to review your response. ',
    refreshBannerText2: 'If you have already submitted your bid, clicking the ‘review’ button will withdraw your response and you will need to resubmit. ',
    refreshBannerText3: 'If you haven\'t started your response or it is in progress, you will need to review these changes. ',
    refreshBannerText4: 'Please click \'Review\' to see the updates. ',
    refreshBannerText5: 'The following questionnaires have changed:',

    ammendmentReason: 'Ammendment Reason: ',

    review: 'Review',

    green: 'green',
    amber: 'amber',
    red: 'red',

    viewResponse: 'View Response',
    readyToSubmit: 'Ready to Submit',
    actionNeeded: 'Action Needed',
    responseNotSubmitted: 'Response Not Submitted',
    readyToSubmitText: 'Ready to submit? Click ‘Submit All’ to submit your response.',
    responseSubmitted: 'Response Submitted',
    responseSubmittedText: 'Response submitted. Need to make changes? Click ‘Withdraw All’, update and resubmit.',

    selectLots: 'Select Lots',
    editLotsTitle: 'Click on ‘Edit Lots’ to add or remove lots from submission',
    editLotsLabel: 'Edit Lots',

    lotSubmitSuccess: 'Lots selected successfully',
    lotSelectError: 'Unable to select lots.',
    retrieveInfoError: 'Unable to retrieve information.',
    answerSubmitSuccess: 'Answers submitted successfully.',
    answerSubmitError: 'Unable to submit answers',
    answerWithdrawSuccess: 'Answers withdrawn successfully.',
    answerWithdrawError: 'Unable to withdraw answers.',
    answerRefreshSuccess: 'Answers refreshed successfully.',
    answerRefreshError: 'Unable to refresh answers.',

    complete: 'Complete',
    selectLotsLabel: 'This opportunity contains different lots, please select the lot(s) in which you\'d like to respond.',
    submitAnswers: 'Submit Answer(s)',
    findOutMoreMessage: 'Find out more about what you need to know before submitting a bid',
    deadlineMessage: 'The deadline for submission has passed.',

    errorIcon: 'error',
    successIcon: 'success',

    unableToDownloadDoc: 'Unable to download document.',

    withdrawAction: 'withdraw',
    submitAction: 'submit',

    exportAllLabel: 'Export All',

    projectPublishLevel: 'project',
    lotPublishLevel: 'lot',

    withdrawAllButtonLabel: 'Withdraw All',
    submitAllButtonLabel: 'Submit All',
};

export default dpsConstants;
