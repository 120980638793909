import React, { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import SupplierDashboard from '../SupplierDashboard';
import LocalAuthorityDashboard from '../LocalAuthorityDashboard';

import UserRoles from '../../features/Header/constants';

const Dashboard = () => {
    const authContext = useContext(AuthContext);
    const { authenticated, user } = authContext;

    if (authenticated) {
        if (user.accountType.toLowerCase() === UserRoles.SUPPLIER_ROLE) {
            return <SupplierDashboard />;
        }
        if (user.accountType.toLowerCase() === UserRoles.LOCAL_AUTHORITY_ROLE
            || user.accountType.toLowerCase() === UserRoles.NEPO_ROLE) {
            return <LocalAuthorityDashboard />;
        }
    }
    return <></>;
};

export default Dashboard;
