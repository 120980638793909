import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import prepareEvaluationSummary from './helpers';
import { getEvaluationUserPermissions } from '../../services/evaluation.service';
import TreeViewer from '../../components/TreeViewer/TreeViewer';
import './styles.scss';
import DisqualifyModal from './components/disqualifyModal/DisqualifyModal';
import ReasonDisqualifyModal from './components/reasonDisqualifyModal/ReasonDisqualifyModal';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import ToastConstants from '../../components/Alerts/Toast/constants';
import { getOpenNodes } from '../../services/localstorage.service';
import dpsConstants from './constants';
import {
    QUESTIONNAIRE_EVALUATE_URL,
    HANDLE_STATUS_UPDATE,
    HANDLE_DISQUALIFY,
    HANDLE_EVALUATE,
    HANDLE_SHOW_REASON_MODAL,
    publishAtLevel,
    COMPANY_NAME,
    ACCOUNT_ID,
} from '../../config/constants';

const SummaryBuyerDPS = ({
    publishLevel,
    currentResponses,
    previousResponses,
    readOnly,
    userSteeringRole,
    localStorageProperty,
    handleStatusUpdate,
    handleDisqualifySupplier,
    handleSaveOpenNodesInLocalStorage,
}) => {
    const [evaluationSummary, setEvaluationSummary] = useState();
    const [disqualifiedSupplier, setDisqualifiedSupplier] = useState();
    const [reasonDisqualify, setReasonDisqualify] = useState();
    const history = useHistory();

    const evaluateResponse = async (responseId) => {
        try {
            const response = await projectManagementAPIs.startEvaluation(responseId);
            if (response.status === 200) {
                history.push(`${QUESTIONNAIRE_EVALUATE_URL}${responseId}`);
            } else {
                Toast.fire({
                    icon: ToastConstants.error,
                    titleText: dpsConstants.UNABLE_TO_START_EVALUATION,
                });
            }
        } catch (error) {
            Toast.fire({
                icon: ToastConstants.error,
                titleText: dpsConstants.UNABLE_TO_START_EVALUATION,
            });
        }
    };

    const actions = {
        [HANDLE_STATUS_UPDATE]: (params) => handleStatusUpdate(...params),
        [HANDLE_DISQUALIFY]: (params) => setDisqualifiedSupplier(
            { [COMPANY_NAME]: params[0], [ACCOUNT_ID]: params[1] },
        ),
        [HANDLE_SHOW_REASON_MODAL]: (params) => setReasonDisqualify(...params),
        [HANDLE_EVALUATE]: (params) => evaluateResponse(...params),
    };

    const handleClickAction = (clickAction, ...params) => {
        if (actions[clickAction]) {
            actions[clickAction](params);
        }
    };

    const onDisqualifySupplier = (object) => {
        setDisqualifiedSupplier(undefined);
        handleDisqualifySupplier(object);
    };

    const onCloseDisqualifiedSupplierModal = () => {
        setDisqualifiedSupplier(undefined);
    };
    const onCloseReasonDisqualifiedSupplierModal = () => {
        setReasonDisqualify(undefined);
    };

    useEffect(() => {
        if (!currentResponses || !previousResponses) {
            return;
        }
        const userPermissions = getEvaluationUserPermissions(userSteeringRole);
        const openNodes = getOpenNodes(localStorageProperty);
        setEvaluationSummary(prepareEvaluationSummary(
            readOnly, userPermissions, currentResponses.responses,
            previousResponses, openNodes,
        ));
    }, [currentResponses, previousResponses]);


    return (
        <>
            {publishLevel === publishAtLevel.lot
            && evaluationSummary
            && Object.keys(evaluationSummary).length !== 0
        && <TreeViewer classAdded='evaluation-summary-tree' data={evaluationSummary} handleActionClick={handleClickAction}
            handleStatusNodeOnClick={handleSaveOpenNodesInLocalStorage} hasSearch={false}/>
            }
            {disqualifiedSupplier
                && <DisqualifyModal supplier={disqualifiedSupplier}
                    handleDisqualifySupplier={onDisqualifySupplier}
                    handleCloseModal={onCloseDisqualifiedSupplierModal}/>
            }
            {reasonDisqualify
            && <ReasonDisqualifyModal reason={reasonDisqualify}
                handleCloseModal={onCloseReasonDisqualifiedSupplierModal}/>}
        </>

    );
};

SummaryBuyerDPS.propTypes = {
    publishLevel: PropTypes.string,
    currentResponses: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    previousResponses: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    readOnly: PropTypes.bool,
    userSteeringRole: PropTypes.array,
    localStorageProperty: PropTypes.string,
    handleStatusUpdate: PropTypes.func,
    handleDisqualifySupplier: PropTypes.func,
    handleSaveOpenNodesInLocalStorage: PropTypes.func,

};

export default SummaryBuyerDPS;
