import React from 'react';
import PropTypes from 'prop-types';
import LabelledCheckBox from '../LabelledCheckBox';

const ListedCheckBox = ({
    id,
    label,
    renderAsRow,
    breakColumn,
    onChange,
    options,
    required,
}) => (<>
    {label
    && <label className='form-label title' id={`${id}-checkBox-label`}>
        {label}{required ? null : <span className='caption'> (Optional)</span>}
    </label>}
    <div className={`listed-checkBox checkBox-group ${renderAsRow ? 'row' : ''}`}>
        {options.map((option, idx) => (
            <><div key={idx} className={`form-selected-container ${option.disabled && 'disabled'}`}>
                <div className='title-and-pill'>
                    <label
                        className='form-label checkBox-label-primary title'
                        id={`${option.id}-label`}
                        htmlFor={`${option.id}-checkBox-btn`}>
                        {option.label}
                    </label>
                    {option.informationPill && option.informationPill}
                </div>
                <div>
                    <label className='form-label checkBox-label body-small'>
                        {option.subLabel}
                    </label>
                </div>
                <div>
                    <label className='form-label checkBox-label-grey caption-xSmall'>
                        {option.tertiaryLabel}
                    </label>
                </div>
                <div>
                    <>
                        {option?.additionalLabelTxt}
                    </>
                </div>
            </div>
            <LabelledCheckBox
                id={`${option.idx}-checkBox-btn`}
                onChange={(event) => onChange(event)}
                name={option.id}
                value={option.value}
                required={option.required || false}
                disabled={option.disabled || false}
                options={[{
                    name: option.id,
                    id: option.id,
                    checked: option.checked || option.disabled || false,
                    disabled: option.disabled || false,
                }]}
            />
            {/* <input
                type='checkbox'
                className='form-checkBox green'
                checked={option.checked}
                id={`${option.id}-checkBox-btn`}
                name={option.id}
                value={option.value}
                onChange={(event) => onChange(event)}
                required={option.required || false}
                disabled={option.disabled || false} /> */}
            </>
        ))}
    </div>
    {breakColumn && <div className='flex-break' />}
</>);

ListedCheckBox.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    breakColumn: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    renderAsRow: PropTypes.bool.isRequired,
    required: PropTypes.bool,
};

export default ListedCheckBox;
