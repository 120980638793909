import { isHTML } from '../services/html.service';

const formatHTMLToPlainText = (htmlStr) => {
    const { innerText } = new DOMParser().parseFromString(htmlStr, 'text/html').body;
    if (isHTML(innerText)) {
        return formatHTMLToPlainText(innerText);
    }
    return innerText;
};

export default formatHTMLToPlainText;
