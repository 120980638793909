import Homepage from '../screens/Homepage';
import LoginPage from '../screens/LoginPage';
import ContactUs from '../screens/ContactUs';
import BrowseOpportunities from '../screens/BrowseOpportunities';
import ContractsRegister from '../screens/ContractsRegister';
import BrowseSuppliers from '../screens/BrowseSuppliers';
// import TestScreen from '../screens/TestScreen';
// import OpportunityOverview from '../screens/OpportunityOverview';
import AwardedContractOverview from '../screens/AwardedContractOverview';
import CompanyDetails from '../screens/CompanyDetails';
import AccountDetails from '../screens/CreateAccount';
import SupplierOverview from '../screens/SupplierOverview';
import BrowseProjects from '../screens/BrowseProjects';
import CodesAndCategories from '../screens/CodesAndCategories';
import RegionsOfSupply from '../screens/RegionsOfSupply';
import AdditionalQuestions from '../screens/AdditionalQuestions';
import BrowseWorkflows from '../screens/BrowseWorkflows';
import BrowseProcurementRequests from '../screens/BrowseProcurementRequests';
import Dashboard from '../screens/Dashboard';
import ProcurementRequestOverview from '../screens/ProcurementRequestOverview';
import Calendar from '../screens/Calendar';
import BrowseSolutions from '../screens/BrowseSolutions';
import SolutionOverview from '../screens/SolutionOverview';
import MyProfile from '../screens/MyProfile';
import BrowseQuestionnaires from '../screens/BrowseQuestionnaires';
import DraftQuestionnaire from '../screens/DraftQuestionnaire';
import PreProcurementRequest from '../screens/PreProcurementRequest';
import CreateProcurementProject from '../screens/CreateProcurementProject';
import EvaluationSummary from '../screens/EvaluationSummary';
import BrowseContracts from '../screens/BrowseContracts';
import SelectSteeringGroup from '../screens/SelectSteeringGroup';
import ContractOverview from '../screens/ContractOverview';
import ViewQuestionnaire from '../screens/ViewQuestionnaire';
import ManageQuestionnaires from '../screens/ManageQuestionnaires';
import EditQuestionnaire from '../screens/EditQuestionnaire';
import AnswerQuestionnaire from '../screens/Response/AnswerQuestionnaire';
import ViewAnsweredQuestionnaire from '../screens/Response/ViewAnsweredQuestionnaire';
import BrowseSpends from '../screens/BrowseSpends';
import BrowseRebates from '../screens/BrowseRebates';
import CreateWorkflow from '../screens/CreateWorkflow';
import ViewWorkflow from '../screens/ViewWorkflow';
import EditWorkflow from '../screens/EditWorkflow';
import EditProjectWorkflow from '../screens/EditProjectWorkflow';
import ProjectOverview from '../screens/ProjectOverview';
import CreateNotice from '../screens/CreateNotice';
import DraftContract from '../screens/DraftContract';
import Lot from '../screens/Lot';
/* eslint-disable */ 
import AboutOpen from '../screens/AboutOpen';
import PrivacyPolicy from '../screens/PrivacyPolicy';
import TermsAndConditions from '../screens/TermsAndConditions';
import UserManagement from '../screens/UserManagement';
import Renewal from '../screens/Renewal';
import CallOff from '../screens/CallOff';
import EvaluateQuestionnaire from '../screens/EvaluateQuestionnaire';
import ViewEvaluatedQuestionnaire from '../screens/ViewEvaluation';
import EditProject from '../screens/EditProject';
import PageNotFound from '../screens/PageNotFound';
import ViewNotice from '../screens/ViewNotice';
import ViewSelectionQuestionniare from '../screens/ViewSelectionQuestionniare';
import EditSelectionQuestionnaire from '../screens/EditSelectionQuestionnaire';
import PublicOpportunityOverview from '../screens/PublicOpportunityOverview';
import PrivateOpportunityOverview from '../screens/PrivateOpportunityOverview';
import AccessibilityStatement from '../screens/AccessibilityStatement';
import BrowseEvents from '../screens/BrowseEvents';
import BrowseStages from '../screens/BrowseStages';
import CreateEvent from '../screens/CreateEvent';
import CreateStage from '../screens/CreateStage';
import ViewStage from '../screens/ViewStage';
import EditStage from '../screens/EditStage';
import BrowseQuestionnaireSections from '../screens/BrowseSections';
import DraftQuestionnaireSection from '../screens/DraftQuestionnaireSection';
import ViewSection from '../screens/ViewSection';
import EditSection from '../screens/EditSection';
import ApprovePRF from '../screens/ApproveProcurementProject';
import ManageLots from '../screens/ManageLots';
import BrowseActivity from '../features/ActivityLog/BrowseActivity';
import BrowseCorrespondence from '../features/Correspondence/BrowseCorrespondence';
import ViewCorrespondence from '../features/Correspondence/ViewCorrespondence';
import AssignQuestionnaireWeightage from '../screens/AssignQuestionnaireWeightage';
import AnswerSummary from '../screens/AnswerSummary';
import ManageSuppliers from '../screens/ManageSuppliers';
import AwardSummary from '../screens/AwardSummary';
import OpportunityAwardSummary from '../screens/OpportunityAwardSummary';
import SteeringGroupContract from '../screens/SteeringGroupContract';
import ManageSuppliersContract from '../screens/ManageSuppliersContract';
import BrowseCallOffs from '../screens/BrowseCallOffs';
import ConsultationSummary from '../screens/ConsultationSummary';
import BrowseCleverQuotes from '../screens/BrowseCleverQuotes';
import AnswerSummaryDPS from '../screens/AnswerSummaryDPS';
import ContractAwardSummaryRTMDirectAward from '../screens/ContractAwardSummaryRTMDirectAward';

const Constants = {
    routes: [{
        path: '/', name: 'Home', Component: Homepage, publicRoute: true,
    }, {
        path: '/login', name: 'Login', Component: LoginPage, publicRoute: true,
    }, {
        path: '/login/:confirmationCode?', name: 'Login', Component: LoginPage, publicRoute: true,
    }, {
        path: '/dashboard', name: 'User Dashboard', Component: Dashboard,
    }, {
        path: '/contact', name: 'Contact Us', Component: ContactUs, publicRoute: true,
    }, {
        path: '/opportunities/:myOpportunities', name: 'My Opportunities', Component: BrowseOpportunities,
    }, {
        path: '/opportunities', name: 'Browse Opportunities', Component: BrowseOpportunities, publicRoute: true,
    }, {
        path: '/opportunities/overview/public/:opportunityId', name: 'Opportunity Overview', Component: PublicOpportunityOverview, publicRoute: true,
    }, {
        path: '/opportunities/overview/:opportunityId', name: 'Opportunity Overview', Component: PrivateOpportunityOverview,
    }, {
        path: '/suppliers', name: 'Browse Suppliers', Component: BrowseSuppliers,
    }, {
        path: '/suppliers/clever-quotes/:opportunityID/:projectID/:eventID', name: 'Clever Quotes', Component: BrowseCleverQuotes,
    }, {
        path: '/suppliers/register-interest/:opportunityID/:projectID/:eventID/:routeToMarketParam?/:lotID?', name: 'Browse Suppliers', Component: BrowseSuppliers,
    }, {
        path: '/suppliers/manage/:projectID/:eventID', name: 'Manage Suppliers', Component: ManageSuppliers,
    }, {
        path: '/supplier/view/sq/:supplierId?', name: 'Selection Questionnaire', Component: ViewSelectionQuestionniare,
    }, {
        path: '/supplier/edit/sq', name: 'Edit Selection Questionnaire', Component: EditSelectionQuestionnaire,
    }, {
        path: '/contracts-register', name: 'Contracts Register', Component: ContractsRegister, publicRoute: true,
    }, {
        path: '/contracts-register/overview/:id', name: 'Awarded Contract Overview', Component: AwardedContractOverview, publicRoute: true,
    }, {
        path: '/contracts-register/:type', name: 'Contract Register', Component: DraftContract,
    }, {
        path: '/registration/company-details/enter', name: 'Enter Company Details', Component: CompanyDetails, publicRoute: true,
    }, {
        path: '/registration/company-details/review', name: 'Review Company Details', Component: CompanyDetails, publicRoute: true,
    }, {
        path: '/registration/account-details', name: 'Account Details', Component: AccountDetails, publicRoute: true,
    }, {
        path: '/registration/codes-and-categories', name: 'Codes and Categories', Component: CodesAndCategories, publicRoute: true,
    }, {
        path: '/registration/regions-of-supply', name: 'Delivery Area', Component: RegionsOfSupply, publicRoute: true,
    }, {
        path: '/registration/additional-questions', name: 'Additional Questions', Component: AdditionalQuestions, publicRoute: true,
    }, {
        path: '/procurement-requests', name: 'Browse Procurement Requests', Component: BrowseProcurementRequests,
    }, {
        path: '/supplier/overview/:id', name: 'Supplier Overview', Component: SupplierOverview,
    }, {
        path: '/procurement-requests/overview/:prfId', name: 'Procurement Request', Component: ProcurementRequestOverview,
    }, {
        path: '/calendar', name: 'My Calendar', Component: Calendar,
    }, {
        path: '/workflows/browse', name: 'Browse Workflows', Component: BrowseWorkflows,
    }, {
        path: '/workflows/view/:workflowID/:projectId?/:type?/:accountId?/:callOffMechanism?', name: 'View Workflow', Component: ViewWorkflow,
    }, {
        path: '/workflows/edit/:workflowID/:isImport?', name: 'Edit Workflow', Component: EditWorkflow,
    }, {
        path: '/workflows/project/edit/:projectId/:workflowID/:isImport?', name: 'Edit Project Workflow', Component: EditProjectWorkflow,
    }, {
        path: '/workflows/contract/edit/:projectId/:workflowID/:contractID?/:isImport?', name: 'Edit Contract Workflow', Component: EditProjectWorkflow,
    }, {
        path: '/workflows/browse/:resourceName?/:projectID?/:accountID?/:callOffMechanism?', name: 'Select Workflow', Component: BrowseWorkflows,
    }, {
        path: '/workflows/import/events/:stage/:stageName?/:routeToMarketParam?/:workflowID?/:resourceName?/:projectID?/:contractID?', name: 'Import Workflow Events', Component: BrowseEvents,
    }, {
        path: '/workflows/import/stages/:workflowName?/:routeToMarketParam?/:workflowID?/:resourceName?/:projectID?/:contractID?', name: 'Import Workflow Stages', Component: BrowseStages,
    }, {
        path: '/workflows/:resourceName?/:projectId?/:routeToMarket?/create/:isImport?', name: 'Create a Custom Workflow', Component: CreateWorkflow,
    }, {
        path: '/questionnaires', name: 'Browse Questionnaires', Component: BrowseQuestionnaires,
    }, {
        path: '/questionnaires/manage/:projectId?/:eventId?', name: 'Manage Questionnaires', Component: ManageQuestionnaires,
    }, {
        path: '/questionnaires/select/:type/:projectID/:eventID', name: 'Select Questionnaire', Component: BrowseQuestionnaires,
    }, {
        path: '/questionnaires/draft/section/:type', name: 'Draft Section', Component: DraftQuestionnaireSection,
    }, {
        path: '/questionnaires/draft/:templateType/:projectId?/:eventId?/:isImport?', name: 'Draft Questionnaire', Component: DraftQuestionnaire,
    }, {
        path: '/questionnaires/view/:templateId/:responseId?', name: 'View Questionnaire', Component: ViewQuestionnaire,
    }, {
        path: '/questionnaires/answer/view/:responseId', name: 'View Questionnaire', Component: ViewAnsweredQuestionnaire,
    }, {
        path: '/questionnaires/answer/:responseId', name: 'Answer Questionnaire', Component: AnswerQuestionnaire,
    }, {
        path: '/questionnaires/edit/:templateId/:projectId?/:eventId?/:isImport?/:responseId?', name: 'Edit Questionnaire', Component: EditQuestionnaire,
    }, {
        path: '/questionnaires/evaluate/:responseID', name: 'Evaluate Questionnaire', Component: EvaluateQuestionnaire,
    }, {
        path: '/questionnaires/evaluate/view/:responseID', name: 'View Evaluation', Component: ViewEvaluatedQuestionnaire,
    }, {
        path: '/questionnaires/weightage/:eventId/:responseID', name: 'Assign Weighting', Component: AssignQuestionnaireWeightage,
    }, {
        path: '/events', name: 'Browse Workflow Events', Component: BrowseEvents,
    }, {
        path: '/stages', name: 'Browse Stages', Component: BrowseStages,
    }, {
        path: '/sections', name: 'Browse Questionnaire Sections', Component: BrowseQuestionnaireSections,
    }, {
        path: '/sections/draft/:templateType', name: 'Draft Questionnaire Section', Component: DraftQuestionnaireSection,
    }, {
        path: '/sections/view/:sectionID/:type?/:templateType?/:importToQuesID?/:projectId?/:eventId?/:responseId?', name: 'View Questionnaire Section', Component: ViewSection,
    }, {
        path: '/sections/edit/:sectionID', name: 'Edit Questionnaire Section', Component: EditSection,
    }, {
        path: '/sections/import/:type/:questionnaireID?/:projectID?/:eventID?/:responseID?', name: 'Import Questionnaire Sections', Component: BrowseQuestionnaireSections,
    }, {
        path: '/events/:routeToMarket/:stageCategory/create', name: 'Create Event', Component: CreateEvent,
    }, {
        path: '/stages/:routeToMarket/create', name: 'Create Stage', Component: CreateStage,
    }, {
        path: '/stages/view/:stageId', name: 'View Stage', Component: ViewStage,
    }, {
        path: '/stages/edit/:stageId', name: 'Edit Stage', Component: EditStage,
    }, {
        path: '/solutions', name: 'Browse Solutions', Component: BrowseSolutions,
    }, {
        path: '/solutions/overview/:solutionId', name: 'Solution Overview', Component: SolutionOverview,
    }, {
        path: '/solutions/overview/:projectId/lot/view/:lotId/:solutionId', name: 'View Lot', Component: Lot,
    }, {
        path: '/my-profile', name: 'My Profile', Component: MyProfile,
    }, {
        path: '/contracts', name: 'Browse Contracts', Component: BrowseContracts,
    }, {
        path: '/callOffs', name: 'Browse Call Offs', Component: BrowseCallOffs,
    }, {
        path: '/user-management', name: 'User Management', Component: UserManagement,
    }, {
        path: '/project/create/:projectId?', name: 'Create new Project', Component: CreateProcurementProject,
    }, {
        path: '/project/edit/:projectId', name: 'Edit Project', Component: EditProject,
    }, {
        path: '/project/prf/:projectId', name: 'Create Project', Component: ApprovePRF,
    }, {
        path: '/project/renewal/:projectId/:contractId', name: 'Renewal', Component: Renewal,
    }, {
        path: '/projects', name: 'Browse Projects', Component: BrowseProjects,
    }, {
        path: '/spends', name: 'Browse Spend', Component: BrowseSpends,
    }, {
        path: '/rebates', name: 'Browse Rebate', Component: BrowseRebates,
    }, {
        path: '/projects/overview/:projectId', name: 'Project Overview', Component: ProjectOverview,
    }, {
        path: '/calloff/overview/:projectId', name: 'Call Off Overview', Component: ProjectOverview,
    }, {
        path: '/project/calloff/:projectId/:lotId?', name: 'Call Off', Component: CallOff,
    }, {
        path: '/projects/overview/:projectId/lot/create', name: 'Add Lot', Component: Lot,
    }, {
        path: '/projects/overview/:projectId/lot/view/:lotId', name: 'View Lot', Component: Lot, publicRoute: true,
    }, {
        path: '/projects/overview/:projectId/lot/edit/:lotId', name: 'Edit Lot', Component: Lot,
    }, {
        path: '/projects/overview/:projectId/lot/browse', name: 'Manage Lots', Component: ManageLots,
    }, {
        path: '/:resourceType/activity/:resourceId', name: 'Activity Log', Component: BrowseActivity,
    },{
        path: '/:resourceType/correspondence/:resourceId', name: 'Correspondence', Component: BrowseCorrespondence,
    }, {
        path: '/:resourceType/correspondence/overview/:resourceId/:topicId/:responseType?', name: 'Correspondence', Component: ViewCorrespondence,
    }, {
        path: '/pre-procurement-request', name: 'New Pre-Procurement Request', Component: PreProcurementRequest,
    }, {
        path: '/procurement-requests/edit/:projectId', name: 'Edit Procurement Request', Component: PreProcurementRequest,
    }, {
        path: '/evaluation/summary/:projectId/:eventId', name: 'Evaluation Summary', Component: EvaluationSummary,
    }, {
        path: '/consultation/summary/:projectId/:eventId', name: 'Response Summary', Component: ConsultationSummary,
    }, {
        path: '/award/summary/:projectId/:eventId', name: 'Award Summary', Component: AwardSummary,
    }, {
        path: '/opportunity/award/Summary/:projectId/:eventId', name: 'Award Summary', Component: OpportunityAwardSummary,
    },{
        path: '/contract/award/summary/:projectId/:eventId', name: 'Award Summary', Component: ContractAwardSummaryRTMDirectAward,
    },
    {
        path: '/answers/summary/:projectId/:eventId', name: 'Answer Summary', Component: AnswerSummary,
    }, {
        path: '/dps/answers/summary/:projectId/:eventId', name: 'Answer Summary DPS', Component: AnswerSummaryDPS,
    }, 
    {
        path: '/dps/answers/summary/:projectId/:eventId', name: 'Answer Summary DPS', Component: AnswerSummaryDPS,
    },  
    {
        path: '/steering-group/select/:projectId', name: 'Edit Steering Group', Component: SelectSteeringGroup,
    }, {
        path: '/steering-group/contract/:contractId', name: 'Edit Steering Group', Component: SteeringGroupContract,
    }, {
        path: '/projects/overview/notice/create/:projectId/:eventId', name: 'Create Draft Notice', Component: CreateNotice,
    }, {
        path: '/projects/overview/notice/view/:projectId/:eventId', name: 'View Notice', Component: ViewNotice,
    }, {
        path: '/contracts/management/:contractId', name: 'Contract Management', Component: ContractOverview,
    }, {
        path: '/contracts/management/supplier/:contractId/:supplierId/:lotId', name: 'Contract Management', Component: ContractOverview,
    },{
        path: '/contracts/suppliers/:contractId', name: 'Manage Suppliers', Component: ManageSuppliersContract,
    }, {
        path: '/contracts/overview/:contractId', name: 'Contract Overview', Component: ContractOverview, publicRoute: true,
    }, {
        path: '/projects/overview/contract/:type/:projectID?', name: 'Draft Contract', Component: DraftContract,
    }, {
        path: '/contract/:type/:contractID', name: 'Edit Contract', Component: DraftContract,
    }, {
        path: '/contracts/management/:type/:contractID', name: 'Draft Contract Variation', Component: DraftContract,
    }, {
        path: '/about-open', name: 'About Open', Component: AboutOpen, publicRoute: true,
    }, {
        path: '/privacy-policy', name: 'Privacy Policy', Component: PrivacyPolicy, publicRoute: true,
    }, {
        path: '/terms-and-conditions', name: 'Terms and Conditions', Component: TermsAndConditions, publicRoute: true,
    }, {
        path: '/accessibility-statement', name: 'Accessibility Statement', Component: AccessibilityStatement, publicRoute: true,
    }, {
        path: '*', name: 'Page not found', Component: PageNotFound, publicRoute: true,
    }],
    actions: {
    },
    strings: {
    },
};

export default Constants;
