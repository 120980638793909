import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Counter = ({ numberOfChars, limit }) => {
    const [charCount, setCharCount] = useState(0);

    useEffect(() => {
        setCharCount(numberOfChars);
    }, [numberOfChars]);

    return (
        <div id='char-count' className='char-count-rich-editor remove-on-print'>
            <span id='current'>{charCount}</span>
            <span id='maximum'> / {limit}</span>
        </div>
    );
};

export default Counter;
Counter.propTypes = {
    numberOfChars: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
};
