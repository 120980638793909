import React, {
    useEffect, useRef, useState, useContext, useMemo,
} from 'react';
import Constants from './constants';
import BrowseData from '../../features/BrowseData';
import AuthContext from '../../context/AuthContext';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import ToastConstants from '../../components/Alerts/Toast/constants';
import isNepoRole from '../../utils/isNepoRole';

const BrowseSolutions = () => {
    const authContext = useContext(AuthContext);
    const [solutions, setSolutions] = useState();
    const firstSelectedTab = useRef();
    const [filteredSolutions, setFilteredSolutions] = useState([]);
    const [selectedTab, setSelectedTab] = useState();

    const loggedUserAccountId = authContext?.user?.accountId;

    const myOrganisationSolutions = useMemo(() => solutions
        ?.filter((solution) => solution.accountID
        === loggedUserAccountId),
    [solutions]);

    const nepoSolutions = useMemo(() => solutions
        ?.filter((solution) => isNepoRole(solution.account.accountType)
        && solution?.sharedWith?.includes(loggedUserAccountId)),
    [solutions]);

    const externalSolutions = useMemo(() => solutions
        ?.filter((solution) => !isNepoRole(solution.account.accountType)
        && solution?.sharedWith?.includes(loggedUserAccountId)),
    [solutions]);

    const getFilteredSolutionsByTab = (tab) => ({
        [Constants.tabOptions[0].value]: myOrganisationSolutions,
        [Constants.tabOptions[1].value]: nepoSolutions,
        [Constants.tabOptions[2].value]: externalSolutions,
    }[tab]);

    const handleSolutionsByTab = (tabLabel) => {
        setSelectedTab(Constants.tabOptions.find((tab) => tab.label === tabLabel).value);
    };

    const fetchData = async () => {
        try {
            const responseData = await projectManagementAPIs
                .listSolutions();

            if (responseData.status === 200) {
                setSolutions(responseData.data.items);
            } else {
                setSolutions([]);
                Toast.fire({
                    icon: ToastConstants.error,
                    titleText: Constants.UNABLE_TO_RETRIEVE_INFORMATION,
                });
            }
        } catch (error) {
            Toast.fire({
                icon: ToastConstants.error,
                titleText: `${Constants.UNABLE_TO_RETRIEVE_INFORMATION} ${ error}`,
            });
        }
    };

    const getTabs = () => {
        const tabs = Constants.tabOptions
            .filter((item) => !(isNepoRole(authContext?.user?.accountType)
        && item.value === Constants.tabOptions[1].value));
        firstSelectedTab.current = tabs[0].value;
        return tabs.map((item) => item.label);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!solutions) {
            return;
        }
        setFilteredSolutions(getFilteredSolutionsByTab(selectedTab
                || firstSelectedTab.current));
    }, [solutions, selectedTab]);

    return <BrowseData
        id='browseSolutions'
        context='Solutions'
        contextSingular='Solution'
        dataSet={filteredSolutions}
        searchPlaceholder= {Constants.SEARCH_FOR_A_SOLUTION}
        numOfRowsPerPage={Constants.NUM_OF_ROWS_PER_PAGE}
        searchFilterAttribute='title'
        allowFilter={true}
        action={{
            type: 'view',
        }}
        sortOptions={Constants.sortOptions}
        tabOptions={getTabs()}
        handleTabSelected={handleSolutionsByTab}
    />;
};

export default BrowseSolutions;
