export default {
    toolbar: {
        options: ['inline', 'list', 'colorPicker', 'remove', 'history', 'emoji', 'link'],
        inline: {
            options: ['bold', 'italic', 'underline'],
        },
        list: {
            options: ['unordered', 'ordered'],
        },
        emoji: {
            emojis: ['✅', '⚠️', '❌', '📌', '📍', '⛔', '⭕',
                '🆗', '0️⃣', '1️⃣', '2️⃣', '3️⃣', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣', '🔟',
                '©', '®', '™', '⚫', '⚪', '⬛', '⬜', '◼', '◻', '◾', '◽', '▪', '▫',
                '🔶', '🔸', '🔷', '🔹', '🔺', '🔻', '🔲', '🔳'],
        },
        colorPicker: {
            colors: ['rgb(247, 218, 100)', 'rgb(0, 0, 0)', 'rgb(255,255,255)', 'rgb(41,105,176)', 'rgb(209,72,65)'],
        },
        link: {
            defaultTargetOption: '_blank',
        },
    },
};
