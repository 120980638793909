/* eslint-disable max-len */
import React, {
    useState, useEffect, useContext, useCallback,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format, isFuture, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import Confirm from '../../components/Alerts/Confirm/Confirm';
import DashboardCard from '../../components/DashboardCard';
import GuidanceBanner from '../../components/GuidanceBanner';
import projectManagementAPIs from '../../services/project-management.service';
import addEllipseIcon from '../../styles/images/addEllipseIcon.svg';
import LabelledTextArea from '../../components/LabelledTextArea';
import Toast from '../../components/Alerts/Toast/Toast';
import ToastConstants from '../../components/Alerts/Toast/constants';
import {
    registerInterestStatuses, publishAtLevel, ERROR_RETRIEVING_INFO,
    LABEL_BUTTON_ADD, projectTypes,
    ToastErrorMessages,
} from '../../config/constants';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import rbac from '../../rbac';
import AuthContext from '../../context/AuthContext';
import StatusLabel from '../../components/StatusLabel';
import actions from '../BrowseCleverQuotes/slice/supplier.actions';
import projectService from '../../services/project.service';
import Constants from './constants';
import statusCodes from '../../utils/statusCodes';

const ManageSuppliers = () => {
    const [project, setProject] = useState({});
    const [publishedEvent, setPublishedEvent] = useState({});
    const [interest, setInterest] = useState([]);
    const [awardResponses, setAwardResponses] = useState([]);
    const [awardResponsesAccountData, setAwardResponsesAccountData] = useState([]);
    const [publishLevel, setPublishLevel] = useState(publishAtLevel.project);
    const [reasonModal, showReasonModal] = useState(false);
    const [values, setValues] = useState({});
    const { projectID, eventID } = useParams();
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const dispatch = useDispatch();

    const SUPPLIER_CARD_SIZE = {
        LARGE: 'large',
        SMALL: 'small',
    };

    const getOpportunityID = (lotID = null) => {
        project?.workflow?.stages.forEach((stage) => {
            const filteredEvents = stage.events.filter((event) => event.eventID === eventID);
            if (filteredEvents[0]?.eventID === eventID) {
                const { opportunityID } = filteredEvents[0];
                let url = `/suppliers/register-interest/${opportunityID}/${projectID}/${eventID}`;

                if (projectService.isProjectOrCallOffDirectAward(project)) {
                    url = `${url}/directAward`;

                    if (lotID) {
                        url = `${url}/${lotID}`;
                    }
                }
                history.push(url);
            }
        });
    };

    const getPublishedEvent = (projectData) => {
        let publishEvent = {};
        projectData.workflow?.stages?.forEach((stage) => {
            stage?.events?.forEach((event) => {
                if (event.eventType === 'publish' && event.isPublished === true && event.eventID === eventID) {
                    publishEvent = {
                        ...event,
                    };
                }
            });
        });
        return publishEvent;
    };

    const flattenResponses = (awardResponsesByAccount) => awardResponsesByAccount?.flatMap((item) => {
        const { account, accountID } = item;
        if (Array.isArray(item.responses)) {
            return item.responses.map((response) => ({
                ...response, status: registerInterestStatuses.approved, account, accountID,
            }));
        }

        if (typeof item.responses === 'object' && item.responses !== null) {
            return [item.responses];
        }

        return [];
    });

    const fetchProject = async () => {
        try {
            const response = await projectManagementAPIs.getProject(projectID);
            if (response.status === 200) {
                setProject(response.data);
                setPublishedEvent(getPublishedEvent(response.data));
            }
        } catch (error) {
            Toast.fire({
                icon: ToastConstants.error,
                title: ERROR_RETRIEVING_INFO,
            });
        }
    };

    const fetchInterest = async () => {
        try {
            const response = await projectManagementAPIs.getInterest(projectID, eventID);
            if (response.status === 200) {
                setInterest(response.data);
            }
        } catch (error) {
            Toast.fire({
                icon: ToastConstants.error,
                title: ERROR_RETRIEVING_INFO,
            });
        }
    };

    const fetchAward = async () => {
        try {
            const response = await projectManagementAPIs.getAwardSummary(projectID, eventID);
            if (response.status === 200) {
                setAwardResponsesAccountData(response.data);
                setPublishLevel(response.data?.publishLevel || publishAtLevel.project);
                const responses = flattenResponses(response.data?.responses);
                setAwardResponses(responses);
            }
        } catch (error) {
            Toast.fire({
                icon: ToastConstants.error,
                title: ERROR_RETRIEVING_INFO,
            });
        }
    };

    const fetchData = () => {
        fetchProject();
        fetchInterest();
        fetchAward();
    };

    useEffect(() => {
        fetchData();
        dispatch(actions.setSupplierState({}));
    }, [projectID, eventID]);

    const showRemoveButton = useCallback((supplier) => {
        if (awardResponsesAccountData?.responses?.length) {
            const currentSupplier = Object.values(awardResponsesAccountData.responses)?.find((supp) => supp.accountID === supplier.accountID);
            return !currentSupplier?.awardLetterSent;
        }
        return false;
    }, [awardResponsesAccountData?.responses]);

    const handleSupplierApproval = async (payload) => {
        const response = await projectManagementAPIs
            .approveOrRejectSupplier(projectID, eventID, payload);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                title: `Successfully ${payload.action} supplier`,
            });
        } else {
            Toast.fire({
                icon: 'error',
                title: 'Unable to update project.',
            });
        }
        fetchData();
        setValues({});
    };

    const handleApproval = (accountID, userID, action) => {
        const approvalObject = {
            ...values,
            accountID,
            userID,
            action,
        };
        setValues(approvalObject);

        if (action === 'rejected') {
            showReasonModal(true);
        } else {
            Confirm(() => {
                handleSupplierApproval(approvalObject);
            }, {
                subTitle: 'Are you sure want to approve this supplier?',
            });
        }
    };

    const handleChange = (e) => {
        const { name } = e.target;
        const { value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const generateRejectModal = () => <>
        <LabelledTextArea id='reason'
            label='Enter your reason for rejecting a supplier.'
            // Not sure if below line is applicable.
            subLabel='This is internal and only visible within your organisation.'
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={values.reason || ''}
            currentCharCount={values.reason ? values.reason.length : 0}
            placeholder='Enter reason'
            required={true} />

        {/* Check if this needs to be added */}
        <LabelledTextArea id='externalReason'
            label='Public statement for rejecting a supplier.'
            subLabel='This is a public statement and is visible to suppliers.'
            breakColumn={true} onChange={(e) => handleChange(e)}
            value={values.externalReason || ''}
            currentCharCount={values.externalReason ? values.externalReason.length : 0}
            placeholder='Enter external reason'
            required={true}
        />
    </>;

    const withdrawSupplierInterest = async (supplier, rtm, lotID) => {
        const response = projectService.isRTMDirectAward(rtm)
            && await projectManagementAPIs.withdrawSupplierDirectAward(
                projectID, eventID, supplier.accountID, lotID,
            );

        if (response.status === statusCodes.SUCCESS) {
            fetchData();
            Toast.fire({
                icon: ToastConstants.success,
                title: ToastErrorMessages.SUCCESSFUL_WITHDRAW_SUPPLIER,
            });
        } else if (response.data?.ErrorCode === Constants.supplierAlreadyRegisteredCodeError) {
            Toast.fire({
                icon: ToastConstants.error,
                title: ToastErrorMessages.SUPPLIER_ALREADY_REGISTERED,
            });
        } else {
            Toast.fire({
                icon: ToastConstants.error,
                title: ToastErrorMessages.FAILED_TO_WITHDRAW_SUPPLIER,
            });
        }
    };

    const generateContent = (data, status, lotID) => data?.map(
        (supplier, idx) => (
            (!status
                && (supplier.status === registerInterestStatuses.rejected
                    || supplier.status === registerInterestStatuses.withdraw))
                    || supplier.status === status)
            && <>
                <div className={`msSupplierRow ${project?.route === 'requestQuotationCleverQuotes' ? 'supplierRowHeight' : ''}`}>
                    <div>
                        <p className='title'>{supplier?.account?.companyName}</p>
                        <p className='caption-small'>{supplier?.user?.contactDetails?.username}</p>
                        {supplier.status === 'pending' && <p className='caption-small'>{format(new Date(supplier.registeredOn), 'dd-MM-yy')}</p>}
                    </div>
                    {status === registerInterestStatuses.approved
                    && rbac.can(
                        rbac.action.registerInterestRequests,
                        rbac.util.getSteeringGroupRole(authContext.user.id, project?.steeringInfo),
                    )
                    && <div className='msButtonContainer'>
                        {showRemoveButton(supplier) && projectService.isProjectOrCallOffDirectAward(project)
                            && <Button
                                id={'removeSupplier'}
                                variant={'secondary'}
                                size='small'
                                handleClick={() => withdrawSupplierInterest(supplier, project?.route, lotID)}
                                label={Constants.removeButtonLabel} />
                        }
                        <Button
                            id={'viewSupplier'}
                            variant={'primary'}
                            size='small'
                            handleClick={() => history.push(`/supplier/overview/${supplier.accountID}`)}
                            label={Constants.viewSupplierButtonLabel} />
                    </div>}
                    {status === registerInterestStatuses.pending
                    && rbac.can(
                        rbac.action.registerInterestRequests,
                        rbac.util.getSteeringGroupRole(authContext.user.id, project?.steeringInfo),
                    )
                    && <div className='msPendingButtonContainer'>
                        <Button
                            id={'viewSupplier'}
                            variant={'secondary'}
                            size='small'
                            handleClick={() => history.push(`/supplier/overview/${supplier.accountID}`)}
                            label='View Supplier' />
                        {project.projectStatus !== 'cancelled'
                        && isFuture(parseISO(publishedEvent.submissionEndDate))
                        && <div className='msPendingActionButtonContainer'>
                            <Button
                                id={idx}
                                variant={'primary'}
                                size='small'
                                handleClick={() => handleApproval(supplier.accountID, supplier.userID, 'approved')}
                                label='Accept' />
                            <Button
                                id={idx}
                                variant={'secondary'}
                                size='small'
                                handleClick={() => handleApproval(supplier.accountID, supplier.userID, 'rejected')}
                                label='Reject' />
                        </div>}
                    </div>}
                    {supplier.status === registerInterestStatuses.rejected
                    && <>
                        <p className='caption-small'>Reason:&nbsp;{supplier.reason}</p>
                        <p className='caption-small'>External Reason:&nbsp;{supplier?.externalReason}</p>
                        <div className='msButtonContainer'>
                            <StatusLabel id={idx} color='red' labelTxt={supplier.status} />
                        </div>
                    </>}
                    {supplier.status === registerInterestStatuses.withdraw
                    && <>
                        <p className='caption-small'>Withdrawal Reason:&nbsp;{supplier?.withdrawalReason}</p>
                        <div className='msButtonContainer'>
                            <StatusLabel id={idx} color='red' labelTxt={supplier.status} />
                        </div>
                    </>}
                </div>
            </>,
    );

    const generateDirectAwardWithLotsContent = (lots) => lots?.sort((a, b) => a.lotOrderNo - b.lotOrderNo).map((lot, idx) => (
        <>
            <header className='dashboardCardHeader' key={lot.lotID}>
                <div className='dashboardHeaderText'>
                    <p className='headerTitle title-large'>{`Lot ${lot.lotOrderNo}: ${lot.title}`}</p>
                </div>
                <div className='dashboardHeaderBtn'>
                    <Button id={`register-supplier-in-lot-${idx}`} size='small' variant='secondary skinless'
                        label={LABEL_BUTTON_ADD} icon={true} iconSrc={addEllipseIcon} disabled={false}
                        handleClick={() => getOpportunityID(lot.lotID)} />
                </div>
            </header>
            {generateContent(awardResponses?.filter((r) => r.lotID === lot.lotID), registerInterestStatuses.approved, lot.lotID)}
        </>
    ));

    const generateRegisteredSuppliersContent = (status) => (projectService.isProjectOrCallOffDirectAward(project) && publishLevel === publishAtLevel.lot
        ? generateDirectAwardWithLotsContent(project.lots)
        : generateContent(interest, status));

    const generateCleverQuotesContent = () => <div id={'cleverQuotesContent'}>
        <p className='title'>Run a Clever Quote to randomly select suppliers that meet your criteria.</p>
        <Button
            id={'cleverQuotesBtn'}
            variant={'primary'}
            size='small'
            handleClick={() => history.push(`/suppliers/clever-quotes/${publishedEvent.opportunityID}/${projectID}/${eventID}`)}
            label='Run Clever Quote'
        />
    </div>;

    return (
        <>
            <GuidanceBanner
                id='msGuidanceBanner'
                headerText= {publishedEvent.eventName}
                bannerText2="You're selecting suppliers for this opportunity!"
                buttonArr={[{
                    size: 'small',
                    allowAction: true,
                    variant: 'secondary',
                    label: 'Back',
                    action: () => history.push(`/projects/overview/${projectID}`),
                }]}
            />
            <div className='msDetailsContainer'>
                <DashboardCard
                    id={'supplierCard'}
                    customStyle={'manage-suppliers-card'}
                    size={SUPPLIER_CARD_SIZE.SMALL}
                    header={true}
                    headerTitle='Registered Suppliers'
                    caption={`${interest.filter((supplier) => supplier.status === registerInterestStatuses.approved).length} suppliers registered`}
                    actionBtn={rbac.can(rbac.action.registerInterestRequests, rbac.util.getSteeringGroupRole(authContext.user.id, project?.steeringInfo))
                        && ((project.projectType !== projectTypes.callOff
                            && project.projectStatus !== 'cancelled'
                            && publishLevel === publishAtLevel.project
                            && (isFuture(parseISO(publishedEvent.submissionEndDate)) || projectService.isProjectOrCallOffDirectAward(project)))
                            && (project?.route !== 'requestQuotationCleverQuotes'
                                || (project?.route === 'requestQuotationCleverQuotes' && interest?.length !== 0))
                        )}
                    actionBtnText={LABEL_BUTTON_ADD}
                    handlerActionBtn={() => getOpportunityID()}
                    variant='secondary'
                    additionalVariant='skinless'
                    icon={true}
                    iconSrc={addEllipseIcon}
                    content={project?.route === 'requestQuotationCleverQuotes' && interest.length === 0
                        ? generateCleverQuotesContent()
                        : generateRegisteredSuppliersContent(registerInterestStatuses.approved)}
                />
                {project?.route !== 'requestQuotationCleverQuotes'
                    && !projectService.isProjectOrCallOffDirectAward(project)
                && <DashboardCard
                    id={'supplierCard'}
                    customStyle='manage-suppliers-card'
                    size={SUPPLIER_CARD_SIZE.SMALL}
                    header={true}
                    headerTitle='Register Interest Requests'
                    content={generateRegisteredSuppliersContent(registerInterestStatuses.pending)}
                />}
                {!projectService.isProjectOrCallOffDirectAward(project)
                && <DashboardCard
                    id={'supplierCard'}
                    customStyle='manage-suppliers-card'
                    size={SUPPLIER_CARD_SIZE.SMALL}
                    header={true}
                    headerTitle='Rejected/Withdrawn Requests'
                    content={generateRegisteredSuppliersContent()}
                />}
            </div>
            {reasonModal
            && <Modal
                id='rejectModal'
                headerTitle='Reject Supplier'
                open={true}
                size='medium'
                body={generateRejectModal()}
                footer={true}
                handleMainActionBtnClick = {() => {
                    handleSupplierApproval(values);
                    setValues({});
                    showReasonModal(false);
                }}
                mainActionButtonTxt={'Confirm'}
                secondActionButton={true}
                handleSecondaryActionBtnClick={() => {
                    setValues({});
                    showReasonModal(false);
                }}
                secondActionButtonTxt={'Cancel'}
                helpOption={false}
                closeModal={() => {
                    setValues({});
                    showReasonModal(false);
                }}
            />
            }
        </>);
};

export default ManageSuppliers;
