import React, { useEffect, useState } from 'react';
import Constants from './constants';
import BrowseData from '../../features/BrowseData';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';

const BrowseContracts = () => {
    const [contracts, setContracts] = useState([]);
    const [tab, setTab] = useState('createdBy');

    const fetchData = async (tabSelected) => {
        try {
            const response = await projectManagementAPIs.listContracts(tabSelected);
            if (response.status === 200) {
                setContracts(response.data.items);
            } else {
                setContracts([]);
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (err) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const getContractsByTab = (selectedTab) => {
        setContracts([]);
        switch (selectedTab) {
            case 'My Contracts':
                setTab('createdBy');
                fetchData('createdBy');
                break;
            case 'My Department':
                setTab('department');
                fetchData('department');
                break;
            case 'My Organisation':
                setTab('');
                fetchData('');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        fetchData(tab);
    }, []);

    return <BrowseData
        id='browseContracts'
        context='Contracts'
        contextSingular='Contract'
        dataSet={contracts}
        searchPlaceholder='Search for a contract'
        searchFilterAttribute='title'
        sortOptions={Constants.sortOptions}
        tabOptions={Constants.tabOptions}
        numOfRowsPerPage={15}
        handleTabSelected={getContractsByTab}
    />;
};

export default BrowseContracts;
