import instance from '../config/axios.instance';
import projectManagement from '../config/endpoints/projectManagement';

const responseVersionsManagementAPIs = {

    getResponseVersion: async (responseVersionID) => {
        try {
            const endpoint = projectManagement.getResponseVersion;
            const response = await instance.get(`${endpoint}/${responseVersionID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
};

export default responseVersionsManagementAPIs;
