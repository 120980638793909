import React, {
    useEffect, useState,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DocumentBuilder from '../../features/DocumentBuilder';
import documentManagementAPIs from '../../services/document-management.service';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';
import Button from '../../components/Button';

const ViewSelectionQuestionniare = () => {
    const history = useHistory();

    const { supplierId } = useParams();
    const [initialState, setInitialState] = useState({});

    const fetchData = async () => {
        let response;
        if (supplierId) {
            response = await documentManagementAPIs.getSuppliersDefaultTemplate(supplierId);
        } else {
            response = await documentManagementAPIs.getDefaultTemplate();
        }
        if (response.status === 200) {
            if (response.data.length > 0) {
                const questionnaire = response.data[0];
                const preparedData = helperFunctions.prepareDocumentBuilderState(
                    'questionnaire', questionnaire, supplierId,
                );
                setInitialState(preparedData);
            }
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return Object.keys(initialState).length > 0
        ? <DocumentBuilder
            documentMode={'viewanswersqmode'}
            context='Questionnaire'
            type={'selectionQuestionnaire'}
            initialDocumentState={initialState}
            sectionLabel='Section'
            sectionItemLabel='Question'
            allowSectionReordering={false}
            allowSectionItemsReordering={false}
            sectionDetailsUserList={[]}
            handlePrimaryToolbarSubmitButton={() => history.push('/supplier/edit/sq')}
        />
        : <div className='sqNoAnswerContainer'>
            <div className='sqNoAnswerHeader'>
                <Button
                    id='sqBackButton'
                    label='Back'
                    variant='secondary'
                    handleClick={() => history.goBack()}
                />
            </div>
            <div className='sqNoAnswerBody'>
                <p className='title-large'>Supplier has not yet completed the Selection Questionnaire</p>
            </div>
        </div>;
};

export default ViewSelectionQuestionniare;
