const Constants = {
    buttons: {
        newSubmission: 'New Submission',
        removeInterest: 'Remove Interest',
        view: 'View',
        viewOutcome: 'View Outcome',
    },
    publishLotLevel: 'lot',
    answerSummaryUrl: '/answers/summary/',
    dpsUrl: '/dps',
    registerInterestQuery: 'registerInterest',
    registerInterestQueryURL: '?registerInterest=true',
    registerInterestParam: 'registerInterest=true',
};
export default Constants;
