import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import withReactContent from 'sweetalert2-react-content';

const confirmSwal = withReactContent(Swal);

const Alert = (title, message, icon = 'info', showCloseButton = false) => {
    confirmSwal.fire({
        icon,
        title,
        text: message,
        showCloseButton,
    });
};

export default Alert;
