import React from 'react';
import PropTypes from 'prop-types';
import userIcon from '../../../../styles/images/userIcon.svg';
import chevronDown from '../../../../styles/images/chevronDown.svg';

const UserNavMenuToggleButton = ({ handleToggleBtnClick, firstName }) => (
    <div id='userNavMenuButtonContainer'>
        <button id='userNavMenuToggleButton'
            onClick={handleToggleBtnClick}>
            <img src={userIcon} id='userIcon' alt='user'/>
            <a className='title' id='userNavMenuToggleBtnLabel'>{`Hello, ${firstName}`}</a>
            <img src={chevronDown} alt='user-options' id='dropdownIcon' />
        </button>
    </div>
);

UserNavMenuToggleButton.propTypes = {
    handleToggleBtnClick: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
};

export default UserNavMenuToggleButton;
