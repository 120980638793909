import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import LabelledSelect from '../../../components/LabelledSelect';
import Toast from '../../../components/Alerts/Toast/Toast';
import { companyTypes } from '../../../config/constants';

const CompanyType = ({ currentStep, onSubmit, closeModal }) => {
    const [value, setValue] = useState('default');

    const validateCompanyType = (e, selValue) => {
        if (selValue !== 'default') {
            onSubmit(e, selValue);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Please select a company type to continue.',
            });
        }
    };

    const onSelectChange = (e) => {
        setValue(e.currentTarget.value);
    };

    const generateDropdownOptions = (key, option) => ((key > 0)
        ? <option key={`company-type-${key}`} value={option.value}>{option.label}</option>
        : <><option key='company-type-default' value='default' disabled>{'Please select'}</option>
            <option key={`company-type-${key}`} value={option.value}>{option.label}</option>
        </>);

    const modalBody = <>
        <LabelledSelect id='company-type'
            options={companyTypes.map((el, idx) => generateDropdownOptions(idx,
                { label: el.name, value: el.name }))}
            label='Select your company type'
            breakColumn={false}
            required={true}
            value={value}
            onChange={(e) => onSelectChange(e)} />
    </>;
    if (currentStep !== 3) {
        return null;
    }
    return <>
        <Modal
            open={true}
            closeModal={closeModal}
            size='small'
            headerTitle='Company Type'
            handleMainActionBtnClick={(e) => validateCompanyType(e, value)}
            mainActionButtonTxt='Continue'
            closeButton={false}
            body={modalBody}
            helpOption={false}
        />
    </>;
};

CompanyType.propTypes = {
    currentStep: PropTypes.number,
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
};

export default CompanyType;
