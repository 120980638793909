const Constants = {
    users: [
        {
            id: '1',
            name: 'user1',
        },
        {
            id: '2',
            name: 'user2',
        },
        {
            id: '3',
            name: 'user3',
        },
        {
            id: '4',
            name: 'user4',
        },
    ],
    sortOptions: [
        null,
        { label: 'Unread First', value: 'lastUnreadMessageSystemTimestamp-descending-dateTime' },
        { label: 'Created on (newest)', value: 'createdOn-descending-dateTime' },
        { label: 'Created on (oldest)', value: 'createdOn-ascending-dateTime' },
        { label: 'Subject (descending)', value: 'subject-descending-dateTime' },
        { label: 'Subject (ascending)', value: 'subject-ascending-dateTime' },
        { label: 'Created By (descending)', value: 'senderCompany-descending-dateTime' },
        { label: 'Created By (ascending)', value: 'senderCompany-ascending-dateTime' },
    ],
    lastMessageSortOptions: [
        { label: 'Last message received (newest)', value: 'lastTopicOrMessageSystemTimestamp-descending-dateTime' },
        { label: 'Last message received (oldest)', value: 'lastTopicOrMessageSystemTimestamp-ascending-dateTime' },

    ],
    topicsSuccessfullyDownloaded: 'Successfully exported Topics.',
    topicsError: 'Unable to download export.',
    LOT: 'lot',
};

export default Constants;
