import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Constants from './constants';
import BrowseData from '../../features/BrowseData';
import documentManagementAPIs from '../../services/document-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import AuthContext from '../../context/AuthContext';
import rbac from '../../rbac';

const BrowseSections = () => {
    const [sections, setSections] = useState([]);
    const { type } = useParams();
    const history = useHistory();
    const authContext = useContext(AuthContext);

    const fetchData = async () => {
        try {
            const responseData = await documentManagementAPIs.listSections();
            if (responseData.status === 200) {
                setSections(responseData.data.items);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return <BrowseData
        id='browseQuestionnaires'
        context={'sections'}
        contextSingular='Section'
        dataSet={sections}
        searchPlaceholder='Search for a section'
        searchFilterAttribute='title'
        allowFilter= {false}
        numOfRowsPerPage={15}
        action={{
            type: type || 'view',
        }}
        sortOptions={Constants.sortOptions}
        allowAddNew={rbac.can(rbac.action.addSection,
            [authContext.user.accountType.toLowerCase() + authContext.user.role])}
        labelAddNew='Add'
        handleAddNew={() => { history.push('/sections/draft/library'); }}
    />;
};

export default BrowseSections;
