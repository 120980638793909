// TODO: Remove when BE is updated for evaluators
import React, {
    useEffect, useContext, useState,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DocumentBuilder from '../../features/DocumentBuilder';
import projectManagementAPIs from '../../services/project-management.service';
import gatewayAPIs from '../../services/gateway.service';

import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';
import AuthContext from '../../context/AuthContext';
import {
    accountTypes, QUESTIONNAIRE, ERROR_RETRIEVING_INFO, ERROR_RETRIEVING_DIFF_INFO,
    LOAD_ERROR,
} from '../../config/constants';
import responseVersionsManagementAPIs from '../../services/response-versions.service';
import ToastConstants from '../../components/Alerts/Toast/constants';

const ViewQuestionnaire = () => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const { templateId, responseId } = useParams();
    const [type, setType] = useState('');
    const [initialState, setInitialState] = useState({});
    const [structureToCompare, setStructureToCompare] = useState();
    const [steeringInfo, setSteeringInfo] = useState();

    const getQuestionnaire = async (idTemplate) => {
        const responseData = await gatewayAPIs.getQuestionnaire(idTemplate);
        return responseData;
    };

    const fetchProject = async (id) => {
        const response = await projectManagementAPIs.getProject(id);
        if (response.status === 200) {
            setSteeringInfo(response?.data?.steeringInfo);
        } else {
            Toast.fire({
                icon: ToastConstants.error,
                titleText: LOAD_ERROR,
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (responseId) {
                if (authContext.user.accountType !== accountTypes.supplier) {
                    const response = await projectManagementAPIs.getResponse(responseId);
                    if (response.status === 200) {
                        const preparedData = helperFunctions
                            .prepareDocumentBuilderState('questionnaire', response.data, responseId);
                        setInitialState(preparedData);
                        setType(preparedData.details.templateType);
                        fetchProject(response.data.projectID);
                    } else {
                        Toast.fire({
                            icon: 'error',
                            titleText: 'Unable to retrieve information.',
                        });
                    }
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'You do not have permission to view this content.',
                    });
                    history.push('/dashboard');
                }
            } else {
                const responseData = await getQuestionnaire(templateId);
                if (responseData.status === 200) {
                    const preparedData = helperFunctions
                        .prepareDocumentBuilderState(QUESTIONNAIRE, responseData.data, templateId);
                    setInitialState(preparedData);
                    setType(preparedData.details.templateType);
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: ERROR_RETRIEVING_INFO,
                    });
                }
            }
        };
        // TODO: if (templateId && authContext.user.isAuthenticated) {
        if (templateId) {
            fetchData();
        }
    }, [templateId, responseId]);

    const getResponseVersion = async (idTemplate) => {
        const responseData = await responseVersionsManagementAPIs.getResponseVersion(idTemplate);
        return responseData;
    };


    const handleShowDiffsInViewer = async (versionId) => {
        const responseData = responseId
            ? await getResponseVersion(versionId)
            : await getQuestionnaire(versionId);

        if (responseData.status === 200) {
            const oldPreparedVersion = helperFunctions
                .prepareDocumentBuilderState(QUESTIONNAIRE, responseData.data, versionId);
            setStructureToCompare(helperFunctions
                .prepareVersionsToCompare(QUESTIONNAIRE, oldPreparedVersion, initialState));
        } else {
            Toast.fire({
                icon: 'error',
                titleText: ERROR_RETRIEVING_DIFF_INFO,
            });
        }
    };


    return Object.keys(initialState).length > 0 ? <DocumentBuilder
        documentMode={'view'}
        context='Questionnaire'
        type={type}
        initialDocumentState={initialState}
        sectionLabel='Section'
        sectionItemLabel='Question'
        allowSectionReordering={false}
        allowSectionItemsReordering={false}
        sectionDetailsUserList={[]}
        showVersionDropdown = {true}
        handleShowDiffsInViewer={handleShowDiffsInViewer}
        structureToCompare={structureToCompare}
        steeringInfo={steeringInfo}
    /> : <></>;
};

export default ViewQuestionnaire;
