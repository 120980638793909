import { combineReducers } from 'redux';
import SupplierRegReducer from '../features/SupplierRegistration/slice/supplier-reg.reducer';
import notificationsReducer from '../features/NotificationsChecker/reducer';
import CodesReducer from '../features/CodesModal/slice/codes.reducer';
import SupplyRegionsReducer from '../features/RegionsOfSupply/slice/supplyRegions.reducer';
import SupplyLocalitiesReducer from '../features/LocalityModal/slice/supplyLocalities.reducer';
import WorkflowsReducer from '../screens/EditProjectWorkflow/slice/workflows.reducer';
import SupplierReducer from '../screens/BrowseCleverQuotes/slice/supplier.reducer';


import constants from './_constants';

const appReducer = combineReducers({
    SupplierRegReducer,
    notifications: notificationsReducer,
    CodesReducer,
    SupplyRegionsReducer,
    SupplyLocalitiesReducer,
    WorkflowsReducer,
    SupplierReducer,
});


const rootReducer = (state, action) => {
    // Reset state across reducers on logout
    if (action.type === constants.AuthConstants.LOGOUT_USER) {
        // disabling eslint here because reducers are supposed to
        // return the initial state when they are called with undefined
        // as the first argument, no matter the action.
        // eslint-disable-next-line no-param-reassign
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
