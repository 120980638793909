import projectManagementAPIs from '../services/project-management.service';


const fetchUnreadData = async (resourceType, resourceId) => {
    const response = await projectManagementAPIs.getUnreadCorrespondence(
        resourceId, resourceType,
    );

    if (response.status !== 200) {
        return undefined;
    }

    // Sum of all unread topics, plus all unread messages
    const unreadCount = response.data.reduce((countThread, topic) => {
        if (!topic) return countThread;
        const topicUnreadMessages = topic.unreadThreads?.reduce(
            (countMsg, messages) => countMsg + (messages?.unreadMessages?.length ?? 0), 0,
        );
        if (topicUnreadMessages > 0) {
            // Not considering the topic itself if there was any unread message inside
            return countThread + topicUnreadMessages;
        } if (!topic.topicIsRead) {
            // Counting the topic if there are no messages inside and was not read yet
            return countThread + 1;
        }
        return countThread;
    }, 0);
    return unreadCount;
};

export default { fetchUnreadData };
