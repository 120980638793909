import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthContext from '../../context/AuthContext';
import rbac from '../../rbac';

const UserNavMenu = ({ userMenuData, handleToggleBtnClick }) => {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const generateLinks = () => {
        const elements = [];
        userMenuData.forEach((userMenu, idx) => {
            const attrs = {
                ...(userMenu.link !== '/logout' ? { to: userMenu.link, onClick: () => handleToggleBtnClick() } : { to: '', onClick: () => handleToggleBtnClick() || authContext.logout(() => history.push('/')) }),
            };
            if (userMenu.rbacAction && rbac.can(rbac.action[userMenu.rbacAction],
                [authContext.user.accountType.toLowerCase() + authContext.user.role])) {
                elements.push(<div key={`userNavMenuItem-${idx}`} className='userNavMenuItem'>
                    {userMenu.name.toLowerCase() !== 'analytics'
                        ? <Link key={`userMenuLink-${idx}`} className='body-small' { ...attrs }>{userMenu.name}</Link>
                        : <a className='body-small' target='_target' rel='noopener noreferrer' href = {userMenu.link}>{userMenu.name}</a>}
                </div>);
            } else if (!userMenu.rbacAction) {
                elements.push(<div key={`userNavMenuItem-${idx}`} className='userNavMenuItem'>
                    {userMenu.name.toLowerCase() !== 'analytics'
                        ? <Link key={`userMenuLink-${idx}`} className='body-small' { ...attrs }>{userMenu.name}</Link>
                        : <a className='body-small' target='_target' rel='noopener noreferrer' href = {userMenu.link}>{userMenu.name}</a>}
                </div>);
            }
        });
        return elements;
    };

    return <div id='userNavMenuContainer'>
        {generateLinks()}
    </div>;
};

UserNavMenu.propTypes = {
    userMenuData: PropTypes.array.isRequired,
    handleToggleBtnClick: PropTypes.func.isRequired,
};

export default UserNavMenu;
