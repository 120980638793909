import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DocumentBuilder from '../../features/DocumentBuilder';
import documentManagementAPIs from '../../services/document-management.service';
import Toast from '../../components/Alerts/Toast/Toast';

const DraftQuestionnaireSection = () => {
    const { templateType } = useParams();
    const history = useHistory();

    const constructAndPostQuestionnaireData = async (documentState) => {
        documentState.sections.forEach(async (sectionState) => {
            const questionsInSection = [];
            sectionState.sectionItems?.forEach((question) => {
                const questionToAdd = {
                    ...question,
                    attachments: question.attachments ? question.attachments : [],
                };
                questionsInSection.push(questionToAdd);
            });
            const postBody = {
                title: sectionState.title,
                description: sectionState.description,
                // templateType,
                questions: questionsInSection,
            };

            const responseData = await documentManagementAPIs.createSection(postBody);
            if (responseData.status === 201) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Questionnaire section created successfully.',
                });
                history.goBack();
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to create questionnaire section.',
                });
            }
        });
    };

    const handleSubmit = (documentState) => {
        constructAndPostQuestionnaireData(documentState);
    };

    return <DocumentBuilder
        documentMode={'Add'}
        context='Section'
        type={templateType}
        initialDocumentState={{
            details: { templateType },
            sections: [],
        }}
        sectionLabel='Section'
        sectionItemLabel='Question'
        allowSectionReordering={true}
        allowSectionItemsReordering={true}
        handlePrimaryToolbarSubmitButton={handleSubmit}
    />;
};

export default DraftQuestionnaireSection;
