import React from 'react';
import PropTypes from 'prop-types';

import LabelledInput, {
    LabelledInputTypeEmail,
} from '../../../../components/LabelledInput';

const SubmitEmail = ({ formData, handleInput }) => <>
    <p className='body py-10'>Don&apos;t worry, it happens to the best of us!</p>
    <LabelledInput
        id='email'
        type={LabelledInputTypeEmail}
        label='Your email'
        placeholder='Enter your email'
        value={formData.email || ''}
        onChange={(event) => handleInput(event)}
        breakColumn={false}
        required={true} />
</>;

SubmitEmail.propTypes = {
    formData: PropTypes.object,
    handleInput: PropTypes.func,
};

export default SubmitEmail;
