import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { fetchData } from './actions';
import AuthContext from '../../context/AuthContext';
import { userStatus } from '../../config/constants';

const NotificationsChecker = () => {
    const dispatch = useDispatch();
    const authContext = useContext(AuthContext);
    useEffect(() => {
        if (authContext?.user?.accountVerified === userStatus.approved
            && authContext?.user?.details?.verified === userStatus.approved) {
            dispatch(fetchData());
        } else {
            dispatch({ type: 'clearNotifications' });
        }
        const intervalID = setInterval(() => {
            if (authContext?.user?.accountVerified === userStatus.approved
                && authContext?.user?.details?.verified === userStatus.approved) {
                dispatch(fetchData());
            }
        }, 300000);
        return () => {
            clearInterval(intervalID);
        };
    }, []);

    return <div>
    </div>;
};

export default NotificationsChecker;
