import React from 'react';
import PropTypes from 'prop-types';

import DashboardCard from '../../../../components/DashboardCard';
import { allSteeringRoles } from '../../../../config/constants';

const SteeringGroups = ({
    id,
    headerTitle,
    actionBtn,
    actionBtnText,
    handlerActionBtn,
    icon,
    iconSrc,
    variant = 'secondary',
    additionalVariant,
    data,
}) => {
    const getRole = (role) => {
        let name = '';
        allSteeringRoles.forEach((steeringgrp) => {
            if (steeringgrp.role === role) {
                name = steeringgrp.name;
            } else if (role === 'supplier') {
                name = 'Supplier(s)';
            }
        });
        return name;
    };

    const steeringGroupContent = (steeringGroups) => steeringGroups.map((steeringGroup, idx) => (steeringGroup.userRole !== 'supplier' ? <div key={idx} className='steeringGroupsItem'>
        <div className='steeringGroupItem'>
            <p className='title'>{getRole(steeringGroup.userRole)}</p>
            <p className='caption'>{steeringGroup.users.join(', ')}</p>
        </div>
    </div>
        : <></>));

    return <DashboardCard
        id={id}
        size='small'
        header={true}
        headerTitle={headerTitle}
        actionBtn={actionBtn}
        actionBtnText={actionBtnText}
        handlerActionBtn={handlerActionBtn}
        variant={variant}
        iconSrc={iconSrc}
        icon={icon}
        content={(data && data.length > 0)
            ? <div className='steeringGroupsContainer'>{steeringGroupContent(data)}</div>
            : <p className='caption' >No steering group selected.</p>}
        additionalVariant={additionalVariant}
        footer={false}
        altContext='Edit steering group'
    />;
};

SteeringGroups.propTypes = {
    id: PropTypes.string.isRequired,
    headerTitle: PropTypes.string.isRequired,
    actionBtn: PropTypes.bool.isRequired,
    actionBtnText: PropTypes.string.isRequired,
    handlerActionBtn: PropTypes.func.isRequired,
    icon: PropTypes.bool.isRequired,
    iconSrc: PropTypes.string,
    variant: PropTypes.string,
    additionalVariant: PropTypes.string,
    data: PropTypes.array.isRequired,
};

export default SteeringGroups;
