import React from 'react';
import propTypes from 'prop-types';
import UploadItem from '../UploadItem';


const UploadItemContainer = ({
    documents,
    handleRemoveDocument,
    handleDownload,
    readOnly,
    showExpiryDate = true,
    userId,
}) => (<>
    {(documents.length > 0)
        ? <div className='document-body'>
            {documents.map((data, idx) => {
                let isReadOnly = false;
                if (userId !== data.uploadedBy) {
                    isReadOnly = true;
                }
                return <UploadItem
                    key={`document-${idx}`}
                    readOnly={readOnly || isReadOnly}
                    document={data}
                    removeDocument={handleRemoveDocument}
                    downloadFile={handleDownload}
                    showExpiryDate={showExpiryDate}
                />;
            })}
        </div>
        : <div key={'document-no-files'} className='document-body'><p className='caption'>No Documents uploaded.</p></div>
    }
</>
);


UploadItemContainer.propTypes = {
    documents: propTypes.array.isRequired,
    handleRemoveDocument: propTypes.func,
    handleDownload: propTypes.func.isRequired,
    readOnly: propTypes.bool,
    showExpiryDate: propTypes.bool,
    userId: propTypes.string,
};
export default UploadItemContainer;
