const gatewayOrchestrator = {
    refresh: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/auth/refresh`,
    upload: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/upload`,
    startSubmission: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/StartSubmission`,
    assignWorkflowToProject: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/AssignWorkflow`,
    verify: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/auth/verify`,
    dashboard: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/dashboard`,
    getWorkflow: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/GetWorkflow`,
    getQuestionnaire: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/GetQuestionnaire`,
    assign: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/assign`,
    template: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/default`,
    contract: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/contract`,
    exportPublicContracts: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/contract/public/export`,
    exportEvaluationSummary: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/evaluation/export`,
    exportAnswersSummary: `${process.env.MS_GATEWAY_HOST_LOCAL || process.env.MS_GATEWAY_HOST}/gateway/answers/export`,
};

export default gatewayOrchestrator;
