import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    differenceInDays,
    addDays,
    differenceInHours,
    addHours,
    differenceInMinutes,
    addMinutes,
} from 'date-fns';

const StandstillTimer = ({ timerEndDate }) => {
    const [days, setDays] = useState();
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();

    const handleClick = () => {
        let x = new Date();
        const y = new Date(timerEndDate);// yyyy-mm-dd hh:mm:ss
        let temp = differenceInDays(y, x);
        setDays(temp);
        x = addDays(x, temp);
        temp = differenceInHours(y, x);
        setHours(temp);
        x = addHours(x, temp);
        temp = differenceInMinutes(y, x);
        setMinutes(temp);
        x = addMinutes(x, temp);
    };


    useEffect(() => {
        setInterval(handleClick, 1000);
    }, [timerEndDate]);

    return (<>
        <div className='ssTimerContainer'>
            <div className='timerTile content'><p className='body bold'>Standstill Timer</p></div>
            <div className='timerTile'><p className='body' id='time'>{days < 0 ? 0 : days}</p><p className='body'>Days</p></div>
            <div className='timerDivider'></div>
            <div className='timerTile'><p className='body' id='time'>{hours < 0 ? 0 : hours}</p> <p className='body'>Hours</p></div>
            <div className='timerDivider'></div>
            <div className='timerTile'><p className='body' id='time'>{minutes < 0 ? 0 : minutes}</p> <p className='body'>Minutes</p></div>
        </div>
    </>);
};

StandstillTimer.propTypes = {
    timerEndDate: PropTypes.string.isRequired,
};

export default StandstillTimer;
