import React from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';

const SideDrawer = ({
    id,
    open,
    openDirection,
    tabletsAndMobileOnly = false,
    children,
}) => {
    let className = `side-drawer ${openDirection} ${tabletsAndMobileOnly && 'tablet-mobile-only'}`;
    className = (open) ? className.concat(' open') : className.replace(' open', '');
    return (
        <FocusTrap active={open}>
            <aside className={className} id={id}>
                {children}
            </aside>
        </FocusTrap>
    );
};

SideDrawer.propTypes = {
    id: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    openDirection: PropTypes.string.isRequired,
    tabletsAndMobileOnly: PropTypes.bool.isRequired,
    children: PropTypes.array.isRequired,
};

export default SideDrawer;
