const Constants = {
    sortOptions: [
        null,
        { label: 'Title (ascending)', value: 'title-ascending-string' },
        { label: 'Title (descending)', value: 'title-descending-string' },
        { label: 'Created on (newest)', value: 'createdOn-descending-dateTime' },
        { label: 'Created on (oldest)', value: 'createdOn-ascending-dateTime' },
    ],
};

export default Constants;
