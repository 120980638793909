import React from 'react';
import PropTypes from 'prop-types';

const LabelComponent = ({
    label,
    value,
}) => (
    <div className='vpnItem'>
        <div className='vpnLabel'>
            <p className='title sectionLabel' id='vpnItem-2'>
                {label}:
            </p>
            <p className='caption sectionValue'>
                {value}
            </p>
        </div>
    </div>
);

LabelComponent.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default LabelComponent;
