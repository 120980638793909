import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Constants from './constants';
import BrowseData from '../../features/BrowseData';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import AuthContext from '../../context/AuthContext';
import rbac from '../../rbac';

const BrowseStages = () => {
    const [stages, setStages] = useState([]);
    const { workflowName } = useParams();

    const history = useHistory();
    const authContext = useContext(AuthContext);

    const fetchData = async () => {
        try {
            const responseData = await projectManagementAPIs.listStages();
            if (responseData.status === 200) {
                setStages(responseData.data.items);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const createStage = (event) => {
        if (event) event.preventDefault();
        history.push('/stages/library/create');
    };

    return <BrowseData
        id='browseStages'
        context='Stages'
        contextSingular='Stage'
        dataSet={stages}
        action={{
            type: (workflowName) ? 'select' : 'view',
            workflowName,
        }}
        searchPlaceholder='Search for a stage'
        numOfRowsPerPage={15}
        searchFilterAttribute='title'
        sortOptions={Constants.sortOptions}
        allowAddNew={!workflowName && rbac.can(rbac.action.addStage,
            [authContext.user.accountType.toLowerCase() + authContext.user.role])}
        labelAddNew='Add'
        handleAddNew={(e) => createStage(e)}
        allowEdit={rbac.can(rbac.action.editStage,
            [authContext.user.accountType.toLowerCase() + authContext.user.role])}
    />;
};

export default BrowseStages;
