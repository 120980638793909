import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { parseISO, isPast } from 'date-fns';
import BrowseData from '../../BrowseData';
import {
    browseActivitiesId,
    browseDataContext,
    browseDataContextSingular,
    searchPlaceholder,
    ToastConstants,
    activityLogPostDeadline,
    sortOptions,
} from './constants';
import { workflowEvents, resourceTypes } from '../../../config/constants';
import projectManagementAPIs from '../../../services/project-management.service';
import Toast from '../../../components/Alerts/Toast/Toast';


const BrowseActivity = () => {
    const { resourceId, resourceType } = useParams();
    const [activityLog, setActivityLog] = useState([]);

    const getPublishedEvents = (projectData) => {
        const allEvents = projectData.workflow?.stages
            ?.reduce((acc, stage) => acc.concat(stage.events), []);
        return allEvents.filter(
            (event) => event.eventType === workflowEvents.publish && event.isPublished === true,
        );
    };

    const matchesWithActivityLogPreDeadline = (message) => {
        const findObject = Object.values(activityLogPostDeadline)
            .find((regExp) => regExp.test(message));
        return !findObject;
    };

    const eventIsActive = (event) => event && isPast(parseISO(event.submissionStartDate))
        && !isPast(parseISO(event.submissionEndDate));

    const fetchActivityLog = async () => {
        try {
            let response = [];
            if (resourceType === resourceTypes.project) {
                response = await projectManagementAPIs.getProject(resourceId);
            } else if (resourceType === resourceTypes.contract) {
                response = await projectManagementAPIs.getContract(resourceId);
            }
            const publishEvents = getPublishedEvents(response?.data);
            if (response.status === 200) {
                const activityLogList = response.data?.activityLog.filter((log) => {
                    const relatedEvent = publishEvents
                        .find((event) => log?.systemTimestamp < event?.submissionEndDate);
                    if (eventIsActive(relatedEvent)) {
                        return matchesWithActivityLogPreDeadline(log.message);
                    }
                    return true;
                });
                setActivityLog(activityLogList);
            }
        } catch (error) {
            Toast.fire({
                icon: ToastConstants.icon,
                titleText: ToastConstants.titleText,
            });
        }
    };

    useEffect(() => {
        fetchActivityLog();
    }, [resourceId, resourceType]);

    return <BrowseData
        id={browseActivitiesId}
        context={browseDataContext}
        contextSingular={browseDataContextSingular}
        dataSet={activityLog}
        searchPlaceholder={searchPlaceholder}
        searchFilterAttribute={''}
        sortOptions={sortOptions}
    />;
};

export default BrowseActivity;
