const filterTypes = {
    SELECT: 'select',
    RANGE: 'range',
    CODESANDCATEGORIES: 'codesandcategories',
    DELIVERYAREA: 'deliveryarea',
    LOCALITY: 'locality',
    INPUT: 'input',
    INPUT_RANGE: 'inputrange',
};

const filterLabels = {
    LOCALITY: 'Locality',
    STATUS: 'Status',
    RIGOUR_LEVEL: 'Rigour Level',
    WORKFLOW_TYPE: 'Workflow type',
    EVENT_TYPE: 'Event type',
    STAGE_TYPE: 'Stage type',
    ROUTE_MARKET: 'Route to Market',
    DEPARTMENT: 'Department',
    AUTHORITY: 'Authority',
    CREATED_BETWEEN: 'Created between',
    PRFS_STARTING_BETWEEN: 'Requests starting between',
    CONTRACTS_STARTING_BETWEEN: 'Contracts starting between',
    CONTRACTS_ENDING_BETWEEN: 'Contracts ending between',
    CODES_AND_CATEGORIES: 'Codes and categories',
    DELIVERY_AREA: 'Delivery area',
    QUESTIONNAIRE_TYPE: 'Questionnaire type',
    COMPANY_TYPE: 'Company type',
    EXPRESSION_WINDOW_BETWEEN: 'Expression window between',
    SUBMISSION_WINDOW_BETWEEN: 'Submission window between',
    COMPANY_SIZE_BETWEEN: 'Company size between',
    POSTCODE: 'Postcode',
    ESTIMATED_VALUE_BETWEEN: 'Estimated Value between',
    CALL_OFF_MECHANISM: 'Call off mechanism',
    SUBMISSION_ENDING_BETWEEN: 'Submission ending between',
};

const filterFields = {
    STATUS: 'status',
    PROJECT_STATUS: 'projectstatus',
    RTM: 'rtm',
    WORKFLOWTYPE: 'workflowtype',
    EVENTTYPE: 'eventtype',
    STAGETYPE: 'stagetype',
    AUTHORITY: 'authority',
    DEPARTMENTS: 'departments',
    PRFS_STARTING_BETWEEN: 'prfsstartingbetween',
    CONTRACTS_STARTING_BETWEEN: 'contractsstartingbetween',
    CONTRACTS_ENDING_BETWEEN: 'contractsendingbetween',
    CREATED_BETWEEN: 'createdbetween',
    CODESANDCATEGORIES: 'codesandcategories',
    DELIVERYAREA: 'deliveryarea',
    LOCALITY: 'locality',
    QUESTIONNAIRETYPE: 'questionnairetype',
    QUESTIONNAIRE_STATUS: 'questionnairestatus',
    COMPANY_TYPE: 'companytype',
    EXPRESSION_WINDOW_BETWEEN: 'expressionbetween',
    SUBMISSION_WINDOW_BETWEEN: 'submissionbetween',
    RIGOUR_LEVEL: 'rigourlevel',
    CONTRACT_STATUS: 'contractstatus',
    COMPANY_SIZE_BETWEEN: 'companysizebetween',
    POSTCODE: 'postcode',
    ESTIMATED_VALUE_BETWEEN: 'estimatedvaluebetween',
    SUBMISSION_ENDING_BETWEEN: 'submissionendingbetween',
    CALL_OFF_MECHANISM: 'calloffmechanism',
    STATUS_CALL_OFF: 'statuscalloff',
};

const fieldsByContext = {
    opportunities: [
        filterFields.STATUS,
        filterFields.AUTHORITY,
        filterFields.RTM,
        filterFields.CODESANDCATEGORIES,
        filterFields.DELIVERYAREA,
        filterFields.EXPRESSION_WINDOW_BETWEEN,
        filterFields.SUBMISSION_WINDOW_BETWEEN,
    ],
    contractsregister: [
        filterFields.CONTRACT_STATUS,
        filterFields.CONTRACTS_STARTING_BETWEEN,
        filterFields.CONTRACTS_ENDING_BETWEEN,
        filterFields.AUTHORITY,
    ],
    suppliers: [
        filterFields.COMPANY_TYPE,
        filterFields.CODESANDCATEGORIES,
        filterFields.DELIVERYAREA,
        filterFields.LOCALITY,
        filterFields.COMPANY_SIZE_BETWEEN,
        filterFields.POSTCODE,
    ],
    projects: [
        filterFields.AUTHORITY,
        filterFields.PROJECT_STATUS,
        filterFields.RTM,
        filterFields.CONTRACTS_STARTING_BETWEEN,
        filterFields.CODESANDCATEGORIES,
    ],
    calloff: [
        filterFields.CONTRACTS_STARTING_BETWEEN,
        filterFields.CONTRACTS_ENDING_BETWEEN,
        filterFields.SUBMISSION_ENDING_BETWEEN,
        filterFields.STATUS_CALL_OFF,
        filterFields.CALL_OFF_MECHANISM,
    ],
    requests: [
        filterFields.CONTRACTS_STARTING_BETWEEN,
        filterFields.ESTIMATED_VALUE_BETWEEN,
        filterFields.AUTHORITY,
    ],
    workflows: [
        filterFields.STATUS,
        // filterFields.WORKFLOWTYPE,
        filterFields.RTM,
        filterFields.AUTHORITY,
        filterFields.DEPARTMENTS,
        filterFields.CREATED_BETWEEN,
        // filterFields.CODESANDCATEGORIES,
        // filterFields.DELIVERYAREA,
    ],
    solutions: [
        filterFields.AUTHORITY,
        filterFields.CONTRACT_STATUS,
        filterFields.CODESANDCATEGORIES,
        filterFields.CREATED_BETWEEN,
    ],
    spends: [],
    rebates: [],
    questionnairesselect: [
        filterFields.AUTHORITY,
        filterFields.CREATED_BETWEEN,
        filterFields.DEPARTMENTS,
    ],
    questionnaires: [
        filterFields.QUESTIONNAIRETYPE,
        filterFields.AUTHORITY,
        filterFields.CREATED_BETWEEN,
        filterFields.DEPARTMENTS,
        filterFields.QUESTIONNAIRE_STATUS,
    ],
    sections: [],
    contracts: [
        filterFields.CONTRACT_STATUS,
        filterFields.RIGOUR_LEVEL,
        filterFields.CONTRACTS_STARTING_BETWEEN,
        filterFields.CONTRACTS_ENDING_BETWEEN,
    ],
    contracts_for_supplier: [
        filterFields.CONTRACT_STATUS,
        filterFields.CONTRACTS_STARTING_BETWEEN,
        filterFields.CONTRACTS_ENDING_BETWEEN,
    ],
    events: [
        filterFields.DEPARTMENTS,
        filterFields.EVENTTYPE,
        filterFields.STAGETYPE,
    ],
    stages: [
        filterFields.DEPARTMENTS,
        filterFields.STAGETYPE,
    ],
};

export default {
    fieldsByContext,
    filterTypes,
    filterLabels,
    filterFields,
};
