const Constants = {
    createdBy: 'Created By: ',
    createdOn: 'Created On: ',
    sentTo: 'Sent to: ',
    all: 'All',
    originalTopic: 'Original Topic: ',
    originalTopicLinkTitle: 'Link',
    companySize: 'Company Size: ',
    postcode: 'Postcode: ',
    locality: 'Locality: ',
    selectQuestionnaires: 'questionnairesselect',
    noResults: 'No results found',
    submissionEndDateLabel: 'Submission end date:',
    contractStartDateLabel: 'Contract Start Date:',
    contractEndDateLabel: 'Contract End Date:',
    descriptionLabel: 'Description:',
};

export default Constants;
