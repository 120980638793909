import instance from '../config/axios.instance';
import endpoints from '../config/endpoints';

const { monitoring } = endpoints;

const monitoringAPIs = {
    getSupplierById: async (companyNumber) => {
        try {
            const endpoint = monitoring.searchSupplierById;
            const response = await instance.get(`${endpoint}/${companyNumber}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getClassificationCodes: async (codeType) => {
        try {
            const endpoint = monitoring.getClassificationCodes;
            const response = await instance.get(`${endpoint}/${codeType}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
};
export default monitoringAPIs;
