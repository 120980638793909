import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
    id,
    type,
    variant = 'primary',
    additionalVariant = '',
    size = '',
    label,
    disabled = false,
    icon = false,
    iconSrc,
    handleClick,
    form,
    alt,
    className,
    onBlur = null,
    buttonReference = null,
}) => (<button
    type={type}
    className={`button title ${size} ${variant} ${className || ''} ${additionalVariant}${disabled ? ' disabled' : ''}`}
    id={id}
    form={form}
    onClick={handleClick}
    onBlur={onBlur}
    ref={buttonReference}
    disabled={disabled}>
    {icon && <img src={iconSrc} alt={alt} className='button-icon'></img>}
    {label}
</button>);

Button.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    variant: PropTypes.string,
    additionalVariant: PropTypes.string,
    size: PropTypes.string,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.bool,
    iconSrc: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
    form: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    onBlur: PropTypes.func,
    buttonReference: PropTypes.func,
};

export default Button;
