import constants from '../../../reducers/_constants';

const setCodes = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_CODES,
    payload,
});

const setOnlySelectedCodes = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_ONLY_SELECTED_CODES,
    payload,
});

const clearCodes = () => ({
    type: constants.SupplierRegistrationConstants.CLEAR_CODES,
});


export default {
    setCodes,
    setOnlySelectedCodes,
    clearCodes,
};
