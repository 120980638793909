import rbac from '../rbac';
import {
    evaluationStatuses,
    questionnaireTypes,
    CURRENT,
    ID,
    COMPANY_NAME,
    DISQUALIFY_REASON,
    RESPONSE_ID,
    SCORE,
    SCORE_TITLE,
    PASS_FAIL_RESULT,
    PASS_FAIL_RESULT_TITLE,
    TOTAL_PASS_FAIL_RESULT,
    TOTAL_SCORE,
    TOTAL_SCORE_TITLE,
    FAIL,
    FAIL_TITLE,
    PASS_TITLE,
} from '../config/constants';
import helperFunctions from '../utils/helperFunctions';
import { treeTypes } from '../components/TreeViewer/index';
import actions from '../rbac/constants';

const getEvaluationUserPermissions = (userSteeringRole) => ({
    submitEvaluationsForApproval: rbac
        .can(rbac.action.submitEvaluationsForApproval, userSteeringRole),
    approveStage1: rbac.can(rbac.action.approveStage1, userSteeringRole),
    approveStage2: rbac.can(rbac.action.approveStage2, userSteeringRole),
    evaluateQuestionnaire: rbac.can(rbac.action.evaluateQuestionnaire, userSteeringRole),
    viewEvaluateQuestionnaire: rbac.can(rbac.action.viewEvaluateQuestionnaire, userSteeringRole),
    disqualifySuppliers: rbac.can(rbac.action.disqualifySuppliers, userSteeringRole),
    awaitingApproval: rbac.can(rbac.action.awaitingApproval, userSteeringRole),
});

const allResponsesHaveEvaluationComplete = (responses) => {
    const allEvaluationStatus = responses
        ?.map((response) => response.evaluationStatus);
    return allEvaluationStatus?.every(
        (status) => status === evaluationStatuses.evaluationComplete,
    );
};
const getSubmissionEvaluationStatus = (responses) => {
    const response = {
        legalStatus: responses[0].legalStatus || undefined,
        evaluationStatus: responses[0].evaluationStatus || undefined,
    };
    if (response?.evaluationStatus === evaluationStatuses.evaluationComplete) {
        return allResponsesHaveEvaluationComplete(responses)
            ? response.evaluationStatus : evaluationStatuses.underEvaluation;
    }
    return response.evaluationStatus || response.legalStatus;
};

const showSubmissionStatusLabel = (evaluationStatus, legalStatus) => {
    const status = (evaluationStatus === evaluationStatuses.notStarted
        && (!legalStatus || legalStatus?.trim() === '')) ? evaluationStatuses.legalNotApplicable : evaluationStatus || legalStatus;

    if (status === evaluationStatuses.evaluationComplete
        || status === evaluationStatuses.legalNotApplicable) {
        return false;
    }
    return true;
};

const getSubmissionsGroupBySubmissionNumber = (responses) => {
    const allSubmissionsNumbers = responses
        ?.map((response) => response.supplierSubmissionNumber);

    const submissions = [...new Set(allSubmissionsNumbers)]
        .reduce((acc, submissionNumber) => ({
            ...acc,
            [submissionNumber]: responses.filter((response) => response
                .supplierSubmissionNumber === submissionNumber),
        }), {});
    return submissions;
};

const getSupplierSubmissionNumber = (firstResponse) => ((typeof firstResponse?.supplierSubmissionNumber === 'number') ? firstResponse.supplierSubmissionNumber : CURRENT);

const getSubmissionNumberIntentStatus = (intentStatuses,
    supplierSubmissionNumber) => (intentStatuses
    .find((supplierIntentStatus) => supplierIntentStatus
        .submissionNumber === supplierSubmissionNumber));

const showPassFailLabel = (responses) => responses?.some(
    (response) => response?.passFailResult,
);

const generatePassFailResult = (responses) => (responses
    ?.some((response) => response.passFailResult?.toLowerCase() === FAIL)
    ? FAIL_TITLE : PASS_TITLE);

const calculateScore = (responses) => responses?.reduce(
    (acc, response) => acc + parseInt(response.score, 10), 0,
) || 0;

const calculateWeightage = (responses) => responses?.reduce(
    (acc, response) => acc + response.weightage,
    0,
) || 0;

const lotWithIncompleteEvaluations = (responses, userPermissions) => {
    const allResponses = responses?.reduce((acc, response) => acc
        .concat(response.responses), []);
    return allResponses?.some((response) => (response
        ?.evaluationStatus !== evaluationStatuses.approved)
        || (response?.evaluationStatus === evaluationStatuses.awaitingApproval
            && userPermissions[rbac.action.approveStage1])
            || (response?.evaluationStatus === evaluationStatuses.stage1Approved
                && userPermissions[rbac.action.approveStage2])) || false;
};


const findResponsesById = (responses, idKey, id) => responses
    ?.find((response) => response[idKey] === id);


const getPrettyQuestionnaireType = (qType) => questionnaireTypes.find(
    (eventType) => eventType.value === qType,
)?.label || '';


const getOnClickOptions = (options, response) => {
    const optionMapping = {
        [ID]: response.supplierAccountID,
        [COMPANY_NAME]: response?.supplier?.companyName,
        [DISQUALIFY_REASON]: response?.disqualificationReason?.internalReason,
        [RESPONSE_ID]: response.responseID,
    };
    return options?.map((option) => optionMapping[option] ?? option) ?? [];
};

const getLineButtons = (lineButtons, userPermissions, readOnly, response, id) => {
    const menu = [];
    if (lineButtons?.length) {
        const allPermissions = lineButtons.reduce((acc, group) => ({
            ...acc,
            [group.permission]: group.buttons,
        }), {});
        Object.entries(allPermissions).forEach(([permission, buttons]) => {
            menu.push(
                ...buttons
                    .filter((button) => !(readOnly && button.showIfReadOnly === false)
                    && userPermissions[permission])
                    .map((button) => {
                        const onClickOptions = getOnClickOptions(button?.onClickOptions, response);
                        return {
                            id: `${button.id}-${id}`,
                            type: treeTypes.MENU_OPTIONS.BUTTON,
                            size: 'small',
                            label: button.label,
                            ...(button.additionalVariant
                                && { additionalVariant: button.additionalVariant }),
                            clickType: button.clickType,
                            ...(button.onClick && { onClick: `${button.onClick}${response.responseID}` }),
                            ...(onClickOptions && { onClickOptions }),
                        };
                    }),
            );
        });
    }
    return menu;
};

const getLabelText = (labelText, id, object) => {
    const labelMappings = {
        [SCORE]: object.weightage
            ? `${SCORE_TITLE}: ${object.score}/${object.weightage}`
            : '',
        [PASS_FAIL_RESULT]: object.passFailResult
            ? `${PASS_FAIL_RESULT_TITLE}: ${helperFunctions.camel2title(object.passFailResult)}`
            : '',
        [TOTAL_SCORE]: object?.length && calculateWeightage(object) !== 0
            ? `${TOTAL_SCORE_TITLE}: ${calculateScore(object)}/${calculateWeightage(object)}`
            : '',
        [TOTAL_PASS_FAIL_RESULT]: object?.length && showPassFailLabel(object)
            ? `${PASS_FAIL_RESULT_TITLE}: ${helperFunctions.camel2title(generatePassFailResult(object))}`
            : '',
    };
    return labelText || (id in labelMappings ? labelMappings[id] : '');
};

const labelIsShown = (readOnly, isCurrentSubmission, label) => {
    if (label.showIfReadOnly !== undefined && readOnly && !label.showIfReadOnly) {
        return false;
    }
    if (label.showIfPreviousSubmission !== undefined) {
        return (isCurrentSubmission && label.showIfPreviousSubmission === false);
    }
    return true;
};

const getLineLabels = (readOnly, isCurrentSubmission, lineLabels, object, id) => lineLabels
    .filter((label) => labelIsShown(readOnly, isCurrentSubmission, label))
    .map((label) => ({
        id: `${label.id}-${id}`,
        type: label.type,
        color: label.color,
        labelTxt: getLabelText(label.labelTxt, label.id, object),
    }));

const removePermissionFromPermissions = (userPermissions, permission) => {
    const clonedUserPermissions = { ...userPermissions };
    if (Object.prototype.hasOwnProperty.call(userPermissions, permission)) {
        delete clonedUserPermissions[permission];
    }
    return clonedUserPermissions;
};

const buttonsContainButton = (responseButtons, buttonId) => {
    const buttons = responseButtons
        ?.reduce((acc, response) => acc.concat(response.buttons), []);
    return buttons?.some((button) => button.id === buttonId);
};

const getElementWithUniqueId = (element, treeViewId) => ({
    ...element,
    id: `${element.id }-${ treeViewId}`,
});

const removeViewPermissionIfEvaluator = (responseButtons, userPermissions, dpsConstants) => {
    let clonedUserPermissions = { ...userPermissions };
    if (userPermissions.evaluateQuestionnaire
        && (buttonsContainButton(responseButtons, dpsConstants.buttonEvaluateQuestionnaireId)
        || buttonsContainButton(responseButtons, dpsConstants.buttonResumeQuestionnaireId))) {
        clonedUserPermissions = removePermissionFromPermissions(clonedUserPermissions,
            actions.viewEvaluateQuestionnaire);
    }
    return clonedUserPermissions;
};

const getTreeViewId = (indexes) => indexes?.reduce(
    (acc, item) => `${acc === '' ? '' : `${acc }-`}${item.id}-${item.value}`,
    '',
) || '';

const getAllLotsMap = (currentLots, previousLots, userPermissions) => {
    const allLots = [...(currentLots || []), ...(previousLots || [])];
    const allLotsWithIncompleteEvaluations = allLots.map((lot) => (
        {
            ...lot,
            withIncompleteEvaluations: lotWithIncompleteEvaluations(lot?.responses,
                userPermissions),
        }));
    const allLotsSorted = allLotsWithIncompleteEvaluations
        .sort((a, b) => (+b.withIncompleteEvaluations) - (+a.withIncompleteEvaluations)
        || a.lotTitle.localeCompare(b.lotTitle));

    return allLotsSorted?.reduce((acc, response) => ({ ...acc, [response.lotID]: response }), {});
};

const getAllSuppliersMap = (responses) => {
    const allSuppliersSorted = responses
        ?.sort((a, b) => (+b.withAction) - (+a.withAction) || a?.account?.companyName
            .localeCompare(b?.account?.companyName));
    return allSuppliersSorted?.reduce((acc, response) => ({
        ...acc,
        [response.accountID]: response,
    }), {});
};

/**
 *
 * @param {*} currentResponses
 * @param {*} supplierId
 * @returns all responses of all lots of a supplier. It's needed to know all
 * evaluation statuses of all responses of a supplier submission
 */
const getSupplierAllLotsResponses = (currentResponses, supplierId) => currentResponses
    ?.reduce((acc, response) => acc.concat(
        ...response.responses
            ?.filter((supplier) => supplier.accountID === supplierId)
            ?.reduce((acc2, supplier) => acc2.concat(supplier.responses), []),
    ), []);


export {
    getEvaluationUserPermissions,
    getSubmissionEvaluationStatus,
    showPassFailLabel,
    generatePassFailResult,
    calculateScore,
    calculateWeightage,
    lotWithIncompleteEvaluations,
    showSubmissionStatusLabel,
    getLineLabels,
    buttonsContainButton,
    removePermissionFromPermissions,
    getLineButtons,
    findResponsesById,
    getSubmissionsGroupBySubmissionNumber,
    getSupplierSubmissionNumber,
    getSubmissionNumberIntentStatus,
    getPrettyQuestionnaireType,
    getElementWithUniqueId,
    removeViewPermissionIfEvaluator,
    getTreeViewId,
    getAllLotsMap,
    getAllSuppliersMap,
    getSupplierAllLotsResponses,
};
