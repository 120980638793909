import React from 'react';
import PropTypes from 'prop-types';
import GuidanceBanner from '../../../../components/GuidanceBanner';
import dpsConstants from '../../dpsConstants';
import { getTextQuestionnaires, refreshAnswers } from '../../helpers';
import { responseStatus } from '../../../../config/constants';

const GenerateRefreshGuidance = ({
    submissionsData, questionnaireAwaitingRefresh, projectId, eventId, getAnswerSummaryDPSResponse,
}) => (questionnaireAwaitingRefresh?.length > 0 ? <div id='answerSummaryBanner'><GuidanceBanner
    id={'questionnaireRefreshGuidance'}
    type={'warning'}
    headerText={dpsConstants.refreshHeaderText}
    bannerText1={dpsConstants.refreshBannerText1}
    bannerText2={getTextQuestionnaires(questionnaireAwaitingRefresh)}
    buttonArr={[{
        id: 'btn-WithdrawAll',
        label: dpsConstants.review,
        size: 'medium',
        variant: 'secondary',
        action: () => refreshAnswers(projectId, eventId, getAnswerSummaryDPSResponse),
        allowAction: true,
        disabled: (submissionsData.projectStatus === responseStatus.cancelled),
    }]}
/> </div> : <></>);

GenerateRefreshGuidance.propTypes = {
    submissionsData: PropTypes.object,
    questionnaireAwaitingRefresh: PropTypes.bool,
    projectId: PropTypes.string,
    eventId: PropTypes.string,
    getAnswerSummaryDPSResponse: PropTypes.func.isRequired,
};

export default GenerateRefreshGuidance;
