import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import projectManagementAPIs from '../../services/project-management.service';
import SummaryBuyer from '../../features/SummaryBuyer';
import { evaluationStatuses, publishAtLevel } from '../../config/constants';
import Toast from '../../components/Alerts/Toast/Toast';
import Confirm from '../../components/Alerts/Confirm/Confirm';
import gatewayOrchestratorAPIs from '../../services/gateway.service';
import {
    handleEvaluationMultipleDownload,
} from '../../utils/uploadHelper';

const EvaluationSummary = () => {
    const [evaluationDetails, setEvaluationDetails] = useState([]);
    const [previousResponses,
        setPreviousResponses] = useState();
    const [projectDetails, setProjectDetails] = useState({});
    const { projectId, eventId } = useParams();

    const arrangeSuppliers = (data) => {
        if (data?.publishLevel === 'lot' && data?.legalUserView === false) {
            const completeLots = [];
            const incompleteLots = [];
            data?.responses?.forEach((lot) => {
                let lotCheck = true;
                const completeSuppliers = [];
                const incompleteSuppliers = [];
                lot?.responses?.forEach((supplier) => {
                    let supCheck = true;
                    const completeResponses = [];
                    const incompleteResponses = [];
                    supplier.responses.forEach((response) => {
                        if (data.dpsRulesApplicable === true
                            && response.evaluationStatus === evaluationStatuses.approved
                        ) {
                            completeResponses.push(response);
                        } else if (
                            data.dpsRulesApplicable === false
                            && response.evaluationStatus === evaluationStatuses.evaluationComplete
                        ) {
                            completeResponses.push(response);
                        } else {
                            incompleteResponses.push(response);
                            supCheck = false;
                        }
                    });
                    const arrangedResponses = incompleteResponses.concat(completeResponses);
                    if (supCheck === true) {
                        completeSuppliers.push(supplier);
                    } else {
                        lotCheck = false;
                        incompleteSuppliers.push({
                            ...supplier,
                            responses: arrangedResponses,
                        });
                    }
                });
                const arrangedSuppliers = incompleteSuppliers.concat(completeSuppliers);
                if (lotCheck === true) {
                    completeLots.push(lot);
                } else {
                    incompleteLots.push({
                        ...lot,
                        responses: arrangedSuppliers,
                    });
                }
            });
            const arrangedLots = incompleteLots.concat(completeLots);
            const evaluationData = Object.create(data);
            evaluationData.responses = arrangedLots;
            setEvaluationDetails(evaluationData);
            return [];
        }

        if (data?.publishLevel === 'project' && data?.legalUserView === false) {
            const completeSuppliers = [];
            const incompleteSuppliers = [];
            data?.responses?.forEach((supplier) => {
                let supCheck = true;
                const completeResponses = [];
                const incompleteResponses = [];
                supplier.responses.forEach((response) => {
                    if (data.dpsRulesApplicable === true
                        && response.evaluationStatus === evaluationStatuses.approved
                    ) {
                        completeResponses.push(response);
                    } else if (
                        data.dpsRulesApplicable === false
                        && response.evaluationStatus === evaluationStatuses.evaluationComplete
                    ) {
                        completeResponses.push(response);
                    } else {
                        incompleteResponses.push(response);
                        supCheck = false;
                    }
                });
                const arrangedResponses = incompleteResponses.concat(completeResponses);
                if (supCheck === true) {
                    completeSuppliers.push(supplier);
                } else {
                    incompleteSuppliers.push({
                        ...supplier,
                        responses: arrangedResponses,
                    });
                }
            });
            const arrangedSuppliers = incompleteSuppliers.concat(completeSuppliers);
            const evaluationData = Object.create(data);
            evaluationData.responses = arrangedSuppliers;
            setEvaluationDetails(evaluationData);
            return [];
        }
        setEvaluationDetails(data);
        return [];
    };

    const getAllEvaluationsRequest = useCallback(async () => {
        try {
            const response = await projectManagementAPIs.getEvaluationSummary(projectId, eventId);
            const projectResponse = await projectManagementAPIs.getProject(projectId);
            if (response?.data?.responses?.length > 0) {
                arrangeSuppliers(response.data);
            } else {
                setEvaluationDetails(response.data);
            }
            setProjectDetails(projectResponse.data);

            if (response?.data?.isProjectDPS
                && response?.data?.publishLevel === publishAtLevel.lot) {
                const previousEvaluationSummary = await projectManagementAPIs
                    .getDPSEvaluationSummary(projectId, eventId);
                setPreviousResponses(previousEvaluationSummary.data.responses);
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    }, [projectId, eventId]);

    const openAllSeals = async () => {
        const response = await projectManagementAPIs.openAllSeals(projectId, eventId);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Successfully opened seals.',
            });
            getAllEvaluationsRequest();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to open seals.',
            });
        }
    };

    const openSeal = async (accountId) => {
        const response = await projectManagementAPIs.openSeal(projectId, eventId, accountId);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Successfully opened seal.',
            });
            getAllEvaluationsRequest();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to open seal.',
            });
        }
    };

    const submitAllForApproval = async (accountId) => {
        const response = await projectManagementAPIs.submitAllForApproval(
            projectId, eventId, accountId,
        );
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Successfully submitted for approval.',
            });
            getAllEvaluationsRequest();
        } else if (response.data.ErrorCode === '9000') {
            Toast.fire({
                icon: 'error',
                titleText: 'Evaluation is not completed for all responses. Please complete the evaluation then submit for approval.',
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to submit for approval.',
            });
        }
    };

    const handleDisqualifySupplier = async (data) => {
        Confirm(async () => {
            const payload = {
                supplierAccountID: data.supplierAccountID,
                internalReason: data.internalReason,
                projectID: projectId,
                eventID: eventId,
            };
            const response = await projectManagementAPIs.disqualifySupplier(payload);
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Successfully disqualified supplier.',
                });
                getAllEvaluationsRequest();
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to disqualify supplier.',
                });
            }
        },
        {
            subTitle: 'Are you sure you want to disqualify this supplier? This action cannot be reversed.',
        });
    };

    const handleStatusUpdate = async (status, action = null, accountId) => {
        if (status === evaluationStatuses.released) {
            if (accountId) {
                openSeal(accountId);
            } else {
                openAllSeals();
            }
            return;
        }
        if (status === evaluationStatuses.evaluationComplete) {
            submitAllForApproval(accountId);
            return;
        }

        const payload = {
            action,
        };
        const response = await projectManagementAPIs.approveEvaluations(
            projectId, eventId, payload, accountId,
        );
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Approval status updated successfully',
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update approval status.',
            });
        }
        getAllEvaluationsRequest();
    };

    const handleAwardsUpdate = async (payLoad) => {
        try {
            const updateResponse = await projectManagementAPIs
                .updateAwardsResponseByProjectId(projectId, payLoad);
            if (updateResponse.status === 200) {
                getAllEvaluationsRequest();
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update awards.',
            });
        }
    };

    const handleSendAwards = async () => {
        try {
            const sendResponse = await projectManagementAPIs.getAllAwarded(projectId);
            if (sendResponse.status === 200) {
                getAllEvaluationsRequest();
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to send award.',
            });
        }
    };

    const exportFunction = async () => {
        const response = await gatewayOrchestratorAPIs.exportEvaluationSummary(projectId);
        if (response.status === 200) {
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const filename = response.headers['content-disposition']?.split('"')[1] || 'export.csv';
            try {
                saveAs(blob, filename);
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to download document.',
                });
            }
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to download document.',
            });
        }
    };

    useEffect(() => {
        getAllEvaluationsRequest();
    }, [getAllEvaluationsRequest]);

    return evaluationDetails?.responses?.length > 0
        ? <SummaryBuyer
            projectDetails={projectDetails}
            dataSet={evaluationDetails}
            handleStatusUpdate={handleStatusUpdate}
            handleAwardsUpdate={handleAwardsUpdate}
            handleSendAwards={handleSendAwards}
            handleDisqualifySupplier={handleDisqualifySupplier}
            isDPS={(projectDetails?.projectDescribes?.includes('dps') && evaluationDetails.dpsRulesApplicable) || false}
            readOnly={projectDetails?.projectStatus === 'cancelled'}
            exportData = {exportFunction}
            handleEvaluationMultipleDownload={handleEvaluationMultipleDownload}
            isProjectDPS = {evaluationDetails.isProjectDPS}
            publishLevel = {evaluationDetails.publishLevel}
            previousResponses = {previousResponses}
            eventId = {eventId}
        />
        : <div id={'evaluationContainer'}>
            {Object.keys(evaluationDetails).length > 0
                ? <>
                    {evaluationDetails?.eventStatus !== 'awaitingPublish'
                        ? <>{evaluationDetails?.immeadiateResponse === true
                            || evaluationDetails?.dpsRulesApplicable === true
                            ? <p className={'caption'}>There {`${evaluationDetails?.numSubmissions === 1 ? 'is' : 'are'}`} {`${evaluationDetails?.numSubmissions || 0}`} {`${evaluationDetails?.numSubmissions === 1 ? 'submission' : 'submissions'}`}. To view, you must be added to the Project Steering Group.</p>
                            : <>{evaluationDetails?.isDeadlinePassed === true
                                ? <p className={'caption'}>There {`${evaluationDetails?.numSubmissions === 1 ? 'is' : 'are'}`} {`${evaluationDetails?.numSubmissions || 0}`} {`${evaluationDetails?.numSubmissions === 1 ? 'submission' : 'submissions'}`} and the deadline has passed. To view, you must be added to the Project Steering Group.</p>
                                : <p className={'caption'}>There {`${evaluationDetails?.numSubmissions === 1 ? 'is' : 'are'}`} {`${evaluationDetails?.numSubmissions || 0}`} {`${evaluationDetails?.numSubmissions === 1 ? 'submission' : 'submissions'}`} and the deadline has not yet passed.</p>
                            }</>
                        }</>
                        : <p className={'caption'}>This opportunity is not yet published.</p>
                    }
                </>
                : <></>}
        </div>;
};

export default EvaluationSummary;
