/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    InputComponent,
    DatePickerComponent,
    SelectSearchComponent,
} from './components';
import LabelledTextArea from '../../components/LabelledTextArea';
import Button from '../../components/Button';
import addEllipseIcon from '../../styles/images/addEllipseIcon.svg';
import Tag from '../../components/Tag';
import LabelledRadioButton from '../../components/LabelledRadioButton';
import LabelledCheckBox from '../../components/LabelledCheckBox';
import LabelledSelect from '../../components/LabelledSelect';
import FTSCriteria from './FTSCriteria';
import cpvSupplementaryCode from './cpvSupplementaryCode';

const SelectCPVCodesModal = React.lazy(() => import('../CodesModalCPV/SelectCPVCodesModal'));
const SelectDACodesModal = React.lazy(() => import('../RegionsOfSupplyDA/SelectDAModal'));

const FTSLots = ({
    noticeEvent,
    prefix,
    formFields,
    handleChange,
    lotDetails = true,
}) => {
    const [showCodesModal, setShowCodesModal] = useState(false);
    const [showDaModal, setShowDaModal] = useState(false);

    const generateDropdownOptions = (key, option) => (
        <option key={key} value={option.value}>{option.label}</option>
    );

    const handleRemoveCPVCode = () => {
        handleChange('', `${prefix}.cpvAdditional.0.cpvCode`);
        handleChange([], `${prefix}.cpvAdditional.0.notMappedCpvCode`);
    };

    const generateCodeTag = (data, isDeletable) => {
        const TagList = [];

        data.forEach((item) => {
            const TagElement = <>
                <Tag key={item.code}
                    id={item.code}
                    tagTxt={`${item.code} - ${item.description}`}
                    isDeletable={isDeletable}
                    size='large'
                    handleDeleteBtnClick={() => handleRemoveCPVCode()}
                />
                <input type='hidden' name={`${prefix}.cpvAdditional.0.cpvCode`} value={item.code} />
            </>;
            TagList.push(TagElement);
        });

        return (
            <div className='vpnItemTags'>
                {TagList}
            </div>
        );
    };

    const handleUpdateCPVCodes = (data) => {
        if (data.length > 0) {
            handleChange(data[0].code, `${prefix}.cpvAdditional.0.cpvCode`);
            handleChange(data, `${prefix}.cpvAdditional.0.notMappedCpvCode`);
        } else {
            handleChange('', `${prefix}.cpvAdditional.0.cpvCode`);
            handleChange([], `${prefix}.cpvAdditional.0.notMappedCpvCode`);
        }
    };

    const handleRemoveDA = () => {
        handleChange('', `${prefix}.n2021`);
        handleChange([], `${prefix}.notMappedn2021Code`);
    };

    const generateDATag = (data, isDeletable) => {
        const TagList = [];

        data.forEach((item, index) => {
            const TagElement = <>
                <Tag key={item.code}
                    id={item.code}
                    tagTxt={`${item.code} - ${item.description}`}
                    isDeletable={isDeletable}
                    size='large'
                    handleDeleteBtnClick={() => handleRemoveDA()}
                />
                <input type='hidden' name={`${prefix}.n2021.${index}`} value={item.code} />
            </>;
            TagList.push(TagElement);
        });

        return (
            <div className='vpnItemTags'>
                {TagList}
            </div>
        );
    };

    const handleUpdateDACodes = (data) => {
        if (data.length > 0) {
            handleChange([data[0].code], `${prefix}.n2021`);
            handleChange(data, `${prefix}.notMappedn2021Code`);
        } else {
            handleChange('', `${prefix}.n2021`);
            handleChange([], `${prefix}.notMappedn2021Code`);
        }
    };

    const getPropObjValue = (object) => {
        const parts = prefix?.split('.');
        let returnProp = null;
        if (parts !== null && parts !== undefined) {
            for (let i = 0; i < parts.length; i++) {
                if (object[parts[i]]) {
                    returnProp = object[parts[i]];
                    // eslint-disable-next-line no-param-reassign
                    object = returnProp;
                } else { return null; }
            }
        }
        return returnProp;
    };

    return (<>
        {lotDetails === true
            ? <>
                <h3 className='title-large subHeading'>II.2.1. Lot title</h3>
                <InputComponent
                    id={`${prefix}.title`}
                    label='Lot title'
                    value={getPropObjValue(formFields)?.title}
                    type='text'
                    onChange={handleChange}
                    required={false}
                />
                <InputComponent
                    id={`${prefix}.lotNo`}
                    label='Lot number'
                    value={getPropObjValue(formFields)?.lotNo}
                    type='number'
                    onChange={handleChange}
                    required={true}
                />
            </>
            : <h3 className='title-large subHeading'>II.2. Description</h3>
        }
        <h3 className='title-large subHeading'>II.2.2. Additional CPV codes </h3>
        <div className='vpnItem'>
            <div className='vpnItemNutsCode'>
                <h4 className='title'>Additional CPV codes</h4>
                <Button
                    id='addCodeButton'
                    size='small'
                    variant='secondary skinless'
                    label='Add'
                    icon={true}
                    iconSrc={addEllipseIcon}
                    disabled={false}
                    handleClick={(e) => {
                        e.preventDefault();
                        setShowCodesModal(true);
                    }}
                />
            </div>
            {getPropObjValue(formFields)?.cpvAdditional
                ? generateCodeTag(getPropObjValue(formFields)?.cpvAdditional[0]?.notMappedCpvCode || [], true)
                : null}
        </div>
        <div className='formElementsDivider'></div>
        <SelectSearchComponent
            id={`${prefix}.notMapped.cpvAdditional.0.cpvSupplementaryCodes`}
            label='Additional Supplementary Code'
            value={getPropObjValue(formFields)?.notMapped?.cpvAdditional ? getPropObjValue(formFields)?.notMapped?.cpvAdditional[0]?.cpvSupplementaryCodes : ''}
            onChange={(e) => handleChange(e, `${prefix}.notMapped.cpvAdditional.0.cpvSupplementaryCodes`)}
            required={true}
            options={cpvSupplementaryCode}
            optionInputType='obj'
        />
        <input
            type='hidden'
            name={`${prefix}.cpvAdditional.0.cpvSupplementaryCodes`}
            id={`${prefix}.cpvAdditional.0.cpvSupplementaryCodes`}
            value={getPropObjValue(formFields)?.notMapped?.cpvAdditional ? getPropObjValue(formFields)?.notMapped?.cpvAdditional[0]?.cpvSupplementaryCodes : ''}
        />
        <div className='formElementsDivider'></div>
        <h3 className='title-large subHeading'>II.2.3. Place of performance</h3>
        <div className='vpnItem'>
            <div className='vpnItemNutsCode'>
                <h4 className='title'>NUTS Code</h4>
                <Button
                    id='addCodeButton'
                    size='small'
                    variant='secondary skinless'
                    label='Add'
                    icon={true}
                    iconSrc={addEllipseIcon}
                    disabled={false}
                    handleClick={(e) => {
                        e.preventDefault();
                        setShowDaModal(true);
                    }}
                />
            </div>
            {generateDATag(getPropObjValue(formFields)?.notMappedn2021Code || [], true)}
        </div>
        <div className='formElementsDivider'></div>
        <LabelledTextArea
            id={`${prefix}.mainSite`}
            breakColumn={true}
            label='Main site or place of performance'
            value={getPropObjValue(formFields)?.mainSite || ''}
            maxCharCount={1000}
            onChange={handleChange}
            required={false}
        />
        <div className='formElementsDivider'></div>
        <h3 className='title-large subHeading'>II.2.4 Description of the procurement</h3>
        <div className='formElementsDivider'></div>
        <LabelledTextArea
            id={`${prefix}.shortDescr`}
            breakColumn={true}
            label='Description of the procurement'
            value={getPropObjValue(formFields)?.shortDescr || ''}
            maxCharCount={10000}
            onChange={handleChange}
            required={true}
        />
        {formFields?.notice?.type !== 'PRI_ONLY'
        && <>
            <div className='formElementsDivider'></div>
            <h4 className='title-large subHeading'>II.2.5. Award criteria</h4>
            {noticeEvent?.noticeType !== 'award' && <LabelledRadioButton
                id={`${prefix}.awardCriteriaType`}
                label='Award criteria'
                breakColumn={false}
                onChange={handleChange}
                options={[{
                    label: 'Criteria below',
                    value: 'CRITERIA_BELOW',
                    id: `${prefix}.awardCriteriaType.CRITERIA_BELOW`,
                    name: `${prefix}.awardCriteriaType`,
                    checked: getPropObjValue(formFields)?.awardCriteriaType === 'CRITERIA_BELOW',
                    required: true,
                }, {
                    label: 'Price is not the only award criterion and all criteria are stated only in the procurement documents',
                    value: 'PROCUREMENT_DOCS',
                    id: `${prefix}.awardCriteriaType.PROCUREMENT_DOCS`,
                    name: `${prefix}.awardCriteriaType`,
                    checked: getPropObjValue(formFields)?.awardCriteriaType === 'PROCUREMENT_DOCS',
                    required: true,
                }]}
                renderAsRow={false}
                required={true}
            />}
            <div className='formElementsDivider'></div>
            {(getPropObjValue(formFields)?.awardCriteriaType === 'CRITERIA_BELOW' || noticeEvent?.noticeType === 'award') && <>
                <LabelledCheckBox
                    id={`${prefix}.notMapped.awardCriteria.qualityCriteria`}
                    label={'Quality criteria'}
                    breakColumn={true}
                    options={[{
                        id: `${prefix}.notMapped.awardCriteria.qualityCriteria.true`,
                        value: true,
                        label: 'Yes',
                        checked: getPropObjValue(formFields)?.notMapped?.awardCriteria?.qualityCriteria === 'true',
                        name: `${prefix}.notMapped.awardCriteria.qualityCriteria`,
                    }, {
                        id: `${prefix}.notMapped.awardCriteria.qualityCriteria.false`,
                        value: false,
                        label: 'No',
                        checked: getPropObjValue(formFields)?.notMapped?.awardCriteria?.qualityCriteria === 'false',
                        name: `${prefix}.notMapped.awardCriteria.qualityCriteria`,
                    }]}
                    onChange={(e) => handleChange(e)}
                    renderAsRow={true}
                    required={false}
                />
                {getPropObjValue(formFields)?.notMapped?.awardCriteria?.qualityCriteria === 'true'
                    && <>
                        <InputComponent
                            id={`${prefix}.notMapped.awardCriteria.quality.criterionNo`}
                            label='Quality criterion - Number'
                            type='number'
                            value={getPropObjValue(formFields)?.notMapped?.awardCriteria?.quality?.criterionNo}
                            required={true}
                            onChange={handleChange}
                        />
                        {// eslint-disable-next-line no-undef, max-len
                            _.times(getPropObjValue(formFields)?.notMapped?.awardCriteria?.quality?.criterionNo,
                                (index) => (<>
                                    <FTSCriteria
                                        prefix={`${prefix}.awardCriteria.0.quality.${index}`}
                                        labelPrefix={'Quality'}
                                        handleChange={handleChange}
                                        formFields={formFields}
                                    />
                                </>))}
                    </>}
                <LabelledRadioButton
                    id={`${prefix}.notMapped.awardCriteria.costCriteria`}
                    label='Cost criteria/price'
                    breakColumn={false}
                    onChange={handleChange}
                    options={getPropObjValue(formFields)?.notMapped?.awardCriteria?.qualityCriteria === 'true' ? [{
                        label: 'Cost criteria',
                        value: 'COST_CRITERIA',
                        id: `${prefix}.notMapped.awardCriteria.COST_CRITERIA`,
                        name: `${prefix}.notMapped.awardCriteria.costCriteria`,
                        checked: getPropObjValue(formFields)?.notMapped?.awardCriteria?.costCriteria === 'COST_CRITERIA',
                        required: true,
                    }, {
                        label: 'Price',
                        value: 'PRICE',
                        id: `${prefix}.notMapped.awardCriteria.PRICE`,
                        name: `${prefix}.notMapped.awardCriteria.costCriteria`,
                        checked: getPropObjValue(formFields)?.notMapped?.awardCriteria?.costCriteria === 'PRICE',
                        required: true,
                    }] : [{
                        label: 'Cost criteria',
                        value: 'COST_CRITERIA',
                        id: `${prefix}.notMapped.awardCriteria.COST_CRITERIA`,
                        name: `${prefix}.notMapped.awardCriteria.costCriteria`,
                        checked: getPropObjValue(formFields)?.notMapped?.awardCriteria?.costCriteria === 'COST_CRITERIA',
                        required: true,
                    }]}
                    renderAsRow={false}
                    required={true}
                />
                {getPropObjValue(formFields)?.notMapped?.awardCriteria?.costCriteria === 'COST_CRITERIA'
                && <>
                    <InputComponent
                        id={`${prefix}.notMapped.awardCriteria.cost.criterionNo`}
                        label='Cost criterion - Number'
                        type='number'
                        value={getPropObjValue(formFields)?.notMapped?.awardCriteria?.cost?.criterionNo}
                        required={true}
                        onChange={handleChange}
                    />
                    {// eslint-disable-next-line no-undef, max-len
                        _.times(getPropObjValue(formFields)?.notMapped?.awardCriteria?.cost?.criterionNo,
                            (index) => (<>
                                <FTSCriteria
                                    prefix={`${prefix}.awardCriteria.0.cost.${index}`}
                                    labelPrefix={'Cost'}
                                    handleChange={handleChange}
                                    formFields={formFields}
                                />
                            </>))}
                </>}
                {getPropObjValue(formFields)?.notMapped?.awardCriteria?.costCriteria === 'PRICE'
                && <InputComponent
                    id={`${prefix}.awardCriteria.0.price.weighting`}
                    label='Price Weighting'
                    type='number'
                    value={getPropObjValue(formFields)?.awardCriteria ? getPropObjValue(formFields)?.awardCriteria[0]?.price?.weighting : ''}
                    required={true}
                    onChange={handleChange}
                />}
            </>}
            <div className='formElementsDivider'></div>
            {noticeEvent?.noticeType !== 'award' && <><h4 className='title-large subHeading'>II.2.6. Estimated value (excluding VAT)</h4>
                <InputComponent
                    id={`${prefix}.valObject.value`}
                    label='Estimated value (excluding VAT)'
                    type='number'
                    value={getPropObjValue(formFields)?.valObject?.value}
                    required={false}
                    onChange={handleChange}
                />
                <LabelledSelect
                    id={`${prefix}.valObject.currency`}
                    label='Currency'
                    options={[
                        { label: 'Select currency', value: '' },
                        { label: 'GBP', value: 'GBP' },
                    ].map((option, idx) => generateDropdownOptions(idx, option))}
                    value={getPropObjValue(formFields)?.valObject?.currency}
                    onChange={handleChange}
                    required={false}
                />
                <div className='formElementsDivider'></div>
                <h4 className='title-large subHeading'>II.2.7. Duration of the contract, framework agreement or dynamic purchasing system</h4>
                <LabelledRadioButton
                    id={`${prefix}.notMapped.contractDuration`}
                    label='Duration of the contract, framework agreement or dynamic purchasing system'
                    breakColumn={false}
                    onChange={handleChange}
                    options={[{
                        label: 'Duration',
                        value: 'DURATION',
                        id: `${prefix}.notMapped.contractDuration.DURATION`,
                        name: `${prefix}.notMapped.contractDuration`,
                        checked: getPropObjValue(formFields)?.notMapped?.contractDuration === 'DURATION',
                        required: true,
                    }, {
                        label: 'Date range',
                        value: 'DATE_RANGE',
                        id: `${prefix}.notMapped.contractDuration.DATE_RANGE`,
                        name: `${prefix}.notMapped.contractDuration`,
                        checked: getPropObjValue(formFields)?.notMapped?.contractDuration === 'DATE_RANGE',
                        required: true,
                    }]}
                    renderAsRow={false}
                    required={true}
                />
                {getPropObjValue(formFields)?.notMapped?.contractDuration === 'DURATION'
            && <>
                <LabelledRadioButton
                    id={`${prefix}.timeFrame.duration.type`}
                    label='Duration type'
                    breakColumn={false}
                    onChange={handleChange}
                    options={[{
                        label: 'Duration in months',
                        value: 'MONTH',
                        id: `${prefix}.timeFrame.duration.type.MONTH`,
                        name: `${prefix}.timeFrame.duration.type`,
                        checked: getPropObjValue(formFields)?.timeFrame?.duration?.type === 'MONTH',
                        required: true,
                    }, {
                        label: 'Duration in days',
                        value: 'DAY',
                        id: `${prefix}.timeFrame.duration.type.DAY`,
                        name: `${prefix}.timeFrame.duration.type`,
                        checked: getPropObjValue(formFields)?.timeFrame?.duration?.type === 'DAY',
                        required: true,
                    }]}
                    renderAsRow={false}
                    required={true}
                />
                <InputComponent
                    id={`${prefix}.timeFrame.duration.value`}
                    label='Duration'
                    type='number'
                    value={getPropObjValue(formFields)?.timeFrame?.duration?.value}
                    required={true}
                    onChange={handleChange}
                />
            </>}
                { getPropObjValue(formFields)?.notMapped?.contractDuration === 'DATE_RANGE'
            && <>
                <DatePickerComponent
                    id={`${prefix}.timeFrame.dateStart`}
                    label='Start date'
                    value={getPropObjValue(formFields)?.timeFrame?.dateStart}
                    onChange={handleChange}
                    minDateFlag={false}
                    required={false}
                />
                <DatePickerComponent
                    id={`${prefix}.timeFrame.dateEnd`}
                    label='End date'
                    value={getPropObjValue(formFields)?.timeFrame?.dateEnd}
                    onChange={handleChange}
                    minDateFlag={false}
                    required={false}
                />
            </>}
                <LabelledRadioButton
                    id={`${prefix}.renewal`}
                    label='This contract is subject to renewal'
                    breakColumn={false}
                    onChange={handleChange}
                    options={[{
                        label: 'Yes',
                        value: true,
                        id: `${prefix}.renewal.true`,
                        name: `${prefix}.renewal`,
                        checked: getPropObjValue(formFields)?.renewal === 'true',
                        required: true,
                    }, {
                        label: 'No',
                        value: false,
                        id: `${prefix}.renewal.false`,
                        name: `${prefix}.renewal`,
                        checked: getPropObjValue(formFields)?.renewal === 'false',
                        required: true,
                    }]}
                    renderAsRow={false}
                    required={true}
                />
                {getPropObjValue(formFields)?.renewal === 'true'
            && <InputComponent
                id={`${prefix}.renewalDescr`}
                label='Description of renewals'
                type='text'
                value={getPropObjValue(formFields)?.renewalDescr}
                required={true}
                onChange={handleChange}
            />}
                <div className='formElementsDivider'></div>
                {(formFields?.procedure?.pt === 'RESTRICTED' || formFields?.procedure?.pt === 'COMPETITIVE_NEGOTIATION')
            && <>
                <h4 className='title-large subHeading'>II.2.9. Information about the limits on the number of candidates to be invited Envisaged number of candidates</h4>
                <LabelledRadioButton
                    id={`${prefix}.notMapped.infoAboutLimits`}
                    label='This contract is subject to renewal'
                    breakColumn={false}
                    onChange={handleChange}
                    options={[{
                        label: 'Exact',
                        value: 'EXACT',
                        id: `${prefix}.notMapped.awardCriteria.awardCriteria.EXACT`,
                        name: `${prefix}.notMapped.infoAboutLimits`,
                        checked: getPropObjValue(formFields)?.notMapped?.infoAboutLimits === 'EXACT',
                        required: false,
                    }, {
                        label: 'Range',
                        value: 'RANGE',
                        id: `${prefix}.notMapped.awardCriteria.awardCriteria.RANGE`,
                        name: `${prefix}.notMapped.infoAboutLimits`,
                        checked: getPropObjValue(formFields)?.notMapped?.infoAboutLimits === 'RANGE',
                        required: false,
                    }, {
                        label: 'None of the above',
                        value: 'NONE',
                        id: `${prefix}.notMapped.awardCriteria.awardCriteria.NONE`,
                        name: `${prefix}.notMapped.infoAboutLimits`,
                        checked: getPropObjValue(formFields)?.notMapped?.infoAboutLimits === 'NONE',
                        required: false,
                    }]}
                    renderAsRow={false}
                    required={false}
                />
                {getPropObjValue(formFields)?.notMapped?.infoAboutLimits === 'EXACT'
                && <InputComponent
                    id={`${prefix}.limitCandidate.nbEnvisiagedCandidate`}
                    label='Number'
                    type='number'
                    value={getPropObjValue(formFields)?.limitCandidate?.nbEnvisiagedCandidate}
                    required={true}
                    onChange={handleChange}
                />}
                {getPropObjValue(formFields)?.notMapped?.infoAboutLimits === 'RANGE'
                && <>
                    <InputComponent
                        id={`${prefix}.limitCandidate.nbMinLimitCandidate`}
                        label='Minimum number'
                        type='number'
                        value={getPropObjValue(formFields)?.limitCandidate?.minNum}
                        required={false}
                        onChange={handleChange}
                    />
                    <InputComponent
                        id={`${prefix}.limitCandidate.nbMaxLimitCandidate`}
                        label='Maximum number'
                        type='number'
                        value={getPropObjValue(formFields)?.limitCandidate?.maxNum}
                        required={false}
                        onChange={handleChange}
                    />
                </>}
                <InputComponent
                    id={`${prefix}.limitCandidate.criteriaCandidate`}
                    label='Objective criteria for choosing the limited number of candidates'
                    type='text'
                    value={getPropObjValue(formFields)?.limitCandidate?.criteriaCandidate}
                    required={false}
                    onChange={handleChange}
                />
            </>}
                <div className='formElementsDivider'></div>
                <h4 className='title-large subHeading'>II.2.10. Information about variants</h4>
                <LabelledRadioButton
                    id={`${prefix}.acceptedVariants`}
                    label='Variants will be accepted'
                    breakColumn={false}
                    onChange={handleChange}
                    options={[{
                        label: 'Yes',
                        value: true,
                        id: `${prefix}.acceptedVariants.true`,
                        name: `${prefix}.acceptedVariants`,
                        checked: getPropObjValue(formFields)?.acceptedVariants === 'true',
                        required: true,
                    }, {
                        label: 'No',
                        value: false,
                        id: `${prefix}.acceptedVariants.false`,
                        name: `${prefix}.acceptedVariants`,
                        checked: getPropObjValue(formFields)?.acceptedVariants === 'false',
                        required: true,
                    }]}
                    renderAsRow={false}
                    required={true}
                />
                <div className='formElementsDivider'></div></>}
            <h4 className='title-large subHeading'>II.2.11. Information about options</h4>
            <LabelledRadioButton
                id={`${prefix}.options`}
                label='Options will be accepted'
                breakColumn={false}
                onChange={handleChange}
                options={[{
                    label: 'Yes',
                    value: true,
                    id: `${prefix}.options.true`,
                    name: `${prefix}.options`,
                    checked: getPropObjValue(formFields)?.options === 'true',
                    required: true,
                }, {
                    label: 'No',
                    value: false,
                    id: `${prefix}.options.false`,
                    name: `${prefix}.options`,
                    checked: getPropObjValue(formFields)?.options === 'false',
                    required: true,
                }]}
                renderAsRow={false}
                required={true}
            />
            {getPropObjValue(formFields)?.options === 'true'
            && <LabelledTextArea
                id={`${prefix}.optionsDescr`}
                label='Description of options'
                type='text'
                value={getPropObjValue(formFields)?.optionsDescr}
                required={true}
                onChange={handleChange}
                maxCharCount={6000}
                breakColumn={true}
            />}
            <div className='formElementsDivider'></div>
            {noticeEvent?.noticeType !== 'award' && <><h4 className='title-large subHeading'>II.2.12. Information about electronic catalogues</h4>
                <div className='formElementsDivider'></div>
                <LabelledCheckBox
                    id={`${prefix}.ecatalogueRequired`}
                    label={'Tenders must be presented in the form of an electronic catalogues or include an electronic catalogue'}
                    breakColumn={true}
                    options={[{
                        id: `${prefix}.ecatalogueRequired.YES`,
                        value: 'YES',
                        label: 'Yes',
                        checked: getPropObjValue(formFields)?.ecatalogueRequired === 'YES',
                        name: `${prefix}.ecatalogueRequired`,
                    }, {
                        id: `${prefix}.ecatalogueRequired.NO`,
                        value: 'NO',
                        label: 'No',
                        checked: getPropObjValue(formFields)?.ecatalogueRequired === 'NO',
                        name: `${prefix}.ecatalogueRequired`,
                    }]}
                    onChange={(e) => handleChange(e)}
                    renderAsRow={true}
                    required={false}
                />
                <div className='formElementsDivider'></div></>}
        </>}
        <h3 className='title-large subHeading'>II.2.14. Additional information</h3>
        <LabelledTextArea
            id={`${prefix}.infoAdd`}
            breakColumn={true}
            label='Additional information'
            value={getPropObjValue(formFields)?.infoAdd || ''}
            maxCharCount={6000}
            onChange={handleChange}
        />
        {showCodesModal
        && <SelectCPVCodesModal
            closeModal={() => setShowCodesModal(false)}
            onSubmit={handleUpdateCPVCodes}
            selectedCodes={[getPropObjValue(formFields)?.cpvMain?.cpvCode]}
            multiple={false}
        />}
        {showDaModal
        && <SelectDACodesModal
            closeModal={() => setShowDaModal(false)}
            onSubmit={handleUpdateDACodes}
            selectedCodes={getPropObjValue(formFields)?.n2021}
            multiple={false}
        />}
    </>);
};

FTSLots.propTypes = {
    noticeEvent: PropTypes.object.required,
    formFields: PropTypes.object.required,
    handleChange: PropTypes.func.required,
    prefix: PropTypes.string.isRequired,
    lotDetails: PropTypes.bool,
};

export default FTSLots;
