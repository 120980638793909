import React from 'react';
import CreateProcurementForm from '../../features/CreateProcurementForm';

const ApprovePRF = () => (
    <section id='cPPSection'>
        <p className='title-xLarge screenTitleHeading' id='sectionTitle'>Enter Project Details</p>
        <CreateProcurementForm type={'prf'}/>
    </section>
);

export default ApprovePRF;
