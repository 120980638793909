import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import helperFunctions from '../../utils/helperFunctions';
import Constants from './constants';

const VersionSelector = ({
    id,
    label,
    placeholder,
    options = [],
    handleShowDiffs,
    clearDropdown,
    type = Constants.PREVIOUS_VERSIONS_QUESTIONNAIRE_TYPE,
}) => {
    const [dropdownOptions, setDropdownOptions] = useState();
    const [selectedValue, setSelectedValue] = useState();

    const handleSelectChange = (data) => {
        setSelectedValue(data);
        handleShowDiffs(data.value);
    };

    useEffect(() => {
        if (options.length) {
            const optionsByType = options.filter((o) => o.type === type);
            const preparedOptions = optionsByType.reverse()
                .map((option, index) => ({
                    name: `V-${optionsByType.length - index} (${helperFunctions.formatPrettyDateTime(option?.localTimestamp || option?.systemTimestamp)})`,
                    value: option?.versionTemplateId || option?.responseID,
                }));
            setDropdownOptions(preparedOptions);
        }
    }, []);
    useEffect(() => {
        setSelectedValue(null);
    }, [clearDropdown]);

    return <>
        {label
            && <><label>{label}</label></>
        }
        <SelectSearch
            id = {id}
            key = {'diff-viewer-select-mode'}
            options={dropdownOptions}
            value = {selectedValue}
            closeOnSelect={true}
            printOptions='on-focus'
            search
            placeholder= {placeholder}
            onChange={(_event, data) => handleSelectChange(data)}
        />

    </>;
};
VersionSelector.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    handleShowDiffs: PropTypes.func.isRequired,
    clearDropdown: PropTypes.bool,
    type: PropTypes.string,
};

export default VersionSelector;
