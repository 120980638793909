import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import projectManagementAPIs from '../../services/project-management.service';
import SummaryBuyer from '../../features/SummaryBuyer';
import Toast from '../../components/Alerts/Toast/Toast';

const EvaluationSummary = () => {
    const [evaluationDetails, setEvaluationDetails] = useState([]);
    const [projectDetails, setProjectDetails] = useState({});
    const { projectId, eventId } = useParams();
    const getAllEvaluationsRequest = useCallback(async () => {
        try {
            const response = await projectManagementAPIs.getEvaluationSummary(projectId, eventId);
            const projectResponse = await projectManagementAPIs.getProject(projectId);
            setEvaluationDetails(response.data);
            setProjectDetails(projectResponse.data);
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    }, [projectId, eventId]);

    useEffect(() => {
        getAllEvaluationsRequest();
    }, [getAllEvaluationsRequest]);

    return evaluationDetails?.responses?.length > 0
        ? <SummaryBuyer
            projectDetails={projectDetails}
            dataSet={evaluationDetails}
            handleStatusUpdate={() => null}
            handleAwardsUpdate={() => null}
            handleSendAwards={() => null}
            isDPS={(projectDetails?.projectDescribes?.includes('dps') && evaluationDetails.dpsRulesApplicable) || false}
            readOnly={true}
            publishLevel={ evaluationDetails?.publishLevel}
        />
        : <div id={'evaluationContainer'}>
            <p className={'caption'}>No submissions available for evaluation.</p>
        </div>;
};

export default EvaluationSummary;
