import React from 'react';
import propTypes from 'prop-types';

function WorknoteItem({ title, txt }) {
    return <section className='worknote-item'>
        <p className='title-small'>{title}</p>
        <p className='worknote-item-content caption-small'>{txt}</p>
        <div className='worknote-item-divider'></div>
    </section>;
}
WorknoteItem.propTypes = {
    title: propTypes.string,
    txt: propTypes.string,
};
export default WorknoteItem;
