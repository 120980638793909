import React from 'react';
import PropTypes from 'prop-types';

import { noticeTypes } from '../../config/constants';
import LabelledSelect from '../../components/LabelledSelect';

const NoticeTypesDropdown = ({
    type,
    handleOnChange,
    publisher = 'CF',
}) => {
    // TODO: Move this into helpers?
    const generateDropdownOptions = (key, option) => ((key > 0)
        ? <option key={`${option.label}-type-${key}`} value={option.value}>{option.label}</option>
        : <>
            <option key='option-type-default' selected disabled>Please select</option>
            <option key={`${option.label}-type-${key}`} value={option.value}>{option.label}</option>
        </>);

    return (
        <LabelledSelect
            id='type'
            options={Object.keys(noticeTypes[publisher]).map(
                (key, idx) => generateDropdownOptions(idx, {
                    label: noticeTypes[publisher][key],
                    value: key,
                }),
            )}
            label='Select Notice Type'
            breakColumn={false}
            required={true}
            value={type}
            onChange={handleOnChange} />
    );
};

NoticeTypesDropdown.propTypes = {
    type: PropTypes.string,
    handleOnChange: PropTypes.func.isRequired,
    publisher: PropTypes.string.required,
};
export default NoticeTypesDropdown;
