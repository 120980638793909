import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SortDropdown = ({
    id,
    options,
    handleSortCallback,
}) => {
    const [selectedValue, setSelectedValue] = useState('default');

    const generateDropdownOptions = (key, option) => ((key > 0)
        ? <option key={key} value={option.value}>{option.label}</option>
        : <option key={key} value='default' disabled>{'Sort by:'}</option>);

    const handleChange = (event) => {
        const { value } = event.target;
        setSelectedValue(value);
        handleSortCallback({
            sort: value,
        });
    };
    return (<select
        className='sortDropdown body'
        id={id}
        value={selectedValue}
        onChange={(event) => handleChange(event)}>
        {options?.map((option, idx) => generateDropdownOptions(idx, option))}
    </select>);
};

SortDropdown.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    handleSortCallback: PropTypes.func,
};

export default SortDropdown;
