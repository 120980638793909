import React from 'react';
import PropTypes from 'prop-types';
import NotificationIcon from '../../../../styles/images/bellIcon.svg';

const NotificationToggleButton = ({ handleToggleBtnClick, numberOfNotifications }) => (
    <div id='notificationsButtonContainer'>
        <button id={'notificationToggleButton'}
            onClick={handleToggleBtnClick}>
            <img src={NotificationIcon} alt='notification-bell-icon' id='bellIcon' />
            <a className='title' id='notificationToggleBtnLabel'>Notifications</a>
        </button>
        <div id='notificationsCounter'>{numberOfNotifications}</div>
    </div>
);

NotificationToggleButton.propTypes = {
    numberOfNotifications: PropTypes.number.isRequired,
    handleToggleBtnClick: PropTypes.func.isRequired,
};

export default NotificationToggleButton;
