import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../../../components/Button';
import EventSection from '../EventSection';
import { eventTypes } from '../../../../../../config/constants';

const Events = ({
    events,
    projectId,
    contractId,
    handleUpdateEvent,
    handleUploadDocument,
    handleDeleteDocument,
    handleAddWorkNotes,
    handleExtentContract,
    handleCreateVariation,
    handleEscalateEvent,
    updateCompleteEventContract,
    awardedSupplierData = [],
    readOnly,
    userSteeringRoles = [],
}) => {
    const [selectedTab, setSelectedTab] = useState(eventTypes.eventTypeCurrent);
    const [filteredEvents, setFilteredEvents] = useState([]);

    const handleClick = (e) => {
        setSelectedTab(e.target.outerText);
    };

    useEffect(() => {
        const filterEvents = () => events.filter((event) => {
            if (selectedTab === eventTypes.eventTypeUpcoming) {
                return event.eventStatus === 'pending';
            }
            if (selectedTab === eventTypes.eventTypePast) {
                return event.eventStatus === 'completed';
            }
            if (selectedTab === eventTypes.eventTypeCurrent) {
                return (event.eventStatus !== 'completed' && event.eventStatus !== 'pending');
            }
            return true;
        });
        setFilteredEvents(filterEvents());
    }, [events, selectedTab]);

    return <>
        <div className='filter-button-group'>
            <Button
                id='actionBtn1'
                size='small'
                variant={`primary ${selectedTab === eventTypes.eventTypeCurrent ? '' : 'white'}`}
                label='Current'
                handleClick={(e) => handleClick(e)}/>
            <Button
                id='actionBtn2'
                size='small'
                variant={`primary ${selectedTab === eventTypes.eventTypeUpcoming ? '' : 'white'}`}
                label='Upcoming'
                handleClick={(e) => handleClick(e)}/>
            <Button
                id='actionBtn3'
                size='small'
                variant={`primary ${selectedTab === eventTypes.eventTypePast ? '' : 'white'}`}
                label='Past'
                handleClick={(e) => handleClick(e)}/>
        </div>
        {filteredEvents?.length > 0
        && filteredEvents?.map(
            (event) => <EventSection
                key={event.eventID}
                event={event}
                projectId={projectId}
                contractId={contractId}
                handleUpdateEvent={handleUpdateEvent}
                handleEscalateEvent={handleEscalateEvent}
                handleUploadDocument={handleUploadDocument}
                handleDeleteDocument={handleDeleteDocument}
                handleAddWorkNotes={handleAddWorkNotes}
                handleExtentContract={handleExtentContract}
                handleCreateVariation={handleCreateVariation}
                updateCompleteEventContract={updateCompleteEventContract}
                readOnly={readOnly}
                awardedSupplierData={awardedSupplierData}
                userSteeringRoles={userSteeringRoles}
            />,
        )}
        {filteredEvents?.length === 0
        && <p className='title'>No events to show for the current selection.</p>
        }
    </>;
};

Events.propTypes = {
    events: PropTypes.array,
    projectId: PropTypes.string,
    contractId: PropTypes.string,
    handleUpdateEvent: PropTypes.func,
    handleUploadDocument: PropTypes.func,
    handleDeleteDocument: PropTypes.func,
    handleAddWorkNotes: PropTypes.func,
    handleExtentContract: PropTypes.func,
    handleCreateVariation: PropTypes.func,
    handleEscalateEvent: PropTypes.func,
    updateCompleteEventContract: PropTypes.func,
    awardedSupplierData: PropTypes.array,
    readOnly: PropTypes.bool,
    userSteeringRoles: PropTypes.array,
};

export default Events;
