import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DocumentBuilder from '../../features/DocumentBuilder';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';

const CreateEvent = () => {
    const { workflowType } = useParams();
    const history = useHistory();

    const handleCreateEvent = async (eventDetails) => {
        const payLoad = {
            ...eventDetails,
            eventDuration: parseInt(eventDetails.eventDuration, 10),
            startDateReminder: eventDetails.startDateReminderFlag
                ? parseInt(eventDetails.startDateReminder, 10)
                : undefined,
            endDateReminder: eventDetails.endDateReminderFlag
                ? parseInt(eventDetails.endDateReminder, 10)
                : undefined,
        };
        const response = await projectManagementAPIs.createEvent(payLoad);
        if (response.status === 201) {
            Toast.fire({
                icon: 'success',
                titleText: 'Event created successfully.',
            });
            history.push('/events');
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to create event.',
            });
        }
    };

    return <DocumentBuilder
        documentMode={'add'}
        context='Event'
        type={workflowType}
        initialDocumentState={{
            details: {},
            sections: [],
        }}
        sectionLabel='Event'
        sectionItemLabel='Event'
        allowSectionReordering={false}
        allowSectionItemsReordering={true}
        handlePrimaryToolbarSubmitButton={(eventDetailsData) => handleCreateEvent(eventDetailsData)}
    />;
};

export default CreateEvent;
