import React from 'react';
import { parse, isFuture, isPast } from 'date-fns';
import Tag from '../components/Tag';
import Button from '../components/Button';
import MessengerIcon from '../styles/images/messengerIcon.png';
import Accordion from '../features/Accordion';

const generateTagChildren = (context, item) => {
    const TagList = [];
    const { header, content } = item;
    let elementId;
    switch (header) {
        case 'Codes and Categories':
            elementId = `${context}CodesAndCategories`;
            break;
        case 'RegionsOfSupply':
            elementId = `${context}RegionsOfSupply`;
            break;
        case 'Departments':
            elementId = `${context}Departments`;
            break;
        default:
            elementId = `${context}Departments`;
    }
    content[0].value.forEach((tag, index) => {
        const TagElement = <Tag key={`${context}-${tag}-${index}`}
            id={elementId}
            tagTxt={tag.level || tag.description || tag.name || tag}
            isDeletable={false}
            size='large' />;
        TagList.push(TagElement);
    });
    if (TagList.length === 0) {
        return <p className={'caption'}>{`No ${header.toLowerCase()} found.`}</p>;
    }
    return <div className='sectionContentTags'>
        {TagList}
    </div>;
};

const generateDataChildren = (context, item) => {
    const labelValueList = [];
    const { content, header } = item;
    const headerNoSpaces = header.split(' ').join('');
    const elementId = headerNoSpaces.charAt(0).toLowerCase() + headerNoSpaces.slice(1);
    content.forEach((contentData, index) => {
        if (Array.isArray(contentData)) {
            contentData.forEach((cData, idx) => {
                const dataList = <div key={`${context}-data-row-${idx}-${index}`}
                    id={elementId}
                    className='sectionRow'>
                    <p className='title sectionLabel' >{cData.label}</p>
                    <p className='caption sectionValue' >{cData.value}</p>
                </div>;
                labelValueList.push(dataList);
            });
        } else {
            const dataList = (contentData.value !== '')
                ? <><div key={`${context}-data-row-${index}`} id={elementId}
                    className='sectionRow'>
                    <p className='title sectionLabel' >{contentData.label}</p>
                    <p className='caption sectionValue' >{contentData.value}</p>
                </div>
                </>
                : '';
            labelValueList.push(dataList);
        }
    });
    return <div className='sectionContent'>
        {labelValueList}
    </div>;
};

const generateSectionBody = (context, data) => {
    const childrenJsx = [];
    data.forEach((item, index) => {
        let sectionBody;
        if (item.header === 'Regional Offices' || item.header === 'Headquarter') {
            sectionBody = [];
            sectionBody.push(<h4 key={`${index}-sectionHeader-accordion`} className='sectionHeader'>{item.header}</h4>);
            if (item?.content?.length === 0) {
                sectionBody.push(<p key={`${index}-sectionHeader-notfound`} className={'caption'}>{`No ${item.header.toLowerCase()} found.`}</p>);
            }
            item.content.forEach((accordion) => {
                const sectionItem = <Accordion
                    key={`${accordion.accordionHeader}-office-accordion`}
                    id='office-accordion-id'
                    open = {item.header === 'Headquarter'}
                    data={[{
                        sectionId: `${context}${accordion.accordionHeader}`,
                        header: <p className='title'>{accordion.accordionHeader}</p>,
                        content: <>{generateSectionBody(`${context}-${accordion.accordionHeader}`, accordion.accordionData)}</>,
                    }]}
                />;
                sectionBody.push(sectionItem);
            });
        } else {
            sectionBody = <React.Fragment key={`${context}-screen-body-section-${index}`}>
                <p className={`title-large sectionHeader${item.header === '' ? '-default' : ''}`}>{item.header}</p>
                {(['Codes and Categories', 'Delivery Area', 'Departments'].includes(item.header))
                    ? generateTagChildren(context, item)
                    : generateDataChildren(context, item)
                }
            </React.Fragment>;
        }
        childrenJsx.push(sectionBody);
    });
    return childrenJsx;
};

const jsxGenerateData = (
    context,
    sectionData,
    handlerHeaderBtnClick,
    secondHandlerHeaderBtnClick = null,
    edit = false,
    editBtn = null,
    formBody = null,
    customBtn = false,
    workflowBtn = null,
    backBtn = null,
) => {
    const { data } = sectionData;
    const { infoHeader } = sectionData;

    const generateButtons = (intent) => {
        const getExpressionEndDate = () => {
            if (context !== 'supplierProfile') {
                const endDate = data[0].content.find((c) => c.label === 'Expression of interest window ends on:');
                return endDate?.value;
            }
            return null;
        };

        const getExpressionStartDate = () => {
            if (context !== 'supplierProfile') {
                const startDate = data[0].content.find((c) => c.label === 'Expression of interest window start on:');
                return startDate?.value;
            }
            return null;
        };

        const expressionEndDate = parse(getExpressionEndDate(), 'dd-MM-yyyy', new Date());
        const expressionStartDate = parse(getExpressionStartDate(), 'dd-MM-yyyy', new Date());
        const displayButton = isPast(expressionStartDate) && isFuture(expressionEndDate);
        const buttonJsx = [];
        switch (true) {
            case (intent.status === ''):
                buttonJsx.push(displayButton && <div key='actionDefaultKey' id='actionBtnContainer'>
                    <Button id='actionBtn'
                        type='submit'
                        label={'Register Interest'}
                        handleClick={handlerHeaderBtnClick}
                    />
                </div>);
                break;
            case (intent.status === 'Removed'):
                buttonJsx.push(<div key={`status-${intent.status}`} id='actionBtnContainer'>
                    <Button id='actionBtn'
                        type='submit'
                        label={'Register Interest'}
                        handleClick={handlerHeaderBtnClick}
                    />
                </div>);
                break;
            case (intent.status === 'Approved'):
                buttonJsx.push(<div key={`status-${intent.status}`} id='actionBtnContainer'>
                    <Button id='actionBtn'
                        className='cancelRequestButton'
                        variant='secondary'
                        additionalVariant='cancel'
                        label={'Remove Interest'}
                        handleClick={handlerHeaderBtnClick}
                    />
                    <Button id='actionBtn'
                        type='submit'
                        label={'Messenger'}
                        icon={true}
                        iconSrc={MessengerIcon}
                        handleClick={() => { alert('messenger panel'); }}
                    />
                </div>);
                break;
            case (intent.status === 'Rejected'):
                buttonJsx.push(<div key={`status-${intent.status}`} id='actionBtnContainer'>
                    <Button id='opportunityRejectButton'
                        className='secondaryBtn'
                        variant='tertiary'
                        additionalVariant='cancel'
                        label={'Rejected'}
                        handleClick={null}
                    />
                </div>);
                break;
            case (intent.status === 'Pending'):
                buttonJsx.push(<div key={`status-${intent.status}`} id='actionBtnContainer'>
                    <Button id='opportunityRejectButton'
                        className='secondaryBtn'
                        variant='tertiary'
                        additionalVariant='cancel'
                        label={'Approval pending'}
                        handleClick={() => null}
                    />
                    <Button id='actionBtn'
                        className='cancelRequestButton'
                        variant='secondary'
                        additionalVariant='cancel'
                        label={'Remove Interest'}
                        handleClick={handlerHeaderBtnClick}
                    />
                </div>);
                break;
            case (intent === 'MessengerOnly'):
                buttonJsx.push(<div key={`status-${intent.status}`} id='actionBtnContainer'>
                    <Button id='actionBtn'
                        type='submit'
                        label={'Messenger'}
                        icon={true}
                        iconSrc={MessengerIcon}
                        handleClick={() => { alert('messenger panel'); }}
                    />
                </div>);
                break;
            default:
                buttonJsx.push(displayButton && <div key={'status-default'} id='actionBtnContainer'>
                    <Button id='actionBtn'
                        type='submit'
                        label={'Register Interest'}
                        handleClick={handlerHeaderBtnClick}
                    />
                </div>);
                break;
        }
        return buttonJsx;
    };

    const renderSectionBody = generateSectionBody(context, data);
    const renderJsx = <div id={`${context}Container`} className='itemContainer'>
        <section className='itemHeaderContainer'>
            <div className='itemHeader'>
                <p id='headerTitle' className='headerTitle title-xLarge'>
                    {infoHeader.itemInfo}</p>
                {infoHeader.referenceNumber && <p className='referenceNumber caption'>
                    {infoHeader.itemValue}
                    {infoHeader.referenceNumber}</p>}
            </div>
            {customBtn ? generateButtons(infoHeader.projectStatus, handlerHeaderBtnClick)
                : <div id='actionBtnContainer'>
                    {infoHeader.secondaryButtonText && <Button id='actionBtn'
                        className = 'cancelRequestButton'
                        variant='secondary'
                        additionalVariant='cancel'
                        label={infoHeader.secondaryButtonText}
                        handleClick={secondHandlerHeaderBtnClick} />}
                    {infoHeader.buttonText && <Button id='actionBtn'
                        type='submit'
                        label={infoHeader.buttonText}
                        handleClick={handlerHeaderBtnClick} />
                    } </div>
            }
            {backBtn && backBtn()}
            {workflowBtn && workflowBtn()}
            {editBtn && editBtn()}
        </section>
        <section className='itemBody'>
            {edit ? formBody : renderSectionBody}
        </section>
    </div>;

    return renderJsx;
};

export default jsxGenerateData;
