import React from 'react';
import Tag from '../../../components/Tag';
import { routeToMarket } from '../../../config/constants';
import helperFunctions from '../../../utils/helperFunctions';

const generateCodeTags = (item) => {
    const TagList = [];
    item?.forEach((tag, index) => {
        const TagElement = <Tag key={`${tag.classificationID}-${index}`}
            id={`row-${tag.classificationID}-${index}`}
            tagTxt={`${tag.category} - ${tag.level}`}
            isDeletable={false}
            size='large' />;
        TagList.push(TagElement);
    });
    if (TagList.length === 0) {
        return <p className={'caption'}>{'No codes and categories found.'}</p>;
    }
    return <div className='sectionContentTags'>
        {TagList}
    </div>;
};

const generateRegionTags = (item) => {
    const TagList = [];
    item?.forEach((tag, index) => {
        const TagElement = <Tag key={`${tag.code}-${index}`}
            id={`row-${tag.code}-${index}`}
            tagTxt={`${tag.code} - ${tag.description}`}
            isDeletable={false}
            size='large' />;
        TagList.push(TagElement);
    });
    if (TagList.length === 0) {
        return <p className={'caption'}>{'No region(s) of supply found.'}</p>;
    }
    return <div className='sectionContentTags'>
        {TagList}
    </div>;
};

const getPrettyRTM = (rtm) => routeToMarket.filter((route) => route.id === rtm)[0]?.name;

const generateSolutionDataChildren = (details) => <>
    <div className='sectionContent'>
        <div id='description' className='sectionRow'>
            <p className='title sectionLabel'>Description:</p>
            <p className='caption sectionValue'>{details.contractDescription ? details.contractDescription : '-'}</p>
        </div>
        <div id='estimatedValue' className='sectionRow'>
            <p className='title sectionLabel'>Award value:</p>
            <p className='caption sectionValue'>{details.awardValue ? helperFunctions.formatCurrency(details.awardValue) : '-'}</p>
        </div>
        <div id='contractStart' className='sectionRow'>
            <p className='title sectionLabel'>Contract Start Date:</p>
            <p className='caption sectionValue'>{details.contractStartDate ? details.contractStartDate : '-'}</p>
        </div>
        <div id='contractStart' className='sectionRow'>
            <p className='title sectionLabel'>Contract End Date:</p>
            <p className='caption sectionValue'>{details.contractEndDate ? details.contractEndDate : '-'}</p>
        </div>
        <div id='contractStart' className='sectionRow'>
            <p className='title sectionLabel'>Route to Market:</p>
            <p className='caption sectionValue'>{getPrettyRTM(details.route)}</p>
        </div>
        <div id='contractStart' className='sectionRow'>
            <p className='title sectionLabel'>Project Type:</p>
            <p className='caption sectionValue'>{details.projectDescribes?.length > 0
                ? details?.projectDescribes.map((role, i) => {
                    if (i + 1 === details?.projectDescribes.length) {
                        return `${helperFunctions.camel2title(role)}`;
                    }
                    return `${helperFunctions.camel2title(role)}, `;
                })
                : '-'}</p>
        </div>
        <div id='contractDuration' className='sectionRow'>
            <p className='title sectionLabel'>Extension(s) Available:</p>
            <p className='caption sectionValue'>{Array.isArray(details.extensionsAvailable) ? details.extensionsAvailable.length : '-'}</p>
        </div>
        {Array.isArray(details.extensionsAvailable)
            ? details.extensionsAvailable.map((extension, index) => <div id='contractDuration' className='sectionRow' key={`extendion${index}`}>
                <p className='title sectionLabel'>Extension Duration {index + 1}:</p>
                <p className='caption sectionValue'>{extension.monthsAvailable}</p>
            </div>)
            : <></>}
        <div id='contractDuration' className='sectionRow'>
            <p className='title sectionLabel'>Responsible Officer:</p>
            <p className='caption sectionValue'>{details?.responsibleUser?.user?.contactDetails?.username}</p>
        </div>
        <div id='contractDuration' className='sectionRow'>
            <p className='title sectionLabel'>Codes and Categories:</p>
            <div className='caption sectionValue'>{generateCodeTags(details.industryInfo)}</div>
        </div>
        <div id='contractDuration' className='sectionRow'>
            <p className='title sectionLabel'>Delivery Area:</p>
            <div className='caption sectionValue'>{generateRegionTags(details.deliveryAreaInfo)}</div>
        </div>
    </div>
</>;

export default generateSolutionDataChildren;
