const constants = {
    noLotsSelected: 'No Lots selected',
    numberOfLotsSelected: 'Number of Lots selected: ',
    selectedLots: 'Selected Lots :',
    searchForLot: 'Search for a Lot',

    codesAndCategories: 'Codes & Categories :',
    deliveryArea: 'Delivery Area :',
    estimatedValue: 'Estimated value : £',

    unableToRetrieveInfo1: 'Unable to retrieve information.....',
    unableToRetrieveInfo2: 'Unable to retrieve information.',
    errorIcon: 'error',

    lot: 'Lot',

    selectAll: 'Select All',

    allSeen: 'You have seen it all.',

    confirmSelectionButton: 'Confirm Selection',
    cancelButton: 'Cancel',
};

export default constants;
