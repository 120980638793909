export const browseActivitiesId = 'browseActivities';
export const browseDataContext = 'activitylogs';
export const browseDataContextSingular = 'Activity Log';
export const searchPlaceholder = 'Search activities';

export const ToastConstants = {
    icon: 'error',
    titleText: 'Unable to retrieve information.',
};

export const activityLogPostDeadline = {
    ANSWERS_SUBMITTED: new RegExp('^Answers Submitted$'),
};

export const sortOptions = [
    null,
    { label: 'Date (oldest)', value: 'systemTimestamp-ascending-dateTime' },
    { label: 'Date (newest)', value: 'systemTimestamp-descending-dateTime' },
    { label: 'Supplier (ascending)', value: 'accountName-ascending-string' },
    { label: 'Supplier (descending)', value: 'accountName-descending-string' },
];
