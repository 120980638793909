import React from 'react';
import PropTypes from 'prop-types';
import StatusLabel from '../../../components/StatusLabel';
import helperFunctions from '../../../utils/helperFunctions';

const HeaderContent = ({
    supplierName,
    submittedAt,
    evaluationStatus,
    actionLabel,
    showStatusLabel = true,
    showScoreLabel = false,
    showPassFailLabel = false,
    color,
    score,
    passFail,
    weightage,
}) => <>
    <div className='left-align-header'>
        <p className='headerTitle title'>{supplierName} {'\n'} </p>
        <p className='headerTitle'>Submitted At: {helperFunctions.formatPrettyDateTime(submittedAt)}</p>
    </div>
    <div className='sbAccordionSectionHeaderAddon'>
        {actionLabel
        && <div className='statuslabel label'>
            <StatusLabel
                id={'id'}
                color={actionLabel === 'Action Needed' ? 'red' : 'amber'}
                labelTxt={actionLabel}
            />
        </div>}
        {showStatusLabel
        && <div className='statuslabel label'>
            <StatusLabel
                id={evaluationStatus}
                color={color}
                labelTxt={evaluationStatus}
            />
        </div>}
        {showScoreLabel && weightage !== 0 && <p className='label'>Total Score: {`${score}/${weightage}`}</p>}
        {showScoreLabel && showPassFailLabel && <p className='label'>Pass/ Fail Result: {helperFunctions.camel2title(passFail)}</p>}
    </div>
</>;

HeaderContent.propTypes = {
    supplierName: PropTypes.string.isRequired,
    submittedAt: PropTypes.string.isRequired,
    evaluationStatus: PropTypes.string.isRequired,
    actionLabel: PropTypes.string,
    showStatusLabel: PropTypes.bool,
    showScoreLabel: PropTypes.bool,
    showPassFailLabel: PropTypes.bool,
    color: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    passFail: PropTypes.string,
    weightage: PropTypes.number.isRequired,
};

export default HeaderContent;
