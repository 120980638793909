/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// import LabelledInput from '../../components/LabelledInput';
import Button from '../../components/Button';
import Toast from '../../components/Alerts/Toast/Toast';

import { fetchData, publishNotice, layoutData } from './index.helpers';

const ViewNotice = () => {
    const history = useHistory();
    const { projectId, eventId } = useParams();
    const [noticeEvent, setNoticeEvent] = useState();
    const [noticeEventLayout, setNoticeEventLayout] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const handlePublish = async (event) => {
        event.preventDefault();

        try {
            await publishNotice(projectId, eventId);
            history.push(`/projecs/overview/${projectId}`);
        } catch (e) {
            Toast.fire({
                icon: 'error',
                titleText: e.message,
            });
        }
    };

    const handleCancel = (event) => {
        event.preventDefault();
        history.goBack();
    };

    const cancelButton = () => (
        <Button
            id='cancel'
            label={noticeEvent && noticeEvent.isPublished ? 'Back' : 'Cancel'}
            variant='secondary'
            handleClick={handleCancel}
            disabled={false}
        />
    );

    const publishButton = () => (
        <Button
            id='submit'
            label='Publish'
            handleClick={handlePublish}
            breakColumn={false}
        />
    );

    const renderError = (message) => (
        <>
            <h2 className='sectionTitle'>
                There was a problem loading this event{'\''}s notice.
            </h2>
            <h3 className='title'>
                {message}
            </h3>
        </>
    );

    useEffect(() => {
        (async () => {
            try {
                const data = await fetchData(projectId, eventId);
                setNoticeEvent(data);
            } catch (error) {
                setErrorMessage(renderError(error.message));

                Toast.fire({
                    icon: 'error',
                    titleText: `Unable to retrieve information. ${error.messsage}`,
                });
            }
        })();
    }, [projectId, eventId]);

    useEffect(() => {
        setNoticeEventLayout(layoutData(noticeEvent));
    }, [noticeEvent]);

    return (
        <div id='overViewData'>
            <section id='viewPublishNoticesSection'>
                <div className='itemContainer'>
                    { !noticeEvent && errorMessage
                    && <>
                        {errorMessage}
                        <div className='vpnItemButtonGroup'>
                            {cancelButton()}
                        </div>
                    </>
                    }
                    { !noticeEvent && !errorMessage
                    && <h2 className='title'>Loading...</h2>}
                    { noticeEvent && noticeEventLayout
                    && <>
                        {noticeEventLayout}

                        <div className='vpnItemButtonGroup'>
                            {cancelButton()}
                            {!noticeEvent.isPublished && publishButton()}
                        </div>
                    </>
                    }
                </div>
            </section>
        </div>
    );
};

export default ViewNotice;
