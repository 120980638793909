import React from 'react';
import PropTypes from 'prop-types';

import LabelledInput, {
    LabelledInputTypeEmail, LabelledInputTypePassword,
} from '../../../../components/LabelledInput';

const LoginFormBody = ({ formData, handleInput, toggleForgotPasswordModal }) => <>
    <LabelledInput
        id='email'
        type={LabelledInputTypeEmail}
        label='Email'
        placeholder='Enter your email'
        value={formData.email || ''}
        onChange={(event) => handleInput(event)}
        breakColumn={false}
        required={true} />
    <LabelledInput
        id='password'
        type={LabelledInputTypePassword}
        label='Password'
        placeholder='Enter password'
        value={formData.password || ''}
        onChange={(event) => handleInput(event)}
        breakColumn={false}
        required={true} />
    <button type='button' id='forgot-password-btn' className='button links-small'
        onClick={toggleForgotPasswordModal}>Forgot your password?</button>
</>;

LoginFormBody.propTypes = {
    formData: PropTypes.object,
    handleInput: PropTypes.func,
    toggleForgotPasswordModal: PropTypes.func,
};

export default LoginFormBody;
