import constants from '../../../reducers/_constants';

const setRegions = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_REGIONS,
    payload,
});

const setOnlySelectedRegions = (payload) => ({
    type: constants.SupplierRegistrationConstants.SET_ONLY_SELECTED_REGIONS,
    payload,
});

const clearRegions = () => ({
    type: constants.SupplierRegistrationConstants.CLEAR_REGIONS,
});

export default {
    setRegions,
    setOnlySelectedRegions,
    clearRegions,
};
