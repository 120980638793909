import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DocumentBuilder from '../../features/DocumentBuilder';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import userManagementAPIs from '../../services/user-management.service';
import actions from '../EditProjectWorkflow/slice/workflows.actions';

const CreateWorkflow = () => {
    const {
        workflowType, resourceName, projectId, isImport, routeToMarket,
    } = useParams();
    const history = useHistory();
    const [initialState, setInitialState] = useState({
        details: {},
        sections: [],
    });
    const dispatch = useDispatch();

    const [memberAuthorities, setMemberAuthorities] = useState([]);
    const {
        documentBuilderState,
    } = useSelector((state) => ({
        documentBuilderState: state.WorkflowsReducer.documentBuilderState,
    }));

    const constructAndPostWorkflowData = async (documentState) => {
        const stagesInDocument = [];
        documentState.sections.forEach((stage, idx) => {
            const eventsInSection = [];
            stage?.sectionItems?.forEach((event, i) => {
                const eventToAdd = {
                    ...event,
                    orderNo: i,
                    eventDuration: parseInt(event.eventDuration, 10),
                    startDateReminder: event.startDateReminderFlag
                        ? parseInt(event.startDateReminder, 10)
                        : undefined,
                    endDateReminder: event.endDateReminderFlag
                        ? parseInt(event.endDateReminder, 10)
                        : undefined,
                    stageCategory: stage.category,
                };

                eventsInSection.push(eventToAdd);
            });

            const stageDataToAdd = {
                stageName: stage.name,
                category: stage.category,
                orderNo: idx,
                events: eventsInSection,
            };

            stagesInDocument.push(stageDataToAdd);
        });

        const postBody = {
            workflowName: documentState.details.name,
            workflowDescription: documentState.details.description,
            workflowType: 'pre-procurement',
            isPublic: documentState.details.shareWorkflowWithEveryone === 'true',
            sharedWith: documentState.details.shareWorkflowWithEveryone === 'true' ? undefined : documentState.details.sharedWith,
            stages: stagesInDocument,
            route: routeToMarket,
        };

        if (documentState.details.route) postBody.route = documentState.details.route;

        const responseData = await projectManagementAPIs.createWorkflow(postBody);
        if (responseData.status === 201) {
            Toast.fire({
                icon: 'success',
                titleText: 'Workflow created successfully.',
            });
            if (resourceName && resourceName !== '_' && projectId && projectId !== '_') {
                history.push(`/workflows/browse/${resourceName}/${projectId}`);
                return;
            }
            history.push('/workflows/browse');
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to create workflow.',
            });
        }
    };

    const fetchMemberAuthorities = async () => {
        const authorities = await userManagementAPIs.getAllAccountsByType('buyer');
        const auths = authorities.data.map((el) => ({
            value: el.accountID,
            label: el.companyName,
        }));
        setMemberAuthorities(auths);
    };

    useEffect(() => {
        if (documentBuilderState.details && isImport) {
            setInitialState(documentBuilderState);
        } else {
            dispatch(actions.setBuilderState({}));
        }
        fetchMemberAuthorities();
    }, []);

    return <DocumentBuilder
        documentMode={'Add'}
        context='Workflow'
        type={workflowType}
        initialDocumentState={initialState}
        sectionLabel='Stage'
        sectionItemLabel='Event'
        allowSectionReordering={true}
        allowSectionItemsReordering={true}
        handlePrimaryToolbarSubmitButton={constructAndPostWorkflowData}
        memberAuthorities={memberAuthorities}
    />;
};

export default CreateWorkflow;
