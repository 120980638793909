const Constants = {
    noData: ' - ',
    yes: 'Yes',
    no: 'No',
    notFoundDefault: 'No Awarded Suppliers.',
    notFoundLot: 'No active Awarded Suppliers for this lot.',
    notFoundContract: 'No active Awarded Suppliers for this contract.',
    errorUnable: 'Unable to display project information',

    contract: {
        header: 'Key Information',
        reference: 'Reference',
        description: 'Description:',
        startDate: 'Start date:',
        endDate: 'End date:',
        awardDate: 'Award date:',
        annualValue: 'Annual Value (£):',
        reviewDate: 'Review date:',
        awardValue: 'Estimated contract value (£):',
        unrecoverableVAT: 'VAT that cannot be recovered (£):',
        route: 'Process Used:',
        projectDescribes: 'Project Type:',
        industryInfo: 'Codes and Categories:',
        projectTypeDPS: 'dps',
        projectTypeFramework: 'framework',
        projectTypeContract: 'contract',
    },
    buyer: {
        header: 'Buyer Information',
        companyName: 'Name:',
        email: 'Email address:',
        contactUsername: 'Primary contact name:',
        departmentName: 'Department:',
    },
    supplier: {
        header: 'Awarded Supplier(s) Information',
        supplierName: 'Supplier Name:',
        sme: 'SME:',
        vcs: 'VCS:',
        regNumber: 'CH REG:',
        status: {
            active: 'active',
        },
    },
};
export default Constants;
