import React from 'react';
// import PropTypes from 'prop-types';

import Success from '../../../../styles/images/verificationSuccess.png';

const VerificationSuccess = () => <>
    <div className='verification-success-container'>
        <p className='body'>Welcome to Open!</p>
        <img src={Success} alt='Success' width='180' className='verification-success-img'></img>
    </div>
</>;

export default VerificationSuccess;
