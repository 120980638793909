const Constants = {
    pending: 'pending',
    inProgress: 'in-progress',
    stage1approval: 'stage1approval',
    stage2approval: 'stage2approval',
    complete: 'complete',
    allowedEventTypes: [
        'questionnaire',
        'evaluation',
        'issueAwardUnsuccessfulNotices',
        'publishNotices',
        'document',
        'calendar',
    ],
    allowedAsOpportunity: [
        'evaluation',
        'issueAwardUnsuccessfulNotices',
        'publishNotices',
    ],
    isViewInOpenEligible: [
        'questionnaire',
        'evaluation',
        'issueAwardUnsuccessfulNotices',
        'publishNotices',
    ],
    allowedToStartAfterAdvert: [
        'evaluation',
        'issueAwardUnsuccessfulNotices',
        'publishNotices',
        'document',
        'calendar',
    ],
    eventTypeCurrent: 'current',
    eventTypeUpcoming: 'upcomming',
    eventTypePast: 'past',
    allowedRiskColors: [null, 'Low', 'Medium', 'High', 'Critical'],
    cancelled: 'cancelled',
    actionButtonText: {
        view: 'View',
    },
    routes: {
        activityLog: '/project/activity/',
    },
};

export default Constants;
