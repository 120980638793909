import React from 'react';
import PropTypes from 'prop-types';
import { Calendar as ReactBigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enGb from 'date-fns/locale/en-GB';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales: enGb,
});

const formats = {
    weekdayFormat: (date, culture, localizers) => localizers.format(date, 'E', culture),
};

const Calendar = ({ eventsList, onSelectEvent, onSelectSlot }) => <ReactBigCalendar
    selectable
    formats={formats}
    id='calendarContent'
    localizer={localizer}
    events={eventsList}
    startAccessor='start'
    endAccessor='end'
    onSelectEvent={onSelectEvent}
    onSelectSlot={onSelectSlot}
    views={{
        month: true,
        week: true,
        day: true,
    }}
    messages= {{
        previous: '<',
        next: '>',
    }}
/>;

Calendar.propTypes = {
    eventsList: PropTypes.array.isRequired,
    onSelectEvent: PropTypes.func.isRequired,
    onSelectSlot: PropTypes.func.isRequired,
};

export default Calendar;
