const Constants = {
    footerData: [{
        title: 'About Us',
        link: '/about-open',
    }, {
        title: 'Terms & Conditions',
        link: '/terms-and-conditions',
    }, {
        title: 'Privacy Policy',
        link: '/privacy-policy',
    }, {
        title: 'Contact Us',
        link: '/contact',
    }, {
        title: 'Accessibility Statement',
        link: '/accessibility-statement',
    }],
};

export default Constants;
