import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import DashboardCard from '../../../components/DashboardCard';
import Constants from './constants';
import LabelledRadioButton from '../../../components/LabelledRadioButton';
import userManagementAPIs from '../../../services/user-management.service';
import projectManagementAPIs from '../../../services/project-management.service';
import Toast from '../../../components/Alerts/Toast/Toast';
import ShowMoreShowLess from '../../../components/SeeMoreSeeLess';

const ShareSolutionCard = (props) => {
    const allAuthorities = props.solution.isPublic === 'true' && props.solution.sharedWith.length > 0 ? 'false' : 'true';
    const [solutionState, setSolutionState] = useState(props.solution);
    const [sharedWithAllAuthorities, setSharedWithAllAuthorities] = useState(allAuthorities);
    const [memberAuthorities, setMemberAuthorities] = useState([]);
    const [editMode, setEditMode] = useState(false);

    const fetchMemberAuthorities = async () => {
        const authorities = await userManagementAPIs.getAllAccountsByType('buyer');
        setMemberAuthorities(authorities.data.map((el) => ({
            value: el.accountID,
            name: el.companyName,
        })));
    };

    const updateSolution = async () => {
        const solutionRequest = {
            solutionID: solutionState.solutionID,
            isPublic: solutionState.isPublic,
            sharedWith: (sharedWithAllAuthorities === 'true') ? [] : solutionState.sharedWith,
        };

        const response = await projectManagementAPIs.updateShareSolution(solutionRequest);

        if (response.status === 201) {
            setSolutionState({ ...solutionState, ...solutionRequest });
            setEditMode(false);
            Toast.fire({
                icon: 'success',
                titleText: Constants.SHARED_SOLUTION_SUCCESS_MESSAGE,
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: Constants.SHARED_SOLUTION_ERROR_MESSAGE,
            });
        }
        return response;
    };

    const updateSolutionState = (event) => {
        setSolutionState({ ...solutionState, isPublic: event.target.value, sharedWith: [] });
    };

    const ShareWorkflowExternallyRadioButton = () => <LabelledRadioButton
        id='isPublic'
        label='Share this solution externally?'
        breakColumn={false}
        onChange={(event) => updateSolutionState(event)}
        options={[{
            label: 'Yes',
            value: 'true',
            id: 'isPublic',
            name: 'isPublic',
            checked: solutionState.isPublic ? solutionState.isPublic === 'true' : false,
        }, {
            label: 'No',
            value: 'false',
            id: 'isNotPublic',
            name: 'isPublic',
            checked: solutionState.isPublic ? solutionState.isPublic === 'false' : false,
        }]}
        renderAsRow={true} />;

    const ShareWithAllMemberAuthoritiesRadioButton = () => solutionState.isPublic === 'true'
        && <LabelledRadioButton
            id='shareWithAllMemberAuthorities'
            label='Share with'
            breakColumn={false}
            onChange={(event) => setSharedWithAllAuthorities(event.target.value)}
            options={[{
                label: 'All member authorities',
                value: 'true',
                id: 'allMemberAuthoritiesYes',
                name: 'shareWithAllMemberAuthorities',
                checked: sharedWithAllAuthorities ? sharedWithAllAuthorities === 'true' : false,
            }, {
                label: 'Specific member authorities',
                value: 'false',
                id: 'allMemberAuthoritiesNo',
                name: 'shareWithAllMemberAuthorities',
                checked: sharedWithAllAuthorities ? sharedWithAllAuthorities === 'false' : false,
            }]}
            renderAsRow={true} />;

    const SpecificAuthoritiesMultiSelect = () => solutionState.isPublic === 'true'
        && sharedWithAllAuthorities === 'false'
        && memberAuthorities.length > 0
        && <div className='labelled-select'>
            <label className='form-label title selectSearchTitle'>Select the specific member authorities on Open</label>
            <SelectSearch
                id='assigned'
                multiple={true}
                printOptions={'on-focus'}
                closeOnSelect={false}
                options={memberAuthorities}
                value={solutionState.sharedWith || []}
                onChange={(event) => setSolutionState({ ...solutionState, sharedWith: event })}
                placeholder='Select member authorities'
                z-index='4'
                emptyMessage='No member authorities available'
            />
        </div>;

    const EditMode = () => <div id='sharedWith'>
        {ShareWorkflowExternallyRadioButton()}
        <div className='formElementsDivider'></div>
        {ShareWithAllMemberAuthoritiesRadioButton()}
        <div className='formElementsDivider'></div>
        {SpecificAuthoritiesMultiSelect()}
    </div>;

    const ViewModeSharedAll = <div id='sharedAll'>
        <p className='caption' id='sharedAllText'>{Constants.SHARED_ALL_SOLUTION_MESSAGE}</p>
    </div>;

    const ViewModeNotShared = <div id='notShared'>
        <p className='caption' id='notSharedText'>{Constants.NOT_SHARED_SOLUTION_MESSAGE}</p>
    </div>;

    const ViewMode = () => {
        const authorityNames = memberAuthorities
            .filter((ma) => solutionState.sharedWith?.includes(ma.value))
            .map((ma) => ma.name);

        const authNamesJoined = authorityNames.join(', ');
        const authNamesCharacterLimit = 200;
        if (authNamesJoined.length >= authNamesCharacterLimit) {
            return <ShowMoreShowLess
                inputText={authNamesJoined}
                inputTextCharacterLimit={authNamesCharacterLimit}
            />;
        }
        return <div id='sharedWith'>
            <p className='caption sectionValue'>{authNamesJoined}</p>
        </div>;
    };

    useEffect(() => {
        fetchMemberAuthorities();
    }, []);

    const CardContent = () => {
        if (editMode) {
            return EditMode(solutionState);
        }

        if (solutionState.isPublic === 'true' && sharedWithAllAuthorities === 'true') {
            return ViewModeSharedAll;
        }

        if ((!solutionState.isPublic && !solutionState.sharedWith)
            || solutionState.sharedWith?.length === 0) {
            return ViewModeNotShared;
        }

        return ViewMode();
    };

    return <DashboardCard
        id='shareSolution'
        size='large'
        header={true}
        headerTitle={'Shared With'}
        content={CardContent()}
        handlerActionBtn={() => (editMode ? updateSolution() : setEditMode(!editMode))}
        actionBtn={solutionState.canShareSolution}
        actionBtnText={editMode ? 'Confirm' : 'Edit'} />;
};

ShareSolutionCard.propTypes = {
    solution: PropTypes.object,
};

export default ShareSolutionCard;
