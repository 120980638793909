import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';

const SelectionQuestionnairePrompt = ({
    closeModal,
    secondaryBtnAction,
    primaryBtnAction,
}) => {
    const modalBody = <>
        <p className='body'>As you will be bidding for opportunities over £5000, we recommend you to complete
            the Section Questionnaire which we will automatically attach when you
            are bidding for opportunities, saving you time late on!</p>
    </>;

    return <>
        <Modal
            open={true}
            closeModal={closeModal}
            size='small'
            headerTitle='Selection Questionnaire'
            handleMainActionBtnClick={primaryBtnAction}
            mainActionButtonTxt='Complete Now'
            closeButton={false}
            body={modalBody}
            helpOption={false}
            secondActionButton={true}
            secondActionButtonTxt='Complete Later'
            handleSecondaryActionBtnClick={secondaryBtnAction}
        />
    </>;
};

SelectionQuestionnairePrompt.propTypes = {
    closeModal: PropTypes.func.isRequired,
    secondaryBtnAction: PropTypes.func.isRequired,
    primaryBtnAction: PropTypes.func.isRequired,
};

export default SelectionQuestionnairePrompt;
