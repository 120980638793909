import { treeTypes } from '../../components/TreeViewer/index';
import {
    approvalStatuses,
    intentStatuses,
    evaluationStatusesColor,
    view,
    QUESTIONNAIRE_VIEW_EVALUATE_URL,
    HANDLE_UPDATE_INTENT_STATUS,
    ID,
    LOT_ID,
    evaluationStatusesLabels,
    responseStatusColour,
} from '../../config/constants';
import actions from '../../rbac/constants';
import dpsConstants from './constants';
import { getAwardStatusColor, getAwardStatusLabel } from '../../services/award.service';

// LABELS
const labelArrayResponseScore = [{
    id: dpsConstants.labelResponseScoreId,
    type: treeTypes.MENU_OPTIONS.TEXT_LABEL,
    showIfReadOnly: false,
}, {
    id: dpsConstants.labelResponsePassFailResultId,
    type: treeTypes.MENU_OPTIONS.TEXT_LABEL,
    showIfReadOnly: false,
}];
const labelArraySubmissionScore = [
    {
        id: dpsConstants.labelSubmissionScoreId,
        type: treeTypes.MENU_OPTIONS.TEXT_LABEL,
    },
];
const labelObjectAction = (action, userHasPermission) => ({
    id: dpsConstants.labelActionId,
    type: treeTypes.MENU_OPTIONS.STATUS_LABEL,
    color: action.values[userHasPermission].color,
    labelTxt: action.values[userHasPermission].label,
});
const labelActionNeeded = {
    id: dpsConstants.labelActionId,
    type: treeTypes.MENU_OPTIONS.STATUS_LABEL,
    color: evaluationStatusesColor.red,
    labelTxt: dpsConstants.ACTION_NEEDED,
};
// BUTTONS
const buttonObjectViewEvaluateQuestionnaire = {
    id: dpsConstants.buttonViewEvaluateQuestionnaireId,
    label: view,
    additionalVariant: 'small',
    type: treeTypes.MENU_OPTIONS.BUTTON,
    clickType: treeTypes.CLICK_TYPE.URL,
    onClick: QUESTIONNAIRE_VIEW_EVALUATE_URL,
};

const labelObjectIntentStatus = (intentStatus) => ({
    id: dpsConstants.labelIntentStatusId,
    type: treeTypes.MENU_OPTIONS.STATUS_LABEL,
    color: getAwardStatusColor(intentStatus),
    labelTxt: getAwardStatusLabel(intentStatus),
});
const labelObjectActionNeeded = {
    id: dpsConstants.labelLotActionNeededId,
    type: treeTypes.MENU_OPTIONS.STATUS_LABEL,
    color: responseStatusColour.red,
    labelTxt: dpsConstants.ACTION_NEEDED,
};

const buttonsSuccessfulUnSuccesfulLots = [{
    id: dpsConstants.unsuccesfulBtn,
    label: dpsConstants.UNSUCCESSFUL,
    type: treeTypes.MENU_OPTIONS.BUTTON,
    clickType: treeTypes.CLICK_TYPE.EVENT,
    variant: 'secondary',
    additionalVariant: 'small',
    onClickOptions: [HANDLE_UPDATE_INTENT_STATUS,
        intentStatuses.unsuccessful,
        ID,
        LOT_ID],
    showIfReadOnly: false,
}, {
    id: dpsConstants.succesfulBtn,
    label: dpsConstants.SUCCESSFUL,
    type: treeTypes.MENU_OPTIONS.BUTTON,
    clickType: treeTypes.CLICK_TYPE.EVENT,
    additionalVariant: 'small',
    onClickOptions: [HANDLE_UPDATE_INTENT_STATUS,
        intentStatuses.successful,
        ID,
        LOT_ID],
    showIfReadOnly: false,
}];
// ACTIONS
const awardApprovalActions = [{
    value: approvalStatuses.stage1ApprovalPending,
    action: {
        permission: actions.approveStage1,
        values: {
            true: {
                label: dpsConstants.ACTION_NEEDED,
                color: evaluationStatusesColor.red,
            },
            false: {
                label: evaluationStatusesLabels.awaitingApproval,
                color: evaluationStatusesColor.amber,
            },
        },
    },
}, {
    value: approvalStatuses.stage2ApprovalPending,
    action: {
        permission: actions.approveStage2,
        values: {
            true: {
                label: dpsConstants.ACTION_NEEDED,
                color: evaluationStatusesColor.red,
            },
            false: {
                label: evaluationStatusesLabels.stage1Approved,
                color: evaluationStatusesColor.amber,
            },
        },
    },
}];

const awardApprovalActionsValues = {
    actions: awardApprovalActions.map((option) => option.action),
};

const getAwardApprovalActionsValues = (status, values) => ({
    [approvalStatuses.stage1ApprovalPending]: values[0],
    [approvalStatuses.stage2ApprovalPending]: values[1],
}[status || '']);

const getAwardApprovalAction = (status) => getAwardApprovalActionsValues(status,
    awardApprovalActionsValues.actions);

export {
    getAwardApprovalAction,
    buttonObjectViewEvaluateQuestionnaire,
    labelArrayResponseScore,
    labelArraySubmissionScore,
    labelObjectAction,
    labelActionNeeded,
    labelObjectIntentStatus,
    buttonsSuccessfulUnSuccesfulLots,
    labelObjectActionNeeded,
};
