import React from 'react';
import ContractForm from '../../features/ContractForm';

const DraftContract = () => (
    <section id='cPPSection'>
        <h2 className='title-xLarge sectionTitle screenTitleHeading' id='sectionTitle'>Enter Contract Details</h2>
        <ContractForm/>
    </section>
);

export default DraftContract;
