import * as yup from 'yup';

import { set } from 'lodash';

const formatNestedLabel = (params, label, message, customKey = undefined) => {
    const strReg = /\$\{\s*(\w+)\s*\}/g;

    const fieldName = set({}, params.path, { label, message });

    const labelPrefix = Object.entries(fieldName).map(([key, value]) => (
        `${customKey || key} - #${value.length}`
    ));

    const finalMessage = `${labelPrefix} - ${label} ${message}`;
    return finalMessage.replace(strReg, (_, key) => params[key]);
};


const baseSchema = {
    title: yup.string().label('notice title').required(),
    description: yup.string().required(),

    type: yup.string().required(),

    deadlineDate: yup.date().label('closing date').required(),
    valueLow: yup.number().label('lowest/actual value').required().min(1),
    valueHigh: yup.number().label('highest value').required().min(1),

    isSuitableForSme: yup.boolean().label('is suitable for small and medium sized enterprises').required(),
    isSuitableForVco: yup.boolean().label('is suitable for voluntary, community, and social enterprises').required(),

    procedureType: yup.string().label('procedure type').required(),
    ojeuContractType: yup.string().label('contract type').required(),

    contactDetails: yup.object().shape({
        name: yup.string().label('contact details - name').required(),
        email: yup.string().label('contact details - email').email(),
        address1: yup.string().label('contact details - first line of the address').required(),
        address2: yup.string().label('contact details - address line 2'),
        town: yup.string().label('contact details - town/city').required(),
        // eslint-disable-next-line no-template-curly-in-string
        postcode: yup.string().label('contact details - postcode').required().matches(/([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/, '${path} must be a valid UK postcode'),
        country: yup.string().label('contact details - country').required(),
        // eslint-disable-next-line no-template-curly-in-string
        phone: yup.string().label('contact details - telephone').optional().matches(/(^\+?[0-9 -()]{1,20}$)/, '${path} must be a valid UK phone number'),
        webAddress: yup.string().label('contact details - website').optional().url(),
    }),

    // eslint-disable-next-line no-template-curly-in-string
    region: yup.string().label('region').required('Please select one or more ${path}'),
    cpvCodes: yup.array().ensure().label('codes and categories').min(1)
        .of(yup.string()),
};

const awardSchema = {
    awards: yup.array().ensure().label('awarded suppliers')
        .of(yup.object().shape({
            accountId: yup.string().label('account ID').uuid().required(),
            userId: yup.string().label('user ID').uuid(),
            referenceType: yup.string().required((params) => (
                formatNestedLabel(params, 'reference type', 'is a required field', 'awarded supplier')
            )),
            reference: yup.string().when(
                'referenceType', {
                    is: (val) => {
                        const requiredValues = [
                            'COMPANIES_HOUSE',
                            'CHARITY_COMMISSION',
                            'SCOTTISH_CHARITY',
                            'NI_CHARITY_COMMISSION',
                        ];

                        return requiredValues.includes(val);
                    },
                    then: (schema) => schema.required((params) => (
                        formatNestedLabel(params, 'reference number', 'is a required field', 'awarded supplier')
                    )),
                    otherwise: (schema) => schema.max(0, (params) => (
                        formatNestedLabel(params, 'reference number', 'must be blank', 'awarded supplier')
                    )),
                },
            ),
            supplierAwardedValue: yup.number().min(1, (params) => (
                // eslint-disable-next-line no-template-curly-in-string
                formatNestedLabel(params, 'supplier awarded value', 'must be greater than ${min}', 'awarded supplier')
            )),
            awardedDate: yup.date().required((params) => (
                formatNestedLabel(params, 'awarded date', 'is a required field', 'awarded supplier')
            )),
            startDate: yup.date().required((params) => (
                formatNestedLabel(params, 'start date', 'is a required field', 'awarded supplier')
            )),
            endDate: yup.date().required((params) => (
                formatNestedLabel(params, 'end date', 'is a required field', 'awarded supplier')
            )),
            awardedToSME: yup.boolean().required((params) => (
                formatNestedLabel(params, 'awarded to SME', 'is a required field', 'awarded supplier')
            )),
            awardedToVCSO: yup.boolean().required((params) => (
                formatNestedLabel(params, 'awarded to VCSO', 'is a required field', 'awarded supplier')
            )),
        })),
};

const contractSchema = {
    start: yup.date().required(),
    end: yup.date().required(),
};

const futureOpportunitySchema = {
    approachMarketDate: yup.date().label('approach to market date').required(),
    spendProfile: yup.string().label('spend profile').required(),
};

// Basic contract type no awards
const contractNoticeSchema = yup.object().noUnknown().shape({
    ...baseSchema,
    ...contractSchema,
});

// Basic contract with awards
const awardNoticeSchema = yup.object().noUnknown().shape({
    ...baseSchema,
    ...contractSchema,
    ...awardSchema,
});

// Pre-procurement - early engagement
const preprocurementNoticeSchema = yup.object().noUnknown().shape({
    ...baseSchema,
});

// Future opportunity
const futureOpportunityNoticeSchema = yup.object().noUnknown().shape({
    ...baseSchema,
    ...contractSchema,
    ...futureOpportunitySchema,
});

const contractsFinderSchemas = {
    contractNoticeSchema,
    awardNoticeSchema,
    preprocurementNoticeSchema,
    futureOpportunityNoticeSchema,
};

export default contractsFinderSchemas;
