import React from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from '../../../../styles/images/errorIcon.svg';

const VerifyYourAccount = ({ verificationError }) => <>
    {
        verificationError
            ? <div className='success-container'>
                <img src={ErrorIcon} alt='Error' className='success-img'></img>
                <p className='body error'>Invalid password. Please try again</p>
            </div>
            : <></>
    }
    <p className='body py-10'>{'Thank you for registering with us! You’ll receive an email with a temporary password to the address you have provided. Please follow the instructions on the email to complete your registration and be notified of relevant opportunities.'}</p>
    <p className='body py-10'>{'Don’t see an email coming through? Check your spam folder, it may be waiting there!'}</p>
</>;

VerifyYourAccount.propTypes = {
    formData: PropTypes.object,
    handleInput: PropTypes.func,
    verificationError: PropTypes.bool,
};

export default VerifyYourAccount;
