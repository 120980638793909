import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AuthContext from '../../context/AuthContext';
import DocumentBuilder from '../../features/DocumentBuilder';
import documentManagementAPIs from '../../services/document-management.service';
import projectManagementAPIs from '../../services/project-management.service';
import userManagementAPIs from '../../services/user-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import actions from '../EditProjectWorkflow/slice/workflows.actions';

const DraftQuestionnaire = () => {
    const {
        templateType, projectId, eventId, isImport,
    } = useParams();
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [evaluators, setEvaluators] = useState([]);
    const [memberAuthorities, setMemberAuthorities] = useState([]);
    const dispatch = useDispatch();

    const fetchMemberAuthorities = async () => {
        const authorities = await userManagementAPIs.getAllAccountsByType('buyer');
        const auths = authorities.data.map((el) => ({
            value: el.accountID,
            label: el.companyName,
        }));
        setMemberAuthorities(auths);
    };
    const [initialState, setInitialState] = useState({
        details: {
            templateType,
        },
        sections: [],
    });

    const {
        documentBuilderState,
    } = useSelector((state) => ({
        documentBuilderState: state.WorkflowsReducer.documentBuilderState,
    }));

    useEffect(() => {
        const fetchData = async () => {
            const responseData = await projectManagementAPIs.getProject(projectId);
            if (responseData.status === 200) {
                const { data } = responseData;
                const { steeringInfo } = data;
                const evaluatorsData = steeringInfo.filter((info) => info.role === 'evaluator').map((info) => info.user);
                setEvaluators(evaluatorsData);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        };
        fetchMemberAuthorities();
        if (projectId && authContext.user.isAuthenticated) {
            fetchData();
        }
    }, [authContext.user.isAuthenticated, projectId]);

    useEffect(() => {
        if (documentBuilderState.details && isImport) {
            setInitialState(documentBuilderState);
        } else {
            dispatch(actions.setBuilderState({}));
        }
    }, []);

    const constructAndPostQuestionnaireData = async (documentState) => {
        const sectionsInDocument = [];
        documentState.sections?.forEach((section, idx) => {
            const questionsInSection = [];
            section.sectionItems?.forEach((item, i) => {
                const questionToAdd = {
                    orderNo: i,
                    responseOptional: item.responseOptional || false,
                    ...item,
                };
                if (item.attachments) {
                    questionToAdd.attachments = item.attachments;
                }
                if (item.freeTextLength) {
                    questionToAdd.freeTextLength = parseInt(item.freeTextLength, 10);
                }

                questionsInSection.push(questionToAdd);
            });
            const sectionDataToAdd = {
                orderNo: idx,
                title: section.title,
                description: section.description,
                questions: questionsInSection,
            };

            sectionsInDocument.push(sectionDataToAdd);
        });

        const postBody = {
            ...documentState.details,
            showWeightings: !!documentState.details.showWeightings,
            sectionsWeighted: !!documentState.details.sectionsWeighted,
            maxScore: documentState.details.maxScore
                ? parseInt(documentState.details.maxScore, 10) : 0,
            minScore: documentState.details.minScore
                ? parseInt(documentState.details.minScore, 10) : 0,
            sections: sectionsInDocument,
            isPublic: documentState.details.shareQuestionnaireWithEveryone === 'true',
            sharedWith: (documentState.details.shareQuestionnaireWithEveryone === 'true' || documentState.details.shareQuestionnaire === 'false' || !documentState.details.shareQuestionnaire) ? undefined : documentState.details.sharedWith,
        };

        delete postBody.shareQuestionnaireWithEveryone;
        delete postBody.sharedWithName;
        delete postBody.shareQuestionnaire;

        if (documentState.templateId) {
            postBody.previousTemplateID = documentState.templateId;
        }

        const responseData = await documentManagementAPIs.createTemplate(postBody);
        if (responseData.status === 201) {
            if (projectId && projectId !== '_' && eventId && eventId !== '_') {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Questionnaire created successfully.',
                });
                history.push(`/questionnaires/select/${templateType}/${projectId}/${eventId}`);
            } else {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Questionnaire created successfully.',
                });
                history.push('/questionnaires');
            }
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to create questionnaire.',
            });
        }
    };

    return <DocumentBuilder
        documentMode={'Add'}
        context='Questionnaire'
        type={templateType}
        initialDocumentState={initialState}
        sectionLabel='Section'
        sectionItemLabel='Question'
        allowSectionReordering={true}
        allowSectionItemsReordering={true}
        sectionDetailsUserList={evaluators}
        handlePrimaryToolbarSubmitButton={constructAndPostQuestionnaireData}
        memberAuthorities={memberAuthorities}
    />;
};

export default DraftQuestionnaire;
