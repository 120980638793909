const userManagement = {
    login: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/login`,
    logout: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/logout`,
    createAccount: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/create`,
    createAccountByAdmin: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/admin-create-user`,
    getUserDetails: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/profile`,
    updateUser: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/update`,
    updateProfile: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/profile`,
    deleteUser: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/delete`,
    checkCompanyExists: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/companyExists`,
    enableDisableUser: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/enabled`,
    updateAccountRequests: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/verified`,
    resendTempPassword: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/resendTempPassword`,
    resetPassword: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/resetPassword`,
    createPassword: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/challenge/password/verify`,
    changePassword: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/change/forgotten/password`,
    getAllAccountUsers: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/all/account`,
    getAllUsersByAccount: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/list/by/account`,
    getAllUsersByAccountForAdmin: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/admin-list/by/account`,
    getAccountRequests: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/all/unverified`,
    getAllSuppliers: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/account/browse/all`,
    joinAccount: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/join/account`,
    updateAccountWorknotes: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/account/worknotes`,
    updateAccountDocuments: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/account/documents`,
    getAllAccountByType: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/list/la`,
    getSingleCalendar: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/calendar/get`,
    getUserProjectCalendar: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/project/calendar`,
    createMeeting: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/calendar/create`,
    updateEvent: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/calendar/update`,
    getCalendarEventsForUser: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/calendar/get/all`,
    getCalendarEvents: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/calendar/all/user`,
    checkHQAndListRO: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/account/list/regionalOffices`,
    approveUser: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/approve`,
    approveAccount: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/account/approve`,
    createDepartment: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/account/department/create`,
    deleteDepartment: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/account/department`,
    listAllDepartments: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/account/list/departments/`,
    getAllAccountsByType: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/account/list`,
    listUsers: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/user/browse/all`,
    getSupplierDetails: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/account/supplier/overview`,
    getCleverQuotes: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/account/browse/supplier`,
    findAccounts: `${process.env.MS_USER_HOST || process.env.MS_GATEWAY_HOST}/account/find`,
};

export default userManagement;
