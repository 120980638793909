import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LabelledInput from '../../../components/LabelledInput';

import LabelledCheckBox from '../../../components/LabelledCheckBox';
import Button from '../../../components/Button';
import constants from '../../../utils/generalConstants';
import LabelledSelect from '../../../components/LabelledSelect';
import actions from '../slice/supplier-reg.action';
import supplierConstants from '../constants';

const AccountDetails = ({
    formValues, prev, consentStatus, hiddenDetails,
}) => {
    const [formData, setFormData] = useState(formValues);
    const [submitButtonStatus, setsubmitButtonStatus] = useState(false);
    const dispatch = useDispatch();

    const persistFormOnChange = (data) => {
        const preparedFormState = {};
        data.map((el) => {
            preparedFormState[el.id] = el.value;
            return el;
        });
        dispatch(actions.setAccountDetails(preparedFormState));
    };

    useEffect(() => {
        if (consentStatus.tcpp && consentStatus.consentEmail) {
            setsubmitButtonStatus(true);
        }
    }, []);

    useEffect(() => {
        if (hiddenDetails?.departments?.length > 0) {
            const updatedFormData = formData.map((el) => {
                const preparedEl = {};
                if (el.id === 'department') {
                    preparedEl.value = hiddenDetails.departments[0].departmentName;
                    preparedEl.readonly = !(hiddenDetails.departments.length > 1);
                    preparedEl.type = hiddenDetails.departments.length > 1 ? 'select' : 'text';
                }
                return { ...el, ...preparedEl };
            });
            setFormData(updatedFormData);
        }
    }, []);

    const persistCBOnChange = (data) => {
        const consent = {};
        data.forEach((el) => {
            consent[el.id] = el.checked;
            return el;
        });
        dispatch(actions.setConsentStatus(consent));
    };


    const oncheckBoxChange = (e) => {
        const checkItems = [...supplierConstants.consentCheckboxOptions];
        const index = checkItems.findIndex((m) => m.value === e.target.value);

        checkItems.forEach((el, idx) => {
            if (index === idx) {
                // eslint-disable-next-line no-param-reassign
                el.checked = e.currentTarget.checked;
            }
        });
        let isAllChecked = false;
        for (let i = 0; i < checkItems.length; i++) {
            if (checkItems[i].checked) {
                isAllChecked = true;
            } else {
                isAllChecked = false;
                break;
            }
        }

        setsubmitButtonStatus(isAllChecked);

        persistCBOnChange(checkItems);
    };
    const handleInput = (event) => {
        const attribute = event.target.name;
        const value = event.target.value || event.currentTarget.value;
        const updatedFormData = formData.map((el) => {
            const preparedEl = {};
            if (el.id === attribute) {
                preparedEl.value = value;
            }
            return { ...el, ...preparedEl };
        });
        setFormData(updatedFormData);
        persistFormOnChange(updatedFormData);
    };

    const countryCodes = constants.strings.callingCodes;

    const getDepartmentList = () => {
        const departmentList = [];
        if (hiddenDetails.departments && hiddenDetails.departments.length > 0) {
            hiddenDetails?.departments.forEach((el) => {
                departmentList.push(el.departmentName);
            });
        }

        return departmentList;
    };
    const departmentList = getDepartmentList();

    const termsAndConditionJSX = <>I agree to Open <a target='_blank' href='/terms-and-conditions'>Terms & Conditions</a> and <a target='_blank' href='/privacy-policy'>Privacy Policy</a>.</>;

    const generateDropdownOptions = (key, option) => ((key > 0)
        ? <option key={`reg-country-${key}`} value={option.value}>{option.label}</option>
        : <><option key='reg-country-default' value='default' disabled>{'Please select'}</option>
            <option key={`reg-country-${key}`} value={option.value}>{option.label}</option>
        </>);

    const renderInput = (el, idx) => (el.type !== 'select'
        ? <LabelledInput
            key={`account-details-inp-${idx}`}
            id={el.id}
            type={el.type}
            label={el.label}
            placeholder={el.placeholder}
            value={el.value}
            onChange={handleInput}
            breakColumn={false}
            required={el.required}
            readonly={el.readonly} />
        : <LabelledSelect id={el.id}
            key={`account-details-inp-${idx}`}
            options={
                el.id === 'department'
                    ? departmentList.map((elem, index) => generateDropdownOptions(index,
                        { label: elem, value: elem }))
                    : countryCodes.map((elem, index) => generateDropdownOptions(index,
                        { label: elem, value: elem }))}
            label={el.label}
            breakColumn={false}
            required={el.required}
            value={el.value}
            onChange={(e) => handleInput(e)} />);


    return <>
        <div id='account-details-form-div' >
            {
                formData.filter((elem) => !elem.formGroup).map((el, idx) => <div key={`company-details-${idx}`} className='reg-input-wrapper'>
                    { renderInput(el, idx) }
                </div>)
            }
            {
                <div className='reg-input-wrapper reg-input-grp'>
                    {formData.filter((elem) => elem.formGroup).map((el, idx) => <div key={`company-details-${idx}`} className='reg-input-wrapper'>
                        { renderInput(el, idx) }
                    </div>)
                    }
                </div>
            }
        </div>
        <div className='term-consent-wrapper'>
            <LabelledCheckBox id='terms'
                label=''
                renderAsRow={false}
                breakColumn={true}
                onChange={(e) => oncheckBoxChange(e)}
                options={[{
                    id: 'tcpp',
                    name: 'tcpp',
                    value: 'I agree to Open Terms & Conditions and Privacy Policy.',
                    label: termsAndConditionJSX,
                    checked: consentStatus.tcpp || false,
                },
                {
                    id: 'consentEmail',
                    name: 'consentEmail',
                    value: 'I consent to receive communications about Open account by email.',
                    label: 'I consent to receive communications about Open account by email.',
                    checked: consentStatus.consentEmail || false,
                }]}
                required={true} />
        </div>
        <div className='form-btn-wrapper'>
            <Button id='second-action-btn'
                additionalVariant='sup-reg-back'
                variant='secondary'
                label='Back'
                handleClick={(event) => prev(event)} />
            <Button id='main-action-btn'
                label='Submit'
                disabled= {!submitButtonStatus}
                handleClick={() => null} />
        </div>
    </>;
};

AccountDetails.propTypes = {
    formValues: PropTypes.array.isRequired,
    prev: PropTypes.func.isRequired,
    consentStatus: PropTypes.object.isRequired,
    hiddenDetails: PropTypes.object.isRequired,
};

export default AccountDetails;
