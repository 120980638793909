import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DocumentBuilder from '../../features/DocumentBuilder';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';

const CreateStage = () => {
    const { routeToMarket } = useParams();
    const history = useHistory();

    const constructAndPostWorkflowData = async (documentState) => {
        const stagesInDocument = {};
        documentState.sections.forEach((stage, idx) => {
            const eventsInSection = [];
            stage?.sectionItems?.forEach((event, i) => {
                const eventToAdd = {
                    ...event,
                    orderNo: i,
                    eventDuration: parseInt(event.eventDuration, 10),
                    startDateReminder: event.startDateReminderFlag
                        ? parseInt(event.startDateReminder, 10)
                        : undefined,
                    endDateReminder: event.endDateReminderFlag
                        ? parseInt(event.endDateReminder, 10)
                        : undefined,
                    stageCategory: stage.category,
                };
                eventsInSection.push(eventToAdd);
            });

            const stageDataToAdd = {
                events: eventsInSection,
            };

            stagesInDocument[idx] = stageDataToAdd;
        });

        const postBody = {
            stageName: documentState.sections[0].name,
            category: documentState.sections[0].category,
            routeToMarket,
            events: stagesInDocument[0].events,
        };

        const responseData = await projectManagementAPIs.createStage(postBody);
        if (responseData.status === 201) {
            Toast.fire({
                icon: 'success',
                titleText: 'Stage created successfully.',
            });
            /** TODO: Update route as per journey */
            // if (resourceName && id) {
            //     history.push(`/workflows/browse/${resourceName}/${id}`);
            //     return;
            // }
            history.push('/stages');
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to create stage.',
            });
        }
    };

    return <DocumentBuilder
        documentMode={'add'}
        context='Stage'
        initialDocumentState={{
            details: {},
            sections: [],
        }}
        sectionLabel='Stage'
        sectionItemLabel='Event'
        allowSectionReordering={false}
        allowSectionItemsReordering={true}
        handlePrimaryToolbarSubmitButton={constructAndPostWorkflowData}
    />;
};

export default CreateStage;
