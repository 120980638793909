/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import {
    InputComponent,
    SelectComponent,
    RadioButtonComponent,
    DatePickerComponent,
} from './components';
import LabelledTextArea from '../../components/LabelledTextArea';
import FTSNameAndAddresses from './FTSNameAndAddresses';


const FTSAwardContracts = ({
    noticeEvent,
    prefix,
    formFields,
    handleChange,
    isAwardedFlag = true,
}) => {
    const getPropObjValue = (object) => {
        const parts = prefix?.split('.');
        let returnProp = null;
        if (parts !== null && parts !== undefined) {
            for (let i = 0; i < parts.length; i++) {
                if (object[parts[i]]) {
                    returnProp = object[parts[i]];
                    // eslint-disable-next-line no-param-reassign
                    object = returnProp;
                } else { return null; }
            }
        }
        return returnProp;
    };

    return (<>
        <InputComponent
            id={`${prefix}.contractNo`}
            label='Contract Number'
            type='text'
            value={getPropObjValue(formFields)?.contractNo}
            required={true}
            onChange={handleChange}
        />

        {formFields?.notMapped?.lots === 'true' && <InputComponent
            id={`${prefix}.notMapped.lotNumber`}
            label='Lot number'
            type='text'
            value={getPropObjValue(formFields)?.notMapped?.lotNumber}
            required={true}
            onChange={handleChange}
        />}

        <InputComponent
            id={`${prefix}.title`}
            label='Title'
            type='text'
            value={getPropObjValue(formFields)?.title}
            required={true}
            onChange={handleChange}
        />

        {formFields?.notMapped?.isAwarded === 'false'
        && <>
            <RadioButtonComponent
                id={`${prefix}.notMapped.noAwardedContract.unsuccessful`}
                label='Has the contract / lot been awarded?'
                value={formFields?.notMapped?.isAwarded === 'false' ? 'true' : 'false'}
                required={true}
                onChange={handleChange}
                options={{
                    false: 'Yes',
                    true: 'No',
                }}
                disabled={true}
            />
            <input
                type='hidden'
                name={`${prefix}.noAwardedContract.unsuccessful`}
                id={`${prefix}.noAwardedContract.unsuccessful`}
                value={formFields?.notMapped?.isAwarded === 'false' ? 'true' : 'false'}
            />
        </>}

        {getPropObjValue(formFields)?.noAwardedContract?.unsuccessful === 'true'
                && <>
                    <h4 className='title-large subHeading'>
                V.1. Information on non-award
                    </h4>
                    <RadioButtonComponent
                        id={`${prefix}.noAwardedContract`}
                        label='The contract/lot is not awarded'
                        value={getPropObjValue(formFields)?.noAwardedContract}
                        required={true}
                        onChange={handleChange}
                        renderAsRow={false}
                        options={{
                            NOTENDER: 'No tenders',
                            REQTOPARTICIPATE: 'Requests to participate were received',
                            ALLREJECTED: 'All were rejected',
                            OTHER: 'Other reasons (discontinuation of procedure)',
                        }}
                    />
                </>}

        {isAwardedFlag && <>
            <h4 className='title-large subHeading'>
                    V.2. Award of contract
            </h4>

            <DatePickerComponent
                id={`${prefix}.dateContractConclusion`}
                label='V.2.1. Date of conclusion of the contract'
                value={getPropObjValue(formFields)?.dateContractConclusion}
                onChange={handleChange}
                minDateFlag={false}
                required={true}
            />

            <h4 className='title-large subHeading'>
                    V.2.2 Information about tenders
            </h4>

            <InputComponent
                id={`${prefix}.tenders.nbTendersReceived`}
                label='Number of tenders received'
                type='number'
                value={getPropObjValue(formFields)?.tenders?.nbTendersReceived}
                required={true}
                onChange={handleChange}
            />

            <InputComponent
                id={`${prefix}.tenders.nbTendersReceivedSME`}
                label='Number of tenders received from SMEs'
                type='number'
                value={getPropObjValue(formFields)?.tenders?.nbTendersReceivedSME}
                required={false}
                onChange={handleChange}
            />

            <InputComponent
                id={`${prefix}.tenders.nbTendersReceivedEmeans`}
                label='Number of tenders received by electronic means'
                type='number'
                value={getPropObjValue(formFields)?.tenders?.nbTendersReceivedEmeans}
                required={false}
                onChange={handleChange}
            />

            <RadioButtonComponent
                id={`${prefix}.awardedToGroup`}
                label='Has the contract been awarded to a group of economic operators?'
                value={getPropObjValue(formFields)?.awardedToGroup}
                required={true}
                onChange={handleChange}
                options={{
                    true: 'Yes',
                    false: 'No',
                }}
            />

            {getPropObjValue(formFields)?.awardedToGroup === 'true' && <InputComponent
                id={`${prefix}.notMapped.numberOfExonmicOperators`}
                label='Total number of economic operators'
                type='number'
                value={getPropObjValue(formFields)?.notMapped?.numberOfExonmicOperators}
                required={true}
                onChange={handleChange}
            />}

            <h4 className='title-large subHeading'>
                        V.2.3 Name and address of the contractor
                        Provide the name and address of each contractor
                        below and in the additional fields
            </h4>

            <FTSNameAndAddresses
                prefix={`${prefix}.contractors.0`}
                noticeEvent={noticeEvent}
                formFields={formFields}
                handleChange={handleChange}
                registerFlag={true}
                internetAddressNameUpdateFlag={true}
                smeFlag={true}
                nutsCodeFlag={true}
            />

            <h4 className='title-large subHeading'>
                        V.2.4 Information on value of the contract/lot (excluding VAT)
            </h4>

            <InputComponent
                id={`${prefix}.valEstimatedTotal.value`}
                label='Initial estimated total value of the contract/lot'
                type='number'
                value={getPropObjValue(formFields)?.valEstimatedTotal?.value}
                required={false}
                onChange={handleChange}
            />

            <SelectComponent
                id={`${prefix}.valEstimatedTotal.currency`}
                label='Currency'
                value={getPropObjValue(formFields)?.valEstimatedTotal?.currency}
                required={false}
                onChange={handleChange}
                options={{
                    GBP: 'GBP (£)',
                }}
            />

            <RadioButtonComponent
                id={`${prefix}.notMapped.totalValueOfContractLot`}
                label='Total value of the contract/lot For framework agreements - total maximum
                    value for this lot. For dynamic purchasing systems - value of contract(s) for
                    this lot not included in previous contract award notices. For contracts based
                    on framework agreements, if required - value of contract(s) for this lot not
                    included in previous contract award notices'
                value={getPropObjValue(formFields)?.notMapped?.totalValueOfContractLot}
                required={true}
                onChange={handleChange}
                options={{
                    EXACT: 'Exact',
                    LOWHIGHOFFERTAKEN: 'Lowest offer taken into consideration',
                }}
            />

            {getPropObjValue(formFields)?.notMapped?.totalValueOfContractLot === 'EXACT'
                    && <InputComponent
                        id={`${prefix}.valTotal.value`}
                        label='Value'
                        type='number'
                        value={getPropObjValue(formFields)?.valTotal?.value}
                        required={true}
                        onChange={handleChange}
                    />}

            {getPropObjValue(formFields)?.notMapped?.totalValueOfContractLot === 'LOWHIGHOFFERTAKEN' && <>
                <InputComponent
                    id={`${prefix}.valRangeTotal.low`}
                    label='Lowest offer'
                    type='number'
                    value={getPropObjValue(formFields)?.valRangeTotal?.low}
                    required={true}
                    onChange={handleChange}
                />

                <InputComponent
                    id={`${prefix}.valRangeTotal.high`}
                    label='Highest offer'
                    type='number'
                    value={getPropObjValue(formFields)?.valRangeTotal?.high}
                    required={true}
                    onChange={handleChange}
                />
            </>}

            <SelectComponent
                id={getPropObjValue(formFields)?.notMapped?.totalValueOfContractLot === 'EXACT'
                    ? `${prefix}.valTotal.currency` : `${prefix}.valRangeTotal.currency`}
                label='Currency'
                value={getPropObjValue(formFields)?.notMapped?.totalValueOfContractLot === 'EXACT'
                    ? getPropObjValue(formFields)?.valTotal?.currency : getPropObjValue(formFields)?.valRangeTotal?.currency}
                required={true}
                onChange={handleChange}
                options={{
                    GBP: 'Pound (£)',
                    EUR: 'Euro (€)',
                    USD: 'Dollars ($)',
                }}
            />

            <h4 className='title-large subHeading'>
                        V.2.5 Information about subcontracting
            </h4>

            <RadioButtonComponent
                id={`${prefix}.likelySubcontracted`}
                label='Is the contract likely to be subcontracted?'
                value={getPropObjValue(formFields)?.likelySubcontracted}
                required={false}
                onChange={handleChange}
                options={{
                    true: 'Yes',
                    false: 'No',
                }}
            />

            {getPropObjValue(formFields)?.likelySubcontracted === 'true'
                    && <>
                        <h4 className='title-large subHeading'>
                    Value or proportion likely to be subcontracted to third parties
                        </h4>

                        <InputComponent
                            id={`${prefix}.valSubContracting.value`}
                            label='Value excluding VAT number'
                            type='number'
                            value={getPropObjValue(formFields)?.valSubContracting?.value}
                            required={false}
                            onChange={handleChange}
                        />
                        <SelectComponent
                            id={`${prefix}.valSubContracting.currency`}
                            label='Currency'
                            value={getPropObjValue(formFields)?.valSubContracting?.currency}
                            required={false}
                            onChange={handleChange}
                            options={{
                                GBP: 'Pound (£)',
                                EUR: 'Euro (€)',
                                USD: 'Dollars ($)',
                            }}
                        />
                        <InputComponent
                            id={`${prefix}.pctSubcontracting`}
                            label='Proportion (%)'
                            type='number'
                            value={getPropObjValue(formFields)?.notMapped?.pctSubcontracting}
                            required={false}
                            maxNumber={100}
                            onChange={handleChange}
                        />
                        <LabelledTextArea
                            id={`${prefix}.infoAddSubcontracting`}
                            label='Short description of the part of the contract to be subcontracted'
                            type='textarea'
                            value={getPropObjValue(formFields)?.shortDescriptionOfSubContract}
                            placeholder={'Enter a short description of the part of the contract to be subcontracted'}
                            required={false}
                            onChange={handleChange}
                        />
                    </>}
            {// eslint-disable-next-line no-undef
                _.times(getPropObjValue(formFields)?.notMapped?.numberOfExonmicOperators, (index) => (
                    <> <h4 className='title-large subHeading'>
                            Section V.2.3. Additional contractor ({index + 1})
                    </h4>

                    <FTSNameAndAddresses
                        prefix={`${prefix}.contractors.${index + 1}`}
                        registerFlag={false}
                        noticeEvent={noticeEvent}
                        formFields={formFields}
                        handleChange={handleChange}
                        internetAddressNameUpdateFlag={true}
                        smeFlag={true}
                    />
                    </>
                ))}
        </>}
    </>
    );
};

FTSAwardContracts.propTypes = {
    project: PropTypes.object.required,
    contract: PropTypes.object.required,
    suppliers: PropTypes.array.required,
    noticeEvent: PropTypes.object.required,
    formFields: PropTypes.object.required,
    handleChange: PropTypes.func.required,
    setShowCodesModal: PropTypes.func.required,
    generateCodeTag: PropTypes.func.required,
    prefix: PropTypes.string.isRequired,
    isAwardedFlag: PropTypes.bool,
};

export default FTSAwardContracts;
