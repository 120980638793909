import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import addIcon from '../../styles/images/addEllipseIcon.svg';

const DashboardCard = ({
    id,
    size,
    header,
    headerTitle,
    caption,
    actionBtn,
    actionBtnText,
    handlerActionBtn,
    headerStatus,
    headerStatusContent,
    iconSrc,
    icon,
    content,
    variant,
    secondaryActionBtn,
    secondaryActionBtnText,
    handleSecondaryActionBtn,
    additionalVariant,
    footer,
    footerBtnText,
    footerBtnHandler,
    footerBtnVariant,
    headerTitleClass = 'title-large',
    headerCaptionClass = 'caption-small',
    altContext,
    secondaryVariant,
    secondaryAdditionalVariant,
    secondaryIcon,
    secondaryIconSrc,
    customStyle = '',
}) => (<div className={`dashboardCard ${size} ${customStyle}`} id={id}>
    <header className='dashboardCardHeader'>
        <div className='dashboardHeaderText'>
            {header && <p className={`headerTitle ${headerTitleClass}`}>{headerTitle}</p>}
            {caption && <p className={`guidanceText ${headerCaptionClass}`}>{caption}</p>}
        </div>
        <div className='dashboardHeaderBtn'>
            {headerStatus && headerStatusContent}
            {secondaryActionBtn && <Button id='dashbaordSecondaryActionBtn'
                type='submit'
                variant={secondaryVariant}
                additionalVariant={secondaryAdditionalVariant}
                label={secondaryActionBtnText}
                handleClick={handleSecondaryActionBtn}
                icon={secondaryIcon}
                iconSrc={secondaryIcon ? secondaryIconSrc : addIcon}
            />
            }
            {actionBtn && <Button id='dashbaordActionBtn'
                type='submit'
                variant={variant}
                additionalVariant={additionalVariant}
                icon={icon}
                iconSrc={icon ? iconSrc : addIcon}
                label={actionBtnText}
                alt={altContext}
                handleClick={handlerActionBtn} />}
        </div>
    </header>
    <main className='dashboardCardMain'>{content}</main>
    {footer && <footer className='dashboardCardFooter'>
        <Button
            id='actionBtn'
            variant={footerBtnVariant || 'tertiary'}
            label={footerBtnText}
            handleClick={footerBtnHandler} />
    </footer>}
</div>);

DashboardCard.propTypes = {
    id: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    header: PropTypes.bool,
    headerTitle: PropTypes.string,
    caption: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    iconSrc: PropTypes.string,
    icon: PropTypes.bool,
    additionalVariant: PropTypes.string,
    variant: PropTypes.string,
    actionBtn: PropTypes.bool,
    actionBtnText: PropTypes.string,
    headerStatus: PropTypes.bool,
    headerStatusContent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]),
    secondaryActionBtn: PropTypes.bool,
    secondaryActionBtnText: PropTypes.string,
    handleSecondaryActionBtn: PropTypes.func,
    footer: PropTypes.bool,
    footerBtnText: PropTypes.string,
    handlerActionBtn: PropTypes.func,
    footerBtnHandler: PropTypes.func,
    footerBtnVariant: PropTypes.string,
    headerTitleClass: PropTypes.string,
    headerCaptionClass: PropTypes.string,
    altContext: PropTypes.string,
    secondaryVariant: PropTypes.string,
    secondaryAdditionalVariant: PropTypes.string,
    secondaryIconSrc: PropTypes.string,
    secondaryIcon: PropTypes.bool,
    customStyle: PropTypes.string,
};

export default DashboardCard;
