import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import LabelledInput, { LabelledInputTypeText, LabelledInputTypeSubmit } from '../../components/LabelledInput';
import ContactDrawer from './components/ContactDrawer';
import LabelledTextArea from '../../components/LabelledTextArea';
import Form from '../../components/Form';
import notificationsManagementAPIs from '../../services/notifications.service';
import Toast from '../../components/Alerts/Toast/Toast';

const ContactForm = () => {
    const history = useHistory();
    const [values, setValues] = useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (values) {
            // alert(`${values.firstName} ${values.lastName} ${values.emailAddress}
            // ${values.phone} ${values.organisation} ${values.enquiry}submitting form`);
            try {
                const response = await notificationsManagementAPIs.contactUs(values);
                if (response.status === 201) {
                    history.push('/contact');
                    Toast.fire({
                        icon: 'success',
                        titleText: 'Contact details and query have been submitted.',
                    });
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Contact details and query has been not submitted.',
                    });
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Contact details and query has been not submitted.',
                });
            }
        }
    };

    const handleChange = (event) => {
        const { name } = event.target;
        const { value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    return (
        <section id='cpMainContainer'>
            <ContactDrawer />
            <div id='cpFormContainer'>
                <p className='title-xLarge'>How can we help?</p>
                <Form id='cpForm' onSubmit={(e) => handleSubmit(e)}>
                    <div className='formInputContainer'>
                        <LabelledInput id='firstName'
                            type={LabelledInputTypeText} label='First name'
                            breakColumn={true} onChange={(e) => handleChange(e)}
                            value={values.firstName || ''}
                            placeholder='Enter first name' /></div>
                    <div className='formInputContainer'>
                        <LabelledInput id='lastName'
                            type={LabelledInputTypeText} label='Last name'
                            breakColumn={true} onChange={(e) => handleChange(e)}
                            value={values.lastName || ''}
                            placeholder='Enter last name' /></div>
                    <div className='formInputContainer'>
                        <LabelledInput id='emailAddress'
                            type={LabelledInputTypeText} label='Email address'
                            breakColumn={true} onChange={(e) => handleChange(e)}
                            value={values.emailAddress || ''}
                            placeholder='Enter email address' /></div>
                    <div className='formInputContainer'>
                        <LabelledInput id='phone'
                            type={LabelledInputTypeText} label='Phone number'
                            breakColumn={true} onChange={(e) => handleChange(e)}
                            value={values.phone || ''}
                            placeholder='Enter phone number' /></div>
                    <div className='formInputContainer'>
                        <LabelledInput id='organisation'
                            type={LabelledInputTypeText} label='Organisation name'
                            breakColumn={false} onChange={(e) => handleChange(e)}
                            value={values.organisation || ''}
                            placeholder='Enter organisation name' /></div>
                    <div className='flex-break' />
                    <div className='formInputContainer largeBox'>
                        <LabelledTextArea id='enquiry'
                            maxCharCount={1000}
                            placeholder='Write your message...' required={true}
                            breakColumn={true} label='Your message' value={values.enquiry || ''}
                            currentCharCount={values.enquiry ? values.enquiry.length : 0}
                            onChange={(e) => handleChange(e)} />
                    </div>
                    <div className='formButtonContainer'><LabelledInput id='submit' type={LabelledInputTypeSubmit} value='Send Message' label='' breakColumn={false} /></div>
                </Form>
            </div>
        </section>
    );
};


export default ContactForm;
