import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// import { format, parseISO } from 'date-fns';

import AuthContext from '../../context/AuthContext';
import projectManagementAPIs from '../../services/project-management.service';
import helperFunctions from '../../utils/helperFunctions';
import editIcon from '../../styles/images/edit.png';
import { procurementReqStatues } from '../../config/constants';

import Upload from '../../features/Upload';
import WorknotesModal from '../../features/WorknotesModal';

import Button from '../../components/Button';
import DashboardCard from '../../components/DashboardCard';
import Form from '../../components/Form';
import LabelledTextarea from '../../components/LabelledTextArea';
import Modal from '../../components/Modal';
import StatusLabel from '../../components/StatusLabel';
import Toast from '../../components/Alerts/Toast/Toast';
import { cleanupDeletedDocument, mergeUploadedDocuments, persistUploadedDocuments } from '../../utils/uploadHelper';

const ProcurementRequestOverview = () => {
    const [showWorknotes, setShowWorkNote] = useState(false);
    const [workNoteMode, setWorkNoteMode] = useState('');
    const [procumentDetails, setProcumentDetails] = useState({});
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const { prfId } = useParams();
    const { id: userId } = authContext.user;

    const getProcurementRequest = async () => {
        try {
            const response = await projectManagementAPIs.getPRF(prfId);
            if (response.status === 200) {
                setProcumentDetails(response.data);
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const uploadDocuments = async (docs) => {
        const mergedDocs = mergeUploadedDocuments(procumentDetails.additionalDocuments, docs,
            {}, authContext.user);

        const payload = {
            ...procumentDetails,
            additionalDocuments: mergedDocs,
        };

        delete payload.projectID;
        delete payload.user;

        await persistUploadedDocuments(
            'upload',
            () => projectManagementAPIs.editPRF(prfId, payload),
            () => setProcumentDetails(payload),
        );
    };

    const deleteDocument = async (doc) => {
        const mergedDocs = cleanupDeletedDocument(procumentDetails.additionalDocuments, doc);

        const payload = {
            ...procumentDetails,
            additionalDocuments: mergedDocs,
        };

        delete payload.projectID;
        delete payload.user;

        await persistUploadedDocuments(
            'delete',
            () => projectManagementAPIs.editPRF(prfId, payload),
            () => setProcumentDetails(payload),
        );
    };

    const viewWorkNotes = () => {
        setWorkNoteMode('view');
        setShowWorkNote(true);
    };

    const addWorkNotes = () => {
        setWorkNoteMode('add');
        setShowWorkNote(true);
    };

    const handleAddWorkNotes = async (worknotes) => {
        const payload = {
            ...procumentDetails,
            worknotes,
        };

        delete payload.projectID;
        delete payload.user;

        try {
            const response = await projectManagementAPIs.editPRF(prfId, payload);
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Worknote added successfully.',
                });
                setProcumentDetails(payload);
            }
        } catch {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to add worknote.',
            });
        }
    };

    const requestDetailsContent = () => <>
        {/* <div id='keyInformation' className='sectionHeader'>
            <p className='title-large keyInfo'>Key Information</p>
        </div> */}
        <div className='sectionContent'>
            <div id='description' className='sectionRow'>
                <p className='title sectionLabel'>Description:</p>
                <p className='caption sectionValue'>{procumentDetails.description}</p>
            </div>
            <div id='createDate' className='sectionRow'>
                <p className='title sectionLabel'>Date Submitted:</p>
                <p className='caption sectionValue'>{procumentDetails.createdOn ? helperFunctions.formatPrettyDate(procumentDetails.createdOn) : '-'}</p>
            </div>
            <div id='estimatedValue' className='sectionRow'>
                <p className='title sectionLabel'>Estimated Value:</p>
                <p className='caption sectionValue'>{procumentDetails.estimatedValue ? helperFunctions.formatCurrency(procumentDetails.estimatedValue) : '-'}</p>
            </div>
            <div id='contractStart' className='sectionRow'>
                <p className='title sectionLabel'>Estimated Contract Start Date:</p>
                <p className='caption sectionValue'>{procumentDetails.contractStart}</p>
            </div>
            <div id='contractDuration' className='sectionRow'>
                <p className='title sectionLabel'>Estimated Contract Duration:</p>
                <p className='caption sectionValue'>{procumentDetails.contractDuration}</p>
            </div>
            <div id='contractDuration' className='sectionRow'>
                <p className='title sectionLabel'>Raised By:</p>
                <p className='caption sectionValue'>{procumentDetails.user?.contactDetails?.username}</p>
            </div>
        </div>
    </>;

    const handleCancellation = async (event) => {
        event.preventDefault();
        const payLoad = {
            reason: cancelReason,
        };
        try {
            const cancelResponse = await projectManagementAPIs.cancelPRF(prfId, payLoad);
            if (cancelResponse.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Procurement request cancelled successfully.',
                });
                getProcurementRequest(prfId);
                setShowCancelModal(false);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to cancel procurement request.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to cancel procurement request.',
            });
        }
    };

    const handleRejection = async (event) => {
        event.preventDefault();
        const payLoad = {
            reason: rejectReason,
        };
        try {
            const cancelResponse = await projectManagementAPIs.rejectPRF(prfId, payLoad);
            if (cancelResponse.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Procurement request rejected successfully.',
                });
                getProcurementRequest(prfId);
                setShowRejectModal(false);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to reject procurement request.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to reject procurement request.',
            });
        }
    };

    const getPrettyType = (_type) => {
        if (_type) return helperFunctions.camel2title(_type);
        return '';
    };

    const procurementActionContent = () => {
        switch (procumentDetails.projectStatus) {
            case procurementReqStatues.awaitingApproval:
                return <div className='prfOverviewContainer'>
                    <div className={'dashboardCard large'} id={'prfActions'}>
                        <main className='dashboardCardMain'>
                            <div id='prfActionContainer'>
                                <StatusLabel
                                    id={'projectCancelledLabel'}
                                    labelTxt='Approval Pending'
                                    color={'red'}
                                />
                                {userId === procumentDetails.userID
                                && <Button
                                    id={'cancelRequest'}
                                    type={'button'}
                                    variant={'primary'}
                                    additionalVariant={'red'}
                                    label={'Cancel Request'}
                                    handleClick={() => setShowCancelModal(true)}
                                />}
                                {authContext.user?.privilege?.includes('reviewer')
                                && <>
                                    <Button
                                        id={'rejectRequest'}
                                        type={'button'}
                                        variant={'primary'}
                                        additionalVariant={'red'}
                                        label={'Reject'}
                                        handleClick={() => setShowRejectModal(true)} />
                                    <Button
                                        id={'createProject'}
                                        type={'button'}
                                        variant={'primary'}
                                        additionalVariant={''}
                                        label={'Create Project'}
                                        handleClick={() => {
                                            history.push(`/project/prf/${prfId}`);
                                        }}/>
                                </>}
                            </div>
                        </main>
                    </div>
                </div>;
            case procurementReqStatues.cancelled:
            case procurementReqStatues.rejected:
            case procurementReqStatues.approved:
                return procumentDetails.approvalHistory?.length > 0
                    ? <div className='prfOverviewContainer'>
                        <div className={'dashboardCard large'} id={'prfActions'}>
                            <main className='dashboardCardMain'>
                                {procumentDetails.approvalHistory.map((status, index) => (
                                    <div key={`procumentDetails-approvalHistory${index}`}>
                                        <p className={`title-xLarge ${status.action === procurementReqStatues.approved ? 'approved' : 'error'}`} id='action'>{status.action && getPrettyType(status.action)}</p>
                                        <p className='caption'>{`${getPrettyType(status.action)} by ${status.user.contactDetails.username} on 
                                        ${status.actionedOn && helperFunctions.formatPrettyDate(status.actionedOn)}`}</p>
                                        { status.reason && <p className='caption'><span className='title' id='labelText'>Reason :</span> {status.reason}</p>}
                                    </div>
                                ))}
                            </main>
                        </div>
                    </div>
                    : <></>;
            default: return <></>;
        }
    };

    useEffect(() => {
        getProcurementRequest();
    }, [prfId]);

    const isReadOnly = () => [procurementReqStatues.approved,
        procurementReqStatues.awaitingApproval,
    ].includes(procumentDetails.projectStatus) && (userId === procumentDetails.userID || authContext.user.privilege?.includes('reviewer'));

    return <div id='prfOverview'>
        {procurementActionContent()}
        <div className='prfOverviewContainer'>
            <DashboardCard
                id={'requestOverviewCard'}
                size={'large'}
                header={true}
                headerTitle={procumentDetails.title}
                caption={`Request # ${procumentDetails.projectReference}`}
                actionBtn={userId === procumentDetails.userID
                    && procumentDetails.projectStatus === procurementReqStatues.awaitingApproval}
                actionBtnText={'Edit Request'}
                handlerActionBtn={() => history.push(`/procurement-requests/edit/${prfId}`)}
                headerStatus={procumentDetails.projectStatus === procurementReqStatues.approved}
                headerStatusContent={<StatusLabel
                    id='projectApproved'
                    color='green'
                    labelTxt='Project Created'
                />}
                iconSrc={editIcon}
                icon={true}
                content={requestDetailsContent()}
                variant={'secondary'}
                secondaryActionBtn={false}
                additionalVariant={'skinless editProcurementDetails'}
                footer={false}
            />
        </div>
        <div className='prfOverviewContainer'>
            <div id={'prfActionCard'}>
                <DashboardCard
                    id='actions'
                    size='small'
                    header={true}
                    headerTitle='Actions'
                    content={<div id='prfActionContent'>
                        <div id='prfActionWorknotes'>
                            <p className='title sectionLabel' id='worknotes'> Worknotes</p>
                            <p className='caption'> {procumentDetails?.worknotes
                                ? procumentDetails?.worknotes?.length
                                : 0} worknotes added</p>
                            <div className='buttonContainer'>
                                <Button id='actionBtnS'
                                    size='small'
                                    variant='secondary'
                                    label='View'
                                    handleClick={viewWorkNotes} />
                                {isReadOnly()
                                && <Button id='actionBtn'
                                    size='small'
                                    variant='primary'
                                    label='Add'
                                    handleClick={addWorkNotes} />}
                            </div>
                        </div>
                        <Upload
                            title = {'Documents'}
                            allowMultiple = {true}
                            uploadIdentifier = {'document'}
                            uploadCallback={uploadDocuments}
                            deleteCallback={deleteDocument}
                            uploadedDocuments={procumentDetails.additionalDocuments}
                            readOnly={!isReadOnly()}
                        />
                    </div>}
                />
            </div>
            {showWorknotes
            && <WorknotesModal
                closeModal={() => setShowWorkNote(false)}
                mode={workNoteMode}
                worknotesInput={procumentDetails?.worknotes?.length > 0
                    ? procumentDetails.worknotes
                    : []}
                handleCallback={handleAddWorkNotes}
            />}
        </div>
        {showCancelModal
        && <Form onSubmit={handleCancellation}>
            <Modal
                prfId='cancelModal'
                open={true}
                size='medium'
                headerTitle='Cancellation Reason'
                body={<>
                    <LabelledTextarea
                        id='cancel'
                        label='Enter your reason for cancelling this request.'
                        breakColumn={true}
                        onChange={(e) => {
                            setCancelReason(e.target.value);
                        }}
                        value={cancelReason}
                        currentCharCount={cancelReason ? cancelReason.length : 0}
                        placeholder='Enter reason'
                        required={true} />
                </>}
                footer={true}
                mainActionButtonTxt='Submit'
                secondActionButtonTxt='Cancel'
                secondActionButton={true}
                handleMainActionBtnClick={() => null}
                handleSecondaryActionBtnClick={() => setShowCancelModal(false)}
                helpOption={false}
                closeModal={() => setShowCancelModal(false)} />
        </Form>}
        {showRejectModal
        && <Form onSubmit={handleRejection}>
            <Modal
                prfId='rejectModal'
                open={true}
                size='medium'
                headerTitle='Rejection Reason'
                body={<>
                    <LabelledTextarea
                        id='cancel'
                        label='Enter your reason for rejecting this request.'
                        breakColumn={true}
                        onChange={(e) => {
                            setRejectReason(e.target.value);
                        }}
                        value={rejectReason}
                        currentCharCount={rejectReason ? rejectReason.length : 0}
                        placeholder='Enter reason'
                        required={true} />
                </>}
                footer={true}
                mainActionButtonTxt='Submit'
                secondActionButtonTxt='Cancel'
                secondActionButton={true}
                handleMainActionBtnClick={() => null}
                handleSecondaryActionBtnClick={() => setShowRejectModal(false)}
                helpOption={false}
                closeModal={() => setShowRejectModal(false)} />
        </Form>}
    </div>;
};


export default ProcurementRequestOverview;
