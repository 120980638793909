import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    InputComponent,
    SelectSearchComponent,
    RadioButtonComponent,
    DatePickerComponent,
    SelectComponent,
} from './components';
import LabelledTextArea from '../../components/LabelledTextArea';
import LabelledSelect from '../../components/LabelledSelect';
import Button from '../../components/Button';
import addEllipseIcon from '../../styles/images/addEllipseIcon.svg';
import Tag from '../../components/Tag';
import FTSLots from './FTSLots';
import cpvSupplementaryCode from './cpvSupplementaryCode';

const SelectCPVCodesModal = React.lazy(() => import('../CodesModalCPV/SelectCPVCodesModal'));

const FTSContracts = ({
    noticeEvent,
    prefix,
    formFields,
    handleChange,
}) => {
    const [showCodesModal, setShowCodesModal] = useState(false);

    const generateDropdownOptions = (key, option) => (
        <option key={key} value={option.value}>{option.label}</option>
    );

    const handleRemoveCPVCode = () => {
        handleChange('', `${prefix}.cpvMain.cpvCode`);
        handleChange([], `${prefix}.cpvMain.notMappedCpvCode`);
    };

    const generateCodeTag = (data, isDeletable) => {
        const TagList = [];

        data.forEach((item) => {
            const TagElement = <>
                <Tag key={item.code}
                    id={item.code}
                    tagTxt={`${item.code} - ${item.description}`}
                    isDeletable={isDeletable}
                    size='large'
                    handleDeleteBtnClick={() => handleRemoveCPVCode()}
                />
                <input type='hidden' name={`${prefix}.cpvMain.cpvCode`} value={item.code} />
            </>;
            TagList.push(TagElement);
        });

        return (
            <div className='vpnItemTags'>
                {TagList}
            </div>
        );
    };

    const handleUpdateCPVCodes = (data) => {
        if (data.length > 0) {
            handleChange(data[0].code, `${prefix}.cpvMain.cpvCode`);
            handleChange(data, `${prefix}.cpvMain.notMappedCpvCode`);
        } else {
            handleChange('', `${prefix}.cpvMain.cpvCode`);
            handleChange([], `${prefix}.cpvMain.notMappedCpvCode`);
        }
    };

    const getPropObjValue = (object) => {
        const parts = prefix?.split('.');
        let returnProp = null;
        if (parts !== null && parts !== undefined) {
            for (let i = 0; i < parts.length; i++) {
                if (object[parts[i]]) {
                    returnProp = object[parts[i]];
                    // eslint-disable-next-line no-param-reassign
                    object = returnProp;
                } else { return null; }
            }
        }
        return returnProp;
    };

    return (<>
        <h3 className='title-large subHeading'>II.1. Scope of the procurement</h3>
        <h3 className='title-large subHeading'>II.1.1. Title</h3>
        <InputComponent
            id={`${prefix}.title`}
            label='Title'
            value={getPropObjValue(formFields)?.title}
            type='text'
            onChange={handleChange}
            required={true}
        />
        <InputComponent
            id={`${prefix}.referenceNumber`}
            label='Reference number'
            value={getPropObjValue(formFields)?.referenceNumber}
            type='text'
            onChange={handleChange}
            required={false}
        />

        {noticeEvent?.noticeType === 'award' && formFields?.notMapped?.isAwarded === 'true' && <>
            <RadioButtonComponent
                id={`${prefix}.notMapped.contract.totalValueOfContractLot`}
                label='Total value of the contract'
                value={getPropObjValue(formFields)?.notMapped?.contract?.totalValueOfContractLot}
                required={true}
                onChange={handleChange}
                options={{
                    EXACT: 'Exact',
                    LOWHIGHOFFERTAKEN: 'Lowest offer taken into consideration',
                }}
            />

            {getPropObjValue(formFields)?.notMapped?.contract?.totalValueOfContractLot === 'EXACT'
                    && <InputComponent
                        id={`${prefix}.valTotal.value`}
                        label='Value'
                        type='number'
                        value={getPropObjValue(formFields)?.valTotal?.value}
                        required={true}
                        onChange={handleChange}
                    />}

            {getPropObjValue(formFields)?.notMapped?.contract?.totalValueOfContractLot === 'LOWHIGHOFFERTAKEN' && <>
                <InputComponent
                    id={`${prefix}.valRangeTotal.low`}
                    label='Lowest offer'
                    type='number'
                    value={getPropObjValue(formFields)?.valRangeTotal?.low}
                    required={true}
                    onChange={handleChange}
                />

                <InputComponent
                    id={`${prefix}.valRangeTotal.high`}
                    label='Highest offer'
                    type='number'
                    value={getPropObjValue(formFields)?.valRangeTotal?.high}
                    required={true}
                    onChange={handleChange}
                />
            </>}

            <SelectComponent
                id={getPropObjValue(formFields)?.notMapped?.contract?.totalValueOfContractLot === 'EXACT'
                    ? `${prefix}.valTotal.currency` : `${prefix}.valRangeTotal.currency`}
                label='Currency'
                value={getPropObjValue(formFields)?.notMapped?.contract?.totalValueOfContractLot === 'EXACT'
                    ? getPropObjValue(formFields)?.valTotal?.currency
                    : getPropObjValue(formFields)?.valRangeTotal?.currency}
                required={true}
                onChange={handleChange}
                options={{
                    GBP: 'Pound (£)',
                    EUR: 'Euro (€)',
                    USD: 'Dollars ($)',
                }}
            />
        </>}

        <div className='formElementsDivider'></div>
        <h3 className='title-large subHeading'>II.1.2. Main CPV code</h3>
        <div className='vpnItem'>
            <div className='vpnItemNutsCode'>
                <h4 className='title'>Main CPV code</h4>
                <Button
                    id='addCodeButton'
                    size='small'
                    variant='secondary skinless'
                    label='Add'
                    icon={true}
                    iconSrc={addEllipseIcon}
                    disabled={false}
                    handleClick={(e) => {
                        e.preventDefault();
                        setShowCodesModal(true);
                    }}
                />
            </div>
            {generateCodeTag(getPropObjValue(formFields)?.cpvMain?.notMappedCpvCode || [], true)}
        </div>
        <div className='formElementsDivider'></div>
        <SelectSearchComponent
            id={`${prefix}.notMapped.cpvSupplementaryCodes`}
            label='Additional Supplementary Code'
            value={getPropObjValue(formFields)?.notMapped?.cpvSupplementaryCodes}
            onChange={(e) => handleChange(e, `${prefix}.notMapped.cpvSupplementaryCodes`)}
            required={true}
            options={cpvSupplementaryCode}
            optionInputType='obj'
        />
        <input
            type='hidden'
            name={`${prefix}.cpvSupplementaryCodes`}
            id={`${prefix}.cpvSupplementaryCodes`}
            value={getPropObjValue(formFields)?.notMapped?.cpvSupplementaryCodes}
        />
        <h3 className='title-large subHeading'>II.1.3. Type of contract</h3>
        {/* This is currently mapped from 0.02 */}
        <InputComponent
            id={`${prefix}.typeContract.ctype`}
            label='Type of contract'
            // eslint-disable-next-line no-nested-ternary
            value={noticeEvent.noticeType === 'preprocurement' ? formFields?.notMapped?.contractType : formFields?.objectContracts ? formFields?.objectContracts[0]?.typeContract?.ctype : ''}
            type='text'
            onChange={handleChange}
            disabled
            required={false}
        />
        <div className='formElementsDivider'></div>
        <h3 className='title-large subHeading'>II.1.4. Short description</h3>
        <LabelledTextArea
            id={`${prefix}.shortDescr`}
            placeholder='Enter description'
            breakColumn={true}
            label='Short description'
            value={getPropObjValue(formFields)?.shortDescr || ''}
            maxCharCount={4000}
            onChange={handleChange}
            required={true}
        />
        <div className='formElementsDivider'></div>
        { noticeEvent.noticeType !== 'award' && <><h3 className='title-large subHeading'>II.1.5. Estimated total value (excluding VAT)</h3>
            <InputComponent
                id={`${prefix}.${noticeEvent.noticeType !== 'award' ? 'valEstimatedTotal' : 'valTotal' }.value`}
                label='Estimated total value'
                value={ noticeEvent.noticeType !== 'award' ? getPropObjValue(formFields)?.valEstimatedTotal?.value : getPropObjValue(formFields)?.vatTotal?.value }
                type='number'
                onChange={handleChange}
                required={false}
            />
            <LabelledSelect
                id={`${prefix}.${noticeEvent.noticeType !== 'award' ? 'valEstimatedTotal' : 'valTotal' }.currency`}
                label='Currency'
                options={[
                    { label: 'Select Currency', value: '' },
                    { label: 'GBP', value: 'GBP' },
                ].map((option, idx) => generateDropdownOptions(idx, option))}
                value={noticeEvent.noticeType !== 'award' ? getPropObjValue(formFields)?.valEstimatedTotal?.currency : getPropObjValue(formFields)?.valTotal?.currency}
                onChange={handleChange}
                required={false}
            />
            <div className='formElementsDivider'></div></>}
        <h3 className='title-large subHeading'>II.1.6. Information about lots</h3>
        <RadioButtonComponent
            id={noticeEvent.noticeType !== 'award' ? 'notMapped.lots' : `${prefix}.lotDivision` }
            label='This contract is divided into lots'
            value={noticeEvent.noticeType !== 'award' ? formFields?.notMapped?.lots : getPropObjValue(formFields)?.lotDivision}
            required={true}
            onChange={handleChange}
            renderAsRow={true}
            options={{
                true: 'Yes',
                false: 'No',
            }}
        />

        {formFields?.notMapped?.lots === 'true' || getPropObjValue(formFields)?.lotDivision === 'true'
            ? <>
                <InputComponent
                    id={'notMapped.lotsQuantity'}
                    label='Quantity of lots'
                    value={formFields?.notMapped?.lotsQuantity}
                    type='number'
                    onChange={handleChange}
                    required={true}
                />
                { noticeEvent.noticeType === 'award' && <>
                    <h4 className='title-large subHeading'>II.1.7. Total value of the procurement (excluding VAT)</h4>
                    <RadioButtonComponent
                        id={'notMapped.valRangetype'}
                        breakColumn={false}
                        onChange={handleChange}
                        value={formFields?.notMapped?.valRangetype ? formFields?.notMapped?.valRangetype : ''}
                        options={{
                            exact: 'Exact',
                            range: 'Lowest/highest offer taken into consideration',
                        }}
                        renderAsRow={false}
                        required={false}
                    />
                    {formFields?.notMapped?.valRangetype === 'exact'
                    && <>
                        <InputComponent
                            id={`${prefix}.${noticeEvent.noticeType !== 'award' ? 'notMapped.valEstimatedTotal' : 'valTotal' }.value`}
                            label='Actual total value'
                            value={ noticeEvent.noticeType !== 'award' ? getPropObjValue(formFields)?.notMapped?.valEstimatedTotal?.value : getPropObjValue(formFields)?.vatTotal?.value }
                            type='number'
                            onChange={handleChange}
                            required={false}
                        />
                        <LabelledSelect
                            id={`${prefix}.${noticeEvent.noticeType !== 'award' ? 'notMapped.valEstimatedTotal' : 'valTotal' }.currency`}
                            label='Currency'
                            options={[
                                { label: 'Select Currency', value: '' },
                                { label: 'GBP', value: 'GBP' },
                            ].map((option, idx) => generateDropdownOptions(idx, option))}
                            value={noticeEvent.noticeType !== 'award' ? getPropObjValue(formFields)?.notMapped?.valEstimatedTotal?.currency : getPropObjValue(formFields)?.valTotal?.currency}
                            onChange={handleChange}
                            required={false}
                        />
                    </>
                    }
                    {formFields?.notMapped?.valRangetype === 'range'
                    && <>
                        <InputComponent
                            id={`${prefix}.valRangeTotal.low`}
                            label='Lowest offer'
                            type='number'
                            value={getPropObjValue(formFields)?.valRangeTotal?.low}
                            required={true}
                            onChange={handleChange}
                        />
                        <InputComponent
                            id={`${prefix}.valRangeTotal.high`}
                            label='Highest offer'
                            type='number'
                            value={getPropObjValue(formFields)?.valRangeTotal?.high}
                            required={true}
                            onChange={handleChange}
                        />
                        <LabelledSelect
                            id={`${prefix}.valRangeTotal.currency`}
                            label='Currency'
                            options={[
                                { label: 'Select Currency', value: '' },
                                { label: 'GBP', value: 'GBP' },
                            ].map((option, idx) => generateDropdownOptions(idx, option))}
                            value={getPropObjValue(formFields)?.valRangeTotal?.currency}
                            onChange={handleChange}
                            required={true}
                        />
                    </>}
                </>
                }
                {noticeEvent.noticeType !== 'award' && <><RadioButtonComponent
                    id={`${prefix}.lotDivision.type`}
                    label='Tenders may be submitted for'
                    value={getPropObjValue(formFields)?.lotDivision?.type}
                    required={false}
                    onChange={handleChange}
                    renderAsRow={false}
                    options={{
                        LOT_ALL: 'All Lots',
                        LOT_ONE_ONLY: 'One Lot Only',
                        LOT_MAX_NUMBER: 'Maximum Number of Lots',
                        NONE_OF_THE_ABOVE: 'None of the Above',
                    }}
                />
                <InputComponent
                    id={`${prefix}.lotDivision.lotMaxOneTenderer`}
                    label='Maximum number of lots that may be awarded to one tenderer'
                    value={getPropObjValue(formFields)?.lotDivision?.lotMaxOneTenderer}
                    type='number'
                    minNumber={0}
                    maxNumber={formFields?.notMapped?.lotsQuantity}
                    onChange={handleChange}
                    required={false}
                />
                {getPropObjValue(formFields)?.lotDivision?.type === 'LOT_MAX_NUMBER'
                && <InputComponent
                    id={`${prefix}.lotDivision.lotMaxNumber`}
                    label='Maximum number of lots'
                    value={getPropObjValue(formFields)?.lotDivision?.lotMaxNumber}
                    type='number'
                    onChange={handleChange}
                    required={false}
                />}
                <div className='formElementsDivider'></div>
                <LabelledTextArea
                    id={`${prefix}.lotDivision.lotCombiningContractRight`}
                    breakColumn={true}
                    label='The contracting authority reserves the right to award contracts combining the following lots or groups of lots '
                    value={getPropObjValue(formFields)?.lotDivision?.lotCombiningContractRight || ''}
                    maxCharCount={1000}
                    onChange={handleChange}
                    required={false}
                /></>}
                <div className='formElementsDivider'></div>
                {// eslint-disable-next-line no-undef
                    _.times(formFields?.notMapped?.lotsQuantity, (index) => (
                        <>
                            <h3 className='title-large subHeading'>Section II.2. Lot ({index + 1})</h3>
                            <FTSLots
                                prefix={`${prefix}.objectDescr.${index}`}
                                noticeEvent={noticeEvent}
                                handleChange={handleChange}
                                formFields={formFields}
                                lotDetails={true}
                            />
                        </>))}
            </>
            : <>
                <div className='formElementsDivider'></div>
                <FTSLots
                    prefix={`${prefix}.objectDescr.${0}`}
                    noticeEvent={noticeEvent}
                    handleChange={handleChange}
                    formFields={formFields}
                    lotDetails={false}
                />
            </>
        }
        {(noticeEvent.noticeType !== 'award' && formFields?.notice?.type !== 'PRI_CALL_COMPETITION' && noticeEvent.noticeType !== 'liveOpportunity')
        && <>
            <h3 className='title-large subHeading'>II.3. Estimated date of publication of contract notice</h3>
            <DatePickerComponent
                id={`${prefix}.datePublicationNotice`}
                label='Estimated date of publication of contract notice'
                value={getPropObjValue(formFields)?.datePublicationNotice}
                onChange={handleChange}
                minDateFlag={false}
                required={true}
            />
        </>}
        {showCodesModal
        && <SelectCPVCodesModal
            closeModal={() => setShowCodesModal(false)}
            onSubmit={handleUpdateCPVCodes}
            selectedCodes={[getPropObjValue(formFields)?.cpvMain?.cpvCode]}
            multiple={false}
        />}
    </>
    );
};

FTSContracts.propTypes = {
    project: PropTypes.object.required,
    contract: PropTypes.object.required,
    suppliers: PropTypes.array.required,
    noticeEvent: PropTypes.object.required,
    formFields: PropTypes.object.required,
    handleChange: PropTypes.func.required,
    setShowCodesModal: PropTypes.func.required,
    generateCodeTag: PropTypes.func.required,
    prefix: PropTypes.string.isRequired,
};

export default FTSContracts;
