const SupplierRegistrationConstants = {
    SET_CURRENTSTEP: 'SET_CURRENTSTEP',
    SET_USERTYPE: 'SET_USERTYPE',
    SET_COMPANYTYPE: 'SET_COMPANYTYPE',
    SET_CONSENTSTATUS: 'SET_CONSENTSTATUS',
    SET_COMPANYDETAILS: 'SET_COMPANYDETAILS',
    SET_HIDDENDETAILS: 'SET_HIDDENDETAILS',
    SET_SELECTEDHQRODATA: 'SET_SELECTEDHQRODATA',
    SET_ACCOUNTDETAILS: 'SET_ACCOUNTDETAILS',
    SET_NEWRODETAILS: 'SET_NEWRODETAILS',
    SET_SHOWRO: 'SET_SHOWRO',
    SET_SHOWROFORM: 'SET_SHOWROFORM',
    INITIATE_LOGIN: 'INITIATE_LOGIN',
    RESET_REG_FORM: 'RESET_REG_FORM',
    ACCOUNT_EXISTS: 'ACCOUNT_EXISTS',
    JOIN_ACCOUNT_ID: 'JOIN_ACCOUNT_ID',
    SET_CODES: 'SET_CODES',
    SET_ONLY_SELECTED_CODES: 'SET_ONLY_SELECTED_CODES',
    CLEAR_CODES: 'CLEAR_CODES',
    SET_REGIONS: 'SET_REGIONS',
    SET_ONLY_SELECTED_REGIONS: 'SET_ONLY_SELECTED_REGIONS',
    CLEAR_REGIONS: 'CLEAR_REGIONS',
    SET_LOCALITIES: 'SET_LOCALITIES',
    SET_ONLY_SELECTED_LOCALITIES: 'SET_ONLY_SELECTED_LOCALITIES',
    CLEAR_LOCALITIES: 'CLEAR_LOCALITIES',
};

export default SupplierRegistrationConstants;
