import React from 'react';
import PropTypes from 'prop-types';
import DashboardCard from '../../../../components/DashboardCard';
import Button from '../../../../components/Button';
import projectManagementAPIs from '../../../../services/project-management.service';
import Constants from '../../constants';

const SolutionAccessRequestsCard = (props) => {
    const REQUEST_STATUS_APPROVED = Constants.SOLUTION_ACCESS_REQUEST_STATUSES.APPROVED;
    const REQUEST_STATUS_REJECTED = Constants.SOLUTION_ACCESS_REQUEST_STATUSES.REJECTED;

    async function updateAccessRequest(request, status) {
        await projectManagementAPIs.updateSolutionAccessRequest(
            request.solutionID,
            request.departmentID,
            request.userID,
            props.contractReference,
            status,
        );

        props.reRenderParentCallback();
    }

    function getDepartmentName(departmentID, departments) {
        const department = departments.find((dept) => dept.departmentID === departmentID);

        return department?.departmentName ?? 'No Department Specified';
    }

    const CardContent = () => <>
        <div className='requests'>
            {props.solutionAccessRequests?.length > 0
                && props.solutionAccessRequests.map((req, idx) => req.account && <div className='request-item' key={`request-item${idx}`}>
                    <p className='username'>{req.user?.contactDetails?.username}</p>
                    <p className='departmentName'>
                        {`${getDepartmentName(req.departmentID, req.account.departments)} (${req.account.companyName})`}
                    </p>

                    <div className='actions'>
                        <Button
                            id={`${req.departmentID}-approve`}
                            className='approve'
                            variant='primary'
                            label='Approve'
                            size='small'
                            handleClick={() => updateAccessRequest(req, REQUEST_STATUS_APPROVED)} />
                        <Button
                            id={`${req.departmentID}-reject`}
                            className='reject'
                            variant='secondary'
                            label='Reject'
                            size='small'
                            handleClick={() => updateAccessRequest(req, REQUEST_STATUS_REJECTED)} />
                    </div>
                </div>)}
            {props.solutionAccessRequests?.length === 0
                && <p className='caption'>There are no access requests to review.</p>}
        </div>
    </>;

    return <DashboardCard
        id='solutionAccessRequestsCard'
        size='large'
        header={true}
        headerTitle={'Solution Access Requests'}
        content={CardContent()} />;
};

SolutionAccessRequestsCard.propTypes = {
    solutionAccessRequests: PropTypes.array,
    contractReference: PropTypes.string,
    reRenderParentCallback: PropTypes.func,
};

export default SolutionAccessRequestsCard;
