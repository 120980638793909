import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isPast, parse } from 'date-fns';
import PropTypes from 'prop-types';
import Upload from '../../../../../../features/Upload';
import Button from '../../../../../../components/Button';
import rightArrowHead from '../../../../../../styles/images/rightArrowHead.svg';
import StatusLabel from '../../../../../../components/StatusLabel';
import {
    allSteeringRoles, eventStatuses, eventStatusesLabel, workflowEventTypes, workflowEvents,
} from '../../../../../../config/constants';
import WorknotesModal from '../../../../../../features/WorknotesModal';
import eventButtons from './constants';
import Form from '../../../../../../components/Form';
import Modal from '../../../../../../components/Modal';
import LabelledTextarea from '../../../../../../components/LabelledTextArea';
import Confirm from '../../../../../../components/Alerts/Confirm/Confirm';
import Constants from '../../../../../../features/DocumentBuilder/components/EventModal/constants';
import AuthContext from '../../../../../../context/AuthContext';

const EventSection = ({
    event,
    projectId,
    contractId,
    handleUpdateEvent,
    handleEscalateEvent,
    handleUploadDocument,
    handleDeleteDocument,
    handleAddWorkNotes,
    handleExtentContract,
    handleCreateVariation,
    updateCompleteEventContract,
    awardedSupplierData = [],
    readOnly,
    userSteeringRoles = [],
}) => {
    const history = useHistory();
    const [expand, setExpand] = useState(false);
    const [showViewWorkNotes, setShowViewWorkNotes] = useState(false);
    const [showAddWorkNotes, setShowAddWorkNotes] = useState(false);
    const [escalateModal, setEscalateModal] = useState(false);
    const [showVariation, setShowVariation] = useState(false);
    const [escalationReason, setEscalationReason] = useState('');
    const [variationReason, setVariationReason] = useState('');
    const authContext = useContext(AuthContext);

    const getPrettyEventType = (eType) => workflowEventTypes.filter(
        (eventType) => eventType.value === eType,
    )[0]?.label;

    const getPrettyPublishType = (type) => Constants.publishTypes
        .filter((publishType) => (publishType?.value === type))[0]?.label;

    const getPrettySteeringGroupRole = (roleId) => allSteeringRoles
        .filter((steeringRole) => (steeringRole.role === roleId))[0]?.name;

    const getPrettyEventStatus = (eStatus) => (
        eventStatusesLabel.filter(
            (eventStatus) => eventStatus.value === eStatus,
        )[0]?.label
    );

    const handleUpdateEventStatus = (eventId, action, confirmAction) => {
        Confirm(handleUpdateEvent, {
            subTitle: `Are you sure you want to ${confirmAction} the workflow event - ${event.eventName}`,
        }, {
            eventId,
            action,
        });
    };

    const handleCompleteEventStatus = (eventId, action) => {
        Confirm(updateCompleteEventContract, {
            subTitle: `Are you sure you want to complete the workflow event - ${event.eventName}`,
        }, {
            eventId,
            action,
        });
    };
    const handleCloseEscalateEvent = () => {
        setEscalateModal(false);
        setEscalationReason('');
    };
    const handleCloseWorknote = () => {
        setShowAddWorkNotes(false);
        setShowViewWorkNotes(false);
    };
    const handleCloseVariationEventModal = () => {
        setShowVariation(false);
        setVariationReason('');
    };
    const handleRenewContract = () => {
        history.push(`/project/renewal/${projectId}/${contractId}`);
    };

    const generateExpandableSection = () => <div className='event-section-content'>
        <p className={`body-small ${event.additionalDocuments?.length !== 0
            ? 'success' : 'warning'}`}>
            {event.additionalDocuments
                ? event.additionalDocuments.length
                : 0} Documents
        </p>
        <p className={`body-small ${event.worknotes?.length === 0
            ? 'warning' : 'success'}`}>
            {event.worknotes
                ? event.worknotes.length
                : 0} Worknotes
        </p>
    </div>;

    const generateCollapsableSection = () => (<div className='component-container'>
        <Upload
            title = {'Documents'}
            allowMultiple = {true}
            uploadIdentifier = {'document'}
            uploadCallback={(data) => handleUploadDocument(data, event.eventID)}
            deleteCallback={(data) => handleDeleteDocument(event.eventID, data.documentID)}
            uploadedDocuments={event.additionalDocuments}
            readOnly={readOnly}
        />
        {authContext?.user?.accountType !== 'supplier'
            && <div className='worknotes-container'>
                <p className='title sectionLabel' id='worknotes'> Worknotes</p>
                <p className='caption'> {event?.worknotes
                    ? event?.worknotes?.length
                    : 0} worknotes added</p>
                <div className='button-container'>
                    <Button id='viewWorknoteBtn'
                        size='small'
                        variant='secondary'
                        label='View'
                        handleClick={() => setShowViewWorkNotes(true)} />
                    {!readOnly
                    && <Button id='addWorknoteBtn'
                        size='small'
                        variant='primary'
                        label='Add'
                        handleClick={() => setShowAddWorkNotes(true)} />
                    }
                </div>
            </div>}
    </div>);

    const getResponsibleSuppliers = (supplierIds) => {
        const suppliers = [];
        const awardedSupplier = awardedSupplierData.flatMap((supObj) => supObj.suppliers || supObj);
        supplierIds.map((Id) => (
            suppliers.push(awardedSupplier.filter(
                (sup) => sup.accountID === Id,
            )[0]?.account?.companyName)
        ));
        return suppliers.join(', ');
    };
    return (
        <><div className='event-section-container'>
            <div key={event.eventID} className={`event-section-card ${isPast(parse(event.endDate, 'dd-MM-yyyy', new Date()))
                && event.eventStatus !== eventStatuses.completed ? 'warning' : 'success'}`}>
                <div className = 'event-section-header'>
                    <div className='event-section-header-content'>
                        <p className='title mr-1'>{event.eventName}</p>
                        {isPast(parse(event.endDate, 'dd-MM-yyyy', new Date()))
                            && <StatusLabel id={`event-${event.eventID}-status`}
                                labelTxt= {event.eventStatus !== eventStatuses.completed ? 'Overdue' : 'Complete'}
                                color= {event.eventStatus !== eventStatuses.completed ? 'amber' : 'green'}
                            />}
                    </div>
                    <button type='button' className='event-section-header-button' id={`event-section-${event.eventID}-header`}
                        onClick={() => setExpand(!expand)}>
                        <span className='title-xSmall'>{ !expand ? 'Expand' : 'Collapse'}</span>
                        <img src={rightArrowHead} className={`event-section-header-icon
                            ${!expand ? '' : 'rotate' }`} alt='expand-collapse'>
                        </img>
                    </button>
                </div>
                <div className='event-section-content'>
                    <p className='caption-xSmall'>{getPrettyEventType(event.eventType)}</p>
                    {expand && <p className='body-small label'>{event.description}</p> }
                    <div className='role_Responsible'>
                        {event.eventType !== workflowEvents.supplierDocumentation
                            ? <><p className='body-small label'>Role Responsible: </p>
                                {event.rolesResponsible
                                    ? <p className='body-small label'>{getPrettySteeringGroupRole(event.rolesResponsible)}</p>
                                    : <p className='body-small label no_Role_Selected'>No Role Selected</p>}
                            </>
                            : <>{authContext?.user?.accountType !== 'supplier'
                                && <><p className='body-small label'>Supplier Responsible: </p>
                                    {event.suppliersResponsibleForEvent.length > 0
                                        ? <p className='body-small label'>{getResponsibleSuppliers(event.suppliersResponsibleForEvent)}</p>
                                        : <p className='body-small label no_Role_Selected'>No Supplier Selected</p>}
                                </>
                            }</>
                        }
                    </div>
                    <p className='body-small label'>End Date : {event.endDate}</p>
                    {workflowEvents.publish === event.eventType && <p className='body-small label'>Publish Level: {event.publishProject ? 'Project' : 'Lot'}</p> }
                    {workflowEvents.publish === event.eventType && <p className='body-small label'>Publish Type: {getPrettyPublishType(event.publishType)}</p>}
                    <StatusLabel id={`event-${event.eventID}-event-status`}
                        labelTxt= {getPrettyEventStatus(event.eventStatus)}
                        color= {'green'}
                    />
                </div>
                <div className='event-section-button'>
                    {!readOnly
                    && eventButtons(
                        event,
                        setEscalateModal,
                        setShowVariation,
                        handleUpdateEventStatus,
                        handleExtentContract,
                        handleRenewContract,
                        handleCompleteEventStatus,
                        userSteeringRoles,
                    )}
                </div>
                {expand ? (
                    <div className='event-section-subsection'>
                        {generateCollapsableSection()}
                    </div>
                ) : (
                    <div className='event-section-subsection'>
                        {generateExpandableSection()}
                    </div>
                )}
            </div>
        </div>
        {showVariation
        && <Form onSubmit={(e) => {
            e.preventDefault();
            handleCreateVariation(variationReason,
                handleCloseVariationEventModal);
        }}>
            <Modal
                id='variationContract'
                open={true}
                size='medium'
                headerTitle='Create Variation'
                body={
                    <LabelledTextarea
                        id='variation'
                        label={'Create variation for contract'}
                        breakColumn={true}
                        onChange={(e) => setVariationReason(e.target.value)}
                        value={variationReason || ''}
                        placeholder='Enter reason'
                        required={true}
                    />}
                footer={true}
                mainActionButtonTxt='Create'
                secondActionButtonTxt='Cancel'
                secondActionButton={true}
                handleMainActionBtnClick={() => null}
                handleSecondaryActionBtnClick={handleCloseVariationEventModal}
                helpOption={false}
                closeModal={handleCloseVariationEventModal}
            />
        </Form>
        }
        {escalateModal
            && <Form
                id={'escalateForm'}
                className={'escalateForm'}
                onSubmit={(e) => {
                    e.preventDefault();
                    handleEscalateEvent(
                        escalationReason,
                        event.eventID,
                        handleCloseEscalateEvent,
                    );
                }}>
                <Modal
                    id='escalateEventModal'
                    open={true}
                    size='medium'
                    headerTitle='Escalation Reason'
                    body={<LabelledTextarea
                        id='reason'
                        label='Enter your reason for raising an escalation for this workflow event.'
                        breakColumn={true}
                        onChange={(e) => setEscalationReason(e.target.value)}
                        value={escalationReason || ''}
                        placeholder='Enter reason'
                        required={true}
                    />}
                    footer={true}
                    mainActionButtonTxt='Submit'
                    secondActionButtonTxt='Cancel'
                    secondActionButton={true}
                    handleMainActionBtnClick={() => null}
                    handleSecondaryActionBtnClick={handleCloseEscalateEvent}
                    helpOption={false}
                    closeModal={handleCloseEscalateEvent}
                />
            </Form>
        }
        {showViewWorkNotes
        && <WorknotesModal
            closeModal={handleCloseWorknote}
            mode={'view'}
            worknotesInput={event && event.worknotes}
            handleCallback={(data, worknote) => handleAddWorkNotes(
                event.eventID, worknote, handleCloseWorknote,
            )}
        />}
        {showAddWorkNotes
        && <WorknotesModal
            closeModal={handleCloseWorknote}
            mode={'add'}
            worknotesInput={event && event.worknotes}
            handleCallback={(data, worknote) => handleAddWorkNotes(
                event.eventID, worknote, handleCloseWorknote,
            )}
        />}
        </>
    );
};

EventSection.propTypes = {
    event: PropTypes.object.isRequired,
    projectId: PropTypes.string,
    contractId: PropTypes.string,
    handleUpdateEvent: PropTypes.func,
    handleEscalateEvent: PropTypes.func,
    handleUploadDocument: PropTypes.func,
    handleDeleteDocument: PropTypes.func,
    handleAddWorkNotes: PropTypes.func,
    handleExtentContract: PropTypes.func,
    handleCreateVariation: PropTypes.func,
    updateCompleteEventContract: PropTypes.func,
    awardedSupplierData: PropTypes.array,
    readOnly: PropTypes.bool,
    userSteeringRoles: PropTypes.array,
};

export default EventSection;
