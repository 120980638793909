import React, {
    useContext, useEffect, useState, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import AuthContext from '../../context/AuthContext';
import Button from '../../components/Button';
import SupplierCard from './components/SupplierCard';
import DashboardCard from '../../components/DashboardCard';
import userManagementAPIs from '../../services/user-management.service';
import documentManagementAPIs from '../../services/document-management.service';
import gatewayAPIs from '../../services/gateway.service';
import Upload from '../../features/Upload';
import Modal from '../../components/Modal';
import rbac from '../../rbac';
import addEllipseIcon from '../../styles/images/addEllipseIcon.svg';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';
import GuidanceBanner from '../../components/GuidanceBanner';

import { cleanupDeletedDocument, mergeUploadedDocuments, persistUploadedDocuments } from '../../utils/uploadHelper';

const SupplierDashboard = () => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [myOpportunities, setMyOpportunities] = useState([]);
    const [opportunities, setOpportunities] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [selectionQuestionnaire, setSelectionQuestionnaire] = useState({});
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [requestStatus, setRequestStatus] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [documents, setDocuments] = useState([]);
    const { accountId } = authContext.user;
    const [dashboardData, setDashboardData] = useState([]);

    const getSectionData = useCallback(async () => {
        try {
            const response = await gatewayAPIs.dashboard();
            setDashboardData(response.data);
            setContracts(response.data?.myContracts);
            setTasks(response?.data?.myTasksAndEvents || []);
            setMyOpportunities(response?.data?.myOpportunities || []);
            setOpportunities(response?.data?.suggestedOpportunities || []);
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    }, [authContext.user.id, accountId]);

    // I think this could be added to some further complexity.
    const getSelectionQuestionnaire = async () => {
        const response = await documentManagementAPIs.getDefaultTemplate();
        if (response.status === 200) {
            setSelectionQuestionnaire(response.data[0]);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const fetchSupplierData = useCallback(async () => {
        setUserDetails(authContext?.user);
        setDocuments(authContext?.user?.additionalDocuments);
    }, [authContext.user.id]);

    useEffect(() => {
        if (authContext.authenticated && authContext.user?.details?.verified === 'approved') {
            fetchSupplierData();
            getSectionData();
            getSelectionQuestionnaire();
        }
    }, [authContext, fetchSupplierData, getSectionData]);

    const getStatus = (startDate, eventStatus) => {
        const current = format(new Date(), 'dd-MM-yyyy');
        if (eventStatus === 'Started') {
            return 'In-progress';
        }
        if (startDate < current) {
            return 'Overdue';
        }
        if (startDate >= current) {
            return 'Pending';
        }
        return 'Pending';
    };

    const taskEventContent = (data) => {
        const taskEventItems = data.map((taskEventItem, index) => <div className='cardContentContainer' key={`event${index}`}>
            <div className='eventItem'>
                <div className='leftColumn'><p className='caption-xSmall'>{taskEventItem.startDate ? taskEventItem.startDate : '-'}</p></div>
                <div className='middleColumn'>
                    <div className='eventHeader'>
                        <p className='title-xSmall'>{taskEventItem.eventName}</p>
                    </div>
                    <div className='eventContent'>
                        <p className='caption-xSmall'>{taskEventItem.projectName}</p>
                    </div>
                </div>
                <div className='rightColumn'>
                    <div className='eventHeader'>
                        <p className='eventStatus title-xSmall'>
                            <div className='statusText' id={getStatus(taskEventItem.startDate, taskEventItem.eventStatus)}>
                                <p className='title-small'>{getStatus(taskEventItem.startDate, taskEventItem.eventStatus)}</p>
                            </div>
                        </p>
                    </div>
                </div>
            </div>
        </div>);
        return taskEventItems;
    };

    const opportunityCont = (data) => <>
        <div className='contentItem'>
            <p className='caption-small itemLabel'>Buyer:</p>
            <p className='title-small itemValue'>{data.account?.companyName}</p>
        </div>
        <div className='contentItem'>
            <p className='caption-small itemLabel'>Estimated value:</p>
            <p className='title-small itemValue'>{data.estimatedValue ? helperFunctions.formatCurrency(data.estimatedValue) : '-'}</p>
        </div>
        <div className='contentItem'>
            <p className='caption-small itemLabel'>Register interest by:</p>
            <p className='title-small itemValue'>{helperFunctions.formatPrettyDateTime(data.expressionWindowEndDate)}</p>
        </div>
    </>;

    const opportunityContent = (data) => {
        const contentsHtml = [];
        data?.forEach((item, idx) => {
            contentsHtml.push(
                <SupplierCard
                    key={`OpportunityId${idx}`}
                    id={`oppId${idx}`}
                    header={true}
                    caption={`Opportunity # ${item.projectReference ? item.projectReference : '-'}`}
                    headerTitle={item.opportunityName}
                    content={opportunityCont(item)}
                    actionBtnText='View Opportunity'
                    handlerActionBtn={() => history.push(`/opportunities/overview/${item.opportunityID}`)}
                />,
            );
        });
        return contentsHtml;
    };

    const contractContent = (data) => <>
        <div className='contentItem'>
            <p className='caption-small itemLabel'>Value:</p>
            <p className='title-small itemValue'>{data.awardValue ? helperFunctions.formatCurrency(data.awardValue) : '-'}</p>
        </div>
        <div className='contentItem'>
            <p className='caption-small itemLabel'>Current Stage:</p>
            <p className='title-small itemValue'>{data.currentStage ? data.currentStage : '-'}</p>
        </div>
    </>;

    const contractsContent = (data) => {
        const contentsHtml = [];
        data.forEach(async (item, idx) => {
            if (idx < 3) {
                contentsHtml.push(
                    <SupplierCard
                        id={`contractId${idx}`}
                        key={`contractId${idx}`}
                        header
                        headerTitle={item.title}
                        caption={`Contract # ${item.contractReference ? item.contractReference : '-'}`}
                        content={contractContent(item)}
                        actionBtnText='View Contract'
                        handlerActionBtn={() => history.push(`/contracts/overview/${item.contractID}`)}
                    />,
                );
            }
        });
        return contentsHtml;
    };

    const handleRequest = (id, status, type) => {
        if (type === 'account') {
            setShowAccountModal(!showAccountModal);
        } else {
            setShowUserModal(!showUserModal);
        }
        setRequestStatus({ id, status });
    };

    const updateAccountRequests = async () => {
        try {
            const responseData = await userManagementAPIs.approveAccount(
                requestStatus.id,
                {
                    action: requestStatus.status,
                    reason: '',
                },
            );
            if (responseData && responseData.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Account request updated successfully.',
                });
                getSectionData();
                setShowAccountModal(!showAccountModal);
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update account request.',
            });
        }
    };

    const updateUserRequests = async () => {
        try {
            const responseData = await userManagementAPIs.approveUser(
                requestStatus.id,
                { action: requestStatus.status },
            );
            if (responseData && responseData.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Account request updated successfully.',
                });
                getSectionData();
                setShowUserModal(!showUserModal);
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update account request.',
            });
        }
    };

    const openAccountRequestContent = (data) => {
        const contentsHtml = [];
        data.forEach((item, idx) => {
            contentsHtml.push(
                <div className='accountAccessRequestItem' key={idx}>
                    <div className='userDetails'>
                        <p className='title'>{`${item?.contactDetails?.firstname} ${item?.contactDetails?.surname}`}</p>
                        <p className='title-xSmall'>{`${item.companyName}`}</p>
                    </div>
                    <div className='actionsButtonsContainer'>
                        <Button
                            size='small'
                            id='approve'
                            label='Approve'
                            variant='primary'
                            handleClick={() => handleRequest(item.accountID, 'approved', 'account')} />
                        <Button
                            size='small'
                            id='reject'
                            label='Reject'
                            variant='secondary'
                            handleClick={() => handleRequest(item.accountID, 'rejected', 'account')} />
                    </div>
                </div>,
            );
        });
        if (contentsHtml.length === 0) {
            contentsHtml.push(<p className='body caption'>No pending open account requests.</p>);
        }
        return contentsHtml;
    };

    const openUserRequestContent = (data) => {
        const contentsHtml = [];
        data.filter((el) => el.userID !== authContext.user.id).forEach(async (item, idx) => {
            contentsHtml.push(
                <div className='accountAccessRequestItem' key={idx}>
                    <div className='userDetails'>
                        <p className='title'>{`${item.contactDetails?.firstname} ${item.contactDetails?.surname}`}</p>
                        <p className='caption'>{item.email}</p>
                        <p className='caption'>{item.departmentName}</p>
                    </div>
                    <div className='actionsButtonsContainer'>
                        <Button
                            size='small'
                            id='approve'
                            label='Approve'
                            variant='primary'
                            handleClick={() => handleRequest(item.userID, 'approved', 'user')} />
                        <Button
                            size='small'
                            id='reject'
                            label='Reject'
                            variant='secondary'
                            handleClick={() => handleRequest(item.userID, 'rejected', 'user')} />
                    </div>
                </div>,
            );
        });
        if (contentsHtml.length === 0) {
            contentsHtml.push(<p className='body caption'>No pending open user requests.</p>);
        }
        return contentsHtml;
    };

    const uploadDocuments = async (newDocs) => {
        const mergedDocs = mergeUploadedDocuments(documents, newDocs,
            {}, authContext.user);

        const payload = {
            additionalDocuments: mergedDocs,
        };

        await persistUploadedDocuments(
            'upload',
            () => userManagementAPIs.updateAccountDocuments(payload),
            fetchSupplierData, // Success
            fetchSupplierData, // Fail
        );
    };

    const deleteDocument = async (doc) => {
        const mergedDocs = cleanupDeletedDocument(documents, doc);

        const payload = {
            additionalDocuments: mergedDocs,
        };

        await persistUploadedDocuments(
            'delete',
            () => userManagementAPIs.updateAccountDocuments(payload),
            fetchSupplierData, // Success
            fetchSupplierData, // Fail
        );
    };

    const generateUnVerifiedContent = () => {
        switch (true) {
            case authContext.user.accountVerified === 'pending':
                return <>
                    <p className='title-large' id='boError'>Request for registration is now with your company admin for approval.</p>
                    <p className='caption-large'>We will email you as soon as you have access to your company’s account on Open.</p>
                </>;
            case authContext.user.accountVerified === 'rejected':
                return <>
                    <p className='title-large' id='boError'>Your request to join this account has been rejected by your company admin. Please contact them directly or register under a separate company account.</p>
                </>;
            case authContext.user.accountVerified === 'approved' && authContext.user?.details?.verified === 'pending':
                return <>
                    <p className='title-large' id='boError'>Request for registration is now with your company admin for approval.</p>
                    <p className='caption-large'>We will email you as soon as you have access to your company’s account on Open.</p>
                </>;
            case authContext.user.accountVerified === 'approved' && authContext.user?.details?.verified === 'rejected':
                return <>
                    <p className='title-large' id='boError'>Your request to join this account has been rejected by your company admin. Please contact them directly or register under a separate company account.</p>
                </>;
            default:
                return <></>;
        }
    };

    return (authContext.user.accountVerified === 'approved' && authContext.user?.details?.verified === 'approved')
        ? <>
            {((userDetails.organisationDetails?.deliveryAreaInfo?.length === 0
                || !userDetails.organisationDetails?.deliveryAreaInfo
                || !userDetails.organisationDetails?.industryInfo
                || userDetails.organisationDetails?.industryInfo?.length === 0))
                // || selectionQuestionnaire?.templateStatus !== 'submitted')
                ? <div className='dashboardContainer'>
                    <GuidanceBanner
                        id={'supplierGuidanceBanner'}
                        type={''}
                        headerText={'Complete your profile'}
                        // bannerText1={(selectionQuestionnaire?.templateStatus !== 'submitted'
                        //     && rbac.can(rbac.action.addEditSelectionQuestionnaire,
                        //         [authContext.user.accountType.toLowerCase()
                        //             + authContext.user.role,
                        //         ]))
                        //     // ?
                        //     && <>Your company has not yet completed a Selection Questionnaire.
                        // eslint-disable-next-line max-len
                        //          Click <a href='' onClick ={() => { history.push('/supplier/view/sq'); }}>here</a> to complete and save some time later.</>
                        //     //  What's the rbac solution here?
                        //     // : <>{!userDetails.questionnaire
                        //     //     ? `Your company has not completed the questionnaire.
                        //     //          Please ask Admin to answer the selection questionnaire.`
                        //     //     : <></>}
                        //     // </>
                        // }
                        bannerText2={
                            ((userDetails.organisationDetails?.deliveryAreaInfo?.length === 0
                                || !userDetails.organisationDetails?.deliveryAreaInfo
                                || !userDetails.organisationDetails?.industryInfo
                                || userDetails.organisationDetails?.industryInfo?.length === 0)
                            && rbac.can(rbac.action.addEditSelectionQuestionnaire,
                                [authContext.user.accountType.toLowerCase()
                                    + authContext.user.role,
                                ]))
                                ? <>Your company doesn’t have Codes and Categories
                                     and/or Delivery Areas assigned.
                                Click <a href='' onClick ={() => { history.push('/my-profile'); }}>here</a> to assign by editing your Profile.</>
                                : <>{(
                                    userDetails.organisationDetails?.deliveryAreaInfo?.length
                                        === 0
                                    || userDetails.organisationDetails?.industryInfo?.length === 0)
                                    ? `Your company doesn't have Codes and Categories
                                     and/or Delivery Areas assigned.
                                     Please ask Admin to assign.`
                                    : <></>}
                                </>}
                        buttonArr={[]}
                        timerEndDate={''}
                        showStatus={''}
                        actionableItem={''}
                    />
                </div>
                : <></>}
            <div className='dashboardContainer'>
                <DashboardCard
                    id='suggestedOpportunities'
                    size='large'
                    header={true}
                    headerTitle='Suggested Opportunities'
                    caption='Using the information you provided during registration, we suggest the following opportunities that we feel would be relevant for you to pursue.'
                    content={<div className='dashboardCardMainRow'>
                        {(opportunities?.length > 0)
                            ? opportunityContent(opportunities)
                            : <><p className='caption'>No suggested opportunities found at the moment.</p></>
                        }</div>}
                    footer={true}
                    footerBtnText='View All'
                    footerBtnHandler={() => history.push('/opportunities')}
                    customStyle = 'customStyle'
                />
            </div>
            <div className='dashboardContainer'>
                <DashboardCard
                    id='taskEventCard'
                    size='small'
                    header={true}
                    headerTitle={'My Tasks and Events'}
                    actionBtn={false}
                    content={(tasks?.length > 0) ? taskEventContent(tasks) : <><p className='caption'>You don&apos;t have any assigned tasks and events in-progress or pending.</p></>}
                    footer={true}
                    footerBtnText='View All'
                    footerBtnHandler={() => history.push('/calendar')}
                    customStyle = 'customStyle'
                />
                <DashboardCard
                    id='myOpportunities'
                    size='medium'
                    header={true}
                    headerTitle={'My Opportunities'}
                    caption='Opportunities you have registered interest in and are currently working on.'
                    content={<div className='dashboardCardMainRow'>
                        {(myOpportunities?.length > 0)
                            ? opportunityContent(myOpportunities)
                            : <><p className='caption'>You are yet to register interest in an opportunity.</p></>}
                    </div>}
                    footer={true}
                    footerBtnText='View All'
                    footerBtnHandler={() => history.push(`/opportunities/${'myOpportunities'}`)}
                    customStyle = 'customStyle'
                />
            </div>
            <div className='dashboardContainer'>
                { rbac.can(rbac.action.openAccountRequests,
                    [authContext.user.accountType.toLowerCase() + authContext.user.role])
            && <DashboardCard
                id='openRequestContentCard'
                size='small'
                header={true}
                headerTitle='Regional Office Requests'
                caption='Regional Offices created in Open, pending your approval.'
                actionBtn={false}
                content={(dashboardData.accountsWaitingApproval?.length > 0)
                    ? <div className='cardContentContainer'>{openAccountRequestContent(dashboardData.accountsWaitingApproval)}</div>
                    : <><p className='caption'>There are no open regional office requests.</p></>
                }
                customStyle = 'customStyle'
            />
                }
                <DashboardCard
                    id='myContracts'
                    size='medium'
                    header={true}
                    headerTitle='My Contracts'
                    actionBtn={false}
                    content={<div className='dashboardCardMainRow'>
                        {(contracts?.length > 0)
                            ? contractsContent(contracts)
                            : <><p className='caption'>No contracts available.</p></>}
                    </div>}
                    footer={true}
                    footerBtnText='View All'
                    footerBtnHandler={() => history.push('/contracts')}
                    customStyle = 'customStyle'
                />
            </div>
            <div className='dashboardContainer'>
                { rbac.can(rbac.action.openUserRequests,
                    [authContext.user.accountType.toLowerCase() + authContext.user.role])
                    && <DashboardCard
                        id='openUserRequestContentCard'
                        size='small'
                        header={true}
                        headerTitle='Open User Requests'
                        caption='Users created on Open by a member of your organisation, pending your approval.'
                        actionBtn={false}
                        content={(dashboardData.usersWaitingApproval?.length > 0)
                            ? <div className='cardContentContainer'>{openUserRequestContent(dashboardData.usersWaitingApproval)}</div>
                            : <><p className='caption'>There are no open user requests.</p></>
                        }
                        customStyle = 'customStyle'
                    />}
                <DashboardCard
                    id='actions'
                    size='small'
                    header={true}
                    headerTitle='Documents'
                    caption={'Please note: Documents added into this area will not only be accessible to you but also to all Buyers registered in Open. Documents which may be useful to upload in this area are: insurance certificates, health & safety certificates, Modern Slavery policy and case studies, among others.'}
                    content={<div className='overview-body' >
                        <Upload
                            title='Documents'
                            allowMultiple={true}
                            uploadIdentifier='Document'
                            uploadCallback={uploadDocuments}
                            deleteCallback={deleteDocument}
                            questionnaire={false}
                            uploadedDocuments={documents}
                        />
                    </div>}
                    customStyle = 'customStyle'
                />

                <DashboardCard
                    id='selection-questionnaire'
                    size='small'
                    header={true}
                    headerTitle='Selection Questionnaire'
                    // actionBtn={rbac.can(rbac.action.addEditSelectionQuestionnaire,
                    //     [authContext.user.accountType.toLowerCase() + authContext.user.role])
                    // }
                    // Right now we just retrieve the default questionnaire
                    actionBtn={false}
                    actionBtnText={'Add'}
                    handlerActionBtn={() => history.push('/supplier/edit/sq')}
                    iconSrc={addEllipseIcon}
                    icon={true}
                    additionalVariant={'secondary skinless align-right'}
                    caption={''}
                    // eslint-disable-next-line max-len
                    // content={<p className='caption'>The Selection Questionnaire has been temporarily disabled while the Open team are working on updates.</p>}
                    content={selectionQuestionnaire
                        ? <div className='overview-body' >
                            <p className='title sectionLabel'>The Selection Questionnaire has been temporarily disabled while the Open team are working on updates.</p>
                            <div className='buttonContainer'>
                                {selectionQuestionnaire?.templateStatus !== 'submitted'
                                && <Button id='actionBtnS'
                                    size='small'
                                    variant='secondary'
                                    label='View'
                                    handleClick={() => history.push('/supplier/view/sq')}
                                />}
                                {/* {rbac.can(rbac.action.addEditSelectionQuestionnaire,
                                    [authContext.user.accountType.toLowerCase()
                                    + authContext.user.role])
                                    && <Button id='actionBtnS'
                                        size='small'
                                        variant='primary'
label={selectionQuestionnaire?.templateType === 'defaultSelectionResponse' ? 'Edit' : 'Respond'}
                                        handleClick={() => history.push('/supplier/edit/sq')}
                                    />} */}
                            </div>
                        </div>
                        : <div className='overview-body' >
                            <p className='caption'>Please answer the selection questionnaire.</p>
                        </div>}
                    customStyle = 'customStyle'
                />
            </div>
            {showAccountModal && <Modal
                id='approveRejectBtn'
                open={true}
                size='small'
                headerTitle='Are you sure?'
                body={<p className='body'>{`Are you sure you want to ${requestStatus.status === 'approved' ? 'approve' : 'reject'} the open account request?`}</p>}
                footer={true}
                mainActionButtonTxt='Yes'
                secondActionButtonTxt='No'
                secondActionButton={true}
                helpOption={false}
                handleMainActionBtnClick={(event) => {
                    event.preventDefault();
                    updateAccountRequests();
                }
                }
                handleSecondaryActionBtnClick={() => {
                    setRequestStatus({});
                    setShowAccountModal(!showAccountModal);
                }}
                closeModal={() => { setShowAccountModal(!showAccountModal); }}
            />}
            {showUserModal && <Modal
                id='approveRejectBtn'
                open={true}
                size='small'
                headerTitle='Are you sure?'
                body={<p className='body'>{`Are you sure you want to ${requestStatus.status === 'approved' ? 'approve' : 'reject'} the user request?`}</p>}
                footer={true}
                mainActionButtonTxt='Yes'
                secondActionButtonTxt='No'
                secondActionButton={true}
                helpOption={false}
                handleMainActionBtnClick={(event) => {
                    event.preventDefault();
                    updateUserRequests();
                }
                }
                handleSecondaryActionBtnClick={() => {
                    setRequestStatus({});
                    setShowUserModal(!showUserModal);
                }}
                closeModal={() => { setShowUserModal(!showUserModal); }}
            />}
        </>
        : <div id='supplierPendingApproval'>
            {generateUnVerifiedContent()}
        </div>;
};

export default SupplierDashboard;
