import React, { useEffect, useState } from 'react';
import { useHistory, matchPath, useLocation } from 'react-router-dom';
import axios from 'axios';

import instance from './axios.instance';
import * as routes from './route.constants';

import Modal from '../components/Modal';
import redirectToLoginURL from '../services/auth.service';

const unauthorizedStatuses = [401, 403];

const dashboardPath = '/dashboard';
const jwtAuthPaths = [
    '/gateway/auth/refresh',
    '/gateway/auth/verify',
];

/**
 * Use the route constants to get all the routes marked as public, except the catch all route
 */
const publicRoutes = routes.default.routes.filter((route) => (route.publicRoute && route.path !== '*'));

/**
 * @param {object} error The Axios error object
 * @returns {Promise} Rejected promise, throwing the Axios error
 */
const errorHandler = (error) => Promise.reject(error);

/**
 * Generic response interceptor, does nothing, but is required for response interceptor registration
 * @param {object} response - The Axios response object
 * @returns {object} response - The Axios response object
 */
const responseInterceptor = (response) => response;

/**
 * Determine if the provided path is one of the public routes registered in the react router
 * @param {string} pathname - The frontend path to match against the registered public routes
 * @returns {boolean} - Whether the provided path matched any of the public routes
 */
const pathRequiresLogin = (pathname) => (
    !publicRoutes.some((route) => (
        matchPath(pathname, {
            path: route.path,
            exact: true,
            strict: false,
        })
    ))
);

/**
 * Axios interceptor functional component. Provides the ability to catch 403/unauthorized requests
 * on pages which are not public.
 */
const AuthInterceptor = () => {
    const history = useHistory();
    const location = useLocation();

    const [showModal, setShowModal] = useState(false);

    const redirectToLogin = (returnUrl) => {
        redirectToLoginURL(returnUrl, history);
    };

    const forbiddenModalBody = () => (
        <>
            <p className='title'>You do not have permission to view this content.</p>
        </>
    );

    const handleModal = (state) => {
        setShowModal(state);

        // Go back one, or back to the dashboard if the user hotlinked
        if (history.length > 2) {
            history.goBack();
        } else {
            history.push(dashboardPath);
        }
    };

    /**
     * Determine whether to just throw the error, or to handle a logged out state
     * by redirection or other custom behaviour.
     * @param {object} error - The Axios error object
     * @returns {Promise} - A rejected promise containing the Axios error
     */
    const responseErrorHandler = (error) => {
        const currentPath = location.pathname;

        if (unauthorizedStatuses.includes(error.response?.status)) {
            if (jwtAuthPaths.includes(error.config.path)) {
                redirectToLogin(currentPath);
            }

            if (pathRequiresLogin(currentPath)) {
                switch (error.response.status) {
                    case 401:
                        redirectToLogin(currentPath);
                        break;
                    default:
                        setShowModal(true);
                        break;
                }
            }
        }

        return errorHandler(error);
    };

    /**
     * Mount and unmount the interceptors when the component mounts/unmounts
     */
    useEffect(() => {
        const instanceResponseInterceptor = instance.interceptors.response.use(
            responseInterceptor, responseErrorHandler,
        );

        const mainResponseInterceptor = axios.interceptors.response.use(
            responseInterceptor, responseErrorHandler,
        );

        // To start listening for location changes...
        const unlisten = history.listen(() => {
            // The current location changed.
            setShowModal(false);
        });

        // Unload the interceptors when the component unmounts
        return () => {
            instance.interceptors.response.eject(instanceResponseInterceptor);
            axios.interceptors.response.eject(mainResponseInterceptor);
            unlisten();
        };
    });

    // No UI to return from this component
    return (
        <>
            {showModal && <Modal id={'forbiddenError'}
                open={showModal}
                footer={true}
                headerTitle='Access denied'
                body={forbiddenModalBody()}
                helpOption={false}
                allowCloseButton={false}
                mainActionButtonTxt='Go back'
                handleMainActionBtnClick={() => handleModal(false)} />
            }
        </>
    );
};

export default AuthInterceptor;
