import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CompanyDetailsForm from '../../features/SupplierRegistration/components/CompanyDetails';
import Confirm from '../../components/Alerts/Confirm/Confirm';
import constants from '../../features/SupplierRegistration/constants';
import Form from '../../components/Form';
import ProgressBar from '../../features/ProgressBar';
import Modal from '../../components/Modal';
import actions from '../../features/SupplierRegistration/slice/supplier-reg.action';
import userManagementAPIs from '../../services/user-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import GuidanceBanner from '../../components/GuidanceBanner';

const CompanyDetails = () => {
    const history = useHistory();
    const {
        initFormData, userType, companyType, newRODetails, showRO, showROForm,
        hiddenDetails, selectedHQROData,
    } = useSelector((state) => ({
        initFormData: state.SupplierRegReducer.companyDetails,
        userType: state.SupplierRegReducer.userType,
        companyType: state.SupplierRegReducer.companyType,
        newRODetails: state.SupplierRegReducer.newRODetails,
        showRO: state.SupplierRegReducer.showRO,
        hiddenDetails: state.SupplierRegReducer.hiddenDetails,
        selectedHQROData: state.SupplierRegReducer.selectedHQROData,
        showROForm: state.SupplierRegReducer.showROForm,
    }));
    const [openModalToShowHQ, setOpenModalToShowHQ] = useState(false);
    const [companyExistData, setCompanyExistData] = useState(constants.companyExistData);
    const [selectHQAccountID, setSelectHQAccountID] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (userType === '' || companyType === '') {
            history.push('/');
        }
        const hiddenData = {
            accountID: hiddenDetails.accountID || '',
            departments: [],
            wishToJoin: true,
        };
        dispatch(actions.setHiddenDetails(hiddenData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const prepareFormState = () => {
        let preparedFormState = [];
        preparedFormState = constants.companyDetails.map((el) => ({
            ...el,
            value: el.id === 'businessStructure' ? companyType : initFormData[el.id],
            readonly: el.id !== 'businessStructure' ? !initFormData[`${el.id}-isChanged`] && !!initFormData[el.id] : true,
        }));
        return preparedFormState;
    };

    const formState = prepareFormState();

    const persistHiddenData = (data) => {
        const hiddenData = {
            accountID: data.accountID || '',
            departments: data.departments || '',
            wishToJoin: data.wishToJoin,
        };
        dispatch(actions.setHiddenDetails(hiddenData));
    };

    const prepareROFormState = () => {
        let preparedFormState = [];
        preparedFormState = constants.regionalOfficeFormData.map((el) => ({
            ...el,
            value: newRODetails[el.id],
            readonly: newRODetails[`${el.id}-isChanged`] !== undefined && !newRODetails[`${el.id}-isChanged`] && !!newRODetails[el.id],
        }));
        return preparedFormState;
    };

    const roFormState = prepareROFormState();

    const prev = (event) => {
        event.preventDefault();
        // eslint-disable-next-line no-restricted-globals
        const response = confirm('Are you sure you want to go back to the home page? This form will be discarded.');
        if (response === true) history.push('/');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // when hidden Data is empty
        if (!hiddenDetails.accountID && hiddenDetails.wishToJoin) {
            // Hit companyExist API to wish to Join HQ only
            try {
                const body = {
                    companyName: initFormData.accountName,
                    regNo: initFormData.registrationNumber,
                    postcode: initFormData.postcode,
                };
                const responseData = await userManagementAPIs
                    .checkHQAndListRO(body);

                let companyExistApiResponse = [];
                if (responseData.status === 200 && responseData.data.length > 0) {
                    companyExistApiResponse = responseData.data;
                }
                setCompanyExistData(companyExistApiResponse);
                if (responseData.data.length > 0) {
                    setOpenModalToShowHQ(true);
                } else {
                    const hiddenData = {
                        accountID: '',
                        departments: [],
                        wishToJoin: undefined,
                    };
                    persistHiddenData(hiddenData);
                    history.push('/registration/codes-and-categories');
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        }
        if (hiddenDetails.accountID || !hiddenDetails.wishToJoin) {
            if (showROForm && !selectedHQROData.selectedROAccountID) {
                Confirm(async () => {
                    history.push('/registration/codes-and-categories');
                }, {
                    subTitle: `You are joining ${initFormData.accountName}. Please confirm.`,
                });
            } else {
                Confirm(async () => {
                    history.push('/registration/account-details');
                }, {
                    subTitle: `You are joining ${initFormData.accountName}. Please confirm.`,
                });
            }
        }
    };

    const onHQSelect = () => {
        if (!selectHQAccountID) {
            Toast.fire({
                icon: 'error',
                titleText: 'Please select a company before continuing.',
            });
            return;
        }
        setOpenModalToShowHQ(false);
        const companyData = companyExistData.find((o) => o.accountID === selectHQAccountID);
        const hiddenData = {
            accountID: companyData.accountID,
            departments: companyData.departments,
            wishToJoin: true,
        };
        persistHiddenData(hiddenData);
        history.push('/registration/account-details');
    };

    const noToJoin = () => {
        setOpenModalToShowHQ(false);
        const hiddenData = {
            wishToJoin: true,
        };
        persistHiddenData(hiddenData);
    };

    const handleHQSelect = (e) => {
        setSelectHQAccountID(e.target.value);
        const currentStatus = e.target.checked;
        const cbs = document.getElementsByClassName('roHQCB');
        for (let i = 0; i < cbs.length; i++) {
            cbs[i].checked = false;
        }
        if (cbs.length === 1) {
            e.target.checked = currentStatus;
        } else {
            e.target.checked = true;
        }
    };
    const closeModal = () => {
        setOpenModalToShowHQ(false);
    };
    const roHQAddressHtml = (item) => <>
        <div className='formInputContainer'>
            <div className='labelled-checkBox checkBox-group '>
                <div className='form-checkBox-container'>
                    <input type='checkbox' className='form-checkBox green roHQCB' id={item.companyName} name={item.companyName} value={item.accountID}
                        onClick={handleHQSelect}>
                    </input>
                    <label className='form-label checkBox-label title' id={item.companyName} htmlFor={item.companyName}>{item.companyName}</label>
                </div>
                <div>
                    <label className='form-label checkBox-label title roData' >{item.businessStructure}</label>
                </div>
                <div>
                    <label className='form-label checkBox-label title roData' id={item.address.addressLine1}>{item.address.addressLine1}</label>
                </div>
                {item.address.addressLine2 && <div>
                    <label className='form-label checkBox-label title roData' id={item.address.addressLine2}>{item.address.addressLine2}</label>
                </div>
                }
                <div>
                    <label className='form-label checkBox-label title roData'>{item.address.city}</label>
                </div>
                {item.address.county
                    && <div>
                        <label className='form-label checkBox-label title roData'>{item.address.county}</label>
                    </div>
                }
                <div>
                    <label className='form-label checkBox-label title roData' id={item.address.postcode}>{item.address.postcode}</label>
                </div>

                <div>
                    <label className='form-label checkBox-label title roData'>{item.address.country}</label>
                </div>
                <div>
                    <label className='form-label checkBox-label title roData'>{item.companyCountryCode || ''} {item.phoneNumber || ''}</label>
                </div>
            </div>
        </div>
    </>;
    const generateModalBody = () => {
        const contentList = [];
        companyExistData.forEach((item) => {
            const element = roHQAddressHtml(item, false);
            contentList.push(element);
        });

        return <>
            <GuidanceBanner
                id='guidanceBannerJoinAccount'
                type='small'
                bannerText1={<div><p className='body'>Your details match an account already registered in Open. You can choose to join it or get in touch via the <a href='' onClick ={(e) => { e.preventDefault(); history.push('/contact'); }}> Contact Us </a> form if you don&apos;t think this is right!</p></div>}
                buttonArr={[]}
            />
            <div className='formInputContainer'>
                <label className='form-label title' id='regionalOffices-checkBox-label'>Select a Headquarter</label>
                {contentList}
            </div>
        </>;
    };

    return <>
        <ProgressBar options={constants.progressBar}
            activeStep={1}
            id='acc-reg-progress'
            context='LeftToRight' />
        <section className='nepo-container registration-form'>
            <div className='wrapper' >
                <Form id='company-details-form' onSubmit={(e) => handleSubmit(e)}>
                    <CompanyDetailsForm
                        formValues={formState}
                        prev={prev}
                        regionalOfficeFormData={roFormState}
                        showRO={showRO}
                        showROForm={showROForm}
                        selectedHQROData= {selectedHQROData}
                    />
                </Form>
                { openModalToShowHQ && <Modal
                    open={true}
                    closeModal={closeModal}
                    size='large'
                    headerTitle='Are you trying to join one of these companies?'
                    handleMainActionBtnClick={() => onHQSelect()}
                    mainActionButtonTxt='Next'
                    closeButton={false}
                    body={generateModalBody()}
                    helpOption={false}
                    secondActionButton={true}
                    secondActionButtonTxt='No'
                    handleSecondaryActionBtnClick={(e) => { e.preventDefault(); noToJoin(); }}
                />}
            </div>
        </section>
    </>;
};
export default CompanyDetails;
