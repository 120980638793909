import draftToHtml from 'draftjs-to-html';
import {
    convertToRaw,
} from 'draft-js';

const getNumOfCharacters = (state) => state.getCurrentContent()
    .getPlainText().replace(/(\r\n|\n|\r)/gm, '').length;

const getHtmlContent = (state) => draftToHtml(convertToRaw(state.getCurrentContent()));

const contentExcededLimits = (currentEditorState,
    editorState, maxCharCount, showCharCount) => getHtmlContent(editorState)
    !== getHtmlContent(currentEditorState)
        && getNumOfCharacters(editorState) < getNumOfCharacters(currentEditorState)
        && (showCharCount && getNumOfCharacters(currentEditorState) > maxCharCount);

const getSelectionNumberOfChars = (currentEditorState) => (currentEditorState
    .getSelection().getEndOffset()
            - currentEditorState.getSelection().getStartOffset());

const getRemainingText = (inputLength,
    currentEditorState, maxCharCount) => maxCharCount - inputLength
            + getSelectionNumberOfChars(currentEditorState);


export {
    getNumOfCharacters,
    getHtmlContent,
    contentExcededLimits,
    getRemainingText,
    getSelectionNumberOfChars,
};
