import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import './styles.scss';
import { v4 as uuidv4 } from 'uuid';
import ViewerOptions from './ViewerOptions';
import StructureViewer from './StructureViewer';

const Viewer = ({
    previousVersion,
    currentVersion,
    structure,
    withControlViewerOptions = true,
    handleHideViewer,
    mode = DiffMethod.LINES,
    withViewerMode = true,
    leftTitle,
    rightTitle,
    showDiffOnly = false,
    splitView = true,
    hideLineNumbers = true,
}) => {
    const [viewerMode, setViewerMode] = useState(mode);

    const changeViewerMode = (vMode) => {
        setViewerMode(vMode);
    };

    return (
        <>
            { withControlViewerOptions
        && <ViewerOptions
            viewerMode= {viewerMode}
            withViewerMode = {withViewerMode}
            handleChangeViewerMode={changeViewerMode}
            handleHideViewer={handleHideViewer}
        />
            }

            { structure
        && <div className='diff-container'>
            <StructureViewer
                key={`structure-viewer-${uuidv4()}`}
                data={structure}
                viewerMode={viewerMode}
                showDiffOnly = {showDiffOnly}
                splitView={splitView}
                hideLineNumbers = {hideLineNumbers} />
        </div>
            }
            { previousVersion && currentVersion
            && <ReactDiffViewer
                key = {`react-diff-viewer-${uuidv4()}`}
                oldValue={previousVersion}
                newValue={currentVersion}
                leftTitle = {leftTitle}
                rightTitle= {rightTitle}
                compareMethod={viewerMode}
                showDiffOnly = {showDiffOnly}
                splitView={splitView}
                hideLineNumbers = {hideLineNumbers} />
            }
        </>
    );
};

Viewer.propTypes = {
    previousVersion: PropTypes.string,
    currentVersion: PropTypes.string,
    structure: PropTypes.object,
    handleHideViewer: PropTypes.func,
    withViewerMode: PropTypes.bool,
    mode: PropTypes.string,
    leftTitle: PropTypes.string,
    rightTitle: PropTypes.string,
    withControlViewerOptions: PropTypes.bool,
    showDiffOnly: PropTypes.bool,
    splitView: PropTypes.bool,
    hideLineNumbers: PropTypes.bool,
};

export default Viewer;
