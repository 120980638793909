import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import BrowseData from '../../features/BrowseData';
import Modal from '../../components/Modal';
import documentManagementAPIs from '../../services/document-management.service';
import Constants from './constants';
import LabelledRadioButton from '../../components/LabelledRadioButton';
import LabelledCheckBox from '../../components/LabelledCheckBox';
import Form from '../../components/Form';
import Button from '../../components/Button';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import AuthContext from '../../context/AuthContext';
import rbac from '../../rbac';

const BrowseQuestionnaires = () => {
    const [questionnaires, setQuestionnaires] = useState([]);
    const [showQuesTypeModal, setShowQuesTypeModal] = useState(false);
    const [createQuestionnaireType, setCreateQuestionnaireType] = useState('');
    const [projectInfo, setProjectInfo] = useState({});
    const [eventDetails, setEventDetails] = useState({});
    const [lots, setLots] = useState([]);
    const [lotSelected, setLotSelected] = useState([]);
    const [assignedTemplates, setAssignedTemplates] = useState([]);
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [lotTemplate, setLotTemplate] = useState({});
    const [showLotModal, setShowLotModal] = useState(false);
    const { type, projectID, eventID } = useParams();
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const questionnaireTypes = params.get('type');
    const [searchSortFilter, setSearchSortFilter] = useState({});

    const filterTemplates = (data) => {
        const filteredAssigned = data.filter(
            (template1) => !assignedTemplates.some(
                (template2) => template1.templateID === template2.templateID,
            ),
        );
        const filteredSelected = filteredAssigned.filter(
            (template1) => !selectedTemplates.some(
                (template2) => template1.templateID === template2.templateID,
            ),
        );
        setFilteredTemplates(filteredSelected);
    };

    const fetchAssignedTemplates = async (pID, eID) => {
        const response = await projectManagementAPIs.getAssignedQuestionnaire(pID, eID);
        if (response.status === 200) {
            setAssignedTemplates(response.data.responseSummary);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve assigned questionnaires.',
            });
        }
    };

    const fetchProjectData = async (id) => {
        const response = await projectManagementAPIs.getProject(id);
        let publishEvent = {};
        response?.data?.workflow?.stages?.forEach((stage) => {
            stage?.events?.forEach((evnt) => {
                if (evnt.eventID === eventID) {
                    publishEvent = {
                        ...evnt,
                    };
                }
            });
        });
        setEventDetails(publishEvent);
        setProjectInfo(response.data);
        setLots(response?.data?.lots || []);
    };

    const fetchData = async (tabSelected) => {
        try {
            const response = await documentManagementAPIs.listTemplates(tabSelected);
            if (response.status === 200) {
                setQuestionnaires(response.data.items);
                filterTemplates(response.data.items);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        if (projectID) {
            if (questionnaireTypes) {
                setSearchSortFilter({
                    filter: {
                        templateType: questionnaireTypes.split(','),
                    },
                });
                fetchData('manage');
            } else {
                fetchData('manage');
            }
            fetchAssignedTemplates(projectID, eventID);
            fetchProjectData(projectID);
        } else {
            fetchData('createdBy');
        }
    }, [eventID, projectID, type]);

    useEffect(() => {
        if (questionnaires) {
            filterTemplates(questionnaires);
        }
    }, [questionnaires, selectedTemplates, assignedTemplates]);

    const assignQuestionnaireToProject = async () => {
        // Tweak fetching of data so we don't need to create payload.
        const payload = {};
        const templates = [];
        selectedTemplates.forEach((template) => {
            const templateObject = {
                questionnaireId: template.templateID,
                questionnaireName: template.templateName,
            };
            if (template.lots) {
                const lotArray = [];
                template.lots.forEach((lot) => {
                    const lotObject = {
                        lotID: lot.lotID,
                        lotTitle: lot.title,
                        lotOrderNo: lot.lotOrderNo,
                    };
                    lotArray.push(lotObject);
                });
                templateObject.lots = lotArray;
            } else {
                templateObject.lots = [];
            }
            templates.push(templateObject);
            payload.assignedQuestionnaires = templates;
        });
        const response = await projectManagementAPIs
            .assignQuestionnaire(projectID, eventID, payload);
        if (response.status === 201) {
            Toast.fire({
                icon: 'success',
                titleText: 'Questionnaire selected successfully.',
            });
            history.goBack();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to assign questionnaire to event.',
            });
        }
    };

    const toggleQuesTypeModal = () => {
        setShowQuesTypeModal(!showQuesTypeModal);
    };

    const getQuestionnaireByTab = (selectedTab) => {
        // Required to reset workflows for infinite scroll to work with tabs.
        setQuestionnaires([]);
        switch (selectedTab) {
            case 'My Questionnaires':
                fetchData('createdBy');
                break;
            case 'My Organisation':
                fetchData('');
                break;
            case 'My Department':
                fetchData('department');
                break;
            case 'All Organisations':
                fetchData('nepo');
                break;
            default:
                break;
        }
    };

    const selectQuestionnaire = (data) => {
        const templateArray = selectedTemplates.filter(
            (template) => template.templateID !== data.templateID,
        );
        templateArray.push(data);
        setSelectedTemplates(templateArray);
    };

    const removeQuestionnaire = (data) => {
        setSelectedTemplates(
            selectedTemplates.filter((template) => template.templateID !== data.templateID),
        );
    };

    const handleSelect = (data) => {
        if (projectInfo.lots
            && projectInfo.lots.length > 0
            && !eventDetails?.publishProject) {
            setLotTemplate(data);
            setShowLotModal(true);
        } else {
            const templateObject = {
                templateID: data.templateID,
                templateName: data.templateName,
            };
            selectQuestionnaire(templateObject);
        }
    };

    const setAllLots = (state) => {
        const lotReset = [];
        lots.forEach((lot) => {
            const preparedLot = {
                ...lot,
                selected: state,
            };
            lotReset.push(preparedLot);
        });
        return lotReset;
    };

    const handleLot = (data) => {
        const templateObject = {
            templateID: data.templateID,
            templateName: data.templateName,
            lots: lotSelected,
        };
        selectQuestionnaire(templateObject);
        setLotSelected([]);
        setLotTemplate();
        setLots(setAllLots(false));
        setShowLotModal(false);
    };

    const handleEditModal = (data) => {
        const lotReset = [];
        const selectedArray = [];

        lots.forEach((lot) => {
            if (data.lots.filter((dataLot) => dataLot.lotID === lot.lotID).length > 0) {
                const preparedLot = {
                    ...lot,
                    selected: true,
                };
                selectedArray.push(lot);
                lotReset.push(preparedLot);
            } else {
                const preparedLot = {
                    ...lot,
                };
                lotReset.push(preparedLot);
            }
        });
        setLotTemplate(data);
        setLots(lotReset);
        setLotSelected(selectedArray);
        setShowLotModal(true);
    };

    const handleCancelModal = () => {
        setLots(setAllLots(false));
        setShowLotModal(false);
    };

    const onCheckBoxChange = (e, item) => {
        const lotArray = lotSelected || [];
        const newLot = {
            lotID: item.lotID,
            title: item.title,
            lotOrderNo: item.lotOrderNo,
        };
        let updatedLots;
        if (lotSelected && lotSelected.filter((lot) => lot.lotID === item.lotID).length > 0) {
            updatedLots = lotSelected.filter((lot) => lot.lotID !== item.lotID);
            setLotSelected(updatedLots);
        } else {
            lotArray.push(newLot);
            setLotSelected(lotArray);
        }

        setLots(lots.map((el) => {
            let preparedEl = {};
            if (`checkbox-${el.lotID}-checkBox-btn` === e.target.id) {
                preparedEl = {
                    ...el,
                    selected: !el.selected,
                };
            } else {
                preparedEl = {
                    ...el,
                };
            }
            return preparedEl;
        }));
    };

    const onAllLotsChange = () => {
        if (lotSelected.length === lots.length) {
            setLotSelected([]);
            setLots(setAllLots(false));
        } else {
            setLotSelected(lots);
            setLots(setAllLots(true));
        }
    };

    const getSelectionQuestionnaires = async () => {
        const response = await documentManagementAPIs.getDefaultTemplate();
        if (response.status === 200) {
            handleSelect(response.data[0]);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve default questionnaire.',
            });
        }
    };

    const generateModalBody = () => {
        const allLots = <div key={'0'} className='bqLotModal'><LabelledCheckBox
            id={'checkbox-all'}
            renderAsRow={false}
            breakColumn={false}
            options={[{
                id: 'checkbox-all',
                value: 'all',
                label: 'Select All',
                checked: lotSelected.length === lots.length || false,
            }]}
            onChange={() => onAllLotsChange()}
            required={false}
        /></div>;
        const lotBody = lots?.sort((a, b) => a.lotOrderNo - b.lotOrderNo)?.map((item, idx) => <div key={idx} className='bqLotModal'>
            <LabelledCheckBox
                id={`checkbox-${idx}`}
                renderAsRow={false}
                breakColumn={false}
                options={[{
                    id: `checkbox-${item.lotID}`,
                    value: item.lotID,
                    label: `Lot ${item.lotOrderNo}: ${item.title}`,
                    checked: item.selected || false,
                }]}
                onChange={(e) => onCheckBoxChange(e, item)}
                required={false}
            />
        </div>);
        const result = [allLots, ...lotBody];
        return result;
    };

    return <>
        {projectID && eventID
            && <><div className={'dashboardCard large'} id={'selectQuestionnaires'}>
                <div className={'selectQuestionnaireContainer'}>
                    <div className={'sqSummaryContainer'}>
                        <p className='title-large'>{'Selection Summary'}</p>
                        <div className={'sqButtonContainer'}>
                            {/* Do we have a questionnaire id for a new one? */}
                            <Button
                                id={'select-Btn-back'}
                                variant= 'secondary'
                                label={'Back'}
                                handleClick={() => history.goBack() } />
                            <Button
                                id={'select-Btn'}
                                additionalVariant = {'primaryToolbarBtn'}
                                variant= 'primary'
                                label={'Confirm'}
                                handleClick={() => assignQuestionnaireToProject() } />
                        </div>
                    </div>
                    <div className='sqSelectedContainer'>
                        {selectedTemplates && selectedTemplates.length === 0
                        && <p className='body'>Questionnaires to be imported will appear here.</p>}
                        {selectedTemplates && selectedTemplates.map(
                            (template) => <div className={'sqSelectedRow'} key={template.templateID}>
                                <div className='sqSelectedHeader'>
                                    <p className='body'>{template.templateName}</p>
                                    <div className={'sqButtonContainer'}>
                                        {projectInfo.lots
                                        && projectInfo.lots.length > 0
                                        && !eventDetails?.publishProject
                                        && <Button
                                            id={'select-Btn-edit'}
                                            variant= 'secondary'
                                            size='small'
                                            label={'Edit Lots'}
                                            handleClick={() => handleEditModal(template) } />}
                                        <Button
                                            id={'select-Btn-remove'}
                                            additionalVariant = {'red'}
                                            variant= 'primary'
                                            size='small'
                                            label={'Remove'}
                                            handleClick={() => removeQuestionnaire(template) } />
                                    </div>
                                </div>
                                {template.lots && <div className='sqSelectedLots'>
                                    <p className='title'>Lots:&nbsp;</p>{template.lots.map(
                                        (lot, idx) => <p className='body' key={idx}>{`${lot.title}`}{idx + 1 < template.lots.length && ', '}&nbsp;</p>,
                                    )}
                                </div>}
                            </div>,
                        )}
                    </div>
                </div>
            </div>
            { eventDetails?.questionnairesType?.includes('selection')
            && <div className={'sqSelectionPanel'}>
                <Button
                    id={'select-Btn-selection-questionnaire'}
                    variant= 'primary'
                    // size='small'
                    label={'Add Selection Template'}
                    handleClick={() => getSelectionQuestionnaires() } />
            </div>}
            </>
        }
        <BrowseData
            id='browseQuestionnaires'
            context={projectID && eventID ? Constants.selectQuestionnaires
                : Constants.questionnaires}
            contextSingular='Questionnaire'
            dataSet={filteredTemplates}
            searchPlaceholder='Search for a questionnaire'
            action={projectID && eventID ? { cb: handleSelect } : null}
            searchFilterAttribute='name'
            sortOptions={Constants.sortOptions}
            tabOptions={projectID && eventID ? null : Constants.tabOptions}
            handleTabSelected={getQuestionnaireByTab}
            allowAddNew={!projectID && !eventID && rbac.can(rbac.action.addQuestionnaire,
                [authContext.user.accountType?.toLowerCase() + authContext.user.role])}
            labelAddNew='Add'
            handleAddNew={() => toggleQuesTypeModal()}
            searchSortFilter={searchSortFilter}
        />

        {showQuesTypeModal
        && <Form id='questionnaireTypeForm' className='questionnaireTypeForm' onSubmit={() => {
            let routeEndpoint = `/questionnaires/draft/${createQuestionnaireType}`;
            if (projectID && projectID !== '_' && eventID && eventID !== '_') {
                routeEndpoint = `${routeEndpoint}/${projectID}/${eventID}`;
            }
            history.push(routeEndpoint);
        }}>
            <Modal
                open={true}
                closeModal={toggleQuesTypeModal}
                size={'small'}
                headerTitle={'Select Questionnaire Type'}
                secondActionButton={true}
                secondActionButtonTxt='Cancel'
                handleSecondaryActionBtnClick={toggleQuesTypeModal}
                handleMainActionBtnClick={() => null}
                mainActionButtonTxt='Continue'
                closeButton={true}
                body={
                    <LabelledRadioButton
                        id='createQuestionnaireTypeSelection'
                        label='Please select one of the following options'
                        breakColumn={false}
                        onChange={(event) => setCreateQuestionnaireType(event.target.value)}
                        options={[{
                            label: 'Selection Questionnaire',
                            value: 'selection',
                            id: 'questionnaireTypeSelection',
                            name: 'createQuestionnaireType',
                            checked: createQuestionnaireType === 'selection' || false,
                            required: true,
                        }, {
                            label: 'Tender Questionnaire',
                            value: 'tender',
                            id: 'questionnaireTypeTender',
                            name: 'createQuestionnaireType',
                            checked: createQuestionnaireType === 'tender' || false,
                            required: true,
                        }, {
                            label: 'Award Questionnaire',
                            value: 'award',
                            id: 'questionnaireTypeAward',
                            name: 'createQuestionnaireType',
                            checked: createQuestionnaireType === 'award' || false,
                            required: true,
                        },
                        {
                            label: 'Market Consultation Questionnaire',
                            value: 'marketConsultation',
                            id: 'questionnaireTypeMarket',
                            name: 'createQuestionnaireType',
                            checked: createQuestionnaireType === 'marketConsultation' || false,
                            required: true,
                        },
                        {
                            label: 'Additional Questionnaire',
                            value: 'additional',
                            id: 'questionnaireTypeAdditional',
                            name: 'createQuestionnaireType',
                            checked: createQuestionnaireType === 'additional' || false,
                            required: true,
                        }]}
                        renderAsRow={false} />
                }
                helpOption={false}
            />
        </Form>}
        {showLotModal && <Modal
            open={true}
            closeModal={() => handleCancelModal()}
            size={'medium'}
            headerTitle={'Select Lot(s)'}
            secondActionButton={true}
            secondActionButtonTxt='Cancel'
            handleSecondaryActionBtnClick={() => handleCancelModal()}
            handleMainActionBtnClick={() => handleLot(lotTemplate)}
            mainActionButtonTxt='Continue'
            closeButton={true}
            body={generateModalBody()}
        />}
    </>;
};

export default BrowseQuestionnaires;
