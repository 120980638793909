const Constants = {
    sortOptions: [
        null,
        { label: 'First name (ascending)', value: 'firstname-ascending' },
        { label: 'First name (descending)', value: 'firstname-descending' },
        { label: 'Last name (ascending)', value: 'surname-ascending' },
        { label: 'Last name (descending)', value: 'surname-descending' },
    ],
    firstName: 'First Name',
    enterFirstName: 'Enter first name',
    surname: 'Last Name',
    enterLastName: 'Enter last name',
    emailAddress: 'Email Address',
    enterEmailAddress: 'Enter Users Email Address',
    countryCode: 'Country Code',
    selectCountryCode: 'Select Country Code',
    phoneNumber: 'Phone Number',
    enterPhoneNumber: 'Enter Users Phone Number',
    jobTitle: 'Job Title',
    enterJobTitle: 'Enter Job Title',
    department: 'Department',
    selectDepartment: 'Select Department',
    specialAccountPrivileges: 'Special Account Privileges',
    selectPrivileges: 'Select Privileges',
    resetPassword: 'Reset Password',
    resetPasswordSuccess: 'A recovery email was sent successfully.',
    resetPasswordError: 'Unable to send recovery email.',
    resetPasswordDisabledUserError: 'Unable to send recovery email. The user is disabled.',
};

export default Constants;
