import React from 'react';
import PropTypes from 'prop-types';

const ProgressBarItem = ({
    mode, icon, title,
}) => <><div className='progress-bar-item'>
    <div className={`before ${mode}`}></div>
    <div className={`progress-circle ${mode}`}>
        {mode === 'past' && <div className={`progress-inner-circle ${mode}`}>
            <div className='active-box'>
                <img className='check' alt='progress-bar' src={icon} />
            </div>
        </div>}
        {mode === 'current' && <div className={`progress-inner-circle ${mode}`}></div>}
    </div>
    <div className={`after ${mode}`}></div>
</div><p className='title title-xSmall'>{title}</p></>;

ProgressBarItem.propTypes = {
    mode: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default ProgressBarItem;
