import React, {
// useContext
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import { questionnaireTypes, intentStatuses } from '../../../config/constants';
import helperFunctions from '../../../utils/helperFunctions';

const SectionContent = ({
    data,
    accountID,
    showButton = false,
    handleUpdateIntentStatus,
    isLotLevel = false,
    readOnly,
}) => {
    const history = useHistory();

    const sectionLength = (data.publishLevel === 'project' ? data?.responses?.questionnaires?.length
        : data?.questionnaires?.length) - 1;

    const generateButtons = (response, idx) => <Button
        id={`view-Btn-${idx}`}
        label={'View'}
        additionalVariant={'small'}
        handleClick={() => history.push(`/questionnaires/evaluate/view/${response.responseID}`) }
    />;

    const getPrettyQuestionnaireType = (qType) => questionnaireTypes.filter(
        (eventType) => eventType.value === qType,
    )[0]?.label;

    return (<>
        {data.publishLevel === 'project'
            ? data?.responses?.questionnaires?.map(
                (item, idx) => (<div className='sbAccordionSectionContentRow' key={idx}>
                    <div className= {`sbAccordionSectionContentData ${isLotLevel && 'lotLevelAdditionalPadding'}`} >
                        <p className='label title'>{item.responseName}</p>
                        <p className='label body'>{getPrettyQuestionnaireType(item.responseType)}</p>
                    </div>
                    <div className='sbAccordionSectionContentAddon'>
                        {item?.weightage !== undefined
                       && <p className='label body'> Score: {`${item.score}/${item.weightage}`}</p>}
                        {item?.passFailResult
                    && <p className='label body'>{`Pass/ Fail Result: ${helperFunctions.camel2title(item.passFailResult)}`}</p>}
                        {showButton && generateButtons(item, idx)}
                    </div>
                    {sectionLength > idx && <div className='splitter' />}
                </div>),
            )
            : data?.questionnaires?.map(
                (item, idx) => (<div className='sbAccordionSectionContentRow' key={idx}>
                    <div className= {`sbAccordionSectionContentData ${isLotLevel && 'lotLevelAdditionalPadding'}`} >
                        <p className='label title'>{item.responseName}</p>
                        <p className='label body'>{getPrettyQuestionnaireType(item.responseType)}</p>
                    </div>
                    <div className='sbAccordionSectionContentAddon'>
                        {item?.weightage !== undefined
                       && <p className='label body'> Score: {`${item.score}/${item.weightage}`}</p>}
                        {item?.passFailResult
                    && <p className='label body'>{`Pass/ Fail Result: ${helperFunctions.camel2title(item.passFailResult)}`}</p>}
                        {showButton && generateButtons(item, idx)}
                    </div>
                    {sectionLength > idx && <div className='splitter' />}
                </div>),
            )
        }
        {(data.intentStatus === undefined || data.intentStatus === intentStatuses.pending)
        && !readOnly
        && <div className='intentStatusButtonContainer'>
            <Button
                id='unsuccesfulBtn'
                size={'small'}
                label={'Unsuccessful'}
                variant='secondary'
                handleClick={() => handleUpdateIntentStatus(
                    intentStatuses.unsuccessful,
                    accountID,
                    data?.lotID,
                )}
            />
            <Button
                id='succesfulBtn'
                size={'small'}
                label={'Successful'}
                handleClick={() => handleUpdateIntentStatus(
                    intentStatuses.successful,
                    accountID,
                    data?.lotID,
                )}
            />
        </div>}
    </>);
};

SectionContent.propTypes = {
    data: PropTypes.object.isRequired,
    accountID: PropTypes.string.isRequired,
    showButton: PropTypes.bool,
    handleUpdateIntentStatus: PropTypes.func,
    isLotLevel: PropTypes.bool,
    readOnly: PropTypes.bool,
};

export default SectionContent;
