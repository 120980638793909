import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import DashboardCard from '../../../../components/DashboardCard';
import helperFunctions from '../../../../utils/helperFunctions';
import { rigour } from '../../../../config/constants';
import editIcon from '../../../../styles/images/edit.png';
import rbac from '../../../../rbac';
import AuthContext from '../../../../context/AuthContext';
import StatusLabel from '../../../../components/StatusLabel';
import UserRoles from '../../../../features/Header/constants';

const ViewContracts = ({ data }) => {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const accountType = authContext.user?.accountType?.toLowerCase();

    const generateContractDetailsChildren = (details) => <>
        <div id='contractDetailsContainer'>
            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>Description:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{details.description}</p>
                </div>
            </div>

            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>Award value:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{details.awardValue ? helperFunctions.formatCurrency(details.awardValue) : '-'}</p>
                </div>
            </div>

            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>Annual value:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{details.annualValue ? helperFunctions.formatCurrency(details.annualValue) : '-'}</p>
                </div>
            </div>

            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>Contract start date:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{details.startDate ? helperFunctions.formatPrettyDateTime(details.startDate) : '-'}</p>
                </div>
            </div>

            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>Contract end date:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{details.endDate ? helperFunctions.formatPrettyDateTime(details.endDate) : '-'}</p>
                </div>
            </div>

            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>Contract Award date:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{details.awardDate || '-'}</p>
                </div>
            </div>

            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>Contract review date:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{details.reviewDate ? details.reviewDate : '-'}</p>
                </div>
            </div>

            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>Total Extensions:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{Array.isArray(details?.extensionsAvailable) ? details.extensionsAvailable?.length : '-'}</p>
                </div>
            </div>

            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>Extension(s) Available:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{Array.isArray(details?.extensionsAvailable) ? (details.extensionsAvailable.filter((ext) => ext.extensionStatus === 'active'))?.length : '-'}</p>
                </div>
            </div>

            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>VAT that cannot be recovered:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{details.unrecoverableVAT ? helperFunctions.formatCurrency(details.unrecoverableVAT) : '-'}</p>
                </div>
            </div>

            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>Rebate percentage:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{details.rebatePercentage || '-'}</p>
                </div>
            </div>
            {Array.isArray(details.extensionsAvailable)
                ? details.extensionsAvailable.map((extension, index) => <div
                    key={`extension-contract-${index}`} className='contractDetailsItem'>
                    <div className='leftColumn'>
                        <p className='title'>Extension Duration {index + 1}:</p>
                    </div>
                    <div className='rightColumn'>
                        <p className='caption'>{extension.monthsAvailable}
                            {extension.extensionStatus === 'inactive' ? <span> (used)</span> : ''}</p>
                    </div>
                </div>)
                : <></>}

            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>Contract Manager:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{details?.contractManager ? details?.contractManager?.user?.contactDetails?.username : '-'}</p>
                </div>
            </div>

            <div className='contractDetailsItem'>
                <div className='leftColumn'>
                    <p className='title'>Responsible User:</p>
                </div>
                <div className='rightColumn'>
                    <p className='caption'>{details?.responsibleOfficer ? details?.responsibleOfficer?.user?.contactDetails?.username : '-'}</p>
                </div>
            </div>
        </div>
    </>;

    const getPrettyRigourType = (eType) => rigour.filter(
        (rigourType) => rigourType.value === eType,
    )[0]?.label;

    return <DashboardCard
        id='mainContent'
        size='large'
        header={true}
        headerTitle={data.title}
        caption={`Contract # ${data.contractReference ? data.contractReference : '-'}`}
        actionBtn={
            data.contractStatus && data.contractStatus !== 'cancelled'
            && rbac.can(
                rbac.action.editContract,
                rbac.util.getSteeringGroupRole(authContext.user.id, data?.steeringInfo),
            )}
        actionBtnText={'Edit Contract Details'}
        variant={'secondary'}
        additionalVariant={'skinless'}
        handlerActionBtn={() => history.push(`/contract/edit/${data.contractID}`)}
        headerStatus={true}
        headerStatusContent={
            <div className='contractStatusPills'>
                {data.contractStatus && data.contractStatus === 'cancelled'
                && <StatusLabel
                    id={'contractCancelled'}
                    color={'red'}
                    labelTxt={'Cancelled'}
                />}
                {accountType !== UserRoles.SUPPLIER_ROLE && <div className={`statusText ${data.rigour?.toLowerCase()}`}>
                    <p className='title'>{getPrettyRigourType(data.rigour ? data.rigour.toLowerCase() : '')}</p>
                </div>}
            </div>
        }
        content={generateContractDetailsChildren(data)}
        footer={false}
        icon={true}
        iconSrc={editIcon}
    />;
};

ViewContracts.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ViewContracts;
