import constants from './constants';

const initialState = {
    documentBuilderState: {
    },
};

const WorkflowsReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SET_BUILDERSTATE: {
            return {
                ...state,
                documentBuilderState: action.payload,
            };
        }
        default:
            return state;
    }
};

export default WorkflowsReducer;
