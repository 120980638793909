/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

const VersionHistoryModal = ({
    versions,
    responseversions,
    closeModal,
    accountType,
}) => {
    const history = useHistory();
    const generatePrettyDate = (date) => {
        try {
            const parsedISO = parseISO(date);
            if (parsedISO !== 'Invalid Date') {
                return `${format(parsedISO, 'dd-MM-yyyy HH:mm')}`;
            }
        } catch (e) {
            return date;
        }
        return date;
    };

    const generateBody = () => {
        const data = accountType === 'supplier' ? responseversions : versions;
        return <>
            <div className='changeHistoryModal'>
                {data?.map((el, index) => <div key={index} className='changeHistoryRow'>
                    <div className='changeHistoryCol'>
                        <p className='caption'>Version: {index + 1}</p>
                        <p className='caption'>{generatePrettyDate(el.createdAt)}</p>
                    </div>
                    <div className='changeHistoryCol view-btn'>
                        <Button
                            id='secondaryToolbarBtn'
                            variant='secondary'
                            additionalVariant='skinless'
                            label={'View Questionnaire'}
                            handleClick={() => {
                                closeModal();
                                if (accountType === 'supplier') {
                                    history.push(`/questionnaires/answer/view/${el.versionID}`, {
                                        deadlinePassed: true,
                                    });
                                } else {
                                    history.push(`/questionnaires/view/_/${el.versionID}`);
                                }
                            }}
                        />
                    </div>
                </div>).reverse()}
            </div>
        </>;
    };

    return <Modal
        open={true}
        size='medium'
        closeModal={closeModal}
        mainActionButtonTxt='Close'
        handleMainActionBtnClick={closeModal}
        headerTitle={accountType === 'supplier' ? 'Refresh History' : 'Change History'}
        closeButton={true}
        body={generateBody()}
        helpOption={false}
    />;
};

VersionHistoryModal.propTypes = {
    versions: PropTypes.object.isRequired,
    responseversions: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    accountType: PropTypes.string.isRequired,
};

export default VersionHistoryModal;
