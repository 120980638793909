import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../components/Tag';

const SelectionPills = ({
    whenEmptyShow, data, removePillItemAction, context,
}) => <>
    <section className='registration-section-pills'>
        {data.length === 0 ? <p className='caption'>{whenEmptyShow}</p> : null}
        <div className='pills-container'>
            { context !== 'supplyRegions'
                ? data.map((pill, idx) => <Tag key={`reg-pill-${idx}`} id={`reg-pill-${idx}`} size='large with-margins' handleDeleteBtnClick={() => removePillItemAction(pill.level)}
                    tagTxt={`${pill.category} - ${pill.level}`} isDeletable={true} />)
                : data.map((pill, idx) => <Tag key={`reg-pill-${idx}`} id={`reg-pill-${idx}`} size='large with-margins' handleDeleteBtnClick={() => removePillItemAction(pill.description)}
                    tagTxt={`${pill.code} - ${pill.description}`} isDeletable={true} />)
            }
        </div>
    </section>
</>;

SelectionPills.propTypes = {
    whenEmptyShow: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    removePillItemAction: PropTypes.func.isRequired,
    context: PropTypes.string,
};

export default SelectionPills;
