/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { set } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
    InputComponent,
    RadioButtonComponent,
    ErrorMessagesFor,
} from './components';
import FTSNameAndAddresses from './FTSNameAndAddresses';
import FTSContracts from './FTSContracts';
import LabelledTextarea from '../../components/LabelledTextArea';
import LabelledCheckBox from '../../components/LabelledCheckBox';
import Button from '../../components/Button';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import FTSAwardContracts from './FTSAwardContracts';
import Confirm from '../../components/Alerts/Confirm/Confirm';

const FTSF02NoticeForm = ({
    project,
    noticeEvent,
}) => {
    const [formFields, setFormFields] = useState({});
    const [errorMessages, setErrorMessages] = useState([]);
    const history = useHistory();

    const handleErrors = (errors) => {
        setErrorMessages(errors?.filter((v, i, a) => a.indexOf(v) === i));
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    const handleFormChange = (e, fieldName) => {
        let name;
        let value;

        if (e?.target) {
            if (e?.target.type === 'checkbox') {
                name = e.target.name;
                value = e.target.checked ? e.target.value : '';
            } else {
                name = e.target.name;
                value = e.target.value;
            }
        } else {
            name = fieldName;
            value = e;
        }
        const nodeTree = name.split('.');

        setFormFields((prevState) => (
            { ...set((prevState || {}), nodeTree.join('.'), value) }
        ));
    };

    const confirmAction = async (e, formData) => {
        const { projectID } = project;
        const { eventID, noticeType } = noticeEvent;
        let responseBody = {};
        setErrorMessages([]);
        e.preventDefault();
        const validationErrorMessages = [];
        let IsValid = true;
        formData.forEach((value, property) => {
            if (value !== '' && !property.includes('notMapped') && property !== 'day'
            && property !== 'month' && property !== 'year' && property !== 'hour24'
            && property !== 'minute' && property !== 'time') {
                if (property === 'procedure.languages' || property.includes('cpvSupplementaryCodes')) {
                    // eslint-disable-next-line no-param-reassign
                    value = value.split(',');
                }
                if (value === 'false') {
                    // eslint-disable-next-line no-param-reassign
                    value = (value === 'true');
                }
                if (value === 'true') {
                    // eslint-disable-next-line no-param-reassign
                    value = (value === 'true');
                }

                if (property.includes('weighting')) {
                    // eslint-disable-next-line no-param-reassign
                    value = Number(value);
                }
                if (property.includes('cpvCode')) {
                    const prefix = parseInt(value.substring(0, 2), 10);
                    const contractType = formFields?.objectContracts ? formFields?.objectContracts[0]?.typeContract?.ctype : '';
                    if (contractType === 'WORKS') {
                        if (prefix !== 45) {
                            validationErrorMessages.push('Main CPV code does not match contract Type. A contract Type of WORKS must have a CPV code that begins with 45.');
                            IsValid = false;
                        }
                    } else if (contractType === 'SUPPLIES') {
                        if (prefix > 44) {
                            if (prefix !== 48) {
                                validationErrorMessages.push('Main CPV code does not match contract Type. A contract Type of SUPPLIES must have a CPV code that begins with 0 to 44, or 48.');
                                IsValid = false;
                            }
                        }
                    } else if (contractType === 'SERVICES') {
                        if (prefix < 49) {
                            validationErrorMessages.push('Main CPV code does not match contract Type. A contract Type of SERVICES must have a CPV code that begins with a number higher than 49.');
                            IsValid = false;
                        }
                    }
                }
                const nodeTree = property.split('.');
                responseBody = ({ ...set((responseBody || {}), nodeTree, value) });
            }
        });
        const payload = {
            type: noticeType,
            formSection: responseBody,
        };

        if (IsValid && projectID && eventID) {
            try {
                const response = await projectManagementAPIs.publishFTSNotice(projectID,
                    eventID, payload);
                if (response.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        titleText: 'Created notice successfully.',
                    });
                    history.push(`/projects/overview/${project.projectID}`);
                } else {
                    handleErrors(response?.data?.Errors?.body || response?.data?.Errors);
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to create notice.',
                });
            }
        } else {
            handleErrors(validationErrorMessages);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        Confirm(async () => {
            await confirmAction(e, formData);
        }, {
            subTitle: 'Are you sure you want to submit?',
        });
    };

    return <>
        <form id='form_03' onSubmit={handleFormSubmit}>
            <div className='vpnDivider'></div>

            <ErrorMessagesFor
                errorMessages={errorMessages}
                origin={'FTS'}
            />
            <h3 className='title-large subHeading'>
            F03 Form: Contract {noticeEvent.noticeType} notice
            </h3>
            <div className='preliminary-questions'>

                <h3 className='title-large subHeading'>
            Preliminary questions
                </h3>
                <RadioButtonComponent
                    id={'objectContracts.0.typeContract.ctype'}
                    label='Contract Type'
                    value={formFields?.objectContracts ? formFields?.objectContracts[0]?.typeContract?.ctype : ''}
                    required={true}
                    onChange={handleFormChange}
                    renderAsRow={false}
                    options={{
                        WORKS: 'Works',
                        SUPPLIES: 'Supplies',
                        SERVICES: 'Services',
                    }}
                />
                <RadioButtonComponent
                    id={'procedure.pt'}
                    label='Procedure Type'
                    value={formFields?.procedure?.pt}
                    required={true}
                    onChange={handleFormChange}
                    renderAsRow={false}
                    options={{
                        OPEN: 'Open procedure',
                        RESTRICTED: 'Restricted procedure',
                        COMPETITIVE_NEGOTIATION: 'Competitive procedure with negotiation',
                        COMPETITIVE_DIALOGUE: 'Competitive dialogue',
                        INNOVATION_PARTNERSHIP: 'Innovation partnership',
                        DIRECT_AWARD: 'Award of a contract without prior publication of a call for competition',
                        PRIOR_PUBLICATION: 'Prior publication may be in the Official Journal of the European Union or the Find a Tender service',
                    }}
                />

                <RadioButtonComponent
                    id='notMapped.isAwarded'
                    label='Has a contract been awarded?'
                    value={formFields?.notMapped?.isAwarded}
                    required={true}
                    onChange={handleFormChange}
                    options={{
                        true: 'Yes',
                        false: 'No',
                    }}
                />
                {formFields?.notMapped?.isAwarded === 'true'
                && <InputComponent
                    id='notMapped.numberOfContracts'
                    label='Number of awarded contracts and/or non-awards'
                    type='number'
                    required={true}
                    value={formFields?.notMapped?.numberOfContracts}
                    onChange={handleFormChange}
                />}

                <LabelledCheckBox
                    id={'notMapped.jointProcurement'}
                    label={'Joint procurement'}
                    breakColumn={true}
                    options={[{
                        id: 'notMapped.jointProcurement',
                        value: true,
                        label: 'The contract involves joint procurement ',
                        checked: formFields?.notMapped?.jointProcurement ? formFields?.notMapped?.jointProcurement === 'true' : false,
                        name: 'notMapped.jointProcurement',
                    }]}
                    onChange={handleFormChange}
                    renderAsRow={true}
                    required={false}
                />

                {formFields?.notMapped?.jointProcurement === 'true'
            && <InputComponent
                id={'notMapped.numberOfContractingAuthorities'}
                label='Number of contracting authorities responsible for the procedure'
                type='number'
                minNumber={2}
                maxNumber={500}
                value={formFields?.notMapped?.numberOfContractingAuthorities}
                onChange={handleFormChange}
                required={true}
            />}
            </div>

            <div className='contracting-authority'>
                <h3 className='title-large subHeading'>
            Section I. Contracting authority
                </h3>
                <h4 className='title-large subHeading'>
                I.1. Name and addresses
                </h4>
                <FTSNameAndAddresses
                    prefix={'contractingBody.addressContractingBody'}
                    handleChange={handleFormChange}
                    formFields={formFields}
                />
                <h4 className='title-large subHeading'>
                I.2. Joint procurement
                </h4>
                {formFields?.notMapped?.jointProcurement === 'true'
                && <LabelledTextarea
                    id='contractingBody.procurementLaw'
                    breakColumn={true}
                    label='In the case of joint procurement involving different countries, state applicable national procurement law'
                    value={formFields?.contractingBody?.procurementLaw || ''}
                    maxCharCount={3000}
                    onChange={(e) => handleFormChange(e)}
                    required={false}
                />}
                <LabelledCheckBox
                    id={'contractingBody.centralPurchasing'}
                    label={'The contract involves joint procurement (as specified in preliminary questions)'}
                    breakColumn={true}
                    options={[{
                        id: 'contractingBody.centralPurchasing',
                        value: true,
                        label: 'The contract is awarded by a central purchasing body',
                        checked: formFields?.contractingBody?.centralPurchasing === 'true',
                        name: 'contractingBody.centralPurchasing',
                    }]}
                    onChange={(e) => handleFormChange(e)}
                    renderAsRow={true}
                    required={false}
                />
                <h3 className='title-large subHeading'>I.4. Type of the contracting authority</h3>
                <RadioButtonComponent
                    id='contractingBody.caType'
                    label=''
                    value={formFields?.contractingBody?.caType}
                    required={true}
                    onChange={handleFormChange}
                    renderAsRow={false}
                    options={{
                        MINISTRY: 'Ministry or any other national or federal authority, including their regional or local subdivisions',
                        NATIONAL_AGENCY: 'National or federal agency/office',
                        REGIONAL_AUTHORITY: 'Regional or local authority',
                        REGIONAL_AGENCY: 'Regional or local agency/office',
                        BODY_PUBLIC: 'Body governed by public law',
                        OTHER: 'Other type',
                    }}
                />
                {formFields?.contractingBody?.caType === 'OTHER'
                && <LabelledTextarea
                    id={'contractingBody.caTypeOther'}
                    label='Please enter details here'
                    value={formFields?.contractingBody?.caTypeOther || ''}
                    type='textarea'
                    required={false}
                    onChange={handleFormChange}
                />}
                <h3 className='title-large subHeading'>I.5. Main activity</h3>
                <RadioButtonComponent
                    id='contractingBody.caActivity'
                    label=''
                    value={formFields?.contractingBody?.caActivity}
                    required={true}
                    onChange={handleFormChange}
                    renderAsRow={false}
                    options={{
                        GENERAL_PUBLIC_SERVICES: 'General public services',
                        DEFENCE: 'Defence',
                        PUBLIC_ORDER_AND_SAFETY: 'Public order and safety',
                        ENVIRONMENT: 'Environment',
                        ECONOMIC_AND_FINANCIAL_AFFAIRS: 'Economic and financial affairs',
                        HEALTH: 'Health',
                        HOUSING_AND_COMMUNITY_AMENITIES: 'Housing and community amenities',
                        SOCIAL_PROTECTION: 'Social protection',
                        RECREATION_CULTURE_AND_RELIGION: 'Recreation, culture and religion',
                        EDUCATION: 'Education',
                        OTHER: 'Other activity',
                    }}
                />
                {formFields?.contractingBody?.caActivity === 'OTHER'
                && <LabelledTextarea
                    id={'contractingBody.caActivityOther'}
                    label='Please enter details here'
                    value={formFields?.contractingBody?.caActivityOther || ''}
                    type='textarea'
                    required={false}
                    onChange={handleFormChange}
                />}

                {formFields?.notMapped?.jointProcurement // eslint-disable-next-line no-undef
                && _.times(formFields?.notMapped?.numberOfContractingAuthorities, (index) => (
                    <><h3 className='title-large subHeading'>
                        Section I.1. Additional contracting authority ({index + 1})
                    </h3>
                    <FTSNameAndAddresses
                        prefix={`contractingBody.addressContractingBodyAdditional.${index}`}
                        handleChange={handleFormChange}
                        formFields={formFields}
                    />
                    </>))}
            </div>

            {// eslint-disable-next-line no-undef
                _.times(formFields?.notMapped?.numberOfAwardedContracts || 1, (index) => (
                    <>
                        <h3 className='title-large subHeading'>Section II. Object ({index + 1})</h3>
                        <FTSContracts
                            prefix={`objectContracts.${index}`}
                            noticeEvent={noticeEvent}
                            handleChange={handleFormChange}
                            formFields={formFields}
                        />
                    </>))
            }

            <div className='procedure'>
                <h3 className='title-large subHeading'>Section IV. Procedure</h3>
                <h4 className='title-large subHeading'>IV.1. Description</h4>
                <h3 className='title-large subHeading'>IV.1.1. Procedure type</h3>
                <h3 className='title-large subHeading'>{formFields?.procedure?.pt}</h3>
                <div className='formElementsDivider'></div>
                {formFields?.procedure?.pt && formFields?.procedure?.pt !== 'INNOVATION_PARTNERSHIP'
                && <>
                    <LabelledCheckBox
                        id={'notMapped.procedure.acceleratedProcCheck'}
                        breakColumn={true}
                        options={[{
                            id: 'notMapped.procedure.acceleratedProcCheck',
                            value: true,
                            label: 'Accelerated procedure',
                            checked: formFields?.notMapped?.procedure?.acceleratedProcCheck === 'true',
                            name: 'notMapped.procedure.acceleratedProcCheck',
                        }]}
                        onChange={(e) => handleFormChange(e)}
                        renderAsRow={true}
                        required={false}
                    />
                    <div className='formElementsDivider'></div>
                    {formFields?.notMapped?.procedure?.acceleratedProcCheck === 'true'
                    && <>
                        <LabelledTextarea
                            id={'procedure.acceleratedProc'}
                            label='Justification'
                            value={formFields?.procedure?.acceleratedProc}
                            type='text'
                            maxCharCount={1000}
                            onChange={handleFormChange}
                            required={true}
                        />
                        <div className='formElementsDivider'></div>
                    </>}
                </>}
                <h4 className='title-large subHeading'>IV.1.3. Information about a framework agreement or a dynamic purchasing system</h4>
                <LabelledCheckBox
                    id='procedure.framework.framework'
                    label=''
                    breakColumn={true}
                    options={[{
                        id: 'procedure.framework.framework',
                        value: true,
                        label: 'The procurement involves the establishment of a framework agreement (Optional)',
                        checked: formFields?.procedure?.framework?.framework ? formFields?.procedure?.framework?.framework === 'true' : false,
                        name: 'procedure.framework.framework',
                    }]}
                    onChange={handleFormChange}
                    renderAsRow={true}
                    required={false}
                />
                {formFields?.procedure?.pt === 'RESTRICTED'
                && <>
                    <div className='formElementsDivider'></div>
                    <LabelledCheckBox
                        id='procedure.dps'
                        label=''
                        breakColumn={true}
                        options={[{
                            id: 'procedure.dps',
                            value: true,
                            label: 'A dynamic purchasing system was set up (Optional)',
                            checked: formFields?.procedure?.dps ? formFields?.procedure?.dps === 'true' : false,
                            name: 'procedure.dps',
                        }]}
                        onChange={handleFormChange}
                        renderAsRow={true}
                        required={false}
                    />
                </>}
                <div className='formElementsDivider'></div>
                <h4 className='title-large subHeading'>IV.1.6. Information about electronic auction</h4>
                <LabelledCheckBox
                    id='procedure.eauction'
                    label=''
                    breakColumn={true}
                    options={[{
                        id: 'procedure.eauction',
                        value: true,
                        label: 'An electronic auction will be used (Optional)',
                        checked: formFields?.procedure?.eauction ? formFields?.procedure?.eauction === 'true' : false,
                        name: 'procedure.eauction',
                    }]}
                    onChange={handleFormChange}
                    renderAsRow={true}
                    required={false}
                />
                {formFields?.procedure?.eauction === 'true'
                && <LabelledTextarea
                    id='procedure.infoAddEauction'
                    breakColumn={true}
                    label='Additional information about electronic auction'
                    value={formFields?.procedure?.infoAddEauction || ''}
                    maxCharCount={1000}
                    onChange={(e) => handleFormChange(e)}
                />}
                <div className='formElementsDivider'></div>
                <h4 className='title-large subHeading'>IV.1.8. Information about the Government Procurement Agreement (GPA)</h4>
                <RadioButtonComponent
                    id='procedure.contractCoveredGPA'
                    label='The procurement is covered by the Government Procurement Agreement'
                    value={formFields?.procedure?.contractCoveredGPA}
                    required={true}
                    onChange={handleFormChange}
                    renderAsRow={false}
                    options={{
                        true: 'Yes',
                        false: 'No',
                    }}
                />
                <div className='formElementsDivider'></div>
                <h4 className='title-large subHeading'>IV.2. Administrative information</h4>
                <h4 className='title-large subHeading'>IV.2.1. Previous publication concerning this procedure</h4>
                <RadioButtonComponent
                    id='notMapped.procedure.previousPublication'
                    label='Previous publication concerning this procedure'
                    value={formFields?.notMapped?.procedure?.previousPublication}
                    required={false}
                    onChange={handleFormChange}
                    renderAsRow={false}
                    options={formFields?.procedure?.pt === 'DIRECT_AWARD' ? {
                        PUBLISHED_ON_TED: 'Published on TED',
                        PUBLISHED_ON_FTS: 'Published on Find a Tender',
                        NONE: 'None of the above',
                    } : {
                        PUBLISHED_ON_TED: 'Published on TED',
                        PUBLISHED_ON_FTS: 'Published on Find a Tender',
                    }}
                />
                {formFields?.notMapped?.procedure?.previousPublication === 'PUBLISHED_ON_TED'
                && <InputComponent
                    id='procedure.noticeNumberOJ'
                    label='Notice number in the OJS'
                    type='text'
                    value={formFields?.procedure?.noticeNumberOJ}
                    required={true}
                    onChange={handleFormChange}
                    pattern={'(19|20)\\d{2}/S \\d{3}-\\d{6}'}
                    title={'Enter the previous publication notice number in the correct format, like 2019/S 012-123456'}
                />}
                {formFields?.notMapped?.procedure?.previousPublication === 'PUBLISHED_ON_FTS'
                && <InputComponent
                    id='procedure.noticeNumberOJ'
                    label='Linked notice'
                    type='text'
                    value={formFields?.procedure?.noticeNumberOJ}
                    required={true}
                    onChange={handleFormChange}
                    pattern={'(19|20)\\d{2}/S \\d{3}-\\d{6}'}
                    title={'Enter the previous publication notice number in the correct format, like 2019/S 012-123456'}
                />}
                <div className='formElementsDivider'></div>
                {formFields?.procedure?.dps === 'true'
                && <>
                    <h4 className='title-large subHeading'>IV.2.8 Information about termination of dynamic purchasing system</h4>
                    <LabelledCheckBox
                        id='procedure.terminationDPS'
                        label=''
                        breakColumn={true}
                        options={[{
                            id: 'procedure.terminationDPS',
                            value: true,
                            label: 'The notice involves the termination of the dynamic purchasing system published by the above contract notice (Optional)',
                            checked: formFields?.procedure?.terminationDPS ? formFields?.procedure?.terminationDPS === 'true' : false,
                            name: 'procedure.terminationDPS',
                        }]}
                        onChange={handleFormChange}
                        renderAsRow={true}
                        required={false}
                    />
                    <div className='formElementsDivider'></div>
                </>}
                <h4 className='title-large subHeading'>IV.2.9 Information about termination of call for competition in the form of a prior information notice</h4>
                <LabelledCheckBox
                    id='procedure.terminationPIN'
                    label=''
                    breakColumn={true}
                    options={[{
                        id: 'procedure.terminationPIN',
                        value: true,
                        label: 'The contracting authority will not award any further contracts based on the above prior information notice (Optional)',
                        checked: formFields?.procedure?.terminationPIN ? formFields?.procedure?.terminationPIN === 'true' : false,
                        name: 'procedure.terminationPIN',
                    }]}
                    onChange={handleFormChange}
                    renderAsRow={true}
                    required={false}
                />
            </div>

            <div className='awardOfContract'>

                {formFields?.notMapped?.isAwarded === 'true'
                // eslint-disable-next-line no-undef
                    ? _.times(formFields?.notMapped?.numberOfContracts || 1, (index) => (
                        <>
                            <h3 className='title-large subHeading'>
                                Section V. Award of contract ({index + 1})
                            </h3>
                            <FTSAwardContracts
                                prefix={`awardContracts.${index}`}
                                noticeEvent={noticeEvent}
                                handleChange={handleFormChange}
                                formFields={formFields}
                            />
                        </>
                    ))
                    : <FTSAwardContracts
                        prefix={'awardContracts.0'}
                        noticeEvent={noticeEvent}
                        handleChange={handleFormChange}
                        formFields={formFields}
                        isAwardedFlag={false}
                    />}
            </div>

            <div className='complimentary-information'>
                <h3 className='title-large subHeading'>
            Section VI. Complementary information
                </h3>

                <LabelledTextarea
                    id='complementaryInfo.infoAdd'
                    label='VI.3. Additional information'
                    type='textarea'
                    value={formFields?.complementaryInfo?.infoAdd}
                    placeholder={'Enter any additional information'}
                    required={false}
                    maxCharCount={10000}
                    onChange={handleFormChange}
                />

                <h4 className='title-large subHeading'>
            VI.4. Procedures for review
                </h4>

                <h5 className='title-large subHeading'>
            VI.4.1. Review body
                </h5>

                <FTSNameAndAddresses
                    prefix={'complementaryInfo.addressReviewBody'}
                    registerFlag={false}
                    nationalIDFlag={false}
                    nutsCodeFlag={false}
                    contactPointFlag={false}
                    buyerAddressFlag={false}
                    handleChange={handleFormChange}
                    formFields={formFields}
                    internetAddressNameUpdateFlag={true}
                />

                <h4 className='title-large subHeading'>
            VI.4.2. Body responsible for mediation procedures
                </h4>

                <RadioButtonComponent
                    id='notMapped.complementaryInfo.mediationProceduresBody'
                    label='Is there a body responsible for mediation procedures?'
                    value={formFields?.notMapped?.complementaryInfo?.mediationProceduresBody}
                    required={false}
                    onChange={handleFormChange}
                    options={{
                        true: 'Yes',
                        false: 'No',
                    }}
                />

                {formFields?.notMapped?.complementaryInfo?.mediationProceduresBody === 'true' && <FTSNameAndAddresses
                    prefix={'complementaryInfo.addressMediationBody'}
                    registerFlag={false}
                    nationalIDFlag={false}
                    nutsCodeFlag={false}
                    contactPointFlag={false}
                    buyerAddressFlag={false}
                    handleChange={handleFormChange}
                    formFields={formFields}
                    internetAddressNameUpdateFlag={true}
                />}

                <h4 className='title-large subHeading'>
            VI.4.3. Review procedure
                </h4>
                <LabelledTextarea
                    id='complementaryInfo.reviewProcedure'
                    label='Precise information on deadline(s) for review procedures'
                    type='text'
                    value={formFields?.complementaryInfo?.reviewProcedure}
                    required={false}
                    onChange={handleFormChange}
                    maxCharCount={4000}
                />
                <h4 className='title-large subHeading'>
            VI.4.4. Service from which information about the review procedure may be obtained
                </h4>

                <RadioButtonComponent
                    id='notMapped.complementaryInfo.reviewProcedureService'
                    label='Is there a service from which information about the review procedure may be obtained?'
                    value={formFields?.notMapped?.complementaryInfo?.reviewProcedureService}
                    required={false}
                    onChange={handleFormChange}
                    options={{
                        true: 'Yes',
                        false: 'No',
                    }}
                />

                {formFields?.notMapped?.complementaryInfo?.reviewProcedureService === 'true' && <FTSNameAndAddresses
                    prefix={'complementaryInfo.addressReviewInfo'}
                    registerFlag={false}
                    nationalIDFlag={false}
                    nutsCodeFlag={false}
                    contactPointFlag={false}
                    buyerAddressFlag={false}
                    handleChange={handleFormChange}
                    formFields={formFields}
                    internetAddressNameUpdateFlag={true}
                />}
            </div>

            <div className='annex'>
                {formFields?.procedure?.pt === 'DIRECT_AWARD'
                && <>
                    <h3 className='title-large subHeading'>Annex D1: General procurement</h3>
                    <RadioButtonComponent
                        id='notMapped.justificationType'
                        label='Justification category'
                        value={formFields?.notMapped?.justificationType}
                        required={true}
                        onChange={handleFormChange}
                        options={{
                            ACCORDANCE_ARTICLE: 'Justification for the choice of the negotiated procedure without prior publication of a call for competition',
                            OUTSIDE_SCOPE: 'Other justification for the award of the contract without prior publication of a call for competition in the Official Journal of the European Union or the Find a Tender service',
                        }}
                        renderAsRow={false}
                    />
                    {formFields?.notMapped?.justificationType === 'ACCORDANCE_ARTICLE'
                    && <>
                        <h4 className='title-large subHeading'>Justification</h4>
                        <LabelledCheckBox
                            id={'notMapped.annexD1justificationCheck1'}
                            label={''}
                            breakColumn={true}
                            options={[{
                                id: 'notMapped.annexD1justificationCheck1',
                                value: true,
                                label: 'No tenders or no suitable tenders/requests to participate in response to (optional)',
                                checked: formFields?.notMapped?.annexD1justificationCheck1 ? formFields?.notMapped?.annexD1justificationCheck1 === 'true' : false,
                                name: 'notMapped.annexD1justificationCheck1',
                            }]}
                            onChange={handleFormChange}
                            renderAsRow={true}
                            required={false}
                        />
                        {formFields?.notMapped?.annexD1justificationCheck1 === 'true'
                        && <RadioButtonComponent
                            id={'procedure.annexD1Part1.dProc'}
                            label='Type of procedure'
                            value={formFields?.procedure?.annexD1Part1?.dProc}
                            required={true}
                            onChange={handleFormChange}
                            renderAsRow={false}
                            options={{
                                OPEN: 'Open procedure',
                                RESTRICTED: 'Restricted procedure',
                            }}
                        />}

                        <LabelledCheckBox
                            id={'notMapped.annexD1justificationCheck2'}
                            label={''}
                            breakColumn={true}
                            options={[{
                                id: 'notMapped.annexD1justificationCheck2',
                                value: true,
                                label: 'The works, supplies or services can be provided only by a particular economic operator for the following reason (optional)',
                                checked: formFields?.notMapped?.annexD1justificationCheck2 ? formFields?.notMapped?.annexD1justificationCheck2 === 'true' : false,
                                name: 'notMapped.annexD1justificationCheck2',
                            }]}
                            onChange={handleFormChange}
                            renderAsRow={true}
                            required={false}
                        />
                        {formFields?.notMapped?.annexD1justificationCheck2 === 'true'
                        && <RadioButtonComponent
                            id={'procedure.annexD1Part1.dType'}
                            label='Reason'
                            value={formFields?.procedure?.annexD1Part1?.dType}
                            required={true}
                            onChange={handleFormChange}
                            renderAsRow={false}
                            options={{
                                TECHNICAL: 'Absence of competition for technical reasons',
                                ARTISTIC: 'Procurement aiming at the creation or acquisition of a unique work of art or artistic performance',
                                PROTECT_RIGHTS: 'Protection of exclusive rights, including intellectual property rights',
                            }}
                        />}

                        <LabelledCheckBox
                            id={'procedure.annexD1Part1.dExtremeUrgency'}
                            label={''}
                            breakColumn={true}
                            options={[{
                                id: 'procedure.annexD1Part1.dExtremeUrgency',
                                value: true,
                                label: 'Extreme urgency brought about by events unforeseeable for the contracting authority and in accordance with the strict conditions stated in the regulation (optional)',
                                checked: formFields?.procedure?.annexD1Part1?.dExtremeUrgency ? formFields?.procedure?.annexD1Part1?.dExtremeUrgency === 'true' : false,
                                name: 'procedure.annexD1Part1.dExtremeUrgency',
                            }]}
                            onChange={handleFormChange}
                            renderAsRow={true}
                            required={false}
                        />
                        <LabelledCheckBox
                            id={'procedure.annexD1Part1.dAddDeliveriesOrdered'}
                            label={''}
                            breakColumn={true}
                            options={[{
                                id: 'procedure.annexD1Part1.dAddDeliveriesOrdered',
                                value: true,
                                label: 'Additional deliveries by the original supplier ordered under the strict conditions stated in the regulation (optional)',
                                checked: formFields?.procedure?.annexD1Part1?.dAddDeliveriesOrdered ? formFields?.procedure?.annexD1Part1?.dAddDeliveriesOrdered === 'true' : false,
                                name: 'procedure.annexD1Part1.dAddDeliveriesOrdered',
                            }]}
                            onChange={handleFormChange}
                            renderAsRow={true}
                            required={false}
                        />
                        <LabelledCheckBox
                            id={'procedure.annexD1Part1.dRepetitionExisting'}
                            label={''}
                            breakColumn={true}
                            options={[{
                                id: 'procedure.annexD1Part1.dRepetitionExisting',
                                value: true,
                                label: 'New works/services, constituting a repetition of existing works/services and ordered in accordance with the strict conditions stated in the regulation (optional)',
                                checked: formFields?.procedure?.annexD1Part1?.dRepetitionExisting ? formFields?.procedure?.annexD1Part1?.dRepetitionExisting === 'true' : false,
                                name: 'procedure.annexD1Part1.dRepetitionExisting',
                            }]}
                            onChange={handleFormChange}
                            renderAsRow={true}
                            required={false}
                        />
                    </>}

                    {formFields?.notMapped?.justificationType === 'OUTSIDE_SCOPE'
                    && <>
                        <h4 className='title-large subHeading'>Justification</h4>
                        <LabelledCheckBox
                            id={'procedure.annexPart2.dOutsideScope'}
                            label={''}
                            breakColumn={true}
                            options={[{
                                id: 'procedure.annexPart2.dOutsideScope',
                                value: true,
                                label: 'The procurement falls outside the scope of application of the regulation',
                                checked: formFields?.procedure?.annexPart2?.dOutsideScope ? formFields?.procedure?.annexPart2?.dOutsideScope === 'true' : false,
                                name: 'procedure.annexPart2.dOutsideScope',
                            }]}
                            onChange={handleFormChange}
                            renderAsRow={true}
                            required={false}
                        />
                    </>}
                    <h4 className='title-large subHeading'>Explanation</h4>
                    <LabelledTextarea
                        id='procedure.dJustification'
                        label='Explain in a clear and comprehensive manner why the award of the contract without prior publication in the Official Journal of the European Union or the Find a Tender service is lawful, by stating the relevant facts and, as appropriate, the conclusions of law in accordance with the regulation'
                        type='textarea'
                        value={formFields?.procedure?.dJustification}
                        placeholder={'Enter text here'}
                        required={true}
                        onChange={handleFormChange}
                    />
                </>}

            </div>
            <div className='vpnItemButtonGroup'>
                <Button
                    id='cancel'
                    label='Cancel'
                    variant='secondary'
                    handleClick={() => history.goBack() }
                    disabled={false}
                />

                <div className='buttonDivider'></div>

                <Button
                    id='submit'
                    type={'submit'}
                    value='Create'
                    label='Create'
                    breakColumn={false}
                />
            </div>
        </form>
    </>;
};

FTSF02NoticeForm.propTypes = {
    project: PropTypes.object.required,
    contract: PropTypes.object.required,
    suppliers: PropTypes.array.required,
    noticeEvent: PropTypes.object.required,
    extra: PropTypes.object.required,
    handleChange: PropTypes.func.required,
    setShowCodesModal: PropTypes.func.required,
    generateCodeTag: PropTypes.func.required,
};

export default FTSF02NoticeForm;
