import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import RegistrationHeader from '../../features/SupplierRegistration/components/RegistrationHeader';
import SelectionPills from '../../features/SupplierRegistration/components/SelectionPills';
import constants from '../../features/SupplierRegistration/constants';
import Form from '../../components/Form';
import ProgressBar from '../../features/ProgressBar';
import Button from '../../components/Button';
// import SupplyRegionsModal from '../../features/RegionsOfSupply/SupplyRegionsModal';
import actions from '../../features/RegionsOfSupply/slice/supplyRegions.action';
import GuidanceBanner from '../../components/GuidanceBanner';

const SupplyRegionsModal = React.lazy(() => import('../../features/RegionsOfSupply/SupplyRegionsModal'));

const RegionsOfSupply = () => {
    const history = useHistory();
    const [showRegionsModal, setShowRegionsModal] = useState(false);
    const dispatch = useDispatch();

    const { companyDetailForm, initCodesRegions, onlySelectedRegions } = useSelector((state) => ({
        companyDetailForm: state.SupplierRegReducer.companyDetails,
        initCodesRegions: state.SupplyRegionsReducer.selectedRegions,
        onlySelectedRegions: state.SupplyRegionsReducer.onlySelectedRegions,
    }));

    const [data, setData] = useState(onlySelectedRegions);

    useEffect(() => {
        if (companyDetailForm.accountName === '') {
            // No Company Details Error.
            history.push('/');
        }
    }, []);

    const toggleModal = () => {
        setShowRegionsModal(!showRegionsModal);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        history.push('/registration/account-details');
    };

    useEffect(() => {
        setData(onlySelectedRegions);
    }, [onlySelectedRegions]);

    const removeRegionSelection = (parent, level) => {
        parent.map((el) => {
            const elem = el;
            if (el.description === level) {
                elem.selected = false;
            }
            if (el.cities) { removeRegionSelection(el.cities, level); }
            if (el.counties) { removeRegionSelection(el.counties, level); }
            return null;
        });
        return initCodesRegions;
    };

    const prev = (event) => {
        event.preventDefault();
        history.goBack();
    };

    const updateRegionSelectionView = (pillName) => {
        dispatch(actions.setOnlySelectedRegions(onlySelectedRegions
            .filter((el) => el.description !== pillName)));
    };

    const handlePillDelete = (pillName) => {
        removeRegionSelection(initCodesRegions, pillName);
        updateRegionSelectionView(pillName);
    };

    const removePill = (pillName) => {
        handlePillDelete(pillName);
    };


    return <>
        <ProgressBar options={constants.progressBar}
            activeStep={3}
            id='company-reg-progress'
            context='LeftToRight' />
        <GuidanceBanner
            id={'codesGuidanceBanner'}
            type={''}
            bannerText1={'You also need to choose the Delivery Area, meaning the area that you can operate and deliver services in - this will also be used to notify you of relevant opportunities. You can select multiple Delivery Areas, and drill down into sub-categories.'}
            bannerText2={'Not sure about which codes to choose? Don’t worry! You can choose some now, and edit your codes later on in the ‘My Profile’ area.'}
        />
        <section className='nepo-container registration-form'>
            <div className='wrapper' >
                <Form id='cnc-form' onSubmit={(e) => handleSubmit(e)}>
                    <RegistrationHeader addAction={toggleModal} headingLabel='Delivery Areas Selected' />
                    <SelectionPills whenEmptyShow='No delivery areas selected. Please select at least one delivery area' data={data} removePillItemAction={removePill} context={'supplyRegions'} />
                    <div className='form-btn-wrapper'>
                        <Button id='second-action-btn'
                            additionalVariant='ros-back'
                            variant='secondary'
                            label='Back'
                            handleClick={(event) => prev(event)} />
                        <Button id='main-action-btn'
                            label='Continue'
                            disabled={data.length === 0}
                            handleClick={() => null} />
                    </div>
                </Form>
            </div>
        </section>;
        {showRegionsModal && <SupplyRegionsModal
            closeModal={toggleModal} /> }
    </>;
};
export default RegionsOfSupply;
