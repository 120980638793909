const actions = {
    editSteeringGroup: 'editSteeringGroup',
    selectWorkflow: 'selectWorkflow',
    setDefaultWorkflow: 'setDefaultWorkflow',
    selectQuestionnaire: 'selectQuestionnaire',
    editAssignedQuestionnaire: 'editAssignedQuestionnaire',
    editWorkflow: 'editWorkflow',
    registerInterestRequests: 'registerInterestRequests',
    registerInterestInvites: 'registerInterestInvites',
    advertProject: 'advertProject',
    cancelProject: 'cancelProject',
    createProject: 'createProject',
    editProject: 'editProject',
    userAccountManagement: 'userAccountManagement',
    userProfileManagement: 'userProfileManagement',
    openAccountRequests: 'openAccountRequests',
    openAllSeal: 'openAllSeal',
    releaseAll: 'releaseAll',
    viewOpenAnswerQuestionnaire: 'viewOpenAnswerQuestionnaire',
    evaluateQuestionnaire: 'evaluateQuestionnaire',
    viewAnswerQuestionnaire: 'viewAnswerQuestionnaire',
    viewEvaluateQuestionnaire: 'viewEvaluateQuestionnaire',
    submitEvaluationsForApproval: 'submitEvaluationsForApproval',
    evaluateApproveRejectAll: 'evaluateApproveRejectAll',
    submitIntentLetterForApproval: 'submitIntentLetterForApproval',
    cancelStandstillTimer: 'cancelStandstillTimer',
    updateStandstillTimer: 'updateStandstillTimer',
    sendIntentLetters: 'sendIntentLetters',
    disqualifySuppliers: 'disqualifySuppliers',
    exportData: 'exportData',
    submitAwardForApproval: 'submitAwardForApproval',
    submitAwardLetterForApproval: 'submitAwardLetterForApproval',
    completeWorkflowEvent: 'completeWorkflowEvent',
    approveRejectWorkflowEvent: 'approveRejectWorkflowEvent',
    setProjectRisk: 'setProjectRisk',
    addEditSelectionQuestionnaire: 'addEditSelectionQuestionnaire',
    addLots: 'addLots',
    addFAQ: 'addFAQ',
    addNotes: 'addNotes',
    uploadDocuments: 'uploadDocuments',
    addContract: 'addContract',
    viewMessenger: 'viewMessenger',
    escalateEvent: 'escalateEvent',
    editFAQ: 'editFAQ',
    openUserRequests: 'openAccountRequests',
    editProcurement: 'editProcurement',
    approveProcurement: 'approveProcurement',
    resolveEscalation: 'resolveEscalation',
    addWorkflow: 'addWorkflow',
    addQuestionnaire: 'addQuestionnaire',
    addEvent: 'addEvent',
    addStage: 'addStage',
    addSection: 'addSection',
    editQuestionnaire: 'editQuestionnaire',
    editEvent: 'editEvent',
    editStage: 'editStage',
    editSection: 'editSection',
    manageContractSuppliers: 'addSupplierToContract',
    approveStage1: 'approveStage1',
    approveStage2: 'approveStage2',
    rejectStage1: 'rejectStage1',
    rejectStage2: 'rejectStage2',
    answerSQ: 'answerSQ',
    manageDepartments: 'manageDepartments',
    editContract: 'editContract',
    addContractRegister: 'addContractRegister',
    analytics: 'analytics',
    downloadAll: 'downloadAll',
};

export default actions;
