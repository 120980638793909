import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({
    id,
    pages,
    currentPageNum,
    numOfDisplayPages,
    paginate,
}) => {
    const generatePageNumbers = () => {
        const elements = [];
        let pagesToProcess = [...pages];

        if (!(pages.length >= 6 && pages.length <= 9)) {
            if ((currentPageNum + numOfDisplayPages) >= pages.length) {
                pagesToProcess = pagesToProcess
                    .splice(pages.length - numOfDisplayPages, pages.length);
            } else {
                pagesToProcess = pagesToProcess.splice(currentPageNum, numOfDisplayPages);
            }
        }

        pagesToProcess.forEach((page) => {
            const pageNumber = pages.indexOf(page);
            elements.push(<li key={`paginationNumber-${pageNumber}`} className='paginationItem'>
                <button className={`paginationItemNumber title ${currentPageNum === pageNumber && 'active'}`}
                    id={`paginationItem${pageNumber}Btn`}
                    disabled={currentPageNum === pageNumber}
                    onClick={() => ((currentPageNum === pageNumber) ? null : paginate(pageNumber))}>
                    {pageNumber + 1}
                </button>
            </li>);
        });

        if ((pages.length > numOfDisplayPages)
            && ((currentPageNum + numOfDisplayPages) < pages.length)) {
            elements.push(<li key={'paginationEllipsis'} className='paginationItem'>
                <p className={'paginationItemEllipsis title'}>...</p>
            </li >);
        }

        return elements;
    };

    return <ul className='pagination' id={id}>
        <li className={`paginationItem ${currentPageNum === 0 && 'disabled'}`}>
            <button className='title' id='paginationFirstBtn' disabled={currentPageNum === 0}
                onClick={() => paginate(0)}>First</button>
        </li>
        <li className={`paginationItem ${currentPageNum === 0 && 'disabled'}`}>
            <button className='title' id='paginationPreviousBtn' disabled={currentPageNum === 0}
                onClick={() => paginate((currentPageNum !== 0)
                    ? currentPageNum - 1 : 0)}>Previous</button>
        </li>
        {generatePageNumbers()}
        <li className={`paginationItem ${currentPageNum === pages.length - 1 && 'disabled'}`}>
            <button className='title' id='paginationNextBtn' disabled={currentPageNum === pages.length - 1}
                onClick={() => paginate((currentPageNum !== pages.length - 1)
                    ? currentPageNum + 1 : pages.length - 1)}>Next</button>
        </li>
        <li className={`paginationItem ${currentPageNum === pages.length - 1 && 'disabled'}`}>
            <button className='title' id='paginationLastBtn' disabled={currentPageNum === pages.length - 1}
                onClick={() => paginate(pages.length - 1)}>Last</button>
        </li>
    </ul>;
};

Pagination.propTypes = {
    id: PropTypes.string.isRequired,
    pages: PropTypes.array.isRequired,
    currentPageNum: PropTypes.number.isRequired,
    numOfDisplayPages: PropTypes.number.isRequired,
    paginate: PropTypes.func.isRequired,
};

export default Pagination;
