import instance from '../config/axios.instance';
import endpoints from '../config/endpoints';

const { userManagement } = endpoints;

const userManagementAPIs = {
    login: async (username, password) => {
        try {
            const endpoint = userManagement.login;
            const body = { username, password };
            const response = await instance.post(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    logout: async (accessToken) => {
        try {
            const endpoint = userManagement.logout;
            const body = { accessToken };
            const response = await instance.post(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    createPassword: async (username, password, session) => {
        try {
            const endpoint = userManagement.createPassword;
            const body = { username, password, session };
            const response = await instance.post(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getVerificationCode: async (username) => {
        try {
            const endpoint = userManagement.resetPassword;
            const body = { username };
            const response = await instance.post(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    resetPassword: async (username, password, confirmationCode) => {
        try {
            const endpoint = userManagement.changePassword;
            const body = { username, password, confirmationCode };
            const response = await instance.put(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getUser: async (userId) => {
        try {
            const endpoint = userManagement.getUser;
            const response = await instance.get(`${endpoint}/${userId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    createAccount: async (body) => {
        try {
            const endpoint = userManagement.createAccount;
            const response = await instance.post(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    createAccountByAdmin: async (body) => {
        try {
            const endpoint = userManagement.createAccountByAdmin;
            const response = await instance.post(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    updateUser: async (body, userId) => {
        try {
            const endpoint = `${userManagement.updateUser}/${userId}`;
            const response = await instance.put(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    updateProfile: async (body, userid) => {
        try {
            const endpoint = userid ? `${userManagement.updateProfile}/${userid}`
                : `${userManagement.updateProfile}`;
            const response = await instance.put(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    joinAccount: async (userId, body) => {
        try {
            const endpoint = userManagement.joinAccount;
            const response = await instance.put(`${endpoint}/${userId}`, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    checkCompanyExists: async (companyName) => {
        try {
            const endpoint = userManagement.checkCompanyExists;
            const response = await instance.get(`${endpoint}/${companyName}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getUserDetails: async () => {
        try {
            const endpoint = userManagement.getUserDetails;
            const response = await instance.get(`${endpoint}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    resendTempPassword: async (username) => {
        try {
            const endpoint = userManagement.resendTempPassword;
            const body = { username };
            const response = await instance.post(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getAllAccountUsers: async (accountId) => {
        try {
            const endpoint = `${userManagement.getAllAccountUsers}/${accountId}`;
            const response = instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    checkHQAndListRO: async (body) => {
        try {
            const endpoint = `${userManagement.checkHQAndListRO}`;
            const response = instance.get(endpoint, {
                params: {
                    cName: body.companyName,
                    regNo: body.regNo,
                    postCode: body.postcode,
                },
            });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getAllSuppliers: async () => {
        try {
            const endpoint = userManagement.getAllSuppliers;
            const response = await instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getAllAccountByType: async (accountType) => {
        try {
            const endpoint = `${userManagement.getAllAccountByType}`;
            const response = instance.get(`${endpoint}/${accountType}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    createMeeting: async (request) => {
        try {
            const endpoint = `${userManagement.createMeeting}`;
            const response = instance.post(endpoint, request);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getUserEvents: async (userID) => {
        try {
            const endpoint = `${userManagement.getCalendarEventsForUser}/${userID}`;
            const response = instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getProjectEvents: async (projectID) => {
        try {
            const endpoint = `${userManagement.getSingleCalendar}/${projectID}`;
            const response = instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getUserProjectEvents: async () => {
        try {
            const endpoint = userManagement.getUserProjectCalendar;
            const response = instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    updateEvent: async (body) => {
        const {
            calendarID,
            start,
            end,
            ...request
        } = body;
        try {
            const endpoint = `${userManagement.updateEvent}/${calendarID}`;
            const response = instance.put(endpoint, request);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    updateAccountWorknotes: async (body) => {
        try {
            const endpoint = `${userManagement.updateAccountWorknotes}`;
            const response = instance.put(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    updateAccountDocuments: async (body) => {
        try {
            const endpoint = `${userManagement.updateAccountDocuments}`;
            const response = instance.put(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    deleteUser: async (userId) => {
        try {
            const endpoint = `${userManagement.deleteUser}/${userId}`;
            const response = instance.delete(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    enableDisableUser: async (userId, status) => {
        try {
            const endpoint = `${userManagement.enableDisableUser}/${userId}/${status}`;
            const response = instance.put(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getCalendarEvents: async (userID) => {
        try {
            const endpoint = `${userManagement.getCalendarEvents}/${userID}`;
            const response = instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getAccountRequests: async (accountID) => {
        try {
            const endpoint = `${userManagement.getAccountRequests}/${accountID}`;
            const response = instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    updateAccountRequests: async (userID, body) => {
        try {
            const endpoint = `${userManagement.updateAccountRequests}/${userID}`;
            const response = instance.put(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    createDepartment: async (accID, body) => {
        try {
            const endpoint = `${userManagement.createDepartment}/${accID}`;
            const response = instance.put(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    deleteDepartment: async (deptID) => {
        try {
            const endpoint = `${userManagement.deleteDepartment}/${deptID}`;
            const response = instance.delete(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    listAllDepartments: async () => {
        try {
            const endpoint = `${userManagement.listAllDepartments}`;
            const response = instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getAllUsersByAccount: async (verified) => {
        try {
            const endpoint = `${userManagement.getAllUsersByAccount}/${verified}`;
            const response = instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getAllUsersByAccountForAdmin: async (verified) => {
        try {
            const endpoint = `${userManagement.getAllUsersByAccountForAdmin}/${verified}`;
            const response = instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    approveUser: async (userID, payLoad) => {
        try {
            const endpoint = `${userManagement.approveUser}/${userID}`;
            const response = instance.put(endpoint, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    approveAccount: async (accountID, payLoad) => {
        try {
            const endpoint = `${userManagement.approveAccount}/${accountID}`;
            const response = instance.put(endpoint, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getAllAccountsByType: async (type) => {
        try {
            const endpoint = `${userManagement.getAllAccountsByType}/${type}`;
            const response = instance.get(endpoint);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    listUsers: async (filterOptions, isCollaborateUser) => {
        try {
            const endpoint = isCollaborateUser
                ? `${userManagement.listUsers}/true`
                : `${userManagement.listUsers}`;
            const response = instance.get(endpoint, {
                params: {
                    ...filterOptions,
                },
            });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getSupplierDetails: async (supplierId) => {
        try {
            const endpoint = userManagement.getSupplierDetails;
            const response = await instance.get(`${endpoint}/${supplierId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    postSupplierRiskStatus: async (accID, body) => {
        try {
            const endpoint = `${userManagement.getSupplierDetails}/${accID}/riskstatus`;
            const response = instance.put(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    getCleverQuotes: async (projectID, filterOptions) => {
        try {
            const endpoint = `${userManagement.getCleverQuotes}/${projectID}`;
            const response = instance.get(endpoint, {
                params: {
                    ...filterOptions,
                },
            });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
    findAccounts: async (body) => {
        try {
            const endpoint = userManagement.findAccounts;
            const response = instance.post(endpoint, body);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
};
export default userManagementAPIs;
