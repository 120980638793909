const roles = {
    po: 'po',
    pm: 'pm',
    legal: 'legal',
    individual_eval: 'individual_eval',
    evaluator: 'eval',
    cs: 'cs',
    cm: 'cm',
    approver: 'Approver',
    stage1approver: 's1a',
    stage2approver: 's2a',
    supplier: 'supplier',
    collaborative: 'collaborative',
    LABasic: 'LABasic',
    LAIntermediate: 'LAIntermediate',
    LAAdvanced: 'LAAdvanced',
    LAAdmin: 'LAAdmin',
    NEPOBasic: 'NEPOBasic',
    NEPOIntermediate: 'NEPOIntermediate',
    NEPOAdvanced: 'NEPOAdvanced',
    NEPOAdmin: 'NEPOAdmin',
    supplierBasic: 'supplierBasic',
    supplierIntermediate: 'supplierIntermediate',
    supplierAdvanced: 'supplierAdvanced',
    supplierAdmin: 'supplierAdmin',
};

export default roles;
