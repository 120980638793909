import React from 'react';

import { titleize } from 'inflected';

import projectManagementAPIs from '../../services/project-management.service';
import helperFunctions from '../../utils/helperFunctions';

const prepareValue = (rawValue) => {
    let preparedValue = rawValue;
    if (Array.isArray(rawValue)) {
        preparedValue = rawValue.length === 0 ? 'No data available' : rawValue.join(', ');
    }
    if (typeof rawValue === 'boolean') {
        preparedValue = rawValue ? 'Yes' : 'No';
    }
    if (typeof rawValue === 'string') {
        preparedValue = helperFunctions.formatPrettyDateTime(rawValue);
    }
    return preparedValue || '-';
};

const createEmptySpanWithChars = (noOfChar) => {
    const spanInput = [];
    for (let i = 0; i < noOfChar; i++) {
        spanInput.push(<span></span>);
    }
    return spanInput;
};

const sortedObj = (obj) => {
    const data = { ...obj };
    return {
        id: data.id,
        createdDate: data.createdDate,
        createdByUser: data.createdByUser,
        isPreAwarded: data.isPreAwarded,
        isPublished: data.isPublished,
        lastUpdatedDate: data.lastUpdatedDate,
        lastUpdatedbyUser: data.lastUpdatedbyUser,
        numberOfViews: data.numberOfViews,
        withdrawnByUser: data.withdrawnByUser,
        withdrawnDate: data.withdrawnDate,
        withdrawnReason: data.withdrawnReason,
        awards: data.awards,
        notice: data.notice,
        organisation: data.organisation,
        publishedVersion: data.publishedVersion,
        cpvCodes: data.cpvCodes,
        additionalDetails: data.additionalDetails,
    };
};

const containsObject = (list) => (
    list.some((item) => (
        typeof item === 'object'
    ))
);

const generateKeyValueJSX = (keys, jsonObj, indentLevel, showIndex, output = []) => {
    if (jsonObj !== null && typeof jsonObj === 'object' && !Array.isArray(jsonObj)) {
        const updatedIndent = indentLevel + 1;

        if (!showIndex || (showIndex && showIndex === 0)) {
            output.push(<>
                <p className='notice-header sticky'>
                    {createEmptySpanWithChars(indentLevel)}
                    {titleize(keys)}
                </p>
            </>);
        }

        Object.entries(jsonObj).forEach(([key, value]) => {
            generateKeyValueJSX(key, value, updatedIndent, undefined, output);
        });
    } else if (Array.isArray(jsonObj) && containsObject(jsonObj)) {
        jsonObj.forEach((el, index) => {
            generateKeyValueJSX(keys, el, indentLevel, index, output);
        });
    } else {
        output.push(<>
            <p className='notice-data-row title'>
                {createEmptySpanWithChars(indentLevel)}
                <span className='notice-title'>
                    {titleize(keys)}:
                </span>{' '}
                <span className='caption notice-body'>
                    {prepareValue(jsonObj)}
                </span>
            </p>
        </>);
    }

    return output;
};

const fetchData = async (projectId, eventId) => {
    const responseData = await projectManagementAPIs.getNotice(projectId, eventId);
    if (responseData.status === 200) {
        return responseData.data;
    }
    throw new Error(responseData.data.Message);
};

const layoutData = (data) => (
    generateKeyValueJSX('', sortedObj(data), 0)
);

const publishNotice = async (projectId, eventId, comment = undefined) => {
    const payLoad = {
        eventID: eventId,
        comment,
    };

    const response = await projectManagementAPIs
        .publishNotice(projectId, eventId, payLoad);

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(`Unable to publish notice: ${response.data?.Message}`);
};

export {
    fetchData,
    layoutData,
    publishNotice,
};
