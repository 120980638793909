import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import LabelledTextArea from '../../../../components/LabelledTextArea';
import Constants from './constants';

const DisqualifyModal = ({
    supplier,
    handleDisqualifySupplier,
    handleCloseModal,
}) => {
    const [values, setValues] = useState();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const generateModalBody = () => <>
        <div className='disqualifySuppliersModal'>
            <p className='body'>{Constants.DISQUALIFY_BODY}</p>
            <div className='disqualifyItem'>
                <p className='label caption supplierTitle'>{`${Constants.SUPPLIER}: ${supplier.companyName}`}</p>
                <p className = 'label caption'>{Constants.DISQUALIFY_REASON}:</p>
                <LabelledTextArea
                    id={'internalReason'}
                    placeholder={Constants.ENTER_REASON}
                    onChange={(e) => handleChange(e)}
                    value={values?.internalReason}
                />
            </div>
        </div>
    </>;

    return (
        <>
            <Modal
                open={true}
                closeModal={() => {
                    setValues();
                    handleCloseModal();
                }}
                size='medium'
                headerTitle={Constants.DISQUALIFY_TITLE}
                handleMainActionBtnClick={() => {
                    handleDisqualifySupplier({
                        supplierAccountID: supplier.accountID,
                        internalReason: values.internalReason,
                    });
                    setValues();
                    handleCloseModal();
                }}
                mainActionButtonTxt={Constants.CONFIRM}
                closeButton={false}
                body={generateModalBody()}
                helpOption={false}
                secondActionButton={true}
                secondActionButtonTxt={Constants.CANCEL}
                handleSecondaryActionBtnClick={() => {
                    setValues();
                    handleCloseModal();
                }}
            />
        </>
    );
};

DisqualifyModal.propTypes = {
    supplier: PropTypes.object,
    handleDisqualifySupplier: PropTypes.func,
    handleCloseModal: PropTypes.func,
};

export default DisqualifyModal;
