import React from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from '../../../../styles/images/errorIcon.svg';

const InvalidCredentialsErrorDialog = ({ toggleRegModal, closeModal }) => {
    const redirectToReg = () => {
        closeModal();
        toggleRegModal();
    };

    return <>
        <div className='success-container'>
            <img src={ErrorIcon} alt='Error' className='success-img'></img>
            <p className='body error'>Couldn{'\''}t log you into Open.</p>
        </div>
        <p className='body inval-login'>If you have logged into Open before, please check the email
            and/or password entered is correct and try again.</p>
        <p className='body inval-login'>If you work for Nepo or a Nepo Member Authority
                and have not logged into Open before today, please contact your
            line manager/department lead for an account on Open.</p>
        <p className='body inval-login'>If you are a supplier and have not logged into Open before
         today, <button id='clickHere' type='button' className='redirect-btn-label body' onClick={redirectToReg}>click here</button> to create your organisation’s account on Open.</p>
    </>;
};


InvalidCredentialsErrorDialog.propTypes = {
    toggleRegModal: PropTypes.func,
    closeModal: PropTypes.func,
};

export default InvalidCredentialsErrorDialog;
