import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import LabelledInput, {
    LabelledInputTypePassword,
    LabelledInputTypeEmail,
} from '../../../../components/LabelledInput';


const CreatePasswordFormBody = ({ formData, handleInput }) => {
    const [passwordsMatchJsx, setPasswordsMatchJsx] = useState(<></>);
    useEffect(() => {
        if (formData.newPassword && formData.verifyPassword) {
            setPasswordsMatchJsx(formData.newPassword === formData.verifyPassword
                ? <p id='passwords-match-label' className='passwords-match success'>Passwords match</p>
                : <p id='passwords-do-not-match-label' className='passwords-match error'>Passwords do not match</p>);
        }
    }, [formData, formData.newPassword, formData.verifyPassword]);
    const passwordLengthHintTxt = 'Must be at least 12 characters long \n'
        + 'Must include at least 1 lowercase letter \n'
        + 'Must include at least 1 uppercase letter \n'
        + 'Must include at least 1 number \n'
        + 'Must include at least 1 of these #?!@$%^&*- special character\n';

    return <>
        <LabelledInput
            id='email'
            type={LabelledInputTypeEmail}
            label='Enter your email.'
            placeholder='Enter your email'
            value={formData.email || ''}
            showHint={true}
            onChange={(event) => handleInput(event)}
            breakColumn={false}
            required={true} />
        <LabelledInput
            id='newPassword'
            type={LabelledInputTypePassword}
            label='Enter your new password.'
            placeholder='Enter new password'
            value={formData.newPassword || ''}
            showHint={true}
            onChange={(event) => handleInput(event)}
            breakColumn={false}
            required={true} />
        <LabelledInput
            id='verifyPassword'
            type={LabelledInputTypePassword}
            label='Confirm password.'
            placeholder='Enter new password'
            value={formData.verifyPassword || ''}
            onChange={(event) => handleInput(event)}
            breakColumn={false}
            required={true} />
        {passwordsMatchJsx}
        <p id='password-length-hint' className='caption-small password-hint'>{passwordLengthHintTxt}</p>
    </>;
};

CreatePasswordFormBody.propTypes = {
    formData: PropTypes.object,
    handleInput: PropTypes.func,
    isVerificationJourney: PropTypes.bool,
};

export default CreatePasswordFormBody;
