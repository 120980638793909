import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DocumentBuilder from '../../features/DocumentBuilder';
import documentManagementAPIs from '../../services/document-management.service';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';

const ViewSection = () => {
    const { sectionID, type } = useParams();
    const [initialState, setInitialState] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const responseData = await documentManagementAPIs.getSection(sectionID);
            if (responseData.status === 200) {
                const preparedData = helperFunctions
                    .prepareDocumentBuilderState('section', responseData.data, sectionID);
                setInitialState(preparedData);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        };
        // TODO: if (templateId && authContext.user.isAuthenticated) {
        if (sectionID) {
            fetchData();
        }
    }, [sectionID]);

    return Object.keys(initialState).length > 0 ? <DocumentBuilder
        documentMode={'view'}
        context='Section'
        type={type}
        initialDocumentState={initialState}
        sectionLabel='Section'
        sectionItemLabel='Question'
        allowSectionReordering={false}
        allowSectionItemsReordering={false}
        sectionDetailsUserList={[]}
    /> : <></>;
};

export default ViewSection;
