import PropTypes from 'prop-types';
import React from 'react';
import Accordion from '../../features/Accordion';
import Constants from './supplierConstants';
import SupplierListComp from './SupplierListComp';

const SupplierAccordian = ({
    dataSet,
    accordionState,
    context,
}) => {
    const generateAccordionSectionHeader = (data) => {
        switch (context.toLowerCase()) {
            case Constants.department:
                return <p className='headerTitle title'>{data?.departmentName}</p>;
            default:
                return <></>;
        }
    };

    const generateAccordionSectionContent = (data) => {
        switch (context.toLowerCase()) {
            case Constants.department:
                return (<SupplierListComp dataSet={data} context={context}/>);
            default:
                return <></>;
        }
    };
    const generateAccordionData = () => {
        const elements = [];
        if (dataSet) {
            switch (context.toLowerCase()) {
                case Constants.department:
                    dataSet.forEach((data, idx) => {
                        elements.push({
                            sectionId: `bdAccordionSection-id-${idx}`,
                            header: generateAccordionSectionHeader(data?.deptInfo),
                            content: generateAccordionSectionContent(data?.userInfo),
                        });
                    });
                    break;
                default:
                    <></>;
                    break;
            }
        }
        return elements;
    };
    return (
        <Accordion id={'bdAccordion'} data={generateAccordionData()} open={accordionState} />
    );
};

SupplierAccordian.propTypes = {
    dataSet: PropTypes.arrayOf(PropTypes.any),
    accordionState: PropTypes.bool,
    context: PropTypes.string.isRequired,
};

export default SupplierAccordian;
