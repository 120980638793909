import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import { format } from 'date-fns';

import LabelledInput, {
    LabelledInputTypeText,
    LabelledInputTypeSubmit,
    LabelledInputTypeCurrency,
} from '../../components/LabelledInput';
import Button from '../../components/Button';
import LabelledTextArea from '../../components/LabelledTextArea';
import Form from '../../components/Form';
import ConfirmModal from '../ConfirmModal';
import UploadModal from '../UploadModal';
import clearIcon from '../../styles/images/clearIcon.svg';
import AuthContext from '../../context/AuthContext';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';

import {
    cleanupDeletedDocument,
    handleDocumentSubmit,
    handleDownload,
    mergeUploadedDocuments,
} from '../../utils/uploadHelper';

const ProcurementRequestForm = () => {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const { projectId } = useParams();

    const [values, setValues] = useState({});
    const [documents, setDocuments] = useState([]);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        const getProcurementRequest = async () => {
            try {
                const response = await projectManagementAPIs.getPRF(projectId);
                if (response.status === 200) {
                    // TODO: Change the date method to use date-fns for contract start date.
                    const serializedResponse = {
                        ...response.data,
                        contractStart: response.data.contractStart
                            ? new Date(response.data.contractStart.split('-').reverse().join('-'))
                            : response.data.contractStart,

                    };
                    setValues(serializedResponse);
                    setDocuments(serializedResponse.additionalDocuments
                        ? serializedResponse.additionalDocuments : []);
                    setIsEdit(true);
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        };
        if (projectId !== undefined) {
            getProcurementRequest();
        }
    }, [projectId]);


    const submitData = async () => {
        try {
            const payLoad = {
                ...values,
                additionalDocuments: documents,
                contractStart: values.contractStart ? format(values.contractStart, 'dd-MM-yyyy') : '',
            };

            if (isEdit === false) {
                const createProcurementRequest = await projectManagementAPIs.createPRF(payLoad);

                if (createProcurementRequest.status === 201) {
                    Toast.fire({
                        icon: 'success',
                        titleText: 'Procurement request created successfully.',
                    });
                    history.push(`/procurement-requests/overview/${createProcurementRequest.data}`);
                } else if (createProcurementRequest.status === 401) {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Not authorized to create procurement request.',
                    });
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to create procurement request.',
                    });
                }
            } else {
                delete payLoad.additionalDocuments;
                delete payLoad.projectID;
                delete payLoad.user;

                const updatedProcurementReq = await projectManagementAPIs.editPRF(projectId,
                    payLoad);
                if (updatedProcurementReq.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        titleText: 'Procurement request updated successfully.',
                    });
                    history.push(`/procurement-requests/overview/${projectId}`);
                } else if (updatedProcurementReq.status === 401) {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Not authorized to update procurement request.',
                    });
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to update procurement request.',
                    });
                }
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to create procurement request.',
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isEdit) {
            submitData();
        } else {
            setShowConfirmModal(true);
        }
    };

    const handleUploadSubmit = async (e, document) => {
        if (isEdit === false) {
            return handleDocumentSubmit(e, document, authContext, {
                accessGroups: [authContext.user.id],
                uploadCallback: (docs) => {
                    const mergedDocs = mergeUploadedDocuments(documents, docs,
                        {}, authContext.user);
                    setDocuments(mergedDocs);
                    setShowUploadModal(false);
                },
            });
        }

        return false;
    };

    const handleRemoveDocument = (document) => {
        const mergedDocs = cleanupDeletedDocument(documents, document);
        setDocuments(mergedDocs);
    };

    const handleConfirm = () => {
        submitData();
        setShowConfirmModal(false);
    };

    const handleChange = (event) => {
        const { name } = event.target;
        const { value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleUpload = (event) => {
        event.preventDefault();
        setShowUploadModal(true);
    };

    const handleView = (event) => {
        event.preventDefault();
        setShowDocumentModal(true);
    };

    const handleCancel = (event) => {
        event.preventDefault();
        history.push('/dashboard');
    };

    return (
        <section id='prMainContainer'>
            <div className='prContainerHeader'>
                <p className='prContainerTitle screenTitleHeading'>Enter Request Details</p>
            </div>
            <div className='prFormContainer'>
                <Form id='prForm' autocomplete='off' onSubmit={(e) => handleSubmit(e)}>
                    <div className='prFormTitle'>
                        <p className='keyInfo'>Key Information</p>
                    </div>
                    <div className='formInputContainer'>
                        <LabelledInput
                            id='title'
                            type={LabelledInputTypeText}
                            label='Request title'
                            breakColumn={true}
                            onChange={(e) => handleChange(e)}
                            value={values.title || ''}
                            placeholder='Enter title'
                        />
                    </div>
                    <div className='prFormInputContainer'>
                        <LabelledTextArea
                            id='description'
                            placeholder='Enter description'
                            required={true}
                            breakColumn={true}
                            label='Request description'
                            value={values.description || ''}
                            currentCharCount={values.description ? values.description.length : 0}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className='formInputContainer'>
                        <LabelledInput
                            id='estimatedValue'
                            type={LabelledInputTypeCurrency}
                            label='Estimated value (£)'
                            breakColumn={true}
                            onChange={(e) => handleChange(e)}
                            value={values.estimatedValue || ''}
                            placeholder='Enter value'
                        />
                    </div>
                    <div className='prFormInputContainer'>
                        <div className='datePickerLabel'>
                            <label className='datePickerLabel title'>Estimated Contract Start Date (Optional)</label>
                        </div>
                        <div className='datePickerWrapper' id='interestWindowRangeWrapper'>
                            <DatePicker
                                className='customDateInput nmiDate-imput'
                                dayPlaceholder='DD'
                                monthPlaceholder='MM'
                                yearPlaceholder='YYYY'
                                format={'dd-MM-y'}
                                calendarIcon={null}
                                clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                                onChange={(changedDate) => {
                                    setValues({
                                        ...values,
                                        contractStart: changedDate,
                                    });
                                }}
                                value={values.contractStart}
                            />
                            <div className='flex-break'></div>
                        </div>
                    </div>
                    <div className='formInputContainer'>
                        <LabelledInput
                            id='contractDuration'
                            type={LabelledInputTypeText}
                            label='Contract duration in month(s)'
                            breakColumn={true}
                            onChange={(e) => handleChange(e)}
                            value={values.contractDuration || ''}
                            required={false}
                            placeholder='Enter duration'
                            pattern='^[0-9]+$'
                            title='Please enter a whole number'
                        />
                    </div>
                    {!isEdit
                    && <div className='prFormInputContainer'>
                        <div className='formLabel'>
                            <p className='title'>Documents</p>
                        </div>
                        <div className='formCaption'>
                            { documents.length === 1 ? <p className='caption'>{documents.length} document uploaded</p>
                                : <p className='caption'>{documents.length} documents uploaded</p>
                            }
                        </div>
                        <div className='prDocumentButtonContainer'>
                            <Button
                                id='viewDocument'
                                label = 'View'
                                size='small'
                                variant='secondary'
                                handleClick={(e) => handleView(e)}
                            />
                            <Button
                                id='uploadDocument'
                                label = 'Upload'
                                size='small'
                                handleClick={(e) => handleUpload(e)}
                            />
                        </div>
                        <div className='flex-break'></div>
                    </div>}
                    <div className='prFormSubmitContainer'>
                        <Button
                            id='cancel'
                            label='Cancel'
                            variant='secondary'
                            handleClick={(e) => handleCancel(e)}
                        />
                        <div className='buttonDivider'></div>
                        <LabelledInput
                            id='submit'
                            type={LabelledInputTypeSubmit}
                            value= {isEdit ? 'Update' : 'Create'}
                            label=''
                            breakColumn={false} />
                    </div>
                </Form>
            </div>
            {showConfirmModal && <>
                <ConfirmModal
                    body={<div>
                        <p className='caption'>Are you sure you want to create request {values.title}?</p>
                    </div>}
                    closeModal={() => setShowConfirmModal(false)}
                    submitForm={handleConfirm}
                    size='small'
                />
            </>}
            {showUploadModal && <>
                <UploadModal
                    mode='add'
                    documents={documents}
                    handleSubmit={handleUploadSubmit}
                    handleRemoveDocument={handleRemoveDocument}
                    handleDownload={handleDownload}
                    closeModal={() => setShowUploadModal(false)}
                    uploadIdentifier='document'
                />
            </>}
            {showDocumentModal && <>
                <UploadModal
                    mode='view'
                    documents={documents}
                    handleSubmit={handleUploadSubmit}
                    handleRemoveDocument={handleRemoveDocument}
                    handleDownload={handleDownload}
                    closeModal={() => setShowDocumentModal(false)}
                    uploadIdentifier='document'
                />
            </>}
        </section>
    );
};

export default ProcurementRequestForm;
