import constants from '../../../reducers/_constants';

const initialState = {
    selectedLocalities: [],
    onlySelectedLocalities: [],
};

const SupplyLocalitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case constants.SupplierRegistrationConstants.SET_LOCALITIES: {
            return {
                ...state,
                selectedLocalities: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.SET_ONLY_SELECTED_LOCALITIES: {
            return {
                ...state,
                onlySelectedLocalities: action.payload,
            };
        }
        case constants.SupplierRegistrationConstants.CLEAR_LOCALITIES: {
            return {
                ...state,
                onlySelectedLocalities: [],
                selectedLocalities: [],
            };
        }
        default:
            return state;
    }
};

export default SupplyLocalitiesReducer;
