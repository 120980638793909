import React from 'react';
import { useParams } from 'react-router-dom';
import CreateProcurementForm from '../../features/CreateProcurementForm';


const CreateProcurementProject = () => {
    const { projectId } = useParams();
    return <section id='cPPSection'>
        <p className='title-xLarge screenTitleHeading' id='sectionTitle'>Enter Project Details</p>
        <CreateProcurementForm type={projectId ? 'edit' : 'create' }/>
    </section>;
};

export default CreateProcurementProject;
