const Constants = {
    headers: {
        headquarterDetails: 'Headquarter Details',
    },
    roBusinessStructure: 'roBusinessStructure',
    country: 'country',
    companyCountryCode: 'companyCountryCode',
    pleaseSelect: 'Please select',
    regCountry: 'reg-country',
    regCompanyType: 'reg-companyType',
    progressBar: [
        'Company Details',
        'Select Codes & Categories',
        'Select Delivery Area',
        'Account Details'],
    createCompanyAccountProgressBar: [
        'Select Codes & Categories',
        'Select Delivery Area',
        'Additional Questions'],
    companyDetails: [{
        label: 'Company name',
        type: 'text',
        required: true,
        id: 'accountName',
        placeholder: 'Company name',
    },
    {
        label: 'Company type',
        type: 'text',
        required: true,
        id: 'businessStructure',
        placeholder: 'Company type',
    },
    {
        label: 'Nature of business (SIC)',
        type: 'text',
        required: false,
        id: 'natureOfBusiness',
        placeholder: 'Nature of business (SIC)',
    },
    {
        label: 'VAT number',
        type: 'text',
        required: false,
        id: 'vatNumber',
        placeholder: 'VAT number',
    },
    {
        label: 'Registration number',
        type: 'text',
        required: false,
        id: 'registrationNumber',
        placeholder: 'Registration number',
        pattern: '^[A-Za-z]{2}[0-9]{6}|[0-9]{8}$',
        title: 'Please enter a valid company house number.',
    },
    {
        label: 'Number of employees',
        type: 'text',
        required: true,
        id: 'noOfEmployees',
        placeholder: 'Number of employees',
        pattern: '^[0-9]+$',
        title: 'Field has to be numbers only',
    },
    {
        label: 'Address line 1',
        type: 'text',
        required: true,
        id: 'addressLine1',
        placeholder: 'Address line 1',
    },
    {
        label: 'Address line 2',
        type: 'text',
        required: false,
        id: 'addressLine2',
        placeholder: 'Address line 2',
    },
    {
        label: 'Town/City',
        type: 'text',
        required: true,
        id: 'city',
        placeholder: 'Town/City',
    },
    {
        label: 'County',
        type: 'text',
        required: false,
        id: 'county',
        placeholder: 'County',
    },
    {
        label: 'Postcode',
        type: 'text',
        // pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
        // title: 'Please enter a valid UK postcode',
        required: true,
        id: 'postcode',
        placeholder: 'Postcode',
    },
    {
        label: 'Country',
        type: 'select',
        required: true,
        id: 'country',
        placeholder: 'Country',
    },
    {
        label: 'Phone number',
        type: 'select',
        required: true,
        id: 'companyCountryCode',
        placeholder: 'Select country code',
        formGroup: true,
    }, {
        label: '',
        type: 'text',
        required: true,
        id: 'phoneNumber',
        placeholder: 'Phone Number',
        pattern: '^[0-9]+$',
        title: 'Field has to be numbers only',
        formGroup: true,
    }],
    accountDetails: [{
        label: 'Email',
        type: 'email',
        required: true,
        id: 'email',
        placeholder: 'Email',
    },
    {
        label: 'First name',
        type: 'text',
        required: true,
        id: 'firstname',
        placeholder: 'First name',
    }, {
        label: 'Last name',
        type: 'text',
        required: true,
        id: 'surname',
        placeholder: 'Last name',
    }, {
        label: 'Job title',
        type: 'text',
        required: true,
        id: 'jobTitle',
        placeholder: 'Job title',
    }, {
        label: 'Department',
        type: 'text',
        required: true,
        id: 'department',
        placeholder: 'Department',
    }, {
        label: 'Phone number',
        type: 'select',
        required: true,
        id: 'countryCode',
        placeholder: 'Select country code',
        formGroup: true,
    }, {
        label: '',
        type: 'number',
        required: true,
        id: 'mobile',
        placeholder: 'Phone number',
        formGroup: true,
    }],
    request: {
        email: '',
        jobTitle: '',
        department: '',
        businessStructure: '',
        accountName: '',
        contactDetails: {
            firstname: '',
            surname: '',
            mobile: '',
            countryCode: '',
        },
        address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            county: '',
            country: '',
            postcode: '',
        },
        noOfEmployees: '',
        natureOfBusiness: '',
        registrationNumber: '',
        vatNumber: '',
        companyType: '',
    },
    requestS4: {
        userDetails: {
            email: '',
            jobTitle: '',
            department: '',
            contactDetails: {
                firstname: '',
                surname: '',
                mobile: '',
                countryCode: '',
            },
            consentStatus: {
                hasAgreedToTnC: '',
                hasAgreedToRecieveComms: '',
                agreedOn: '',
            },
        },
        organisationDetails: {
            businessStructure: '',
            accountName: '',
            address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                county: '',
                country: '',
                postcode: '',
            },
            noOfEmployees: 0,
            natureOfBusiness: '',
            registrationNumber: '',
            vatNumber: '',
            companyType: '',
            companyCountryCode: '',
            phoneNumber: '',
            regionalOffice: {
                roAccountName: '',
                address: {
                    roAddressLine1: '',
                    roAddressLine2: '',
                    roCity: '',
                    roCounty: '',
                    roCountry: '',
                    roPostcode: '',
                },
                roBusinessStructure: '',
                roNoOfEmployees: 0,
                roNatureOfBusiness: '',
                roRegistrationNumber: '',
                roVatNumber: '',
                roCompanyCountryCode: '',
                roPhoneNumber: '',
            },
        },
    },
    requestS1: {
        userDetails: {
            email: '',
            jobTitle: '',
            department: '',
            contactDetails: {
                firstname: '',
                surname: '',
                mobile: '',
                countryCode: '',
            },
            consentStatus: {
                hasAgreedToTnC: '',
                hasAgreedToRecieveComms: '',
                agreedOn: '',
            },
        },
        organisationDetails: {
            businessStructure: '',
            accountName: '',
            address: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                county: '',
                country: '',
                postcode: '',
            },
            noOfEmployees: 0,
            natureOfBusiness: '',
            registrationNumber: '',
            vatNumber: '',
            companyType: '',
            companyCountryCode: '',
            phoneNumber: '',
        },
    },
    APIMocks: {
        checkCompanyAccount: {
            status: 200,
        },
    },
    countryList: [
        { name: 'United Kingdom' },
        { name: 'Afghanistan' },
        { name: 'Aland Islands' },
        { name: 'Albania' },
        { name: 'Algeria' },
        { name: 'American Samoa' },
        { name: 'Andorra' },
        { name: 'Angola' },
        { name: 'Anguilla' },
        { name: 'Antarctica' },
        { name: 'Antigua and Barbuda' },
        { name: 'Argentina' },
        { name: 'Armenia' },
        { name: 'Aruba' },
        { name: 'Australia' },
        { name: 'Austria' },
        { name: 'Azerbaijan' },
        { name: 'Bahamas' },
        { name: 'Bahrain' },
        { name: 'Bangladesh' },
        { name: 'Barbados' },
        { name: 'Belarus' },
        { name: 'Belgium' },
        { name: 'Belize' },
        { name: 'Benin' },
        { name: 'Bermuda' },
        { name: 'Bhutan' },
        { name: 'Bolivia' },
        { name: 'Bonaire, Sint Eustatius and Saba' },
        { name: 'Bosnia and Herzegovina' },
        { name: 'Botswana' },
        { name: 'Bouvet Island' },
        { name: 'Brazil' },
        { name: 'British Indian Ocean Territory' },
        { name: 'Brunei Darussalam' },
        { name: 'Bulgaria' },
        { name: 'Burkina Faso' },
        { name: 'Burundi' },
        { name: 'Cambodia' },
        { name: 'Cameroon' },
        { name: 'Canada' },
        { name: 'Cape Verde' },
        { name: 'Cayman Islands' },
        { name: 'Central African Republic' },
        { name: 'Chad' },
        { name: 'Chile' },
        { name: 'China' },
        { name: 'Christmas Island' },
        { name: 'Cocos (Keeling) Islands' },
        { name: 'Colombia' },
        { name: 'Comoros' },
        { name: 'Congo' },
        { name: 'Congo, Democratic Republic of the Congo' },
        { name: 'Cook Islands' },
        { name: 'Costa Rica' },
        { name: 'Cote D\'Ivoire' },
        { name: 'Croatia' },
        { name: 'Cuba' },
        { name: 'Curacao' },
        { name: 'Cyprus' },
        { name: 'Czech Republic' },
        { name: 'Denmark' },
        { name: 'Djibouti' },
        { name: 'Dominica' },
        { name: 'Dominican Republic' },
        { name: 'Ecuador' },
        { name: 'Egypt' },
        { name: 'El Salvador' },
        { name: 'Equatorial Guinea' },
        { name: 'Eritrea' },
        { name: 'Estonia' },
        { name: 'Ethiopia' },
        { name: 'Falkland Islands (Malvinas)' },
        { name: 'Faroe Islands' },
        { name: 'Fiji' },
        { name: 'Finland' },
        { name: 'France' },
        { name: 'French Guiana' },
        { name: 'French Polynesia' },
        { name: 'French Southern Territories' },
        { name: 'Gabon' },
        { name: 'Gambia' },
        { name: 'Georgia' },
        { name: 'Germany' },
        { name: 'Ghana' },
        { name: 'Gibraltar' },
        { name: 'Greece' },
        { name: 'Greenland' },
        { name: 'Grenada' },
        { name: 'Guadeloupe' },
        { name: 'Guam' },
        { name: 'Guatemala' },
        { name: 'Guernsey' },
        { name: 'Guinea' },
        { name: 'Guinea-Bissau' },
        { name: 'Guyana' },
        { name: 'Haiti' },
        { name: 'Heard Island and Mcdonald Islands' },
        { name: 'Holy See (Vatican City State)' },
        { name: 'Honduras' },
        { name: 'Hong Kong' },
        { name: 'Hungary' },
        { name: 'Iceland' },
        { name: 'India' },
        { name: 'Indonesia' },
        { name: 'Iran, Islamic Republic of' },
        { name: 'Iraq' },
        { name: 'Ireland' },
        { name: 'Isle of Man' },
        { name: 'Israel' },
        { name: 'Italy' },
        { name: 'Jamaica' },
        { name: 'Japan' },
        { name: 'Jersey' },
        { name: 'Jordan' },
        { name: 'Kazakhstan' },
        { name: 'Kenya' },
        { name: 'Kiribati' },
        { name: 'Korea, Democratic People\'s Republic of' },
        { name: 'Korea, Republic of' },
        { name: 'Kosovo' },
        { name: 'Kuwait' },
        { name: 'Kyrgyzstan' },
        { name: 'Lao People\'s Democratic Republic' },
        { name: 'Latvia' },
        { name: 'Lebanon' },
        { name: 'Lesotho' },
        { name: 'Liberia' },
        { name: 'Libyan Arab Jamahiriya' },
        { name: 'Liechtenstein' },
        { name: 'Lithuania' },
        { name: 'Luxembourg' },
        { name: 'Macao' },
        { name: 'Macedonia, the Former Yugoslav Republic of' },
        { name: 'Madagascar' },
        { name: 'Malawi' },
        { name: 'Malaysia' },
        { name: 'Maldives' },
        { name: 'Mali' },
        { name: 'Malta' },
        { name: 'Marshall Islands' },
        { name: 'Martinique' },
        { name: 'Mauritania' },
        { name: 'Mauritius' },
        { name: 'Mayotte' },
        { name: 'Mexico' },
        { name: 'Micronesia, Federated States of' },
        { name: 'Moldova, Republic of' },
        { name: 'Monaco' },
        { name: 'Mongolia' },
        { name: 'Montenegro' },
        { name: 'Montserrat' },
        { name: 'Morocco' },
        { name: 'Mozambique' },
        { name: 'Myanmar' },
        { name: 'Namibia' },
        { name: 'Nauru' },
        { name: 'Nepal' },
        { name: 'Netherlands' },
        { name: 'Netherlands Antilles' },
        { name: 'New Caledonia' },
        { name: 'New Zealand' },
        { name: 'Nicaragua' },
        { name: 'Niger' },
        { name: 'Nigeria' },
        { name: 'Niue' },
        { name: 'Norfolk Island' },
        { name: 'Northern Mariana Islands' },
        { name: 'Norway' },
        { name: 'Oman' },
        { name: 'Pakistan' },
        { name: 'Palau' },
        { name: 'Palestinian Territory, Occupied' },
        { name: 'Panama' },
        { name: 'Papua New Guinea' },
        { name: 'Paraguay' },
        { name: 'Peru' },
        { name: 'Philippines' },
        { name: 'Pitcairn' },
        { name: 'Poland' },
        { name: 'Portugal' },
        { name: 'Puerto Rico' },
        { name: 'Qatar' },
        { name: 'Reunion' },
        { name: 'Romania' },
        { name: 'Russian Federation' },
        { name: 'Rwanda' },
        { name: 'Saint Barthelemy' },
        { name: 'Saint Helena' },
        { name: 'Saint Kitts and Nevis' },
        { name: 'Saint Lucia' },
        { name: 'Saint Martin' },
        { name: 'Saint Pierre and Miquelon' },
        { name: 'Saint Vincent and the Grenadines' },
        { name: 'Samoa' },
        { name: 'San Marino' },
        { name: 'Sao Tome and Principe' },
        { name: 'Saudi Arabia' },
        { name: 'Senegal' },
        { name: 'Serbia' },
        { name: 'Serbia and Montenegro' },
        { name: 'Seychelles' },
        { name: 'Sierra Leone' },
        { name: 'Singapore' },
        { name: 'Sint Maarten' },
        { name: 'Slovakia' },
        { name: 'Slovenia' },
        { name: 'Solomon Islands' },
        { name: 'Somalia' },
        { name: 'South Africa' },
        { name: 'South Georgia and the South Sandwich Islands' },
        { name: 'South Sudan' },
        { name: 'Spain' },
        { name: 'Sri Lanka' },
        { name: 'Sudan' },
        { name: 'Suriname' },
        { name: 'Svalbard and Jan Mayen' },
        { name: 'Swaziland' },
        { name: 'Sweden' },
        { name: 'Switzerland' },
        { name: 'Syrian Arab Republic' },
        { name: 'Taiwan, Province of China' },
        { name: 'Tajikistan' },
        { name: 'Tanzania, United Republic of' },
        { name: 'Thailand' },
        { name: 'Timor-Leste' },
        { name: 'Togo' },
        { name: 'Tokelau' },
        { name: 'Tonga' },
        { name: 'Trinidad and Tobago' },
        { name: 'Tunisia' },
        { name: 'Turkey' },
        { name: 'Turkmenistan' },
        { name: 'Turks and Caicos Islands' },
        { name: 'Tuvalu' },
        { name: 'Uganda' },
        { name: 'Ukraine' },
        { name: 'United Arab Emirates' },
        { name: 'United States' },
        { name: 'United States Minor Outlying Islands' },
        { name: 'Uruguay' },
        { name: 'Uzbekistan' },
        { name: 'Vanuatu' },
        { name: 'Venezuela' },
        { name: 'Viet Nam' },
        { name: 'Virgin Islands, British' },
        { name: 'Virgin Islands, U.s.' },
        { name: 'Wallis and Futuna' },
        { name: 'Western Sahara' },
        { name: 'Yemen' },
        { name: 'Zambia' },
        { name: 'Zimbabwe' },
    ],
    regionalOffices: [
        {
            name: 'Regional Office 1 Address Postcode',
            addressLine1: 'Address Line 1',
            addressLine2: 'Address Line 2',
            postcode: 'Postcode',

        },
        {
            name: 'Accenture Newcastle',
            addressLine1: 'Address Line 3',
            addressLine2: 'Address Line 4',
            postcode: 'Postcode',
        },
    ],
    regionalOfficeFormData: [{
        label: 'Company name',
        type: 'text',
        required: true,
        id: 'roAccountName',
        placeholder: 'Company name',
    },
    {
        label: 'Company type',
        type: 'select',
        required: true,
        id: 'roBusinessStructure',
        placeholder: 'Company type',
    },
    {
        label: 'Nature of business (SIC)',
        type: 'text',
        required: false,
        id: 'roNatureOfBusiness',
        placeholder: 'Nature of business (SIC)',
    },
    {
        label: 'VAT number',
        type: 'text',
        required: false,
        id: 'roVatNumber',
        placeholder: 'VAT number',
    },
    {
        label: 'Registration number',
        type: 'text',
        required: false,
        id: 'roRegistrationNumber',
        placeholder: 'Registration number',
        pattern: '^[A-Za-z]{2}[0-9]{6}|[0-9]{8}$',
        title: 'Please enter a valid company house number.',
    },
    {
        label: 'Number of employees',
        type: 'text',
        required: true,
        id: 'roNoOfEmployees',
        placeholder: 'Number of employees',
        pattern: '^[0-9]+$',
        title: 'Field has to be numbers only',
    },
    {
        label: 'Address line 1',
        type: 'text',
        required: true,
        id: 'roAddressLine1',
        placeholder: 'Address line 1',
    },
    {
        label: 'Address line 2',
        type: 'text',
        required: false,
        id: 'roAddressLine2',
        placeholder: 'Address line 2',
    },
    {
        label: 'Town/City',
        type: 'text',
        required: true,
        id: 'roCity',
        placeholder: 'Town/City',
    },
    {
        label: 'County',
        type: 'text',
        required: false,
        id: 'roCounty',
        placeholder: 'County',
    },
    {
        label: 'Postcode',
        type: 'text',
        // pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
        // title: 'Please enter a valid UK postcode',
        required: true,
        id: 'roPostcode',
        placeholder: 'Postcode',
    },
    {
        label: 'Country',
        type: 'select',
        required: true,
        id: 'roCountry',
        placeholder: 'Country',
    },
    {
        label: 'Phone number',
        type: 'select',
        required: true,
        id: 'roCompanyCountryCode',
        placeholder: 'Select country code',
        formGroup: true,
    }, {
        type: 'text',
        required: true,
        id: 'roPhoneNumber',
        placeholder: 'Phone Number',
        pattern: '^[0-9]+$',
        title: 'Field has to be numbers only',
        formGroup: true,
    }],
    consentCheckboxOptions: [
        {
            id: 'tcpp',
            value: 'I agree to Open Terms & Conditions and Privacy Policy.',
            label: 'I agree to Open Terms & Conditions and Privacy Policy.',
            checked: false,
        },
        {
            id: 'consentEmail',
            value: 'I consent to receive communications about Open account by email.',
            label: 'I consent to receive communications about Open account by email.',
            checked: false,
        },

    ],

    companyExistData: [
        {
            accountID: 'e54fd6a1-b6e4-4e74-b6bd-66da0a17c7ba',
            name: 'AccountName-HQ1',
            isHQ: true,
            department: [
                {
                    departmentName: 'departmentName-HQ1',
                    departmentID: 'departmentName-HQ1',
                },
                {
                    departmentName: 'departmentName2-HQ1',
                    departmentID: 'departmentName2-HQ1',
                },
            ],
            address: {
                addressLine1: 'HQ1-Add1',
                addressLine2: 'HQ1-Add2',
                postcode: 'HQ1-112211',
            },
            regionalOffices: [
                {
                    accountID: 'ee52d605-9e88-46be-be43-d4c53b5588cd',
                    accountName: 'RO1-HQ1',
                    // department: 'Department-RO1_HQ1',
                    department: [
                        {
                            departmentName: 'Department-RO1_HQ1',
                            departmentID: 'dDepartment-RO1_HQ1',
                        },
                        {
                            departmentName: 'Department2-RO1_HQ1',
                            departmentID: 'Department2-RO1_HQ1',
                        },
                    ],
                    address: {
                        addressLine1: 'Add_RO1-HQ1',
                        addressLine2: 'Add2_RO1-HQ1',
                        postcode: 'RO1-HQ1-112112',
                    },

                },
                {
                    accountID: 'HQ-1-RO2',
                    accountName: 'RO2-HQ1',
                    department: [
                        {
                            departmentName: 'Department-RO2_HQ1',
                            departmentID: 'dDepartment-RO2HQ1',
                        },
                        {
                            departmentName: 'Department2-RO2_HQ1',
                            departmentID: 'Department2-RO2_HQ1',
                        },
                    ],
                    address: {
                        addressLine1: 'Add_RO2-HQ1',
                        addressLine2: 'Add2_RO2-HQ1',
                        postcode: 'RO2-HQ1-112112',
                    },

                },
                {
                    accountID: 'HQ-1-RO3',
                    accountName: 'RO3-HQ1',
                    department: [
                        {
                            departmentName: 'Department-RO3_HQ1',
                            departmentID: 'dDepartment-RO3HQ1',
                        },
                        {
                            departmentName: 'Department2-RO3_HQ1',
                            departmentID: 'Department2-RO3_HQ1',
                        },
                    ],
                    address: {
                        addressLine1: 'Add_RO3-HQ1',
                        addressLine2: 'Add2_RO3-HQ1',
                        postcode: 'RO3-HQ1-112112',
                    },

                },


            ],

        },
    ],
    companyExistData1: [
        {
            accountID: 'e54fd6a1-b6e4-4e74-b6bd-66da0a17c7ba',
            name: 'AccountName-HQ1',
            isHQ: true,
            department: [
                {
                    departmentName: 'departmentName-HQ1',
                    departmentID: 'departmentName-HQ1',
                },
                {
                    departmentName: 'departmentName2-HQ1',
                    departmentID: 'departmentName2-HQ1',
                },
            ],
            address: {
                addressLine1: 'HQ1-Add1',
                addressLine2: 'HQ1-Add2',
                postcode: 'HQ1-112211',
            },
            regionalOffices: [
                {
                    accountID: 'ee52d605-9e88-46be-be43-d4c53b5588cd',
                    accountName: 'RO1-HQ1',
                    // department: 'Department-RO1_HQ1',
                    department: [
                        {
                            departmentName: 'Department-RO1_HQ1',
                            departmentID: 'dDepartment-RO1_HQ1',
                        },
                        {
                            departmentName: 'Department2-RO1_HQ1',
                            departmentID: 'Department2-RO1_HQ1',
                        },
                    ],
                    address: {
                        addressLine1: 'Add_RO1-HQ1',
                        addressLine2: 'Add2_RO1-HQ1',
                        postcode: 'RO1-HQ1-112112',
                    },

                },
                {
                    accountID: 'HQ-1-RO2',
                    accountName: 'RO2-HQ1',
                    department: [
                        {
                            departmentName: 'Department-RO2_HQ1',
                            departmentID: 'dDepartment-RO2HQ1',
                        },
                        {
                            departmentName: 'Department2-RO2_HQ1',
                            departmentID: 'Department2-RO2_HQ1',
                        },
                    ],
                    address: {
                        addressLine1: 'Add_RO2-HQ1',
                        addressLine2: 'Add2_RO2-HQ1',
                        postcode: 'RO2-HQ1-112112',
                    },

                },
                {
                    accountID: 'HQ-1-RO3',
                    accountName: 'RO3-HQ1',
                    department: [
                        {
                            departmentName: 'Department-RO3_HQ1',
                            departmentID: 'dDepartment-RO3HQ1',
                        },
                        {
                            departmentName: 'Department2-RO3_HQ1',
                            departmentID: 'Department2-RO3_HQ1',
                        },
                    ],
                    address: {
                        addressLine1: 'Add_RO3-HQ1',
                        addressLine2: 'Add2_RO3-HQ1',
                        postcode: 'RO3-HQ1-112112',
                    },

                },


            ],

        },
        {
            accountID: 'a612d310-d87d-495a-a5f3-ffcc1d0b61e8',
            name: 'AccountName-HQ2',
            isHQ: true,
            department: [
                {
                    departmentName: 'departmentName-HQ2',
                    departmentID: 'departmentName-HQ2',
                },

            ],
            address: {
                addressLine1: 'HQ2-Add1',
                addressLine2: 'HQ2-Add2',
                postcode: 'HQ2-112211',
            },
            regionalOffices: [
                {
                    accountID: 'HQ2-RO1',
                    accountName: 'RO1-HQ2',
                    department: [
                        {
                            departmentName: 'departmentName_RO1-HQ2',
                            departmentID: 'departmentName_RO1-HQ2',
                        },

                    ],
                    address: {
                        addressLine1: 'Add_RO1-HQ2',
                        addressLine2: 'Add2_RO1-HQ2',
                        postcode: 'RO1-HQ2-112112',
                    },

                },
                {
                    accountID: 'HQ2-RO2',
                    accountName: 'RO2-HQ2',
                    department: [
                        {
                            departmentName: 'departmentName_RO2-HQ2',
                            departmentID: 'departmentName_RO2-HQ2',
                        },

                    ],
                    address: {
                        addressLine1: 'Add_RO2-HQ2',
                        addressLine2: 'Add2_RO2-HQ2',
                        postcode: 'RO2-HQ2-112112',
                    },

                },
            ],

        },
    ],
};

export default Constants;
