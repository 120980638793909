import React from 'react';
import PropTypes from 'prop-types';
import ViewerMode from './ViewerMode';
import Button from '../Button';
import Constants from './constants';

const ViewerOptions = ({
    viewerMode,
    withViewerDrowpdown = true,
    withHideButton = true,
    handleChangeViewerMode,
    handleHideViewer,
}) => (<>

    { <div className='control-viewer'>
        {withViewerDrowpdown
         && <ViewerMode
             selectedMode = {viewerMode}
             handleChangeMode= {handleChangeViewerMode}
         />
        }
        {withHideButton
        && <Button
            id= 'diff-viewer-hide-button'
            label= {Constants.HIDE_VIEWER}
            type='button'
            variant='secondary'
            size='medium'
            handleClick={ handleHideViewer }/>
        }
    </div> }
</>);

ViewerOptions.propTypes = {
    withViewerDrowpdown: PropTypes.bool,
    withHideButton: PropTypes.bool,
    viewerMode: PropTypes.string.isRequired,
    handleHideViewer: PropTypes.func,
    handleChangeViewerMode: PropTypes.func,
};

export default ViewerOptions;
