import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Constants from './constants';
import BrowseData from '../../features/BrowseData';
import projectManagementAPIs from '../../services/project-management.service';
import AuthContext from '../../context/AuthContext';
import Toast from '../../components/Alerts/Toast/Toast';

const BrowseOpportunities = () => {
    const [opportunities, setOpportunities] = useState([]);
    const authContext = useContext(AuthContext);
    const [tab, setTab] = useState('');
    const { myOpportunities } = useParams();

    const fetchData = async (tabSelected) => {
        try {
            let response;
            if (authContext.authenticated === false) {
                response = await projectManagementAPIs.listPublicOpportunities();
            } else {
                response = await projectManagementAPIs.listOpportunities(tabSelected);
            }
            if (response.status === 200) {
                setOpportunities(response.data.items);
            } else {
                setOpportunities([]);
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const getOpportunitiesByTab = (selectedTab) => {
        setOpportunities([]);
        switch (selectedTab) {
            case 'Available Opportunities':
                setTab('');
                fetchData('');
                break;
            case 'My Opportunities':
                setTab('user');
                fetchData('user');
                break;
            case 'My Department':
                setTab('department');
                fetchData('department');
                break;
            case 'My Organisation':
                setTab('account');
                fetchData('account');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        fetchData(tab);
    }, []);

    return <BrowseData
        id='browseOpportunities'
        context='Opportunities'
        contextSingular='Opportunity'
        dataSet={opportunities}
        searchPlaceholder='Search for a opportunity'
        searchFilterAttribute='title'
        sortOptions={Constants.sortOptions}
        tabOptions={authContext.authenticated === true ? Constants.tabOptions : undefined}
        handleTabSelected={getOpportunitiesByTab}
        defaultTab={ myOpportunities === 'myOpportunities' ? 1 : 0 }
    />;
};

export default BrowseOpportunities;
